import {
  TableCell,
  TableRow,
  makeStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import EmptyCTA from './EmptyCta'

interface Props {
  colSpan: number
  message: string | React.ReactNode
  className?: string
  fillerClass?: string
  secondaryTextTemp?: boolean
  cta?: boolean
  customEmptyCondition?: boolean
}

const EmptyRecordsCTARow: React.FC<Props> = (props: Props) => {
  const { colSpan, message, className, fillerClass, secondaryTextTemp, cta, customEmptyCondition } = props
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell colSpan={colSpan} className={clsx(classes.root, className)}>
        <EmptyCTA className={fillerClass} helperText={message} secondaryText={secondaryTextTemp} cta={cta} customEmptyCondition={customEmptyCondition} />
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
  },
  container: {
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default EmptyRecordsCTARow
