import { AirdropLootBox, LootBoxType } from 'js/constants/demexPoints'

import CommonBox from '../Promotion/DemexPoints/CommonBox.png'
// @ts-ignore
import CommonBoxReveal from '../Promotion/DemexPoints/CommonBoxReveal.webm'
import EpicBox from '../Promotion/DemexPoints/EpicBox.png'
// @ts-ignore
import EpicBoxReveal from '../Promotion/DemexPoints/EpicBoxReveal.webm'
import LegendaryBox from '../Promotion/DemexPoints/LegendaryBox.png'
// @ts-ignore
import LegendaryBoxReveal from '../Promotion/DemexPoints/LegendaryBoxReveal.webm'
import RareBox from '../Promotion/DemexPoints/RareBox.png'
// @ts-ignore
import RareBoxReveal from '../Promotion/DemexPoints/RareBoxReveal.webm'
import RetroBox from '../Promotion/DemexPoints/RetroBox.png'
// @ts-ignore
import RetroBoxReveal from '../Promotion/DemexPoints/RetroBoxReveal.webm'
import UncommonBox from '../Promotion/DemexPoints/UncommonBox.png'
// @ts-ignore
import UncommonBoxReveal from '../Promotion/DemexPoints/UncommonBoxReveal.webm'

export const LootBoxes: Record<string, AirdropLootBox> = {
  [LootBoxType.Retro]: { iconSrc: RetroBox, revealSrc: RetroBoxReveal, label: 'Retro', minPoints: 0, maxPoints: 0 }, // retro does not have min or max points defined
  [LootBoxType.Common]: { iconSrc: CommonBox, revealSrc: CommonBoxReveal, label: 'Common', minPoints: 100, maxPoints: 10000 },
  [LootBoxType.Uncommon]: { iconSrc: UncommonBox, revealSrc: UncommonBoxReveal, label: 'Uncommon', minPoints: 10000, maxPoints: 20000 },
  [LootBoxType.Rare]: { iconSrc: RareBox, revealSrc: RareBoxReveal, label: 'Rare', minPoints: 20000, maxPoints: 30000 },
  [LootBoxType.Epic]: { iconSrc: EpicBox, revealSrc: EpicBoxReveal, label: 'Epic', minPoints: 30000, maxPoints: 40000 },
  [LootBoxType.Legendary]: { iconSrc: LegendaryBox, revealSrc: LegendaryBoxReveal, label: 'Legendary', minPoints: 40000, maxPoints: 100000 }
}
