import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { RecentTradesStateProps, RecentTradesActionTypes, RecentTradesState } from './types'

export const DefaultInitialState:
  Record.Factory<RecentTradesStateProps> = Record<RecentTradesStateProps>({
    recentTrades: [],
  })

const defaultInitialState: RecentTradesState = new DefaultInitialState()

export const recentTradesReducer = (
  state: RecentTradesState = defaultInitialState,
  action: AnyAction,
): RecentTradesState => {
  switch (action.type) {
    case RecentTradesActionTypes.ADD_RECENT_TRADES: {
      if (action.payload) {
        // Remove sorting of recent_trades to avoid lag
        const trades = action.payload
          .concat(state.recentTrades)
          .slice(0, 100)
        return state.set('recentTrades', trades)
      }
      return state
    }
    case RecentTradesActionTypes.SET_RECENT_TRADES: {
      if (action.payload) {
        // Remove sorting of recent_trades to avoid lag
        const trades = action.payload
          .slice(0, 100)
        return state.set('recentTrades', trades)
      }
      return state
    }
    case RecentTradesActionTypes.CLEAR_RECENT_TRADES: {
      return new DefaultInitialState()
    }
    default:
      return state
  }
}
