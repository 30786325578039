import { makeStyles } from '@material-ui/core'
import { Color } from '@material-ui/lab'
import clsx from 'clsx'
import React from 'react'

import { ReactComponent as AlertIcon } from 'assets/AlertIcon.svg'
import { ReactComponent as CautionIcon } from 'assets/Caution.svg'
import { ReactComponent as InfoIcon } from 'assets/InfoIcon.svg'
import { ReactComponent as TickIcon } from 'assets/Tick.svg'

import SvgIcon from '../SvgIcon'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: number
  status?: Color
}

const PulsingIndicator: React.FC<Props> = (props: Props) => {
  const { className, size = 16, status = 'info' } = props
  const classes = useStyles()

  const IndicatorIcon = React.useMemo(() => {
    switch (status) {
      case 'success':
        return TickIcon
      case 'error':
        return AlertIcon
      case 'warning':
        return CautionIcon
      default:
        return InfoIcon
    }
  }, [status])

  return (
    <span
      className={clsx(classes.root, className, status)}
      style={{ maxHeight: size, width: size }}
    >
      <span className={clsx(classes.pulse, status)} style={{ height: size, width: size }} />
      <SvgIcon
        className={clsx(classes.pulsingIcon, status)}
        component={IndicatorIcon}
        style={{ height: size, width: size }}
      />
    </span>
  )
}

const useStyles = makeStyles((theme) => ({
  '@keyframes appear': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 0.6,
    },
    '100%': {
      opacity: 0,
    },
  },
  root: {
    position: 'relative',
  },
  pulse: {
    position: 'absolute',
    top: 0,
    left: 0,
    '&.warning': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.error': {
      backgroundColor: theme.palette.error.main,
    },
    '&.success': {
      backgroundColor: theme.palette.success.main,
    },
    '&.info': {
      backgroundColor: theme.palette.primary.main,
    },
    filter: `blur(${theme.spacing(1)}px)`,
    zIndex: -1,
    animation: '$appear 1500ms ease-in-out infinite',
  },
  pulsingIcon: {
    zIndex: 1200,
    '&.error': {
      '& path': {
        stroke: 'url(#accentError)',
        strokeWidth: 1.5,
      },
    },
    '&.warning': {
      '& path': {
        stroke: 'url(#accentWarning)',
      },
    },
    '&.info': {
      '& path': {
        stroke: theme.palette.text.demexSolid,
      },
    },
    '&.success': {
      '& path': {
        stroke: 'url(#accentSuccess)',
        strokeWidth: 1.5,
      },
    },
  },
}))


export default PulsingIndicator
