import { BigNumber } from 'bignumber.js'
import { TypeUtils } from 'carbon-js-sdk'
import { Map, Set } from 'immutable'
import { createSelector } from 'reselect'

import { ChartLayoutType, ChartLayoutsType } from 'js/utils/types'

import { BooksObj, HistoricalFundingRateGraphData, HistoricalFundingRates, Market, MarketCandlesticks, MarketLiquidity, MarketTypeTab, ModeDialogState, OrderDetailsDialogState } from './types'

import { RootState } from '../rootReducer'

export function getFavouriteMarkets(state: RootState): Set<string> {
  return state.exchange.favouriteMarkets
}

export function getFundingInterval(state: RootState): number | null {
  return state.exchange.fundingInterval
}

export function getHideLayouts(state: RootState): Set<string> {
  return state.exchange.hideLayouts
}

export function getMarket(state: RootState): Market | null {
  return state.exchange.market
}

export function getSearchHistory(state: RootState): TypeUtils.SimpleMap<string[]> {
  return state.exchange.searchHistory
}

export function getMarketName(state: RootState): string {
  return state.exchange.marketName
}

export function getMarkets(state: RootState): Market[] {
  return state.exchange.markets
}

export function getMarketsAliases(state: RootState): TypeUtils.SimpleMap<string> {
  return state.exchange.marketsAliases
}

export function getMarketDisplayNames(state: RootState): TypeUtils.SimpleMap<string> {
  return state.exchange.marketDisplayNames
}

export const getMarketsAsMap = createSelector(
  [getMarkets],
  (markets: Market[]): Map<string, Market> => Map(markets.map((market) => [market.id, market])),
)

export function getLayout(state: RootState): ChartLayoutType {
  return state.exchange.layout
}

export function getLayouts(state: RootState): ChartLayoutsType {
  return state.exchange.layouts
}

export function getMarketSelect(state: RootState): boolean {
  return state.exchange.marketSelect
}

export function getMarketTypeSelect(state: RootState): MarketTypeTab {
  return state.exchange.marketTypeSelect
}

export function getMarketSearch(state: RootState): string {
  return state.exchange.marketSearch
}

export function getLastMarket(state: RootState): {
  [key: string]: string
} {
  return state.exchange.lastMarket
}

export function getLastSwapMarket(state: RootState): {
  [key: string]: string
} {
  return state.exchange.lastSwapMarket
}

export function getMarketsLiquidityMap(state: RootState): TypeUtils.SimpleMap<MarketLiquidity> {
  return state.exchange.marketLiquidityMap
}

export function getSymbolToggle(state: RootState): boolean {
  return state.exchange.symbolToggle
}

export function getCandlesticks(state: RootState): MarketCandlesticks[] {
  return state.exchange.candlesticks
}

export function getShowLowLiquidity(state: RootState): boolean {
  return state.exchange.showLowLiquidity
}

export function getBooksObj(state: RootState): BooksObj {
  return state.exchange.booksObj
}

export function getIsSimpleMode(state: RootState): boolean {
  return state.exchange.isSimpleMode
}

export function getModeDialogState(state: RootState): ModeDialogState {
  return state.exchange.modeDialogState
}

export function getTempOrderDetailsDialogState(state: RootState): OrderDetailsDialogState {
  return state.exchange.tempOrderDetailsDialogState
}

export function getAdjustedMaxFuturesQuantity(state: RootState): BigNumber {
  return state.exchange.adjustedMaxFuturesQuantity
}

export function getHistoricalMarketFundingRates(state: RootState): HistoricalFundingRates | null {
  return state.exchange.marketHistoricalFundingRates
}

export function getHistoricalFundingRateGraphdata(state: RootState): HistoricalFundingRateGraphData[] {
  return state.exchange.historicalFundingRateGraphData
}

export function getExchangeSectionCollapseStatus(state: RootState): TypeUtils.SimpleMap<boolean> {
  return state.exchange.sectionCollapseStatus
}
