import { TypeUtils, WSModels } from 'carbon-js-sdk'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import dayjs from 'dayjs'
import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { TokenPrice } from 'types/price'

import { TokenPricesActionTypes, PricingProps, PricingState, basePricingParams } from './types'

export const DefaultInitialState:
  Record.Factory<PricingProps> = Record<PricingProps>({
    prices: {},
    pricesSetMap: {},
    pricingParams: basePricingParams,
  })

const defaultInitialState: PricingState = new DefaultInitialState()

export const pricingReducer = (
  state: PricingState = defaultInitialState,
  action: AnyAction,
): PricingState => {
  switch (action.type) {
    case TokenPricesActionTypes.UPDATE_TOKEN_PRICES: {
      return state.set('prices', {
        ...state.prices,
        ...action.payload,
      })
    }
    case TokenPricesActionTypes.SET_PRICE_SET_MAP: {
      return state.set('pricesSetMap', action.payload)
    }
    case TokenPricesActionTypes.SET_PRICING_PARAMS: {
      return state.set('pricingParams', action.payload)
    }

    default:
      return state
  }
}

export function parseRawPrices(newPrices: WSModels.TokenPrice[]): TypeUtils.SimpleMap<TokenPrice> {
  return newPrices.reduce((result, price) => {
    result[price.denom] = {
      denom: price.denom,
      index: bnOrZero(price.index),
      indexUpdatedAt: dayjs(price.index_updated_at),
      twap: bnOrZero(price.twap),
    }
    return result
  }, {} as TypeUtils.SimpleMap<TokenPrice>)
}
