import { alpha } from '@material-ui/core'

const palette = {
  secondary: {
    light: '#5583e8',
    main: '#4276e6',
    dark: '#4276e6',
  },
  green: {
    light: '#32b683',
    main: '#02a566',
    dark: '#09764F',
  },
  red: {
    light: '#ef5d5e',
    main: '#ee4b4c',
    dark: '#9F2C23',
  },
  darkBlue: {
    light: '#222d44',
    main: '#151d2e',
    dark: '#144488',
  },
  resizableHandler: '#6A748E',
  cautionIcon: '#EDBA34',
  icon: '#959ebc',
  processing: '#FFA800',
  processingBg: 'rgba(255, 168, 0, 0.05)',
}

export const legacyDarkPalette = {
  ...palette,
  type: 'dark',
  primary: {
    main: '#141925',
  },
  error: {
    main: '#C7372C',
  },
  background: {
    default: '#0F121A',
    paper: '#0F121A',
    header: '#0F121A',
    tooltip: '#F2F2F3',
    popper: '#191e2c',
    highlight: '#283149',
    hover: '#21252F',
    disabled: '#1A1F2A',
    disabledOutline: '#262E3F',
    alternate: '#f0f3fa',
    container: '#F8FAFD',
    selected: {
      default: '#4276e6',
      hover: '#5583e8',
    },
    notSelected: {
      default: '#283149',
      hover: '#323B52',
    },
    marketTag: '#192643',
    errorTag: alpha('#fa4e4f', 0.14),
  },
  text: {
    primary: '#FAFBFC',
    secondary: '#FAFBFC',
    label: '#8E939B',
    tooltip: '#1E2938',
    toggle: {
      selected: '#FAFBFC',
      notSelected: '#FAFBFC',
    },
  },
  input: {
    background: {
      disabled: '#1a1f2a',
    },
    outline: {
      default: '#3A589A',
      disabled: '#262E3F',
      focused: '#4276e6',
      hover: '#6f92e0',
    },
    text: {
      default: '#FAFBFC',
      disabled: '#596075',
      placeholder: '#6B748B',
      toggle: {
        selected: '#FAFBFC',
        notSelected: '#FAFBFC',
      },
    },
  },
  tag: {
    background: {
      success: 'rgb(233, 245, 240)',
    },
    text: {
      success: '#00b66f',
    },
  },
  buy: '#02a566',
  sell: '#ee4b4c',
  buyText: '#00b66f',
  sellText: '#fa4e4f',
  divider: '#262e3f',
  componentTitle: '#e4e5ec',
  blank: '#2a3442',
  scrollbar: {
    thumb: '#6C6E71',
    track: '#414447',
  },
  disabledOutline: '#262E3F',
  tableBorder: '#E3E8F7',
}

export const legacyLightPalette = {
  ...palette,
  type: 'light',
  primary: {
    main: '#0e111a',
  },
  background: {
    default: '#fbfdfe',
    paper: '#fcfdff',
    header: '#fff',
    popper: '#F8FAFD',
    tooltip: '#191E2C',
    highlight: '#E7EDF9',
    hover: '#F2F5FA',
    disabled: '#bdccd9',
    alternate: '#F5F7FA',
    container: '#E3E8F7',
    selected: {
      default: '#4276e6',
      hover: '#5583e8',
    },
    notSelected: {
      default: '#E7EDF9',
      hover: '#D7E0F6',
    },
    marketTag: '#E7EDF9',
    errorTag: alpha('#fa4e4f', 0.14),
  },
  text: {
    primary: '#363B46',
    secondary: '#8E939B',
    hint: '#6B748B',
    label: '#6B748B',
    tooltip: '#FAFBFC',
    toggle: {
      selected: '#FAFBFC',
      notSelected: '#4276e6',
    },
  },
  input: {
    background: {
      disabled: '#eef0f5',
    },
    outline: {
      default: '#9AAED9',
      disabled: '#DFE4EF',
      focused: '#4276e6',
      hover: '#6f92e0',
    },
    text: {
      default: '#262E3F',
      disabled: '#B3B9C5',
      placeholder: '#6B748B',
    },
  },
  tag: {
    background: {
      success: 'rgb(233, 245, 240)',
    },
    text: {
      success: '#00b66f',
    },
  },
  buy: '#0c9463',
  sell: '#f04437',
  buyText: '#00b66f',
  sellText: '#fa4e4f',
  divider: '#d8d8d8',
  componentTitle: '#262e3f',
  blank: '#E7EDF9',
  scrollbar: {
    thumb: '#ABAAA9',
    track: '#DDDCDB',
  },
  tableBorder: '#E3E8F7',
}
