import { action } from 'typesafe-actions'

import { OrderFormState, OrderManagerActionTypes, SavedOrderType } from './types'

export const clearOrderForm = () => action(
  OrderManagerActionTypes.CLEAR_ORDER_FORM,
)

export const setOrderFormInputs = (form: OrderFormState) => action(
  OrderManagerActionTypes.SET_ORDER_FORM_INPUTS, form,
)

export const setShowOrderDetails = (show: boolean) => action(
  OrderManagerActionTypes.SET_SHOW_ORDER_DETAILS, show,
)

export const selectFeeToken = (denom: string) => action(
  OrderManagerActionTypes.SELECT_FEE_TOKEN, denom,
)

export const setFeeQuantity = (quantity: string) => action(
  OrderManagerActionTypes.SET_FEE_QUANTITY, quantity,
)

export const clearSWTHFeeForm = () => action(
  OrderManagerActionTypes.CLEAR_SWTH_FEE_FORM,
)

export const setAlertDialogOpen = () => action(
  OrderManagerActionTypes.SET_ALERT_DIALOG_OPEN,
)

export const setAlertDialogClose = () => action(
  OrderManagerActionTypes.SET_ALERT_DIALOG_CLOSE,
)

export const setReservedSettingsOpen = () => action(
  OrderManagerActionTypes.SET_RESERVED_SETTINGS_OPEN,
)

export const setReservedSettingsClose = () => action(
  OrderManagerActionTypes.SET_RESERVED_SETTINGS_CLOSE,
)

export const setOpenTpSlDialog = (open: boolean) => action(
  OrderManagerActionTypes.SET_OPEN_TP_SL_DIALOG, open,
)

export const setTakeProfitTriggerErrMsg = (errMsg: string) => action(
  OrderManagerActionTypes.SET_TAKE_PROFIT_TRIGGER_ERR_MSG, errMsg,
)

export const setTakeProfitPriceErrMsg = (errMsg: string) => action(
  OrderManagerActionTypes.SET_TAKE_PROFIT_PRICE_ERR_MSG, errMsg,
)

export const setStopLossTriggerErrMsg = (errMsg: string) => action(
  OrderManagerActionTypes.SET_STOP_LOSS_TRIGGER_ERR_MSG, errMsg,
)

export const setStopLossPriceErrMsg = (errMsg: string) => action(
  OrderManagerActionTypes.SET_STOP_LOSS_PRICE_ERR_MSG, errMsg,
)

export const reCalculateTpSlTriggerPrices = () => action(
  OrderManagerActionTypes.CALCULATE_TP_SL_TRIGGER_PRICES,
)

export const saveOrderType = (payload: SavedOrderType) => action(
  OrderManagerActionTypes.SAVE_ORDER_TYPE, payload,
)
