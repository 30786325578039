import { makeStyles, Popper, PopperProps, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { useMemo } from 'react'

interface AnchoredPopperProps extends PopperProps {
  popperWrapperClass?: string,
  popperClass?: string,
  maxWidth?: number
  children: any
}

const AnchoredPopper: React.FC<AnchoredPopperProps> = (props: AnchoredPopperProps) => {
  const { open, children, popperWrapperClass, popperClass, maxWidth=300, placement, ...popperProps } = props

  const classes = useStyles()
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null)

  const placementOffset = useMemo(() => {
    if (placement === 'left-start' || placement === 'right-start') {
      return '-10, 10'
    }
    if (placement === 'left-end' || placement === 'right-end') {
      return '10, 10'
    }
    return '0, 10'
  }, [placement])

  return (
    <Popper
      {...popperProps}
      open={open}
      className={clsx(classes.popperWrapper, popperWrapperClass)}
      placement={placement}
      modifiers={{
        arrow: {
          enabled: true,
          element: arrowRef,
        },
        offset: {
          enabled: true,
          offset: placementOffset
         },
      }}
    >
      <span className={classes.arrow} ref={setArrowRef} />
      <div className={clsx(classes.popper, popperClass)} style={{ maxWidth }}>
        {children}
      </div>
    </Popper>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  popperWrapper: {
    zIndex: 1000,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: '50%',
      marginTop: '-5px',
      '&::before': {
        borderColor: `transparent transparent ${theme.palette.divider} ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.primary,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: '50%',
      marginBottom: '-3px',
      '&::before': {
        borderColor: `${theme.palette.divider} ${theme.palette.divider} transparent transparent`,
        backgroundColor: theme.palette.background.primary,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      top: '50%',
      marginLeft: '-3px',
      '&::before': {
        borderColor: `transparent ${theme.palette.divider} ${theme.palette.divider} transparent`,
        backgroundColor: theme.palette.background.primary,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      top: '50%',
      marginRight: '-5px',
      '&::before': {
        borderColor: `${theme.palette.divider} transparent transparent ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.primary,
      },
    },
  },
  arrow: {
    position: 'absolute',
    zIndex: 1001,
    width: '10px',
    height: '10px',
    transform: 'rotate(90deg)',
    '&::before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      border: '1px solid',
      transform: 'rotate(45deg)',
    },
  },
  popper: {
    position: 'relative',
    backgroundColor: theme.palette.background.primary,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
  },
}))

export default AnchoredPopper
