import { createStyles, makeStyles } from '@material-ui/core'
import React from 'react'

interface Props {
  children: React.ReactNode
}

const List: React.FC<Props> = (props: Props) => {
  const { children } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    listStyle: 'none',
    position: 'relative',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
}))

export default List
