import { LayoutStructVersion } from 'js/constants/assets'

const baseLayouts = {
  version: LayoutStructVersion,
  xlLayout: [
    {
      w: 117, h: 13, x: 0, y: 1, i: 'chart', minW: 100, minH: 10,
    },
    {
      w: 31, h: 6, x: 117, y: 8, i: 'recentTrades', minW: 25, minH: 5,
    },
    {
      w: 31, h: 7, x: 117, y: 1, i: 'orderBook', minW: 25, minH: 5,
    },
    {
      w: 32, h: 20, x: 148, y: 0, i: 'orderManager', minW: 25, minH: 15,
    },
    {
      w: 148, h: 6, x: 0, y: 14, i: 'orderHistory', minW: 100, minH: 5,
    },
    {
      w: 148, h: 1, x: 0, y: 0, i: 'marketsBar', minW: 148, maxW: 148, minH: 1, maxH: 1,
    }
  ],
  lgLayout: [
    {
      w: 99, h: 18, x: 0, y: 1.5, i: 'chart', minW: 90, minH: 12,
    },
    {
      w: 41, h: 7, x: 99, y: 12.5, i: 'recentTrades', minW: 35, minH: 5,
    },
    {
      w: 41, h: 11, x: 99, y: 1.5, i: 'orderBook', minW: 35, minH: 8,
    },
    {
      w: 40, h: 27, x: 140, y: 0, i: 'orderManager', minW: 35, minH: 18,
    },
    {
      w: 140, h: 7, x: 0, y: 19.5, i: 'orderHistory', minW: 100, minH: 5,
    },
    {
      w: 140, h: 2, x: 0, y: 0, i: 'marketsBar', minW: 140, maxW: 140, minH: 2, maxH: 2,
    }
  ],
  mdLayout: [
    {
      w: 106, h: 17.5, x: 0, y: 2, i: 'chart', minW: 100, minH: 12,
    },
    {
      w: 37, h: 5, x: 106, y: 15, i: 'recentTrades', minW: 30, minH: 4,
    },
    {
      w: 37, h: 12.5, x: 106, y: 2, i: 'orderBook', minW: 30, minH: 10,
    },
    {
      w: 37, h: 27, x: 143, y: 0, i: 'orderManager', minW: 30, minH: 20,
    },
    {
      w: 143, h: 7, x: 0, y: 20, i: 'orderHistory', minW: 100, minH: 5,
    },
    {
      w: 143, h: 2.5, x: 0, y: 0, i: 'marketsBar', minW: 143, maxW: 143, minH: 2.5, maxH: 2.5,
    }
  ],
  smLayout: [
    {
      w: 8, h: 9, x: 0, y: 1, i: 'chart', minW: 7, minH: 8,
    },
    {
      w: 4, h: 4, x: 8, y: 13, i: 'recentTrades', minW: 3, minH: 3,
    },
    {
      w: 8, h: 8, x: 0, y: 9, i: 'orderBook', minW: 7, minH: 7,
    },
    {
      w: 4, h: 13, x: 8, y: 0, i: 'orderManager', minW: 3, minH: 10,
    },
    {
      w: 12, h: 5, x: 0, y: 17, i: 'orderHistory', minW: 4, minH: 4,
    },
    {
      w: 12, h: 1, x: 0, y: 0, i: 'marketsBar', maxH: 1, minW: 12, maxW: 12, minH: 1,
    }
  ],
  xsLayout: [
    {
      w: 4, h: 11, x: 0, y: 1, i: 'chart', minW: 3, minH: 10,
    },
    {
      w: 4, h: 4, x: 4, y: 17, i: 'recentTrades', minW: 3, minH: 3,
    },
    {
      w: 4, h: 9, x: 0, y: 12, i: 'orderBook', minW: 3, minH: 7,
    },
    {
      w: 4, h: 16, x: 4, y: 1, i: 'orderManager', minW: 3, minH: 10,
    },
    {
      w: 8, h: 5, x: 0, y: 21, i: 'orderHistory', minW: 6, minH: 4,
    },
    {
      w: 8, h: 0, x: 0, y: 0, i: 'marketsBar', minW: 8, minH: 0, maxW: 8, maxH: 0,
    }
  ],
  // the dimensions here are not important
  defaultLayout: [
    { i: 'chart' },
    { i: 'recentTrades' },
    { i: 'orderBook' },
    { i: 'orderManager' },
    { i: 'orderHistory' },
    { i: 'marketsBar' },
  ],
  gridConfig: {
    cols: { xl: 180, lg: 180, md: 180, sm: 12, xs: 8, },
    breakpoints: { xl: 1920, lg: 1536, md: 1280, sm: 960, xs: 0, }
  }
}

export default baseLayouts
