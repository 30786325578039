import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { WsStateProps, WsState, WebSocketActionTypes } from './types'

export const DefaultInitialState:
  Record.Factory<WsStateProps> = Record<WsStateProps>({
    isConnected: false,
    connectedMarketName: '',
  })

const defaultInitialState: WsState = new DefaultInitialState()

export const wsReducer = (
  state: WsState = defaultInitialState,
  action: AnyAction,
): WsState => {
  switch (action.type) {
    case WebSocketActionTypes.STATUS_CHANGE: {
      return state.set('isConnected', action.payload)
    }
    case WebSocketActionTypes.DISCONNECT:
    case WebSocketActionTypes.CONNECT:
    default:
      return state
  }
}
