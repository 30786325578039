
import { useMemo } from 'react'

import { getGroupedInboxNotifications, getTotalNewCount } from 'js/state/modules/alerts/selectors'
import { Section } from 'js/utils/alerts'

import useSelect from './useSelect'

export type DisplaySection = Section | 'All'

export interface TabDisplay {
  section: DisplaySection
  label: string
}
const useGetInboxTabDisplays = (): TabDisplay[] => {
  const groupedInboxNotifications = useSelect(getGroupedInboxNotifications())
  const totalNewCount = useSelect(getTotalNewCount())
  const tabs = useMemo(() => {
    const tabs: TabDisplay[] = []
    Object.entries(groupedInboxNotifications).forEach(([section, notifDetails]) => {
      const { counts } = notifDetails
      const newCount = counts.new
      const tabsCount = newCount > 99 ? '99+' : newCount
      tabs.push({ section: section as Section, label: `${section.toString()} ${newCount > 0 ? `(${tabsCount})` : ''}` })
    })
    tabs.unshift({ section: 'All', label: `All ${totalNewCount > 0 ? `(${totalNewCount})` : ''}` })
    return tabs
  }, [groupedInboxNotifications, totalNewCount])
  return tabs
}

export default useGetInboxTabDisplays
