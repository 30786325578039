import { makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { useCommonStyles } from "js/utils"

const StakeFAQFallback: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  return (
    <div className={classes.root}>
      <div className={clsx(commonClasses.flexColumn, classes.container)}>
        <LoadingSkeleton width={300} paddingBottom={48} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton />
      </div>
    </div>

  )
}


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  container: {
    backgroundColor: theme.palette.background.primary,
    padding: theme.spacing(3, 4),
    minHeight: '793px',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.background.base,
      padding: theme.spacing(1.25, 1.5, 2),
      minHeight: '487px',
    },
  },
}))

export default StakeFAQFallback
