import { RecordOf } from "immutable"

import { OTCQuote, OTCRfq } from "types/otc"

export const OTCActionTypes = {
  SET_RFQS: '@otc/SET_RFQS',
  SET_QUOTES_BY_RFQ: '@otc/SET_QUOTES_BY_RFQ',
  GET_RFQS: '@otc/GET_RFQS',
  GET_QUOTES_BY_RFQ: '@otc/GET_QUOTES_BY_RFQ',
  RESET_QUOTES_BY_RFQ: '@otc/RESET_QUOTES_BY_RFQ',
  SET_SELL_COINS: '@otc/SET_SELL_COINS',
  GET_SELL_COINS: '@otc/GET_SELL_COINS',
  RESET_SELL_COINS: '@otc/RESET_SELL_COINS',
}

export interface OTCStateProps {
  rfqs: OTCRfq[]
  quotes: OTCQuote[]
  sellCoins: string[]
}

export type OTCState = RecordOf<OTCStateProps>
