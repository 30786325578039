import BigNumber from 'bignumber.js'
import { CarbonSDK } from 'carbon-js-sdk'

import { AdjustedBalance } from 'js/state/modules/walletBalance/types'

import { BN_ZERO } from './number'
import { SimpleMap } from './types'

export function getAvailTokenDenom(
  balances: SimpleMap<AdjustedBalance>,
  tokenPrefDenoms: string[],
  sdk: CarbonSDK | undefined,
  feeType: string | string[],
): string {
  let res = 'swth'
  for (const denom of tokenPrefDenoms) {
    const networkFee = getTotalFee(sdk, feeType, denom)
    const { available = BN_ZERO, feeReserved = BN_ZERO } = balances[denom] ?? {}
    const availableBeforeReservedFees = available.plus(feeReserved)
    if (availableBeforeReservedFees.gt(0)) {
      res = denom
      if (availableBeforeReservedFees.gt(networkFee)) {
        break
      }
    }
  }
  return res
}

export function getTotalFee(sdk: CarbonSDK | undefined, feeType: string | string[], feeDenom: string) {
  const newFeeType = Array.isArray(feeType) ? feeType : [feeType]
  const tokenDp = sdk?.token?.getDecimals(feeDenom) ?? 0
  return newFeeType.reduce((prev: BigNumber, fee: string) => {
    const currentFee = (sdk?.gasFee?.getFee(fee, feeDenom) ?? BN_ZERO).shiftedBy(-tokenDp)
    return prev.plus(currentFee)
  }, BN_ZERO)
}
