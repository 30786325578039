import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { startCountdown } from 'js/state/modules/loadingTask/actions'
import { RootState } from 'js/state/modules/rootReducer'

import LoadingIcon, { LoadingIconProps } from '../LoadingIcon'

/** LOADING ICON WRAPPER FOR BUTTON COMPONENT ONLY: DO NOT CALL FROM OTHER COMPONENTS */

const LoadingButtonIndicator: React.FC<LoadingIconProps> = (props: LoadingIconProps) => {
  const { ...rest } = props
  const countdown = useSelector<RootState, Boolean>((store) => store.layout.countdown)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => { // on component unmount
      if (countdown) {
        dispatch(startCountdown(false))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LoadingIcon {...rest} />
  )
}

export default LoadingButtonIndicator
