import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { startStakeSaga } from 'js/state/modules/stake/actions'

interface Props extends React.HTMLAttributes<HTMLDivElement> {

}
const StartStakeSaga: React.FC<Props> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Disabled fee tiers start stake saga as it is spamming everytime user changes page.
    dispatch(startStakeSaga())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}


export default StartStakeSaga
