import { makeStyles, Theme } from "@material-ui/core"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"

const GLPFallback: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <LoadingSkeleton width={180} />
        <br /><br />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton />
      </div>
    </div>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: '30rem',
    width: '100%',
    margin: '3rem auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'unset',
      padding: theme.spacing(2, 0),
      margin: theme.spacing(0.5, 0),
    },
  },
  wrapper: {
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.background.primary,
    height: '684px',
  },
}))

export default GLPFallback