const props = {
  MuiIconButton: {
    disableRipple: true,
  },
  MuiUseMediaQuery: {
    noSsr: true,
  },
}

export default props
