import { WalletBalanceField } from 'js/state/modules/walletBalance/types'
import { BN_ZERO } from 'js/utils/number'

import useSelect from './useSelect'

const useBalance = (denom: string, part: WalletBalanceField = 'available') => {
  const value = useSelect(state => state.walletBalance.balances[denom]?.[part] ?? BN_ZERO, (lhs, rhs) => lhs.eq(rhs))
  return value
}
export default useBalance
