import BigNumber from "bignumber.js"

import { LootBoxType, WheelItem } from "js/constants/demexPoints"

import { LootBoxes } from "assets/demexPoints"

import { BN_MILLION, toShorterNum } from "./number"

export const generateWheelItems = (lootBoxTypes: LootBoxType[]): WheelItem[] => {
  return lootBoxTypes.map((type, index) => ({
    id: type + index,
    type: type,
    imgSrc: LootBoxes[type].iconSrc,
    label: LootBoxes[type].label,
    imgAlt: LootBoxes[type].label + "-loot-box",
  }))
}

export const formatDemexPoints = (points: BigNumber, noDp?: boolean) => {
  if (points.lt(1000)) {
    return `${points.toFormat(0)}`
  }
  if (points.lt(BN_MILLION) && noDp) {
    return `${points.dividedBy(1000).toFormat(0)}k`
  }
  if (points.gte(1000) && points.lt(10000)) {
    return `${points.dividedBy(1000).toFormat(2)}k`
  }
  if (points.gte(10000) && points.lt(100000)) {
    return `${points.dividedBy(1000).toFormat(1)}k`
  }
  if (points.gte(100000) && points.lt(BN_MILLION)) {
    return `${points.dividedBy(1000).toFormat(0)}k`
  }
  return toShorterNum(points, false, undefined, undefined, 0)
}
