import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { SHIFT_DECIMALS } from 'js/constants/assets'
import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { getMarket } from 'js/state/modules/exchange/selectors'
import { getCurrentMarketOpenPosition } from 'js/state/modules/history/selectors'
import { getCurrentMarketMarkPrice } from 'js/state/modules/marketStats/selectors'
import { getInitialMarginFraction, getRequiredMM } from 'js/utils/liquidations'
import { getAdjustedMarginVals } from 'js/utils/markets'
import { shiftByBaseDp, unshiftByDiffDp, shiftByQuoteDp } from 'js/utils/order'

/**
 * Returns current position values (adjusted), as well as the following stats
 *  - initial margin fraction
 *  - max leverage
 *  - unrealized pnl (from current position)
 *  - initial margin base, risk step size and initial margin step
 *  - maintenance margin
*/
const useCurrentMarketPositionStats = () => {
  const currentOpenPosition = useSelector(getCurrentMarketOpenPosition)
  const market = useSelector(getMarket)
  const markPrice = useSelector(getCurrentMarketMarkPrice)
  const sdk = useSelector(getCarbonSDK)

  const {
    position,
    unrealizedPnl,
    initialMarginFraction,
    maxLeverage,
    marginVals,
    maintenanceMargin,
  } = useMemo(() => {
    // Lots adjustment
    const adjustedLots = shiftByBaseDp(market, new BigNumber(currentOpenPosition?.lots ?? '0'))

    // Max lots adjustment
    const adjustedMaxLots = shiftByBaseDp(market, new BigNumber(currentOpenPosition?.max_lots ?? '0'))

    // Avg entry price adjustment
    const adjustedEntryPrice = unshiftByDiffDp(market, new BigNumber(currentOpenPosition?.avg_entry_price ?? '0'))

    // Avg exit price adjustment
    const adjustedExitPrice = unshiftByDiffDp(market, new BigNumber(currentOpenPosition?.avg_exit_price ?? '0'))

    // Realized pnl adjustment
    const realizedPnlBN = shiftByQuoteDp(market, new BigNumber(currentOpenPosition?.realized_pnl ?? '0'))

    // Total fee amount adjustment
    const totalFeeAmtBN = shiftByQuoteDp(market, new BigNumber(currentOpenPosition?.total_fee_amount ?? '0'))

    // Avg allocated amount adjustment
    const avgAllocatedAdjusted = shiftByQuoteDp(market, new BigNumber(currentOpenPosition?.avg_allocated_margin ?? '0'))

    // Allocated amount adjustment
    const allocatedAmtAdjusted = shiftByQuoteDp(market, new BigNumber(currentOpenPosition?.allocated_margin ?? '0'))

    // Unrealized PNL (of current position)
    const unrealizedPnl: BigNumber = markPrice.times(adjustedLots).minus(adjustedEntryPrice.times(adjustedLots))

    // Initial Margin Fraction (of market)
    const { initialMarginBase, initialMarginStep, riskStepSize } = getAdjustedMarginVals(market, sdk)
    const initialMarginFraction = getInitialMarginFraction(adjustedLots, initialMarginBase, riskStepSize, initialMarginStep)

    // Max Leverage
    const maxLeverage = initialMarginFraction.isZero() ? new BigNumber(0) : new BigNumber(1).div(initialMarginFraction)

    // Maintenance Margin
    const mmr = new BigNumber(market?.maintenanceMarginRatio ?? '0').shiftedBy(-SHIFT_DECIMALS)
    const maintenanceMargin = getRequiredMM(initialMarginFraction, mmr)

    const position = {
      ...currentOpenPosition,
      realized_pnl: realizedPnlBN,
      max_lots: adjustedMaxLots,
      total_fee_amount: totalFeeAmtBN,
      avg_allocated_margin: avgAllocatedAdjusted,
      avg_entry_price: adjustedEntryPrice,
      avg_exit_price: adjustedExitPrice,
      allocated_margin: allocatedAmtAdjusted,
      lots: adjustedLots,
    }

    return {
      position,
      unrealizedPnl,
      initialMarginFraction,
      maxLeverage,
      marginVals: {
        initialMarginBase, initialMarginStep, riskStepSize,
      },
      maintenanceMargin,
    }
  }, [currentOpenPosition, sdk, market, markPrice])

  return {
    position,
    unrealizedPnl,
    initialMarginFraction,
    maxLeverage,
    marginVals,
    maintenanceMargin,
  }
}
export default useCurrentMarketPositionStats
