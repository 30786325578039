import { Theme } from '@material-ui/core'

import { FlavorableOverrides } from './types'

const MuiTableCell: FlavorableOverrides = (theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
})

export default MuiTableCell
