import { Network } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved

import { AlertsApis } from 'js/constants/alerts'
import { ApiError, ApiErrorResponse, ApiResponse, HttpMethod } from 'js/constants/api'

import { sendAlertsApiRequest } from './api'

import { SimpleMap } from '../types'

export enum VerificationType {
  Email = 'email',
  Telegram = 'telegram'
}

export interface TriggerVerification {
  type: VerificationType
}


export type EmailDialogActionType = 'add' | 'edit' | 'remove'

export type TelegramDialogActionType = 'add' | 'remove'

export interface UpdateEmailAction {
  type: EmailDialogActionType
  email: string
}

export interface UpdateTelegramAction {
  type: 'remove' // adding of telegram id is done through bot, only trigger verification is done on demex
  botChatId: string
}

export interface VerificationRequest {
  type: VerificationType
  token: string
}

export interface VerificationResponse {
  verifiedAt: number
}

export interface NewVerificationRequest {
  type: VerificationType
}

export interface NewEmailVerificationRequest extends NewVerificationRequest {
  extras: {
    origin: string
  }
}
export interface NewVerificationResponse {
  expiresAt: Date
}

export interface NewTelegramVerificationResponse extends NewVerificationResponse {
  token: string
}
export interface VerificationStatus {
  verifiedAt: Date | null
  createdAt: Date | null
  expiresAt: Date | null
  // only valid for telegram
  token?: string
}

export interface UpdateVerificationStatus {
  type: VerificationType
  update: {
    verifiedAt?: Date | null
    createdAt?: Date | null
    expiresAt?: Date | null
    // only valid for telegram
    token?: string
  }
}

export type VerificationStatusResponse = SimpleMap<VerificationStatus>

export interface VerificationError extends ApiError {
  errors: {
    reason: string
  }
}
export enum VerificationErrorReason {
  Expired = 'expired',
  Verified = 'verified',
}

export enum VerificationState {
  Verified = 'verified',
  Expired = 'expired',
  Invalid = 'invalid',
}

export const triggerNewVerification = async (network: Network, accessToken: string, body: NewVerificationRequest): Promise<ApiResponse<NewVerificationResponse> | ApiErrorResponse<VerificationError | ApiError>> => {
  const url = `${AlertsApis[network].external}/verify/new`
  return await sendAlertsApiRequest<NewVerificationResponse, VerificationError | ApiError>(HttpMethod.POST, url, body, accessToken)
}

export const getVerificationDetails = async (network: Network, address: string): Promise<ApiResponse<VerificationStatusResponse> | ApiErrorResponse> => {
  const url = `${AlertsApis[network].external}/verify/details/${address}`
  return await sendAlertsApiRequest<VerificationStatusResponse>(HttpMethod.GET, url)
}

export const verifyToken = async (network: Network, body: VerificationRequest): Promise<ApiResponse<VerificationResponse> | ApiErrorResponse<VerificationError | ApiError>> => {
  const url = `${AlertsApis[network].external}/verify`
  return await sendAlertsApiRequest<VerificationResponse, VerificationError | ApiError>(HttpMethod.POST, url, body)
}
