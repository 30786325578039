import BigNumber from 'bignumber.js'
import { TypeUtils } from 'carbon-js-sdk'
import { RecordOf } from 'immutable'

export const MAXRESULTS = 100
export const RESULTSPERPAGE = 10

export interface LeaderboardStateProps {
  topPerformers: LeaderboardData
  bottomPerformers: LeaderboardData
  leaderboardRankMap: LeaderMap
  filters: LeaderBoardParams
  usernameMap: UsernamesMap | undefined
}

export type LeaderboardState = RecordOf<LeaderboardStateProps>

export const LeaderboardActionTypes = {
  RELOAD_TOP_LEADERBOARD: '@leaderboard/RELOAD_TOP_LEADERBOARD',
  SET_TOP_LEADERBOARD: '@leaderboard/SET_TOP_LEADERBOARD',
  RELOAD_BOTTOM_LEADERBOARD: '@leaderboard/RELOAD_BOTTOM_LEADERBOARD',
  SET_BOTTOM_LEADERBOARD: '@leaderboard/SET_BOTTOM_LEADERBOARD',
  RELOAD_BOTH_LEADERBOARDS: '@leaderboard/RELOAD_BOTH_LEADERBOARDS',
  SET_LEADERBOARD_MAP: '@leaderboard/SET_LEADERBOARD_MAP',
  RELOAD_LEADERBOARD_MAP: '@leaderboard/RELOAD_LEADERBOARD_MAP',
  SET_LEADERBOARD_FILTERS: '@leaderboard/SET_LEADERBOARD_FILTERS',
  SET_USERNAME_MAP: '@leaderboard/SET_USERNAME_MAP',
  QUERY_USERNAME_MAP: '@leaderboard/QUERY_USERNAME_MAP',
  RELOAD_LEADERBOARD_DATA: '@leaderboard/RELOAD_LEADERBOARD_DATA',
}

export interface LeaderboardData {
  count: number
  data: LeaderBoardEntry[] | null
  limit: number
  market: string
  offset: number
  sort: 'ASC' | 'DESC'
  toTime: number
}

export interface LeaderBoardEntry {
  address: string
  totalPnl: BigNumber
  rank: number
  rankChange?: number
}

export enum LeaderBoardDuration {
  H24 = 'last_24_hours',
  D7 = 'last_7_days',
  D30 = 'last_30_days',
  ALL = 'all_time',
}

export interface LeaderBoardParams {
  market: string
  duration: LeaderBoardDuration
}

export interface PnlLoopItem {
  label: string
  unix: number
}

export interface LeaderMap {
  [key: string]: {
    top: LeaderBoardEntry[]
    bottom: LeaderBoardEntry[]
  }
}

export type UsernamesMap = TypeUtils.SimpleMap<string>

export interface TablePagination {
  top: number
  bottom: number
}

export enum LeaderFilter {
  Duration = 'duration',
  Market = 'market',
}

export type FilterKey = LeaderFilter.Duration | LeaderFilter.Market
