import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState, FC, useMemo } from 'react'

import { useCommonStyles } from 'js/utils'

interface CircleCountDownProps {
  expiryAt: Date
  size: number
  stroke: string
  strokeWidth: number
  strokeLinecap?: 'butt' | 'round' | 'square' | 'inherit' | undefined
}

const CircleCountDown: FC<CircleCountDownProps> = ({
  expiryAt,
  size,
  stroke,
  strokeWidth,
  strokeLinecap = 'round',
}) => {
  const styles = useStyles()
  const commonStyles = useCommonStyles()
  const radius = size / 2
  const circumference = size * Math.PI

  const totalMilliseconds = useMemo(() => expiryAt.getTime() - new Date().getTime(), [expiryAt])

  const [countdown, setCountdown] = useState(totalMilliseconds)

  const seconds = (countdown / 1000).toFixed()
  const strokeDashoffset = circumference - (countdown / totalMilliseconds) * circumference

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(expiryAt.getTime() - new Date().getTime())
      } else {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [countdown, expiryAt])

  if (Number(seconds) <= 0) return <React.Fragment></React.Fragment>

  return (
    <div className={clsx(styles.root, commonStyles.flexRow, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter)}>
      <label
        className={clsx(styles.seconds, commonStyles.flexRow, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter)}
        style={{ width: size, height: size, fontSize: size * 3 / 8 }}
      >
        {seconds}
      </label>
      <div className={styles.countDownContainer} style={{ width: size, height: size }}>
        <svg className={styles.svg} width={size} height={size}>
          <circle
            fill="none"
            r={radius}
            cx={radius}
            cy={radius}
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLinecap}
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
          />
        </svg>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative"
  },
  seconds: {
    position: "absolute",
    color: theme.palette.text.primary
  },
  svg: {
    transform: "scale(-1, 1)",
    overflow: "visible"
  },
  countDownContainer: {
    transform: "rotate(90deg)"
  }
}))

export default CircleCountDown