import { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { addBackgroundLoading, removeBackgroundLoading } from 'js/state/modules/loadingTask/actions'
import { uuidv4 } from 'js/utils'
import { handleKeplrErrors, isKeplrError } from 'js/utils/keplr'

import useSelect from './useSelect'

export type AsyncTaskTask<T> = () => Promise<T>
export type AsyncTaskErrorHandler = (error: Error) => Promise<boolean | undefined | void>
export type FinalHandler = (() => void) | null

export type AsyncTaskRunner<T> = (task: AsyncTaskTask<T>, errorHandler?: AsyncTaskErrorHandler, finalHandler?: FinalHandler) => Promise<void>
export type AsyncTaskLoading = boolean
export type AsyncTaskError = Error | null
export type AsyncTaskClearError = () => void

export type AsyncTaskOutput<T> = [
  AsyncTaskRunner<T>,
  AsyncTaskLoading,
  AsyncTaskError,
  AsyncTaskClearError,
]

const parseError = (original: unknown): Error => {
  const error = original
  return error as Error
}

const useAsyncTask = <T>(taskname: string, onError?: (e: Error) => void, showKeplrErrorToast?: boolean, debounceDelay: number = 0): AsyncTaskOutput<T> => {
  const [error, setError] = useState<Error | null>(null)
  const dispatch = useDispatch()
  const sdk = useSelector(getCarbonSDK)
  const history = useHistory()
  const isLoading = useSelect(state => !!state.layout.loadingTasks[taskname])
  const debounceTimeout = useRef<NodeJS.Timeout>()
  const taskUuid = useRef<string>()

  const asyncTaskRunner = useCallback(async (task: AsyncTaskTask<T>, errorHandler?: AsyncTaskErrorHandler, finalHandler?: FinalHandler): Promise<void> => {
    if (typeof task !== 'function') {
      throw new Error('async task not a function')
    }

    setError(null)

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
      if (taskUuid.current) dispatch(removeBackgroundLoading(taskUuid.current))
    }

    taskUuid.current = uuidv4()

    dispatch(addBackgroundLoading(taskname, taskUuid.current))

    debounceTimeout.current = setTimeout(async () => {
      try {
        await task()
      } catch (rawError) {
        let error = parseError(rawError)

        if (isKeplrError(error, sdk)) {
          error = handleKeplrErrors(error, sdk, history, dispatch, showKeplrErrorToast)
        } else if (errorHandler) {
          const handleErrorResult = await errorHandler(error)
          if (handleErrorResult === false) {
            return
          }
        }

        if (onError) {
          onError(error)
          return
        }

        console.debug('unhandled async task error') // eslint-disable-line no-console
        console.debug(rawError) // eslint-disable-line no-console
        setError(error)
      } finally {
        if (taskUuid.current) dispatch(removeBackgroundLoading(taskUuid.current))

        finalHandler?.()
      }
    }, debounceDelay)
  }, [dispatch, taskname, onError, history, sdk, showKeplrErrorToast, debounceDelay])

  const clearError = useCallback(() => setError(null), [])

  return [asyncTaskRunner, isLoading, error, clearError]
}
export default useAsyncTask
