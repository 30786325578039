import { Set } from 'immutable'

import { LoadingTasks, TxLoadingTasks } from 'js/models'

import { Toast } from './types'

import { RootState } from '../rootReducer'

export function getTxLoadingTasks(state: RootState): TxLoadingTasks {
  return state.layout.txLoadingTasks
}
export function getLoadingTasks(state: RootState): LoadingTasks {
  return state.layout.loadingTasks
}

export function getWsInit(state: RootState): Set<string> {
  return state.layout.wsInit
}

export function getToasts(state: RootState): Toast[] {
  return state.layout.toasts
}
