import BigNumber from 'bignumber.js'
import { Carbon, OrderModule, TypeUtils } from 'carbon-js-sdk'
import { RecordOf, Set } from 'immutable'

import { ExchangeMode } from 'js/constants/markets'
import { ChartLayoutType, ChartLayoutsType } from 'js/utils/types'


export interface ExchangeStateProps {
  readonly favouriteMarkets: Set<string>
  readonly hideLayouts: Set<string>
  readonly markets: Market[]
  readonly marketsAliases: TypeUtils.SimpleMap<string>
  readonly marketDisplayNames: TypeUtils.SimpleMap<string>
  readonly market: Market | null
  readonly marketName: string
  readonly layouts: ChartLayoutsType
  readonly layout: ChartLayoutType
  readonly marketSelect: boolean
  readonly marketTypeSelect: MarketTypeTab
  readonly marketSearch: string
  readonly symbolToggle: boolean
  readonly lastMarket: {
    [key: string]: string
  }
  readonly lastSwapMarket: {
    [key: string]: string
  }
  readonly candlesticks: MarketCandlesticks[]
  readonly showLowLiquidity: boolean
  readonly booksObj: BooksObj
  readonly isSimpleMode: boolean
  readonly modeDialogState: ModeDialogState
  tempOrderDetailsDialogState: OrderDetailsDialogState
  readonly searchHistory: TypeUtils.SimpleMap<string[]>
  readonly fundingInterval: number | null
  adjustedMaxFuturesQuantity: BigNumber
  readonly marketLiquidityMap: TypeUtils.SimpleMap<MarketLiquidity>
  readonly marketHistoricalFundingRates: HistoricalFundingRates | null
  readonly historicalFundingRateGraphData: HistoricalFundingRateGraphData[]
  readonly sectionCollapseStatus: TypeUtils.SimpleMap<boolean>
}

export type ExchangeState = RecordOf<ExchangeStateProps>

export const ExchangeActionTypes = {
  ADD_FAVOURITE_MARKET: '@exchange/ADD_FAVOURITE_MARKET',
  CLEAR_HIDE_LAYOUTS: '@exchange/CLEAR_HIDE_LAYOUTS',
  REMOVE_FAVOURITE_MARKET: '@exchange/REMOVE_FAVOURITE_MARKET',
  SET_HIDE_LAYOUTS: '@exchange/SET_HIDE_LAYOUTS',
  SET_MARKET: '@exchange/SET_MARKET',
  SET_MARKET_NAME: '@exchange/SET_MARKET_NAME',
  SET_MARKETS: '@exchange/SET_MARKETS',
  SET_MARKETS_ALIASES: '@exchange/SET_MARKETS_ALIASES',
  SET_MARKET_DISPLAY_NAMES: '@exchange/SET_MARKET_DISPLAY_NAMES',
  SET_LAYOUT: '@exchange/SET_LAYOUT',
  SET_LAYOUTS: '@exchange/SET_LAYOUTS',
  RESET_LAYOUT: '@exchange/RESET_LAYOUT',
  SET_MARKET_TYPE_SELECT: '@exchange/SET_MARKET_TYPE_SELECT',
  SET_MARKET_SELECT_OPEN: '@exchange/SET_MARKET_SELECT_OPEN',
  SET_MARKET_SELECT_CLOSE: '@exchange/SET_MARKET_SELECT_CLOSE',
  SET_MARKET_SEARCH: '@exchange/SET_MARKET_SEARCH',
  SET_SYMBOL_TOGGLE: '@exchange/SET_SYMBOL_TOGGLE',
  SET_LAST_MARKET: '@exchange/SET_LAST_MARKET',
  SET_LAST_SWAP_MARKET: '@exchange/SET_LAST_SWAP_MARKET',
  SET_MARKETS_CANDLESTICKS: '@exchange/SET_MARKETS_CANDLESTICKS',
  RELOAD_MARKETS: '@exchange/RELOAD_MARKETS',
  SET_SHOW_LOW_LIQUIDITY: '@exchange/SET_SHOW_LOW_LIQUIDITY',
  SET_BOOKS_OBJ: '@exchange/SET_BOOKS_OBJ',
  SET_IS_SIMPLE_MODE: '@exchange/SET_IS_SIMPLE_MODE',
  SET_MODE_DIALOG_STATE: '@exchange/SET_MODE_DIALOG_STATE',
  SET_SHOW_MODE_DIALOG: '@exchange/SET_SHOW_MODE_DIALOG',
  SET_TEMP_ORDER_DETAILS_DIALOG_STATE: '@exchange/SET_TEMP_ORDER_DETAILS_DIALOG_STATE',
  SET_SEARCH_HISTORY: '@exchange/SET_SEARCH_HISTORY',
  SET_FUNDING_INTERVAL: '@exchange/SET_FUNDING_INTERVAL',
  SET_MARKET_ORDER_BOOK: '@exchange/SET_MARKET_ORDER_BOOK',
  SYNC_MARKET_ORDER_BOOK: '@exchange/SYNC_MARKET_ORDER_BOOK',
  CALCULATE_ADJUSTED_MAX_FUTURES_QUANTITY: '@exchange/CALCULATE_ADJUSTED_MAX_FUTURES_QUANTITY',
  SET_ADJUSTED_MAX_FUTURES_QUANTITY: '@exchange/SET_ADJUSTED_MAX_FUTURES_QUANTITY',
  SET_MARKETS_LIQUIDITY_MAP: '@exchange/SET_MARKETS_LIQUIDITY_MAP',
  SET_MARKET_HISTORICAL_FUNDING_RATES: '@exchange/SET_MARKET_HISTORICAL_FUNDING_RATES',
  SET_HISTORICAL_FUNDING_RATE_GRAPH_DATA: '@exchange/SET_HISTORICAL_FUNDING_RATE_GRAPH_DATA',
  SET_MARKET_PREFERRED_LEVERAGES: '@exchange/SET_MARKET_PREFERRED_LEVERAGES',
  SET_SECTION_COLLAPSE_STATUS: '@exchange/SET_SECTION_COLLAPSE_STATUS',
  FETCH_HISTORICAL_FUNDING_GRAPH_DATA: '@exchange/FETCH_HISTORICAL_FUNDING_GRAPH_DATA',
}

const MARKET_TABS = ['all', 'spot', 'futures', 'options', 'perpetual', 'favourites'] as const
export type MarketTypeTab = (typeof MARKET_TABS)[number];

export interface Market extends Carbon.Market.Market {
  isNew: boolean
}

export const MarketTabList: MarketTypeTab[] = [
  'perpetual',
  'futures',
  'spot',
  'all',
  // 'options',
  'favourites',
]

export interface TotalStats {
  avgPrice: BigNumber
  total: BigNumber
  totalCurrency: BigNumber
}

export interface MarketCandlesticks {
  market: string
  bars: number[]
}
export interface OpenOrderLevel {
  price: string;
  quantity: string;
}

export interface ExchangeOrderBook {
  market: string;
  asks: OpenOrderLevel[];
  bids: OpenOrderLevel[];
}

export type OrderBookUpdates = TypeUtils.SimpleMap<OrderBookUpdate>

export interface OrderBookUpdate {
  market: string;
  bidsChanges: OpenOrderLevel[];
  asksChanges: OpenOrderLevel[];
}

export type BooksObj = TypeUtils.SimpleMap<ExchangeOrderBook>

export interface OrderDetailsDialogState {
  open: boolean,
  tempOrder?: OrderModule.CreateOrderParams,
  isChart?: boolean,
}

export interface ModeDialogState {
  isOpen: boolean,
  selected: ExchangeMode,
}

export interface MarketLiquidity {
  bidsUsd: BigNumber
  asksUsd: BigNumber
}

export interface HistoricalFundingRates {
  market: string,
  fundingRates: TypeUtils.SimpleMap<BigNumber>,
}

export interface HistoricalFundingRateGraphData {
  hourly: string,
  market: string,
  avgFunding: BigNumber,
}

export const liteModeLocalStorageKey = '@exchange/SET_IS_SIMPLE_MODE_DEFAULT'

export const runFetchHistoricalFundingGraphData = '@exchange/FETCH_HISTORICAL_FUNDING_GRAPH_DATA'