import { Record } from "immutable"
import { AnyAction } from "redux"

import { StatsigActionTypes, StatsigState, StatsigStateProps } from "./types"

export const DefaultInitialState:
  Record.Factory<StatsigStateProps> = Record<StatsigStateProps>({
    statsigClient: undefined,
    statsigExperiments: undefined,
  })

const defaultInitialState: StatsigState = new DefaultInitialState()

export const statsigReducer = (
  state: StatsigState = defaultInitialState,
  action: AnyAction,
): StatsigState => {
  switch (action.type) {
    case StatsigActionTypes.SET_STATSIG_CLIENT:
      return state.set('statsigClient', action.payload)
    case StatsigActionTypes.SET_STATSIG_EXPERIMENTS:
      return state.set('statsigExperiments', action.payload)
    default:
      return state
  }
}