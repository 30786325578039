import Paths from 'js/constants/paths'

import { ReactComponent as Nitron } from 'assets/Nitron.svg'
import { ReactComponent as NitronLiquidations } from 'assets/NitronLiquidations.svg'
export interface NavItemProps {
  label: string
  labelClass?: string
  icon: SVGComponent
  iconType: 'fill' | 'stroke'
  path: string
  state?: any
  showActive?: boolean
  activePaths?: string[]
  checkPendingTransfer?: boolean
  hidden?: boolean
}

const navItems: NavItemProps[] = [
  {
    label: 'Lend, Borrow, Mint',
    icon: Nitron,
    path: Paths.Nitron.Main,
    iconType: 'stroke',
  }, {
    label: 'Nitron Liquidations',
    icon: NitronLiquidations,
    path: Paths.Nitron.Liquidations,
    iconType: 'stroke',
  },
]

export interface NavSection {
  header?: string
  navItems: NavItemProps[]
}

export const navSections: NavSection[] = [
  {
    navItems,
  },
]

// To avoid leaving dust when repaying debt,
// we calculate debt with interest as of 75 seconds later (~+25 blks)
export const offsetSeconds: number = 75

// Values are in %
export const LOW_CAPACITY_THRESHOLD = 50
export const MEDIUM_CAPACITY_THRESHOLD = 75
export const FULL_CAPACITY_THRESHOLD = 100

export const disableCap: number = 1
export const noCap: number = 0
