import { Grid, makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { useDimensions } from "js/hooks"
import { muiBreakpoints } from "js/theme"
import { StyleUtils, useCommonStyles } from "js/utils"

const CDPMainFallback: React.FC = () => {
  const classes = useStyles()
  const dimensions = useDimensions()
  const commonClasses = useCommonStyles()

  const { isDesktop, isMobile } = React.useMemo(() => {
    return {
      isDesktop: dimensions.windowSize.width >= muiBreakpoints.lg,
      isMobile: dimensions.windowSize.width < muiBreakpoints.sm,
    }
  }, [dimensions.windowSize.width])

  return (
    <div className={clsx(commonClasses.justifyContentCenter, classes.root)}>
      <Grid className={classes.grid} container spacing={isDesktop ? 4 : 0} direction="row">
        <Grid item xs={12} lg={9}>
          <div className={clsx(commonClasses.flexColumn, classes.topCards, 'infoCard')}>
            {!isDesktop && <LoadingSkeleton width={isMobile ? undefined : 100} />}
            {!isMobile ? (
              <React.Fragment>
                <LoadingSkeleton />
                <LoadingSkeleton />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <LoadingSkeleton height={12} />
                <LoadingSkeleton height={12} />
                <LoadingSkeleton height={12} />
                <LoadingSkeleton height={12} />
              </React.Fragment>
            )}
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div className={clsx(commonClasses.flexColumn, classes.topCards, 'liquidationCta')}>
            <LoadingSkeleton height={12} />
            <LoadingSkeleton height={16} />
          </div>
        </Grid>
        {isDesktop && (
          <React.Fragment>
            <Grid item xs={12} lg={6}>
              <div className={classes.containerBox} style={{ minHeight: `${451}px` }}>
                <LoadingSkeleton width={180} paddingBottom={48} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton />
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={classes.containerBox} style={{ minHeight: `${451}px` }}>
                <LoadingSkeleton width={180} paddingBottom={48} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton />
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={classes.containerBox} style={{ minHeight: `${451}px` }}>
                <LoadingSkeleton width={180} paddingBottom={48} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton />
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={classes.containerBox} style={{ minHeight: `${451}px` }}>
                <LoadingSkeleton width={180} paddingBottom={48} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton />
              </div>
            </Grid>
          </React.Fragment>
        )}
        {!isDesktop && (
          <Grid item xs={12} lg={6} >
            <div className={classes.containerBox} style={{ minHeight: `${451}px` }}>
              <LoadingSkeleton width={180} paddingBottom={48} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton />
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.base,
    minHeight: 'calc(100vh - 48px - 28px)',
    maxWidth: 'calc(100% - 17rem)',
    width: '100%',
    '@media (min-width: 2560px)': {
      width: '1800px',
      margin: '0 auto',
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 4),
      flex: 1,
      padding: theme.spacing(1, 0),
      maxWidth: `calc(100% - ${theme.spacing(4) * 2}px)`,
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      maxWidth: '100%',
    },
  },
  grid: {
    maxWidth: '100%',
    margin: theme.spacing(2, 'auto'),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down('md')]: {
      gap: '8px',
      margin: theme.spacing(0, 'auto'),
    },
  },
  topCards: {
    gap: '8px',
    padding: theme.spacing(2.5, 4, 3),
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.primary,
    minHeight: '96px',
    '&.infoCard': {
      padding: theme.spacing(2.5, 4, 3),
      background: theme.palette.background.primary,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5, 2),
        minHeight: '221px',
      },
    },
    '&.liquidationCta': {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5, 4),
      },
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(1.5, 2),
      },
    }
  },
  containerBox: {
    ...StyleUtils.scrollBar(theme),
    backgroundColor: theme.palette.background.primary,
    borderRadius: 4,
    padding: theme.spacing(3, 4),
    overflow: 'overlay',
    '@supports not (overflow:overlay)': {
      overflow: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5, 2),
    },
  }
}))

export default CDPMainFallback