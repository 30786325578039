import "./polyfill"

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'// eslint-disable-line import/no-unresolved
import isBetween from 'dayjs/plugin/isBetween'// eslint-disable-line import/no-unresolved
import localeData from 'dayjs/plugin/localeData'// eslint-disable-line import/no-unresolved
import localizedFormat from 'dayjs/plugin/localizedFormat'// eslint-disable-line import/no-unresolved
import relativeTime from 'dayjs/plugin/relativeTime'// eslint-disable-line import/no-unresolved
import timezone from 'dayjs/plugin/timezone'// eslint-disable-line import/no-unresolved
import utc from 'dayjs/plugin/utc'// eslint-disable-line import/no-unresolved
import React from 'react'
import ReactDOM from 'react-dom/client'// eslint-disable-line import/no-unresolved
import { Provider } from 'react-redux'
import 'typeface-roboto'

import App from 'js/components/App/App'
import { store } from 'js/state'
import { setQueuePwaUpdateToast, setShowDemexLoader } from "js/state/modules/app/actions"
import 'js/utils/sentry'

import './index.css'
import * as serviceWorker from './serviceWorker'

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)
dayjs.extend(localeData)
dayjs.extend(duration)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    registration.update().then(() => {
      store.dispatch(setQueuePwaUpdateToast(true))
      window.location.reload()
    })
  },
  onSuccess: () => {
    store.dispatch(setShowDemexLoader(false))
  },
})
