import { useEffect, useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import Paths from 'js/constants/paths'

const exceptions = [
  Paths.Trade + '/:market', 
  Paths.Swap + '/:market', 
  Paths.Lite + '/:market',
  Paths.Account.Balance + '/:tab/:token',
  '/account/history/:tab',
  '/account/transfers/:tab',
  Paths.Vaults.Manage,
  Paths.LaunchVaults.Manage,
  Paths.Pools.AddLiquidity,
  Paths.Pools.RemoveLiquidity,
  Paths.Pools.CommitTokens,
  Paths.Pools.UnlockTokens,
]

function getPageName(pathname: string): string {
  // if match path in exceptions, return matched path (params are matched out)
  // i.e. /trade/ETH-PERP => /trade/:market, /trade/BTC-PERP also => /trade/:market
  for (let p of exceptions) {
    const match = matchPath(pathname, {
      path: p,
      exact: false,
    })
    if (match) {
      return match.path
    }
  }

  return pathname
}

export default function ScrollToTop() {
  const { pathname } = useLocation()
  const pagename = useMemo(() => getPageName(pathname), [pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pagename])

  return null
}
