import { createStyles, makeStyles } from "@material-ui/core"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"

const RewardsHistoryFallback: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.rewardsContainer}>
      <LoadingSkeleton width={300} paddingBottom={48} />
      <LoadingSkeleton paddingBottom={24} />
      <LoadingSkeleton paddingBottom={24} />
      <LoadingSkeleton />
    </div>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  rewardsContainer: {
    borderRadius: theme.shape.borderRadius,
    margin: '2rem',
    backgroundColor: theme.palette.background.primary,
    minHeight: 'calc(100% - 4.8rem)',
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100% - 0.5rem)',
      margin: theme.spacing(1, 0),
      padding: theme.spacing(2),
    }
  },
}))

export default RewardsHistoryFallback