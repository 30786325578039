import { Record } from 'immutable'
import { cloneDeep } from 'lodash'
import { AnyAction } from 'redux'

import { OTCStateProps, OTCState, OTCActionTypes } from "./types"

import { AccountActionTypes } from '../account/types'

const defaultValues: OTCStateProps = {
  rfqs: [],
  quotes: [],
  sellCoins: []
}

export const DefaultInitialState: Record.Factory<OTCStateProps> = Record<OTCStateProps>(cloneDeep(defaultValues))
const defaultInitialState: OTCState = new DefaultInitialState()

export const OTCReducer = (
  state: OTCState = defaultInitialState,
  action: AnyAction,
): OTCState => {
  switch (action.type) {
    case AccountActionTypes.LOGOUT: {
      return state.set('sellCoins', [])
    }
    case OTCActionTypes.SET_RFQS: {
      return state.set('rfqs', action.payload)
    }
    case OTCActionTypes.SET_QUOTES_BY_RFQ: {
      return state.set('quotes', action.payload)
    }
    case OTCActionTypes.RESET_QUOTES_BY_RFQ: {
      return state.set('quotes', [])
    }
    case OTCActionTypes.SET_SELL_COINS: {
      return state.set('sellCoins', action.payload)
    }
    case OTCActionTypes.RESET_SELL_COINS: {
      return state.set('sellCoins', [])
    }
    default:
      return state
  }
}