import { Theme, Tooltip as MuiTooltip, TooltipProps, makeStyles, withStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'

import withProps from 'js/components/Common/withProps'
import { StyleUtils } from 'js/utils/styles'

const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    ...theme.typography.body4,
    lineHeight: '13px',
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.primary,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: StyleUtils.dropShadow(theme),
    boxSizing: 'border-box',
    padding: theme.spacing(1, 1.5),
    maxWidth: '12.5rem',
  },
  tooltipPlacementBottom: {
    [theme.breakpoints.up('xs')]: {
      margin: theme.spacing(0.5, 0),
    },
  },
  tooltipPlacementTop: {
    [theme.breakpoints.up('xs')]: {
      margin: theme.spacing(0.5, 0),
    },
  },
  tooltipPlacementRight: {
    [theme.breakpoints.up('xs')]: {
      margin: theme.spacing(0, 1),
    },
  },
  tooltipPlacementLeft: {
    [theme.breakpoints.up('xs')]: {
      margin: theme.spacing(0, 1),
    },
  },
}))(withProps({ placement: 'top-start' })(MuiTooltip))

interface CommonTooltipProps extends TooltipProps {
  isTextTooltip?: boolean
  tooltipClass?: string
  onHandleOpen?: (ev: any) => void
  mobileLeaveTouchDelay?: number
  disabled?: boolean
}

const CommonTooltip: React.FC<CommonTooltipProps> = (props: CommonTooltipProps) => {
  const { children, title, isTextTooltip, tooltipClass, mobileLeaveTouchDelay = 5000, onHandleOpen, disabled, ...rest } = props
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const isTooltipShown = title !== ''

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = (ev: any) => {
    if (onHandleOpen) {
      onHandleOpen(ev)
    }
    setOpen(true)
  }

  if (disabled) return children

  return (
    <StyledTooltip
      className={clsx({
        [classes.helpCursor]: isTooltipShown,
        [classes.tooltipText]: isTooltipShown && isTextTooltip,
      })}
      classes={{
        tooltip: tooltipClass,
      }}
      interactive
      onOpen={handleTooltipOpen}
      onClose={handleTooltipClose}
      open={open}
      onClick={handleTooltipOpen}
      leaveTouchDelay={isMobile ? mobileLeaveTouchDelay : 1500}
      title={title}
      {...rest}>
      {children}
    </StyledTooltip>
  )
}

const useStyles = makeStyles(() => ({
  tooltipText: {
    borderBottom: '1px dashed',
  },
  helpCursor: {
    cursor: 'help',
  },
}))

export default CommonTooltip
