import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { BridgeActionTypes, BridgeState, BridgeStateProps } from './types'

export const DefaultInitialState: Record.Factory<BridgeStateProps> = Record<BridgeStateProps>({
  externalTokens: {},
  connections: {},
})

const defaultInitialState: BridgeState = new DefaultInitialState()

export const bridgeReducer = (
  state: BridgeState = defaultInitialState,
  action: AnyAction,
): BridgeState => {
  switch (action.type) {
    case BridgeActionTypes.SET_EXTERNAL_TOKENS: {
      return state.set('externalTokens', action.payload)
    }
    case BridgeActionTypes.SET_CONNECTIONS: {
      return state.set('connections', action.payload)
    }
    default:
      return state
  }
}
