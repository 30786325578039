import { Theme } from '@material-ui/core'

import { FlavorableOverrides } from './types'

const MuiIconButton: FlavorableOverrides = (theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

export default MuiIconButton
