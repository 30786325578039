import { Backdrop, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { useSelect } from 'js/hooks'
import { setShowCrossSellingWidget } from 'js/state/modules/crossSelling/actions'
import { getHighlightFocusState } from 'js/state/modules/crossSelling/selectors'


const HighlightFocus: React.FC = () => {
  const classes = useStyles()
  const { enabled } = useSelect(getHighlightFocusState)
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (enabled) {
      dispatch(setShowCrossSellingWidget())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Backdrop
      open={enabled}
      className={classes.highlightFocus}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  highlightFocus: {
    zIndex: 100,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(1px)',
  },
}))


export default HighlightFocus
