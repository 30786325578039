import { Backdrop, BackdropProps, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setFeeToggleClose } from 'js/state/modules/app/actions'
import { getFeeToggle } from 'js/state/modules/app/selectors'
import { StyleUtils } from 'js/utils'

interface GlobalBackdropProps extends Omit<BackdropProps, 'open'> {

}
const GlobalBackdrop: React.FC<GlobalBackdropProps> = (props: GlobalBackdropProps) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const feeToggle = useSelector(getFeeToggle)

  const handleCloseBackdrop = () => {
    if (feeToggle) dispatch(setFeeToggleClose())
  }

  return (
    <Backdrop
      onMouseEnter={handleCloseBackdrop}
      open={feeToggle}
      invisible
      {...rest}
      className={clsx(classes.root, className)}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 101,
    ...StyleUtils.blurOverride(),
  },
}))


export default GlobalBackdrop
