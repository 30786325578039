import { WSResult } from 'carbon-js-sdk'
import { RecordOf } from 'immutable'

export type WsEvent = {
  type: 'status'
  status: boolean
} | {
  type: 'message'
  result: WSResult
}

export interface WSSubscribeRace {
  disconnected?: boolean
  subscribe?: undefined
}

export interface WsStateProps {
  readonly isConnected: boolean
  readonly connectedMarketName: string | null
}

export type WsState = RecordOf<WsStateProps>

export const WebSocketActionTypes = {
  CONNECT: '@webSocket/CONNECT',
  DISCONNECT: '@webSocket/DISCONNECT',

  CONNECT_WITH_MARKET: '@webSocket/CONNECT_WITH_MARKET',
  CONNECT_WITHOUT_MARKET: '@webSocket/CONNECT_WITHOUT_MARKET',

  STATUS_CHANGE: '@webSocket/STATUS_CHANGE',
}
