import { makeStyles } from '@material-ui/core'
import { BigNumber } from 'bignumber.js'
import { CarbonSDK } from 'carbon-js-sdk'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import TextButton from 'js/components/Common/TextButton'
import Paths from 'js/constants/paths'
import useLoading, { LoadingItems } from 'js/hooks/useLoading'
import { isWalletConnected } from 'js/state/modules/account/selectors'
import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { RootState } from 'js/state/modules/rootReducer'
import { checkSelectToken } from 'js/state/modules/walletBalance/actions'
import { formatSmallNumber } from 'js/utils'

import LoadingSkeleton from './LoadingSkeleton/LoadingSkeleton'
import SubscriptText from './SubscriptText'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isIsolation?: boolean
  title: string
  balance: BigNumber | string
  limit?: React.ReactNode
  bnFormatter?: (value: BigNumber) => string | React.ReactNode
  maxTitle: React.ReactNode
  maxButtonClick?: any
  depositMoreDenom?: string
  balanceLoading?: boolean
}

const FormInputWrapper: React.FC<Props> = (props: Props) => {
  const { isIsolation = false, title, balance, bnFormatter, maxTitle, maxButtonClick, depositMoreDenom, children, className, limit, balanceLoading, ...rest } = props
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const sdk = useSelector(getCarbonSDK)
  const tokens = useSelector<RootState, Token[]>((state) => state.app.tokens)
  const isLoggedIn = useSelector(isWalletConnected)

  const isBalanceLoading = useLoading(LoadingItems.balances, LoadingItems.collaterals, LoadingItems.tokenDebts, LoadingItems.tokenSupply, LoadingItems.tokenPrices, LoadingItems.emodeCategories)

  const formatBalance = (value: BigNumber) => {
    const bnValue = bnOrZero(value)
    const formatted = bnValue.eq(0) ? bnValue.toFormat(2) : formatSmallNumber(bnValue, 0, 5, 5)

    return <SubscriptText formatted={formatted} />
  }

  const handleDirectToDeposit = () => {
    if (!depositMoreDenom) return
    history.push(`${Paths.Account.Deposit}/${depositMoreDenom}`)
    const token = tokens.find((tkn) => tkn.denom === depositMoreDenom)
    if (token) {
      dispatch(checkSelectToken(token))
    }
  }

  const { balanceStr, isDepositMore } = React.useMemo(() => {
    const balanceBN = bnOrZero(balance)
    const balanceStr = bnFormatter ? bnFormatter(balanceBN) : formatBalance(balanceBN)

    if (!depositMoreDenom || !sdk) {
      return {
        isDepositMore: false,
        balanceStr,
      }
    }

    const isCdpToken = CarbonSDK.TokenClient.isCdpToken(depositMoreDenom) // check if cdp token
    const isPoolToken = CarbonSDK.TokenClient.isPoolToken(depositMoreDenom) // check if pool token
    const isNativeStableCoin = sdk.token.isNativeStablecoin(depositMoreDenom) // check if USC
    return {
      isDepositMore: !isCdpToken && !isPoolToken && !isNativeStableCoin && balanceBN.eq(0),
      balanceStr,
    }
  }, [depositMoreDenom, sdk, balance, bnFormatter])

  const renderButton = () => {
    if (isDepositMore) {
      return (
        <TextButton
          label="Deposit"
          className={classes.maxButton}
          onClick={handleDirectToDeposit}
        />
      )
    }
    if (typeof maxButtonClick === 'function') {
      return (
        <TextButton
          label="Max"
          className={classes.maxButton}
          onClick={maxButtonClick}
        />
      )
    }
    return null
  }

  return (
    <React.Fragment>
      <div
        className={clsx(classes.inputLabelContainer, className, {[classes.marginTop1p5]: isIsolation})}
        {...rest}
      >
        <div>{title}</div>
        {isLoggedIn && (
          isBalanceLoading ? (
            <LoadingSkeleton height={16} width={180} end={true} />
          ) : (
            <div className={classes.labelItems}>
              {maxTitle}<span>:&nbsp;</span>{balanceStr}{limit && (
                <React.Fragment>
                  &nbsp;/&nbsp;
                  {limit}
                </React.Fragment>
              )}
              {renderButton()}
            </div>
          )
        )}
      </div>
      {children}
    </React.Fragment >
  )
}

const useStyles = makeStyles((theme) => ({
  inputLabelContainer: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '2rem',
    paddingTop: '0.375rem',
  },
  labelItems: {
    ...theme.typography.body3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'right',
    [theme.breakpoints.only('xs')]: {
      alignItems: 'flex-end',
    },
  },
  maxButton: {
    paddingLeft: theme.spacing(0.75),
  },
  dialogTitle: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    margin: theme.spacing(0, 0, 2),
    width: '100%',
  },
  dialogContent: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  dialogActions: {
    justifyContent: 'center',
  },
  dialogButton: {
    width: '100%',
  },
  marginTop1p5: {
    marginTop: '1.5rem !important',
  }
}))


export default FormInputWrapper
