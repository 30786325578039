import React from 'react'

import TextButton from 'js/components/Common/TextButton'
import { StaticLinks } from 'js/constants/externalLinks'
import { AlertBannerVariantType } from 'js/state/modules/app/types'
import { SimpleMap } from 'js/utils'

interface ConnectionError {
  nodeError?: ErrorMessage,
  thirdPartyError?: ErrorMessage
}
interface ErrorMessage {
  navText: string,
  title: string,
  subtitle: string,
  text?: React.ReactNode,
  alertVariant?: AlertBannerVariantType
}

export const connectionErrorMessages: SimpleMap<ConnectionError> = {
  1: {
    thirdPartyError: {
      navText: 'No Internet Connection',
      title: 'No Internet',
      subtitle: 'Please connect to the internet and refresh Demex to try again.',
      alertVariant: 'error',
    },
  },
  2: {
    nodeError: {
      navText: 'Connection Issue',
      title: 'Unable to connect to Demex chain',
      subtitle: 'Demex is currently unable to connect to the Demex chain. All funds remain safe, and you may resume transactions once connectivity is restored.',
      text: (
        <React.Fragment>
          Please check <TextButton href={StaticLinks.Socials.DemexTwitter} target='_blank' label="X" isInline/> or <TextButton href={StaticLinks.Socials.TelegramANN} target='_blank' label="Telegram" isInline/> for updates.
        </React.Fragment>
      ),
      alertVariant: 'error',
    },
  },
  3: {
    nodeError: {
      navText: 'Connection Issue',
      title: 'Offline API Nodes',
      subtitle: 'One of Demex’s off-chain services cannot be reached. All funds remain safe, and you may resume transactions once connectivity is restored.',
      text: (
        <React.Fragment>
          Please check <TextButton href={StaticLinks.Socials.DemexTwitter} target='_blank' label="X" isInline/> or <TextButton href={StaticLinks.Socials.TelegramANN} target='_blank' label="Telegram" isInline/> for updates.
        </React.Fragment>
      ),
      alertVariant: 'warning',
    },
  },
  4: {
    nodeError: {
      navText: 'Degraded Connection',
      title: 'Unable to connect to Demex RPC',
      subtitle: 'This node is experiencing degraded performance. Demex is currently unable to load chain data or broadcast transactions.',
      text: <React.Fragment>Please change your selected Demex API node below.</React.Fragment>,
      alertVariant: 'warning',
    },
  },
  5: {
    nodeError: {
      navText: 'Degraded Connection',
      title: 'Unable to connect to Demex Websocket',
      subtitle: 'This node is experiencing degraded performance. Demex is currently unable to load various trading and blockchain related data.',
      text: (
        <React.Fragment>
          Please try the following:
          <ul>
            <li>Change your selected Demex API node below</li>
            <li>Disable your adblocker</li>
            <li>Turn off your VPN</li>
            <li>Switch your browser to Chrome</li>
          </ul>
          Please check <TextButton href={StaticLinks.Socials.DemexTwitter} target='_blank' label="X" isInline/> or <TextButton href={StaticLinks.Socials.TelegramANN} target='_blank' label="Telegram" isInline/> for updates.
        </React.Fragment>
      ),
      alertVariant: 'warning',
    },
  },
  6: {
    thirdPartyError: {
      navText: 'Issue with 3rd-Party API',
      title: 'Unable to connect to 3rd-party price feed',
      subtitle: 'Notional USD values may be missing. This does not affect trading or your PnL.',
      text: (
        <React.Fragment>
          Please try the following:
          <ul>
            <li>Change your selected Demex API node below</li>
            <li>Disable your adblocker</li>
          </ul>
        </React.Fragment>
      ),
      alertVariant: 'warning',
    },
  },
  7: {
    thirdPartyError: {
      navText: 'Issue with 3rd-Party API',
      title: 'Unable to connect to Hydrogen API',
      subtitle: 'Crosschain transaction history may not be updated. This does not affect deposits and withdrawals.',
      text: <React.Fragment>Your balances and transaction history will be updated once connectivity is restored.</React.Fragment>,
      alertVariant: 'warning',
    },
  },
  8: {
    thirdPartyError: {
      navText: 'Issue with Insights API',
      title: 'Unable to fetch leaderboard data',
      subtitle: 'Leaderboard data may be missing or outdated.',
      text: <React.Fragment>Data will reappear when connectivity is restored.</React.Fragment>,
      alertVariant: 'warning',
    },
  },
  9: {
    thirdPartyError: {
      navText: 'Issue with Referral API',
      title: 'Unable to fetch referral data',
      subtitle: 'Referral data and links may be degraded at the moment.',
      text: <React.Fragment>Please try again later.</React.Fragment>,
      alertVariant: 'warning',
    },
  },
  10: {
    thirdPartyError: {
      navText: 'Issue with 3rd-Party API',
      title: 'Unable to connect to GitHub',
      subtitle: 'Market configuration may be outdated.',
      text: <React.Fragment>Configuration will be updated when connectivity is restored.</React.Fragment>,
      alertVariant: 'warning',
    },
  },
}
