import { TypeUtils } from 'carbon-js-sdk'

import { TokenPrice } from 'types/price'

import { PricingParams, PriceSet } from './types'

import { RootState } from '../rootReducer'

export function getTokenPrices(state: RootState): TypeUtils.SimpleMap<TokenPrice> {
  return state.pricing.prices
}

export function getPricesSetMap(state: RootState): TypeUtils.SimpleMap<PriceSet> {
  return state.pricing.pricesSetMap
}

export function getPricingParams(state: RootState): PricingParams {
  return state.pricing.pricingParams
}
