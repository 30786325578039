import { CircularProgress } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import { useLocation } from "react-router"

import Paths from "js/constants/paths"
import { useCommonStyles } from "js/utils"

import ReferralsFallback from "./ReferralsFallback"
import RewardsHistoryFallback from "./RewardsHistoryFallback"

const PromotionSwitch: React.FC = () => {
  const location = useLocation()
  const commonClasses = useCommonStyles()
  switch (location.pathname) {
    case Paths.Rewards.Referrals: {
      return <ReferralsFallback />
    }
    case Paths.Rewards.RewardsHistory: {
      return <RewardsHistoryFallback />
    }
    default: {
      return (
        <div className={clsx(commonClasses.justifyContentCenter, commonClasses.alignItemsCenter)} style={{ width: '100%', height: '100%' }}>
          <CircularProgress />
        </div>
      )
    }
  }
}

export default PromotionSwitch