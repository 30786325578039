import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { ReactComponent as ArrowLeftGradient } from 'assets/ArrowLeftGradient.svg'
import { ReactComponent as ArrowRightGradient } from 'assets/ArrowRightGradient.svg'

import TextButton from './TextButton'

interface BackButtonProps {
  label?: string
  onClick?: () => void
  boxClass?: string
  labelClass?: string
  svgClass?: string
  isRight?: boolean
}

const BackButton: React.FC<BackButtonProps> = (props: BackButtonProps) => {
  const { label = "Back", onClick, boxClass, labelClass, isRight = false, svgClass } = props
  const classes = useStyles()
  return (
    <TextButton
      label={label}
      onClick={onClick}
      boxClass={clsx(boxClass, classes.backBox)}
      startIcon={!isRight ? ArrowLeftGradient : undefined}
      endIcon={isRight ? ArrowRightGradient: undefined}
      labelClass={clsx(labelClass, classes.backLabel)}
      svgClass={svgClass}
      fillType="fill"
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  backBox: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      '&.start': {
        marginTop: theme.spacing(0.125)
      }
    }
  },
  labelClass: {
    ...theme.typography.h6,
  }
}))

export default BackButton