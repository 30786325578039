import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "js/state/modules/rootReducer"
import { setStatsigExperiments } from "js/state/modules/statsig/actions"
import { getStatsigClient, getStatsigExperiments } from "js/state/modules/statsig/selectors"
import { StatsigExperiment } from "js/state/modules/statsig/types"

import useAsyncTask from "./useAsyncTask"
import useSelect from "./useSelect"

interface UserStatsigExperimentVariable {
  loadingStatsigVariable: boolean
  variable: boolean | undefined
}

export const useStatsigExperiment = (experimentName: string, variableKey: string, experimentCondition?: boolean): UserStatsigExperimentVariable => {
  const dispatch = useDispatch()
  const statsigClient = useSelector(getStatsigClient)
  const statsigExperimentMap = useSelector(getStatsigExperiments)
  const statsigExperiment = useSelect((state: RootState): StatsigExperiment | undefined => state.statsig.statsigExperiments?.[experimentName])
  const [runFetchStatsigExperimentVariation, loadingFetchStatsigExperimentVariation] = useAsyncTask('runFetchStatsigExperimentVariation')
  const variable = useMemo(() => statsigExperiment?.variables?.[variableKey], [statsigExperiment?.variables, variableKey])

  const fetchStatsigExperimentVariation = () => {
    runFetchStatsigExperimentVariation(async () => {
      try {
        if (!statsigClient) throw new Error('statsig client not initialized')
        if (!statsigExperiment) {
          // fetch experiment variable if specified experiment not found
          const assignedVariation = await statsigClient.getExperiment(experimentName)
          const variableValue = assignedVariation.value[variableKey] as boolean | undefined
          if (variableValue !== undefined) {
            const statsigExperimentMapCopy = {
              ...statsigExperimentMap,
              [experimentName]: {
                experimentName,
                variables: {
                  [variableKey]: variableValue
                }
              }
            }
            dispatch(setStatsigExperiments(statsigExperimentMapCopy))
          }
        }
      } catch (err) {
        console.error('Failed to fetch experiment variable:', err)
      }
    })
  }

  useEffect(() => {
    if (statsigClient // statsig initialized
      && experimentCondition // conditions for experiment met e.g. isMobile etc
      && !statsigExperiment // no cached experiment variables for specified experimentName
    ) {
      fetchStatsigExperimentVariation()
    }
    // eslint-disable-next-line
  }, [statsigClient, experimentCondition])

  return {
    loadingStatsigVariable: loadingFetchStatsigExperimentVariation,
    variable,
  }
}