import { makeStyles } from "@material-ui/core"

import { StyleUtils } from "js/utils/styles"

export const useStyles = makeStyles((theme) => ({
  navBar: {
    zIndex: 11,
    position: 'sticky',
    top: '3rem',
    ...StyleUtils.scrollBar(theme),
    width: '100%',
    backgroundColor: theme.palette.background.secondary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(0, 2),
    overflow: 'overlay',
    '@supports not (overflow:overlay)': {
      overflow: 'auto',
    },
  },
  classItem: {
    ...theme.typography.body3,
    fontWeight: 700,
    [theme.breakpoints.up("lg")]: {
      ...theme.typography.body4,
    },
  },
  classListItem: {
    justifyContent: 'center',
    '&:hover': {
      '& div': {
        color: theme.palette.text.primary,
      },
      '&.fill path': {
        fill: theme.palette.text.primary,
      },
      '&.stroke path': {
        stroke: theme.palette.text.primary,
      },
    },
  },
  drawerContainer: {
    paddingTop: '0.25rem',
    minWidth: '17rem',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.primary,
    [theme.breakpoints.down('md')]: {
      display: 'none',
      minWidth: 'unset',
    },
  },
  stickySidebar: {
    position: 'sticky',
    top: '72px', // top bar + drawerContainer padding top + sidebar header margin
  },
  walletLoadingWrapper: {
    margin: theme.spacing(3.5, 2.5),
  },
  divider: {
    backgroundColor: theme.palette.divider,
    margin: '0 1rem',
  },
  svgIcon: {
    width: '24px',
    height: '24px',
    [theme.breakpoints.down('md')]: {
      width: '18px',
      height: '18px',
    },
    '& path': {
      stroke: theme.palette.text.secondary,
    },
  },
  sidebarHeader: {
    margin: '10px 20px',
  },
  sectionHeader: {
    ...theme.typography.body3,
    margin: theme.spacing(2, 2, 0, 2),
    textTransform: 'uppercase',
    color: theme.palette.text.tertiary,
  },
}))
