import { getUsdValue } from 'js/state/modules/walletBalance/selectors'
import { BN_ZERO } from 'js/utils/number'

import useSelect from './useSelect'

const useUsdValue = (denom: string) => {
  const usdValue = useSelect(state => !denom ? BN_ZERO : getUsdValue(denom)(state) ?? BN_ZERO, (lhs, rhs) => lhs.eq(rhs))
  return usdValue
}

export default useUsdValue
