import BigNumber from 'bignumber.js'
import { WSModels } from 'carbon-js-sdk'
import { RecordOf } from 'immutable'

import { BN_ZERO } from 'js/utils/number'
import { SimpleMap } from 'js/utils/types'

export const EMPTY_PRICES: MarketPrices = {
  mark: BN_ZERO,
  index: BN_ZERO,
  last: BN_ZERO,
}

export interface MarketStatsStateProps {
  readonly marketStats: SimpleMap<ModifiedMarketStat>
  readonly marketPrices: MarketPrices | null
}

export interface MarketPrices {
  mark: BigNumber
  index: BigNumber
  last: BigNumber
}

export interface MarketStat {
  day_close: string
  day_high: string
  day_low: string
  day_open: string
  day_quote_volume: string
  day_volume: string
  index_price: string
  last_price: string
  mark_price: string
  market: string
  market_type: string
  open_interest: string
}

export interface ModifiedMarketStat extends WSModels.MarketStat {
  change: string | null
  lastPrice: string | null
  volume: string
}

export type MarketStatsState = RecordOf<MarketStatsStateProps>

export const MarketStatsActionTypes = {
  SET_MARKET_STATS: '@marketStats/SET_MARKET_STATS',
  UPDATE_MARKET_STATS: '@marketStats/UPDATE_MARKET_STATS',
  SYNC_MARKET_STATS: '@marketStats/SYNC_MARKET_STATS',
  CLEAR_MARKET_STATS: '@marketStats/CLEAR_MARKET_STATS',
  UPDATE_MARKET_PRICES: '@marketStats/UPDATE_MARKET_PRICES',
}
