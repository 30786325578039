import { SimpleMap } from 'carbon-js-sdk/lib/util/type'
import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { BN_ZERO } from 'js/utils/number'

import { PerpPoolProps, PerpPoolsActionTypes, SetPerpPoolProps, VaultState, VaultStateProps } from './types'

export const DefaultInitialState:
  Record.Factory<VaultStateProps> = Record<VaultStateProps>({
    perpPools: {},
    launchVaultsPoolId: 0,
    marketsLiquidityUsageMultiplier: [],
    bonusFluoDistributor: {
      bonusTiers: {},
      totalLockedBaseLPAmount: BN_ZERO,
      userLockedBaseLPAmount: BN_ZERO,
      longUnbondingPeriod: 0,
      shortUnbondingPeriod: 0,
      bonusFluoMultiplier: BN_ZERO,
      fluoBaseUnit: 0,
    },
    fluoDistributor: {
      fluoPerSecond: BN_ZERO,
      totalBoostedShares: BN_ZERO,
      accumulatedFluoPerShare: BN_ZERO,
      lastClaimedRewardTime: 0,
      totalStakedLpAmount: BN_ZERO,
      startTime: 0,
      accumulatedFluoPrecision: 0,
      boostPrecision: 0,
      maxBoostPrecision: 0,
      userInfo: {
        amount: BN_ZERO,
        boostMultiplier: BN_ZERO,
        rewardDebt: BN_ZERO,
      },
    },
    fluo: {
      userFluoAmount: BN_ZERO,
      fluoDecimals: 0,
    },
    stFluo: {
      totalFluoAssetDeposited: BN_ZERO,
      totalSupply: BN_ZERO,
      userStFluoAmount: BN_ZERO,
    },
    esFluo: {
      totalStFluo: BN_ZERO,
      userEsFluoAmount: BN_ZERO,
      vestingDuration: 0,
      userUnlockedVestingAmount: BN_ZERO,
      userLastVestUpdateTime: 0,
      userStartVestTime: 0,
      totalSupply: BN_ZERO,
    },
    veFluo: {
      totalStFluo: BN_ZERO,
      userVeFluoAmount: BN_ZERO,
      maxVestingDuration: 0,
      totalSupply: BN_ZERO,
      userVestingSchedule: {
        amount: BN_ZERO,
        startTime: 0,
        endTime: 0,
        releasedAmount: BN_ZERO,
        initialVEAmount: BN_ZERO,
      },
    },
    unbondingFluo: {
      unbondingPeriod: 0,
      userUnbondInfos: [],
      userUnbondedAmount: BN_ZERO,
      userUnbondStartIdx: 0,
    },
    fluoEmitter: {
      fluoPerSecond: 0,
      lastInitiateRedeemTime: 0,
    },
    lp: {
      userLpAmount: BN_ZERO,
    },
    perpPoolNetDeposits: {},
    perpPoolIncentivesContracts: {},
    perpPoolIncentivesUserInfo: {},
    perpPoolRewardDistributorMap: {},
    perpPoolEvmBalanceMap: {},
    perpPoolExpiredIncentivesContracts: {},
    perpPoolExpiredRewardDistributorData: {},
  })

const defaultInitialState: VaultState = new DefaultInitialState()

export const vaultReducer = (
  state: VaultState = defaultInitialState,
  action: AnyAction,
): VaultState => {
  switch (action.type) {
    case PerpPoolsActionTypes.SET_PERP_POOLS: {
      const initialPerpPoolsState = action.payload
      const perpPools: SimpleMap<PerpPoolProps> = {}
      initialPerpPoolsState.forEach(({ id, ...rest }: SetPerpPoolProps) => {
        perpPools[id.toString()] = { ...rest }
      })
      return state.set('perpPools', { ...perpPools })
    }
    case PerpPoolsActionTypes.UPDATE_PERP_POOL: {
      const { payload } = action
      const perpPools = state.get('perpPools')
      if (perpPools[payload.poolId]) {
        const { poolId, ...updates } = payload
        perpPools[payload.poolId] = {
          ...perpPools[payload.poolId],
          ...updates,
          poolInfo: {
            ...perpPools[payload.poolId]?.poolInfo, ...payload.poolInfo,
          },
          vaultPerformanceData: {
            ...perpPools[payload.poolId]?.vaultPerformanceData, ...payload.vaultPerformanceData,
          }
        }
      }
      return state.set('perpPools', { ...perpPools })
    }
    case PerpPoolsActionTypes.SET_PERP_POOL_PERFORMANCE_SNAPSHOT_TIMEFRAME: {
      const { poolId, timeframe } = action.payload
      const perpPools = state.get('perpPools')
      if (perpPools[poolId]) {
        perpPools[poolId] = {
          ...perpPools[poolId],
          performanceSnapshotTimeframe: timeframe,
        }
      }
      return state.set('perpPools', { ...perpPools })
    }
    case PerpPoolsActionTypes.SET_MARKETS_LIQUIDITY_USAGE_MULTIPLIER: {
      return state.set('marketsLiquidityUsageMultiplier', action.payload)
    }
    case PerpPoolsActionTypes.SET_LAUNCH_VAULTS_POOL_ID: {
      return state.set('launchVaultsPoolId', action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_DISTRIBUTOR_USER_STAKED_LP_AMOUNT: {
      return state.setIn(['fluoDistributor', 'userInfo', 'amount'], action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_DISTRIBUTOR_FLUO_PER_SECOND: {
      return state.setIn(['fluoDistributor', 'fluoPerSecond'], action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_DISTRIBUTOR_USER_BOOST_MULTIPLIER: {
      return state.setIn(['fluoDistributor', 'userInfo', 'boostMultiplier'], action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_DISTRIBUTOR_TOTAL_BOOSTED_SHARES: {
      return state.setIn(['fluoDistributor', 'totalBoostedShares'], action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_DISTRIBUTOR_ACCUMULATED_FLUO_PER_SHARE: {
      return state.setIn(['fluoDistributor', 'accumulatedFluoPerShare'], action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_DISTRIBUTOR_LAST_CLAIMED_REWARD_TIME: {
      return state.setIn(['fluoDistributor', 'lastClaimedRewardTime'], action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_DISTRIBUTOR_TOTAL_STAKED_LP_AMOUNT: {
      return state.setIn(['fluoDistributor', 'totalStakedLpAmount'], action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_DISTRIBUTOR_ACCUMULATED_FLUO_PRECISION: {
      return state.setIn(['fluoDistributor', 'accumulatedFluoPrecision'], action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_DISTRIBUTOR_BOOST_PRECISION: {
      return state.setIn(['fluoDistributor', 'boostPrecision'], action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_DISTRIBUTOR_START_TIME: {
      return state.setIn(['fluoDistributor', 'startTime'], action.payload)
    }
    case PerpPoolsActionTypes.SET_USER_REWARD_DEBT: {
      return state.setIn(['fluoDistributor', 'userInfo', 'rewardDebt'], action.payload)
    }
    case PerpPoolsActionTypes.SET_USER_ES_FLUO_AMOUNT: {
      return state.setIn(['esFluo', 'userEsFluoAmount'], action.payload)
    }
    case PerpPoolsActionTypes.SET_ES_FLUO_VESTING_DURATION: {
      return state.setIn(['esFluo', 'vestingDuration'], action.payload)
    }
    case PerpPoolsActionTypes.SET_ES_FLUO_USER_UNLOCKED_VESTING_AMOUNT: {
      return state.setIn(['esFluo', 'userUnlockedVestingAmount'], action.payload)
    }
    case PerpPoolsActionTypes.SET_ES_FLUO_USER_START_VEST_TIME: {
      return state.setIn(['esFluo', 'userStartVestTime'], action.payload)
    }
    case PerpPoolsActionTypes.SET_ES_FLUO_USER_LAST_VEST_UPDATE_TIME: {
      return state.setIn(['esFluo', 'userLastVestUpdateTime'], action.payload)
    }
    case PerpPoolsActionTypes.SET_ES_FLUO_DATA: {
      return state.set('esFluo', action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_DATA: {
      return state.set('fluo', action.payload)
    }
    case PerpPoolsActionTypes.SET_USER_FLUO_AMOUNT: {
      return state.setIn(['fluo', 'userFluoAmount'], action.payload)
    }
    case PerpPoolsActionTypes.SET_USER_VE_FLUO_AMOUNT: {
      return state.setIn(['veFluo', 'userVeFluoAmount'], action.payload)
    }
    case PerpPoolsActionTypes.SET_VE_FLOW_MAX_VESTING_DURATION: {
      return state.setIn(['veFluo', 'maxVestingDuration'], action.payload)
    }
    case PerpPoolsActionTypes.SET_VE_FLOW_USER_VESTING_SCHEDULE: {
      return state.setIn(['veFluo', 'userVestingSchedule'], action.payload)
    }
    case PerpPoolsActionTypes.SET_VE_FLUO_DATA: {
      return state.set('veFluo', action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_DISTRIBUTOR_DATA: {
      return state.set('fluoDistributor', action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_EMITTER_FLUO_PER_SECOND: {
      return state.setIn(['fluoEmitter', 'fluoPerSecond'], action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_EMITTER_LAST_INITIATE_REDEEM_TIME: {
      return state.setIn(['fluoEmitter', 'lastInitiateRedeemTime'], action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_EMITTER_DATA: {
      return state.set('fluoEmitter', action.payload)
    }
    case PerpPoolsActionTypes.SET_ST_FLUO_DATA: {
      return state.set('stFluo', action.payload)
    }
    case PerpPoolsActionTypes.SET_TOTAL_ST_FLUO_IN_ES_FLUO: {
      return state.setIn(['esFluo', 'totalStFluo'], action.payload)
    }
    case PerpPoolsActionTypes.SET_TOTAL_ST_FLUO_IN_VE_FLUO: {
      return state.setIn(['veFluo', 'totalStFluo'], action.payload)
    }
    case PerpPoolsActionTypes.SET_USER_ST_FLUO_AMOUNT: {
      return state.setIn(['stFluo', 'userStFluoAmount'], action.payload)
    }
    case PerpPoolsActionTypes.SET_ST_FLUO_SUPPLY: {
      return state.setIn(['stFluo', 'totalSupply'], action.payload)
    }
    case PerpPoolsActionTypes.SET_ES_FLUO_SUPPLY: {
      return state.setIn(['esFluo', 'totalSupply'], action.payload)
    }
    case PerpPoolsActionTypes.SET_VE_FLUO_SUPPLY: {
      return state.setIn(['veFluo', 'totalSupply'], action.payload)
    }
    case PerpPoolsActionTypes.SET_TOTAL_FLUO_ASSET_DEPOSITED_IN_ST_FLUO: {
      return state.setIn(['stFluo', 'totalFluoAssetDeposited'], action.payload)
    }
    case PerpPoolsActionTypes.SET_FLUO_UNBONDING_PERIOD: {
      return state.setIn(['unbondingFluo', 'unbondingPeriod'], action.payload)
    }
    case PerpPoolsActionTypes.SET_USER_FLUO_UNBOND_INFOS: {
      return state.setIn(['unbondingFluo', 'userUnbondInfos'], action.payload)
    }
    case PerpPoolsActionTypes.SET_USER_FLUO_UNBOND_START_IDX: {
      return state.setIn(['unbondingFluo', 'userUnbondStartIdx'], action.payload)
    }
    case PerpPoolsActionTypes.SET_UNBONDING_FLUO_DATA: {
      return state.set('unbondingFluo', action.payload)
    }
    case PerpPoolsActionTypes.SET_BONUS_FLUO_DISTRIBUTOR_DATA: {
      return state.set('bonusFluoDistributor', action.payload)
    }
    case PerpPoolsActionTypes.SET_BONUS_FLUO_USER_LOCKED_BASE_LP_AMOUNT: {
      return state.setIn(['bonusFluoDistributor', 'userLockedBaseLPAmount'], action.payload)
    }
    case PerpPoolsActionTypes.SET_BONUS_FLUO_TOTAL_LOCKED_BASE_LP_AMOUNT: {
      return state.setIn(['bonusFluoDistributor', 'totalLockedBaseLPAmount'], action.payload)
    }
    case PerpPoolsActionTypes.SET_BONUS_FLUO_BONUS_TIERS: {
      return state.setIn(['bonusFluoDistributor', 'bonusTiers'], { ...action.payload })
    }
    case PerpPoolsActionTypes.SET_LP_DATA: {
      return state.set('lp', action.payload)
    }
    case PerpPoolsActionTypes.SET_USER_LP_AMOUNT: {
      return state.setIn(['lp', 'userLpAmount'], action.payload)
    }
    case PerpPoolsActionTypes.RELOAD_PERP_POOL_NET_DEPOSITS: {
      return state.set('perpPoolNetDeposits', {})
    }
    case PerpPoolsActionTypes.SET_PERP_POOL_NET_DEPOSITS: {
      return state.set('perpPoolNetDeposits', action.payload)
    }
    case PerpPoolsActionTypes.SET_PERP_POOL_INCENTIVES_CONTRACTS: {
      return state.set('perpPoolIncentivesContracts', action.payload)
    }
    case PerpPoolsActionTypes.SET_PLP_EVM_BALANCE_MAP: {
      return state.set('perpPoolEvmBalanceMap', action.payload)
    }
    case PerpPoolsActionTypes.SET_REWARD_DISTRIBUTOR_DATA_MAP: {
      return state.set('perpPoolRewardDistributorMap', action.payload)
    }
    case PerpPoolsActionTypes.SET_PLP_INCENTIVES_USER_INFO: {
      return state.set('perpPoolIncentivesUserInfo', action.payload)
    }
    case PerpPoolsActionTypes.SET_PERP_POOL_INCENTIVES_EXPIRED_CONTRACTS: {
      return state.set('perpPoolExpiredIncentivesContracts', action.payload)
    }
    case PerpPoolsActionTypes.SET_EXPIRED_REWARD_DISTRIBUTOR_DATA: {
      return state.set('perpPoolExpiredRewardDistributorData', action.payload)
    }
    default:
      return state
  }
}
