import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { setUrlReferraId } from 'js/state/modules/referrals/actions'

const ReferralParamListener: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    const url = location.search.replace(/^\?/, '').split('&')
    const referralParam = url.filter((param) => {
      return param.startsWith('referrer=')
    })
    const referralIdFromUrl = referralParam[0]?.substring(9)
    const referralId = referralIdFromUrl ?? sessionStorage.getItem('urlReferralId')

    if (referralId) {
      dispatch(setUrlReferraId(referralId))
    }

    if (referralIdFromUrl) {
      sessionStorage.setItem('urlReferralId', referralIdFromUrl)
    }
  }, [dispatch, location.search])

  return null
}


export default ReferralParamListener
