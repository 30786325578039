import { makeStyles, Theme, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { Dropdown, SvgIcon, TypographyLabel } from 'js/components/Common'
import { useDropdownHandler, useTaskSubscriber } from 'js/hooks'
import { getConnectionError } from 'js/state/modules/account/selectors'
import { useCommonStyles } from 'js/utils'

import { ReactComponent as NoConnection } from 'assets/NoConnection.svg'

import { connectionErrorMessages } from '../ErrorMessages'

interface Props {
}

const InternetConnectionDropdown: React.FC<Props> = (props: Props): ReactElement<Props> => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const theme = useTheme()
  const { errorPriority } = useSelector(getConnectionError)
  const [loadingFetchNodes] = useTaskSubscriber('fetchNodes')
  const [loadingThirdParty] = useTaskSubscriber('ThirdPartyAPI')
  const [isDropdownOpen, handleDropdownOpen, handleDropdownClose] = useDropdownHandler(false)

  const {
    topbarLabel,
    dropdownTitle,
    dropdownSubtitle,
    dropdownText,
    thirdPartyError,
  } = React.useMemo(() => {
    let topbarLabel = ''
    let dropdownTitle = ''
    let dropdownSubtitle = ''
    let dropdownText
    let thirdPartyError = false

    if (errorPriority) {
      thirdPartyError = errorPriority !== 1
      const errorObj = connectionErrorMessages[errorPriority]?.thirdPartyError
      topbarLabel = errorObj?.navText ?? ''
      dropdownTitle = errorObj?.title ?? ''
      dropdownSubtitle = errorObj?.subtitle ?? ''
      dropdownText = errorObj?.text ?? ''
    }

    return {
      topbarLabel,
      dropdownTitle,
      dropdownSubtitle,
      dropdownText,
      thirdPartyError,
    }
  }, [errorPriority])

  const networkIcon = (
    <div className={commonClasses.alignItemsCenter}>
      <SvgIcon className={clsx(classes.icon, { thirdPartyError })} component={NoConnection} />
      <TypographyLabel variant="body1" color="error" className={clsx(classes.topbarLabel, { thirdPartyError })}>{topbarLabel}</TypographyLabel>
    </div>
  )

  return (
    <React.Fragment>
      {(topbarLabel && !loadingFetchNodes && !loadingThirdParty) && (
        <Dropdown
          dropdownOpen={isDropdownOpen}
          onClick={isDropdownOpen ? handleDropdownClose : handleDropdownOpen}
          onMouseLeave={handleDropdownClose}
          label={networkIcon}
          hasDropdownIcon={false}
          rightDropDownMenu
          overrideLength
          className={classes.dropdown}
          extraLength={25}
          additionalHeight={theme.spacing(3)}
        >
          <TypographyLabel color="error" className={clsx(classes.title, { thirdPartyError })}>{dropdownTitle}</TypographyLabel>
          <TypographyLabel color="textPrimary" className={clsx(classes.subtitle, !!dropdownText && 'withBodyText')}>{dropdownSubtitle}</TypographyLabel>
          <TypographyLabel color="textSecondary" className={classes.text}>{dropdownText}</TypographyLabel>
        </Dropdown>
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    transition: 'all 0.2s ease-in-out',
    borderRadius: 4,
    width: '15rem',
    padding: theme.spacing(1.5),
    zIndex: 1,
    '&.rightDropDownMenu': {
      overflow: 'hidden',
    }
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: theme.spacing(0.75),
    padding: theme.spacing(0.25, 0),
    '@media (min-width: 960px) and (max-width: 1056px)': {
      margin: theme.spacing(0, 2.5),
    },
    '& path': {
      fill: theme.palette.error.main,
    },
    '&.thirdPartyError': {
      '& path': {
        fill: theme.palette.warning.main,
      },
    },
  },
  topbarLabel: {
    color: theme.palette.error.main,
    '&.thirdPartyError': {
      color: theme.palette.warning.main,
    },
  },
  title: {
    ...theme.typography.body3,
    fontWeight: 700,
    marginBottom: theme.spacing(0.5),
    '&.thirdPartyError': {
      color: theme.palette.warning.main,
    },
  },
  subtitle: {
    ...theme.typography.body4,
    lineHeight: '13px',
    '&.withBodyText': {
      marginBottom: theme.spacing(1),
    }
  },
  text: {
    ...theme.typography.body4,
    lineHeight: '13px',
    '& ul': {
      margin: 0,
      paddingLeft: '1em',
    }
  },
}))

export default InternetConnectionDropdown
