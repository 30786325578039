import { ConsensusParams } from '@cosmjs/tendermint-rpc' // eslint-disable-next-line import/no-unresolved
import { ValidatorUpdate } from '@cosmjs/tendermint-rpc/build/tendermint34/responses' // eslint-disable-next-line import/no-unresolved
import { Event } from '@cosmjs/tendermint-rpc/build/tendermint37'
import BigNumber from 'bignumber.js'
import { TxData } from 'carbon-js-sdk'

import { usdGroupedToken } from 'js/constants/assets'

import { SortDirection } from './table'

export type QueryPromiseTimeoutErr = 'query_timeout_error_demex'

export const queryTimeoutMsg: QueryPromiseTimeoutErr = 'query_timeout_error_demex'

type QueryPromiseReturn<T> = T | QueryPromiseTimeoutErr

export function queryResponse<T = unknown>(queryPromise: Promise<T>, duration: number = 15000): Promise<QueryPromiseReturn<T>> {
  const timeoutRace = new Promise((resolve) => setTimeout(resolve, duration, queryTimeoutMsg))
  return Promise.race([queryPromise, timeoutRace]).then((value: any) => (value as QueryPromiseReturn<T>))
}

export class OperationTimeoutError extends Error {
}

export interface TimedTaskOpts {
  durationInMs?: number
  abortMessage?: string
}

export const runTimedTask = <T>(task: (signal: AbortSignal) => Promise<T>, opts: TimedTaskOpts = {}): Promise<T> => {
  const {
    durationInMs = 5000,
    abortMessage = 'Operation timed out',
  } = opts

  return new Promise((resolve, reject) => {
    const controller = new AbortController()

    // setup new abort controller, cleanup upon abort signal received.
    const abortListener = () => {
      controller.signal.removeEventListener('abort', abortListener)
      reject(new OperationTimeoutError(abortMessage))
    }
    controller.signal.addEventListener('abort', abortListener)

    // emit 'abort' to controller.signal after durationInMs.
    const timeout = setTimeout(() => controller.abort(), durationInMs)

    // propogate return type and error
    task(controller.signal)
      .then(resolve)
      .catch(reject)

      // clear timeout when either resolved or rejected
      .finally(() => clearTimeout(timeout))
  })
}

export const getErrorMessage = (error: unknown): string | undefined => {
  if (!error) return undefined
  if (typeof error === 'object' && 'message' in error && typeof error.message === 'string') return error.message
  if (typeof error === 'string') return error
  return JSON.stringify(error)
}

export const isMultiChainDenom = (denom: string | undefined) => {
  if (!denom) return false
  return [usdGroupedToken, 'swth'].includes(denom)
}

export const clickElement = (element?: Element | null) => {
  element?.dispatchEvent(new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false
  }))
}


// Reference from cosmos-sdk v0.50 upgrade because cosmjs hasn't have a version yet
export interface ResultBlockResults {
  readonly height: number
  readonly txs_results: readonly TxData[]
  readonly validator_updates: readonly ValidatorUpdate[]
  readonly consensus_param_updates?: ConsensusParams
  readonly finalize_block_events: readonly Event[]
  readonly app_hash: Uint8Array | null
}

// Temporary workaround as cosmjs does not have an updated version yet for cosmos-sdk v0.50
export const fetchBlockResults = async (height: number, url: string): Promise<ResultBlockResults> => {
  const fetchResult = await fetch(`${url}/block_results?height=${height}`)
  const { result } = await fetchResult.json()
  return result
}

export const calculateElementHeight = (element?: HTMLElement | null) => {
  if (!element) return 0
  const divStyle = window.getComputedStyle(element)
  const divMarginHeight = parseFloat(divStyle.marginTop) + parseFloat(divStyle.marginBottom)
  const divHeight = element.offsetHeight + divMarginHeight
  return divHeight
}

export const calculateElementWidth = (element?: HTMLElement | null) => {
  if (!element) return 0
  const divStyle = window.getComputedStyle(element)
  const divMarginWidth = parseFloat(divStyle.marginLeft) + parseFloat(divStyle.marginRight)
  const divWidth = element.offsetWidth + divMarginWidth
  return divWidth
}

export const isEqualSets = <T>(setA: Set<T>, setB: Set<T>) => {
  if (setA.size !== setB.size) {
    return false
  }
  for (const a of setA) {
    if (!setB.has(a)) {
      return false
    }
  }
}

export const sortNumbers = (direction: SortDirection, valueA: BigNumber, valueB: BigNumber) => {
  if (valueA.lt(valueB)) {
    return direction === 'desc' ? 1 : -1
  }
  if (valueA.gt(valueB)) {
    return direction === 'desc' ? -1 : 1
  }
  return 0
}

export const sortStrings = (direction: SortDirection, valueA: string, valueB: string) => {
  if (valueA < valueB) {
    return direction === 'desc' ? 1 : -1
  }
  if (valueA > valueB) {
    return direction === 'desc' ? -1 : 1
  }
  return 0
}
