import { Card, Grid, makeStyles, Theme } from "@material-ui/core"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { StyleUtils } from "js/utils"

const ManageVaultsFallback: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={0} className={classes.cardRoot}>
              <LoadingSkeleton width={400} paddingBottom={48} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card elevation={0} className={classes.cardRoot}>
              <LoadingSkeleton width={400} paddingBottom={48} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card elevation={0} className={classes.cardRoot}>
              <LoadingSkeleton width={400} paddingBottom={48} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton />
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...StyleUtils.scrollBar(theme),
    flex: 1,
    background: theme.palette.background.base,
    color: theme.palette.text.primary,
    overflow: 'hidden',
    '@supports not (overflow:overlay)': {
      overflow: 'auto',
    },
    minHeight: '100vh',
  },
  content: {
    marginTop: theme.spacing(2.5),
    maxWidth: '60rem',
    margin: 'auto',
    paddingTop: '42px',
  },
  cardRoot: {
    position: 'relative',
    overflow: 'unset',
    borderRadius: 4,
    padding: theme.spacing(3, 4),
    border: 'none',
    background: theme.palette.background.primary,
    height: '394px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.only('sm')]: {
      height: '442px',
      padding: theme.spacing(3, 3, 4),
    },
    [theme.breakpoints.only('xs')]: {
      height: '462px',
      padding: theme.spacing(2, 3),
    },
    '@media (max-width: 360px)': {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
    },
  },
}))

export default ManageVaultsFallback