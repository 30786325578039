import { FormControlLabel, SvgIcon, Typography, makeStyles } from '@material-ui/core'
import { CarbonSDK } from 'carbon-js-sdk'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IconButton, TypographyLabel } from 'js/components/Common'
import Button from 'js/components/Common/Button'
import NumberField from 'js/components/Common/NumberField'
import V2Switch from 'js/components/Common/V2Switch'
import { SettingsFee } from 'js/constants/assets'
import { FeatureType } from 'js/constants/notification'
import { EvtCategory, useAsyncTask, useEventTrackers, useIndivReservedFees } from 'js/hooks'
import { setEnableReservedFee, setTokenFeeSettings } from 'js/state/modules/account/actions'
import { getEnableSwthFee } from 'js/state/modules/account/selectors'
import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { getReservedSettingsOpen } from 'js/state/modules/orderManager/selectors'
import { useCommonStyles } from 'js/utils'
import { makeCustomToast } from 'js/utils/notifications'
import { StyleUtils } from 'js/utils/styles'

import { ReactComponent as EditPencil } from 'assets/EditPencil.svg'

interface Props {
  asset?: string
}

interface FormState {
  reserveStr: string
  reserve: number
}

interface WarnZero {
  reserve: boolean
}

const defaultWarnZero: WarnZero = {
  reserve: false,
}

const warnZeroMsg = "It's advisable to keep a small amount of tokens to pay network fees later."

const ReserveSettings: React.FC<Props> = (props: Props) => {
  const {asset} = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dispatch = useDispatch()
  const eventTrackers = useEventTrackers()
  const customToast = makeCustomToast({ featureType: FeatureType.WALLET })
  const indivReservedFees: SettingsFee = useIndivReservedFees()
  const [detailSettingsOpen, setDetailSettingsOpen] = useState<boolean>(false)

  const open = useSelector(getReservedSettingsOpen)
  const sdk: CarbonSDK = useSelector(getCarbonSDK) as CarbonSDK

  const address = sdk?.wallet?.bech32Address ?? ''
  const indivEnable = useSelector(getEnableSwthFee) ?? false

  const initialFormState: FormState = {
    reserveStr: indivReservedFees?.reserveUsd.toString(10),
    reserve: indivReservedFees?.reserveUsd.toNumber(),
  }

  const [formState, setFormState] = React.useState<FormState>(initialFormState)
  const [warnZero, setWarnZero] = React.useState<WarnZero>(defaultWarnZero)

  // eslint-disable-next-line
  const [runReserveAlert, loading, error, clearError] = useAsyncTask('runReserveAlert')

  useEffect(() => {
    if (open) {
      setFormState(initialFormState)
      clearError()
      const warnReserve = indivReservedFees?.reserveUsd?.isEqualTo(0)
      setWarnZero({
        reserve: warnReserve,
      })
    }
  }, [open]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setDetailSettingsOpen(false)
  }

  const handleInputBtnClick = (amount: number) => {
    setWarnZero({
      ...warnZero,
      reserve: false,
    })
    setFormState({
      ...formState,
      reserveStr: amount.toString(),
      reserve: amount,
    })
  }

  const onValueChange = () => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value
      let inputAmt = Number(inputValue)
      if (isNaN(inputAmt) || inputAmt < -1) {
        inputAmt = 0
      }
      if (inputAmt === 0) {
        setWarnZero({
          ...warnZero,
          reserve: true,
        })
      } else {
        setWarnZero({
          ...warnZero,
          reserve: false,
        })
      }
      setFormState({
        ...formState,
        reserve: inputAmt,
        reserveStr: inputValue,
      })
    }
  }

  const onEndEditing = () => {
    return () => {
      const newReserve = Math.round(formState.reserve * 10) / 10
      setFormState({
        ...formState,
        reserveStr: newReserve.toString(),
        reserve: newReserve,
      })
    }
  }

  const inputValidate = () => {
    clearError()
    if (formState.reserve < 0) {
      throw new Error('Please enter a positive amount to reserve.')
    }
  }

  // eslint-disable-next-line
  const handleSubmitReserve = () => {
    dispatch(setEnableReservedFee(true, address))
    eventTrackers.sendEvent(EvtCategory.Trade, 'reserve_network_fee', {asset: asset ?? '', enable: indivEnable ? 'true' : 'false', feeAmount: formState.reserveStr})
    runReserveAlert(async () => {
      inputValidate()
      customToast.success({ title: 'Reserve Settings Saved', message: `Always cap reserve at ${formState.reserve.toString()} USD.` })
      dispatch(setTokenFeeSettings({
        reserve: formState.reserve,
      }, address))
      handleClose()
    })
    eventTrackers.sendEvent(EvtCategory.Trade, 'reserve_network_fee')
  }

  const onClickCheckbox = () => {
    if (indivEnable) {
      dispatch(setEnableReservedFee(false, address))
      customToast.success({ title: `Disable Success`, message: `Reserved fee settings disabled` })
    } else {
      setDetailSettingsOpen(!detailSettingsOpen)
    }
  }

  return (
    <React.Fragment>
      <Typography className={classes.descriptionText}>
        {detailSettingsOpen
          ? 'Setting a small token reserve for fees is recommended. This amount will be deducted from your available balance.'
          : 'Enter your preferred amount to be reserved as network fees for Demex transactions. Demex will automatically convert the amount below into the tokens selected for fees at the time of transaction.'
        }
      </Typography>
      <div className={classes.dropdownSubtitleRow}>
        <TypographyLabel className={classes.dropdownSubtitle}>
          Enable Reserved Fees
        </TypographyLabel>
        <FormControlLabel
          classes={{
            root: classes.switch,
          }}
          control={(
            <V2Switch
              className={classes.switch}
              checked={indivEnable || (!indivEnable && detailSettingsOpen)}
              onChange={onClickCheckbox}
            />
          )}
          label=''
        />
      </div>
      {(detailSettingsOpen || indivEnable) && (
        <div className={clsx(classes.dropdownSubtitleRow, classes.reserveAmountBox, { editing: detailSettingsOpen })}>
          <TypographyLabel className={classes.dropdownSubtitle}>
            Amount reserved for fees
          </TypographyLabel>
          <TypographyLabel className={clsx(classes.dropdownSubtitle, 'value')}>
            {indivEnable && (
              <IconButton
                onClick={() => setDetailSettingsOpen(true)}
                icontype="stroke"
              >
                <SvgIcon
                  className={classes.iconClass}
                  component={EditPencil}
                />
              </IconButton>
            )}
            ${indivReservedFees.reserveUsd.toFixed(2)}
          </TypographyLabel>
        </div>
      )}
      {detailSettingsOpen && (
        <React.Fragment>
          <div className={classes.topInput}>
            <NumberField
              value={formState.reserveStr}
              onBlur={onEndEditing()}
              onChange={onValueChange()}
              warningText={warnZero.reserve ? warnZeroMsg : undefined}
              label="Enter your preferred amount to be reserved as fees"
              suffix="USD"
              className={classes.formControl}
            />
            <div className={clsx(classes.buttonGroupRoot, commonClasses.alignItemsCenter)}>
              {[1, 2, 10, 20].map((reserve) => (
                <Button
                  className={clsx(
                    classes.btnGroupBtn,
                    {
                      selected: formState.reserve === reserve,
                    },
                  )}
                  key={`reserve-${reserve}`}
                  onClick={() => handleInputBtnClick(reserve)}
                  baseButtonVariant="outlined"
                  btnsize="regular"
                >
                  {reserve}
                </Button>
              ))}
            </div>
          </div>
          {
            error && (
              <TypographyLabel className={clsx(commonClasses.mt2, classes.mb0p5)} color="error">
                {error.message}
              </TypographyLabel>
            )
          }
          <div className={classes.actionButtons}>
            <Button
              disableElevation
              baseButtonVariant="outlined"
              onClick={handleClose}
              disabled={loading}
              btnsize='regular'
            >
              Cancel
            </Button>
            <Button
              disableElevation
              baseButtonVariant="contained"
              color="primary"
              onClick={handleSubmitReserve}
              disabled={loading}
              btnsize='regular'
            >
              Confirm
            </Button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(1, 3, 2),
  },
  buttonGroupRoot: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      paddingBottom: theme.spacing(2),
      overflowX: 'auto',
    },
  },
  btnGroupBtn: {
    ...theme.typography.body3,
    borderRadius: theme.spacing(0.5),
    flex: '1',
    marginRight: theme.spacing(1.5),
    padding: theme.spacing(1, 4),
    maxWidth: '23%',
    width: '100%',
    height: '2rem !important',
    maxHeight: '2rem !important',
    '&:hover, &.selected, &.selectedAlt': {
      background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(270deg, #482BFF 0%, #007AFF 100%)',
      backgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      WebkitBackgroundClip: 'text',
      border: StyleUtils.borderStyle(theme.palette.primary.main, 1),
    },
    '&.disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.08) !important',
      border: 'none',
      color: 'rgba(255, 255, 255, 0.18) !important',
    },
    '&.selectedAlt.disabled': {
      background: 'unset',
      WebkitTextFillColor: 'unset',
    },
    '&:last-child': {
      marginRight: 'unset',
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: 'unset',
      width: '20%',
      padding: theme.spacing(1),
    },
    '@media (max-width: 360px)': {
      padding: theme.spacing(0.75),
    },
  },
  checkboxLbl: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    '@media (max-width: 360px)': {
      ...theme.typography.body4,
    },
  },
  checkboxRoot: {
    marginLeft: theme.spacing(-1.5),
  },
  formControl: {
    width: '100%',
    '& > label': {
      fontWeight: 700,
    },
  },
  subtitle: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.8rem',
    },
  },
  title: {
    marginBottom: theme.spacing(2.5),
    width: '100%',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputBox: {
    '& input': {
      '&.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.18)',
      },
      '&::placeholder': {
        color: theme.palette.text.disabled,
      },
    },
    '&:hover fieldset': {
      cursor: 'pointer',
      border: StyleUtils.borderStyle(`${theme.palette.text.disabled} !important`, 1),
    },
    '&:focus-within fieldset': {
      border: StyleUtils.borderStyle(`${theme.palette.text.secondary} !important`, 1),
    },
    '&.warning': {
      '& fieldset': {
        borderColor: theme.palette.warning.main,
      },
    },
    '&.disabled': {
      '& fieldset': {
        border: 'none',
        '&:hover': {
          border: 'none',
        },
      },
    },
  },
  selectBox: {
    margin: theme.spacing(0.5, 0, 1),
    '@media (max-width: 360px)': {
      marginBottom: theme.spacing(3),
    },
  },
  topInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    '@media (max-width: 360px)': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1.5),
    },
  },
  paper: {
    '@media (max-width: 360px)': {
      margin: theme.spacing(1.5),
    },
  },
  dropdownSubtitle: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    lineHeight: '1rem',
    fontWeight: 700,
    '&.value': {
      color: theme.palette.text.primary,
      lineHeight: '0.875rem',
    },
  },
  dropdownSubtitleRow: {
    ...theme.typography.body3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(0.5),
    alignItems: 'center',
    color: theme.palette.text.secondary,
    lineHeight: '1rem',
    marginBottom: theme.spacing(2),
    '&:last-child': {
      paddingBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body4,
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  switch: {
    marginLeft: 0,
    marginRight: 0,
  },
  mb0p5: {
    marginBottom: theme.spacing(0.5),
  },
  iconClass: {
    top: '3px',
    position: 'relative',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  reserveAmountBox: {
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.tertiary,
    padding: '6px 12px 0',
    borderRadius: '4px',
    '&.editing': {
      padding: '6px 12px 8px',
    },
    '&.editing > div': {
      position: 'unset',
    },
    '& > div': {
      position: 'relative',
      top: '-2px',
    },
    '& > div:first-child': {
      top: '-4px',
    }
  },
  descriptionText: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
}))

export default ReserveSettings
