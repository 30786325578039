import { Divider, Drawer, Theme, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { Dropdown } from 'js/components/Common'
import Button from 'js/components/Common/Button'
import MenuListItems, { MenuItem } from 'js/components/Common/MenuListItems'
import { useDropdownHandler } from 'js/hooks'

import SliderTopBar from '../components/MenuSlider/SliderTopBar'

interface Props {
  openDrawer?: boolean
  closeDrawer?: () => void
  items: MenuItem[]
  dropdownLabel: string
  isHighlighted: boolean
  isMainTabs?: boolean
}

const MenuTabsDropdown: React.FC<Props> = (props: Props) => {
  const { openDrawer, closeDrawer, items, dropdownLabel, isHighlighted, isMainTabs = false } = props
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [openDropdown, handleDropdownOpen, handleDropdownClose] = useDropdownHandler(false)

  const customClasses = {
    endIcon: classes.endIcon,
    startIcon: classes.startIcon,
  }

  const textLinks = items.map((item: MenuItem) => ({
    ...item,
    customClasses,
  }))

  return isMobile ? (
    <Drawer
      anchor="left"
      open={openDrawer}
      onClose={closeDrawer}
      classes={{
        paper: classes.drawer,
      }}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
      }}
      hideBackdrop={true}
    >
      <div
        className={classes.list}
        role="presentation"
      >
        <div className={classes.innerDiv}>
          <SliderTopBar backButton backButtonOnClick={closeDrawer} />
          <Divider />
          <MenuListItems
            menuListClasses={{ root: classes.menuList }}
            items={textLinks}
            size="large"
            menuClass={classes.menuClass}
          />
        </div>
      </div>
    </Drawer>
  ) : (
    <Dropdown
      label={(
        <div className={classes.tabWrapper}>
          <Button
            baseButtonVariant="text"
            className={clsx(classes.tab, { isHighlighted }, isMainTabs && 'navbarTabs')}
            onClick={() => { }}
            disableRipple
          >
            {dropdownLabel}
          </Button>
          <div className={clsx(classes.activeIndicator, { isHighlighted })} />
        </div>
      )}
      className={classes.dropdown}
      dropdownOpen={openDropdown}
      onClick={openDropdown ? handleDropdownClose : handleDropdownOpen}
      onClose={handleDropdownClose}
      labelClassName={classes.labelClass}
      extraLength={15}
      additionalHeight={theme.spacing(2)}
    >
      <MenuListItems
        menuListClasses={{ root: classes.menuList }}
        items={textLinks}
        size="large"
        menuClass={classes.menuClass}
        onClick={handleDropdownClose}
      />
    </Dropdown>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  tabWrapper: {
    position: 'relative',
    flex: 1,
    height: '100%',
  },
  activeIndicator: {
    height: '2px',
    background: 'transparent',
    borderRadius: '4px',
    width: theme.spacing(3.5),
    margin: '-2px auto 0',
    '&.isHighlighted': {
      background: theme.palette.text.demexSolid,
    },
  },
  drawer: {
    overflowY: 'hidden',
    padding: theme.spacing(2.5),
    background: theme.palette.background.paper,
  },
  dropdown: {
    padding: theme.spacing(1),
    borderRadius: 4,
    minWidth: '10.75rem',
    transition: 'all 0.2s ease-in-out',
  },
  labelClass: {
    margin: theme.spacing(0, 1.25, 0, 2.5),
  },
  innerDiv: {
    position: 'absolute',
    width: '100%',
    overflowY: 'auto',
  },
  list: {
    height: '100%',
    width: '14rem',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
  },
  startIcon: {
    '&.large': {
      maxWidth: '1.375rem',
      maxHeight: '1.375rem',
      minWidth: '1.375rem',
      minHeight: '1.375rem',
    },
  },
  endIcon: {
    maxWidth: '0.75rem',
    maxHeight: '0.75rem',
    minWidth: '0.75rem',
    minHeight: '0.75rem',
  },
  tab: {
    ...theme.typography.body2,
    height: '100%',
    padding: 0,
    borderRadius: 0,
    width: '100%',
    minWidth: 'unset',
    opacity: 1,
    fontWeight: '400 !important',
    color: theme.palette.text.secondary,
    fontSize: '15px !important',
    '&:hover': {
      textShadow: `.5px 0 0 ${theme.palette.text.secondary}`,
      backgroundColor: 'transparent',
    },
    '&.isHighlighted': {
      color: theme.palette.text.primary,
      textShadow: `.5px 0 0 ${theme.palette.text.primary}`,
    },
    '@media (min-width: 960px) and (max-width: 1056px)': {
      padding: theme.spacing(0, 3),
      '&:first-child': {
        paddingLeft: theme.spacing(2.5),
      },
    },
  },
  menuList: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    '& li': {
      padding: theme.spacing(1.5, 1.25, 1.5, 1),
    },
    '& > :nth-last-child(-n+2) div': {
      marginBottom: theme.spacing(0.1),
    },
  },
  menuClass: {
    fontFamily: 'Play',
    fontWeight: 400,
    fontSize: '15px',
    marginBottom: theme.spacing(0.25),
  },
}))

export default MenuTabsDropdown
