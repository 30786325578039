import {
  createStyles, Link, makeStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import SvgIcon from 'js/components/Common/SvgIcon'

interface Props {
  href: string
  component: any
  className?: string
  svgClass?: string
}

const ExternalIconLink: React.FC<Props> = (props: Props) => {
  const {
    className,
    href,
    component,
    svgClass,
  } = props
  const classes = useStyles()

  return (
    <Link
      className={clsx(classes.link, className)}
      href={href}
      target="_blank"
      rel="noopener"
      color="secondary"
    >
      <SvgIcon className={clsx(classes.externalLinkIcon, svgClass)} component={component} />
    </Link>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  externalLinkIcon: {
    fontSize: '1rem',
    color: theme.palette.text.label,
  },
}))

export default ExternalIconLink
