import { Container, Divider, Hidden, Theme, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { muiBreakpoints } from 'js/theme/ThemeV2'
import { useCommonStyles } from "js/utils"

const mobileTabletDimensions = `@media (max-width: ${muiBreakpoints.sm - 1}px)`

interface Props extends React.HTMLAttributes<HTMLDivElement> {

}

const DepositWithdrawFallback: React.FC<Props> = (props: Props) => {
  const { className, children, ...rest } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const theme = useTheme()
  const isMobileTablet = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={classes.boxContainer}>
        <Hidden smDown>
          <Container className={clsx(commonClasses.justifyContentStart, classes.tabsBoxContainer, 'backRow')} maxWidth="md">
            &nbsp;
          </Container>
          <Divider className={classes.divider} />
        </Hidden>

        <Container className={classes.container} maxWidth="md">
          {!isMobileTablet && (
            <div className={classes.gridFiller} />
          )}

          <div className={clsx(classes.contentGrid, commonClasses.fullWidth)}>
            {isMobileTablet && (
              <div className={clsx(commonClasses.justifyContentCenter, commonClasses.alignItemsCenter)}>
                <LoadingSkeleton height={24} width={196} secondaryClass={classes.mobileSecondary} />
              </div>
            )}

            <StepSectionLoading>
              <LoadingSkeleton height={56} secondaryClass={clsx(classes.contentMaxWidth, classes.mobileSecondary)} />
            </StepSectionLoading>

            <StepSectionLoading>
              <LoadingSkeleton height={56} secondaryClass={clsx(classes.contentMaxWidth, classes.mobileSecondary)} />
            </StepSectionLoading>

            <StepSectionLoading isLastNumber>
              <LoadingSkeleton height={56} secondaryClass={clsx(classes.contentMaxWidth, classes.mobileSecondary)} />
            </StepSectionLoading>
          </div>
        </Container>
      </div>
    </div >
  )
}

interface StepSectionLoadingProps extends React.PropsWithChildren {
  isLastNumber?: boolean
}

const StepSectionLoading: React.FC<StepSectionLoadingProps> = (props: StepSectionLoadingProps) => {
  const { children, isLastNumber } = props
  const classes = useStyles()
  const theme = useTheme()
  const isMobileTablet = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <React.Fragment>
      {/* Step Number */}
      {!isMobileTablet && (
        <div className={classes.numberDiv}>
          <div className={classes.stepNumber} />

          {!isLastNumber && (
            <div className={classes.stepPath} />
          )}
        </div>
      )}
      {/* Section Title */}
      {!isMobileTablet && (
        <div className={classes.centeredDiv}>
          <LoadingSkeleton height={24} width={196} />
        </div>
      )}

      {/* Section Contents */}
      <div className={classes.centeredDiv}>
        {children}
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.base,
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(6.5),
    },
  },
  boxContainer: {
    backgroundColor: theme.palette.background.primary,
    margin: theme.spacing(4),
    marginBottom: theme.spacing(10),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0, 0, 5.75),
    minHeight: 'calc(100vh - 48px - 28px - 32px - 64px)', // menu bar, footer, box padding, box margin
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: theme.spacing(1, 0, 0),
    },
  },
  centeredDiv: {
    paddingTop: 3,
  },
  tabsBoxContainer: {
    height: '3.5rem',
    maxWidth: 'unset',
    padding: theme.spacing(0, 4),
    '@media (min-width: 960px) and (max-width: 1190px)': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      flexDirection: 'column',
      maxWidth: '59rem',
    },
    '@media (min-width: 1280px) and (max-width: 1400px)': {
      padding: theme.spacing(0, 3),
      flexDirection: 'column',
      maxWidth: '60rem',
    },
  },
  container: {
    maxWidth: 'unset',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(3, 4),
    gap: theme.spacing(3),
    '@media (min-width: 960px) and (max-width: 1190px)': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      maxWidth: '59rem',
    },
    '@media (min-width: 1280px) and (max-width: 1400px)': {
      padding: theme.spacing(3),
      maxWidth: '60rem',
    },
    [mobileTabletDimensions]: {
      padding: theme.spacing(1.25, 2),      
    },
  },
  contentGrid: {
    display: 'grid',
    gridTemplateColumns: '1.75rem 1fr 4fr',
    gap: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: theme.spacing(2),
    },
  },
  contentMaxWidth: {
    '& > span': {
      maxWidth: '32rem',
      width: '100%',
      [theme.breakpoints.only('sm')]: {
        maxWidth: 'calc(100% - 3rem)',
      },
      [theme.breakpoints.only('xs')]: {
        maxWidth: 'unset',
      },
    },
  },
  skeletonGap: {
    '& > span': {
      marginTop: theme.spacing(2),
    },
  },
  gridFiller: {
    minWidth: '3.25rem',
    maxWidth: '3.25rem',
  },
  mobileSecondary: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'initial',
    },
  },
  numberDiv: {
    width: '1.75rem',
  },
  selectNetworkRoot: {
    border: 'none',
    borderRadius: '4px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      backgroundColor: theme.palette.background.primary,
    },
  },
  stepNumber: {
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: theme.spacing(1),
    position: 'relative',
    height: '1.75rem',
    width: '1.75rem',
  },
  stepPath: {
    backgroundColor: theme.palette.divider,
    position: 'relative',
    height: 'calc(100% + 14px)',
    left: '13px',
    width: 2,
  },
}))

export default DepositWithdrawFallback