import { List, Record } from 'immutable'
import { AnyAction } from 'redux'

import { BN_ZERO, parseNumber } from 'js/utils/number'

import { OrderBookActionTypes, OrderBookState, OrderBookStateProps, OrderBookEntry } from './types'


export const DefaultInitialState:
  Record.Factory<OrderBookStateProps> = Record<OrderBookStateProps>({
    openSells: List<OrderBookEntry>(),
    openBuys: List<OrderBookEntry>(),
  })

const defaultInitialState: OrderBookState = new DefaultInitialState()

export const orderBookReducer = (
  state: OrderBookState = defaultInitialState,
  action: AnyAction,
): OrderBookState => {
  switch (action.type) {
    case OrderBookActionTypes.SYNC_ORDERBOOK: {
      if (action.payload) {
        const incomingOrders: List<any> = List<any>(action.payload)
        const newBuys: List<any> = incomingOrders.filter((order: any) => order.side === 'buy')
        const newSells: List<any> = incomingOrders.filter((order: any) => order.side === 'sell')
        const newBuysState: List<any> = processOrderSide(state.openBuys, newBuys)
        const newSellsState: List<any> = processOrderSide(state.openSells, newSells)
        return state.merge({
          openBuys: newBuysState,
          openSells: newSellsState,
        })
      }
      return state
    }
    case OrderBookActionTypes.CLEAR_ORDERBOOK: {
      return new DefaultInitialState()
    }
    case OrderBookActionTypes.TEST_UPDATE_ORDERBOOK: {
      if (state.openBuys.size === 0) {
        return state
      }
      const newQuantity = action.payload ? '0.3' : '0.4'

      // modify a buy order's quantity
      const newOpenBuys = List<any>(state.openBuys.update(0, (v: any) => {
        const g = { ...v }
        g.quantity = newQuantity
        return g
      }))

      // add and remove a sell order
      const mockSell = {
        market: 'btc_z29', price: '129925.5', quantity: '1', side: 'buy', type: 'new',
      }
      let newOpenSells = state.openSells
      if (action.payload) {
        newOpenSells = List<any>(state.openSells.push(mockSell))
      } else {
        newOpenSells = List<any>(state.openSells.filter((v) => v.price !== mockSell.price))
      }

      return state.merge({
        openBuys: newOpenBuys,
        openSells: newOpenSells,
      })
    }
    case OrderBookActionTypes.SET_OPEN_BUYS: {
      return state.set('openBuys', action.payload)
    }
    case OrderBookActionTypes.SET_OPEN_SELLS: {
      return state.set('openSells', action.payload)
    }
    default:
      return state
  }
}

interface OrderBookMap {
  [index: number]: any
}

const bookSorter = (lhs: any, rhs: any) => {
  return lhs.priceBN.comparedTo(rhs.priceBN)
}

export function processOrderSide(originalState: List<any>, incomingOrders: List<any>): List<any> {
  const newState: OrderBookMap = {}

  for (const item of originalState.toArray()) {
    item.priceBN = parseNumber(item.price, BN_ZERO)!
    item.isNewOrderPrice = false
    newState[item.price] = item
  }

  for (const item of incomingOrders.toArray()) {
    item.priceBN = parseNumber(item.price, BN_ZERO)!
    if (!newState[item.price]) {
      item.isNewOrderPrice = true
      newState[item.price] = item
    } else {
      item.isNewOrderPrice = false
      newState[item.price].quantity = parseNumber(newState[item.price].quantity, BN_ZERO)!.plus(parseNumber(item.quantity, BN_ZERO)!).toString(10)
    }
    if (newState[item.price].quantity === '0') {
      delete newState[item.price]
    }
  }

  const result: OrderBookEntry[] = Object.values(newState).sort(bookSorter).map((level: OrderBookEntry) => ({
    price: level.price,
    quantity: level.quantity,
    market: level.market,
    isNewOrderPrice: level.isNewOrderPrice,
  }))
  return List(result)
}
