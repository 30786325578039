import * as Sentry from '@sentry/react'
import { CaptureContext } from '@sentry/types'

import { BUILD_TIMESTAMP, DEPLOY_ENV } from './environment'

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
let sentryExceptionHandler: (typeof Sentry)["captureException"] | null = null

export const sentryCaptureException = (exception: any, context?: CaptureContext) => {
  sentryExceptionHandler?.(exception, context)
}

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: DEPLOY_ENV,
    release: BUILD_TIMESTAMP,
    ignoreErrors: [
      // TODO: remove after fixing websocket subscription errors from spot history chart.
      'Failed to execute \'send\' on \'WebSocket\': Still in CONNECTING state.',
      // TODO: remove after fixing websocket subscription errors from spot history chart.
      'The object is in an invalid state.',

      // trading view chart issue
      'Cannot read properties of null (reading \'tradingViewApi\')',

      // unrelated extension issues
      // solana issue
      'can\'t redefine non-configurable property "solana"',
    ],
    denyUrls: [
      /127\.0\.0\.1:60004/,
      /dapi\.o3\.app:60003/i,
      /dsp-trk\.eskimi\.com/i,
    ],
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/tm-api.carbon.network/,
          /^https:\/\/test-tm-api.carbon.network/,
          /^https:\/\/dev-tm-api.carbon.network/,
        ],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.01, // Capture % of the transactions
  })

  sentryExceptionHandler = (exception: any, context?: CaptureContext) => Sentry.captureException(exception, context)
}
