import React, { Fragment } from 'react'

interface StringReplacementProps {
  original: string
  toReplace: string
  replacement: React.ReactNode
}

const StringReplacement: React.FC<StringReplacementProps> = (props: StringReplacementProps) => {
  const { original, toReplace, replacement } = props
  if (!original || !toReplace || !replacement) {
    return null
  }

  const parts = original.split(toReplace)
  const elements: React.ReactNode[] = []

  parts.forEach((part, index) => {
    if(part !== '') {
      elements.push(<span style={{ display: "inline-flex "}} key={`part-${index}`}>{part}</span>)
    }

    if (index < parts.length - 1) {
      elements.push(<Fragment key={`part-${index}`}>{replacement}</Fragment>)
    }
  })

  return (
    <span>
      {elements}
    </span>
  )
}

export default StringReplacement
