import { RecordOf } from "immutable"

export interface NPSSurveyStateProps {
  readonly showNPSSurveyDialog: boolean
  readonly npsSurveyStage: NPSSurveyStage
  readonly npsSurveyStatus: NPSSurveyStatus,
}

export type NPSSurveyState = RecordOf<NPSSurveyStateProps>

export const NPSSurveyActionTypes = {
  SET_OPEN_NPS_SURVEY_DIALOG: '@npsSurvey/SET_OPEN_NPS_SURVEY_DIALOG',
  SET_CLOSE_NPS_SURVEY_DIALOG: '@npsSurvey/SET_CLOSE_NPS_SURVEY_DIALOG',
  SET_NPS_SURVEY_STATUS: '@npsSurvey/SET_NPS_SURVEY_STATUS',
  SET_NPS_SURVEY_STAGE: '@npsSurvey/SET_NPS_SURVEY_STAGE',
}

export enum NPSSurveyStage {
  Rating = 0,
  Feedback = 1,
}

export interface NPSSurveyStatus {
  npsSurveyCount: number
  lastSurveyDisplayAt: string | null
}

export interface SetNPSSurveyStatusPayload {
  address: string | undefined
  npsSurveyStatus?: NPSSurveyStatus
}
