import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { Erc20StateProps, Erc20ActionTypes, Erc20State } from './types'


export const DefaultInitialState: Record.Factory<Erc20StateProps> = Record<Erc20StateProps>({
  tokenPairsMap: {}
})

const defaultInitialState: Erc20State = new DefaultInitialState()

export const erc20Reducer = (
  state: Erc20State = defaultInitialState,
  action: AnyAction,
): Erc20State => {
  switch (action.type) {
    case Erc20ActionTypes.SET_TOKEN_PAIRS_MAP: {
      return state.set('tokenPairsMap', action.payload)
    }
    default:
      return state
  }
}
