import {
  Hidden,
  Theme, makeStyles
} from '@material-ui/core'
import { Color } from '@material-ui/lab'
import { CarbonSDK } from 'carbon-js-sdk'
import React, { Suspense, useRef } from 'react'
import { useSelector } from 'react-redux'

import { Dropdown, PulsingIndicator } from 'js/components/Common'
import { EvtCategory, useAdjustedBalance, useDropdownHandler, useEventTrackers, useIndivReservedFees } from 'js/hooks'
import { getReservedTokensPreference, isWalletConnected } from 'js/state/modules/account/selectors'
import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { BN_ZERO } from 'js/utils/number'

import { ReactComponent as FeeSettings } from 'assets/FeeSettings.svg'

import { FeeSettingsDetail } from './components'

import BottomButton from '../../BottomButton'

const SWTHFeeDropdown: React.FC = () => {
  const classes = useStyles()
  const eventTrackers = useEventTrackers()
  const isLoggedIn = useSelector(isWalletConnected)
  const sdk: CarbonSDK = useSelector(getCarbonSDK) as CarbonSDK
  const indivReservedFees = useIndivReservedFees()
  const wallet = sdk?.wallet
  const { firstPriority: firstPriorityToken = { denom: '', symbol: '' } } = useSelector(getReservedTokensPreference) ?? {}
  const balanceAvailable = useAdjustedBalance(firstPriorityToken.denom, 'available')
  const balanceFeeReserved = useAdjustedBalance(firstPriorityToken.denom, 'feeReserved')
  const availableBeforeFees = (balanceAvailable).plus(balanceFeeReserved)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const dropdownPaperRef = useRef<HTMLDivElement | null>(null)
  const [isDropdownOpen, handleDropdownOpen, handleDropdownClose] = useDropdownHandler(false)
  const status = React.useMemo((): Color | undefined => {
    if (availableBeforeFees) {
      if (availableBeforeFees.isZero() && indivReservedFees?.reserve.gt(0)) {
        return 'error'
      }
      if (availableBeforeFees.lt(indivReservedFees?.reserve) && availableBeforeFees.gt(BN_ZERO)) {
        return 'warning'
      }
    }
    return undefined
  }, [indivReservedFees, availableBeforeFees])

  const feeSettingsIcon = (
    <BottomButton
      icon={FeeSettings}
      label={(
        <React.Fragment>Fee Settings {wallet?.bech32Address && status && (
          <PulsingIndicator
            size={16}
            className={classes.pulseAlt}
            status={status}
          />
        )}
        </React.Fragment>
      )}
    />
  )

  const handleLabelClick = () => {
    if (isDropdownOpen) {
      handleDropdownClose()
    } else {
      handleDropdownOpen()
      eventTrackers.sendEvent(EvtCategory.Navigation, 'menu_fee_settings')
    }
  }

  return (
    <React.Fragment>
      <Hidden smDown>
        {
          isLoggedIn && (
            <Dropdown
              dropdownOpen={isDropdownOpen}
              paperRef={dropdownPaperRef}
              onDropdownLabelClick={handleLabelClick}
              onMouseLeave={handleDropdownClose}
              label={feeSettingsIcon}
              hasDropdownIcon={false}
              leftUpperMenu
              className={classes.dropdown}
              labelClassName={classes.dropdownLbl}
              containerClass={classes.dropdownLblContainer}
              additionalHeight={110}
            >
              <Suspense fallback={null}>
                <div ref={contentRef}>
                  <FeeSettingsDetail feeDropdownOpen={isDropdownOpen} feeDropdownPaperRef={dropdownPaperRef} />
                </div>
              </Suspense>
            </Dropdown>
          )
        }
      </Hidden>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    transition: 'all 0.2s ease-in-out',
    borderRadius: 4,
    width: '23.5rem',
    height: '640px !important',
    zIndex: 2,
    padding: theme.spacing(2, 3),
  },
  dropdownLbl: {
    '&.dropdownOpen': {
      background: 'transparent',
    },
  },
  dropdownLblContainer: {
    paddingRight: 0,
  },
  pulseAlt: {
    marginLeft: theme.spacing(1),
  },
}))

export default SWTHFeeDropdown
