/* eslint-disable import/prefer-default-export */
import BigNumber from 'bignumber.js'
import { TypeUtils, WSModels } from 'carbon-js-sdk'
import { EModeCategory } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/e_mode_category'

import { CDPAccountView, CDPAsset, Collateral, Debt, Params } from 'types/cdp'

import { RootState } from '../rootReducer'


export function getCdpAssets(state: RootState): TypeUtils.SimpleMap<CDPAsset> {
  return state.cdp.cdpAssets
}

export function getCdpAsset(denom: string = ''): (state: RootState) => CDPAsset | undefined {
  return (state: RootState) => {
    const assets = state.cdp.cdpAssets
    return assets[denom]
  }
}

export function getCdpAssetsAlias(state: RootState): TypeUtils.SimpleMap<string> {
  return state.cdp.cdpAssetsAlias
}

export function getCdpAccountView(state: RootState): CDPAccountView | undefined {
  return state.cdp.accountView
}

export function getParams(state: RootState): Params {
  return state.cdp.params
}

export function getCollaterals(state: RootState): Collateral[] {
  return state.cdp.collaterals ?? []
}

export function getDebts(state: RootState): Debt[] {
  return state.cdp.debts ?? []
}

export function getHideZeroBalance(state: RootState): boolean {
  return state.cdp.hideZeroBalance
}

export function getCdpModuleBalances(state: RootState): TypeUtils.SimpleMap<BigNumber> {
  return state.cdp.cdpModuleAddressBalances
}

export function getRewardSchemes(state: RootState): TypeUtils.SimpleMap<WSModels.RewardScheme> {
  return state.cdp.rewardSchemes
}

export function getRewardDebts(state: RootState): TypeUtils.SimpleMap<WSModels.RewardDebt> {
  return state.cdp.rewardDebts
}

export function getTotalSupplyMap(state: RootState): TypeUtils.SimpleMap<BigNumber> {
  return state.cdp.totalSupplyMap
}

export function getEModeCategories(state: RootState): Record<string, EModeCategory> {
  return state.cdp.emodeCategories
}

export function getEModeCategory(state: RootState): EModeCategory | undefined {
  return state.cdp.emodeCategory
}

export function getHideSmallAmount(state: RootState): boolean {
  return state.cdp.hideSmallAmount
}