import { makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { StyleUtils } from 'js/utils'

import { Option } from './types'

interface Props {
  selectedOptionValue: string
  options: Option[]
}

const MobileButtonTab: React.FC<Props> = (props: Props) => {
  const { selectedOptionValue, options } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        {
          options.map((option, index) => (
            <div
              key={index}
              className={clsx(classes.item, { 'selected': selectedOptionValue === option.value })}
              onClick={option.onSelect}>
              <Typography component="span" className={classes.label}>
                {option.label}
              </Typography>
            </div>
          ))
        }
      </div>
      <div className={clsx(classes.row, 'faded')}>
        {
          options.map((option, index) => (
            <div
              key={index}
              className={clsx(classes.item, 'faded')}
              onClick={option.onSelect}>
              <Typography component="span" className={classes.label}>
                {option.label}
              </Typography>
            </div>
          ))
        }
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    margin: theme.spacing(0.75, 0),
    '-webkit-tap-highlight-color': 'rgba(255, 255, 255, 0)',
  },
  row: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: '4px',
    '&.faded': {
      position: 'absolute',
      top: 0,
      left: 0,
    }
  },
  item: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(0.25),
    padding: theme.spacing(0.25, 0),
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'all 0.5s ease-in-out',
    background: StyleUtils.buttonGradient(theme),
    opacity: 0,
    zIndex: 1,
    '& > p': {
      ...theme.typography.body3,
      transition: 'all 0.5s ease-in-out',
      color: theme.palette.text.secondary,
    },
    '&.selected': {
      opacity: 1,
      '& > p': {
        color: theme.palette.common.white,
      },
    },
    '&.faded': {
      zIndex: 'unset',
      background: 'unset',
      opacity: 1,
    },
    '&:active': {
      backgroundColor: 'transparent !important',
    },
  },
  label: {
    fontSize: '11px !important',
  }
}))

export default MobileButtonTab
