import { isString } from 'lodash'
import React, { ReactNode } from 'react'

import { NotificationType } from 'js/constants/notification'
import { NotificationProps } from 'js/utils/notifications'

import Notification from './Notification'
import RawError from './RawError'

const TransactionNotification: React.FC<NotificationProps> = (props: NotificationProps) => {
  const { body, type } = props
  const getNotificationBody = (type: NotificationType, body: ReactNode) => {
    if (type === NotificationType.ERROR && isString(body)) {
      return <RawError error={body} />
    }
    return body
  }
  return (
    <Notification
      {...props}
      body={body && body !== '' && getNotificationBody(type, body)} />
  )
}

export default TransactionNotification
