import { SimpleMap } from './types'

export enum EvtCategory {
  Wallet = 'Wallet',
  RecentTrades = 'Recent Trades',
  Navigation = 'Navigation',
  Trade = 'Trade',
  Swap = 'Swap',
  Nitron = 'Nitron',
  Earn = 'Earn',
  Rewards = 'Rewards',
  Markets = 'Markets',
  Profile = 'Profile',
  Misc = 'Misc',
  ConvertBalance = 'Convert Balance',
  NetPromoterScore = 'Net Promoter Score'
}

export type EventAction =
  // Wallet
  'wallet_connect_tap' // When user taps on 'Connect Wallet' button
  | 'wallet_select_tap' // When user taps on a wallet
  | 'wallet_connect_success' // When user successfully connects their wallet (only when user manually connects)
  | 'wallet_connect_failure' // When user fails to connect their wallet
  | 'wallet_reconnect' // When page refreshes and the wallet gets auto-reconnected
  | 'wallet_disconnect_tap' // When user taps on 'Disconnect Wallet' button in dropdown bar
  | 'click_wallet_deposit' // click Deposit button (y)
  | 'click_wallet_withdraw' // click Withdraw button (y)
  | 'wallet_deposit_complete' // successfully deposit tokens (y)
  | 'wallet_withdraw_complete' // successfully withdraw tokens (y)

  // ConvertBalance
  | 'dust_convert_tap' // When user taps on 'Create RFQ' button
  | 'dust_convert_submit' // When user taps on 'Accept Quote' button
  | 'dust_cancel_convert_tap' // When user taps on 'Cancel RFQ' button
  | 'dust_stakeswth_tap' // When user taps on 'Stake SWTH' button

  // Navigation
  | 'menu_trade' // When user taps on 'Trade' button
  | 'menu_swap' // When user taps on 'Swap' button
  | 'menu_nitron' // When user taps on 'Nitron' button
  | 'menu_pools' // When user taps on 'Pools' button
  | 'menu_perp_pool' // When user taps on 'Perp Pools' button
  | 'menu_spot_pool' // When user taps on 'Spot Pools' button
  | 'menu_glp_compounder' // When user taps on 'GLP Compounder' button
  | 'menu_rewards' // When user taps on 'Rewards' button
  | 'menu_markets' // When user taps on 'Markets' button
  | 'menu_leaderboard' // When user taps on 'Leaderboard' button
  | 'menu_blog' // When user taps on 'Blog' button
  | 'menu_explorer' // When user taps on 'Explorer' button
  | 'menu_guide' // When user taps on 'Guide' button
  | 'menu_docs' // When user taps on 'Docs' button
  | 'menu_support' // When user taps on 'Support' button
  | 'menu_feedback' // When user taps on 'Feedback' button
  | 'menu_twitter' // When user taps on 'Twitter' button
  | 'menu_telegram' // When user taps on 'Telegram' button
  | 'menu_discord' // When user taps on 'Discord' button
  | 'menu_github' // When user taps on 'Github' button
  | 'menu_notification' // When user taps on the notifications icon (Only when opening)
  | 'menu_network' // When user changes their network
  | 'menu_fee_settings' // When user taps on 'Fee Settings' button
  | 'menu_referrals' // When user taps on 'Referrals' button
  | 'menu_demex_points' // When user taps on 'Demex points' button
  | 'menu_trade_layout' // When user changes their trade layout

  // Trade
  | 'trade_market_tap' // When user selects a market from the markets drop down
  | 'trade_create_order' // When user creates an order
  | 'trade_cancel_order' // When user cancels an open order
  | 'trade_edit_leverage' // When user edits their leverage value
  | 'trade_edit_margin' // When user edits their margin value
  | 'fee_edit_priority' // When user edits their network fee priority
  | 'reserve_network_fee' // When user edits their reserve network fee
  | 'trade_balancecard_deposit' // When user taps on 'Deposit' button on the trade balance card tab
  | 'trade_balancecard_withdraw' // When user taps on 'Withdraw' button on the trade balance card tab
  | 'trade_edit_price_tap' // When user taps on 'Edit Price' button on an open order
  | 'trade_edit_price_submit' // When user submits the edited price
  | 'trade_edit_qty_start' // When user taps on 'Edit Quantity' button on an open order
  | 'trade_edit_qty_submit' // When user submits the edited quantity
  | 'trade_order_explorer_tap' // When user taps on the Explorer (arrow) button on an order
  | 'trade_balances_trade' // When user selects a market from the 'Trade' button on the bottom Balances tab on the Trade page
  | 'trade_open_order_more' // When user taps on the arrow button on an open order
  | 'click_recent_trades' // When user taps on link to Recent Trades page

  // Swap
  | 'swap_from_select' // When user selects an asset to swap from
  | 'swap_to_select' // When user selects an asset to swap to
  | 'swap_submit' // When user taps on 'Swap' button to swap

  // Nitron
  | 'nitron_lend_select' // When user taps on 'Lend' button for an asset on Nitron page
  | 'nitron_lend_submit' // When user taps on 'Lend' button for an asset on the asset's page
  | 'nitron_borrow_select' // When user taps on 'Borrow' button for an asset on Nitron page (includes USC)
  | 'nitron_borrow_submit' // When user taps on 'Borrow' button for an asset on the asset's page (includes USC)
  | 'nitron_withdraw_select' // When user taps on 'Withdraw' button for an asset on Nitron page
  | 'nitron_withdraw_submit' // When user taps on 'Withdraw' button for an asset on the asset's page
  | 'nitron_return_select' // When user taps on 'Return' button for an asset on Nitron page
  | 'nitron_return_submit' // When user taps on 'Return' button for an asset on the asset's page
  | 'nitron_liquidations_tap' // When user taps on 'Nitron Liquidations' button on Nitron page
  | 'nitron_liquidate_submit' // When user taps on 'Liquidate' button for a position on Liquidations page
  | 'nitron_repay_debt_tap' // When user taps on 'Repay' button for a position on Borrower’s Liquidation Details page
  | 'nitron_liquidate_collateral_tap' // When user taps on 'Liquidate' button for a position on Borrower’s Liquidation Details page
  | 'nitron_repay_liquidate_submit' // When user taps on 'Repay & Liquidate' button for a position
  | 'nitron_add_collateral_submit' // When user taps on 'Add Collateral' (plus) button
  | 'nitron_remove_collateral_submit' // When user taps on Remove Collateral (minus) button
  | 'nitron_mint_submit' // When user taps on 'Mint' button
  | 'nitron_additional_rewards_claim' // When user taps on 'Claim' for additional rewards

  // Nitron Cross Sell
  | 'menu_trade' // When user taps on 'Trade Now' button on Nitron page
  | 'menu_perp_pool' // When user taps on 'Deposit Now' button on Nitron page

  // Earn
  | 'perp_pool_tap' // When user taps on 'Add Liquidity' button for a perp pool
  | 'perp_deposit_submit' // When user deposits funds into a perp pool
  | 'perp_withdraw_submit' // When user withdraws funds from a perp pool
  | 'spot_pool_tap' // When user taps on 'Add Liquidity' button for a spot pool
  | 'spot_deposit_submit' // When user adds liquidity to a spot pool
  | 'spot_remove_submit' // When user removes funds from a spot pool
  | 'spot_commit_submit' // When user commits more funds to a pool
  | 'spot_extend_submit' // When user extends their commit period
  | 'spot_create_submit' // When user creates their own pool
  | 'lp_claim_submit' // When user claims their rewards from providing liquidity
  | 'stake_swth_tap' // When user taps on 'Stake SWTH' button
  | 'stake_tap' // When user taps on 'Stake' on a validator
  | 'stake_submit' // When user stakes SWTH with a validator
  | 'unstake_submit' // When user unstakes SWTH with a validator
  | 'redelegate_submit' // When user redelegates SWTH between validators
  | 'stake_claim_submit' // When user claims their rewards from staking SWTH

  // Rewards
  | 'demex_points_spin' // When user spins the wheel to earn Demex points
  | 'demex_points_more' // When user taps on the other buttons on the Demex points page
  | 'carbon_credits_tap' // When user taps on 'Carbon Credits' button
  | 'carbon_credits_claim' // When user claims their rewards from Carbon Credits
  | 'referrals_copy_link' // When user copies their referral link
  | 'get_referred_tap' // When user opens the referral banner ("You've been referred!") and taps on "Connect Wallet"
  | 'referred_trade_tap' // When user clicks "Start Trading" in the referral popup

  // Markets
  | 'markets_create_submit' // When user creates a new market
  | 'markets_import_tokens_submit' // When user imports a new token

  // Profile
  | 'deposit' // When user taps on 'Deposit'
  | 'deposit_select' // When user deposits an asset
  | 'withdraw' // When user taps on 'Withdraw'
  | 'withdraw_select' // When user withdraws an asset
  | 'balance_lend' // When user taps on 'Lend' button on Balance page
  | 'balance_borrow' // When user taps on 'Borrow' button on Balance page
  | 'balance_trade' // When user taps on 'Trade' button on Balance page
  | 'account_delegation' // When user sends an invite to assign/be a delegate (subaccount)

  // NetPromoterScore
  | 'nps_rating_shown' // When the NPS survey is shown. Track which time the survey is shown
  | 'nps_rating_submit' // When user taps on a number on the rating page
  | 'nps_review_submit' // When user taps on Submit button on the review page. Track the user’s rating selected and their review as is

  // Misc
  | 'onboarding_shown' // When the onboarding slideup is shown
  | 'onboarding_wallet_connect' // When user connects wallet from the onboarding slideup
  | 'onboarding_deposit_tap' // When user taps on 'Deposit' button from the onboarding slideup
  | 'onboarding_notifications_tap' // When user taps on 'Enable' button from the onboarding slideup
  | 'onboarding_oneclick_tap' // When user taps on 'Confirm' button from the onboarding slideup
  | 'refresh_update_shown' // When the 'Refresh to update Demex' popup is shown
  | 'campaign_tap' // When user taps on a campaign CTA
  | 'campaign_register_submit' // When user registers for a campaign
  | 'PWA_install_shown' // When the popup to download Demex app is shown
  | 'oneclick_shown' // When the one click trading popup is shown
  | 'error_shown' // When any error type toast is displayed
  | 'onboarding_widget_tap' // When user taps on the onboarding widget
  | 'onboarding_notification_tap' // When user taps on the notification in the onboarding widget
  | 'onboarding_oneclick_tap' // When user taps on the one click trading in the onboarding widget
  | 'onboarding_trade_tap' // When user taps on the trade button in the onboarding widget
  | 'onboarding_dismiss' // When user dismisses the onboarding widget


export const sendGaEvent = (eventCategory: EvtCategory, eventAction: EventAction, eventLabel?: string, eventValue?: number, args?: SimpleMap<string>) => {
  const data = cleanUndefined({
    event: eventAction,
    event_category: eventCategory,
    event_label: eventLabel,
    value: eventValue,
    ...args,
  })

  if (typeof window !== 'undefined') {
    (window as any).logGoogleAnalytics?.(data);
    (window as any).dataLayer?.push(data)
  }
}

const cleanUndefined = (args: any = {}) => {
  for (const key in args) {
    if (typeof args[key] === 'undefined') {
      delete args[key] // eslint-disable-line no-param-reassign
    }
  }

  return args
}
