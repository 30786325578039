import { Divider, MenuList, MenuListClassKey, MenuListProps, MenuItem as MuiMenuItem } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import SvgIcon from 'js/components/Common/SvgIcon'
import { useCommonStyles } from "js/utils"

import { ReactComponent as TickIcon } from 'assets/TickNew.svg'

import { menuListStyles } from './styles'

import DropDownIcon from '../DropDownIcon'

type MenuListClasses = { [key in MenuListClassKey]: string }

export interface MenuItemCustomClasses {
  startIcon?: string
  endIcon?: string
}

export interface InnerMenuItem extends React.DOMAttributes<any> {
  active?: boolean
  show?: boolean
  key: string
  className?: string
  label: string | React.ReactNode
  startIcon?: SVGComponent
  startIconType?: 'stroke' | 'fill'
  customClasses?: MenuItemCustomClasses
  endIcon?: SVGComponent
  endIconType?: 'stroke' | 'fill'
  endLabel?: React.ReactNode
  hasDivider?: boolean
  menuItemWrapper?: boolean
}

export interface MenuItem extends React.DOMAttributes<any> {
  active?: boolean
  show?: boolean
  key: string
  className?: string
  label: string | React.ReactNode
  startIcon?: SVGComponent
  startIconType?: 'stroke' | 'fill'
  endIcon?: SVGComponent
  endIconType?: 'stroke' | 'fill'
  customClasses?: MenuItemCustomClasses
  notificationCount?: number
  endLabel?: React.ReactNode
  hasDivider?: boolean
  menuItemWrapper?: boolean
  innerItems?: InnerMenuItem[],
  innerItemsShowing?: boolean,
}
interface Props extends MenuListProps {
  menuListClasses?: Partial<MenuListClasses>
  items?: MenuItem[]
  size?: 'small' | 'regular' | 'large'
  menuClass?: string
  hasSeperator?: boolean
  showTickIcon?: boolean
}

const MenuListItems: React.FC<Props> = (props: Props) => {
  const { items = [], menuListClasses = {}, size = 'regular', menuClass = {}, hasSeperator, showTickIcon,  ...rest } = props
  const classes = menuListStyles()
  const commonClasses = useCommonStyles()

  const genIconTypeClasses = (iconType: 'stroke' | 'fill') => ({
    [classes.iconFill]: iconType === 'fill',
    [classes.iconStroke]: iconType === 'stroke',
  })

  const innerItemElement = (innerItem: InnerMenuItem) => {
    const { active = false, show = true, className, endIcon, customClasses = {}, label, key, startIcon, startIconType, endIconType, endLabel, hasDivider, menuItemWrapper = true, ...restItem } = innerItem
    return show && (
      menuItemWrapper ? (
        <div key={key}>
          <MuiMenuItem
            className={clsx(classes.label, { [classes.active]: active }, className)}
            {...restItem}
          >
            {startIcon && startIconType && (
              <SvgIcon className={clsx(classes.icon, 'innerIcon', 'startIcon', genIconTypeClasses(startIconType), customClasses.startIcon)} component={startIcon} />
            )}
            <div className={clsx(classes.menuItemBox, menuClass)}>
              {label}
              {endIcon && endIconType && (
                <SvgIcon className={clsx(classes.icon, 'innerIcon', 'endIcon', genIconTypeClasses(endIconType), classes.endIconInnerList, customClasses.endIcon)} component={endIcon} />
              )}
              {endLabel}
            </div>
          </MuiMenuItem>
          {hasDivider && (
            <Divider className={classes.divider} />
          )}
        </div>
      ) : label
    )
  }

  return (
    <MenuList
      classes={{
        ...menuListClasses,
        root: clsx(classes.root, menuListClasses.root),
      }}
      {...rest}
    >
      {items.map((item: MenuItem, index: number) => {
        const {
          active = false, show = true, className, endIcon, customClasses = {}, label, key, startIcon, startIconType, endIconType, notificationCount = 0,
          endLabel, menuItemWrapper = true, hasDivider, innerItems, innerItemsShowing, ...restItem
        } = item
        return show && (
          <div key={item.key}>
            {menuItemWrapper ? (
              <MuiMenuItem
                className={clsx(classes.label, { [classes.active]: active || (innerItems && innerItemsShowing) }, className, { small: size === 'small', large: size === 'large' })}
                {...restItem}
              >
                {startIcon && startIconType && (
                  <SvgIcon className={clsx(classes.icon, { large: size === 'large' }, 'startIcon', genIconTypeClasses(startIconType), customClasses.startIcon)} component={startIcon} />
                )}
                {/* notification count indicator */}
                {notificationCount > 0 && (<div className={clsx(classes.notificationIndicator, commonClasses.flexRow, commonClasses.alignItemsCenter, commonClasses.justifyContentCenter)}>{notificationCount}</div>)}
                <div className={clsx(classes.menuItemBox, menuClass, commonClasses.flexRow, commonClasses.justifyContentSpaceBetween, commonClasses.fullWidth, commonClasses.alignItemsCenter)}>
                  {label}
                  {active && showTickIcon ? (
                    <SvgIcon className={classes.icon} component={TickIcon} />
                  ) : (
                    <React.Fragment>
                      {innerItems ? (
                        <DropDownIcon className={clsx(classes.icon, 'innerIcon', 'endIcon')} open={innerItemsShowing} />
                      ) : endIcon && endIconType && (
                        <SvgIcon className={clsx(classes.icon, 'endIcon', genIconTypeClasses(endIconType), customClasses.endIcon)} component={endIcon} />
                      )}
                      {endLabel}
                    </React.Fragment>
                  )}
                </div>
              </MuiMenuItem>
            ) : label}
            {innerItems && (
              <div className={clsx(classes.innerList, innerItemsShowing && classes.scrollDown, !innerItemsShowing && classes.scrollUp)}>
                {innerItems.map((innerItem: InnerMenuItem) => innerItemElement(innerItem))}
              </div>
            )}
            {(hasDivider || hasSeperator) && index !== items.length - 1 && (
              <Divider className={classes.divider} />
            )}
          </div>
        )
      })}
    </MenuList>
  )
}

export default MenuListItems
