import { createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { useState } from 'react'
import Popover, { Position } from 'react-tiny-popover'

interface Props {
  tooltipContent: any
  children: any
  childClass?: string
  containerClassName?: string
  contentDestination?: HTMLElement
  toolTipClassName?: string
  direction?: Position
  offset?: number
  open?: boolean
}

const Tooltip: React.FC<Props> = (props: Props) => {
  const {
    toolTipClassName, direction = 'top', children, tooltipContent,
    containerClassName, offset = 6, contentDestination, open = false,
    childClass,
  } = props

  const classes = useStyles()

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  if (!tooltipContent) return children

  const openTooltip = (): void => {
    setIsPopoverOpen(true)
  }

  const closeTooltip = (): void => {
    setIsPopoverOpen(false)
  }

  return (
    <Popover
      isOpen={open || isPopoverOpen}
      position={direction}
      content={(
        <div className={clsx(classes.toolTip, toolTipClassName)}>
          {tooltipContent}
        </div>
      )}
      padding={offset}
      contentDestination={contentDestination}
      containerClassName={containerClassName}
    >
      <div 
        className={clsx(classes.child, childClass)}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
      >
        {children}
      </div>
    </Popover>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  toolTip: {
    ...theme.typography.label1,
    textAlign: 'left',
    padding: '0.3rem 0.6rem',
    borderRadius: '2px',
    maxWidth: '20rem',
    backgroundColor: theme.palette.background.tooltip,
    color: theme.palette.text.tooltip,
    zIndex: 10000,
  },
  child: {
    display: 'inline',
  },
}))

export default Tooltip
