import { FeeStructure, StakeEquivalence } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/fee'
import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { BN_ZERO } from 'js/utils/number'

import { FeeTiersActionTypes, FeeTiersState, FeeTiersStateProps } from './types'


const DefaultInitialTradingFeesState:
  Record.Factory<FeeTiersStateProps> = Record<FeeTiersStateProps>({
    stakeEquivalences: [] as StakeEquivalence[],
    feeStructures: [] as FeeStructure[],
    validatorStakedSWTH: BN_ZERO,
    totalStakeEquivalence: BN_ZERO,
    denomToStakeEquivalenceTokenMap: {},
    tokenNameToStakeEquivalenceRatioMap: {},
    perpsVipTierInfo: undefined,
    spotVipTierInfo: undefined,
    oldPerpsVipTierInfo: undefined,
    oldSpotVipTierInfo: undefined,
    currentMarketVipTier: undefined,
    delegateSpotVipTierInfo: new Map(),
    delegatePerpsVipTierInfo: new Map(),
    vipTierInfoRequestAddresses: new Set(),
  })

const defaultInitialTradingFeesState: FeeTiersState = new DefaultInitialTradingFeesState()

export const tradingFeesReducer = (
  state: FeeTiersState = defaultInitialTradingFeesState,
  action: AnyAction,
): FeeTiersState => {
  switch (action.type) {
    case FeeTiersActionTypes.SET_STAKE_EQUIVALENCES: {
      return state.set('stakeEquivalences', action.payload)
    }
    case FeeTiersActionTypes.SET_FEE_STRUCTURES: {
      return state.set('feeStructures', action.payload)
    }
    case FeeTiersActionTypes.SET_VALIDATOR_STAKED_SWTH: {
      return state.set('validatorStakedSWTH', action.payload)
    }
    case FeeTiersActionTypes.SET_TOTAL_STAKE_EQUIVALENCE: {
      return state.set('totalStakeEquivalence', action.payload)
    }
    case FeeTiersActionTypes.SET_DENOM_TO_STAKE_EQUIVALENCE_TOKEN_MAP: {
      return state.set('denomToStakeEquivalenceTokenMap', action.payload)
    }
    case FeeTiersActionTypes.SET_TOKEN_NAME_TO_STAKE_EQUIVALENCE_RATIO_MAP: {
      return state.set('tokenNameToStakeEquivalenceRatioMap', action.payload)
    }
    case FeeTiersActionTypes.SET_PERPS_VIP_TIER_INFO: {
      return state.set('perpsVipTierInfo', action.payload)
    }
    case FeeTiersActionTypes.SET_SPOT_VIP_TIER_INFO: {
      return state.set('spotVipTierInfo', action.payload)
    }
    case FeeTiersActionTypes.SET_CURRENT_MARKET_VIP_TIER: {
      return state.set('currentMarketVipTier', action.payload)
    }
    case FeeTiersActionTypes.SET_DELEGATES_PERPS_VIP_TIER_INFO: {
      return state.set('delegatePerpsVipTierInfo', action.payload)
    }
    case FeeTiersActionTypes.SET_DELEGATES_SPOT_VIP_TIER_INFO: {
      return state.set('delegateSpotVipTierInfo', action.payload)
    }
    case FeeTiersActionTypes.ADD_VIP_TIER_INFO_REQUEST_ADDRESSES: {
      const addresses = state.get('vipTierInfoRequestAddresses')
      addresses.add(action.payload)
      return state.set('vipTierInfoRequestAddresses', addresses)
    }
    case FeeTiersActionTypes.SET_OLD_PERPS_VIP_TIER_INFO: {
      return state.set('oldPerpsVipTierInfo', action.payload)
    }
    case FeeTiersActionTypes.SET_OLD_SPOT_VIP_TIER_INFO: {
      return state.set('oldSpotVipTierInfo', action.payload)
    }
    default:
      return state
  }
}
