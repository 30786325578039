import { Record } from 'immutable'
import { cloneDeep } from 'lodash'
import { AnyAction } from 'redux'


import {
  WalletBalanceActionTypes,
  WalletBalanceState,
  WalletBalanceStateProps,
  defaultTransferForm,
} from './types'

const defaultValues: WalletBalanceStateProps = {
  balances: {},
  adjustedBalances: {},
  portfolio: {},
  nonZeroBalanceDenoms: undefined,
  derivedTokenRedemption: {},

  evmBalances: {},
  carbonBalances: {},
  profile: null,
  selectedToken: null,
  groupTokenOption: '',
  transferOption: {},
  transferForm: defaultTransferForm,
  initiatedTxDetails: undefined,

  bridgeTransferMethods: {},
  chainSelectOptions: {
    optionList: [],
    denomMap: {},
  },
  chainSelectOpen: false,
  directDeposits: null,

  walletFees: {},

  rawDelegations: null,
  rawUnbondingDelegations: null,

  tokenProperties: {},
}

export const DefaultInitialState: Record.Factory<WalletBalanceStateProps> = Record<WalletBalanceStateProps>(cloneDeep(defaultValues))

const defaultInitialState: WalletBalanceState = new DefaultInitialState()

export const WalletBalanceReducer = (
  state: WalletBalanceState = defaultInitialState,
  action: AnyAction,
): WalletBalanceState => {
  switch (action.type) {
    case WalletBalanceActionTypes.SET_BALANCES: {
      return state.set('balances', action.payload)
    }
    case WalletBalanceActionTypes.SET_ADJUSTED_BALANCES: {
      return state.set('adjustedBalances', action.payload)
    }
    case WalletBalanceActionTypes.SET_PORTFOLIO: {
      return state.set('portfolio', action.payload)
    }
    case WalletBalanceActionTypes.SET_NON_ZERO_BALANCE_DENOMS: {
      return state.set('nonZeroBalanceDenoms', action.payload)
    }
    case WalletBalanceActionTypes.SET_DERIVED_TOKEN_REDEMPTION: {
      return state.set('derivedTokenRedemption', action.payload)
    }
    case WalletBalanceActionTypes.SET_EVM_WALLET_BALANCE: {
      return state.set('evmBalances', action.payload)
    }
    case WalletBalanceActionTypes.SET_CARBON_WALLET_BALANCE: {
      return state.set('carbonBalances', action.payload)
    }
    case WalletBalanceActionTypes.CLEAR_WALLET_BALANCE: {
      const resetUserValues = {
        balances: defaultValues.balances,
        adjustedBalances: defaultValues.adjustedBalances,
        portfolio: defaultValues.portfolio,
        nonZeroBalanceDenoms: defaultValues.nonZeroBalanceDenoms,
        evmBalances: defaultValues.evmBalances,
        carbonBalances: defaultValues.carbonBalances,
        profile: defaultValues.profile,
        transferForm: defaultValues.transferForm,
        directDeposits: {
          ...state.directDeposits,
          addresses: undefined
        },
        rawDelegations: defaultValues.rawDelegations,
        rawUnbondingDelegations: defaultValues.rawUnbondingDelegations,
      }
      return state.merge(resetUserValues)
    }
    case WalletBalanceActionTypes.SET_BRIDGE_TRANSFER_METHODS: {
      return state.set('bridgeTransferMethods', action.payload)
    }
    case WalletBalanceActionTypes.SET_CHAIN_SELECT_OPTIONS: {
      return state.set('chainSelectOptions', action.payload)
    }
    case WalletBalanceActionTypes.SET_CHAIN_SELECT_OPEN: {
      return state.set('chainSelectOpen', action.payload)
    }
    case WalletBalanceActionTypes.SET_SELECTED_TOKEN: {
      return state.set('selectedToken', action.payload)
    }
    case WalletBalanceActionTypes.SELECT_GROUP_TOKEN_OPTION: {
      return state.set('groupTokenOption', action.payload)
    }
    case WalletBalanceActionTypes.SET_SELECTED_TRANSFER_SOURCE: {
      return state.setIn(
        ['transferOption', 'source'],
        action.payload,
      )
    }
    case WalletBalanceActionTypes.SET_SELECTED_BLOCKCHAIN: {
      return state.setIn(
        ['transferOption', 'blockchain'],
        action.payload,
      )
    }
    case WalletBalanceActionTypes.SET_SELECTED_OPTION_DENOM: {
      return state.setIn(
        ['transferOption', 'optionDenom'],
        action.payload,
      )
    }
    case WalletBalanceActionTypes.SET_SELECTED_DENOM_ADDITIONAL_TOKEN_INFO: {
      return state.setIn(
        ['transferOption', 'additionalTokenInfo'],
        action.payload,
      )
    }
    case WalletBalanceActionTypes.UPDATE_TRANSFER_FORM_INPUTS: {
      return state.set('transferForm', {
        ...state.transferForm,
        ...action.payload,
      })
    }
    case WalletBalanceActionTypes.SET_INITIATED_TX_DETAILS: {
      return state.set('initiatedTxDetails', action.payload)
    }
    case WalletBalanceActionTypes.SET_DIRECT_DEPOSITS_SUPPORTED_CHAINS: {
      return state.set('directDeposits', { ...state.directDeposits, supportedChains: action.payload })
    }
    case WalletBalanceActionTypes.SET_USER_DIRECT_DEPOSITS_ADDRESSES: {
      return state.set('directDeposits', { ...state.directDeposits, addresses: action.payload })
    }
    case WalletBalanceActionTypes.SET_WALLET_FEES: {
      return state.set('walletFees', {
        ...state.walletFees,
        ...action.payload,
      })
    }
    case WalletBalanceActionTypes.SET_RAW_DELEGATIONS: {
      return state.set('rawDelegations', action.payload)
    }
    case WalletBalanceActionTypes.SET_RAW_UNBONDING_DELEGATIONS: {
      return state.set('rawUnbondingDelegations', action.payload)
    }
    case WalletBalanceActionTypes.SET_TOKEN_PROPERTIES: {
      return state.set('tokenProperties', action.payload)
    }
    default:
      return state
  }
}
