import { FormControl, FormControlLabel, FormGroup, Theme, createStyles, makeStyles, useMediaQuery, useTheme, withStyles } from '@material-ui/core'
import clsx from 'clsx'
import { List } from 'immutable'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import { Dropdown } from 'js/components/Common'
import TextButton from 'js/components/Common/TextButton'
import V2Switch from 'js/components/Common/V2Switch'
import Paths from 'js/constants/paths'
import { useDropdownHandler } from 'js/hooks'
import { setFeeToggleClose } from 'js/state/modules/app/actions'
import { clearHideLayouts, resetLayout, setHideLayouts, setLayout } from 'js/state/modules/exchange/actions'
import { getHideLayouts, getLayout } from 'js/state/modules/exchange/selectors'
import baseLayouts from 'js/utils/layouts'

import { ReactComponent as TradeLayout } from 'assets/DisplaySettings.svg'

import BottomButton from '../BottomButton'

interface Props { }

const DisplaySettings: React.FC<Props> = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const hideLayouts: any = useSelector(getHideLayouts)
  const layout = useSelector(getLayout)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const isTradePage = useRouteMatch(Paths.Trade.Main)

  const handleReset = () => {
    dispatch(clearHideLayouts())
    dispatch(resetLayout())
  }

  const { xlLayout, lgLayout, mdLayout, smLayout, xsLayout, gridConfig } = baseLayouts
  const { breakpoints } = gridConfig

  const handleChange = (componentName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const prevLayout: List<any> = List(layout)
    let newLayout: List<any>

    if (event.target.checked) {
      let newItem: any
      const width = window.innerWidth
      if (width >= breakpoints.xl) {
        newItem = xlLayout.find((layout: any) => layout.i === componentName)
      } else if (width >= breakpoints.lg) {
        newItem = lgLayout.find((layout: any) => layout.i === componentName)
      } else if (width >= breakpoints.md) {
        newItem = mdLayout.find((layout: any) => layout.i === componentName)
      } else if (width >= breakpoints.sm) {
        newItem = smLayout.find((layout: any) => layout.i === componentName)
      } else {
        newItem = xsLayout.find((layout: any) => layout.i === componentName)
      }
      newLayout = List(prevLayout.push(newItem))
    } else {
      newLayout = List(prevLayout.filter((layout: any) => layout.i !== componentName))
    }

    dispatch(setLayout(newLayout))
    dispatch(setHideLayouts(componentName, !event.target.checked))
  }

  if (!isTradePage) return null

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={clsx(classes.categoryWrapper, classes.categoriesWrapper)}>
          <div className={classes.categoryWrapper}>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  classes={{ root: classes.controlLabelRoot, label: classes.switchLabel }}
                  className={classes.switch}
                  control={(
                    <V2Switch
                      className={classes.switchRoot}
                      checked={!hideLayouts.contains('orderBook')}
                      onChange={handleChange('orderBook')}
                      value="orderBook"
                    />
                  )}
                  label="Order Book"
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{ root: classes.controlLabelRoot, label: classes.switchLabel }}
                  className={classes.switch}
                  control={(
                    <V2Switch
                      className={classes.switchRoot}
                      checked={!hideLayouts.contains('recentTrades')}
                      onChange={handleChange('recentTrades')}
                      value="recentTrades"
                    />
                  )}
                  label="Recent Trades"
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{ root: classes.controlLabelRoot, label: classes.switchLabel }}
                  className={classes.switch}
                  control={(
                    <V2Switch
                      className={classes.switchRoot}
                      checked={!hideLayouts.contains('chart')}
                      onChange={handleChange('chart')}
                      value="chart"
                    />
                  )}
                  label="Price Chart"
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{ root: classes.controlLabelRoot, label: classes.switchLabel }}
                  className={classes.switch}
                  control={(
                    <V2Switch
                      className={classes.switchRoot}
                      checked={!hideLayouts.contains('orderHistory')}
                      onChange={handleChange('orderHistory')}
                      value="orderHistory"
                    />
                  )}
                  label="Positions & Orders"
                  labelPlacement="start"
                />
                {isMobile && (
                  <FormControlLabel
                    classes={{ root: classes.controlLabelRoot, label: classes.switchLabel }}
                    className={classes.switch}
                    control={(
                      <V2Switch
                        className={classes.switchRoot}
                        checked={!hideLayouts.contains('marginDisplay')}
                        onChange={handleChange('marginDisplay')}
                        value="marginDisplay"
                      />
                    )}
                    label="Market Leverage"
                    labelPlacement="start"
                  />
                )}
              </FormGroup>
            </FormControl>

            <TextButton
              label="Reset Layout"
              boxClass={classes.reset}
              onClick={handleReset}
              fillType="stroke"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(0.5)}px`,
    backgroundColor: theme.palette.background.secondary,
    color: theme.palette.text.secondary,
    borderRadius: 4,
    padding: theme.spacing(2.25, 2.5, 2),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0),
    },
  },
  categoryLabel: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
  },
  categoriesWrapper: {
    paddingBottom: theme.spacing(0.5),
  },
  categoryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  reset: {
    backgroundColor: 'transparent',
    '& p': {
      ...theme.typography.body3,
      fontWeight: 700,
    },
    '& svg': {
      height: '1rem',
      maxWidth: '1rem',
      '&.start': {
        marginRight: theme.spacing(1),
      },
    },
  },
  switchRoot: {
    margin: 0,
  },
  controlLabelRoot: {
    margin: 0,
    width: '100%',
    placeContent: 'space-between',
    gap: `${theme.spacing(2)}px`,
  },
  altIcon: {
    width: '1.375rem',
    height: '1.375rem',
    margin: theme.spacing(0, 0.75),
    padding: theme.spacing(0.25, 0),
    '&.stroke': {
      '& path': {
        stroke: theme.palette.text.secondary,
      },
      '&:hover': {
        '& path': {
          stroke: `${theme.palette.text.animated} !important`,
        },
      },
    },
    '&.fill': {
      '& path': {
        fill: theme.palette.text.secondary,
      },
      '&:hover': {
        '& path': {
          fill: `${theme.palette.text.animated} !important`,
        },
      },
    },
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    margin: theme.spacing(0, 0.75),
    padding: theme.spacing(0.25, 0),
    '@media (min-width: 960px) and (max-width: 1056px)': {
      margin: theme.spacing(0, 2.5),
    },
  },
  iconBtnOverride: {
    '& svg': {
      marginTop: 0,
    },
    '& svg path': {
      stroke: `${theme.palette.text.secondary} !important`,
      fill: 'none !important',
    },
    '&.displaySettingsOpen': {
      '& svg path:first-child': {
        stroke: 'none !important',
        fill: `${theme.palette.text.animated} !important`,
      },
      '& svg path': {
        stroke: `${theme.palette.text.animated} !important`,
      },
    },
  },
  dropdownLbl: {
    '&.dropdownOpen': {
      background: 'transparent',
    },
  },
  dropdownLblContainer: {
    paddingRight: 0,
  },
  dropdown: {
    borderRadius: 4,
    width: 'auto',
    zIndex: 2,
    transition: 'all 0.2s ease-in-out',
    '&.rightDropDownMenu': {
      overflow: 'hidden',
    }
  },
  switch: {
    marginLeft: 0,
    paddingBottom: theme.spacing(1.75),
    '&:last-child': {
      paddingBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '7rem',
      marginRight: theme.spacing(2),
    },
  },
  switchLabel: {
    ...theme.typography.body3,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    marginRight: 0,
  },
  hideSwitch: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  tableDivider: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  noMargin: {
    margin: 0,
  },
  dropdownTitle: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    alignItems: 'center',
    padding: theme.spacing(0),
    marginBottom: theme.spacing(1.5),
  },
  graphicWrapper: {
    padding: 1,
  },
  modeWrapper: {
    minWidth: '90px',
  },
  modesWrapper: {
    gap: `${theme.spacing(2)}px`,
  },
  themeIcon: {
    height: '1rem',
  },
  settingsTag: {
    position: 'absolute',
    top: '50%',
    borderRadius: '3px',
    border: '1px solid',
    backgroundColor: theme.palette.background.primary,
    padding: '0 4px',
    '& > p': {
      ...theme.typography.body5,
    },
  },
  liteSettingsTag: {
    borderColor: theme.palette.primary.main,
    left: '20%',
    color: theme.palette.primary.main,
  },
  proSettingsTag: {
    borderColor: theme.palette.secondary.main,
    left: '23%',
    color: theme.palette.secondary.main,
  },
})))


export const ThemeSwitch = withStyles((theme: Theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    margin: 0,
  },
  track: {
    borderRadius: 16,
  },
  switchBase: {
    padding: 2,
    color: '#FFFFFF',
    placeItems: 'center',
    '&$checked': {
      transform: 'translateX(16px)!important',
    },
  },
  checked: {},
}))(V2Switch)

const DisplaySettingsDropdown: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [isDropDownOpen, handleDropdownOpen, handleDropdownClose] = useDropdownHandler(false)

  const openDisplaySettings = () => {
    handleDropdownOpen()
    dispatch(setFeeToggleClose())
  }

  return (
    <Dropdown
      dropdownOpen={isDropDownOpen}
      onClick={isDropDownOpen ? handleDropdownClose : openDisplaySettings}
      onMouseLeave={handleDropdownClose}
      label={(
        <BottomButton icon={TradeLayout} label='Trade Layout' />
      )}
      hasDropdownIcon={false}
      leftUpperMenu
      overrideLength
      className={classes.dropdown}
      labelClassName={classes.dropdownLbl}
      containerClass={classes.dropdownLblContainer}
    >
      <DisplaySettings />
    </Dropdown>
  )
}

export default DisplaySettingsDropdown
export { DisplaySettings, DisplaySettingsDropdown }
