import { IBCUtils } from 'carbon-js-sdk'

import { PacketForwardData } from 'js/constants/TransferOptions'

// So that ibc tx will not timeout because of a larger chain id revision number
export const getHighestRevisionNumber = (packetForwardData: PacketForwardData | undefined, chainIdObj: IBCUtils.ChainIdOutput): number => {
  let revNumber = 0
  if (!packetForwardData) return chainIdObj.version
  const { forwardInterchain } = packetForwardData
  forwardInterchain.forEach(({ fromChainId, toChainId }) => {
    const fromChainIdVersion = IBCUtils.parseChainId(fromChainId).version
    const toChainIdVersion = IBCUtils.parseChainId(toChainId).version
    revNumber = Math.max(revNumber, fromChainIdVersion, toChainIdVersion)
  })
  return revNumber
}