import { Divider, Grid, Hidden, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingIcon from "js/components/Common/LoadingIcon"
import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { useCommonStyles } from "js/utils"


const OverviewFallback: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container className={classes.root} spacing={isMobile ? 1 : 3}>
      <Grid item xs={12}>
        {/* balance */}
        <div className={clsx(classes.cardBase, classes.noPadding)}>
          <Grid container>
            <Grid item xs={12} md={4} className={classes.gridItem}>
              <LoadingSkeleton paddingBottom={48} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton />
            </Grid>
            <Hidden smDown>
              <Divider className={classes.verticalDivider} orientation="vertical" flexItem />
            </Hidden>
            <Grid item xs={12} md={8} className={clsx(classes.gridItem, 'graph')}>
              <div className={clsx(commonClasses.alignItemsCenter, commonClasses.justifyContentCenter, commonClasses.fullHeight)}>
                <LoadingIcon size="2.5rem" />
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} md={7}>
        {/* rewards */}
        <div className={clsx(classes.cardBase, 'secondRow')}>
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton />
        </div>
      </Grid>
      <Grid item xs={12} md={5}>
        {/* fee tier */}
        <div className={clsx(classes.cardBase, 'secondRow')}>
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton />
        </div>
      </Grid>
      <Grid item xs={12}>
        {/* assets */}
        <div className={clsx(classes.cardBase, classes.assetsBox)}>
          <LoadingSkeleton width={300} paddingBottom={48} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton />
        </div>
      </Grid>
    </Grid>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    }
  },
  cardBase: {
    backgroundColor: theme.palette.background.primary,
    borderRadius: '4px',
    padding: theme.spacing(2.5, 4, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2.5, 2.5, 3),
    },
    '&.secondRow': {
      height: '140px',
      [theme.breakpoints.down('sm')]: {
        height: '180px',
      }
    },
  },
  noPadding: {
    padding: 0,
  },
  assetsBox: {
    padding: theme.spacing(3, 4),
    height: '376px',
  },
  gridItem: {
    padding: theme.spacing(2.5, 4),
    height: '338px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2.5),
      height: '201px',
      '&.graph': {
        height: '360px',
      },
    },
  },
  verticalDivider: {
    marginRight: '-1px',
  },
}))

export default OverviewFallback