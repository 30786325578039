import BigNumber from 'bignumber.js'
import { Staking } from 'carbon-js-sdk/lib/codec'
import { AllianceAsset } from 'carbon-js-sdk/lib/codec/alliance/alliance/alliance'
import { Proposal } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/gov'
import { List } from 'immutable'
import { action } from 'typesafe-actions'

import { Validator } from 'js/models/staking/Validator'

import {
  APRStats,
  DelegationReward,
  StakeActionTypes,
  Status,
  UserRedelegation,
  UserUnbonding,
  ValidatorParticipations,
} from './types'

export interface StakingInformation {
  totalStaked: string
  usdPrice: string
}

export const startStakeSaga = () => action(
  StakeActionTypes.START_SAGA,
)

export const setStatuses = (statuses: Status) => action(
  StakeActionTypes.SET_STATUSES, statuses,
)

export const setStakingInformation = (stakingInformation: StakingInformation) => action(
  StakeActionTypes.SET_STAKING_INFORMATION, stakingInformation,
)

export const setStakingValidators = (validators: List<Validator>) => action(
  StakeActionTypes.SET_VALIDATORS, validators,
)

export const setAprStats = (payload: APRStats) => action(
  StakeActionTypes.SET_APR_STATS, payload,
)

export const reloadAprStats = () => action(StakeActionTypes.RELOAD_APR_STATS)

export const setValidatorDelegations = (validators: List<Validator>) => action(
  StakeActionTypes.SET_VALIDATOR_DELEGATIONS, validators,
)

export const setDelegations = (delegations: Staking.DelegationResponse[]) => action(
  StakeActionTypes.SET_DELEGATIONS, delegations,
)

export const setUnbondingDelegations = (delegations: UserUnbonding[]) => action(
  StakeActionTypes.SET_UNBONDING_DELEGATIONS, delegations,
)

export const setRedelegations = (delegations: UserRedelegation[]) => action(
  StakeActionTypes.SET_REDELEGATIONS, delegations,
)

export const setDelegationRewards = (delegationRewards: DelegationReward[]) => action(
  StakeActionTypes.SET_DELEGATION_REWARDS, delegationRewards,
)

export const setValidatorParticipations = (participation: ValidatorParticipations[]) => action(
  StakeActionTypes.SET_VALIDATOR_PARTICIPATIONS, participation,
)

export const queryStakingValidators = () => action(
  StakeActionTypes.QUERY_STAKING_VALIDATORS,
)

export const queryDelegatorDelegations = () => action(
  StakeActionTypes.QUERY_DELEGATOR_DELEGATIONS,
)

export const queryDelegatorDelegationRewards = () => action(
  StakeActionTypes.QUERY_DELEGATOR_DELEGATION_REWARDS,
)

export const setAllianceAssets = (payload: AllianceAsset[]) => action(
  StakeActionTypes.SET_ALLIANCES, payload,
)

export const setProposals = (payload: Proposal[]) => action(
  StakeActionTypes.SET_PROPOSALS, payload,
)

export const setAvgBlockTime = (payload: BigNumber | null) => action(
  StakeActionTypes.SET_AVG_BLOCK_TIME, payload,
)

export const setTotalDelegationRewardsUsd = (payload: BigNumber) => action(
  StakeActionTypes.SET_TOTAL_DELEGATION_REWARDS_USD, payload,
)
