export interface SectionTabInfo {
  label: string
  id: string
}

export const EXCHANGE_SECTIONS = {
  chart: 'price-chart',
  orderBook: 'order-book',
  leverageOrBalance: 'leverageOrBalance',
  positionOrOrder: 'positionOrOrder',
}

export const EXCHANGE_SPOT_SECTION_TABS: SectionTabInfo[] = [
  {
    label: 'Price Chart',
    id: EXCHANGE_SECTIONS.chart,
  },
  {
    label: 'Order Book',
    id: EXCHANGE_SECTIONS.orderBook,
  },
  {
    label: 'Your Balance',
    id: EXCHANGE_SECTIONS.leverageOrBalance,
  },
  {
    label: 'Orders',
    id: EXCHANGE_SECTIONS.positionOrOrder,
  }
]

export const EXCHANGE_FUTURES_SECTION_TABS: SectionTabInfo[] = [
  {
    label: 'Price Chart',
    id: EXCHANGE_SECTIONS.chart,
  },
  {
    label: 'Order Book',
    id: EXCHANGE_SECTIONS.orderBook,
  },
  {
    label: 'Leverage',
    id: EXCHANGE_SECTIONS.leverageOrBalance,
  },
  {
    label: 'Positions',
    id: EXCHANGE_SECTIONS.positionOrOrder,
  },
]
