import { createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import useGetRouteMatch from 'js/hooks/useGetRouteMatch'

interface Props {
  children: React.ReactNode
  className?: string
  isDisplayFlex?: boolean
}

const PageContainer: React.FC<Props> = (props: Props) => {
  const { children, className, isDisplayFlex = true } = props
  const classes = useStyles()
  const { isTrade, isLite, isPools } = useGetRouteMatch()

  return (
    <div className={clsx(classes.container, { [classes.fullBackground]: (isTrade || isLite), isDisplayFlex }, className)}>
      {!(isTrade || isLite || isPools) && (
        <Helmet>
          <title>Demex | The Only DEX You Need</title>
        </Helmet>
      )}
      {children}
    </div>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  container: {
    color: theme.palette.text.primary,
    flex: 1,
    '&.isDisplayFlex': {
      display: 'flex',
    },
  },
  fullBackground: {
    background: theme.palette.background.base,
  },
}))

export default PageContainer
