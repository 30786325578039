import React from "react"
import { useLocation } from "react-router"

import Paths from "js/constants/paths"

import CDPMainFallback from "./CDPMainFallback"
import LiquidationDetailsFallback from "./LiquidationDetailsFallback"
import LiquidationsFallback from "./LiquidationsFallback"

import CommonFormFallback from "../CommonFormFallback"

const CDPSwitch = () => {
  const location = useLocation()

  if (location.pathname.includes('nitron/liquidations/risky')) {
    return <LiquidationDetailsFallback />
  }
  if (location.pathname.includes('nitron/liquidations/repay')
    || location.pathname.includes('nitron/lend')
    || location.pathname.includes('nitron/withdraw')
    || location.pathname.includes('nitron/return')
    || location.pathname.includes('nitron/borrow')
    || location.pathname.includes('nitron/mint')
    || location.pathname.includes('nitron/edit')
  ) {
    return <CommonFormFallback />
  }
  switch (location.pathname) {
    case Paths.Nitron.Main: {
      return <CDPMainFallback />
    }
    case Paths.Nitron.Liquidations: {
      return <LiquidationsFallback />
    }
    case Paths.Nitron.EMode: {
      return <CommonFormFallback />
    }
    default: {
      return <CDPMainFallback />
    }
  }
}

export default CDPSwitch