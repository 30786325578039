import {
  CheckboxProps,
  Checkbox as MuiCheckbox,
  SvgIcon,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import { omit } from 'lodash'
import React from 'react'

import { ReactComponent as CheckboxDefaultIcon } from 'assets/CheckboxDefault.svg'
import { ReactComponent as CheckboxEnabledIcon } from 'assets/CheckboxEnabled.svg'

interface Props extends CheckboxProps {
  iconClassName?: string
}

// Material ui's checkbox but default to our own checkbox enabled icon
const Checkbox: React.FC<Props> = (props: Props) => {
  const { checkedIcon, iconClassName, className } = props
  const classes = useStyles()
  const checkedCheckboxIcon = (
    <SvgIcon
      className={clsx(iconClassName)}
      component={CheckboxEnabledIcon}
    />
  )
  const defaultCheckboxIcon = (
    <SvgIcon
      className={clsx(classes.defaultCheckboxIcon, iconClassName)}
      component={CheckboxDefaultIcon}
    />
  )

  return (
    <MuiCheckbox
      {...omit(props, ['iconClassName'])}
      className={clsx(classes.root, className)}
      checkedIcon={checkedIcon || checkedCheckboxIcon}
      icon={defaultCheckboxIcon}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    />
  )
}
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginRight: theme.spacing(0),
    '& svg': {
      fill: 'none',
    },
    '&:hover, &.Mui-Checked:hover': {
      backgroundColor: `${theme.palette.action.hover} !important`,
    },
    '&.Mui-checked svg > path:first-child': {
      fill: theme.palette.text.demexSolid,
    },
  },
}))

export default Checkbox
