import BigNumber from 'bignumber.js'
import { OrderModule } from 'carbon-js-sdk'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import { List } from 'immutable'
import { useSelector } from 'react-redux'

import { SHIFT_DECIMALS } from 'js/constants/assets'
import { isFutures, isSpot } from 'js/models/Market'
import { isBuy, isMarket } from 'js/models/Order'
import { getMarketsAsMap } from 'js/state/modules/exchange/selectors'
import { Market, OpenOrderLevel } from 'js/state/modules/exchange/types'
import { getOpenBuys, getOpenSells } from 'js/state/modules/orderBook/selectors'
import { OrderBookEntry } from 'js/state/modules/orderBook/types'
import { BN_ZERO, parseNumber } from 'js/utils/number'
import { shiftByBaseDp, sortByPriceEntry, unshiftByDiffDp } from 'js/utils/order'

import useMarketStats from './useMarketStats'

export const getMarkPrice = (orders: List<OrderBookEntry>, quantity: BigNumber, market: Market | null | undefined) => {
  let sumQuantity = BN_ZERO
  let sumTotal = BN_ZERO
  let remainingQuantity = quantity

  orders.every((order) => {
    if (remainingQuantity.isZero()) {
      return false
    }
    const adjustedQuantity = shiftByBaseDp(market, parseNumber(order.quantity, BN_ZERO)!)
    const currentQuantity = BigNumber.min(remainingQuantity, adjustedQuantity)
    sumQuantity = sumQuantity.plus(currentQuantity)

    const adjustedPrice = unshiftByDiffDp(market, parseNumber(order.price, BN_ZERO)!)
    const currentTotal = adjustedPrice.times(currentQuantity)
    sumTotal = sumTotal.plus(currentTotal)

    remainingQuantity = remainingQuantity.minus(currentQuantity)
    return true
  })

  return !sumQuantity.isZero() ? sumTotal.div(sumQuantity) : BN_ZERO
}

export const getMarkPriceByTotal = (orders: List<OrderBookEntry>, targetTotal: BigNumber, market: Market | null | undefined) => {
  let sumQuantity = BN_ZERO
  let sumTotal = BN_ZERO

  orders.every((order) => {
    if (sumTotal.gte(targetTotal)) {
      return false
    }
    const adjustedQuantity = shiftByBaseDp(market, parseNumber(order.quantity, BN_ZERO)!)
    const adjustedPrice = unshiftByDiffDp(market, parseNumber(order.price, BN_ZERO)!)

    const currentTotal = adjustedPrice.times(adjustedQuantity)

    if (sumTotal.plus(currentTotal).gt(targetTotal)) {
      const remainingTotal = targetTotal.minus(sumTotal)
      const remainingQuantity = remainingTotal.div(adjustedPrice)
      sumQuantity = sumQuantity.plus(remainingQuantity)
      sumTotal = targetTotal
    } else {
      sumQuantity = sumQuantity.plus(adjustedQuantity)
      sumTotal = sumTotal.plus(currentTotal)
    }
    return true
  })

  return !sumTotal.isZero() ? targetTotal.div(sumQuantity) : BN_ZERO
}

export const getMarkPriceFromMarketBook = (orders: List<OpenOrderLevel>, quantity: BigNumber, market: Market | null | undefined) => {
  if (!market) return BN_ZERO

  const orderBookEntries: List<OrderBookEntry> = orders.map(({ price, quantity }: OpenOrderLevel) => {
    return {
      price: bnOrZero(price).shiftedBy(-SHIFT_DECIMALS).toString(),
      quantity,
      market: market.id,
    }
  })
  return getMarkPrice(orderBookEntries, quantity, market)
}


const useMarkPrice = (marketId: string) => {
  const markets = useSelector(getMarketsAsMap)
  const marketStats = useMarketStats(marketId)
  const openBuys = useSelector(getOpenBuys)
  const openSells = useSelector(getOpenSells)

  return (order: OrderModule.CreateOrderParams | null): BigNumber => {
    if (!order) return new BigNumber('-')
    const market = markets.get(order?.marketId || '')

    let markPrice = new BigNumber('-')
    if (isFutures(market?.marketType)) {
      markPrice = unshiftByDiffDp(market, bnOrZero(marketStats?.mark_price))
    }
    if (isSpot(market?.marketType)) {
      // Calculate using market price calculation (as stipulated in Spot Order Form)
      if (isMarket(order?.orderType || '')) {
        const orders = isBuy(order?.side || '') ? sortByPriceEntry(openSells, true) : sortByPriceEntry(openBuys, false)
        markPrice = getMarkPrice(orders, order?.quantity, market)
      }
    }

    return markPrice
  }
}

export default useMarkPrice
