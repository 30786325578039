import { Grid, makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { useDimensions } from "js/hooks"
import { muiBreakpoints } from "js/theme"
import { StyleUtils, useCommonStyles } from "js/utils"

const LiquidationsFallback: React.FC = () => {
  const classes = useStyles()
  const dimensions = useDimensions()
  const commonClasses = useCommonStyles()

  const { isTablet } = React.useMemo(() => {
    return {
      isTablet: dimensions.windowSize.width < muiBreakpoints.md,
    }
  }, [dimensions.windowSize.width])

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container spacing={!isTablet ? 4 : 0} direction="row">
        <Grid item lg={5} xs={12}>
          <div className={clsx(commonClasses.flexColumn, classes.topCards, 'totalInfo')}>
            {!isTablet ? (
              <React.Fragment>
                <LoadingSkeleton />
                <LoadingSkeleton />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <LoadingSkeleton height={12} width={120} />
                <LoadingSkeleton height={12} />
                <LoadingSkeleton height={12} />
              </React.Fragment>
            )}
          </div>
        </Grid>
        <Grid item lg={7} xs={12}>
          <div className={clsx(commonClasses.flexColumn, classes.topCards, 'userInfo')}>
            {!isTablet ? (
              <React.Fragment>
                <LoadingSkeleton />
                <LoadingSkeleton />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <LoadingSkeleton height={12} width={120} />
                <LoadingSkeleton height={12} />
                <LoadingSkeleton height={12} />
              </React.Fragment>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.containerBox} style={{ minHeight: `${800}px` }}>
            <LoadingSkeleton width={180} paddingBottom={48} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.base,
    minHeight: 'calc(100vh - 48px - 28px)',
    maxWidth: 'calc(100% - 17rem)',
    width: '100%',
    justifyContent: 'center',
    '@media (min-width: 2560px)': {
      width: '1800px',
      margin: '0 auto',
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
      maxWidth: '100%',
      flex: 1,
      padding: theme.spacing(1, 0),
    },
  },
  grid: {
    maxWidth: '100%',
    margin: theme.spacing(1.5, 'auto'),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 1.5),
    },
    [theme.breakpoints.down('md')]: {
      gap: '8px',
      margin: theme.spacing(0, 'auto'),
    },
  },
  topCards: {
    gap: '8px',
    padding: theme.spacing(2.5, 4, 3),
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.primary,
    minHeight: '112px',
    '&.totalInfo': {
      padding: theme.spacing(2.5, 4, 3),
      background: theme.palette.background.primary,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5, 2),
        minHeight: '95px',
      },
    },
    '&.userInfo': {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5, 2),
        minHeight: '94px',
      },
    }
  },
  containerBox: {
    ...StyleUtils.scrollBar(theme),
    backgroundColor: theme.palette.background.primary,
    borderRadius: 4,
    padding: theme.spacing(3, 4),
    overflow: 'overlay',
    '@supports not (overflow:overlay)': {
      overflow: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5, 2),
    },
  }
}))

export default LiquidationsFallback