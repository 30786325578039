
import { TypeUtils } from 'carbon-js-sdk'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

import { useAdjustedRecentTradesByMarket, useGetRouteMatch, useMarketDisplayName } from 'js/hooks'
import { getMarket } from 'js/state/modules/exchange/selectors'
import { BN_ZERO, parseNumber } from 'js/utils/number'

interface Props extends React.HTMLAttributes<HTMLDivElement> {

}

interface TitleDescItem {
  title: string
  description: string
}

export const titleDescriptionMap: TypeUtils.SimpleMap<TitleDescItem> = {
  Borrow: {
    title: 'Demex | Nitron, lend and borrow digital assets',
    description: 'Experience the power of Nitron, the Demex money market, where you can lend and borrow digital assets to earn APY and enjoy exclusive incentives.',
  },
  Pools: {
    title: 'Demex | Liquidity Pools',
    description: 'Maximise your earnings by providing liquidity for spot and perpetual markets on Demex\'s active liquidity pools',
  },
  PerpPools: {
    title: 'Demex | Perpetuals Liquidity Pool',
    description: 'Discover perpetual liquidity pools on Demex and earn APY through trading fees, traders PnL, and funding rates',
  },
}

const AppTitle: React.FC<Props> = (props: Props) => {
  const adjustedTrades = useAdjustedRecentTradesByMarket()
  const lastPrice = adjustedTrades[0]?.price
  const { isTrade, isLite, isSwap, isBorrow, isPools, isPerpPool } = useGetRouteMatch()
  const market = useSelector(getMarket)
  const getDisplayName = useMarketDisplayName()

  const { title, description } = useMemo((): {
    title?: string
    description?: string
  } => {
    if (isPools) {
      return titleDescriptionMap.Pools
    }
    if (isBorrow) {
      return titleDescriptionMap.Borrow
    }
    if (isPerpPool) {
      return titleDescriptionMap.PerpPools
    }
    if (market && lastPrice && (isTrade || isLite || isSwap)) {
      const lastPriceBN = parseNumber(lastPrice, BN_ZERO)!
      return {
        title: `${lastPriceBN.toFormat()} | ${getDisplayName(market)} | Demex`,
      }
    }
    return {}
  }, [market, lastPrice, isTrade, isLite, isSwap, isBorrow, isPerpPool, isPools]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Helmet>
      <title>
        {title ?? 'Demex | The Only DEX You Need'}
      </title>
      {description && (
        <React.Fragment>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} data-rh="true" />
        </React.Fragment>
      )}
    </Helmet>
  )
}

export default AppTitle
