import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { useCommonStyles } from 'js/utils'

import TypographyLabel from '../TypographyLabel'

interface Props extends ButtonProps {
  label?: string | React.ReactNode
  labelClass?: string
}

const InputLabelButton: React.FC<Props> = (props: Props) => {
  const { className, label, labelClass, children, ...rest } = props

  const classes = useStyles(props)
  const commonClasses = useCommonStyles()

  return (
    <div className={clsx(commonClasses.alignItemsCenter, commonClasses.flexRow)}>
      {!!label && (
        <TypographyLabel className={clsx(classes.label, labelClass)} color="textSecondary">
          {label}
        </TypographyLabel>
      )}
      <Button variant="text" color="primary" {...rest} className={clsx(classes.root, className)}>
        {children}
      </Button>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  label: {},
  root: {
    textTransform: 'none',
    padding: theme.spacing(0.5, 1),
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(-0.5),
    marginRight: theme.spacing(-0.5),
    '& > span:first-child': {
      lineHeight: '1em',
    },
  },
}))

export default InputLabelButton
