import { WSModels } from 'carbon-js-sdk'
import { RecordOf } from 'immutable'

export interface RecentTradesStateProps {
  readonly recentTrades: WSModels.RecentTrade[]
}

export type RecentTradesState = RecordOf<RecentTradesStateProps>

export const RecentTradesActionTypes = {
  ADD_RECENT_TRADES: '@recentTrades/ADD_RECENT_TRADES',
  SET_RECENT_TRADES: '@recentTrades/SET_RECENT_TRADES',
  CLEAR_RECENT_TRADES: '@recentTrades/CLEAR_RECENT_TRADES',
}
