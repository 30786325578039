import BigNumber from 'bignumber.js'
import { TypeUtils } from 'carbon-js-sdk'
import { Connection, ExternalTokenMapping } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/bridge/bridge'
import { RecordOf } from 'immutable'

export interface BridgeStateProps {
  readonly externalTokens: TypeUtils.SimpleMap<ExternalTokenMapping>
  readonly connections: TypeUtils.SimpleMap<Connection>
}

export type BridgeState = RecordOf<BridgeStateProps>

export enum BridgeActionTypes {
  SET_EXTERNAL_TOKENS = '@bridge/SET_EXTERNAL_TOKENS',
  SET_CONNECTIONS = '@bridge/SET_CONNECTIONS',
}

export interface RelayFees {
  symbol: string
  amount: BigNumber
  value: BigNumber
}