import { Backdrop, Paper, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { CSSProperties, PropsWithChildren, useEffect, useRef } from 'react'

import { StyleUtils, useCommonStyles } from 'js/utils'

interface Props extends PropsWithChildren {
  showDropdown: boolean
  onClickAway: () => void
  onMouseLeave?: () => void
  dropdownPaperClass?: string
  backdropClass?: string
  backdropStyle?: CSSProperties
  heightOffset?: number
}

const DropdownContent: React.FC<Props> = (props: Props) => {
  const { children, showDropdown, onClickAway, onMouseLeave, dropdownPaperClass, backdropClass, backdropStyle, heightOffset = 15 } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const contentRef = useRef<HTMLDivElement | null>(null)
  const paperRef = useRef<HTMLDivElement | null>(null)
  const [dropdownHeight, setDropdownHeight] = React.useState<number | 'auto'>('auto')

  useEffect(() => {
    if (showDropdown && paperRef.current && contentRef.current) {
      const contentHeight = contentRef.current.offsetHeight
      const paperStyle = window.getComputedStyle(paperRef.current)
      const paperMarginHeight = parseFloat(paperStyle.marginTop) + parseFloat(paperStyle.marginBottom)
      const paperPaddingHeight = parseFloat(paperStyle.paddingTop) + parseFloat(paperStyle.paddingBottom)
      const paperBorderHeight = parseFloat(paperStyle.borderTopWidth) + parseFloat(paperStyle.borderBottomWidth)
      
      const totalHeight = contentHeight + paperMarginHeight + paperPaddingHeight + paperBorderHeight + heightOffset
      setDropdownHeight(totalHeight)
    }
  }, [showDropdown, heightOffset])

  return (
    <div className={clsx(classes.dropdownContainer, commonClasses.posRelative)}>
      {showDropdown && (
        <Backdrop onClick={onClickAway} open invisible className={clsx(classes.backdrop, backdropClass)} style={backdropStyle} />
      )}
      <Paper
        ref={paperRef}
        className={clsx(classes.dropdown, { showDropdown }, dropdownPaperClass)}
        elevation={2}
        onMouseLeave={onMouseLeave}
        style={{ height: showDropdown ? dropdownHeight : '0' }}>
        <div ref={contentRef}>
          {children}
        </div>
      </Paper>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  dropdownContainer: {
    border: 'none',
  },
  dropdown: {
    ...StyleUtils.scrollBar(theme),
    transition: 'all 0.2s ease-in-out',
    display: 'block',
    zIndex: 1000,
    position: 'absolute',
    width: '100%',
    top: 0,
    boxShadow: StyleUtils.dropShadow(theme),
    left: 0,
    borderRadius: 2,
    maxHeight: '300px !important',
    opacity: 0,
    overflow: 'overlay',
    '@supports not (overflow:overlay)': {
      overflow: 'auto',
    },
    '&.showDropdown': StyleUtils.dropdownAnimation(true),
  },
  backdrop: {
    zIndex: 0,
    ...StyleUtils.blurOverride(),
  },
}))

export default DropdownContent
