import BigNumber from 'bignumber.js'
import { OrderModule } from 'carbon-js-sdk'
import { useLocation } from 'react-router'

import { OrderFormState } from 'js/state/modules/orderManager/types'


function useQuery() {
  const { search } = useLocation()
  return new URLSearchParams(search)
}

const useUrlOrderParser = () => {
  const query = useQuery()
  return () => {
    const queryOrderType: string | null = query.get('orderType')
    const queryPrice = query.get('price')
    const queryQuantity = query.get('quantity')
    const querySide = query.get('side')
    const queryStopPrice = query.get('stopPrice') // mainly use for order as position doesn't have this info
    const queryTriggerType = query.get('triggerType') // mainly use for order as position doesn't have this info
    const queryPostOnly = query.get('postOnly') // use for copy order
    const queryReduceOnly = query.get('reduceOnly') // use for copy order
    if (!queryReduceOnly && !queryPostOnly && !queryTriggerType && !queryStopPrice && !queryOrderType && !queryPrice && !queryQuantity && !querySide) {
      return undefined
    }
    const newFormValues: Partial<OrderFormState> = {}
    if (queryPostOnly && queryPostOnly === 'true') {
      newFormValues.isPostOnly = true
    }
    if (queryReduceOnly && queryReduceOnly === 'true') {
      newFormValues.isReduceOnly = true
    }
    if (queryOrderType) {
      const enumOrderType = queryOrderType as OrderModule.OrderType
      const isValid = Object.values(OrderModule.OrderType).includes(enumOrderType)
      if (isValid) newFormValues.orderType = enumOrderType
    }
    if (queryPrice) {
      const queryPriceBN = new BigNumber(queryPrice)
      const isValid = !(queryPriceBN.isNaN())
      if (isValid) {
        newFormValues.inputPrice = queryPriceBN.toString(10)
        newFormValues.price = queryPriceBN
      }
    }
    if (queryQuantity) {
      const queryQuantityBN = new BigNumber(queryQuantity)
      const isValid = !(queryQuantityBN.isNaN())
      if (isValid) {
        newFormValues.quantity = queryQuantityBN
        newFormValues.inputQuantity = queryQuantityBN.toString(10)
      }
    }
    if (querySide) {
      const enumSide = querySide as OrderModule.OrderSide
      const isValid = Object.values(OrderModule.OrderSide).includes(enumSide)
      if (isValid) newFormValues.side = enumSide
    }

    if (queryStopPrice) {
      const queryStopPriceBN = new BigNumber(queryStopPrice)
      const isValid = !(queryStopPriceBN.isNaN())
      if (isValid) {
        newFormValues.stopPrice = queryStopPriceBN
        newFormValues.inputStopPrice = queryStopPrice
      }
    }

    if (queryTriggerType) {
      const enumTriggerValue = queryTriggerType as OrderModule.TriggerType
      const isValid = Object.values(OrderModule.TriggerType).includes(enumTriggerValue)
      if (isValid) newFormValues.triggerType = enumTriggerValue
    }
    return newFormValues
  }
}
export default useUrlOrderParser
