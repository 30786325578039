import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import { ExchangeMode, fallbackDefaultPerpMarkets, fallbackDefaultSpotMarkets } from 'js/constants/markets'
import Paths from 'js/constants/paths'
import { useGetRouteMatch } from 'js/hooks'
import { isPerpetualMarket, isSpotMarket, isValidMarket } from 'js/models/Market'
import { getCarbonSDK, getDemexConfig, getNet } from 'js/state/modules/app/selectors'
import { setIsSimpleMode, setLastMarket, setLastSwapMarket, setMarketName } from 'js/state/modules/exchange/actions'
import { getLastMarket, getLastSwapMarket, getMarket, getMarketDisplayNames, getMarkets, getMarketsAliases, getMarketsAsMap } from 'js/state/modules/exchange/selectors'
import { Market } from 'js/state/modules/exchange/types'
import { SimpleMap } from 'js/utils/types'

interface GetCurrentMarketObj {
  activeMarket: Market | undefined,
  validMarkets: SimpleMap<Market>
}

const stripFirstSlash = (marketStr: string) => {
  return marketStr.indexOf('/') === 0 ? marketStr.slice(1) : marketStr
}

const pageRegex = /^\/(lite|trade|swap)/

export default (exchangeMode: ExchangeMode): GetCurrentMarketObj => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const demexConfig = useSelector(getDemexConfig)
  const lastMarket = useSelector(getLastMarket)
  const lastSwapMarket = useSelector(getLastSwapMarket)
  const market = useSelector(getMarket)
  const markets = useSelector(getMarkets)
  const marketsAliases = useSelector(getMarketsAliases)
  const marketDisplayNames = useSelector(getMarketDisplayNames)
  const network = useSelector(getNet)
  const sdk = useSelector(getCarbonSDK)
  const marketMap = useSelector(getMarketsAsMap)
  const { isSwap } = useGetRouteMatch()

  const getExpectedActiveMarket = (
    currentMarket: Market | undefined,
    initialMarketName: string,
    lastVisitedMarket: string,
    lastVisitedSwapMarket: string,
  ) => {
    let expectedMarket = marketMap.get(initialMarketName)
    if (isSwap && currentMarket?.id !== lastVisitedSwapMarket) {
      expectedMarket = marketMap.get(lastVisitedSwapMarket)
    }
    if (!isSwap && currentMarket && currentMarket.id !== lastVisitedMarket) {
      expectedMarket = marketMap.get(lastVisitedMarket)
    }
    return expectedMarket
  }

  const activeMarket = useMemo(() => {
    let currentMarket: Market | undefined
    if (!sdk || marketMap.size <= 0 || Object.values(marketsAliases).length <= 0) return currentMarket

    const lastVisitedMarket = lastMarket[network] ?? ''
    const lastVisitedSwapMarket = lastSwapMarket[network] ?? ''
    const fallbackMarket = fallbackDefaultPerpMarkets[network] ?? ''
    const fallbackSpotMarket = fallbackDefaultSpotMarkets[network] ?? ''

    // Get marketname from url
    const urlMarketName = stripFirstSlash(location.pathname.replace(pageRegex, ''))
    const queryMarketName = marketsAliases[urlMarketName] ?? urlMarketName

    if (!market || market.id !== queryMarketName) {
      // Try find requested market
      currentMarket = getExpectedActiveMarket(currentMarket, queryMarketName, lastVisitedMarket, lastVisitedSwapMarket)
    } else {
      currentMarket = getExpectedActiveMarket(currentMarket, market.id, lastVisitedMarket, lastVisitedSwapMarket)

      if (urlMarketName.length === 0) {
        const marketId = currentMarket?.id ?? ''
        const displayName = marketDisplayNames[marketId] ?? ''
        let tradePath = Paths.Lite
        if (exchangeMode === 'pro') {
          tradePath =  Paths.Trade.Main
        }
        const path = isSwap ? Paths.Swap.Home : tradePath
        history.push(`${path}/${displayName}`)
      }
    }

    // If not in requested market obj, search for last visited market > fallback market (should have by now)
    if (!currentMarket) {
      if (isSwap) {
        currentMarket = marketMap.get(lastVisitedSwapMarket) ?? marketMap.get(fallbackSpotMarket)
      } else {
        currentMarket = marketMap.get(lastVisitedMarket) ?? marketMap.get(fallbackMarket)
      }
    }

    const isBlacklistedMarket = !isValidMarket(currentMarket, demexConfig.blacklistedMarkets)
    if (isBlacklistedMarket) {
      if (isSpotMarket(currentMarket)) {
        currentMarket = marketMap.find((m) => isSpotMarket(m) && isValidMarket(m, demexConfig.blacklistedMarkets))
      } else {
        currentMarket = marketMap.find((m) => isPerpetualMarket(m) && isValidMarket(m, demexConfig.blacklistedMarkets))
      }
    }

    return currentMarket
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, marketsAliases, location.pathname, network, lastMarket, lastSwapMarket, exchangeMode, market, marketMap, history, marketDisplayNames])

  const validMarketsObj = useMemo(() => {
    let marketTypeCheck = isPerpetualMarket(activeMarket) ? isPerpetualMarket : isSpotMarket
    if (!activeMarket) {
      marketTypeCheck = isPerpetualMarket
    }
    // init valid markets obj first
    const validMarketsObj = Object.values(markets).reduce((prev: SimpleMap<Market>, market: Market) => {
      if (!isValidMarket(market, demexConfig.blacklistedMarkets)) return prev
      if (exchangeMode === 'lite' && !marketTypeCheck(market)) return prev
      prev[market.id] = market
      return prev
    }, {})
    return validMarketsObj
  }, [demexConfig.blacklistedMarkets, exchangeMode, markets, activeMarket])

  useEffect(() => {
    if (!sdk) return

    // If still no market, return (probably cos chain down)
    if (!activeMarket || market?.id === activeMarket.id) return

    dispatch(setMarketName(activeMarket.id))
    const marketId = activeMarket?.id ?? ''
    const displayName = marketDisplayNames[marketId] ?? ''
    let tradePath = Paths.Lite
    if (exchangeMode === 'pro') {
      tradePath = Paths.Trade.Main
    }
    const path = isSwap ? Paths.Swap.Home : tradePath

    history.push(`${path}/${displayName}`)
    if (!isSwap) {
      dispatch(setLastMarket(activeMarket.id, network))
      dispatch(setIsSimpleMode(exchangeMode !== 'pro'))
    } else {
      dispatch(setLastSwapMarket(activeMarket.id, network))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketDisplayNames, location.pathname, lastMarket, lastSwapMarket, validMarketsObj, network, market, sdk, exchangeMode, activeMarket])

  return {
    activeMarket,
    validMarkets: validMarketsObj,
  }
}
