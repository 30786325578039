import { IconButton, Theme, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import React, { Suspense, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import Button from 'js/components/Common/Button'
import SvgIcon from 'js/components/Common/SvgIcon'
import { FUTURES_QUOTES } from 'js/constants/assets'
import Paths from 'js/constants/paths'
import { EvtCategory, useGetRouteMatch, useEventTrackers } from 'js/hooks'
import { isWalletConnected } from 'js/state/modules/account/selectors'
import { clearDropdowns, setMobileMenuOpen } from 'js/state/modules/app/actions'
import { getCarbonSDK, getNet, getOpenMenu } from 'js/state/modules/app/selectors'
import { getIsSimpleMode } from 'js/state/modules/exchange/selectors'
import { getOpenRefPopUp, getShowRefBanner } from 'js/state/modules/referrals/selectors'
import { checkSelectToken } from 'js/state/modules/walletBalance/actions'
import { StyleUtils, getTokenName, useCommonStyles } from 'js/utils'

import { ReactComponent as Cross } from 'assets/Cross.svg'
import { ReactComponent as DemexLogoLight } from 'assets/DemexLogoLight.svg'
import { ReactComponent as MenuIcon } from 'assets/Menu.svg'

import Toolbar from './Toolbar'

const InstallDialog = React.lazy(() => import('./components/PWA/InstallDialog'))
const ReferralBanner = React.lazy(() => import('js/components/PromotionHub/Referrals/ReferralBanner'))
const MenuTabs = React.lazy(() => import('./components/MenuTabs'))
const WalletBtn = React.lazy(() => import('./components/WalletBtn'))
const InternetConnectionDropdown = React.lazy(() => import('./dropdowns/InternetConnectionDropdown'))
const NotificationDropdown = React.lazy(() => import('./dropdowns/InboxDropdown'))

interface Props {
  isMaintenance?: boolean
}

const DesktopHeaderBar: React.FC<Props> = (props: Props) => {
  const { isAccountMain, isPools, isLeaderboard, isWelcomeOffer } = useGetRouteMatch()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const shouldRender = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const eventTrackers = useEventTrackers()

  const sdk = useSelector(getCarbonSDK)
  const net = useSelector(getNet)
  const openMenu = useSelector(getOpenMenu)
  const isLoggedIn = useSelector(isWalletConnected)
  const isSimpleMode = useSelector(getIsSimpleMode)
  const openRefPopUp = useSelector(getOpenRefPopUp)
  const showReferralBanner = useSelector(getShowRefBanner)

  const futuresDenom = FUTURES_QUOTES[net]?.[0]
  const futuresSymbol = getTokenName(futuresDenom ?? '', sdk?.token, net)
  const sidebar = isAccountMain || isPools || isLeaderboard // TODO: to update with pages with sidebar

  const handleOpen = () => {
    dispatch(setMobileMenuOpen())
  }

  const handleClose = () => {
    dispatch(clearDropdowns())
  }

  const handleClickTrade = () => {
    if (isWelcomeOffer) {
      return
    }
    history.push(Paths.Home)
    eventTrackers.sendEvent(EvtCategory.Navigation, 'menu_trade', { source: 'landingPage' }, isSimpleMode ? 'lite' : 'pro')
  }

  const handleDepositClick = (evt: React.MouseEvent) => {
    const newSelect = sdk?.token.tokenForDenom(futuresDenom ?? '')
    if (!newSelect || !futuresSymbol) return

    handleClose()
    eventTrackers.sendEvent(EvtCategory.Profile, 'deposit', { source: 'navbar' })
    dispatch(checkSelectToken(newSelect))
    history.push(`${Paths.Account.Deposit}/${newSelect.denom}`)
  }

  if (!shouldRender) return null

  return (
    <Toolbar className={clsx(classes.appBar, { sidebar, openRefPopUp })}>
      <div className={commonClasses.flexRow}>
        <div className={classes.desktopHidden}>
          {!openMenu && (
            <IconButton className={classes.leftPad} onClick={handleOpen}>
              <SvgIcon className={classes.icon} component={MenuIcon} />
            </IconButton>
          )}
          {openMenu && (
            <IconButton className={classes.leftPad} onClick={handleClose}>
              <SvgIcon className={classes.crossIcon} component={Cross} />
            </IconButton>
          )}
        </div>
        <Button
          className={clsx(classes.logo, { welcomeOffer: isWelcomeOffer })}
          color="inherit"
          baseButtonVariant="text"
          disableFocusRipple
          disableRipple
          onClick={handleClickTrade}
        >
          <SvgIcon className={clsx(classes.logoSvg, classes.logoFill)} component={DemexLogoLight} />
        </Button>
      </div>
      {!isWelcomeOffer && (
        <Suspense>
          <MenuTabs />
        </Suspense>
      )}
      <div className={classes.grow} />
      <div className={classes.buttonGroup}>
        {!isWelcomeOffer && (
          <React.Fragment>
            <div className={classes.dropdownIcons}>
              <div className={classes.mobileHidden}>
                <Suspense>
                  <InternetConnectionDropdown />
                  <InstallDialog />
                  {isLoggedIn && !isMobile && (
                    <NotificationDropdown />
                  )}
                </Suspense>
              </div>
              <Suspense>
                {showReferralBanner && (
                  <ReferralBanner />
                )}
              </Suspense>
            </div>
          </React.Fragment>
        )}
        <Suspense>
          {!props.isMaintenance && isLoggedIn && (
            <div className={classes.depositWallet}>
              <div className={classes.depositButton} onClick={handleDepositClick}>Deposit</div>
              <div className={classes.divider} />
              <WalletBtn buttonStyle={clsx(classes.connectBtnStyle)} />
            </div>
          )}

          {!props.isMaintenance && !isLoggedIn && <WalletBtn buttonStyle={clsx(classes.connectBtnStyle)} />}
        </Suspense>
      </div>
    </Toolbar>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  desktopHidden: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  mobileHidden: {
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(1.5)}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  appBar: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.primary,
    zIndex: theme.zIndex.drawer - 100,
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
    padding: 0,
    position: 'sticky',
    top: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&.openRefPopUp': {
      zIndex: 1,
    },
  },
  dropdownIcons: {
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(1.5)}px`,
    marginRight: theme.spacing(1.5),
    height: '100%',
    '@media (min-width: 960px) and (max-width: 1056px)': {
      marginRight: theme.spacing(2),
    },
  },
  logo: {
    margin: theme.spacing(0, 1, 0, 0.75),
    '&:hover, &:active': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: theme.spacing(0.75, 0.25),
    },
    '&.welcomeOffer': {
      cursor: 'auto',
      backgroundColor: theme.palette.background.primary,
    },
  },
  logoAlt: {
    margin: theme.spacing(0, 2, 0, 0),
    padding: theme.spacing(1.5, 0),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 0, 0),
    },
  },
  christmasSvg: {
    height: '2.85rem',
    '& g': {
      transform: 'translate(-1.125rem, 0.1875rem)',
    },
    [theme.breakpoints.only('xs')]: {
      height: '2.6rem',
      '& g': {
        transform: 'translate(-1rem, 0.1875rem)',
      },
    },
  },
  logoSvg: {
    height: '1.375rem',
    width: '6.125rem',
    [theme.breakpoints.only('xs')]: {
      width: '5rem',
      height: '1.125rem',
    },
  },
  logoFill: {
    '& > path:nth-child(5)': {
      fill: theme.palette.text.primary,
    },
  },
  switcheologoSvg: {
    // height: '5.7rem',
    width: '5.7rem',
    '& path': {
      fill: '#ffffff',
    },
  },
  hiddenTab: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  button: {
    ...theme.typography.label1,
    alignSelf: 'center',
    fontWeight: 600,
    padding: theme.spacing(2.5, 6),
    marginRight: theme.spacing(1.25),
    color: '#ffffff',
    opacity: 1,
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(0),
    },
  },
  gutters: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  icon: {
    '& path': {
      stroke: theme.palette.text.primary,
    },
  },
  crossIcon: {
    height: '1rem',
  },
  leftPad: {
    // marginLeft: theme.spacing(2),
    minWidth: '2.25rem',
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  connectBtnStyle: {
    '&.baseButtonContained': {
      ...theme.typography.body3,
      fontWeight: 700,
      padding: '8px 12px',
    },
  },
  depositWallet: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '6px 2px 6px 10px',
    backgroundColor: theme.palette.background.tertiary,
    height: '28px',
    '&:hover': {
      backgroundColor: theme.palette.background.tertiary,
    }
  },
  depositButton: {
    ...theme.typography.body3,
    fontWeight: 700,
    cursor: 'pointer',
  },
  divider: {
    marginLeft: '10px',
    backgroundColor: StyleUtils.commonDividerColor,
    width: '1px',
    height: '100%',
  }
}))

export default memo(DesktopHeaderBar)
