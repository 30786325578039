import BigNumber from 'bignumber.js'
import type { Insights } from 'carbon-js-sdk'
import { action } from 'typesafe-actions'

import { SimpleMap } from 'js/utils'

import { CarbonCreditsSeasonInfo, CompetitionActionTypes, CompLeaderboardFilters, DemexPointsLeaderboard, LeaderboardPnl, LossProtectionRecord, MemeTradingRecord, PnlDurations, SubCompetition, SwthRewardsRecord, UserDemexPoints, VolumeChallengeFilters } from './types'

export const setMarketCumulativeVolume = (volume: BigNumber) => action(
  CompetitionActionTypes.SET_MARKET_CUMULATIVE_VOLUME, volume
)

export const queryMarketCumulativeVolume = (competition: Insights.CompetitionItem) => action(
  CompetitionActionTypes.QUERY_MARKET_CUMULATIVE_VOLUME, competition
)

export const setVolumeLeaderboardData = (data: Insights.PerpsCompTradingVolumeEntry[]) => action(
  CompetitionActionTypes.SET_VOLUME_LEADERBOARD_DATA, data,
)

export const queryVolumeLeaderboardData = (filters: VolumeChallengeFilters) => action(
  CompetitionActionTypes.QUERY_VOLUME_LEADERBOARD_DATA,
  filters,
)

export const setPNLLeaderboardData = (data: Insights.PNLLeaderboardEntry[]) => action(
  CompetitionActionTypes.SET_PNL_LEADERBOARD_DATA, data,
)

export const queryPNLLeaderboardData = (filters: CompLeaderboardFilters) => action(
  CompetitionActionTypes.QUERY_PNL_LEADERBOARD_DATA,
  filters,
)
export const setLotteryLeaderboardData = (data: Insights.LotteryLeaderboardEntry[]) => action(
  CompetitionActionTypes.SET_LOTTERY_LEADERBOARD_DATA, data,
)

export const queryLotteryLeaderboardData = (filters: CompLeaderboardFilters) => action(
  CompetitionActionTypes.QUERY_LOTTERY_LEADERBOARD_DATA,
  filters,
)
export const setLeagueLeaderboardData = (data: Insights.LeaderboardLeagueEntry[]) => action(
  CompetitionActionTypes.SET_LEAGUE_LEADERBOARD_DATA, data,
)

export const queryLeagueLeaderboardData = (filters: CompLeaderboardFilters) => action(
  CompetitionActionTypes.QUERY_LEAGUE_LEADERBOARD_DATA,
  filters,
)

export const setCosmosLeaderboardDataMap = (dataMap: SimpleMap<Insights.LeaderboardCosmosEntry>) => action(
  CompetitionActionTypes.SET_COSMOS_DATA_MAP_LEADERBOARD_DATA, dataMap,
)

export const queryCosmosLeaderboardData = (filters: CompLeaderboardFilters) => action(
  CompetitionActionTypes.QUERY_COSMOS_LEADERBOARD_DATA,
  filters,
)

export const setCurrentCompetition = (comp: Insights.CompetitionItem | undefined) => action(
  CompetitionActionTypes.SET_CURRENT_COMPETITION,
  comp,
)

export const setPastCompetition = (comp: Insights.CompetitionItem | undefined) => action(
  CompetitionActionTypes.SET_PAST_COMPETITION,
  comp,
)

export const setUpcomingCompetition = (comp: Insights.CompetitionItem | undefined) => action(
  CompetitionActionTypes.SET_UPCOMING_COMPETITION,
  comp,
)

export const setSubCompetitions = (subCompetitionsMap: SimpleMap<SubCompetition>) => action(
  CompetitionActionTypes.SET_SUBCOMPETITIONS_MAP,
  subCompetitionsMap,
)

export const setActiveStep = (step: number) => action(
  CompetitionActionTypes.SET_ACTIVE_STEP,
  step,
)

export const setDisableOpen = (payload: boolean) => action(
  CompetitionActionTypes.SET_DISABLE_OPEN_SIGNUP,
  payload,
)

export const queryIsRegistered = (payload: string | undefined) => action(
  CompetitionActionTypes.QUERY_IS_REGISTERED,
  payload
)

export const setIsRegistered = (payload: boolean) => action(
  CompetitionActionTypes.SET_IS_REGISTERED,
  payload,
)

export const setRegisteredSubCompetitionId = (payload: string) => action(
  CompetitionActionTypes.SET_REGISTERED_SUBCOMPETITION_ID,
  payload,
)

export const queryCarbonCreditsLeaderboard = () => action(
  CompetitionActionTypes.FETCH_CARBON_CREDITS_SEASON_INFO
)

export const setCarbonCreditsSeasonInfo = (payload: CarbonCreditsSeasonInfo[]) => action(
  CompetitionActionTypes.SET_CARBON_CREDITS_SEASON_INFO,
  payload,
)

export const setCarbonCreditsRootHash = (payload: string) => action(
  CompetitionActionTypes.SET_CARBON_CREDITS_ROOT,
  payload,
)

export const setAccumulatedSeasonsRewards = (payload: SwthRewardsRecord[]) => action(
  CompetitionActionTypes.SET_ACCUMULATED_SEASONS_REWARDS,
  payload
)

export const setOngoingSeasonInfo = (payload: CarbonCreditsSeasonInfo) => action(
  CompetitionActionTypes.SET_ONGOING_SEASON_INFO,
  payload,
)

export const setTotalAccumulatedRewards = (payload: BigNumber) => action(
  CompetitionActionTypes.SET_TOTAL_ACCUMULATED_REWARDS,
  payload,
)

export const setUnclaimedRewards = (payload: BigNumber) => action(
  CompetitionActionTypes.SET_UNCLAIMED_REWARDS,
  payload,
)

export const fetchCarbonCreditsPnlLeaderboard = (payload: PnlDurations) => action(
  CompetitionActionTypes.FETCH_CARBON_CREDITS_PNL_LEADERBOARD,
  payload
)

export const setCarbonCreditsPnlLeaderboard = (payload: LeaderboardPnl[] | undefined) => action(
  CompetitionActionTypes.SET_CARBON_CREDITS_LEADERBOARD_PNL,
  payload,
)

export const setMerkleTree = (payload: string[][]) => action(
  CompetitionActionTypes.SET_MERKLE_TREE,
  payload
)

export const fetchUnclaimedRewards = () => action(
  CompetitionActionTypes.FETCH_UNCLAIMED_REWARDS,
)

export const setDemexPointsLeaderboard = (payload: DemexPointsLeaderboard[] | undefined) => action(
  CompetitionActionTypes.SET_DEMEX_POINTS_LEADERBOARD,
  payload
)

export const setUserDemexPoints = (payload?: UserDemexPoints) => action(
  CompetitionActionTypes.SET_USER_DEMEX_POINTS,
  payload
)

export const fetchDemexPoints = () => action(
  CompetitionActionTypes.FETCH_USER_DEMEX_POINTS,
)

export const setHideDemexPointsGuide = (address: string) => action(
  CompetitionActionTypes.SET_HIDE_DEMEX_POINTS_GUIDE,
  address,
)

export const setPollUserDemexPoints = (payload: boolean) => action(
  CompetitionActionTypes.SET_POLL_USER_DEMEX_POINTS,
  payload
)

export const queryUserTradingVolumeCampaign = () => action(
  CompetitionActionTypes.QUERY_USER_TRADING_VOLUME_CAMPAIGN
)

export const setUserTradingVolumeCampaign = (data: BigNumber) => action(
  CompetitionActionTypes.SET_USER_TRADING_VOLUME_CAMPAIGN, data
)

export const queryLossProtectionLeaderboard = () => action(
  CompetitionActionTypes.QUERY_LOSS_PROTECTION_LEADERBOARD
)

export const setLossProtectionLeaderboard = (data: LossProtectionRecord[] | undefined) => action(
  CompetitionActionTypes.SET_LOSS_PROTECTION_LEADERBOARD,
  data
)

export const setShowLossProtectionGuide = (data: boolean) => action(
  CompetitionActionTypes.SET_SHOW_GUIDE,
  data
)

export const setShowLossProtectionRegistration = (data: boolean) => action(
  CompetitionActionTypes.SET_SHOW_REGISTRATION,
  data
)

export const setPollLossProtection = (payload: boolean) => action(
  CompetitionActionTypes.SET_POLL_LOSS_PROTECTION,
  payload
)

export const queryMemeTradingLeaderboard = () => action(
  CompetitionActionTypes.QUERY_MEME_TRADING_LEADERBOARD
)

export const setMemeTradingLeaderboard = (data: MemeTradingRecord[] | undefined) => action(
  CompetitionActionTypes.SET_MEME_TRADING_LEADERBOARD,
  data
)

export const setShowMemeTradingGuide = (data: boolean) => action(
  CompetitionActionTypes.SET_SHOW_MEME_TRADING_GUIDE,
  data
)

export const setShowMemeTradingRegistration = (data: boolean) => action(
  CompetitionActionTypes.SET_SHOW_MEME_TRADING_REGISTRATION,
  data
)

export const setPollMemeTrading = (payload: boolean) => action(
  CompetitionActionTypes.SET_POLL_MEME_TRADING,
  payload
)

export const setShowMantleTradingGuide = (data: boolean) => action(
  CompetitionActionTypes.SET_SHOW_MANTLE_TRADING_GUIDE,
  data
)

export const setShowMantleTradingRegistration = (data: boolean) => action(
  CompetitionActionTypes.SET_SHOW_MANTLE_TRADING_REGISTRATION,
  data
)

export const setPollMantleTrading = (payload: boolean) => action(
  CompetitionActionTypes.SET_POLL_MANTLE_TRADING,
  payload
)