
import { Theme } from '@material-ui/core'

import { PickersOverrides } from './types'

const MuiPickersCalendarHeader: PickersOverrides = (theme: Theme) => ({
  switchHeader: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: '42px',
    '& > div > p': {
      fontFamily: 'Play',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: '0em',
    },
  },
  iconButton: {
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    '@media (max-width: 320px)': {
      padding: theme.spacing(1.5, 1),
    },
  },
  dayLabel: {
    fontFamily: 'Play',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0em',
    width: '40px',
    color: theme.palette.text.primary,
    [theme.breakpoints.only('xs')]: {
      width: '32px',
    },
    '@media (max-width: 320px)': {
      width: '28px',
    },
  },
})

export default MuiPickersCalendarHeader
