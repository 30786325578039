import { OrderModule } from 'carbon-js-sdk'

export function isLimit(type: OrderModule.OrderType | string): boolean {
  const newType = type as string
  return newType === (OrderModule.OrderType.Limit as string) || newType === (OrderModule.OrderType.StopLimit as string) || newType === (OrderModule.OrderType.TakeProfitLimit as string)
}

export function isMarket(type: OrderModule.OrderType | string): boolean {
  const newType = type as string
  return newType === (OrderModule.OrderType.Market as string) || newType === (OrderModule.OrderType.StopMarket as string) || newType === (OrderModule.OrderType.TakeProfitMarket as string)
}

export function isStop(type: OrderModule.OrderType | string): boolean {
  const newType = type as string
  return newType === (OrderModule.OrderType.StopLimit as string) || newType === (OrderModule.OrderType.StopMarket as string)
}

export function isStopMarketOrTakeProfitMarket(type: OrderModule.OrderType | string): boolean {
  const newType = type as string
  return newType === (OrderModule.OrderType.TakeProfitMarket as string) || newType === (OrderModule.OrderType.StopMarket as string)
}

export function isTakeProfit(type: OrderModule.OrderType | string): boolean {
  const newType = type as string
  return newType === (OrderModule.OrderType.TakeProfitLimit as string) || newType === (OrderModule.OrderType.TakeProfitMarket as string)
}

export function isStopOrTakeProfit(type: OrderModule.OrderType | string): boolean {
  return isStop(type) || isTakeProfit(type)
}

export function isBuy(side: OrderModule.OrderSide | string): boolean {
  const newSide = side as string
  return newSide === (OrderModule.OrderSide.Buy as string)
}

export function isIoc(timeInForce: OrderModule.TimeInForce | string): boolean {
  const newSide = timeInForce as string
  return newSide === (OrderModule.TimeInForce.Ioc as string)
}
