import { DeliverTxResponse } from '@cosmjs/stargate'
import daysjs from 'dayjs'

import { FeatureType } from 'js/constants/notification'

export type LoadingTask = {
  [index: string]: daysjs.Dayjs;
}
export type LoadingTasks = {
  [index: string]: LoadingTask;
}

export enum TxPhase {
  BROADCAST = 'broadcast',
  BLOCK = 'block',
}

export type TxLoadingTask = {
  [index: string]: TxLoadingTaskDetails
}

export interface TxLoadingTaskDetails {
  txHash: string
  toastId?: string,
  featureType?: FeatureType,
  onBlockConfirmation?: OnBlockConfirmation,
  extras?: any,
}

export interface OnBlockConfirmation {
  handler?: PromiseHandler<DeliverTxResponse>,
  callback?: (response: DeliverTxResponse) => void,
}

export type TxLoadingTasks = {
  [index: string]: TxLoadingTask;
}

export interface PromiseHandler<T> {
  resolve: (result: T) => void;
  reject: (reason?: any) => void;
}
