export enum StorageKey {
  CosmosLedgerHdPath = 'cosmosLedgerBIP44Path',
  Mnemonic = '@account/mnemonic',
  ConnectedWallet = '@account/connected-wallet',
  PublicKey = '@account/public-key',
  RainbowKitWalletKey = '@account/rainbowKit',
  HiddenBannerKey = '@app/hiddenBanners',
  ChainInfoMap = '@app/chain-info-map',
  RecentConnectedWeb3WalletId = 'wagmi.recentConnectorId',
  Jwt = '@account/JWT',
  HiddenMarketBannerIdsKey = '@app/hiddenMarketBannerIds',
  OrderDefaultTimeInForce = '@orderManager/defaultTimeInForce',
}

export const StorageVersion = {
  [StorageKey.ChainInfoMap]: 0,
} as const
