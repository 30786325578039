import { Carbon } from 'carbon-js-sdk'
import dayjs from 'dayjs'

export enum MarketType {
  Featured = 'featured',
  Spot = 'spot',
  Futures = 'futures',
  Options = 'options',
  Perpetual = 'perpetual',
}

export function isFutures(type?: MarketType | string) {
  return type === MarketType.Futures
}

export function isSpot(type?: MarketType | string) {
  return type === MarketType.Spot
}

export function isPerpetual(expiryTime?: string | Date): boolean {
  const zeroDate = new Date(0)
  const expiryDate = typeof expiryTime === 'string' ? new Date(expiryTime) : expiryTime
  return expiryDate?.getTime() === zeroDate.getTime()
}

export function isPerpetualMarket(market: Carbon.Market.Market | null | undefined): boolean {
  if (!market) return false
  return isFutures(market.marketType) && isPerpetual(market.expiryTime)
}

export function isFuturesMarket(market: Carbon.Market.Market | null | undefined): boolean {
  if (!market) return false
  return isFutures(market.marketType)
}

export function isSpotMarket(market: Carbon.Market.Market | null | undefined): boolean {
  if (!market) return false
  return isSpot(market.marketType)
}

export function isExpired(market: Carbon.Market.Market | null | undefined) {
  if (!market || !market?.expiryTime) return false
  if (isSpot(market.marketType) || isPerpetual(market?.expiryTime)) return false
  const timestamp = market.expiryTime.getTime()
  return dayjs.utc(timestamp).isBefore(dayjs.utc())
}

export function isValidMarket(
  market: Carbon.Market.Market | null | undefined,
  blacklistedMarkets: string[] = [],
): boolean {
  if (!market || !market?.isActive || blacklistedMarkets.includes(market?.id)) return false
  if (isSpot(market.marketType) || isPerpetual(market.expiryTime)) return true
  return isFutures(market.marketType) && !isExpired(market)
}

export function getMarketType(market: Carbon.Market.Market | null | undefined) {
  if (!market?.marketType) return ''
  switch (true) {
    case market.marketType === MarketType.Featured || market.marketType === MarketType.Options:
      return market.marketType
    case isSpotMarket(market):
      return MarketType.Spot
    case isPerpetualMarket(market):
      return MarketType.Perpetual
    case isFuturesMarket(market):
      return MarketType.Futures
    default:
      return ''
  }
}
