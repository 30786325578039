import { Theme } from '@material-ui/core'

const colors = ['blue', 'darkBlue', 'green', 'red']

export const makeButtonColors = (theme: Theme) => (color: string) => {
  const mappedColor = color === 'blue' ? 'secondary' : color
  const paletteColor = (theme.palette as any)[mappedColor]
  return {
    color: theme.palette.common.white,
    backgroundColor: paletteColor?.main,
    '&:hover': {
      backgroundColor: paletteColor?.light,
    },
    '&:active:hover': {
      backgroundColor: paletteColor?.dark,
    },
  }
}

export default colors
