import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { ReactComponent as ExternalLinkIcon } from 'assets/ExternalLink.svg'

import TextButton, { TextButtonProps } from './TextButton'

export interface LearnMoreLinkProps extends Partial<TextButtonProps> {
  inlineBlock?: boolean
  atTooltipBottom?: boolean
}

const LearnMoreLink: React.FC<LearnMoreLinkProps> = (props: LearnMoreLinkProps) => {
  const classes = useStyles(props)
  const { boxClass, labelClass = classes.learnMoreText, svgClass = classes.learnMoreIcon, className, href, label = 'Learn More', endIcon = ExternalLinkIcon, inlineBlock = false, atTooltipBottom } = props
  return (
    <TextButton
      label={label}
      endIcon={endIcon}
      href={href}
      target="_blank"
      boxClass={clsx(inlineBlock && classes.inline, atTooltipBottom && classes.tooltipBottom, boxClass)}
      labelClass={labelClass}
      svgClass={svgClass}
      className={className}
    />
  )
}

const useStyles = makeStyles<Theme, LearnMoreLinkProps>((theme: Theme) => ({
  learnMoreIcon: {
    height: '8px',
    width: '8px',
    paddingTop: '1px',
  },
  learnMoreText: {
    ...theme.typography.body4,
    fontWeight: 700,
  },
  inline: {
    display: 'inline-block',
    marginLeft: theme.spacing(0.5),
  },
  tooltipBottom: {
    marginTop: theme.spacing(0.5),
  },
}))

export default LearnMoreLink
