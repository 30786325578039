import { Switch, Theme, withStyles } from '@material-ui/core'

export default withStyles((theme: Theme) => ({
  root: {
    width: 24,
    height: 16,
    padding: 0,
    marginRight: 16,
  },
  switchBase: {
    padding: 2,
    color: '#FFFFFF',
    '&$checked': {
      transform: 'translateX(8px)',
      color: '#FFFFFF',
      border: 'none',
      '& + $track': {
        opacity: 1,
        background: theme.palette.text.demexSolid,
        border: 'none',
      },
      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
        '& + $track': {
          opacity: '0.1',
          background: '#fff',
        },
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: 'none',
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme.palette.text.disabled,
  },
  checked: {},
}))(Switch)
