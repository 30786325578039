import { makeStyles, Theme } from '@material-ui/core'
import React from 'react'

import MenuLinksConfig from './appConfig'

import MenuListItems from '../Common/MenuListItems'

type Props = {}

const HelpAndSupport: React.FC<Props> = (props) => {
  const classes = useStyles()
  const menuListConfig = MenuLinksConfig()

  return (
    <MenuListItems
      menuListClasses={{ root: classes.menuList }}
      size="large"
      menuClass={classes.menuClass}
      items={menuListConfig.Help}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: 0,
    '& li': {
      padding: theme.spacing(1.5, 1.25, 1.5, 1),
    },
    '& > :nth-last-child(-n+2) div': {
      marginBottom: theme.spacing(0.1),
    },
  },
  menuClass: {
    fontSize: '15px',
    marginBottom: theme.spacing(0.25),
  },
}))

export default HelpAndSupport
