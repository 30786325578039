import { useCallback, useState } from 'react'

import { navbarDropdownTimeoutDuration } from 'js/constants/date'

import useDelayedAction from './useDelayedAction'

type EventOrBoolean = boolean | React.FocusEvent<any> | React.MouseEvent<any>

export type DropdownHandlerReturnTypes = [
  boolean,
  (overrideState?: EventOrBoolean) => void,
  (skipDelay?: EventOrBoolean) => void,
]

const useDropdownHandler = (intiallyOpened = false, closeDelay = navbarDropdownTimeoutDuration): DropdownHandlerReturnTypes => {
  const [doDelayedAction, clearDelayedAction] = useDelayedAction()
  const [open, setOpen] = useState(intiallyOpened)

  const handleOpen = useCallback((overrideState?: EventOrBoolean) => {
    // overwrite if not boolean, for convenience to directly plug as DOM event handler
    if (typeof overrideState !== 'boolean') {
      overrideState = undefined
    }

    clearDelayedAction()
    setOpen(typeof overrideState === 'boolean' ? overrideState : true)
  }, [setOpen, clearDelayedAction])

  const handleDelayedClose = useCallback((skipDelay: EventOrBoolean = false) => {
    // overwrite if not boolean, for convenience to directly plug as DOM event handler
    if (typeof skipDelay !== 'boolean') {
      skipDelay = false
    }

    doDelayedAction(() => setOpen(false), skipDelay ? 0 : closeDelay)
  }, [doDelayedAction, setOpen, closeDelay])

  return [open, handleOpen, handleDelayedClose]
}

export default useDropdownHandler
