import { action } from 'typesafe-actions'

import { TradeHelpWizardStage } from 'js/constants/crossSelling'

import { CrossSellingActionTypes, HighlightFocusState, SetCrossSellingSourceTokenPayload, SetCrossSellingStagePayload, SetEnableCrossSellingPayload } from './types'

export const setCrossSellingStatus = (address: string | undefined) => action(
  CrossSellingActionTypes.SET_CROSS_SELLING_STATUS,
  address,
)

export const setEnableCrossSelling = (payload: SetEnableCrossSellingPayload) => action(
  CrossSellingActionTypes.SET_ENABLE_CROSS_SELLING,
  payload,
)

export const setShowCrossSellingWidget = () => action(
  CrossSellingActionTypes.SET_SHOW_CROSS_SELLING_WIDGET,
)

export const setHideCrossSellingWidget = () => action(
  CrossSellingActionTypes.SET_HIDE_CROSS_SELLING_WIDGET,
)

export const setCrossSellingStage = (payload: SetCrossSellingStagePayload) => action(
  CrossSellingActionTypes.SET_CROSS_SELLING_STAGE,
  payload
)

export const setCrossSellingSourceToken = (payload: SetCrossSellingSourceTokenPayload) => action(
  CrossSellingActionTypes.SET_CROSS_SELLING_SOURCE_TOKEN,
  payload
)

export const setShowCrossSellingDialog = () => action(
  CrossSellingActionTypes.SET_SHOW_CROSS_SELLING_DIALOG,
)

export const setHideCrossSellingDialog = () => action(
  CrossSellingActionTypes.SET_HIDE_CROSS_SELLING_DIALOG,
)

export const setShowTradeHelpWizard = () => action(
  CrossSellingActionTypes.SET_SHOW_TRADE_HELP_WIZARD
)

export const setTradeHelpWizardStage = (stage: TradeHelpWizardStage) => action(
  CrossSellingActionTypes.SET_TRADE_HELP_WIZARD_STAGE,
  stage,
)

export const setHighlightFocusState = (state: HighlightFocusState) => action(
  CrossSellingActionTypes.SET_HIGHLIGHT_FOCUS,
  state,
)
