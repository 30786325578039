import { Network } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved
import { SimpleMap } from 'carbon-js-sdk/lib/util/type'

import { HttpMethod } from 'js/constants/api'

import { sendApiRequest } from './api'


interface SupportedChainApiResponse {
  id: number
  name: string
  chain_id: string
  is_testnet: boolean
  carbon_gateway: string
  carbon_native_gas_gateway: string
  coin_type: number
  blockchain_type: string
}

export type SupportedChains = SimpleMap<SupportedChainApiResponse>


export interface RegisteredAddress {
  bip44_address_index: number
  carbon_address: string
  created_at: string
  deposit_address: string
  id: number
  url: string
  network: SupportedChainApiResponse
}

export type RegisteredAddresses = SimpleMap<RegisteredAddress>

export const getSupportedChains = async (domain: string, network: Network): Promise<SupportedChains | undefined> => {
  const url = `${domain}/networks.json`
  const response: SupportedChainApiResponse[] = await sendApiRequest(HttpMethod.GET, url)
  const isTestnet = network !== Network.MainNet
  return response.filter(({ is_testnet }) => is_testnet === isTestnet).reduce((acc, chain) => {
    acc[chain.name] = chain
    return acc
  }, {} as SupportedChains)
}

export const getRegisteredAddresses = async (domain: string, network: Network, accessToken: string): Promise<RegisteredAddresses | undefined> => {
  const url = `${domain}/user/addresses.json`
  const response: RegisteredAddress[] = await sendApiRequest(HttpMethod.GET, url, undefined, accessToken)
  const isTestnet = network !== Network.MainNet
  return response.filter(({ network }) => network.is_testnet === isTestnet).reduce((acc, registeredAddress) => {
    acc[registeredAddress.network.name] = registeredAddress
    return acc
  }, {} as RegisteredAddresses)
}


export const createNewDepositAddress = async (domain: string, chainId: number, accessToken: string): Promise<RegisteredAddress | undefined> => {
  const url = `${domain}/user/addresses.json`
  const response: RegisteredAddress = await sendApiRequest(HttpMethod.POST, url, { chain_id: chainId }, accessToken)
  return response
}