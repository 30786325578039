import { AccessTokenResponse, hasExpired, hasRefreshTokenExpired, isValidIssuer } from 'carbon-js-sdk/lib/util/auth'
import { jwtDecode } from 'jwt-decode'

import { StorageKey } from 'js/constants/storage'

import { safeParseStoredValue } from './localstorage'

export const saveJwt = (value: AccessTokenResponse) => {
  localStorage.setItem(StorageKey.Jwt, JSON.stringify(value))
}

export const getValidJwt = (): AccessTokenResponse | undefined => {
  const jwt:AccessTokenResponse | undefined = safeParseStoredValue(localStorage.getItem(StorageKey.Jwt))
  if (!isValidJwt(jwt)) {
    localStorage.removeItem(StorageKey.Jwt)
    return undefined
  }
  return jwt
}

const isValidJwt = (jwt?: AccessTokenResponse) => {
  if (!jwt) return false
  try {
    const { iss = '', exp = 0 } = jwtDecode(jwt.access_token)
    const accessTokenExpired = hasExpired(exp)
    return isValidIssuer(iss) && (!accessTokenExpired || !hasRefreshTokenExpired(jwt.refresh_token))
  } catch (e) {
    console.error('unable to decode jwt', e)
    return false
  }
}