import { makeStyles } from "@material-ui/core"
import { IconButton } from "@material-ui/core"
import clsx from "clsx"
import React, { useState } from 'react'

import Button from "js/components/Common/Button"
import { AlertBannerVariantType } from "js/state/modules/app/types"
import { darkColors, lightColors } from "js/theme/ThemeV2/palettes"
import { StyleUtils } from "js/utils"

import { ReactComponent as WarnIcon } from 'assets/CautionNew.svg'
import { ReactComponent as CloseIcon } from 'assets/Close.svg'
import { ReactComponent as ErrorIcon } from 'assets/Error.svg'
import { ReactComponent as InfoIcon } from 'assets/InfoIconNew.svg'
import { ReactComponent as SuccessIcon } from 'assets/SuccessTickInsideCircleNew.svg'


interface AlertIconMapping {
  error?: React.ReactNode,
  info?: React.ReactNode,
  success?: React.ReactNode,
  warning?: React.ReactNode,
}

export interface AlertAction {
  label: string,
  variant: "contained" | "outlined"
  onClick: () => void
  tailingIcon?: React.ReactNode,
  btnsize?: 'regular' | 'small'
}

interface AlertBannerProps {
  cta?: AlertAction,
  title?: string | React.ReactNode
  message: string | React.ReactNode
  variant?: AlertBannerVariantType
  allowCollapse?: boolean
  icon?: React.ReactNode
  svgImage?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  className?: string
  imageClassName?: string
  alertBannerContentClass?: string
  iconTextBoxClass?: string
  buttonBoxClass?: string
  bannerTextClass?: string
  onClick?: () => void
  onClose?: () => void
}

const AlertBanner: React.FC<AlertBannerProps> = (props) => {
  const classes = useStyles()
  const { cta, title, message, variant = 'info', allowCollapse = true, className, imageClassName = classes.image, icon, svgImage: Image, onClick, onClose, alertBannerContentClass, buttonBoxClass, iconTextBoxClass, bannerTextClass } = props
  const [showBanner, setShowBanner] = useState<boolean>(true)

  const defaultIconMapping: AlertIconMapping = {
    error: (
      <ErrorIcon className={clsx(classes.icon, "error")} />
    ),
    warning: (
      <WarnIcon className={clsx(classes.icon, "warning")} />
    ),
    info: (
      <InfoIcon className={clsx(classes.icon, "info")} />
    ),
    success: (
      <SuccessIcon className={clsx(classes.icon, "success")} />
    )
  }

  const getBannerIcon = () => {
    if (Image) return <Image className={imageClassName}/>
    if (icon) return icon
    return defaultIconMapping[variant]
  }

  if (!showBanner) return null

  return (
    <div className={clsx(classes.alertBannerContainer, className)} onClick={onClick && onClick}>
      <div className={clsx(classes.alertBanner, classes[variant], alertBannerContentClass)}>
        <div className={clsx(classes.iconTextBox, iconTextBoxClass)}>
          <div className={classes.iconBox}>
            {getBannerIcon()}
          </div>
          <div>
            {title && (
              <div className={classes.bannerTitle}>
                {title}
              </div>
            )}
            <div className={clsx(classes.bannerText, bannerTextClass)}>
              {message}
            </div>
          </div>
        </div>
        {cta && (
          <div className={clsx(classes.buttonBox, buttonBoxClass)}>
            <Button
              className={classes.actionBtn}
              baseButtonVariant={cta.variant ?? 'contained'}
              color={cta.variant === 'outlined' ? 'default' : 'primary'}
              buttonType="action"
              btnsize={cta.btnsize ?? 'regular'}
              onClick={cta.onClick}
            >
              <div className={classes.buttonLabel}>
                <span>{cta.label}</span>
                {cta.tailingIcon && cta.tailingIcon}
              </div>
            </Button>
          </div>
        )}
      </div>
      {allowCollapse && (
        <IconButton
          onClick={onClose ? onClose : () => setShowBanner(false)}
          className={classes.closeIconButton}
          disableRipple
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  alertBannerContainer: {
    position: 'relative',
  },
  alertBanner: {
    ...theme.typography.body3,
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1.5),
    alignItems: 'center',
    borderRadius: '8px',
    gap: '12px',
    color: theme.palette.text.primary,
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: theme.spacing(1),
    },
  },
  info: {
    border: `1px solid ${theme.palette.primary.light}`,
    ...StyleUtils.infoBannerBackground(theme),
  },
  warning: {
    border: `1px solid ${theme.palette.warning.main}`,
    ...StyleUtils.warningBannerBackground(theme),
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
    ...StyleUtils.errorBannerBackground(theme),
  },
  success: {
    border: `1px solid ${theme.palette.success.main}`,
    ...StyleUtils.successBannerBackground(theme),
  },
  iconTextBox: {
    display: 'flex',
    gap: theme.spacing(1.5),
    alignItems: 'center',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  iconBox: {
    display:'flex' ,
    justifyContent:'center',
    alignSelf: 'flex-start',
  },
  bannerTitle: {
    display: 'block',
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.body4,
      fontWeight: 700,
    },
  },
  bannerText: {
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.body4,
    },
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    margin: theme.spacing(0.5, 0),
    [theme.breakpoints.down('xs')]: {
      width: '1.25rem',
      height: '1.25rem',
      margin: theme.spacing(0.25, 0),
    },
  },
  image: {
    [theme.breakpoints.down('xs')]: {
      width: '1.75rem',
      height: '1.75rem',
    }
  },
  closeIconButton: {
    top: '6px',
    right: '6px',
    height: '0.75rem',
    width: '0.75rem',
    position: "absolute",
    padding: 0,
    '& path': {
      stroke: theme.palette.type === 'dark' ? darkColors.mono.text.secondary : lightColors.mono.text.secondary,
    },
  },
  closeIcon: {
    height: '0.75rem',
  },
  buttonBox: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    textTransform: 'capitalize',
    '& svg': {
      width: '16px',
      height: '16px',
    }
  },
  actionBtn: {
    ...theme.typography.body4,
    whiteSpace: 'nowrap',
    height: 'auto !important',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
    },
  },
}))

export default AlertBanner
