import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'


import AlertBanner from 'js/components/Common/AlertBanner'

import { ReactComponent as ExternalLinkIcon } from 'assets/ExternalLink.svg'

import TextButton from '../TextButton'

interface Props extends React.HTMLAttributes<HTMLDivElement> {

}
const NNEOWithdrawWarning: React.FC<Props> = (props: Props) => {
  const { children, className, ...rest } = props
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <AlertBanner
        variant="warning"
        title="Do not withdraw nNEO to an exchange"
        message={(
          <div>
            nNEO may not be supported at other exchanges, please <strong>do not withdraw nNEO directly to an exchange</strong>.
            <TextButton
              label="nNEO Token Details"
              href="https://neotracker.io/asset/f46719e2d16bf50cddcef9d4bbfece901f73cbb6"
              target="_blank"
              endIcon={ExternalLinkIcon}
              boxClass={classes.box}
              svgClass={classes.iconClass}
            />
          </div>
        )}
        allowCollapse={false}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(0.25),
  },
  alertAction: {
    paddingLeft: 0,
  },
  iconClass: {
    marginLeft: '0.5rem',
    width: '0.5rem',
  },
}))


export default NNEOWithdrawWarning
