import { Theme } from '@material-ui/core'

import { StyleUtils } from 'js/utils'

import { PickersOverrides } from './types'

const MuiPickersModal: PickersOverrides = (theme: Theme) => ({
  dialogRootWider: {
    backgroundColor: theme.palette.background.secondary,
    boxShadow: StyleUtils.dropShadow(theme),
    minWidth: 'unset',
    '& > div': {
      justifyContent: 'space-between',
      padding: '32px',
      '& > button': {
        width: '100px',
        height: '48px',
        borderRadius: '4px',
        border: `1px solid ${theme.palette.action.disabledBackground}`,
        '& > span': {
          fontFamily: 'Play',
          fontSize: '16px',
          fontStyle: 'normal',
          lineHeight: '20px',
          letterSpacing: '0em',
          fontWeight: 700,
          color: theme.palette.text.primary,
        },
        '&:hover': {
          background: theme.palette.action.hover,
        },
        '&:not(:first-child)': {
          background: StyleUtils.buttonGradient(theme),
          border: 'none',
          '& > span': {
            color: '#FFFFFF',
          },
          '&:hover': {
            background: StyleUtils.buttonGradientHover(theme),
          },
        },
      },
      [theme.breakpoints.only('xs')]: {
        padding: '20px 20px 28px',
      },
      '@media (max-width: 320px)': {
        padding: '16px 12px 28px',
      },
    },
    [theme.breakpoints.only('xs')]: {
      margin: '32px 12px',
      maxWidth: 320,
      minWidth: 320,
    },
    '@media (max-width: 320px)': {
      maxWidth: 280,
      minWidth: 'unset',
    },
  },
})

export default MuiPickersModal
