import { Theme } from '@material-ui/core'

import { PickersOverrides } from './types'

const MuiPickersCalendar: PickersOverrides = (theme: Theme) => ({
  transitionContainer: {
    minHeight: '182px',
  },
})

export default MuiPickersCalendar
