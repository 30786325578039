import BigNumber from 'bignumber.js'
import { BlockchainUtils, CarbonSDK, TypeUtils } from 'carbon-js-sdk'

import { ChartLayoutType } from 'js/utils/types'

export const evmNativeTokenAddress = '0x0000000000000000000000000000000000000000'

export const usdGroupedToken = 'cgt/1'

export const nativeStakingDenom = 'swth'

// Ethereum token denoms
export const ethNativeDenom = 'eth.1.2.942d87'
export const ethGasDenom = ethNativeDenom
export const yieldUsdDenom = 'yieldusd.1.2.447c22'
export const usdcEthDenom = 'usdc.1.2.343151'
// Neo N3 token denoms
export const n3GasDenom = 'gas.1.14.a87f81'
// Arbitrum token denoms
export const cGLPDenom = 'cglp.1.19.1698d3'
export const usdcArbDenom = 'usdc.1.19.f9afe3'

// Stafi tokens
export const rSWTHDenom = 'ibc/F73839B9CAD86AC5E8D589177CCB6528A93499BC36C35790A79EBB794C344080'
export const cibSWTHDenom = 'cibt/swth'

// Noble tokens
export const nobleUsdcDenom = 'ibc/43897B9739BD63E3A08A88191999C632E052724AB96BD4C74AE31375C991F48D'

// IBC denoms
export const uusdcDenom = 'uusdc'

export enum DenomPrefix {
  Cdp = 'cibt',
  PerpLp = 'cplt',
  SpotLp = 'clpt',
  GroupToken = 'cgt',
}
export const isTokenType = (denom: string, type: DenomPrefix) => denom?.startsWith(`${type}/`)

// For Trade UI only
export const marginDp = 2

export const SWTH_DECIMALS = 8

export const LayoutStructVersion = 21

export const SHIFT_DECIMALS = 18

export interface TokenFeeSettings {
  reserve: number,
}

export type TokenFeePreference = {
  selectedTokens: string[]
  unselectedTokens: string[]
  layout: ChartLayoutType[]
}

export interface SettingsFee {
  reserve: BigNumber
  reserveUsd: BigNumber
}

// Add default spot quote denoms for create market
export const SPOT_QUOTES: TypeUtils.SimpleMap<string[]> = {
  [CarbonSDK.Network.MainNet]: [
    'dai.1.2.12b76b', 'dai.1.6.80c073', ethNativeDenom,
    'nneo.1.4.cc7591', 'bnb.1.6.773edb', 'zil.1.18.1a4a06',
    'ibc/ED07A3391A112B175915CD8FAF43A2DA8E4790EDE12566649D0C2F97716B8518', // osmo
    'ibc/2B58B8C147E8718EECCB3713271DF46DEE8A3A00A27242628604E31C2F370EF5', // luna
    'ibc/A4DB47A9D3CF9A068D454513891B526702455D3EF08FB9EB558C561F9DC2B701', // atom
    'ibc/9E3EB38E5E157AEBFF4A8EAC66E654BC8ECFCB1F758F4D1C0F2D65945E9E2935', // juno
    'ibc/35E771B8682D828173F4B795F6C307780F96DC64D6F914FAE4CC9B4666F66364', // evmos
    'ibc/062588846168EDFECF20F7681F2A0A131A53B9A3C8535BCA8F7D1A268246974B', // axelar
    'ibc/92E974290AF9E2BC3AEEEC35305C8FD76AC5A22A74CF8D91270FDF5A1C41E861', // canto
    'swth', 'eth.1.19.c3b805', 'usc', usdGroupedToken,
    'ibc/96BB9743B631E66525F13E5E944AF1A222AF928A4C870E457CDE932092F9528B', // somm
    'ibc/4E06CF24FEBFB3F5AF645377DCC0B70AA6183BAF6B918B8B6243FCDEB7D38118', // iris hub
  ],
  [CarbonSDK.Network.TestNet]: ['btc', 'eth', 'neo', 'dai', 'swth', usdGroupedToken, 'atom'],
  [CarbonSDK.Network.DevNet]: ['btc', 'eth', 'neo', 'dai', 'swth'],
  [CarbonSDK.Network.LocalHost]: ['btc', 'eth', 'neo', 'dai', 'swth'],
}

// Add default futures quote denoms for create market
export const FUTURES_QUOTES: TypeUtils.SimpleMap<string[]> = {
  [CarbonSDK.Network.MainNet]: [usdGroupedToken],
  [CarbonSDK.Network.TestNet]: ['cgusd', 'iusd', 'usdc'],
  [CarbonSDK.Network.DevNet]: ['iusd', 'usdc'],
  [CarbonSDK.Network.LocalHost]: ['iusd', 'usdc'],
}

// Blacklist swthb.1.6.d04123
export const fallbackTokenBlacklist: { [key in CarbonSDK.Network]: string[] } = {
  [CarbonSDK.Network.MainNet]: [
    'swthb.1.6.d04123', // binance wrapped token
    'swth.1.18.94d5fe', // zilliqa wrapped token
    'bneo.1.14.59a1d7', // n3 bNEO, invalid token address
    'ibc/F7E92EE59B5428793F3EF5C1A4CB2494F61A9D0C9A69469D02390714A1372E16', // ion (osmosis-native token),
    'ibc/4128D5DDF2EF8776B5C704C3153E1CBEC5DABC04DB0CC6221EDCC342EB04779C', // luna (invalid token)
    'ibc/1B03A71B8E6F6EF424411DC9326A8E0D25D096E4D2616425CFAF2AF06F0FE717', // axl (invalid token)
    'ibc/731D16914CB3304BD7D177D3D4B210D82C04E5DF86E08AC994A8675C3E00C366', // atom (invalid token)
    'ibc/A59A9C955F1AB8B76671B00C1A0482C64A6590352944BB5880E5122358F7E1CE', // evmos (invalid token)
    'ibc/D70B0FBF97AEB04491E9ABF4467A7F66CD6250F4382CE5192D856114B83738D2', // lunc
    'ibc/4294C3DB67564CF4A0B2BFACC8415A59B38243F6FF9E288FBA34F9B4823BA16E', // ustc
  ],
  [CarbonSDK.Network.TestNet]: [],
  [CarbonSDK.Network.DevNet]: [],
  [CarbonSDK.Network.LocalHost]: [],
}

export interface TokenVariant {
  assetId: string
  label: string
  hasBalance?: boolean
}

export const NeoVariantObj: TypeUtils.SimpleMap<TypeUtils.SimpleMap<TokenVariant[]>> = {
  Neo: {
    swth: [{
      assetId: '39a154ae9ad537a18e8eec2f1a40ebee02e6093e',
      label: 'SWTH (Inflationary)',
    }, {
      assetId: '32e125258b7db0a0dffde5bd03b2b859253538ab',
      label: 'SWTH (Legacy)',
    }],
  },
  Neo3: {},
}

export const tokenAddrRegex = /^0x([a-z\d]+)$/i

export const carbonStablecoinDenom = 'usc'

export const glpContracts: TypeUtils.SimpleMap<string> = {
  fsGLP: '0x1addd80e6039594ee970e5872d247bf0414c8903',
  sGLP: '0x2f546ad4edd93b956c8999be404cdcafde3e89ae',
  cGLP: '0xab19bdaeb37242fa0f30486195f45b9cf5361b78',
  glpRewards: '0x5C04a12EB54A093c396f61355c6dA0B15890150d',
}

export const GroupOptionDefaultChain: TypeUtils.SimpleMap<BlockchainUtils.BlockchainV2> = {
  USDC: 'Ethereum',
  BUSD: 'Binance Smart Chain',
  // axlUSDC: 'Axelar',
}

export const usdcByNetwork: {
  [key in CarbonSDK.Network]: string
} = {
  [CarbonSDK.Network.MainNet]: usdcEthDenom,
  [CarbonSDK.Network.TestNet]: 'usdc',
  [CarbonSDK.Network.DevNet]: 'usdc',
  [CarbonSDK.Network.LocalHost]: 'usdc',
}

export const feeQuoteOverride: TypeUtils.SimpleMap<string> = {
  [yieldUsdDenom]: usdcEthDenom,
  [cGLPDenom]: usdcArbDenom,
}

export const nitronBannerWhitelist: TypeUtils.SimpleMap<string> = {
  'ibc/BAA1D21893B1D36865C6CA44D18F4ACF08BAD70CB6863C4722E0A61703808F77': "Lend stDYDX on Nitron to become eligible for the $STRD Airdrop!",
  'ibc/6569E05DEE32B339D9286A52BE33DFCEFC97267F23EF9CFDE0C055140967A9A5': "Lend stTIA on Nitron to become eligible for the $STRD Airdrop!",
  'ibc/16065EE5282C5217685C8F084FC44864C25C706AC37356B0D62811D50B96920F': "Lend milkTIA on Nitron to become eligible for milkyway's mPoints!",
  'ibc/EF37C83E78BD1F9B2401B3B3E5C00E0DBA71A648E2A8C3018861786F7A5F0105': "Lend dATOM on Nitron to earn 3x more droplets from drop protocol!",
  'ibc/DF1D61996FD4F1B411B0168BC36C5E0FDF8EBC3776EEA3E0C97F4B072B28A355': "Lend ATOM-dATOM on Nitron to earn 5x more droplets from drop protocol!",
  'ibc/31A57AC0D7447979833AC6ACCB62C34346A834CAF4DF4F4EDBB1ECFC0EE258D2': "Lend USDC-dATOM on Nitron to earn 50x more droplets from drop protocol!",
  'ibc/5F23E1FC863C8DAD5D2E97A18741B5F54B5EF92F8F61F1431CA90B658E75E570': "Lend NTRN-dATOM on Nitron to earn 50x more droplets from drop protocol!",

}
export const tiaRelatedTokens: string[] = [
  'ibc/16065EE5282C5217685C8F084FC44864C25C706AC37356B0D62811D50B96920F', // milkTIA
  'ibc/6C349F0EB135C5FA99301758F35B87DB88403D690E5E314AB080401FEE4066E5', // TIA
  'ibc/6569E05DEE32B339D9286A52BE33DFCEFC97267F23EF9CFDE0C055140967A9A5', // stTIA
]

export const balanceViewDenoms: TypeUtils.SimpleMap<string[]> = {
  [CarbonSDK.Network.MainNet]: ['usd', 'wbtc.1.2.786598', 'eth.1.2.942d87', 'swth', usdGroupedToken],
  [CarbonSDK.Network.TestNet]: ['usd', 'btc', 'eth', 'swth', usdGroupedToken],
  [CarbonSDK.Network.DevNet]: ['usd', 'btc', 'eth', 'swth'],
  [CarbonSDK.Network.LocalHost]: ['usd', 'btc', 'eth', 'swth'],
}

export const ibcGasOverride: {
  [index: string]: number
} = {
  'osmosis-1': 500000,
  'archway-1': 450000,
}

export const fallbackCrossSellingSourceTokens: { [key in CarbonSDK.Network]: string[] } = {
  [CarbonSDK.Network.MainNet]: ['swth'],
  [CarbonSDK.Network.TestNet]: ['swth'],
  [CarbonSDK.Network.DevNet]: ['swth'],
  [CarbonSDK.Network.LocalHost]: ['swth'],
}

export const crossSellingTargetTokens: { [key in CarbonSDK.Network]: string } = {
  [CarbonSDK.Network.MainNet]: usdGroupedToken,
  [CarbonSDK.Network.TestNet]: 'cgusd',
  [CarbonSDK.Network.DevNet]: 'usdc',
  [CarbonSDK.Network.LocalHost]: 'usdc',
}