import { Listener } from '@ethersproject/abstract-provider' // eslint-disable-line import/no-extraneous-dependencies
import { Network } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved
import { RecordOf } from 'immutable'

export type EvmWsEvent = {
  type: 'status'
  status: boolean
} | {
  type: 'message'
  initialSubscription?: boolean
  result: EvmWsResult
}


export type EvmWsResult = {
  address: string,
  blockHash: string,
  blockNumber: string,
  data: string,
  logIndex: string,
  removed: boolean,
  topics: string[],
  transactionHash: string,
  transactionIndex: string,
}
export interface EvmWsSubscribeRace {
  disconnectAction?: boolean
  socketDisconnected?: boolean
  networkChanged?: Network
}

export interface EvmWsSubscribeEventRace {
  disconnected?: boolean
  subscribe?: undefined
}
export interface EvmWsStateProps {
  readonly isConnected: boolean
  readonly toConnect: boolean
}

export interface ContractEventListener {
  contractAddress: string,
  topics?: string[],
  eventEmittedCallBack?: Listener,
}

export type EvmWsState = RecordOf<EvmWsStateProps>

export const EvmWebSocketActionTypes = {
  ADD_CONTRACT_EVENT_LISTENER: '@evmWebSoecket/ADD_CONTRACT_EVENT_LISTENER',
  CONNECT: '@evmWebSocket/CONNECT',
  DISCONNECT: '@evmWebSocket/DISCONNECT',
  STATUS_CHANGE: '@evmWebSocket/STATUS_CHANGE',
}
