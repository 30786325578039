import { ExternalUtils, TypeUtils } from 'carbon-js-sdk'
import { Duration } from 'carbon-js-sdk/lib/codec'
import { Profile } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/profile/profile'
import { SubAccount } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/subaccount/subaccount'

import { ConnectionError, Contact, ContactData, GranteeDetails, LastTransaction, ReservedTokensPreference, SmartWalletAddressMap, SocialLoginInfo, TokenFeePreference, TokenFeeSettings, TxBeforeMergeEvm } from 'js/state/modules/account/types'
import { UserNotification } from 'js/utils/notifications'

import { RootState } from '../rootReducer'

export function isWalletConnected(state: RootState): boolean {
  return !!state.app.carbonSDK?.wallet
}

export function isAccountMerged(state: RootState): boolean {
  return !!state.app.carbonSDK?.wallet?.accountMerged
}

export function getProfile(state: RootState): Profile | null {
  return state.account.profile
}

export function getSocialLoginInfo(state: RootState): SocialLoginInfo | undefined {
  return state.account.socialLoginInfo
}

export function getLoginRedirect(state: RootState): string | null {
  return state.account.loginRedirect
}

export function getNeoExternalBalances(state: RootState): ExternalUtils.TokensWithExternalBalance[] {
  return state.account.neoExternalBalances
}

export function getEthExternalBalances(state: RootState): ExternalUtils.TokensWithExternalBalance[] {
  return state.account.ethExternalBalances
}

export function getTokenFeeSettings(state: RootState): TokenFeeSettings | null {
  return state.account.tokenFeeSettings
}

export function getSmartWalletAddressMap(state: RootState): SmartWalletAddressMap | undefined {
  return state.account.smartWalletAddress
}

export function getEnableSwthFee(state: RootState): boolean | null {
  return state.account.enableReservedFee
}

export function getBscExternalBalances(state: RootState): ExternalUtils.TokensWithExternalBalance[] {
  return state.account.bscExternalBalances
}

export function getHideZeroBalances(state: RootState): boolean {
  return state.account.hideZero
}

export function getTokenFeePrefs(state: RootState): TokenFeePreference | null {
  return state.account.tokenFeePrefs
}

export function getUserNotifications(state: RootState): UserNotification[] {
  return state.account.userNotifications
}

export function getConnectionError(state: RootState): ConnectionError {
  return state.account.connectionError
}

export function getShowCarbonAddress(state: RootState): boolean {
  return state.account.showCarbonAddress
}

export function getContactData(state: RootState): ContactData {
  return state.account.contactData
}

export function getRecentlyUsedContactData(state: RootState): Contact[] {
  return state.account.recentlyUsedContactData
}

export function getShowAddAddressDialog(state: RootState): boolean {
  return state.account.showAddAddressDialog
}

export function getShowCreateSubAccountDialog(state: RootState): boolean {
  return state.account.showCreateSubAccountDialog
}

export function getPendingSubAccountCreations(state: RootState): SubAccount[] | undefined {
  return state.account.pendingSubAccountCreations
}

export function getPendingSubAccountActivations(state: RootState): SubAccount[] | undefined {
  return state.account.pendingSubAccountActivations
}

export function getSubAccounts(state: RootState): SubAccount[] | undefined {
  return state.account.subAccounts
}

export function getSubAccountCooldown(state: RootState): Duration {
  return state.account.subAccountCooldown
}

export function getIsSWTHDefaultWithdrawal(state: RootState): boolean {
  return state.account.isSWTHDefaultWithdrawal
}

export function getIsSWTHWithdrawal(state: RootState): boolean {
  return state.account.isSWTHWithdrawal
}

export function getReservedTokensPreference(state: RootState): ReservedTokensPreference | null {
  return state.account.reservedTokensPreference
}

export function getIsSignlessEnabled(state: RootState): boolean {
  return state.account.isSignlessTransactionsEnabled
}

export function getGranteeDetailsMap(state: RootState): TypeUtils.SimpleMap<GranteeDetails> {
  return state.account.granteeDetailsMap
}

export function getLastTransactionMap(state: RootState): TypeUtils.SimpleMap<LastTransaction> {
  return state.account.lastTransactionMap
}

export function getSignlessDialogTrigger(state: RootState): string | undefined {
  return state.account.signlessDialogTrigger
}

export function getTxBeforeMergeEvm(state: RootState): TxBeforeMergeEvm | null {
  return state.account.txBeforeMergeEvm
}

export function getIsNewUser(state: RootState): boolean | null {
  return state.account.isNewUser
}

