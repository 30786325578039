import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getHiddenBanners } from 'js/state/modules/app/selectors'
import { darkTheme } from 'js/theme'
import { StyleUtils } from 'js/utils'

import { ReactComponent as Close } from 'assets/Close.svg'

import IconButton from './IconButton'
import SvgIcon from './SvgIcon'

export interface BannerProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick?: React.MouseEventHandler<HTMLElement> | undefined
  onClose?: React.MouseEventHandler<HTMLElement> | undefined
  backgroundImg?: string
  canClose?: boolean
  pngImg?: boolean
  closeBtnClass?: string
  bannerClass?: string
  bannerId?: string
}

const Banner: React.FC<BannerProps> = (props: BannerProps) => {
  const { onClick, className, closeBtnClass, bannerClass, canClose = false, backgroundImg, pngImg, onClose, bannerId, ...rest } = props
  const classes = useStyles()
  const hiddenBanners = useSelector(getHiddenBanners)

  const close = useMemo(() => {
    return bannerId && !!hiddenBanners[bannerId]
  }, [bannerId, hiddenBanners])

  const handleClose = (ev: any) => {
    onClose && onClose(ev)
    ev.stopPropagation()
  }

  return (
    <div {...rest} onClick={onClick} className={clsx(classes.root, className, { close })}>
      {canClose && (
        <IconButton className={clsx(classes.closeBtn, closeBtnClass, { close })} onClick={handleClose}>
          <SvgIcon className={classes.closeIcon} component={Close} />
        </IconButton>
      )}
      <div className={clsx(classes.banner, bannerClass, backgroundImg ? 'backgroundImg' : '', { close })} style={{ backgroundImage: `${pngImg ? `url(${backgroundImg})` : `url("data:image/svg+xml,${backgroundImg}")`}` }} />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  closeBtn: {
    position: 'absolute',
    zIndex: 10,
    '& svg': {
      width: '1.25rem',
      maxHeight: '1.25rem',
      '&:hover': {
        '& path': {
          fill: darkTheme.palette.text.primary,
        },
      },
    },
    '&.close': {
      display: 'none',
    },
  },
  root: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0.5, 0),
    },
    backgroundColor: theme.palette.background.base,
    '&.close': {
      margin: theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
      '&.close': {
        margin: theme.spacing(0),
      },
    },
  },
  banner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: StyleUtils.bannerGradient(theme),
    borderRadius: '4px',
    boxShadow: StyleUtils.dropShadow(theme),
    position: 'relative',
    height: '2.4rem',
    overflow: 'hidden',
    transition: 'height 0.5s',
    [theme.breakpoints.down('md')]: {
      zIndex: 5,
    },
    '&.backgroundImg': {
      background: 'border-box',
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
      },
    },
    '&.close': {
      height: 0,
    },
  },
}))

export default Banner
