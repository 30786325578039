import { RecordOf } from 'immutable'

export interface WelcomeOfferStateProps {
 numUserLeft: number
 openAddTwitterDialog: boolean
 openSignTransactionDialog: boolean
 openDepositDialog: boolean
 openWrongUserDialog: boolean
 openUserCapReachedDialog: boolean
 showStepOneCompletedBanner: boolean
 stepBtnDisabled: boolean
 showCompleteDialog: boolean
}
export type WelcomeOfferState = RecordOf<WelcomeOfferStateProps>

export const WelcomeOfferActionTypes = {
  SET_NUM_USER_LEFT: '@welcomeOffer/SET_NUM_USER_LEFT',
  SET_OPEN_ADD_TWITTER_DIALOG: '@welcomeOffer/SET_OPEN_ADD_TWITTER_DIALOG',
  SET_OPEN_SIGN_TRANSACTION_DIALOG: '@welcomeOffer/SET_OPEN_SIGN_TRANSACTION_DIALOG',
  SET_OPEN_DEPOSIT_DIALOG: '@welcomeOffer/SET_OPEN_DEPOSIT_DIALOG',
  SET_OPEN_WRONG_USER_DIALOG: '@welcomeOffer/SET_OPEN_WRONG_USER_DIALOG',
  SET_OPEN_USER_CAP_REACHED_DIALOG: '@welcomeOffer/SET_OPEN_USER_CAP_REACHED_DIALOG',
  SET_SHOW_STEP_ONE_COMPLETED_BANNER: '@welcomeOffer/SET_SHOW_STEP_ONE_COMPLETED_BANNER',
  SET_SHOW_COMPLETE_DIALOG: '@welcomeOffer/SET_SHOW_COMPLETE_DIALOG',
  SET_STEP_BTN_DISABLED: '@welcomeOffer/SET_STEP_BTN_DISABLED',
  QUERY_USER_STATUS: '@welcomeOffer/QUERY_USER_STATUS',
  QUERY_FOR_NUM_USERS_LEFT: '@welcomeOffer/QUERY_FOR_NUM_USERS_LEFT',
}
