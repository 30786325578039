import { Carbon } from 'carbon-js-sdk'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { getCarbonSDK, getDemexConfig, getNet } from 'js/state/modules/app/selectors'
import { getMarketDisplayName } from 'js/utils/strings'

const useMarketDisplayName = () => {
  const sdk = useSelector(getCarbonSDK)
  const demexConfig = useSelector(getDemexConfig)
  const net = useSelector(getNet)
  const getDisplayName = useCallback((market: Carbon.Market.Market | undefined | null, isUrl: boolean = false) => {
    const marketId = market?.id
    const marketOverride = marketId ? demexConfig?.marketsOverride?.[marketId] : undefined
    return getMarketDisplayName(market, sdk, net, isUrl, marketOverride)
  }, [net, sdk, demexConfig?.marketsOverride])
  return getDisplayName
}

export default useMarketDisplayName
