import { Grid, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import LoadingIcon from 'js/components/Common/LoadingIcon'
import LoadingSkeleton from 'js/components/Common/LoadingSkeleton/LoadingSkeleton'
import { LiteContainer } from 'js/components/Exchange/Lite/Container'
import { LiteProvider } from 'js/components/Exchange/Lite/Provider'
import { useCommonStyles } from 'js/utils'

const LiteModeFallback: React.FC = () => {
  const classes = contentStyles()
  const commonClasses = useCommonStyles()

  return (
    <LiteProvider>
      <LiteContainer>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} md={7} className={classes.gridItem}>
            <div className={clsx(commonClasses.flexColumn, commonClasses.fullHeight)}>
              <div className={classes.banner}>
                <LoadingSkeleton />
              </div>
              <div className={clsx(classes.chartContainerFallback, commonClasses.alignItemsCenter, commonClasses.justifyContentCenter, commonClasses.fullHeight)}>
                <LoadingIcon size="2.5rem" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={5} className={classes.gridItem}>
            <div className={classes.orderContainerFallback}>
              <LoadingSkeleton width={200} containerWidth={200} paddingBottom={48} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton />
            </div>
          </Grid>
          <Grid item xs={12} md={5} className={clsx(classes.gridItem, classes.noTopBottomPadding)}>
            <div className={clsx(classes.banner, 'mobile')}>
            </div>
          </Grid>
        </Grid>
      </LiteContainer>
    </LiteProvider>
  )
}

const contentStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    justifyContent: 'center',
  },
  gridItem: {
    [theme.breakpoints.only('md')]: {
      '&:first-child': {
        padding: theme.spacing(0, 1, 0, 2),
      },
      '&:last-child': {
        padding: theme.spacing(0, 2, 0, 1),
      },
    },
  },
  banner: {
    padding: theme.spacing(2.625, 3),
    marginBottom: theme.spacing(2),
    minHeight: '5rem',
    background: theme.palette.background.primary,
    '&:not(.mobile)': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '&.mobile': {
      height: '118px',
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        display: 'none!important',
      },
    },
  },
  chartContainerFallback: {
    height: '400px',
    background: theme.palette.background.primary,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  orderContainerFallback: {
    background: theme.palette.background.primary,
    height: '100%',
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      height: '670px',
    }
  }
}))

export default LiteModeFallback
