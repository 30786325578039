import { createTheme, Theme } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides' // eslint-disable-line import/no-unresolved

import { SimpleMap } from 'js/utils/types'

import overrides from './overrides'
import { FlavorableOverrides } from './overrides/types'
import { dark, light } from './palettes'
import props from './props/props'
import typography from './typography/typography'

// initialize themes used to apply to overrides
const darkFlavouringTheme = createTheme({ palette: dark })
const lightFlavouringTheme = createTheme({ palette: light })

const flavour = (overrides: SimpleMap<FlavorableOverrides>, theme: Theme) => {
  const result: Overrides = {}
  for (const key in overrides) { // eslint-disable-line guard-for-in
    result[key as keyof Overrides] = overrides[key](theme)
  }
  return result
}


export const lightTheme = createTheme({
  overrides: flavour(overrides, lightFlavouringTheme),
  typography,
  palette: light,
  props,
})

export const darkTheme = createTheme({
  overrides: flavour(overrides, darkFlavouringTheme),
  typography,
  palette: dark,
  props,
})

interface MUIBreakpoints {
  xl: number
  lg: number
  md: number
  sm: number
  xs: number
}

export const muiBreakpoints: MUIBreakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}