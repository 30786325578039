import { makeStyles } from '@material-ui/core'
import { CarbonSDK } from 'carbon-js-sdk'
import clsx from 'clsx'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { getPools } from 'js/state/modules/liquidityPool/selectors'
import { getTokenProperties } from 'js/state/modules/walletBalance/selectors'
import { getUnderlyingDenom } from 'js/utils/strings'

import { ReactComponent as CdpBadge } from 'assets/CdpBadge.svg'

import CoinIcon, { CoinIconProps } from './CoinIcon'
import SvgIcon from './SvgIcon'

interface Props extends CoinIconProps {
  denomA?: string
  denomB?: string
  svgClass?: string
  rightSvgClass?: string
  leftSvgClass?: string
  cdpBadgeClass?: string
}

/*
 * for cdp tokens must pass in denom
 */
const AssetIcon: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const {
    className, denom = '', svgClass,
    denomA: inputDenomA, denomB: inputDenomB,
    rightSvgClass, leftSvgClass, cdpBadgeClass, ...rest } = props
  const classes = useStyles()
  const sdk = useSelector(getCarbonSDK)
  const pools = useSelector(getPools)
  const tokenProperties = useSelector(getTokenProperties)

  let denomA = inputDenomA
  let denomB = inputDenomB

  let symbols: string[] = []
  if (!denom.startsWith('ibc/')) {
    symbols = sdk?.token.getTokenName(denom).split('-') ?? []
  }
  if (symbols.length > 1 && symbols[0].toLowerCase() !== 'cplt') {
    [denomA, denomB] = symbols
  }

  let clpOverrideDenom
  // some components pass in token symbol
  const tokenProperty = tokenProperties[denom] ?? Object.values(tokenProperties).find((property) => property.tokenBasicInfo.assetName === denom)
  if (tokenProperty?.tokenBasicInfo.isPerpPoolToken) {
    clpOverrideDenom = 'CLP'
  }

  const isCdpToken = (denom: string) => {
    return CarbonSDK.TokenClient.isCdpToken(denom)
  }

  const pool = pools[denom.toLowerCase()]

  if (denom && !denomA && !denomB && pool) {
    denomA = pool.denomA
    denomB = pool.denomB
  }

  if (isCdpToken(denom)) {
    const underlyingDenom = getUnderlyingDenom(denom, sdk?.token) ?? ''
    const cdpPool = pools[underlyingDenom.toLowerCase()]
    if (cdpPool) {
      denomA = cdpPool.denomA
      denomB = cdpPool.denomB
    }
  }

  const coinDenom = (denom: string) => {
    let tokenName = sdk?.token.getTokenName(denom) ?? denom
    if (!pool && isCdpToken(denom)) {
      const underlyingDenom = getUnderlyingDenom(denom, sdk?.token) ?? ''
      tokenName = sdk?.token.getTokenName(underlyingDenom) ?? ''
    }
    if (tokenName === denom.toUpperCase() && denom.toLowerCase() !== denom) {
      return denom
    }
    return tokenName
  }

  return (
    <div className={clsx(classes.root, className)}>
      {(denomA && denomB) && (
        <Fragment>
          <div className={clsx(classes.icon, classes.rightIcon, svgClass, rightSvgClass)}>
            <CoinIcon denom={coinDenom(denomA)} className={classes.icon} />
            {
              isCdpToken(denomA) && (
                <SvgIcon className={clsx(classes.cdpBadge, cdpBadgeClass)} component={CdpBadge} />
              )
            }
          </div>
          <div className={clsx(classes.icon, classes.leftIcon, svgClass, leftSvgClass)}>
            <CoinIcon denom={coinDenom(denomB)} className={classes.icon} />
            {
              isCdpToken(denomB) && (
                <SvgIcon className={clsx(classes.cdpBadge, cdpBadgeClass)} component={CdpBadge} />
              )
            }
          </div>
          {isCdpToken(denom) && (
            <SvgIcon className={clsx(classes.cdpBadge, cdpBadgeClass)} component={CdpBadge} />
          )}
        </Fragment>
      )}
      {(!denomA || !denomB) && (
        <Fragment>
          <CoinIcon denom={coinDenom(clpOverrideDenom ?? denom)} {...rest} className={clsx(classes.icon, svgClass)} />
          {isCdpToken(denom) && (
            <SvgIcon className={clsx(classes.cdpBadge, cdpBadgeClass)} component={CdpBadge} />
          )}
        </Fragment>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: '2em',
    width: '2em',
    height: '2em',
    flexShrink: 0,
    margin: '5px',
  },
  icon: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  leftIcon: {
    width: '1.75em',
    height: '1.75em',
    transform: 'translate(-.25em, .5em)',
  },
  rightIcon: {
    width: '1.75em',
    height: '1.75em',
    transform: 'translate(.5em, -.25em)',
  },
  cdpBadge: {
    position: 'absolute',
    right: theme.spacing(-0.5),
    bottom: theme.spacing(-0.5),
    width: '60%',
    height: '60%',
  },
}))

export default AssetIcon
