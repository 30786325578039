import { FormControlLabel, FormControlLabelClassKey, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { PartialBoxProps, extractBoxProps } from 'js/utils/component'

type FormControlClasses = { [key in FormControlLabelClassKey]: string }

interface Props extends PartialBoxProps {
  boxClass?: string
  className?: string
  disabled?: boolean
  value?: any
  control: React.ReactNode
  label: string | React.ReactNode
  formControlClasses?: Partial<FormControlClasses>
  onChange?: (event: React.ChangeEvent<{}>, checked: boolean) => void
}

// For checkboxes, radio buttons and switches
const FormControlBox: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const { boxProps, restProps } = extractBoxProps(props)
  const { boxClass, className, disabled = false, formControlClasses = {}, ...rest } = restProps

  return (
    <div className={clsx(classes.box, boxClass)} {...boxProps}>
      <FormControlLabel
        className={clsx(classes.formControl, className)}
        disabled={disabled}
        classes={formControlClasses}
        {...rest}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: 'inline',
  },
  formControl: {
    marginLeft: 0,
    marginRight: 0,
  },
}))

export default FormControlBox
