import { CrossSellingStage, TradeHelpWizardStage } from 'js/constants/crossSelling'
import { HighlightFocusState } from 'js/state/modules/crossSelling/types'

import { RootState } from '../rootReducer'

export function getEnableCrossSelling(state: RootState): boolean {
  return state.crossSelling.crossSellingStatus.enableCrossSelling
}

export function getShowCrossSellingWidget(state: RootState): boolean {
  return state.crossSelling.showCrossSellingWidget
}

export function getCrossSellingSourceToken(state: RootState): string | null {
  return state.crossSelling.crossSellingStatus.crossSellingSourceToken
}

export function getCrossSellingStage(state: RootState): CrossSellingStage {
  return state.crossSelling.crossSellingStatus.crossSellingStage
}

export function getShowCrossSellingDialog(state: RootState): boolean {
  return state.crossSelling.showCrossSellingDialog
}

export function getShowTradeHelpWizard(state: RootState): boolean {
  return state.crossSelling.showTradeHelpWizard
}

export function getTradeHelpWizardStage(state: RootState): TradeHelpWizardStage {
  return state.crossSelling.tradeHelpWizardStage
}

export function getHighlightFocusState(state: RootState): HighlightFocusState {
  return state.crossSelling.highlightFocusState
}