import { createStyles, Divider, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { IconButton, SvgIcon } from 'js/components/Common'
import BackButton from 'js/components/Common/BackButton'
import Button from 'js/components/Common/Button'
import NotificationBell from 'js/components/Common/NotificationBell'
import Paths from 'js/constants/paths'
import { EvtCategory, useEventTrackers } from 'js/hooks'
import { setAllMenusClose, setMobileNotificationMenuOpen } from 'js/state/modules/app/actions'
import { getOpenMenu, getOpenNodeMenu, getOpenNotificationMenu } from 'js/state/modules/app/selectors'
import { getIsSimpleMode } from 'js/state/modules/exchange/selectors'

import { ReactComponent as Close } from 'assets/Close.svg'
import { ReactComponent as DemexLogoLight } from 'assets/DemexLogoLight.svg'

interface SliderTopBarClasses {
  root?: string
  divider?: string
  button?: string
}

interface Props {
  backButton?: boolean,
  backButtonOnClick?: () => void
  noBottomDivider?: boolean,
  customClasses?: SliderTopBarClasses
  addPadding?: boolean
  showNotificationIcon?: boolean
  additionalHeader?: React.ReactNode
}

const SliderTopBar: React.FC<Props> = (props: Props) => {
  const { backButton, backButtonOnClick, customClasses = {}, addPadding = false, noBottomDivider = true, showNotificationIcon = false, additionalHeader } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const eventTrackers = useEventTrackers()
  const history = useHistory()

  const isSimpleMode = useSelector(getIsSimpleMode)
  const openMenu = useSelector(getOpenMenu)
  const openNodeMenu = useSelector(getOpenNodeMenu)
  const openNotificationMenu = useSelector(getOpenNotificationMenu)

  const closeMenu = () => {
    dispatch(setAllMenusClose())
  }

  const openMobileNotifications = () => {
    dispatch(setMobileNotificationMenuOpen())
  }

  const handleClickTrade = () => {
    closeMenu()
    history.push(Paths.Home)
    eventTrackers.sendEvent(EvtCategory.Navigation, 'menu_trade', { source: 'landingPage' }, isSimpleMode ? 'lite' : 'pro')
  }

  return (
    <React.Fragment>
      <div
        className={clsx(classes.menuTopBar, { addPadding }, customClasses.root)}
        role="presentation"
      >
        <div>
          {(openMenu || openNodeMenu) && (
            <IconButton className={classes.closeBtn} onClick={closeMenu}>
              <SvgIcon className={classes.crossIcon} component={Close} />
            </IconButton>
          )}
          <Button
            className={classes.logo}
            onClick={handleClickTrade}
            color="inherit"
            baseButtonVariant="text"
            disableFocusRipple
            disableRipple
          >
            <SvgIcon className={clsx(classes.logoSvg, classes.logoFill)} component={DemexLogoLight} />
          </Button>
        </div>
        {showNotificationIcon && (
          openNotificationMenu ? (
            <NotificationBell outlined={openNotificationMenu} />
          ) : (
            <div onClick={openMobileNotifications}>
              <NotificationBell />
            </div>
          )
        )}
      </div>
      {backButton ? (
        <React.Fragment>
          <Divider className={clsx(classes.divider, { addPadding }, customClasses.divider)} />
          <BackButton
            onClick={backButtonOnClick}
            boxClass={clsx(classes.buttonBack, classes.iconAlt, { addPadding }, customClasses.button)}
          />
          {!noBottomDivider && (
            <Divider className={clsx(classes.divider, { addPadding }, customClasses.divider)} />
          )}
        </React.Fragment>
      ) : (
        <Divider className={clsx(classes.divider, { addPadding }, customClasses.divider)} />
      )}
      {additionalHeader}
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => (createStyles({
  logo: {
    height: 'unset',
    maxHeight: 'unset',
    '&:hover, &:active': {
      backgroundColor: 'transparent',
    },
  },
  logoSvg: {
    width: theme.spacing(11.25),
    height: theme.spacing(3),
  },
  logoFill: {
    '& > path:nth-child(5)': {
      fill: theme.palette.text.primary,
    },
  },
  closeBtn: {
    padding: theme.spacing(1, 0.75),
  },
  crossIcon: {
    height: '1.375rem',
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },
  notificationBtn: {
    [theme.breakpoints.down('xs')]: {
      '&:hover svg path': {
        stroke: `${theme.palette.text.secondary} !important`,
      },
    },
  },
  notificationIcon: {
    height: '1.375rem',
    '&.active path, &.active:hover path': {
      fill: `none !important`,
      stroke: `${theme.palette.text.primary} !important`,
    },
    '&:hover path': {
      fill: 'none !important',
    }
  },
  divider: {
    '&.addPadding': {
      margin: theme.spacing(0, 2.5),
    },
  },
  menuTopBar: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(0.625),
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.secondary,
    '&.addPadding': {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
  },
  backArrow: {
    height: '0.75rem',
    marginRight: theme.spacing(1),
  },
  backText: {
    ...theme.typography.body3,
    fontWeight: 'bold',
  },
  buttonBack: {
    padding: '0.625rem 0rem',
    '&.addPadding': {
      marginLeft: theme.spacing(2.5),
    },
    '&:hover, &:active': {
      backgroundColor: 'transparent',
    },
  },
  iconAlt: {
    minWidth: '1.75rem',
  },
})))

export default SliderTopBar
