import { CarbonSDK, Zilpay } from 'carbon-js-sdk'

import { logger } from 'js/utils'

const cache: {
  [index: string]: Zilpay
} = {}

export interface UseZilPayResult {
  zilpay: Zilpay
  registerListener: (listener: ZilPayChainChangeListener) => (() => void)
  registerNetworkListener: (network: CarbonSDK.Network) => void
}

export interface ZilPayChainChangeListener {
  (): void | Promise<void>
}
interface ZilPayChainChangeListeners {
  [index: string]: ZilPayChainChangeListener
}

const networkChangeListeners: ZilPayChainChangeListeners = {}

let listenerId = 0
let networkListener = false

const registerListener = (listener: ZilPayChainChangeListener) => {
  const id = listenerId++
  networkChangeListeners[id] = listener
  const deregisterListener = () => removeListener(id)
  return deregisterListener
}

const registerNetworkListener = (network: CarbonSDK.Network) => {
  if (!networkListener) {
    (cache[network].getAPI() as any)?.wallet.observableNetwork().subscribe((network: any) => {
      logger('zilpay network change', network)
      Object.values(networkChangeListeners).forEach((listener) => {
        try {
          listener()
        } catch (err) {
          console.error(err)
        }
      })
    })
    networkListener = true
  }
}

const removeListener = (id: number) => {
  delete networkChangeListeners[id]
}

const useZilPay = (net: CarbonSDK.Network): UseZilPayResult => {
  if (!cache[net]) {
    cache[net] = new Zilpay(net)
  }

  if (!networkListener) {
    try {
      registerNetworkListener(net)
    } catch (error) { console.error(error) }
  }

  return { zilpay: cache[net], registerListener, registerNetworkListener }
}
export default useZilPay
