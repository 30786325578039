import { Theme } from '@material-ui/core'

import { FlavorableOverrides } from './types'

const MuiTable: FlavorableOverrides = (theme: Theme) => ({
  root: {
    borderColor: theme.palette.divider,
  },
})

export default MuiTable
