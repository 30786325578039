import { Theme, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import generateJazzicon from 'jazzicon' // eslint-disable-line import/no-unresolved
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getSocialLoginInfo } from 'js/state/modules/account/selectors'
import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { charactersStr } from 'js/utils/strings'
import { StyleUtils } from 'js/utils/styles'

interface Props {
  className?: string
  diameter?: number
}

const ProfileAvatar: React.FC<Props> = (props: Props) => {
  const { className, diameter = 32 } = props
  const classes = useStyles()
  const sdk = useSelector(getCarbonSDK)
  const userInfo = useSelector(getSocialLoginInfo)

  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null)
  const [accountNumber, setAccountNumber] = useState<number>(0)

  useEffect(() => {
    const imageUrl = userInfo?.profileImageUrl
    if (imageUrl) {
      // assign profile image for display only if it loads
      const image = new Image()
      image.onload = () => setProfileImageUrl(imageUrl)
      image.src = imageUrl
    } else {
      setProfileImageUrl(null)
    }
  }, [userInfo?.profileImageUrl])

  // Generate int from address (fallback in the case where account does not have account number)
  const generateIntFromAddr = (addr: string): number => {
    const addrArr = addr.split('')
    if (addrArr.length === 0) return 0
    const addrInt = addrArr.reduce((prev: number, char: string, index: number) => {
      return prev + charactersStr.indexOf(char) + index
    }, 0)
    return addrInt
  }

  const handleRenderAvatar = useCallback((node: HTMLDivElement | null) => {
    const profileAvatarEl = node?.querySelector('#profile-avatar-jazzicon')
    if (node && profileAvatarEl) {
      node.removeChild(profileAvatarEl)
    }

    if (accountNumber <= 0 && !sdk?.wallet?.bech32Address) return

    const addrInt = generateIntFromAddr(sdk?.wallet?.bech32Address ?? '')
    const el = generateJazzicon(diameter, accountNumber + addrInt)
    if (el) {
      el.setAttribute('id', 'profile-avatar-jazzicon')
      node?.appendChild(el)
    }
  }, [accountNumber, diameter, sdk?.wallet])

  useEffect(() => {
    let isMounted: boolean = true
    const getChainAccount = async () => {
      if (!(sdk && sdk?.wallet?.bech32Address)) return

      let accountNumber: number = 0
      try {
        const account = await sdk.query.chain.getAccount(sdk.wallet.bech32Address)
        accountNumber = account?.accountNumber ?? 0
      } catch (err) {
        accountNumber = 0
        console.error(err)
      } finally {
        if (isMounted) setAccountNumber(accountNumber)
      }
    }
    getChainAccount()
    return () => {
      isMounted = false
    }
  }, [sdk])

  if (!sdk?.wallet?.bech32Address) {
    return (
      <div className={clsx(classes.default, className)} style={{ width: diameter, height: diameter }}>
        U
      </div>
    )
  }

  return (
    <div
      style={{
        width: diameter + 4,
        height: diameter + 4,
      }}
      className={clsx(className, classes.circularBorder)}
    >
      {!profileImageUrl && (<div ref={handleRenderAvatar} />)}
      {profileImageUrl && (<img alt='Profile' src={profileImageUrl} />)}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  default: {
    ...theme.typography.body3,
    background: StyleUtils.primaryBranding,
    color: theme.palette.common.white,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
  },
  circularBorder: {
    border: `2px solid ${theme.palette.primary.main}`, // Choose border width and color as per your requirements
    borderRadius: '50%',
    '& img': {
      height: '100%',
      width: '100%',
      borderRadius: '50%',
    },
  },
}))

export default ProfileAvatar
