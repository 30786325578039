import BigNumber from 'bignumber.js'
import type { Insights } from 'carbon-js-sdk'
import { RecordOf } from 'immutable'

import { Iteration } from 'js/constants/competition'
import { SimpleMap } from 'js/utils'

export interface CompetitionStateProps {
  cumulativeVolume: BigNumber
  volumeData: Insights.PerpsCompTradingVolumeEntry[]
  pnlData: Insights.PNLLeaderboardEntry[]
  lotteryData: Insights.LotteryLeaderboardEntry[]
  leagueData: Insights.LeaderboardLeagueEntry[]
  cosmosDataSubCompetitionIdMap: SimpleMap<Insights.LeaderboardCosmosEntry>
  currentCompetition: Insights.CompetitionItem | undefined
  pastCompetition: Insights.CompetitionItem | undefined
  upcomingCompetition: Insights.CompetitionItem | undefined
  subCompetitionsMap: SimpleMap<SubCompetition>
  activeStep: number
  disableOpen: boolean
  isRegistered: boolean
  registeredSubCompetitionId: string | undefined

  carbonCreditsSeasons: CarbonCreditsSeasonInfo[]
  carbonCreditsRootHash: string
  accumulatedSeasonsRewards: SwthRewardsRecord[]
  ongoingSeason: CarbonCreditsSeasonInfo | undefined
  totalAccumulatedRewards: BigNumber | undefined
  unclaimedRewards: BigNumber | undefined
  merkleTree: string[][]
  leaderboardPnl: LeaderboardPnl[] | undefined

  userDemexPoints: UserDemexPoints | undefined
  demexPointsLeaderboard: DemexPointsLeaderboard[] | undefined
  hideDemexPointsGuide: string[]
  userTradingVolumeCampaign: BigNumber

  lossProtectionLeaderboard: LossProtectionRecord[] | undefined
  showLossProtectionGuide: boolean
  showLossProtectionRegistration: boolean

  memeTradingLeaderboard: any,
  showMemeTradingGuide: boolean,
  showMemeTradingRegistration: boolean,

  showMantleTradingGuide: boolean,
  showMantleTradingRegistration: boolean,
}

export interface CompLeaderboardFilters {
  market?: string
  iteration?: Iteration
}

export interface VolumeChallengeFilters {
  iteration?: Iteration
  address?: string
}

export interface SubCompetition extends Insights.CompetitionItemConfig {
  competitionId: string
  subCompetitionId: string
  lendingQuantityBN: BigNumber
}

export type CompetitionState = RecordOf<CompetitionStateProps>

export const CompetitionActionTypes = {
  SET_MARKET_CUMULATIVE_VOLUME: '@competition/SET_CUMULATIVE_VOLUME',
  QUERY_MARKET_CUMULATIVE_VOLUME: '@competition/QUERY_CUMULATIVE_VOLUME',
  SET_VOLUME_LEADERBOARD_DATA: '@competition/SET_VOLUME_LEADERBOARD_DATA',
  QUERY_VOLUME_LEADERBOARD_DATA: '@competition/QUERY_VOLUME_LEADERBOARD_DATA',
  SET_PNL_LEADERBOARD_DATA: '@competition/SET_PNL_LEADERBOARD_DATA',
  QUERY_PNL_LEADERBOARD_DATA: '@competition/QUERY_PNL_LEADERBOARD_DATA',
  SET_LOTTERY_LEADERBOARD_DATA: '@competition/SET_LOTTERY_LEADERBOARD_DATA',
  QUERY_LOTTERY_LEADERBOARD_DATA: '@competition/QUERY_LOTTERY_LEADERBOARD_DATA',
  SET_LEAGUE_LEADERBOARD_DATA: '@competition/SET_LEAGUE_LEADERBOARD_DATA',
  QUERY_LEAGUE_LEADERBOARD_DATA: '@competition/QUERY_LEAGUE_LEADERBOARD_DATA',
  SET_COSMOS_DATA_MAP_LEADERBOARD_DATA: '@competition/SET_COSMOS_DATA_MAP_LEADERBOARD_DATA',
  QUERY_COSMOS_LEADERBOARD_DATA: '@competition/QUERY_COSMOS_LEADERBOARD_DATA',
  QUERY_COMPETITION_LIST: '@competition/QUERY_COMPETITION_LIST',
  SET_CURRENT_COMPETITION: '@competition/SET_CURRENT_COMPETITION',
  SET_PAST_COMPETITION: '@competition/SET_PAST_COMPETITION',
  SET_UPCOMING_COMPETITION: '@competition/SET_UPCOMING_COMPETITION',
  SET_SUBCOMPETITIONS_MAP: '@competition/SET_SUBCOMPETITIONS_MAP',
  SET_ACTIVE_STEP: '@competition/SET_ACTIVE_STEP',
  SET_DISABLE_OPEN_SIGNUP: '@competition/SET_DISABLE_OPEN_SIGNUP',
  SET_IS_REGISTERED: '@competition/SET_IS_REGISTERED',
  QUERY_IS_REGISTERED: '@competition/QUERY_IS_REGISTERED',
  SET_REGISTERED_SUBCOMPETITION_ID: '@competition/SET_REGISTERED_SUBCOMPETITION_ID',

  FETCH_CARBON_CREDITS_SEASON_INFO: '@competition/FETCH_CARBON_CREDITS_SEASON_INFO',
  SET_CARBON_CREDITS_SEASON_INFO: '@competition/SET_CARBON_CREDITS_SEASON_INFO',
  SET_CARBON_CREDITS_ROOT: '@competition/SET_CARBON_CREDITS_ROOT',
  SET_ACCUMULATED_SEASONS_REWARDS: '@competition/SET_ACCUMULATED_SEASONS_REWARDS',
  SET_ONGOING_SEASON_INFO: '@competition/SET_ONGOING_SEASON_INFO',
  SET_TOTAL_ACCUMULATED_REWARDS: '@competition/SET_TOTAL_ACCUMULATED_REWARDS',
  SET_UNCLAIMED_REWARDS: '@competition/SET_UNCLAIMED_REWARDS',
  FETCH_UNCLAIMED_REWARDS: '@competition/FETCH_UNCLAIMED_REWARDS',
  SET_MERKLE_TREE: '@competition/SET_MERKLE_TREE',
  FETCH_CARBON_CREDITS_PNL_LEADERBOARD: '@competition/FETCH_CARBON_CREDITS_PNL_LEADERBOARD',
  SET_CARBON_CREDITS_LEADERBOARD_PNL: '@competition/SET_CARBON_CREDITS_LEADERBOARD_PNL',

  FETCH_USER_DEMEX_POINTS: '@competition/FETCH_USER_DEMEX_POINTS',
  SET_USER_DEMEX_POINTS: '@competition/SET_USER_DEMEX_POINTS',
  SET_POLL_USER_DEMEX_POINTS: '@competition/SET_POLL_USER_DEMEX_POINTS',
  SET_SPINNED_IDS: '@competition/SET_SPINNED_IDS',
  SET_DEMEX_POINTS_LEADERBOARD: '@competition/SET_DEMEX_POINTS_LEADERBOARD',
  FETCH_DEMEX_POINTS_LEADERBOARD: '@competition/FETCH_DEMEX_POINTS_LEADERBOARD',
  SET_HIDE_DEMEX_POINTS_GUIDE: '@competition/SET_HIDE_DEMEX_POINTS_GUIDE',

  QUERY_USER_TRADING_VOLUME_CAMPAIGN: '@competition/QUERY_USER_TRADING_VOLUME_CAMPAIGN',
  SET_USER_TRADING_VOLUME_CAMPAIGN: '@competition/SET_USER_TRADING_VOLUME_CAMPAIGN',

  QUERY_LOSS_PROTECTION_LEADERBOARD: '@competition/QUERY_LOSS_PROTECTION_LEADERBOARD',
  SET_LOSS_PROTECTION_LEADERBOARD: '@competition/SET_LOSS_PROTECTION_LEADERBOARD',
  SET_SHOW_GUIDE: '@competition/SET_SHOW_GUIDE',
  SET_SHOW_REGISTRATION: '@competition/SET_SHOW_REGISTRATION',
  SET_POLL_LOSS_PROTECTION: '@competition/SET_POLL_LOSS_PROTECTION',

  QUERY_MEME_TRADING_LEADERBOARD: '@competition/QUERY_MEME_TRADING_LEADERBOARD',
  SET_MEME_TRADING_LEADERBOARD: '@competition/SET_MEME_TRADING_LEADERBOARD',
  SET_SHOW_MEME_TRADING_GUIDE: '@competition/SET_SHOW_MEME_TRADING_GUIDE',
  SET_SHOW_MEME_TRADING_REGISTRATION: '@competition/SET_SHOW_MEME_TRADING_REGISTRATION',
  SET_POLL_MEME_TRADING: '@competition/SET_POLL_MEME_TRADING',

  SET_SHOW_MANTLE_TRADING_GUIDE: '@competition/SET_SHOW_MANTLE_TRADING_GUIDE',
  SET_SHOW_MANTLE_TRADING_REGISTRATION: '@competition/SET_SHOW_MANTLE_TRADING_REGISTRATION',
  SET_POLL_MANTLE_TRADING: '@competition/SET_POLL_MANTLE_TRADING',
}

export const CompetitionTasks = {
  ReloadPNL: 'reload-pnl-data',
  ReloadLottery: 'reload-lottery-data',
  ReloadLeague: 'reload-league-data',
  ReloadVolume: 'reload-volume-data',
  ReloadCompetitionList: 'reload-competition-list',
  ReloadRegisteredStatus: 'reload-registered-status',
  ReloadCarbonCreditsLeaderboard: 'reload-carbon-credits-leaderboard',
  ReloadPnlLeaderboard: 'reload-pnl-leaderboard',
  ReloadClaimedRewards: 'reload-claimed-rewards',
  ReloadUserDemexPoints: 'reload-user-demex-points',
  ReloadDemexPointsLeaderboard: 'reload-demex-points-leaderboard',
  ReloadLossProtectionLeaderboard: 'reload-loss-protection-leaderboard',
  ReloadMemeTradingLeaderboard: 'reload-meme-trading-leaderboard',
}

export interface RegisterProps {
  address: string
  sign: {
    pubKey: string
  }
  competitionId: string
  subCompetitionId?: string
}

export interface CarbonCreditsSeasonInfo {
  epoch: number
  unixStart: number
  twap: BigNumber
  prizePool: BigNumber
  totalPoints?: BigNumber
  rewards: SwthRewardsRecord[]
}

export interface SwthRewardsRecord {
  rank: number
  address: string
  reward: BigNumber
  points: BigNumber
  volume: BigNumber
}

export type PnlDurations = 1 | 7 | 30 | 300 | 0
export interface LeaderboardPnl {
  address: string
  rank: number
  totalPnl: BigNumber
  profitPercent: BigNumber
}

export interface DemexPointsLeaderboard {
  rank: number
  address: string
  points: number
  tier: number
  referrerTier: number
}

export interface SquadMemberInfo {
  address: string
  points: number
}
export interface SquadUserInfo {
  tier: number
  squadPoints: number
  members: SquadMemberInfo[]
}

export interface SquadReferrerInfo {
  tier: number
  squadPoints: number
  captain: string
}
export interface SquadInfo {
  user: SquadUserInfo
  referrer: SquadReferrerInfo
}

type RetroStatus = 'null' | 'unclaimed' | 'claimed'
export interface UserDemexPoints {
  points: number
  volume: number
  uncreditedVolume: number
  uncreditedPerpPool: number
  retroPoints: number
  retroStatus: RetroStatus
  unspinned: number[] // an array of loot box ids that is unopened
  squads: SquadInfo
}

export interface CrewLeaderboardEntry {
  subCompetitionId: string,
  totalPoints: BigNumber,
  prize: BigNumber,
  participantsCount: BigNumber,
  rank: number
}

export interface CrewMemberLeaderboardEntry extends Insights.LeaderboardLeagueEntry {
  prize: BigNumber
}
export interface LossProtectionRecord {
  rank: number
  address: string
  totalRebate: BigNumber
  totalRPNL: BigNumber
  totalVolume: BigNumber
}

export interface MemeTradingRecord {
  rank: number
  address: string
  totalPNL: BigNumber
  rewards: BigNumber | null
  username?: string
}
