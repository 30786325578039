export const baseColors = {
  blue: {
    50: '#00B2FF',
    100: '#007AFF',
    200: '#482BFF',
  },
  green: {
    50: '#00AF92',
    100: '#00A862',
  },
  red: {
    50: '#FF5107',
    100: '#E84747',
    200: '#FF8181'
  },
  yellow: {
    100: '#FFA800',
    200: '#FF5107',
  },
  purple: {
    100: '#BB91FC',
    200: '#33134F'
  },
  chains: {
    carbon: '#53C2C1',
    neo: '#00E599',
    zilliqa: '#29CCC4',
    ethereum: '#7F7CFF',
    polygon: '#AB85EC',
    bsc: '#FFB900',
    osmosis: '#332DC2',
  },
  buyFlash: '#10543A',
  sellFlash: '#6D2D2F',
}

export const darkColors = {
  ...baseColors,
  mono: {
    text: {
      primary: '#F5F5F7',
      secondary: '#A4A5A8',
      tertiary: '#6F7073',
      disabled: '#6F7073',
      disabledBackground: '#444546',
      contrast: '#1F1D1A',
      animated: '#0096FF',
      demexSolid: '#148AFF',
      demexSolidHover: '#79B6F9',
    },
    background: {
      base: '#111213',
      primary: '#1A1D1F',
      secondary: '#272A2E',
      tertiary: 'rgba(255, 255, 255, 0.06)',
      divider: 'rgba(255, 255, 255, 0.08)',
      active: 'rgba(255, 255, 255, 0.04)', // hover/selected
    },
    shadow: 'rgba(0, 0, 0, 0.64)',
    scrollbar: {
      thumb: '#6C6E71',
      track: '#414447',
    },

  },
}

export const lightColors = {
  ...baseColors,
  mono: {
    text: {
      primary: '#1F1D1A',
      secondary: '#676561',
      tertiary: '#B7B6B4',
      disabled: '#B1ACA4',
      disabledBackground: '#DBD9D5',
      contrast: '#F5F5F7',
      animated: '#0096FF',
      demexSolid: '#148AFF',
      demexSolidHover: '#79B6F9',
    },
    background: {
      base: '#FAF9F8',
      primary: '#FFFFFF',
      secondary: '#FBFAF9',
      tertiary: 'rgba(31, 29, 26, 0.03)',
      divider: 'rgba(31, 29, 26, 0.08)',
      active: 'rgba(31, 29, 26, 0.02)', // hover/selected
    },
    shadow: 'rgba(31, 29, 26, 0.1)',
    scrollbar: {
      thumb: '#ABAAA9',
      track: '#DDDCDB',
    },
  },
}

export const switcheo = {
  default: {
    main: '#4276E6',
    white: '#FFFFFF',
  },
  blue: {
    50: '#ECF1FC',
    100: '#E7EDF9',
    200: '#5583E8',
  },
  green: {
    50: '#E4F5F0',
    100: '#32B683',
    300: '#02A566',
  },
  red: {
    50: '#FBECED',
    100: '#EF5D5E',
    200: '#EE4B4C',
  },
  yellow: {
    50: '#FBF5E6',
    100: '#E6A100',
    200: '#CC8800',
  },
  mono: {
    50: '#FCFDFF', // background for tables
    100: '#F5F7FA', // main background
    200: '#DFE4EF', // text colours
    3000: '#CFD5EA', // outline
    400: '#B3B9C5', // disabled button text colour
    500: '#6B748B', // secondary text colour
    600: '#363B46', // primary text colour
    700: '#8E939B', // text colour for list subheaders
    800: '#191E2C', // tooltip background

    // accent colours here
    A50: '#F8FAFD', // background for sections in modals (eg swth deposits)
    A100: '#EEF0F5', // disabled button colour
    A200: '#E7EDF9', // used in dropdown hovers
    A300: '#EFF3FB', // used in 'you will receive' sections in modals
    A400: '#F2F5FA', // text button hover colour
    A500: '#E3E8F7', // outline
    A600: '#DFE5F0', // scrollbar
    A700: '#D7E0F6', // unused
  },
  error: '#FA4E4F',
  warning: '#EEAD00',
  success: '#00B66F',
}
