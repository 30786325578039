import { isMobile } from 'react-device-detect'

import { EncryptedKeyIcon, KeplrIcon, LeapIcon, LedgerIcon, MetaMaskIcon, PhantomIcon, Web3Icon } from 'assets/login'
import { GoogleIcon, XIcon } from 'assets/socials'
import { o3wallet, zilpay } from 'assets/transfer_methods'

export enum Page {
  Ledger = 'ledger',
  Keplr = 'keplr',
  Leap = 'leap',
  Metamask = 'metamask',
  EncryptedKey = 'encryptedKey',
  Main = 'main',
  Register = 'register',
  Reset = 'reset',
}

export type LoginMethod = {
  page?: Page
  key: string
  name: string
  wallet?: string
  newTag?: boolean
  image: SVGComponent
  imageViewBox?: string
  isRainbowKit?: boolean
}

export enum ExternalWalletMethodKey {
  O3Wallet = 'o3Wallet',
  ZilPay = 'zilpay',
}

export enum SocialsMethodKey {
  Google = 'google',
  Twitter = 'twitter',
}

export enum MainWalletMethodKey {
  Ledger = 'ledger',
  Keplr = 'keplr',
  Leap = 'leap',
  Web3 = 'web3',
  EncryptedKey = 'encryptedKey',
  Metamask = 'metamask',
  Phantom = 'phantom',
}

export const WALLET_METHODS: Record<MainWalletMethodKey, LoginMethod> = {
  [MainWalletMethodKey.Web3]: {
    key: MainWalletMethodKey.Web3,
    name: isMobile ? 'Mobile Wallets' : 'Browser Wallets',
    wallet: 'web3',
    image: Web3Icon,
    isRainbowKit: true,
  },
  [MainWalletMethodKey.Metamask]: {
    key: MainWalletMethodKey.Metamask,
    name: 'Metamask',
    wallet: 'metamask',
    image: MetaMaskIcon,
    isRainbowKit: true,
  },
  [MainWalletMethodKey.Phantom]: {
    key: MainWalletMethodKey.Phantom,
    name: 'Phantom',
    wallet: 'phantom',
    image: PhantomIcon,
    isRainbowKit: true,
  },
  [MainWalletMethodKey.Keplr]: {
    page: Page.Keplr,
    key: MainWalletMethodKey.Keplr,
    name: 'Keplr',
    image: KeplrIcon,
  },
  [MainWalletMethodKey.Leap]: {
    page: Page.Leap,
    key: MainWalletMethodKey.Leap,
    name: 'Leap',
    image: LeapIcon,
    imageViewBox: '0 0 68 68',
  },
  [MainWalletMethodKey.Ledger]: {
    page: Page.Ledger,
    key: MainWalletMethodKey.Ledger,
    name: 'Ledger',
    image: LedgerIcon,
  },
  [MainWalletMethodKey.EncryptedKey]: {
    page: Page.EncryptedKey,
    key: MainWalletMethodKey.EncryptedKey,
    name: 'Encrypted Key',
    image: EncryptedKeyIcon,
  }
}

export const SOCIAL_METHODS: Record<SocialsMethodKey, LoginMethod> = {
  [SocialsMethodKey.Google]: {
    key: SocialsMethodKey.Google,
    name: 'Connect with Google',
    image: GoogleIcon,
  },
  [SocialsMethodKey.Twitter]: {
    key: SocialsMethodKey.Twitter,
    name: 'Connect with X',
    image: XIcon,
  },
}

export const EXTERNAL_WALLET_METHODS: Record<ExternalWalletMethodKey, LoginMethod> = {
  [ExternalWalletMethodKey.O3Wallet]: {
    key: ExternalWalletMethodKey.O3Wallet,
    name: 'O3Wallet',
    image: o3wallet,
  },
  [ExternalWalletMethodKey.ZilPay]: {
    key: ExternalWalletMethodKey.ZilPay,
    name: 'Zilpay',
    image: zilpay,
  },
}
export const LOGIN_TIMEOUT_MS = 60000
export const GET_WALLET_PROVIDER_TIMEOUT_MS = 7000
