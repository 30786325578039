import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getSDKWallet } from 'js/state/modules/app/selectors'
import { getLeaderboardRankMap } from 'js/state/modules/leaderboard/selectors'
import { LeaderMap, LeaderBoardEntry } from 'js/state/modules/leaderboard/types'

export enum LeaderBoardLbls {
  H24 = 'last24Hours',
  D7 = 'last7Days',
  D30 = 'last30Days',
  ALL = 'allTime'
}

export interface RankObj {
  [key: string]: LeaderBoardEntry | undefined
}

export const UnrankedNum: number = 101

const useUserState = () => {
  const leaderboardRankMap = useSelector(getLeaderboardRankMap) as LeaderMap
  const wallet = useSelector(getSDKWallet)

  const rankObj = useMemo((): RankObj => {
    return Object.values(LeaderBoardLbls).reduce((prev: RankObj, key: string) => {
      const newPrev: RankObj = prev

      const rankMap = leaderboardRankMap[key] ?? { top: [], bottom: [] }
      const topItem = rankMap?.top?.find((entry: LeaderBoardEntry) => entry.address === wallet?.bech32Address)
      const bottomItem = rankMap?.bottom?.find((entry: LeaderBoardEntry) => entry.address === wallet?.bech32Address)
      if (topItem || bottomItem) {
        newPrev[key] = topItem ?? bottomItem
      }
      return prev
    }, {})
  }, [leaderboardRankMap, wallet?.bech32Address]) // eslint-disable-line

  const getRank = (duration: LeaderBoardLbls): number => {
    if (!wallet) return UnrankedNum
    return rankObj[duration]?.rank ?? UnrankedNum
  }

  const getPnl = (duration: LeaderBoardLbls): BigNumber => {
    if (!wallet) return new BigNumber(0)
    return rankObj[duration]?.totalPnl ?? new BigNumber(0)
  }

  return {
    getRank, getPnl,
  }
}

export default useUserState
