import BigNumber from 'bignumber.js'
import { WSModels } from 'carbon-js-sdk'
import { RecordOf, List } from 'immutable'

export interface WSOrderBook {
  asks: WSModels.PriceLevel[]
  bids: WSModels.PriceLevel[]
}

export interface OrderBookStateProps {
  readonly openSells: List<OrderBookEntry>
  readonly openBuys: List<OrderBookEntry>
}

export interface OrderBookEntry extends WSModels.PriceLevel {
  market: string
  isNewOrderPrice?: boolean
}

// WS Event Response
export interface OrderBookWsEvent {
  market: string
  price: string
  priceBN: BigNumber
  quantity: string
  side: string
  type: string
}

export type OrderBookState = RecordOf<OrderBookStateProps>

export const OrderBookActionTypes = {
  SET_OPEN_BUYS: '@orderBook/SET_OPEN_BUYS',
  SET_OPEN_SELLS: '@orderBook/SET_OPEN_SELLS',
  SYNC_ORDERBOOK: '@orderBook/SYNC_ORDERBOOK',
  CLEAR_ORDERBOOK: '@orderBook/CLEAR_ORDERBOOK',

  TEST_UPDATE_ORDERBOOK: '@orderBook/TEST_UPDATE_ORDERBOOK',
}
