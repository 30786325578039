import { Theme } from '@material-ui/core'

import { FlavorableOverrides } from './types'

const MuiButton: FlavorableOverrides = (theme: Theme) => ({
  root: {
    textTransform: 'none',
    borderRadius: '2px',
  },
})

export default MuiButton
