import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { EvmWebSocketActionTypes, EvmWsState, EvmWsStateProps } from './types'

export const DefaultInitialState:
  Record.Factory<EvmWsStateProps> = Record<EvmWsStateProps>({
    isConnected: false,
    toConnect: false,
  })

const defaultInitialState: EvmWsState = new DefaultInitialState()

export const evmWsReducer = (
  state: EvmWsState = defaultInitialState,
  action: AnyAction,
): EvmWsState => {
  switch (action.type) {
    case EvmWebSocketActionTypes.STATUS_CHANGE: {
      return state.set('isConnected', action.payload)
    }
    case EvmWebSocketActionTypes.DISCONNECT:
      return state.set('toConnect', false)
    case EvmWebSocketActionTypes.CONNECT:
      return state.set('toConnect', true)
    default:
      return state
  }
}
