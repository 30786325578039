import BigNumber from 'bignumber.js'
import { BlockchainUtils, Carbon, CarbonSDK, TokenUtils, TypeUtils } from 'carbon-js-sdk'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import dayjs from 'dayjs'
import Papa from 'papaparse'

import { DenomPrefix } from 'js/constants/assets'
import { MarketType, isFutures, isPerpetual } from 'js/models/Market'
import { Market } from 'js/state/modules/exchange/types'

import { customToast } from './notifications'
import { BN_ZERO } from './number'
import { MarketOverride, SimpleMap } from './types'

export type CsvHeaderData = Array<string>
export type CsvRowData = Array<Array<any>>

export const charactersStr = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

export const uuidv4 = () => { // eslint-disable-line import/prefer-default-export
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0 // eslint-disable-line no-bitwise
    const v = c === 'x' ? r : ((r & 0x3) | 0x8) // eslint-disable-line no-bitwise
    return v.toString(16)
  })
}

export const generateRandomString = (length: number = 0) => {
  let finalStr = ''
  for (let ii = 0; ii < length; ii++) {
    const index = Math.floor(Math.random() * (charactersStr.length + 1))
    if (charactersStr[index]) {
      finalStr = `${finalStr}${charactersStr[index]}`
    }
  }
  return finalStr
}

/**
 * Generates a memo string based on the given SDK object, and whether or not the transaction is an IBC withdrawal.
 *
 * @param sdk The SDK object to use in the memo.
 * @param isIBCWithdrawal Whether or not the transaction is an IBC withdrawal.
 * @returns A generated memo string.
 *
 * @example
 * const memo = generateMemo(sdk, true)
 * console.log(memo); // "Demex_Date-2023-04-26T12-34-56_l"
 */
export const generateMemo = (sdk: CarbonSDK, isIBCWithdrawal: boolean) => {
  const now = new Date()
  const formattedDateTime = now.toISOString().split('.')[0]

  const memoParts = [
    'Demex',
    `Date-${formattedDateTime}`,
  ]

  if (isIBCWithdrawal) memoParts.push(sdk?.wallet?.isLedgerSigner() ? 'l' : 'nl')

  return memoParts.join('_')
}

/**
 * get double-digit integer (for integers only)
 * @param number integer in number format
 */
export const forceDoubleInteger = (number: number) => {
  const newNumber = number ?? 0
  return `00${newNumber}`.substr(-2)
}

export function fallbackCopyTextToClipboard(text: string, successMessage: string, errorMessage: string) {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    if (successful) {
      customToast.info({ title: successMessage })
    } else {
      customToast.error({ title: 'Error', message: errorMessage })
    }
  } catch (err) {
    customToast.error({ title: 'Error', message: errorMessage })
  }

  document.body.removeChild(textArea)
}

export function copyTextToClipboard(
  text: string,
  successMessage: string = 'Address copied',
  errorMessage: string = 'Error copying address. Please do it manually.',
  enableToastNotif: boolean = true,
  subText: string = '',
) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, successMessage, errorMessage)
    return
  }
  navigator.clipboard.writeText(text).then(() => {
    if (enableToastNotif) {
      customToast.info({ title: successMessage, message: subText })
    }
  }, (err) => {
    fallbackCopyTextToClipboard(text, successMessage, errorMessage)
  })
}

export function exportCsvFile(data: CsvRowData, headers?: CsvHeaderData, filename?: string) {
  const csvFormat = 'data:text/csv;charset=utf-8,'

  let csv
  if (headers) {
    csv = Papa.unparse({
      fields: headers,
      data,
    })
  } else {
    csv = Papa.unparse(data)
  }

  csv = `${csvFormat}${csv}`

  const encodedUri = encodeURI(csv)
  const link = document.createElement('a')

  const fileNameExt = filename ? `${filename}.csv` : 'file.csv'

  link.style.top = '0'
  link.style.left = '0'
  link.style.position = 'fixed'
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', fileNameExt)

  document.body.appendChild(link)

  try {
    link.click()
    customToast.success({ title: 'Success', message: 'CSV successfully exported' })
  } catch (err) {
    customToast.error({ title: 'Error', message: 'There seems to be some error when exporting the CSV file. Please check the console for more information.' })
  }

  document.body.removeChild(link)
}

export function shortenStr(str: string = '', numChar: number = 15) {
  if (str.length > 0) {
    if (str.length > numChar) {
      return `${str.substring(0, numChar + 1)}...`
    }
    return str
  }
  return ''
}

export function truncateStr(str: string = '', frontNum: number = 8, backNum: number = 8, ellipsis: string = '..') {
  // Check if numbers are negative or zero
  // If negative, get absolute value. If zero, assign default value.
  const frontLimit = frontNum === 0 ? 8 : Math.abs(frontNum)
  const backLimit = backNum === 0 ? 8 : Math.abs(backNum)

  if (str.length > 0) {
    if (str.length > frontLimit + backLimit) {
      return `${str.substr(0, frontLimit)}...${str.substr(-backLimit)}`
    }
    return str
  }
  return ''
}

export function checkStrLength(str: string = '', numChar: number = 10) {
  if (str.length > numChar) {
    return [`${str.substr(0, numChar - 3)}...`, true]
  }
  return [str, false]
}

export function capitalize(str: string = '') {
  if (str.length > 0) {
    if (str.length === 1) {
      return str.toUpperCase()
    }
    return `${str[0].toUpperCase()}${str.substr(-str.length + 1).toLowerCase()}`
  }
  return ''
}

export function capitalizeOrderType(str: string = '') {
  return str
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-')
}

export function titleCase(str: string = '') {
  if (str.length > 0) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
  return ''
}

export function checkNetCaps(str: string, network: CarbonSDK.Network | undefined) {
  if (network && network !== CarbonSDK.Network.MainNet) {
    return str.toUpperCase()
  }
  return str
}

export function getSWTHLabel(
  denom: string = '',
  tokenCli: CarbonSDK.TokenClient | undefined,
  network: CarbonSDK.Network | undefined,
  blockchain?: BlockchainUtils.BlockchainV2,
): string {
  const isSWTH = (denom === 'swth')
  if (isSWTH && blockchain !== undefined && tokenCli !== undefined) {
    const tokenAddr = tokenCli.getDepositTokenFor(denom, blockchain, 'V2')?.tokenAddress
    if (tokenAddr && tokenAddr === 'c0ecb8499d8da2771abcbf4091db7f65158f1468') { return 'SWTH-V2' }
    if (tokenAddr && tokenAddr === '250b211ee44459dad5cd3bca803dd6a7ecb5d46c') { return 'SWTH-V1' }
  }
  return getTokenName(denom, tokenCli, network)
}

export function getTokenName(
  denom: string = '',
  tokenCli?: CarbonSDK.TokenClient,
  network?: CarbonSDK.Network,
  symbolOverride?: TypeUtils.SimpleMap<string>,
): string {
  if (!tokenCli || !network) return denom
  return tokenCli.getTokenName(denom, symbolOverride)
}

export function getTransferTokenName(
  denom: string = '',
  tokenCli: CarbonSDK.TokenClient | undefined,
  network: CarbonSDK.Network | undefined,
  symbolOverride: TypeUtils.SimpleMap<string> | undefined = undefined,
): string {
  if (!tokenCli || !network) return denom
  const sourceToken = tokenCli.getSourceToken(denom)
  const tokenDenom = sourceToken?.denom ?? denom
  return getTokenName(tokenDenom, tokenCli, network, symbolOverride)
}

export function getContractSymbol(
  market: Market | null | undefined,
  tokenCli: CarbonSDK.TokenClient | undefined,
  network: CarbonSDK.Network | undefined,
  symbolOverride: TypeUtils.SimpleMap<string> | undefined = undefined,
): string {
  if (!market) return ''
  if (isFutures(market?.marketType)) {
    // if required, quote token is index number 5 from symbolArr (view getFuturesBaseSymbol)
    return 'USD'
  }
  return getTokenName(market.quote, tokenCli, network, symbolOverride)
}

export const futuresMarketRegex = /^([a-z]+)_(([\d]{2}[a-z]{3}[\d]{2})|PERP)(\.([a-z]+))?/i

export function getFuturesBaseSymbol(
  market: Carbon.Market.Market | null | undefined,
  tokenCli: CarbonSDK.TokenClient | undefined,
  network: CarbonSDK.Network | undefined,
  marketOverride: MarketOverride | undefined,
  symbolOverride: TypeUtils.SimpleMap<string> | undefined = undefined,
): string {
  let base = ''
  if (!market || !tokenCli) return base
  if (marketOverride && marketOverride?.base?.symbol) {
    return marketOverride?.base?.symbol
  }
  if (isFutures(market?.marketType)) {
    const symbolArr = market.displayName.match(futuresMarketRegex)
    if (symbolArr && symbolArr[1]) {
      return symbolOverride?.[symbolArr[1]] ?? symbolArr[1] ?? ''
    }
  }
  return getTokenName(market.base, tokenCli, network, symbolOverride)
}

export function formatWithSymbol(str: any, symbol: string) {
  return str.concat(' ', symbol)
}

const lowerCaseAlpha = 'abcdefghijklmnopqrstuvwxyz'
export function cameltoNormal(camel: string): string {
  let normalStr = ''
  for (let ii = 0; ii < camel.length; ii++) {
    if (ii === 0) {
      normalStr = `${normalStr}${capitalize(camel[ii])}`
      continue
    }
    if (lowerCaseAlpha.includes(camel[ii])) {
      normalStr = `${normalStr}${camel[ii]}`
      continue
    }
    normalStr = `${normalStr} ${capitalize(camel[ii])}`
  }
  return normalStr
}

export function getCibtDenom(denom: string) {
  return `${DenomPrefix.Cdp}/${denom}`
}

export function getUnderlyingDenom(denom: string, tokenClient: CarbonSDK.TokenClient | undefined): string {
  if (!tokenClient?.cdpTokens[denom]) return ''
  return tokenClient?.getCdpUnderlyingToken(denom)?.denom ?? ''
}

export function getCdpAccountDebtsKey(denom: string, type: string) {
  return `${denom}-${type}`
}
export function matchStart(target: string, searchTerm: string = '') {
  const lowerSearch = searchTerm.replace(/[\s-/]/g, '').toLowerCase()
  const lowerTarget = target.replace(/[\s-/]/g, '').toLowerCase()
  return lowerTarget.startsWith(lowerSearch)
}

export function matchSearch(target: string, searchTerm: string = '') {
  const lowerSearch = searchTerm.replace(/[\s-/]/g, '').toLowerCase()
  const lowerTarget = target.replace(/[\s-/]/g, '').toLowerCase()
  return lowerTarget.includes(lowerSearch)
}

export function fuzzySearch(query: string, target: string) {
  query = query.replace(/[\s-/]/g, '').toLowerCase()
  target = target.replace(/[\s-/]/g, '').toLowerCase()

  if (query.length > target.length) {
    return query.includes(target)
  }

  let matches = 0

  for (let i = 0; i < target.length; i++) {
    if (query.charCodeAt(matches) === target.charCodeAt(i)) {
      matches++
      if (matches === query.length) {
        break
      }
    }
  }

  return matches === query.length
}

export const getEvmChainName = (blockchain?: BlockchainUtils.BlockchainV2): string | undefined => {
  if (!blockchain) return undefined
  const isEvmChain = BlockchainUtils.isEvmChain(blockchain)
  if (isEvmChain) {
    switch (blockchain) {
      case 'Binance Smart Chain':
        return 'BNB Smart Chain (BSC)'
      case 'OKC':
        return 'OKTC'
      default:
        return blockchain
    }
  }
  return undefined
}

export const getMarketDisplayName = (
  market: Carbon.Market.Market | undefined | null,
  sdk: CarbonSDK | undefined,
  net: CarbonSDK.Network,
  isUrl: boolean,
  marketOverride: MarketOverride | undefined,
): string => {
  if (!market) {
    return ''
  }
  const displayName = market.displayName
  const symbolOverride = isFutures(market.marketType)
    ? TokenUtils.FuturesDenomOverride
    : undefined
  const baseSymbol = getFuturesBaseSymbol(market, sdk?.token, net, marketOverride, symbolOverride)
  switch (market.marketType) {
    case MarketType.Spot: {
      const [base, quote] = displayName.split('_')
      return (isUrl ? `${base}/${quote}` : `${base} / ${quote}`)
    }
    case MarketType.Futures: {
      if (isPerpetual(market.expiryTime)) {
        // 1000PEPE_PERP.USD --> 1000PEPE_PERP --> 1000PEPE-PERP
        const name = displayName.split('.')[0].replace(/_/g, '-')
        return name
      }
      return (isUrl ? `${baseSymbol}-${dayjs(market.expiryTime).format('YYYYMMDD')}` : `${baseSymbol} - ${dayjs(market.expiryTime).format('DD MMM YYYY')}`)
    }
    default:
      return market.displayName
  }
}

export const reverseStr = (str: string, charNum: number = 1) => {
  if (!str) return ''
  let oldStr = str
  let newStr = ''
  while (oldStr.length > 0) {
    newStr = `${newStr}${oldStr.substr(-charNum)}`
    oldStr = oldStr.substring(0, oldStr.length - charNum)
  }
  return newStr
}

export interface SmallNumObj {
  integerPart: string
  zeroCount: number
  decimalPart: string
}

export type SmallNumResult = SmallNumObj | string

export const smallNumberFormatter = (bnValue: BigNumber) => {
  return formatSmallNumber(bnValue, 0, 5, 5, true)
}

export const formatSmallNumber = (
  availableBN: BigNumber,
  shiftDecimals: number = 0,
  format: number = 8,
  decimalsPlaces: number = 8,
  largeNumberDefaultTwoDP: boolean = false,
  limit: number = 0.001,
): SmallNumResult => {
  let availableBNShifted = availableBN.shiftedBy(-shiftDecimals)
  if (availableBNShifted.isNaN()) {
    availableBNShifted = BN_ZERO
  }

  // if value >= 0.001 OR value === 0, return original value
  if (availableBNShifted.abs().isGreaterThanOrEqualTo(limit) || availableBNShifted.isEqualTo(0)) {
    if (largeNumberDefaultTwoDP) {
      const hasTwoDecimals = availableBNShifted.abs().gt(0.1) || availableBNShifted.eq(0)
      return availableBNShifted.toFormat(hasTwoDecimals ? 2 : format)
    }
    return availableBNShifted.toFormat(format, BigNumber.ROUND_DOWN)
  }

  const integerPart = availableBNShifted.integerValue(BigNumber.ROUND_DOWN)
  const decimalPart = availableBNShifted.minus(integerPart)
  const decimalPartStr = decimalPart.absoluteValue().toString(10).substring(2)
  if (decimalPartStr) {
    let zeroCount = 0
    for (let ii = 0; ii < decimalPartStr.length; ii++) {
      if (decimalPartStr[ii] !== '0') break
      zeroCount++
    }

    if (zeroCount > 2) {
      return (
        {
          integerPart: integerPart.eq(0) ? `${availableBNShifted.gt(0) ? '' : '-'}${integerPart.toString(10)}` : integerPart.toString(10),
          zeroCount,
          decimalPart: decimalPartStr.slice(zeroCount).slice(0, decimalsPlaces - 2),
        }
      )
    }
  }

  return availableBNShifted.toFormat(format)
}

export const formatPriceDisplay = (priceStr: string, symbol: string | undefined, isFuturesMarket: boolean) => {
  if (isFuturesMarket) return `${priceStr} USD`
  if (!symbol) return priceStr
  return !symbol ? priceStr : `${priceStr} ${symbol}`
}

export const cleanNumericalInputString = (inputString: string) => {
  return bnOrZero(inputString).toString()
}

export function stringArraysAreEqual(array1: string[], array2: string[]) {
  return JSON.stringify(array1) === JSON.stringify(array2)
}

export interface SwapFees {
  amount: string | SmallNumResult // Fees property
  denom: string
  symbol?: string
  amountUsd?: string | SmallNumResult
}

export interface TradingFees {
  makerFee?: SwapFees
  takerFee?: SwapFees
}

export const replacePlaceholder = (message: string, placeholders: SimpleMap<string>): string => {
  return message.replace(/{(\w+)}/g, (_, key) => placeholders[key] ?? `{${key}}`)
}