import { capitalize } from './strings'

export interface ErrorMap {
  [index: string]: string
}

export const errorRegex = /\[(\d+)\] ([a-z\d\s]+); message index: (\d+): /i
export const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const cleanBackendErr = (error: string | null) => {
  if (error && error.match(errorRegex)) {
    return capitalize(error.replace(errorRegex, ''))
  }
  return error ?? 'Transaction Error'
}
