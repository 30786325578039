import BigNumber from 'bignumber.js'
import { CarbonSDK } from 'carbon-js-sdk'

export const POOL_DECIMALS = 18

export const DefaultPoolTokenDecimals = 18

// Constant amount of liquidity (to estimate APR)
export const constantLP = new BigNumber(1000)

// Cut-off amount of liquidity for small pools
export const smallLiquidityPools = new BigNumber(50000)

export const minAmpBps = new BigNumber(10000)

export const fallbackBlacklistedPools: { [key in CarbonSDK.Network]: number[] } = {
  [CarbonSDK.Network.MainNet]: [
    34, // zil-busd (invalid)
    43, // swth-ustc (deprecated terra classic)
    44, // lunc-swth (deprecated terra classic)
    74, // usdc-usc (invalid)
    83, // axlusdc-usdc (invalid)
  ],
  [CarbonSDK.Network.TestNet]: [],
  [CarbonSDK.Network.DevNet]: [],
  [CarbonSDK.Network.LocalHost]: [],
}

export interface RewardCoin {
  denom: string
  amount: BigNumber
  usdValue: BigNumber
}

export interface InsightsCoin {
  denom: string
  amount: Number
  amountValue: Number
}
