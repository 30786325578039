import { RecordOf } from "immutable"

export interface ExternalWsStateProps {
  readonly wsUrl: string | null
}

export type ExternalWsState = RecordOf<ExternalWsStateProps>

export const WebSocketActionTypes = {
  WS_URL_CHANGE: '@externalWebSocket/WS_URL_CHANGE',
}
