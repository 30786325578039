import BigNumber from 'bignumber.js'
import { BN_ZERO } from 'carbon-js-sdk/lib/util/number'
import { useSelector } from 'react-redux'

import { getReservedTokensPreference } from 'js/state/modules/account/selectors'
import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { getAdjustedBalances } from 'js/state/modules/walletBalance/selectors'
import { getNetworkFeeBN } from 'js/utils'
import { getMaxAmountAdjustedForNetworkFee } from 'js/utils/fee'
import { getAvailTokenDenom } from 'js/utils/networkFee'

const useGetBalanceInputDetails = (txTypes: string[], inputDenom: string) => {
  const sdk = useSelector(getCarbonSDK)
  const balances = useSelector(getAdjustedBalances)
  const { tokenPrefDenoms = [], reservedTokens } = useSelector(getReservedTokensPreference) ?? {}
  const feeDenom = getAvailTokenDenom(balances, tokenPrefDenoms, sdk, txTypes)
  const initialAvailBalance = balances?.[inputDenom]?.available ?? BN_ZERO
  const networkFee = txTypes.reduce((prev: BigNumber, txType: string) => {
    prev = prev.plus(getNetworkFeeBN(feeDenom, sdk, txType))
    return prev
  }, BN_ZERO)
  const adjustedMaxAmount = getMaxAmountAdjustedForNetworkFee(
    { amount: initialAvailBalance, denom: inputDenom },
    { amount: networkFee, denom: feeDenom },
    reservedTokens
  ).amount
  return {
    adjustedMaxAmount,
    networkFee: { denom: feeDenom, amount: networkFee }
  }
}
export default useGetBalanceInputDetails 
