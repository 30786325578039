const typography = {
  fontFamily: ['Roboto', 'Arial'].join(','),
  h1: {
    // styleName: Heading/48px;
    fontFamily: 'Montserrat',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '58px',
    letterSpacing: 'normal',
  },
  h2: {
    // styleName: Heading/32px;
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '38px',
    letterSpacing: 'normal',
  },
  h3: {
    // styleName: Heading/24px;
    fontFamily: 'Eina01',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: 'normal',
  },
  h4: {
    // styleName: Heading/20px;
    fontFamily: 'Eina01',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: 'normal',
  },
  h5: {
    // styleName: Heading/18px;
    fontFamily: 'Eina01',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: 'normal',
  },
  h6: {
    // styleName: Heading/16px;
    fontFamily: 'Eina01',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: 'normal',
  },
  body1: {
    // styleName: Extra Large 18px/Body;
    fontFamily: 'Eina01',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: 'normal',
  },
  body2: {
    // styleName: Large 16px/Body;
    fontFamily: 'Eina01',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: 'normal',
  },
  body3: {
    // styleName: Regular 13px/Title;
    fontFamily: 'Eina01',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: 'normal',
  },
  body4: {
    // styleName: Small 11px/Body;
    fontFamily: 'Eina01',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '12px',
    letterSpacing: 'normal',
  },
  body5: {
    // styleName: Extra Small 9px/Body;
    fontFamily: 'Eina01',
    fontSize: '9px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '12px',
    letterSpacing: 'normal',
  },
  number1: {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    letterSpacing: '-0.0125rem',
    fontWeight: 600,
  },
  number2: {
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    letterSpacing: '-0.0125rem',
    fontWeight: 600,
  },
  number3: {
    fontFamily: 'Roboto',
    fontSize: '0.625rem',
    lineHeight: '0.875rem',
    letterSpacing: '0rem',
    fontWeight: 600,
  },
}

export default typography
