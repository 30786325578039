import {
  createStyles,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { dismissAllActiveToasts } from 'js/state/modules/loadingTask/actions'
import { NotificationProps } from 'js/utils/notifications'

import GradientText from '../Common/GradientText'

const DismissNotification: React.FC<NotificationProps> = (props: NotificationProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  
  useEffect(() => {
    toast.onChange((toastsCount) => {
      if(!props.dismissToastId) return

      if (toast.isActive(props.dismissToastId) && toastsCount <= 2) {
        toast.dismiss(props.dismissToastId)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dismissToastId])
  
  const handleClick = () => {
    dispatch(dismissAllActiveToasts())
  }

  return (
    <div className={clsx(classes.root)} onClick={handleClick}>
      <GradientText className={classes.label}>Close All</GradientText>
    </div>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    background: theme.palette.background.secondary,
    flex: 1,
    transition: 'all 500ms ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 0),
  },
  label: {
    ...theme.typography.body3,
  }
}))

export default DismissNotification
