import { SimpleMap } from 'carbon-js-sdk/lib/util/type'

import { HttpMethod } from 'js/constants/api'

import { sendApiRequest } from './api'

interface Response {
  hash: string
}

export const DEMEX_SHARE_API_DOMAIN = 'https://app.dem.exchange/share'

export const downloadImage = (fileUrl: string, fileName = 'downloaded-image.png') => {
  const link = document.createElement('a')
  link.href = fileUrl
  link.download = fileName

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const createDownloadableImage = async (params: SimpleMap<string>): Promise<Response> => {
  const url = new URL(`${DEMEX_SHARE_API_DOMAIN}/generate`)
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      url.searchParams.append(key, value)
    }
  })

  const response = await sendApiRequest(HttpMethod.GET, url.href)
  return response
}

export const getImageUrl = (hash: string) => `${DEMEX_SHARE_API_DOMAIN}/images/${hash}/image.png`


export const createBlobImage = async (hash: string) => {
  const url = getImageUrl(hash)
  const image = await fetch(url, {
    method: HttpMethod.GET,
  })
  return await image.blob()
}


export const shareImage = async (hash: string, fileName: string = 'shared-image', fileType: string = 'png') => {
  const blob = await createBlobImage(hash)
  const file = new File([blob], `${fileName}.${fileType}`, { type: `image/${fileType}` })
  if (navigator.canShare && navigator.canShare({ files: [file] })) {
    return await navigator.share({ files: [file] })
  }
  throw new Error('Web Share API is not supported or cannot share this file.')

}