import { bnOrZero } from 'carbon-js-sdk/lib/util/number'

import { getCdpAsset, getEModeCategory } from 'js/state/modules/cdp/selectors'
import { UnlockRatios } from 'js/utils'

import useSelect from './useSelect'

const useGetUnlockRatios = (denom: string): UnlockRatios => {
  const eModeCategory = useSelect(getEModeCategory)
  const asset = useSelect(getCdpAsset(denom))

  const isEmodeDenom = eModeCategory && !!eModeCategory?.denoms.includes(denom)
  if (isEmodeDenom) return { loanToValue: bnOrZero(eModeCategory.loanToValue), liquidationThreshold: bnOrZero(eModeCategory.liquidationThreshold) }
  return { loanToValue: bnOrZero(asset?.assetInfo?.loanToValue), liquidationThreshold: bnOrZero(asset?.assetInfo?.liquidationThreshold) }
}

export default useGetUnlockRatios
