import BigNumber from 'bignumber.js'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'

export function parseNumber(number: string | null | number = '0', defaultValue?: BigNumber): BigNumber | null {
  const bnNumber = new BigNumber(number ?? '0')
  if (!bnNumber.isFinite()) {
    return defaultValue || null
  }
  return bnNumber
}

export const numberRegex = /^(\d+)$/

export const BN_ZERO = new BigNumber(0)
export const BN_ONE = new BigNumber(1)
export const BN_YEAR = new BigNumber(365)

export const BN_HUNDRED = new BigNumber(100)
export const BN_THOUSAND = new BigNumber(1000)
export const BN_TENTHOUSAND = new BigNumber(10000)
export const BN_MILLION = new BigNumber(1000000)
export const BN_BILLION = new BigNumber(1000000000)
export const BN_INFINITY = new BigNumber(Infinity)

export function multipleOf(number: string | number, n: BigNumber, roundingMode?: BigNumber.RoundingMode): BigNumber {
  const BN = BigNumber.clone({
    DECIMAL_PLACES: 0,
    ROUNDING_MODE: roundingMode,
  })
  const bigNumber = new BN(number)
  const result = n.isZero() ? bigNumber : bigNumber.div(n).multipliedBy(n)
  return new BigNumber(result)
}

export function adjustGweiToValue(value: string, precision: number): string {
  const valBN = new BigNumber(value)
  return valBN.shiftedBy(-precision).decimalPlaces(precision).toString(10)
}

export function adjustValueToGwei(value: string, precision: number): string {
  const valBN = new BigNumber(value)
  return valBN.shiftedBy(precision).toFixed(0, BigNumber.ROUND_DOWN)
}

export function humanFormat(number: any, dp: number = 3) {
  return new BigNumber(number).toFormat(dp)
}

export function toPercentage(number: any, dp: number = 3) {
  if (!number) {
    return BN_ZERO.toFormat(dp)
  }
  let finalBN = BN_ZERO
  if (!BigNumber.isBigNumber(number)) {
    finalBN = parseNumber(number, BN_ZERO)!
  } else {
    finalBN = number
  }
  if (!finalBN.isFinite() || finalBN.isZero()) {
    return BN_ZERO.toFormat(dp)
  }
  return finalBN.times(100).toFormat(dp)
}

export function toShorterNum(number: string | number | BigNumber, includeDollarSign: boolean = false, roundingMode?: RoundingMode, lowestExp: number = -4, toFormat: number = 5, hasThousandShorten: boolean = false): string {
  let numberBN = bnOrZero(number)
  const dollarPortion = includeDollarSign ? '$' : ''
  if (numberBN.gt(new BigNumber(1e30))) {
    return `>${dollarPortion}1,000,000,000,000,000,000,000b`
  }
  if (numberBN.gte(BN_BILLION)) {
    return `${dollarPortion}${numberBN.dividedBy(BN_BILLION).decimalPlaces(2, roundingMode).toFormat()}b`
  }
  if (numberBN.gte(BN_MILLION) && numberBN.lt(BN_BILLION)) {
    return `${dollarPortion}${numberBN.dividedBy(BN_MILLION).decimalPlaces(2, roundingMode).toFormat()}m`
  }
  if (hasThousandShorten && numberBN.gte(BN_THOUSAND) && numberBN.lt(BN_MILLION)) {
    return `${dollarPortion}${numberBN.dividedBy(BN_THOUSAND).decimalPlaces(2, roundingMode).toFormat()}k`
  }
  if (numberBN.gte(BN_TENTHOUSAND) && numberBN.lt(BN_MILLION)) {
    return `${dollarPortion}${numberBN.toFormat(toFormat > 2 ? 0 : toFormat, roundingMode)}`
  }

  return formatUsdPrice(numberBN, includeDollarSign, numberBN.isNegative(), roundingMode, lowestExp, toFormat)
}

export type RoundingMode = typeof BigNumber.ROUND_CEIL | typeof BigNumber.ROUND_FLOOR

export function formatUsdPrice(number: string | number | BigNumber, includeDollarSign: boolean = true, isNegative?: boolean, roundingMode?: RoundingMode, lowestExp: number = -4, toFormat: number = 5) {
  let newNum = BN_ZERO
  if (typeof number === 'string' || typeof number === 'number') {
    newNum = parseNumber(number, BN_ZERO)!
  } else {
    newNum = number
  }
  if (newNum.isNaN() || !newNum.isFinite()) {
    return '-'
  }

  const dollarPortion = ((isNegative ?? newNum.isNegative()) ? '-' : '') + (includeDollarSign ? '$' : '')
  newNum = newNum.abs()

  if (newNum.isZero()) {
    return `${dollarPortion}0.00`
  }
  const lowestValue = new BigNumber(10).exponentiatedBy(lowestExp)
  if (newNum.gt(0) && newNum.lt(lowestValue)) {
    return `< ${dollarPortion}${lowestValue.toString(10)}`
  }
  if (newNum.lt(0.1)) {
    return `${dollarPortion}${newNum.toFormat(toFormat, roundingMode)}`
  }
  return `${dollarPortion}${newNum.toFormat(2, roundingMode)}`
}

export function sortBNArr(bns: BigNumber[]) {
  return bns.sort((a: BigNumber, b: BigNumber) => a.minus(b).toNumber())
}
