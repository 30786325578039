import { Theme } from '@material-ui/core'

import { FlavorableOverrides } from './types'

const MuiCircularProgress: FlavorableOverrides = (theme: Theme) => ({
  circle: {
    strokeLinecap: 'round',
  },
  colorPrimary: {
    '& .MuiCircularProgress-svg circle': {
      stroke: 'url(#demexLinearGradient)',
    },
  },
})

export default MuiCircularProgress
