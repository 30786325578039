import { makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSidebar from "js/components/Common/Sidebar/LoadingSidebar"
import { useDimensions } from "js/hooks"
import { useCommonStyles } from "js/utils"

import CDPSwitch from "./CDPSwitch"

const CDPFallback: React.FC = () => {
  const classes = useStyles()
  const dimensions = useDimensions()
  const commonClasses = useCommonStyles()

  return (
    <div className={clsx(commonClasses.flexRow, classes.container)} style={{ minHeight: `${dimensions.otherPagesHeight.desktop}px` }}>
      <LoadingSidebar />
      <CDPSwitch />
    </div>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}))

export default CDPFallback
