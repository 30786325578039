import { Theme } from '@material-ui/core'

import { FlavorableOverrides } from './types'

const MuiRadio: FlavorableOverrides = (theme: Theme) => ({
  root: {
    padding: theme.spacing(0.25),
    margin: theme.spacing(0, 0.75),
  },
  colorSecondary: {
    '&:hover': {
      backgroundColor: theme.palette.background.active,
    },
    '&.Mui-checked': {
      '&:hover': {
        backgroundColor: `${theme.palette.background.active} !important`,
      },
    },
  },
})

export default MuiRadio
