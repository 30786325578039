import { makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSidebar from "js/components/Common/Sidebar/LoadingSidebar"
import useDimensions from "js/hooks/useDimensions"
import { useCommonStyles } from "js/utils/styles"

import PoolsSwitch from "./PoolsSwitch"

const PoolsFallback: React.FC = () => {
  const classes = useStyles()
  const dimensions = useDimensions()
  const commonClasses = useCommonStyles()

  return (
    <div className={clsx(commonClasses.flexRow, classes.container)} style={{ minHeight: `${dimensions.otherPagesHeight.desktop}px` }}>
      <LoadingSidebar />
      <div className={classes.poolsMain}>
        <PoolsSwitch />
      </div>
    </div>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  poolsMain: {
    backgroundColor: theme.palette.background.base,
    minHeight: 'calc(100vh - 48px - 28px)', // top bar and bottom bar
    maxWidth: 'calc(100% - 17rem)',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
}))

export default PoolsFallback
