import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import React from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import { useDispatch, useSelector } from 'react-redux'
import 'react-resizable/css/styles.css'

import LoadingIcon from 'js/components/Common/LoadingIcon'
import LoadingSkeleton from 'js/components/Common/LoadingSkeleton/LoadingSkeleton'
import { EXCHANGE_SECTIONS } from 'js/constants/sectionTabs'
import useDimensions, { gridRowHeightMobile } from 'js/hooks/useDimensions'
import { setLayout, setLayouts } from 'js/state/modules/exchange/actions'
import { getHideLayouts, getLayout, getLayouts } from 'js/state/modules/exchange/selectors'
import { getOpenRefPopUp } from 'js/state/modules/referrals/selectors'
import { useCommonStyles } from 'js/utils'
import baseLayouts from 'js/utils/layouts'

const useStyles = makeStyles((theme) => ({
  root: {
    '& *::-webkit-scrollbar': {
      // the actual width is this value minus twice of border width
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
    },
    '& *::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
      borderRadius: '10px',
    },
    '& *::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px',
    },
    '& *::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: theme.palette.background.tertiary,
      border: '3px solid',
      borderColor: 'transparent',
      // the actual border radius should be this value minus twice of border width
      borderRadius: theme.spacing(2.5),
    },
    '& *': {
      scrollbarColor: 'transparent',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    border: 'none',
    borderRadius: '4px',
    '&.mobileOrderBook': {
      flexDirection: 'row',
      gap: '16px',
    }
  },
  alertRoot: {
    backgroundColor: theme.palette.background.base,
  },
  alert: {
    backgroundColor: theme.palette.background.base,
    paddingBottom: '0.5rem',
    [theme.breakpoints.only('xs')]: {
      paddingTop: '0.5rem',
      paddingBottom: 0,
    },
  },
  chart: {
    display: 'flex',
    flexDirection: 'column',
  },
  marketsBar: {
    backgroundColor: theme.palette.background.primary,
    padding: theme.spacing(1, 2),
    minHeight: 'max-content',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '16px',
    [theme.breakpoints.down('xs')]: {
      height: '48px',
    }
  },
  gridBox: {
    backgroundColor: theme.palette.background.primary,
    height: '100%',
    padding: theme.spacing(2.5),
  },
  orderHistory: {
    overflowY: 'unset',
  },
  marginDisplay: {
    minWidth: '280px',
    minHeight: '270px',
  },
  balances: {
    minHeight: '270px',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '3fr 3fr 2fr 2fr',
    gridTemplateRows: `${theme.spacing(13.5)}px 3fr 2fr 2fr`,
    gridTemplateAreas: `
      "chart      chart   orderBook    orderManager"
      "chart           chart        orderBook    orderManager"
      "contractDetails recentTrades orderBook"
      "orderHistory    orderHistory orderHistory orderHistory"
    `,
    width: '100%',
  },
  layout: {
    flexGrow: 1,
    margin: theme.spacing(0.5),
    background: theme.palette.background.base,
    borderRadius: '4px',
    maxWidth: '100%',
    '& .react-grid-item.cssTransforms': {
      transitionProperty: 'none',
    },
    '& .animated.react-grid-item.cssTransforms': {
      transitionProperty: 'transform',
    },
    '& .react-grid-item > .react-resizable-handle::after': {
      position: 'absolute',
      right: '3px',
      bottom: '3px',
      width: '8px',
      height: '8px',
      borderRight: `solid 1px ${theme.palette.text.secondary}`,
      borderBottom: `solid 1px ${theme.palette.text.secondary}`,
      borderBottomRightRadius: '5px',
    },
    '& .react-grid-item:last-child > .react-resizable-handle': {
      display: 'none'
    },
    '& .react-grid-item.react-grid-placeholder': {
      background: theme.palette.background.primary,
    },
  },
  minChartHeight: {
    minHeight: '27.5rem',
  },
  mobileAccordionDiv: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.only('xs')]: {
      '&.chart': {
        margin: theme.spacing(0, 0, 1),
      },
    },
  },
  sticky: {
    backgroundColor: theme.palette.background.base,
    position: 'sticky',
    top: '3rem',
    zIndex: 100,
    margin: theme.spacing(0.5, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
      top: '5.875rem',
    },
    '&.openRefPopUp': {
      zIndex: 1,
    },
  },
  closeIcon: {
    height: '1.125rem',
    width: '1.125rem',
    position: 'absolute',
    right: theme.spacing(1.5),
    paddingTop: theme.spacing(0.8),
    zIndex: 1,
  },
  sectionTabsFallback: {
    zIndex: 111,
    position: 'sticky',
    top: '2.9685rem',
    width: '100%',
    height: '48px',
    backgroundColor: theme.palette.background.secondary,
  },
}))

const ResponsiveGridLayout = WidthProvider(Responsive)

const TradeMainFallback: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const dimensions = useDimensions()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery('@media (max-width:968px)') && !isMobile
  const isMdUp = useMediaQuery('@media (min-width:1289px)')
  const isLgUp = useMediaQuery('@media (min-width:1546px)')
  const isXlUp = useMediaQuery('@media (min-width:1928.95px)')

  const layout = useSelector(getLayout)
  const layouts = useSelector(getLayouts)
  const hideLayouts = useSelector(getHideLayouts)
  const openRefPopUp = useSelector(getOpenRefPopUp)

  const MAX_HEIGHT_MARKETS_BAR = 60

  const orderBookRef = React.useRef<HTMLDivElement>(null)
  const recentTradesRef = React.createRef<any>()

  const rowHeight = React.useMemo((): number => {
    const tradeRootHeight = new BigNumber(dimensions.tradeUIRoot.height)
    const numRows = isMdUp && !isXlUp ? 28 : 20
    const rawHeight = tradeRootHeight.div(numRows)
    const height = BigNumber.max(rawHeight, 18)
    return height.toNumber()
  }, [dimensions, isMdUp, isXlUp])

  const onLayoutChange = (newLayout: any, newLayouts: any) => {
    dispatch(setLayout(newLayout))
    dispatch(setLayouts(newLayouts))
  }

  const items: any = {
    chart: (
      <div className={clsx(classes.chart, classes.box, classes.gridBox)}>
        <div className={clsx(commonClasses.alignItemsCenter, commonClasses.justifyContentCenter, commonClasses.fullHeight)}>
          <LoadingIcon size="2.5rem" />
        </div>
      </div>
    ),
    orderBook: (
      <div className={clsx(classes.gridBox, classes.box)} ref={orderBookRef}>
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton />
      </div>
    ),
    contractDetails: (
      <div className={clsx(classes.gridBox, classes.box)}>
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton />
      </div>
    ),
    recentTrades: (
      <div className={clsx(classes.gridBox, classes.box)} ref={recentTradesRef}>
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton />
      </div>
    ),
    orderHistory: (
      <div className={clsx(classes.gridBox, classes.box)}>
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton />
      </div>
    ),
    marginDisplay: (
      <React.Fragment />
    ),
    orderManager: (
      <div className={clsx(classes.gridBox, classes.box)}>
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton />
      </div>
    ),
  }
  if (!isTablet && !isMobile) {
    items['marketsBar'] = (
      <div className={classes.marketsBar}>
        <LoadingSkeleton width={200} containerWidth={200} />
        <LoadingSkeleton width={100} containerWidth={100} />
        <LoadingSkeleton width={100} containerWidth={100} />
        <LoadingSkeleton width={100} containerWidth={100} />
        <LoadingSkeleton width={100} containerWidth={100} />
      </div>
    )
  }

  layout.forEach((thisLayoutItem: any) => {
    const thisProps: any = {}

    // find all layouts touching on the x-axis
    let thatLayoutItems = layout.filter(
      (thatLayoutItem: any) => thisLayoutItem?.x + thisLayoutItem?.w === thatLayoutItem?.x,
    )
    thatLayoutItems.forEach((thatLayoutItem: any) => {
      if (thatLayoutItem) {
        items[thatLayoutItem.i] = React.cloneElement(items[thatLayoutItem.i], {})
      }
    })

    // find all layouts touching on the y-axis
    thatLayoutItems = layout.filter(
      (thatLayoutItem: any) => thisLayoutItem?.y + thisLayoutItem?.h === thatLayoutItem?.y,
    )
    thatLayoutItems.forEach((thatLayoutItem: any) => {
      if (items[thatLayoutItem.i]) {
        items[thatLayoutItem.i] = React.cloneElement(items[thatLayoutItem.i], {})
      }
    })

    if (items[thisLayoutItem?.i]) {
      items[thisLayoutItem.i] = React.cloneElement(items[thisLayoutItem.i], thisProps)
    }
  })

  const { xl, lg, md, sm, xs } = layouts

  React.useEffect(() => {
    if (!isMdUp || !rowHeight || !layouts || !layout) return
    let currentLayout

    if (isXlUp) {
      currentLayout = xl
    } else if (isLgUp) {
      currentLayout = lg
    } else {
      currentLayout = md
    }

    const marketsBarDimensions = currentLayout.find((item: any) => item.i === 'marketsBar')
    const orderHistoryDimensions = currentLayout.find((item: any) => item.i === 'orderHistory')

    if (!orderHistoryDimensions || !marketsBarDimensions) return

    const ratio = MAX_HEIGHT_MARKETS_BAR / rowHeight

    const rowDifference = marketsBarDimensions.h - ratio

    const newLayout = currentLayout.map((item: any) => {
      if (item.i === 'marketsBar') {
        return { ...item, h: ratio, minH: ratio, maxH: ratio }
      } else if (item.i === 'orderHistory') {
        const newHeight = orderHistoryDimensions.h + rowDifference
        return { ...item, h: newHeight }
      }

      return item
    })

    let newLayouts

    if (isXlUp) {
      newLayouts = { ...layouts, xl: newLayout }
    } else if (isLgUp) {
      newLayouts = { ...layouts, lg: newLayout }
    } else {
      newLayouts = { ...layouts, md: newLayout }
    }

    if (!isEqual(layout, newLayout)) {
      dispatch(setLayout(newLayout))
      dispatch(setLayouts(newLayouts))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowHeight, isMdUp, isLgUp, isXlUp])

  const gridLayouts = { xl, lg, md, sm, xs }
  const { gridConfig } = baseLayouts
  return (
    <div style={{ width: '100%' }} className={classes.root}>
      {isTablet && (
        <div className={clsx(classes.sticky, { openRefPopUp })}>
          <div className={classes.marketsBar}>
            <LoadingSkeleton width={200} />
            <LoadingSkeleton width={100} />
            <LoadingSkeleton width={100} />
            <LoadingSkeleton width={100} />
            <LoadingSkeleton width={100} />
          </div>
        </div>
      )}
      {isMobile ? (
        <React.Fragment>
          <div className={classes.sectionTabsFallback}></div>
          <div className={clsx(classes.sticky, { openRefPopUp })}>
            <div className={classes.marketsBar}>
              <LoadingSkeleton width={200} />
              <LoadingSkeleton width={100} />
              <LoadingSkeleton width={100} />
              <LoadingSkeleton width={100} />
              <LoadingSkeleton width={100} />
            </div>
          </div>
          {!hideLayouts.contains('chart') && (
            <div id={EXCHANGE_SECTIONS.chart} className={clsx(classes.mobileAccordionDiv, 'chart')}>
              <div className={clsx(commonClasses.alignItemsCenter, commonClasses.justifyContentCenter, classes.minChartHeight)}>
                <LoadingIcon size="1.5rem" />
              </div>
            </div>
          )}
          {!hideLayouts.contains('orderBook') && (
            <div id={EXCHANGE_SECTIONS.orderBook} className={clsx(classes.mobileAccordionDiv)} ref={orderBookRef}>
              <div className={clsx(classes.gridBox, classes.box, 'mobileOrderBook')} ref={orderBookRef}>
                <div style={{ width: '50%' }}>
                  <LoadingSkeleton paddingBottom={24} />
                  <LoadingSkeleton paddingBottom={24} />
                  <LoadingSkeleton paddingBottom={24} />
                  <LoadingSkeleton paddingBottom={24} />
                  <LoadingSkeleton />
                </div>
                <div style={{ width: '50%' }}>
                  <LoadingSkeleton paddingBottom={24} />
                  <LoadingSkeleton paddingBottom={24} />
                  <LoadingSkeleton paddingBottom={24} />
                  <LoadingSkeleton paddingBottom={24} />
                  <LoadingSkeleton />
                </div>
              </div>
            </div>
          )}

          {!hideLayouts.contains('marginDisplay') && (
            <div id={EXCHANGE_SECTIONS.leverageOrBalance} className={classes.mobileAccordionDiv}>
              <div className={clsx(classes.gridBox, classes.box)} ref={orderBookRef}>
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton />

              </div>
            </div>
          )}
          {!hideLayouts.contains('orderHistory') && (
            <div id={EXCHANGE_SECTIONS.positionOrOrder} className={classes.mobileAccordionDiv}>
              <div className={clsx(classes.gridBox, classes.box)} ref={orderBookRef}>
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton />
              </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <ResponsiveGridLayout
          className={classes.layout}
          draggableHandle=".draggable"
          draggableCancel=".draggable-cancel"
          layouts={gridLayouts}
          margin={[4, 4]}
          // verticalCompact
          containerPadding={[0, 0]}
          cols={gridConfig.cols}
          breakpoints={gridConfig.breakpoints}
          rowHeight={isMdUp ? rowHeight : gridRowHeightMobile}
          onLayoutChange={onLayoutChange}
          measureBeforeMount
          useCSSTransforms
        >
          {layout.filter((e: any) => e?.i && e?.i !== 'marginDisplay').map((gridLayout: any) => {
            if (isTablet && gridLayout.i === 'marketsBar') {
              return (
                <div key={gridLayout.i} data-grid={gridLayout}></div>
              )
            }

            return (
              <div key={gridLayout.i} data-grid={gridLayout}>
                {items[gridLayout.i]}
              </div>
            )
          })}
        </ResponsiveGridLayout>
      )}
    </div>
  )
}

export default TradeMainFallback
