import { CarbonSDK } from 'carbon-js-sdk'
import { Leverage } from 'carbon-js-sdk/lib/websocket/models' // eslint-disable-line import/no-unresolved
import { Record } from 'immutable'
import { AnyAction } from 'redux'


import { AddPreferredLeveragePayload, LeveragesActionTypes, LeveragesState, LeveragesStateProps, PreferredLeverageByNetwork, SetPreferredLeveragePayload } from './types'

import { ExchangeActionTypes } from '../exchange/types'

export const DefaultInitialState:
  Record.Factory<LeveragesStateProps> = Record<LeveragesStateProps>({
    leverages: {},
    preferredLeverages: {},
    isSettingLeverages: false,
    pendingLeverage: 0,
    showLeveragesModal: false,
  })

const defaultInitialState: LeveragesState = new DefaultInitialState()

export const leveragesReducer = (
  state: LeveragesState = defaultInitialState,
  action: AnyAction,
): LeveragesState => {
  switch (action.type) {
    case LeveragesActionTypes.SET_LEVERAGES: {
      const currentLeverages = { ...state.leverages }
      for (const data of action.payload) {
        const { market_id, leverage }: Leverage = data
        currentLeverages[market_id!] = leverage
      }
      return state.set('leverages', currentLeverages)
    }
    case LeveragesActionTypes.SET_PREFERRED_LEVERAGES: {
      const { network, address }: SetPreferredLeveragePayload = action.payload
      
      if (!address) return state.set('preferredLeverages', {})

      const preferredLeverageByNetwork: PreferredLeverageByNetwork = JSON.parse(localStorage.getItem(ExchangeActionTypes.SET_MARKET_PREFERRED_LEVERAGES) || '{}')
      const currentPreferredLeverages = preferredLeverageByNetwork[network]?.[address] ?? {}

      return state.set('preferredLeverages', currentPreferredLeverages)
    }
    case LeveragesActionTypes.ADD_PREFERRED_LEVERAGE: {
      const { network, address, preferredLeverages }: AddPreferredLeveragePayload = action.payload

      if (!address) return state

      const preferredLeverageByNetwork = JSON.parse(localStorage.getItem(ExchangeActionTypes.SET_MARKET_PREFERRED_LEVERAGES) || '{}')
      const cleanedPreferredLeverages: PreferredLeverageByNetwork = {}
      
      for (const currNetwork in preferredLeverageByNetwork) {
        if (Object.values(CarbonSDK.Network).map(network => network.toString()).includes(currNetwork)) {
          cleanedPreferredLeverages[currNetwork] = preferredLeverageByNetwork[currNetwork]
        }
      }

      cleanedPreferredLeverages[network] ??= {}
      cleanedPreferredLeverages[network][address] ??= {}

      cleanedPreferredLeverages[network][address] = { ...cleanedPreferredLeverages[network][address], ...preferredLeverages }

      localStorage.setItem(ExchangeActionTypes.SET_MARKET_PREFERRED_LEVERAGES, JSON.stringify(cleanedPreferredLeverages))
      return state.set('preferredLeverages', cleanedPreferredLeverages[network][address])
    }
    case LeveragesActionTypes.BEGIN_SET_LEVERAGES: {
      return state.set('isSettingLeverages', true)
    }
    case LeveragesActionTypes.END_SET_LEVERAGES: {
      return state.set('isSettingLeverages', false)
    }
    case LeveragesActionTypes.CLEAR_LEVERAGES: {
      return new DefaultInitialState()
    }
    case LeveragesActionTypes.SET_PENDING_LEVERAGE: {
      return state.set('pendingLeverage', action.payload)
    }
    case LeveragesActionTypes.CLEAR_PENDING_LEVERAGE: {
      return state.set('pendingLeverage', defaultInitialState.pendingLeverage)
    }
    case LeveragesActionTypes.SHOW_LEVERAGE_MODAL: {
      return state.set('showLeveragesModal', true)
    }
    case LeveragesActionTypes.HIDE_LEVERAGE_MODAL: {
      return state.set('showLeveragesModal', false)
    }
    default:
      return state
  }
}
