import { Theme } from '@material-ui/core'

import { FlavorableOverrides } from './types'

const MuiBackdrop: FlavorableOverrides = (theme: Theme) => ({
  root: {
    opacity: '1 !important',
    visibility: 'visible',
    backdropFilter: 'blur(1px)',
    zIndex: 0,
  },
})

export default MuiBackdrop
