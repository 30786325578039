import { SimpleMap } from "js/utils"

export enum CrossSellingStage {
  Inactive = 0,
  Lend = 1,
  Borrow = 2,
  Trade = 3,
  Complete = 4,
}

export const nextCrossSellingStage: SimpleMap<CrossSellingStage> = {
  'acquire': CrossSellingStage.Lend,
  'lend': CrossSellingStage.Borrow,
  'borrow': CrossSellingStage.Trade,
  'trade': CrossSellingStage.Complete
} 

export type CrossSellingTriggerEvent = 'acquire' | 'borrow' | 'lend' | 'trade'

export enum TradeHelpWizardStage {
  Intro = 1,
  CreateOrder = 2,
  MonitorPosition = 3,
}

export const MAX_HELP_WIZARD_STAGE = 3