import { Theme } from '@material-ui/core'

import { FlavorableOverrides } from './types'

const MuiGrid: FlavorableOverrides = (theme: Theme) => ({
  "spacing-xs-4": {
    '& > $item': {
      padding: theme.spacing(1.5),
    }
  }
})

export default MuiGrid
