import { TypeUtils } from 'carbon-js-sdk'
import { Connection, ExternalTokenMapping } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/bridge/bridge'

import { RootState } from '../rootReducer'

export function getExternalTokens(state: RootState): TypeUtils.SimpleMap<ExternalTokenMapping> {
  return state.bridge.externalTokens
}

export function getBridgeConnections(state: RootState): TypeUtils.SimpleMap<Connection> {
  return state.bridge.connections
}