import BigNumber from 'bignumber.js'

import { BN_ZERO } from './number'
import { SimpleMap } from './types'

export interface Coin {
  amount: BigNumber,
  denom: string,
}

export const getMaxAmountAdjustedForNetworkFee = (maxAdjustedBalance: Coin, fee: Coin, reservedTokens: SimpleMap<BigNumber> = {}) => {
  const adjustedMaxAmount: Coin = { ...maxAdjustedBalance }
  const { amount, denom } = maxAdjustedBalance
  const { amount: feeAmount, denom: feeDenom } = fee

  if (feeDenom !== denom) return adjustedMaxAmount
  const hasReserved = !!reservedTokens[denom]

  if (hasReserved) {
    const reservedAmount = reservedTokens[denom]
    if (reservedAmount.lt(feeAmount)) {
      const remainder = feeAmount.minus(reservedAmount)
      adjustedMaxAmount.amount = BigNumber.max(amount.minus(remainder), BN_ZERO)
      return adjustedMaxAmount
    }
    return adjustedMaxAmount
  }
  adjustedMaxAmount.amount = BigNumber.max(amount.minus(feeAmount), BN_ZERO)

  return adjustedMaxAmount
}