import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { Page } from 'js/constants/login'
import { setSubPage, setWalletToggle } from 'js/state/modules/app/actions'

const ReferralParamListener: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    if (location.search.includes('loginType=')) {
      const queries = location.search.match(/loginType=([A-za-z0-9]{1,})/) ?? []
      const loginPages = Object.values(Page)
      if (queries[1] && loginPages.includes(queries[1] as unknown as Page)) {
        dispatch(setSubPage(queries[1] as Page))
        dispatch(setWalletToggle(true))
      }
    }
    return () => { }
  }, [dispatch, location.search])

  return null
}


export default ReferralParamListener
