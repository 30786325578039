import BigNumber from 'bignumber.js'
import { Staking } from 'carbon-js-sdk/lib/codec'
import { AllianceAsset } from 'carbon-js-sdk/lib/codec/alliance/alliance/alliance'
import { Proposal } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/gov'
import { Dayjs } from 'dayjs'
import { List, RecordOf } from 'immutable'

import { Validator } from 'js/models/staking/Validator'

export interface StakingLastUpdatedAt {
  validators: string
  delegations: string
  unbondingDelegations: string
  delegationRewards: string
  redelegations: string
}

export interface Status {
  bonded: boolean
  unbonded: boolean
  unbonding: boolean
}

export interface DelegatorCount {
  address: string
  count: number
}

export interface ValidatorParticipations {
  address: string
  validatorAddress: string
  participated: string
  total: string
}

export interface StakeStateProps {
  statuses: Status
  validators: List<Validator>
  delegations: Staking.DelegationResponse[]
  unbondingDelegations: UserUnbonding[]
  redelegations: UserRedelegation[]
  delegationRewards: DelegationReward[]
  totalStaked: string
  usdPrice: string
  lastUpdatedAt: StakingLastUpdatedAt
  aprStats: APRStats
  validatorParticipations: ValidatorParticipations[]
  proposals: Proposal[],
  allianceAssets: AllianceAsset[],
  avgBlockTime: BigNumber | null,
  totalDelegationRewardsUsd: BigNumber,
}

export interface APRStats {
  avgBlockTime: BigNumber,
  avgReward: BigNumber,
  totalBonded: BigNumber,
  apyMap: APYMap
}

export interface SubmitDelegationParams {
  amount: string
  validatorAddress: string
  validatorName: string
  feeDenom: string
}

export interface SubmitUnbondingParams {
  amount: string
  validatorAddress: string
  validatorName: string
  feeDenom: string
}

export interface SubmitRedelegationParams {
  amount: string
  validatorAddressSource: string
  validatorAddressDestination: string
  destinationName: string
  feeDenom: string
}

export interface SubmitWithdrawAllRewardsParams {
  feeDenom: string
}

export interface validatorToDelegatorsMap {
  [address: string]: Staking.DelegationResponse
}

export type StakeState = RecordOf<StakeStateProps>

export const StakeActionTypes = {
  START_SAGA: '@stake/START_SAGA',
  SET_STATUSES: '@stake/SET_STATUSES',
  SET_STAKING_INFORMATION: '@stake/SET_STAKING_INFORMATION',
  QUERY_STAKING_INFORMATION: '@stake/QUERY_STAKING_INFORMATION',
  QUERY_STAKING_VALIDATORS: '@stake/QUERY_STAKING_VALIDATORS',
  QUERY_USER_DELEGATIONS: '@stake/QUERY_USER_DELEGATIONS',
  QUERY_USER_UNBONDING_DELEGATIONS: '@stake/QUERY_USER_UNBONDING_DELEGATIONS',
  QUERY_USER_REDELEGATIONS: '@stake/QUERY_USER_REDELEGATIONS',
  QUERY_DELEGATOR_DELEGATIONS: '@stake/QUERY_DELEGATOR_DELEGATIONS',
  QUERY_DELEGATOR_DELEGATION_REWARDS: '@stake/QUERY_DELEGATOR_DELEGATION_REWARDS',
  SUBMIT_DELEGATION: '@stake/SUBMIT_DELEGATION',
  SUBMIT_UNBONDING: '@stake/SUBMIT_UNBONDING',
  SUBMIT_REDELEGATION: '@stake/SUBMIT_REDELEGATION',
  SUBMIT_WITHDRAW_ALL_DELEGATION_REWARDS: '@stake/SUBMIT_WITHDRAW_ALL_DELEGATION_REWARDS',
  SET_VALIDATORS: '@stake/SET_VALIDATORS',
  RELOAD_APR_STATS: '@stake/RELOAD_APR_STATS',
  SET_APR_STATS: '@stake/SET_APR_STATS',
  SET_VALIDATOR_DELEGATIONS: '@stake/SET_VALIDATOR_DELEGATIONS',
  SET_DELEGATIONS: '@stake/SET_DELEGATIONS',
  SET_UNBONDING_DELEGATIONS: '@stake/SET_UNBONDING_DELEGATIONS',
  SET_REDELEGATIONS: '@stake/SET_REDELEGATIONS',
  SET_DELEGATION_REWARDS: '@stake/SET_DELEGATION_REWARDS',
  SET_VALIDATOR_PARTICIPATIONS: '@stake/SET_VALIDATOR_PARTICIPATIONS',
  QUERY_VALIDATOR_PARTICIPATIONS: '@stake/QUERY_VALIDATOR_PARTICIPATIONS',
  QUERY_ALLIANCES: '@stake/QUERY_ALLIANCES',
  SET_ALLIANCES: '@stake/SET_ALLIANCES',
  SET_PROPOSALS: '@stake/SET_PROPOSALS',
  QUERY_PROPOSALS: '@stake/QUERY_PROPOSALS',
  SET_AVG_BLOCK_TIME: '@stake/SET_AVG_BLOCK_TIME',
  SET_TOTAL_DELEGATION_REWARDS_USD: '@stake/SET_TOTAL_DELEGATION_REWARDS_USD',
}

// Responses from API

interface TasksType {
  [index: string]: string
}

export const StakeTasks: TasksType = {
  Validators: 'validators-staking',
  ValidatorDelegations: 'validators-delegations-staking',
  AprStats: 'validators-apr-stats',
  Equivalence: 'stake-equivalence',
  DelegationRewards: 'delegation-rewards',
  StakeEquivalenceCalcs: 'stake-equivalence-calcs',
}

export interface UserUnbonding {
  balance: BigNumber
  initialBalance: BigNumber
  denom: string
  validatorAddress: string
  delegatorAddress: string
  completionTime: Dayjs
  creationHeight: number
}

export interface UserRedelegation {
  balance: BigNumber
  initialBalance: BigNumber
  newShares: BigNumber
  denom: string
  validatorSrcAddress: string
  validatorDstAddress: string
  delegatorAddress: string
  completionTime: Dayjs
  creationHeight: number
}

export interface DelegationReward {
  validatorAddress: string
  delegatorAddress: string
  denom: string
  rewards: TokenAmount[]
}

export interface TokenAmount {
  amount: BigNumber;
  denom: string;
}

export interface APYMap {
  [denom: string]: BigNumber
}
