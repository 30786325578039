
import { ApiError, ApiErrorResponse, ApiResponse, HttpMethod } from 'js/constants/api'

import { sendApiRequest } from '../api'

export const sendAlertsApiRequest = async <K, T = ApiError>(
  method: HttpMethod,
  url: string,
  body?: any,
  accessToken?: string
): Promise<ApiResponse<K> | ApiErrorResponse<T>> => {
  const response = await sendApiRequest(method, url, body, accessToken)
  return response
}
