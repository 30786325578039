import { List } from 'immutable'

import { OrderBookEntry } from 'js/state/modules/orderBook/types'

import { RootState } from '../rootReducer'

export function getOpenBuys(state: RootState): List<OrderBookEntry> {
  return state.orderBook.openBuys
}

export function getOpenSells(state: RootState): List<OrderBookEntry> {
  return state.orderBook.openSells
}
