import { Divider, useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import React, { HTMLAttributes } from 'react'

import List from './List'
import ListItem from './ListItem'
import { useStyles } from './styles'

import LoadingSkeleton from '../LoadingSkeleton/LoadingSkeleton'

interface Props extends HTMLAttributes<HTMLDivElement> {
  sticky?: boolean
}
const LoadingSidebar: React.FC<Props> = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  const desktop = useMediaQuery(theme.breakpoints.up('lg'))

  return !desktop ? (
    <div className={classes.navBar}>
      <ListItem iconType="fill">
        <LoadingSkeleton height={20} width={120} />
      </ListItem>
      <ListItem iconType="fill">
        <LoadingSkeleton height={20} width={120} />
      </ListItem>
      <ListItem iconType="fill">
        <LoadingSkeleton height={20} width={120} />
      </ListItem>
    </div>
  ) : (
    <div className={clsx(classes.drawerContainer, props.className)}>
      <div className={clsx({ [classes.stickySidebar] : props.sticky })}>
        <div className={classes.walletLoadingWrapper}>
          <LoadingSkeleton height={48} />
        </div>
        <Divider className={classes.divider} />
        <List>
          <ListItem iconType="fill" disabledClick>
            <LoadingSkeleton />
          </ListItem>
          <ListItem iconType="fill" disabledClick>
            <LoadingSkeleton />
          </ListItem>
          <ListItem iconType="fill" disabledClick>
            <LoadingSkeleton />
          </ListItem>
        </List>
      </div>
    </div>
  )
}

export default LoadingSidebar
