import BigNumber from 'bignumber.js'
import { Hydrogen, OrderModule, TypeUtils, WSModels } from 'carbon-js-sdk'
import { List } from 'immutable'

import { ModifiedHistoryOrder, OverviewGraphCoordinate, PendingCrosschainTransfers, FundingData, FundingBreakdownGraphCoordinate } from './types'

import { RootState } from '../rootReducer'

export function getOpenOrders(state: RootState): List<ModifiedHistoryOrder> {
  return state.history.openOrders
}

export function getOrderHistory(state: RootState): List<ModifiedHistoryOrder> {
  return state.history.orderHistory
}

export function getEditedOrders(state: RootState): { [orderId: string]: Partial<OrderModule.EditOrderParams> } {
  return state.history.editedOrders
}

export function getAccountTrades(state: RootState): List<WSModels.AccountTrade> {
  return state.history.trades
}

export function getIsCurrentMarketSelected(state: RootState): boolean {
  return state.history.isCurrentMarketSelected
}

export function getOpenPositions(state: RootState): List<WSModels.Position> {
  return state.history.openPositions
}

export function getCurrentMarketOpenPosition(state: RootState): WSModels.Position | undefined {
  const currentMarket = state.exchange.market
  return state.history.openPositions.filter(
    (position) => position.market_id === currentMarket?.id && !new BigNumber(position.lots).eq(0),
  ).first()
}

export function getClosedPositions(state: RootState): List<WSModels.Position> {
  return state.history.closedPositions
}

export function getClosePositionPriceInputs(state: RootState): TypeUtils.SimpleMap<string> {
  return state.history.closePositionPriceInputs
}

export function getIsUSD(state: RootState): any {
  return state.history.isUSD
}

export function getCrosschainTransfers(state: RootState): Hydrogen.CrossChainTransfer[] {
  return state.history.crosschainTransfers
}

export function getPendingCrosschainTransfers(state: RootState): PendingCrosschainTransfers {
  return state.history.pendingCrosschainTransfers
}

export function getIsSyncComplete(state: RootState): boolean {
  return state.history.isSyncComplete
}

export function getIsUserOverviewMode(state: RootState): boolean {
  return state.history.isUserOverviewMode
}

export function getUserBalanceGraphData(state: RootState): OverviewGraphCoordinate[] {
  return state.history.balanceGraphData
}

export function getUserPnlGraphData(state: RootState): OverviewGraphCoordinate[] {
  return state.history.pnlGraphData
}

export function getUserPnl(state: RootState): BigNumber {
  return state.history.pnl
}

export function getUserFunding(state: RootState): FundingData[] {
  return state.history.fundingData
}

export function getUserFundingPaidBreakdownGraphData(state: RootState): FundingBreakdownGraphCoordinate[] {
  return state.history.fundingPaidBreakdownGraphData
}

export function getUserFundingReceivedBreakdownGraphData(state: RootState): FundingBreakdownGraphCoordinate[] {
  return state.history.fundingReceivedBreakdownGraphData
}
