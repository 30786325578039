import { IconButton, makeStyles, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import React, { useState } from 'react'

import Tooltip from 'js/components/Exchange/Common/Tooltip'
import { StyleUtils } from 'js/utils'
import { copyTextToClipboard, SmallNumObj, SmallNumResult } from 'js/utils/strings'

import { ReactComponent as CopyOutlinedIcon } from 'assets/CopyOutlined.svg'

import TypographyLabel from './TypographyLabel/TypographyLabel'



interface Props {
  formatted: SmallNumResult | BigNumber
  defaultFormattedNum?: string
  numSubscriptClass?: string
  subSubscriptClass?: string
  usdValue?: boolean
  tooltipUnderlineClass?: string
  frontUnit?: string
  backUnit?: string
  tooltipPlacement?: 'top' | 'bottom'
}

const SubscriptText: React.FC<Props> = (props: Props) => {
  const { defaultFormattedNum, formatted, numSubscriptClass, subSubscriptClass, tooltipUnderlineClass, usdValue, frontUnit = '', backUnit = '', tooltipPlacement } = props
  const classes = useStyles()
  const [copied, setCopied] = useState(false)
  const dollarPrefix = usdValue ? '$' : ''

  const handleCopyValue = (amount: string, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation()
    copyTextToClipboard(
      amount,
      'Amount copied',
      'Error copying amount. Please do it manually.',
      false,
    )
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  function formatDollarValue(str: string, excludeBackUnit: boolean = false): string {
    const suffixUnit = backUnit && !excludeBackUnit ? backUnit : ''
    let prefixUnit = dollarPrefix || frontUnit || ''
    if (dollarPrefix && frontUnit) {
      prefixUnit = frontUnit + dollarPrefix
    }

    // move negative sign in front of prefixUnit (e.g. $-2.00 -> -$2.00)
    if (str.includes('-')) {
      return '-' + prefixUnit + str.replace('-', '') + suffixUnit
    }
    return prefixUnit + str + suffixUnit
  }

  if (defaultFormattedNum) {
    return (
      <React.Fragment>{formatDollarValue(defaultFormattedNum)}</React.Fragment>
    )
  }

  if (typeof formatted === 'string') {
    return (
      <React.Fragment>{formatDollarValue(formatted)}</React.Fragment>
    )
  }

  // if value is greater than 0.001, formatted type will be BigNumber
  if (formatted && usdValue && BigNumber.isBigNumber(formatted)) {
    let returnFormattedValue = formatted.toFormat(5)
    if (formatted.gt(0.1)) {
      returnFormattedValue = formatted.toFormat(2)
    }
    return (
      <React.Fragment>{formatDollarValue(returnFormattedValue)}</React.Fragment>
    )
  }

  // if value is smaller than 0.001, formatted type will be SmallNumObj
  const { integerPart, zeroCount, decimalPart } = formatted as SmallNumObj
  const zeroAmount = '0'.repeat(zeroCount)
  const defaultValueStr = `${integerPart}.${zeroAmount}${decimalPart}`
  const subscriptLeftStr = `${integerPart}.0`

  return (
    <Tooltip
      placement={tooltipPlacement ?? 'top'}
      arrow={false}
      leaveDelay={300}
      title={(
        <React.Fragment>
          <TypographyLabel
            className={classes.typographyLabel}
            onClick={(e) => {
              handleCopyValue(defaultValueStr, e)
            }}>
            <span className={clsx(classes.text, { show: copied })}>Copied!</span>
            <span className={clsx(classes.text, { show: !copied })}>
              {formatDollarValue(defaultValueStr)}
              <IconButton
                className={classes.copyIcon}>
                <CopyOutlinedIcon />
              </IconButton>
            </span>
          </TypographyLabel>
        </React.Fragment>
      )}
    >
      <span className={clsx(classes.numberWithSubscript, numSubscriptClass)}>
        <span className={clsx(classes.tooltipUnderline, tooltipUnderlineClass)}>{formatDollarValue(subscriptLeftStr, true)}</span>
        <sub className={clsx(classes.subscript, subSubscriptClass)}>{zeroCount}</sub>
        <span className={clsx(classes.tooltipUnderline, tooltipUnderlineClass)}>{decimalPart}{backUnit}</span>
      </span>
    </Tooltip>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    ...theme.typography.body4,
    display: 'none',
    '&.show': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  tooltipUnderline: {
    borderBottom: '1px dashed',
  },
  subscript: {
    ...theme.typography.body5,
    paddingLeft: theme.spacing(0.125),
    paddingRight: theme.spacing(0.125),
    borderBottom: '0px',
    verticalAlign: 'baseline',
    position: 'relative',
    top: '2px',
  },
  numberWithSubscript: {
    ...theme.typography.body3,
  },
  copyIcon: {
    padding: '0px 0px',
    '& svg': {
      height: '0.70em',
      width: '0.70em',
      '& path': {
        ...StyleUtils.textButtonHoverAnimationDuration(),
        stroke: theme.palette.text.demexSolid,
      },
      '&:hover': {
        '& path': {
          stroke: theme.palette.text.demexSolidHover,
        },
      }
    },
  },
  typographyLabel: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}))

export default SubscriptText
