import { useMemo } from "react"
import { useSelector } from "react-redux"

import { liteModeLocalStorageKey } from "js/state/modules/exchange/types"
import { RootState } from "js/state/modules/rootReducer"
import { StatsigExperimentNames } from "js/state/modules/statsig/types"

import { useStatsigExperiment } from "./useStatsigExperiment"

const useIsLiteMode = () => {
  const isSimpleMode = useSelector((store: RootState) => store.exchange.isSimpleMode)
  const noCachedLiteModeSetting = !localStorage.getItem(liteModeLocalStorageKey)
  const { variable: isStatsigAllocatedLiteMode } = useStatsigExperiment(StatsigExperimentNames.ProVsLiteMode, 'Lite mode', noCachedLiteModeSetting)

  const isLiteMode = useMemo((): boolean => {
    if (noCachedLiteModeSetting && typeof isStatsigAllocatedLiteMode === 'boolean') {
      return isStatsigAllocatedLiteMode
    } else {
      return isSimpleMode
    }
  }, [isStatsigAllocatedLiteMode, isSimpleMode, noCachedLiteModeSetting])

  return {
    isLiteMode,
    noCachedLiteModeSetting,
  }
}

export default useIsLiteMode