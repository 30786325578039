export enum WalletType {
  Keplr = 'Keplr',
  Metamask = 'MetaMask',
  LegacyMetamask = 'MetaMask (Legacy)',
  EncryptedKey = 'Encrypted Key',
  Leap = 'Leap',
  Web3Auth = 'Web3Auth',
  Ledger = 'Ledger',
  WalletConnect = 'walletConnect'
}

declare global {
  interface Window{
    web3?: any
  }
}
