import { Hidden, Theme, Tooltip, makeStyles } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { CarbonSDK, CarbonTx, OrderModule } from 'carbon-js-sdk'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import clsx from 'clsx'
import React, { CSSProperties, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import DropdownContent from 'js/components/Account/UserOverview/components/DropdownContent'
import { formStyles } from 'js/components/Borrow/styles'
import { AmountInput, SearchInput, TypographyLabel } from 'js/components/Common'
import AlertBanner from 'js/components/Common/AlertBanner'
import AssetIcon from 'js/components/Common/AssetIcon'
import Button from 'js/components/Common/Button'
import DropdownButton from 'js/components/Common/DropdownButton'
import FormInputWrapper from 'js/components/Common/FormInputWrapper'
import TextButton from 'js/components/Common/TextButton'
import TokenSymbol from 'js/components/Common/TokenSymbol'
import TokensTable, { TokenBalance } from 'js/components/Common/TokensTable/TokensTable'
import { SHIFT_DECIMALS } from 'js/constants/assets'
import { StaticLinks } from 'js/constants/externalLinks'
import Paths from 'js/constants/paths'
import { useAsyncTask, useDropdownHandler, useGetBalanceInputDetails, useMarketStats, useUsdValue, } from 'js/hooks'
import useBroadcastTx from 'js/hooks/useBroadcastTx'
import { isSpot, isValidMarket } from 'js/models/Market'
import { isBuy } from 'js/models/Order'
import { getReservedTokensPreference } from 'js/state/modules/account/selectors'
import { getCarbonSDK, getDemexConfig, getNet, getTokens } from 'js/state/modules/app/selectors'
import { getMarketsAsMap } from 'js/state/modules/exchange/selectors'
import { Market } from 'js/state/modules/exchange/types'
import { clearSWTHFeeForm, selectFeeToken, setFeeQuantity } from 'js/state/modules/orderManager/actions'
import { getFeeQuantity, getFeeToken } from 'js/state/modules/orderManager/selectors'
import { getReferrerAddr, getReferrerComm, getReferrerKb } from 'js/state/modules/referrals/selectors'
import { checkSelectToken } from 'js/state/modules/walletBalance/actions'
import { getAdjustedBalances } from 'js/state/modules/walletBalance/selectors'
import { BN_ZERO, formatUsdPrice, parseNumber } from 'js/utils/number'
import { MemoLocation, generateExchangeMemo, getAdjustedTickLotSize, massageInput, unshiftByDiffDp } from 'js/utils/order'
import { getTokenName } from 'js/utils/strings'
import { StyleUtils, useCommonStyles } from 'js/utils/styles'

import { ReactComponent as ExternalLinkIcon } from 'assets/ExternalLink.svg'

interface MarketsObj {
  tokenMap: TokenBalance[]
  hasMarkets: boolean
}

interface Props {
  feeDropdownOpen?: boolean
  feeDropdownPaperRef?: React.RefObject<HTMLDivElement>
}

const txType = CarbonTx.Types.MsgCreateOrder

const MiddleFeeBox: React.FC<Props> = (props: Props) => {
  const {feeDropdownOpen, feeDropdownPaperRef} = props
  const adjustedBalances = useSelector(getAdjustedBalances)
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const styles = formStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [orderTx] = useBroadcastTx('order', txType)

  const { firstPriority: firstPriorityToken = { denom: '', symbol: '' } } = useSelector(getReservedTokensPreference) ?? {}
  const demexConfig = useSelector(getDemexConfig)
  const tokens = useSelector(getTokens)
  const [isDropdownOpen, handleDropdownOpen, handleDropdownClose] = useDropdownHandler(false)
  const feeToken = useSelector(getFeeToken)
  const feeQuantity = useSelector(getFeeQuantity)
  const markets = useSelector(getMarketsAsMap)
  const sdk: CarbonSDK = useSelector(getCarbonSDK) as CarbonSDK
  const network = useSelector(getNet)
  const referralAddr = useSelector(getReferrerAddr)
  const referralComm = useSelector(getReferrerComm)
  const referralKb = useSelector(getReferrerKb)

  const tokenClient = sdk?.token

  const usdValue = useUsdValue(firstPriorityToken.denom ?? 'swth')

  const [backdropStyle, setBackdropStyle] = React.useState<CSSProperties>({})

  useEffect(() => {
    if (feeDropdownOpen === false && isDropdownOpen) {
      handleDropdownClose()
    }
  }, [feeDropdownOpen, handleDropdownClose, isDropdownOpen])

  useEffect(() => {
    if (isDropdownOpen && feeDropdownPaperRef?.current) {
      const dropdownHeight = feeDropdownPaperRef.current.offsetHeight
      const paperStyle = window.getComputedStyle(feeDropdownPaperRef.current)
      const paperLeftWidth = parseFloat(paperStyle.marginLeft) + parseFloat(paperStyle.paddingLeft) + parseFloat(paperStyle.borderLeftWidth)
      
      setBackdropStyle({
        minHeight: dropdownHeight,
        left: -paperLeftWidth,
      })
    }
  }, [feeDropdownPaperRef, isDropdownOpen])

  const { hasMarkets, tokenMap } = React.useMemo((): MarketsObj => {
    const tokenPrefMarkets = markets.filter((market: Market) => {
      return isSpot(market.marketType)
        && (market.base === firstPriorityToken.denom || market.quote === firstPriorityToken.denom)
        && isValidMarket(market, demexConfig.blacklistedMarkets)
    })
    const tokensList: string[] = []

    tokenPrefMarkets.forEach((market: Market) => {
      if (market.quote !== firstPriorityToken.denom && !tokensList.includes(market.quote)) {
        tokensList.push(market.quote)
      }
      if (market.base !== firstPriorityToken.denom && !tokensList.includes(market.base)) {
        tokensList.push(market.base)
      }
    })
    const tokenMap = tokens.filter((token: Token) => (
      tokensList.includes(token.denom)
    )).map((token: Token): TokenBalance => {
      const availableBalance = adjustedBalances[token.denom]?.available ?? BN_ZERO
      const usdValue = tokenClient?.getUSDValue(token.denom) ?? BN_ZERO
      const symbol = getTokenName(token.denom, tokenClient, network)
      return {
        assetId: token.tokenAddress,
        denom: token.denom,
        symbol,
        name: token.name,
        balance: availableBalance,
        usdValue: availableBalance.times(usdValue),
        noOfExtraBlockChains: 0,
      }
    })
    return {
      tokenMap, hasMarkets: tokenMap.length > 0,
    }
  }, [markets, tokens, tokenClient, adjustedBalances, network, firstPriorityToken.denom, demexConfig])

  const [input, setInput] = React.useState<string>('')
  const [priceWarning, showPriceWarning] = React.useState<boolean>(false)
  const [avgPrice, setAvgPrice] = React.useState<BigNumber>(BN_ZERO)
  const [newPrice, setNewPrice] = React.useState<BigNumber>(BN_ZERO)
  const [lastPrice, setLastPrice] = React.useState<BigNumber>(BN_ZERO)
  const [getRecentTrades] = useAsyncTask('getRecentTrades')
  const [submitMarketOrder, loading, error, clearError] = useAsyncTask('submitMarketOrder', undefined, true) // eslint-disable-line @typescript-eslint/no-unused-vars

  const selectedToken = tokens.find((token: Token) => token.denom.toLowerCase() === feeToken?.toLowerCase())
  const selectedTokenDenom = selectedToken?.denom ?? ''
  const selectedMarket = useMemo(() => {
    return markets.find((market: Market) => (
      isSpot(market.marketType)
      && ((market.quote === feeToken && market.base === firstPriorityToken.denom) || (market.base === feeToken && market.quote === firstPriorityToken.denom))
      && isValidMarket(market, demexConfig.blacklistedMarkets)
    ))
  }, [demexConfig, markets, feeToken, firstPriorityToken])
  const marketStats = useMarketStats(selectedMarket?.id ?? '')
  const currentOrderSide = selectedMarket?.quote === feeToken ? OrderModule.OrderSide.Buy : OrderModule.OrderSide.Sell

  const { tickSize: tickSizeBN, lotSize: lotSizeBN } = React.useMemo(() => {
    const tickLotSize = getAdjustedTickLotSize(selectedMarket)
    return tickLotSize
  }, [selectedMarket])

  const { adjustedToken, priceImpactPercent } = React.useMemo(() => {
    // Price Impact = (Diff btw avgPrice and lastPrice) / lastPrice * 100
    const adjustedLastPrice = unshiftByDiffDp(selectedMarket, lastPrice)
    const diff = isBuy(currentOrderSide) ? newPrice.minus(adjustedLastPrice) : adjustedLastPrice.minus(newPrice)
    const priceImpactPercent = diff.div(adjustedLastPrice).times(100)
    const feeQuantityBN = parseNumber(feeQuantity, BN_ZERO)!

    const tokenAmt = isBuy(currentOrderSide) ? feeQuantityBN.div(avgPrice) : feeQuantityBN.times(avgPrice)
    const adjustedToken = avgPrice.isZero() ? BN_ZERO : tokenAmt

    return {
      adjustedToken: massageInput(adjustedToken, currentOrderSide, lotSizeBN, true),
      priceImpactPercent,
    }
  }, [newPrice, avgPrice, lastPrice, feeQuantity, lotSizeBN, currentOrderSide, sdk?.token, selectedMarket]) // eslint-disable-line

  const { adjustedMaxAmount, networkFee } = useGetBalanceInputDetails([txType], selectedTokenDenom)
  const feeDenom = networkFee.denom

  const handleSelectToken = (token: any) => {
    clearError()
    dispatch(selectFeeToken(token.denom))
    handleDropdownClose()
  }

  const onAmountMax = () => {
    clearError()
    dispatch(setFeeQuantity(massageInput(adjustedMaxAmount, currentOrderSide, tickSizeBN, false).toString(10)))
  }

  const onAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    const rawInput = event.target.value
    dispatch(setFeeQuantity(rawInput))
  }

  const onEndEditing = () => {
    const feeQuantityBN = parseNumber(feeQuantity, BN_ZERO)!
    if (feeQuantityBN.lt(0) || feeQuantityBN.isZero()) {
      dispatch(setFeeQuantity('0'))
      return
    }
    if (feeQuantityBN.gt(adjustedMaxAmount)) {
      dispatch(setFeeQuantity(massageInput(adjustedMaxAmount, currentOrderSide, tickSizeBN, false).toString(10)))
      return
    }
    dispatch(setFeeQuantity(massageInput(feeQuantityBN, currentOrderSide, tickSizeBN, false).toString(10)))
  }

  useEffect(() => {
    getRecentTrades(async () => {
      try {
        if (!selectedMarket || !sdk?.token || !selectedToken) {
          return
        }

        const baseDp = sdk.token.getDecimals(selectedMarket?.base ?? '') ?? 0
        const quoteDp = sdk.token.getDecimals(selectedMarket?.quote ?? '') ?? 0

        const marketLastPrice = marketStats?.last_price

        const orderBookResponse = await sdk?.query.book.CombinedBook({
          marketId: selectedMarket?.id,
        })
        const openSells = orderBookResponse.book?.asks ?? []
        const openBuys = orderBookResponse.book?.bids ?? []
        const openOrders = isBuy(currentOrderSide) ? openSells : openBuys

        if (!marketLastPrice || !openOrders) {
          showPriceWarning(true)
        } else {
          showPriceWarning(false)
        }
        const lastPriceBN = parseNumber(marketLastPrice, BN_ZERO)! ?? BN_ZERO

        const feeQuantityBN = parseNumber(feeQuantity, BN_ZERO)!
        const tokenAmt = isBuy(currentOrderSide) ? feeQuantityBN.dividedBy(lastPriceBN) : feeQuantityBN

        if (openOrders.length === 0) {
          setAvgPrice(BN_ZERO)
        } else {
          let totalCost = BN_ZERO
          let totalVolume = BN_ZERO
          let newPrice = BN_ZERO

          for (const order of openOrders) {
            const orderQuantity = parseNumber(order.totalQuantity, BN_ZERO)!.shiftedBy(-baseDp)
            const orderPrice = parseNumber(order.price, BN_ZERO)!.shiftedBy(baseDp - quoteDp).shiftedBy(-SHIFT_DECIMALS)

            newPrice = orderPrice
            if (orderQuantity.plus(totalVolume).gte(tokenAmt)) {
              const remainingQuantity = tokenAmt.minus(totalVolume)
              totalCost = totalCost.plus(orderPrice.times(remainingQuantity))
              totalVolume = totalVolume.plus(remainingQuantity)
              break
            } else {
              totalCost = totalCost.plus(orderPrice.times(orderQuantity))
              totalVolume = totalVolume.plus(orderQuantity)
            }
          }

          setAvgPrice(totalCost.dividedBy(totalVolume))
          setNewPrice(newPrice)
        }

        setLastPrice(lastPriceBN)
      } catch (err) {
        console.error(err)
      }
    })
  }, [selectedToken, feeQuantity, selectedMarket]) // eslint-disable-line react-hooks/exhaustive-deps

  const inputValidate = () => {
    if (!selectedToken) {
      throw new Error(`Please select a token to purchase ${firstPriorityToken.symbol} tokens with.`)
    }
    const feeQuantityBN = parseNumber(feeQuantity, BN_ZERO)!
    if (feeQuantityBN.lte(BN_ZERO)) {
      throw new Error(`Please enter a ${getTokenName(selectedTokenDenom, sdk.token, network)} quantity of more than 0`)
    }
    if (feeQuantityBN.gt(adjustedMaxAmount)) {
      throw new Error(`You do not have enough ${getTokenName(selectedTokenDenom, sdk.token, network)} tokens to purchase the amount of ${firstPriorityToken.symbol} you entered. Please deposit more or enter a smaller amount.`)
    }
  }

  const submitOrder = () => {
    submitMarketOrder(async () => {
      if (!selectedMarket || !sdk) return

      inputValidate()
      clearError()

      // not using sdk because quantity is shifted by 18 digits as well
      // might break the code
      const adjustedQuantity = sdk.token.toUnitless(selectedMarket.base, adjustedToken)

      const demexReferrer = demexConfig.demexReferrer
      const submitParams: OrderModule.CreateOrderParams = {
        marketId: selectedMarket.id,
        side: currentOrderSide,
        orderType: OrderModule.OrderType.Market,
        quantity: adjustedQuantity,
        referralAddress: referralAddr ?? demexReferrer?.address ?? '',
        referralCommission: referralComm ?? demexReferrer?.commission ?? 0,
        referralKickback: referralKb ?? demexReferrer?.kickback ?? 0,
      }
      await orderTx({
        txTask: async () => {
          return sdk.order.create(
            submitParams,
            {
              feeDenom,
              memo: generateExchangeMemo(MemoLocation.Fees),
            },
          )
        },
      })
      dispatch(clearSWTHFeeForm())
    })
  }

  const goToFeesDocs = () => {
    window.open(StaticLinks.DemexDocs.NetworkFees, '_blank')
  }

  const handleDepositClick = (denom: string) => {
    const newToken = sdk?.token.tokenForDenom(denom)
    if (!newToken) return

    dispatch(checkSelectToken(newToken))
    history.push(`${Paths.Account.Deposit}/${newToken.denom}`)
  }

  return (
    <div className={commonClasses.mt0}>
      <Hidden mdUp>
        <FormInputWrapper
          className={classes.formInputWrapper}
          title="From"
          balance={bnOrZero(massageInput(adjustedMaxAmount, currentOrderSide, tickSizeBN, false))}
          maxTitle="Balance"
          maxButtonClick={onAmountMax}
          depositMoreDenom={selectedTokenDenom}
        >
          <AmountInput
            value={feeQuantity}
            onChange={onAmountChange}
            onBlur={onEndEditing}
            suffix={(
              <DropdownButton
                className={clsx(classes.tokenSelect, 'tokenSelect')}
                open={isDropdownOpen}
                onClick={isDropdownOpen ? handleDropdownClose : handleDropdownOpen}
                containerClass={classes.containerClass}
              >
                <div className={clsx(commonClasses.alignItemsCenter, commonClasses.justifyContentEnd, commonClasses.flex1)}>
                  {feeToken && (
                    <React.Fragment>
                      <AssetIcon
                        className={clsx(classes.assetIcon, 'assetFrom')}
                        denom={feeToken}
                      />
                      <TokenSymbol className={classes.tokenSymbolLabel} denom={feeToken} />
                    </React.Fragment>
                  )}
                </div>
              </DropdownButton>
            )}
            assetDenom={feeToken ?? ""}
            fullWidth
            inputClasses={{
              outlined: {
                adornedEnd: classes.adornedEnd,
              },
            }}
            boxClass={classes.formInputWrapper}
          />
        </FormInputWrapper>
      </Hidden>
      <Hidden smDown>
        <AmountInput
          value={feeQuantity}
          onChange={onAmountChange}
          onBlur={onEndEditing}
          suffix={(
            <DropdownButton
              className={clsx(classes.tokenSelect, 'tokenSelect')}
              open={isDropdownOpen}
              onClick={isDropdownOpen ? handleDropdownClose : handleDropdownOpen}
              containerClass={classes.containerClass}
            >
              <div className={clsx(commonClasses.alignItemsCenter, commonClasses.justifyContentEnd, commonClasses.flex1)}>
                {feeToken && (
                  <React.Fragment>
                    <AssetIcon
                      className={clsx(classes.assetIcon, 'assetFrom')}
                      denom={feeToken}
                    />
                    <TokenSymbol className={classes.tokenSymbolLabel} denom={feeToken} />
                  </React.Fragment>
                )}
              </div>
            </DropdownButton>
          )}
          assetDenom={feeToken ?? ""}
          fullWidth
          inputClasses={{
            outlined: {
              adornedEnd: classes.adornedEnd,
            },
          }}
          boxClass={styles.amountInputBox}
          tokenBalance={bnOrZero(massageInput(adjustedMaxAmount, currentOrderSide, tickSizeBN, false))}
          onClickActionBtn={onAmountMax}
          primaryLabel={"From"}
          secondaryLabel={"Balance"}
          depositMoreDenom={selectedTokenDenom}
        />
      </Hidden>
      <DropdownContent heightOffset={0} showDropdown={isDropdownOpen} onClickAway={handleDropdownClose} backdropClass={clsx(feeDropdownPaperRef && classes.tokenBackdrop, { hide: !isDropdownOpen })} backdropStyle={backdropStyle}>
        <div className={classes.middleFeeBox}>
          <SearchInput
            input={input}
            setInput={setInput}
            classes={{
              root: classes.search,
              field: classes.field,
              input: classes.input,
              icon: classes.searchIcon,
            }}
          />
        </div>
        <div className={classes.tokensContainer}>
          <TokensTable
            tokens={tokenMap}
            onClickRow={handleSelectToken}
            classes={{
              container: classes.container,
              tokenName: classes.newTokenName,
              tokenTitle: classes.tokenTitle,
              assetIcon: classes.tableAssetIcon,
            }}
            input={input}
          />
        </div>
      </DropdownContent>
      <AmountInput
        className={clsx(classes.inputPrice, 'inputPrice')}
        value={!adjustedToken.isFinite() ? '0' : adjustedToken.toString(10)}
        disabled
        fullWidth
        suffix={(
          <React.Fragment>
            {isDropdownOpen && (
              <AssetIcon className={classes.assetIcon} svgClass={classes.coinIcon} denom={sdk.token.getTokenName(firstPriorityToken.denom).toLowerCase()} />
            )}
            <TokenSymbol className={classes.tokenSymbolLabel} denom={firstPriorityToken.denom} />
          </React.Fragment>
        )}
        assetDenom={firstPriorityToken.denom}
        boxClass={classes.formInputContainer}
        primaryLabel={"To"}
        secondaryLabel={`1 ${firstPriorityToken.symbol} = ${formatUsdPrice(usdValue)}`}
        wrapSecondaryLabel
      />
      {priceWarning && (
        <div className={classes.alertContainer}>
          <AlertBanner
            className={classes.alertRoot}
            variant="warning"
            title="Warning"
            message="There are no open orders for your selected token at the moment. Please choose another token or wait a little longer for orders to be placed."
            allowCollapse={false}
          />
        </div>
      )}

      {!hasMarkets && (
        <div className={classes.alertContainer}>
          <AlertBanner
            variant="warning"
            className={classes.alertRoot}
            title="Warning"
            message={(
              <React.Fragment>
                There are no markets for this token at the moment. Please choose another token or deposit the token directly.
                <TextButton boxClass={classes.alertBoxClass} endIcon={ExternalLinkIcon} label="Deposit" onClick={() => handleDepositClick(firstPriorityToken?.denom ?? '')} />
              </React.Fragment>
            )}
            allowCollapse={false}
          />
        </div>
      )}

      {adjustedToken.isFinite() && !adjustedToken.isZero()
        && (
          <div className={clsx(commonClasses.mt2, classes.priceImpactContainer, commonClasses.alignItemsCenter, commonClasses.justifyContentSpaceBetween)}>
            <Tooltip
              classes={{ tooltip: classes.tooltipContainer }}
              title={(
                <div className={classes.tooltip}>
                  The estimated difference betwen the best price and your average order execution price.<br /><br />The larger the order relative to the overall market liquidity, the greater the price impact.
                </div>
              )}
              placement="right"
            >
              <div className={classes.priceImpact}>
                Price Impact
              </div>
            </Tooltip>
            <TypographyLabel className={classes.priceValue}>
              {
                !priceImpactPercent.isFinite()
                  ? '-'
                  : `${priceImpactPercent.decimalPlaces(1).toString(10)}%`
              }
            </TypographyLabel>
          </div>
        )}

      <div className={clsx(classes.bottomBox, commonClasses.mt3)}>
        {priceWarning ? (
          <Tooltip
            title={(
              <TypographyLabel className={clsx(classes.tooltip, classes.tooltipColor)}>
                No open orders for your selected token at the moment.
              </TypographyLabel>
            )}
            classes={{ tooltip: classes.tooltipContainer }}>
            <Button
              color="primary"
              className={clsx(classes.submitBtn, 'submitBtn')}
              disabled={priceWarning}>
              Buy &nbsp;<TokenSymbol denom={firstPriorityToken.denom} />
            </Button>
          </Tooltip>
          ) : (
          <Button
            color="primary"
            className={clsx(classes.submitBtn, 'submitBtn')}
            disabled={priceWarning || !hasMarkets}
            loading={loading}
            onClick={submitOrder}>
            Buy &nbsp;<TokenSymbol denom={firstPriorityToken.denom} />
          </Button>
        )}
        <TextButton
          label="What Are Network Fees"
          className={classes.link}
          svgClass={classes.svgIcon}
          endIcon={ExternalLinkIcon}
          onClick={goToFeesDocs}
          target="_blank"
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  adornedEnd: {
    marginTop: '0.375rem',
    paddingRight: 0,
  },
  alertRoot: {
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1.5, 2),
      '& .MuiAlert-action': {
        marginRight: 0,
        paddingLeft: 0,
      },
      '& .MuiAlert-icon': {
        marginRight: theme.spacing(2.5),
      },
    },
  },
  assetIcon: {
    height: '1.3rem',
    width: '1.3rem',
    paddingTop: '1.3rem',
    marginRight: theme.spacing(1),
  },
  priceImpactContainer: {
    marginBottom: '20px',
  },
  alertBoxClass: {
    marginTop: 6,
    '& p': {
      ...theme.typography.body4,
      fontWeight: 700,
    },
    '& svg': {
      height: 8,
      maxWidth: 8,
    },
  },
  cautionIconAlt: {
    maxHeight: '1.35rem',
    width: '1.35rem',
    '&.warning': {
      '& path:first-child': {
        fill: theme.palette.warning.main,
      },
    },
  },
  circleClass: {
    marginLeft: theme.spacing(1),
  },
  coinIcon: {
    height: '1.3rem',
    width: '1.3rem',
    // padding: theme.spacing(0, 1, 0, 0),
  },
  container: {
    height: '100%',
    maxHeight: '8.75em',
  },
  containerClass: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'unset',
      alignItems: 'center',
    },
  },
  dropdown: {
    ...StyleUtils.scrollBar(theme),
    transition: 'all 0.2s ease-in-out',
    backgroundColor: theme.palette.background.secondary,
    border: `1px solid ${theme.palette.divider}`,
    zIndex: 1000,
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    borderRadius: 2,
    maxHeight: 200,
    opacity: 0,
    overflow: 'overlay',
    '@supports not (overflow:overlay)': {
      overflow: 'auto',
    },
  },
  dropdownOpen: StyleUtils.dropdownAnimation(),
  dropdownContainer: {
    border: 'none',
  },
  errorMsg: {
    color: theme.palette.error.main,
  },
  field: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 1, 1, 0),
    border: 'none',
  },
  formInputWrapper: {
    marginTop: 0,
    paddingTop: 0,
  },
  input: {
    border: '1px solid transparent',
    color: theme.palette.text.disabled,
    borderRadius: 4,
  },
  inputBox: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.background.tertiary,
    color: theme.palette.text.disabled,
    borderRadius: 4,
    height: theme.spacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 5,
    '&:hover': {
      border: `1px solid ${theme.palette.text.disabled}`,
    },
  },
  inputReadonly: {
    paddingRight: theme.spacing(1),
    border: StyleUtils.borderStyle(theme.palette.input.outline.disabled, 1),
    '&:hover': {
      border: StyleUtils.borderStyle(theme.palette.input.outline.disabled, 1),
    },
  },
  inputPrice: {
    ...theme.typography.body3,
    border: 'none',
    color: theme.palette.text.primary,
    outline: 'none',
    margin: '0 auto',
    width: '100%',
    '&.disabled': {
      backgroundColor: theme.palette.background.disabled,
      color: theme.palette.text.disabled,
    },
    '& fieldset': {
      border: 'none',
      borderWidth: 0,
    },
  },
  priceLabel: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
  },
  capsLabel: {
    textTransform: 'uppercase',
  },
  priceValue: {
    ...theme.typography.body3,
  },
  priceImpact: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    borderBottom: '1px dashed',
    cursor: 'help',
  },
  subtitle: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    fontWeight: 700,
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body3,
      fontWeight: 700,
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  tokenBackdrop: {
    position: 'absolute',
    height: '100%',
    width: '23.5rem',
    top: -312,
    '&.hide': {
      display: 'none',
    },
  },
  tokensContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  tokenLabelName: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
  },
  newTokenName: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
  },
  tokenTitle: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
  },
  tokenSelect: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    borderColor: 'transparent !important',
    outline: 'none',
    padding: theme.spacing(0, 2, 0, 0),
    width: 'unset !important',
    '&:active, &:hover': {
      backgroundColor: 'transparent',
      borderColor: 'transparent !important',
    },
    '& svg': {
      minWidth: '1rem',
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: 'none',
    },
  },
  tooltip: {
    ...theme.typography.body4,
    color: theme.palette.text.primary,
    margin: theme.spacing(1),
    maxWidth: '13rem',
  },
  tooltipColor: {
    color: theme.palette.text.primary,
  },
  tooltipContainer: {
    zIndex: 4000,
    backgroundColor: theme.palette.background.primary,
  },
  selectTextAlt: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
  },
  searchIcon: {
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },
  bottomBox: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '80px',
    alignItems: 'stretch ',
    flexDirection: 'column',
    marginTop: theme.spacing(1.5),
  },
  link: {
    '& p': {
      ...theme.typography.body4,
      fontWeight: 700,
    },
  },
  submitBtn: {
    ...theme.typography.body2,
    width: '100%',
    cursor: 'pointer',
    fontWeight: 700,
    alignSelf: 'center',
    opacity: 1,
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.only('xs')]: {
      marginRight: 0,
    },
  },
  dropdownBox: {
    alignItems: 'center',
  },
  dropdownIcon: {
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 1),
    },
  },
  dropdownButton: {
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0, 2, 0, 0),
    },
  },
  search: {
    display: 'flex',
  },
  tableAssetIcon: {
    margin: theme.spacing(0, 1.5, 0, 0),
  },
  formInputContainer: {
    marginTop: theme.spacing(2),
  },
  tokenSymbolLabel: {
    ...theme.typography.body3,
    '& svg': {
      minWidth: 'unset !important',
    },
  },
  middleFeeBox: {
    padding: theme.spacing(1),
  },
  alertContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  }
}))

export default MiddleFeeBox
