import BigNumber from 'bignumber.js'
import { Insights } from 'carbon-js-sdk'

import { SimpleMap } from 'js/utils'

import { CarbonCreditsSeasonInfo, DemexPointsLeaderboard, LeaderboardPnl, LossProtectionRecord, MemeTradingRecord, SubCompetition, SwthRewardsRecord, UserDemexPoints } from './types'

import { RootState } from '../rootReducer'

export function getMarketCumulativeVolume(state: RootState): BigNumber {
  return state.competition.cumulativeVolume
}

export function getVolumeLeaderboardData(state: RootState): Insights.PerpsCompTradingVolumeEntry[] {
  return state.competition.volumeData
}

export function getPNLLeaderboardData(state: RootState): Insights.PNLLeaderboardEntry[] {
  return state.competition.pnlData
}

export function getLotteryLeaderboardData(state: RootState): Insights.LotteryLeaderboardEntry[] {
  return state.competition.lotteryData
}
export function getLeagueLeaderboardData(state: RootState): Insights.LeaderboardLeagueEntry[] {
  return state.competition.leagueData
}

export function getCosmosLeaderboardDataMap(state: RootState): SimpleMap<Insights.LeaderboardCosmosEntry> {
  return state.competition.cosmosDataSubCompetitionIdMap
}

export function getCurrentCompetition(state: RootState): Insights.CompetitionItem | undefined {
  return state.competition.currentCompetition
}

export function getPastCompetition(state: RootState): Insights.CompetitionItem | undefined {
  return state.competition.pastCompetition
}

export function getUpcomingCompetition(state: RootState): Insights.CompetitionItem | undefined {
  return state.competition.upcomingCompetition
}

export function getSubCompetitionsMap(state: RootState): SimpleMap<SubCompetition> {
  return state.competition.subCompetitionsMap
}

export function getIsRegistered(state: RootState): boolean {
  return state.competition.isRegistered
}

export function getRegisteredSubCompetitionId(state: RootState): string | undefined {
  return state.competition.registeredSubCompetitionId
}

export function getCarbonCreditsRootHash(state: RootState): string {
  return state.competition.carbonCreditsRootHash
}

export function getCarbonCreditsSeasonsInfo(state: RootState): CarbonCreditsSeasonInfo[] {
  return state.competition.carbonCreditsSeasons
}

export function getAccumulatedSeasonRewards(state: RootState): SwthRewardsRecord[] {
  return state.competition.accumulatedSeasonsRewards
}

export function getUnclaimedRewards(state: RootState): BigNumber | undefined {
  return state.competition.unclaimedRewards
}

export function getOngoingSeasonInfo(state: RootState): CarbonCreditsSeasonInfo | undefined {
  return state.competition.ongoingSeason
}

export function getTotalAccumulatedRewards(state: RootState): BigNumber | undefined {
  return state.competition.totalAccumulatedRewards
}

export function getLeaderboardPnl(state: RootState): LeaderboardPnl[] | undefined {
  return state.competition.leaderboardPnl
}

export function getMerkleTree(state: RootState): string[][] {
  return state.competition.merkleTree
}

export function getUserDemexPoints(state: RootState): UserDemexPoints | undefined {
  return state.competition.userDemexPoints
}

export function getDemexPointsLeaderboard(state: RootState): DemexPointsLeaderboard[] | undefined {
  return state.competition.demexPointsLeaderboard
}

export function getHideDemexPointsGuide(state: RootState): string[] {
  return state.competition.hideDemexPointsGuide
}

export function getUserTradingVolumeCampaign(state: RootState): BigNumber {
  return state.competition.userTradingVolumeCampaign
}

export function getLossProtectionLeaderboard(state: RootState): LossProtectionRecord[] | undefined {
  return state.competition.lossProtectionLeaderboard
}

export function getShowLossProtectionGuide(state: RootState): boolean {
  return state.competition.showLossProtectionGuide
}

export function getShowLossProtectionRegistration(state: RootState): boolean {
  return state.competition.showLossProtectionRegistration
}

export function getMemeTradingLeaderboard(state: RootState): MemeTradingRecord[] | undefined {
  return state.competition.memeTradingLeaderboard
}

export function getShowMemeTradingGuide(state: RootState): boolean {
  return state.competition.showMemeTradingGuide
}

export function getShowMemeTradingRegistration(state: RootState): boolean {
  return state.competition.showMemeTradingRegistration
}

export function getShowMantleTradingGuide(state: RootState): boolean {
  return state.competition.showMantleTradingGuide
}

export function getShowMantleTradingRegistration(state: RootState): boolean {
  return state.competition.showMantleTradingRegistration
}