import { O3Wallet } from 'carbon-js-sdk'
import { Network } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved


export type O3NetworkMap = {
  Neo: string
  Neo3: string
}

export const NetworksMap: O3NetworkMap = {
  Neo: 'MainNet',
  Neo3: 'N3MainNet',
}

interface O3WalletError {
  type: string
  description: string
}

export const detectO3Wallet = async (network: Network) => {
  try {
    const wallet = await O3Wallet.instance({ network })
    return await wallet.getNetworks()
  } catch (e) {
    if ((e as O3WalletError)?.type === 'NO_PROVIDER') {
      throw new Error('Unable to detect O3Wallet.')
    }
    throw e
  }
}



