import { BroadcastTxSyncResponse } from '@cosmjs/tendermint-rpc'
import BigNumber from 'bignumber.js'
import { CarbonSDK, CoinModule } from 'carbon-js-sdk'

import { FeatureType } from 'js/constants/notification'
import { BroadcastTxParams, WaitForBlockConfirmationCallback } from 'js/constants/tx'

import { makeCustomToast } from './notifications'

export function getTokensFromFaucet(sdk: CarbonSDK): Promise<Response> {
  if (!sdk.wallet?.bech32Address) return Promise.reject(new Error('Not logged in'))

  if (sdk.network !== CarbonSDK.Network.MainNet) {
    const body = JSON.stringify({
      address: sdk?.wallet?.bech32Address,
      coins: ['1000000000swth'],
    })
    return fetch(sdk.networkConfig.faucetUrl, { method: 'POST', body })
  }
  return Promise.reject(new Error('Wrong network'))
}

export const mintTokensFromCarbon = async (denom: string, sdk: CarbonSDK | undefined, feeDenom: string | undefined, mintTokenTx: (params: BroadcastTxParams) => Promise<BroadcastTxSyncResponse | WaitForBlockConfirmationCallback | null>): Promise<MintStatus> => {
  try {
    if (!sdk) throw new Error('sdk is not initialized!')
    const response = (await mintTokenTx({
      txTask: async () => {
        const tokenDp = sdk.token.getDecimals(denom) ?? 0
        const params: CoinModule.MintTokenParams = {
          amount: new BigNumber(10000).shiftedBy(tokenDp),
          denom,
        }
        return sdk.coin.mintToken(params, { feeDenom })
      },
      opts: {
        featureType: FeatureType.WALLET,
        waitForBlock: true,
      },
    })) as WaitForBlockConfirmationCallback | undefined
    if (response) {
      await response?.wait()
      const customToast = makeCustomToast({ featureType: FeatureType.WALLET })
      customToast.success({ title: 'Mint Success', message: 'Mint was successful. Please check your balance.' })
    }
    return { status: 'success' }
  } catch (err) {
    return { status: 'error', message: (err as Error).message }
  }
}

export type MintStatus = {
  status: 'success'
} | {
  status: 'error'
  message: string
}

export interface OnConnectCallback {
  onSuccess?: () => Promise<void> | void
  onFailure?: () => Promise<void> | void
}

