import { makeStyles } from '@material-ui/core'
import React from 'react'


import { FeatureType, ViewOrderHistoryLink } from 'js/constants/notification'
import { OrderNotificationProps, OrderScenario, getOrderCancelReason, getOrderNotificationType } from 'js/utils/notifications'

import Notification from './Notification'
import OrderMessage from './OrderMessage'

const OrderNotification: React.FC<OrderNotificationProps> = (props: OrderNotificationProps) => {
  const { scenario, cancelReason, orders } = props
  const classes = useStyles()


  const getTitle = (scenario: OrderScenario, cancelReasonTitle?: string): string => {
    switch (scenario) {
      case FeatureType.ORDER_CANCELLED:
      case FeatureType.ORDER_SYSTEM_CANCELLED: {
        return cancelReasonTitle ?? 'Order Cancelled'
      }
      case FeatureType.ORDER_FILLED:
      case FeatureType.ORDER_PARTIALLY_FILLED:
        return 'Trade Executed'
      default:
        return 'Order Placed'
    }
  }

  const getCancelReason = () => {
    if (orders.length === 1) {
      const { market, side, tif } = orders[0]
      return getOrderCancelReason(market, side, tif, cancelReason)
    } else return undefined
  }

  const getLink = () => {
    if (orders.length === 1) {
      return cancelReasonDetails?.link
    } else if (orders.length > 3) {
      return { ...ViewOrderHistoryLink, svgClass: classes.orderHistorySvg }
    } else return undefined
  }

  const cancelReasonDetails = getCancelReason()
  const link = getLink()
  const title = getTitle(scenario, cancelReasonDetails?.title)
  return (
    <Notification
      type={getOrderNotificationType(scenario, cancelReason)}
      featureType={scenario}
      title={title}
      message={<OrderMessage
        scenario={scenario}
        orders={orders}
        cancelReasonDetails={cancelReasonDetails?.message}
      />}
      link={link}
    />
  )
}

export default OrderNotification

const useStyles = makeStyles((theme) => ({
  orderHistorySvg: {
    width: '16px',
    height: '16px',
    maxWidth: 'unset',
    '& path': {
      strokeWidth: 2,
      stroke: theme.palette.text.demexSolid,
      fill: 'none !important',
    }
  }
})) 