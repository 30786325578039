import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { useCommonStyles } from "js/utils"

const ConvertTokenFallback: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  return (
    <div className={classes.root}>
      <div className={clsx(classes.topBar, commonClasses.alignItemsCenter)}>

      </div>
      <div className={clsx(classes.boxRow, commonClasses.justifyContentCenter)}>
        <div className={clsx(classes.boxContainer, classes.leftContainer)}>
          <LoadingSkeleton paddingBottom={48} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton />
        </div>
        <div className={clsx(classes.boxContainer, classes.rightContainer)}>
          <LoadingSkeleton paddingBottom={48} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton />
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.base,
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(6.5),
    },
  },
  topBar: {
    margin: theme.spacing(0, 4, 4),
    padding: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: '61px',
    [theme.breakpoints.down('xs')]: {
      height: '91px',
      margin: 0,
      padding: theme.spacing(2, 2, 3),
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  boxRow: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  boxContainer: {
    backgroundColor: theme.palette.background.primary,
    minHeight: '540px',
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
      padding: theme.spacing(3, 2),
    },
  },
  leftContainer: {
    width: '370px',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  rightContainer: {
    marginLeft: '1rem',
    width: '480px',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0, 0),
      width: '100%',
    },
  },
}))

export default ConvertTokenFallback