import { BigNumber } from 'bignumber.js'
import { TypeUtils } from 'carbon-js-sdk'
import { FeeStructure, FeeTier, StakeEquivalence } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/fee'
import { RecordOf } from 'immutable'


export interface FeeTiersStateProps {
    stakeEquivalences: StakeEquivalence[],
    feeStructures: FeeStructure[],
    validatorStakedSWTH: BigNumber,
    totalStakeEquivalence: BigNumber,
    denomToStakeEquivalenceTokenMap: DenomToStakeEquivalenceTokenMap,
    tokenNameToStakeEquivalenceRatioMap: TokenNameToStakeEquivalenceRatioMap,

    // user's vip tier info
    perpsVipTierInfo?: VipTierInfo
    spotVipTierInfo?: VipTierInfo
    oldPerpsVipTierInfo?: VipTierInfo
    oldSpotVipTierInfo?: VipTierInfo
    currentMarketVipTier?: VipTierInfo
    delegateSpotVipTierInfo: AddressVipTierInfoMap
    delegatePerpsVipTierInfo: AddressVipTierInfoMap
    vipTierInfoRequestAddresses: Set<string>
  }

export type FeeTiersState = RecordOf<FeeTiersStateProps>

export const FeeTiersActionTypes = {
  SET_STAKE_EQUIVALENCES: '@feeTiers/SET_STAKE_EQUIVALENCES',
  SET_FEE_STRUCTURES: '@feeTiers/SET_FEE_STRUCTURES',
  SET_VALIDATOR_STAKED_SWTH: '@feeTiers/SET_VALIDATOR_STAKED_SWTH',
  SET_TOTAL_STAKE_EQUIVALENCE: '@feeTiers/SET_TOTAL_STAKE_EQUIVALENCE',
  SET_DENOM_TO_STAKE_EQUIVALENCE_TOKEN_MAP: '@feeTiers/SET_DENOM_TO_STAKE_EQUIVALENCE_TOKEN_MAP',
  SET_TOKEN_NAME_TO_STAKE_EQUIVALENCE_RATIO_MAP: '@feeTiers/SET_TOKEN_NAME_TO_STAKE_EQUIVALENCE_RATIO_MAP',
  SET_PERPS_VIP_TIER_INFO: '@feeTiers/SET_PERPS_VIP_TIER_INFO',
  SET_SPOT_VIP_TIER_INFO: '@feeTiers/SET_SPOT_VIP_TIER_INFO',
  SET_OLD_PERPS_VIP_TIER_INFO: '@feeTiers/SET_OLD_PERPS_VIP_TIER_INFO',
  SET_OLD_SPOT_VIP_TIER_INFO: '@feeTiers/SET_OLD_SPOT_VIP_TIER_INFO',
  SET_CURRENT_MARKET_VIP_TIER: '@feeTiers/SET_CURRENT_MARKET_VIP_TIER',
  SET_DELEGATES_PERPS_VIP_TIER_INFO: '@feeTiers/SET_DELEGATES_PERPS_VIP_TIER_INFO',
  SET_DELEGATES_SPOT_VIP_TIER_INFO: '@feeTiers/SET_DELEGATES_SPOT_VIP_TIER_INFO',
  ADD_VIP_TIER_INFO_REQUEST_ADDRESSES: '@feeTiers/ADD_VIP_TIER_INFO_REQUEST_ADDRESSES',
  CALCULATE_ALL_VIP_TIER_INFO: '@feeTiers/CALCULATE_ALL_VIP_TIER_INFO',
}

export interface StakeEquivalenceToken {
  amount: BigNumber
  decimals: number
}

export type TokenNameToStakeEquivalenceRatioMap = TypeUtils.SimpleMap<BigNumber>
export type DenomToStakeEquivalenceTokenMap = TypeUtils.SimpleMap<StakeEquivalenceToken>

export interface VipTierInfo {
  currentVipTierName: string
  currentVipTierIndex: number
  progress: number
  swthRemainingToNextTier: BigNumber
  nextVipTierName: string
  nextVipTierIndex?: number
  feeTiers: FeeTier[]
  makerFee: string
  takerFee: string
}

export type AddressVipTierInfoMap = Map<string, VipTierInfo>
