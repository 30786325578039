import { Hidden, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { CarbonSDK, Insights } from 'carbon-js-sdk'
import { NetworkConfigs } from 'carbon-js-sdk/lib/constant/network' // eslint-disable-line import/no-unresolved
import clsx from 'clsx'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import LoadingIcon from 'js/components/Common/LoadingIcon'
import { useTaskSubscriber } from 'js/hooks'
import { getCustomNodes, getNet, getNodes, getRatingLatency } from 'js/state/modules/app/selectors'
import { CustomNodeItem } from 'js/state/modules/app/types'
import { useCommonStyles } from 'js/utils'
import { RatingLatencyObj } from 'js/utils/nodes'

import { ReactComponent as ArrowDown } from 'assets/ArrowDown.svg'
import { ReactComponent as ArrowUp } from 'assets/ArrowUp.svg'

import NodeRow from './NodeRow'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  handleSelectNode: (node: any) => void
  recommendedNode: Insights.NodeItem
  selectingNode: any
}

const NodeTable: React.FC<Props> = (props: Props) => {
  const { children, className, handleSelectNode, recommendedNode, selectingNode, ...rest } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const network = useSelector(getNet)
  const nodeList = useSelector(getNodes)
  const customNodes = useSelector(getCustomNodes)
  const ratingLatencies = useSelector(getRatingLatency)
  const [loading] = useTaskSubscriber('fetchNodes')
  const [sort, setSort] = useState({ prop: 'Rating', direction: 'desc' })

  const nodes: Insights.NodeItem[] = useMemo(() => {
    if (network !== CarbonSDK.Network.LocalHost) return nodeList
    const node = NetworkConfigs[CarbonSDK.Network.LocalHost]
    return [{
      nodeId: 'localhostNode',
      rpcUrl: node.tmRpcUrl,
      restUrl: node.restUrl,
      wsUrl: node.wsUrl,
      tmWsUrl: node.tmWsUrl,
      faucetUrl: node.faucetUrl,
      insightsUrl: node.insightsUrl,
      moniker: `${network} default node`,
      appBuild: network,
      lastupdated: '',
      rpcUptime: '100',
      wsUptime: '100',
      insightUptime: '100',
      appVersion: '',
      appCommit: '',
      latestBlockHeight: 0,
      creator: {
        name: '',
        telegram: '',
      },
    }]
  }, [network, nodeList])

  const sortFunc = (prop: string) => {
    if (prop === sort.prop) {
      setSort({
        ...sort,
        direction: sort.direction === 'asc' ? 'desc' : 'asc',
      })
      return
    }
    setSort({
      prop,
      direction: 'asc',
    })
  }

  const returnSortIcon = (sortKey?: string) => {
    if (sortKey && sortKey === sort.prop) {
      return sort.direction === 'asc'
        ? <ArrowUp className={clsx(classes.sortIcon, { single: sort.prop === sortKey })} />
        : <ArrowDown className={clsx(classes.sortIcon, { single: sort.prop === sortKey })} />
    }
    return undefined
  }

  const sortName = (nodes: any[], direction: string) => {
    return nodes.sort((nodeA: any, nodeB: any) => {
      const nameA = nodeA.moniker
      const nameB = nodeB.moniker
      if (nameA < nameB) {
        return direction === 'desc' ? 1 : -1
      }
      if (nameA > nameB) {
        return direction === 'desc' ? -1 : 1
      }
      return 0
    })
  }

  const sortRating = (nodes: Insights.NodeItem[], direction: string, ratingLats: RatingLatencyObj) => {
    return nodes.sort((nodeA: Insights.NodeItem, nodeB: Insights.NodeItem) => {
      const ratingA = ratingLats[nodeA.nodeId]?.rating ?? 0
      const ratingB = ratingLats[nodeB.nodeId]?.rating ?? 0
      return direction === 'desc' ? ratingB - ratingA : ratingA - ratingB
    })
  }

  const {
    sortedNodes,
    sortedCustomNodes,
  } = useMemo(() => {
    const filteredCustomNodes = customNodes.filter((node: CustomNodeItem) => node.appBuild === network)
    switch (sort.prop) {
      case 'Name':
        return {
          sortedNodes: sortName(nodes, sort.direction),
          sortedCustomNodes: sortName(filteredCustomNodes, sort.direction),
        }
      case 'Rating':
        return {
          sortedNodes: sortRating(nodes, sort.direction, ratingLatencies),
          sortedCustomNodes: filteredCustomNodes,
        }
      default:
        return {
          sortedNodes: nodes,
          sortedCustomNodes: filteredCustomNodes,
        }
    }
  }, [nodes, customNodes, sort, network, ratingLatencies])

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Table className={classes.table} size="small">
        <Hidden mdDown>
          <TableHead>
            <TableRow>
              <TableCell className={classes.filler} />
              <TableCell colSpan={2} className={classes.headerCell} onClick={() => sortFunc('Name')}>
                <div className={commonClasses.alignItemsCenter}>
                  <Typography className={classes.headerTitle}>
                    Node Name
                  </Typography>
                  {returnSortIcon('Name')}
                </div>
              </TableCell>
              <TableCell align="right" className={clsx(classes.headerCell, classes.nodeRating)} onClick={() => sortFunc('Rating')}>
                <div className={clsx(commonClasses.alignItemsCenter, commonClasses.justifyContentEnd)}>
                  <Typography className={classes.headerTitle}>
                    Node Rating
                  </Typography>
                  {returnSortIcon('Rating')}
                </div>
              </TableCell>
              <TableCell align="right" className={clsx(classes.headerCell, classes.noRightPadding)} />
              <TableCell className={classes.filler} />
            </TableRow>
          </TableHead>
        </Hidden>
        {loading ? (
          <TableBody>
            <TableRow className={classes.customHeader}>
              <TableCell colSpan={6}>
                <div className={classes.loading}>
                  <LoadingIcon size="2em" />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <React.Fragment>
            <TableBody>
              {sortedNodes.map((node, index) => (
                <NodeRow
                  node={node as any}
                  isCustom={false}
                  selectingNode={selectingNode}
                  recommendedNode={recommendedNode}
                  handleSelectNode={handleSelectNode}
                  key={node.nodeId}
                />
              ))}
            </TableBody>
            <TableBody>
              {
                sortedCustomNodes.map((node) => (
                  <NodeRow
                    node={node as any}
                    isCustom
                    selectingNode={selectingNode}
                    recommendedNode={recommendedNode}
                    handleSelectNode={handleSelectNode}
                    key={node.nodeId}
                  />
                ))
              }
            </TableBody>
          </React.Fragment>
        )}
      </Table>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    maxHeight: '21rem',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  filler: {
    minWidth: theme.spacing(2.5),
  },
  table: {
    border: 'none',
    '& tr:hover': {
      backgroundColor: 'transparent !important',
    },
    '& td': {
      paddingRight: 0,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '120px',
      }
    },
    '& th': {
      padding: theme.spacing(1, 0.5, 1, 0),
      '&$filler': {
        padding: 0,
      },
    },
    '& td, & th': {
      borderBottom: 'none',
      '&:nth-last-child(2)': {
        paddingLeft: theme.spacing(0.75),
      },
      '&:first-child, &:last-child': {
        width: 0,
        paddingRight: 0,
        minWidth: 0,
      }
    }
  },
  customHeader: {
    '& td': {
      ...theme.typography.body4,
      padding: 0,
      color: theme.palette.text.hint,
      borderBottom: 'none',
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3, 3, 4),
  },
  sortIcon: {
    marginLeft: theme.spacing(0.5),
    height: '1rem',
    '&.single': {
      '& path': {
        fill: theme.palette.text.primary,
      },
    },
  },
  headerCell: {
    color: theme.palette.text.hint,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  headerTitle: {
    ...theme.typography.body4,
    whiteSpace: 'nowrap',
  },
  noRightPadding: {
    paddingRight: `${theme.spacing(0.1)} !important`,
  },
}))

export default NodeTable
