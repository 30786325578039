import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'

interface CountdownDetails {
  estTimeLeft: number
  daysStr: string
  hoursStr: string
  minutesStr: string
  secondsStr: string
}

const useCountdown = (endDate: dayjs.Dayjs | undefined): CountdownDetails => {
  const [estTimeLeft, setEstTimeLeft] = useState<number>(endDate?.diff(dayjs.utc(), 's') ?? 0)
  const [daysStr, setDaysStr] = useState<string>('0')
  const [hoursStr, setHoursStr] = useState<string>('0')
  const [minutesStr, setMinutesStr] = useState<string>('0')
  const [secondsStr, setSecondsStr] = useState<string>('0')
  const timerId = useRef<number | null>(null)

  useEffect(() => {
    timerId.current = setInterval(() => {
      const timeLeft = endDate?.diff(dayjs.utc(), 's') ?? 0
      setEstTimeLeft(Math.max(timeLeft, 0))
    }, 500) as unknown as number

    return () => {
      if (timerId.current !== null) {
        clearInterval(timerId.current)
      }
    }
  }, [endDate])

  useEffect(() => {
    const days = Math.floor((estTimeLeft % 31536000) / 86400)
    const hours = Math.floor((estTimeLeft % 86400) / 3600)
    const minutes = Math.floor((estTimeLeft % 3600) / 60)
    const seconds = Math.floor(estTimeLeft % 60)

    if (days > 0 || Number(daysStr) > 0) {
      setDaysStr(days.toString())
    }
    if (hours > 0 || Number(hoursStr) > 0) {
      setHoursStr(hours.toString())
    }
    if (minutes > 0 || Number(minutesStr) > 0) {
      setMinutesStr(minutes.toString())
    }
    if (seconds > 0 || Number(secondsStr) > 0) {
      setSecondsStr(seconds.toString())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estTimeLeft])

  return {
    estTimeLeft,
    daysStr,
    hoursStr,
    minutesStr,
    secondsStr,
  }
}

export default useCountdown
