import { Record } from 'immutable'
import { cloneDeep } from 'lodash'
import { AnyAction } from 'redux'

import { safeParseStoredValue } from 'js/utils/localstorage'

import { NPSSurveyActionTypes, NPSSurveyStage, NPSSurveyState, NPSSurveyStateProps, NPSSurveyStatus } from './types'

import { AccountActionTypes } from '../account/types'

const defaultNPSSurveyStatus: NPSSurveyStatus = {
  npsSurveyCount: 0,
  lastSurveyDisplayAt: null,
}

const defaultValues: NPSSurveyStateProps = {
  showNPSSurveyDialog: false,
  npsSurveyStage: NPSSurveyStage.Rating,
  npsSurveyStatus: defaultNPSSurveyStatus,
}

export const DefaultInitialState: Record.Factory<NPSSurveyStateProps> = Record<NPSSurveyStateProps>(cloneDeep(defaultValues))
const defaultInitialState: NPSSurveyState = new DefaultInitialState()

export const NPSSurveyReducer = (
  state: NPSSurveyState = defaultInitialState,
  action: AnyAction,
): NPSSurveyState => {
  switch (action.type) {
    case AccountActionTypes.LOGOUT: {
      return state.set('showNPSSurveyDialog', false).set('npsSurveyStage', NPSSurveyStage.Rating)
    }
    case NPSSurveyActionTypes.SET_NPS_SURVEY_STATUS: {
      const { address, npsSurveyStatus } = action.payload
      if (!address) return state.set('npsSurveyStatus', defaultNPSSurveyStatus)

      const npsSurveyStatusByAddress = safeParseStoredValue(localStorage.getItem(NPSSurveyActionTypes.SET_NPS_SURVEY_STATUS), {})

      npsSurveyStatusByAddress[address] = {
        ...(npsSurveyStatusByAddress[address] ?? defaultNPSSurveyStatus),
        ...(npsSurveyStatus ?? {})
      }
      localStorage.setItem(NPSSurveyActionTypes.SET_NPS_SURVEY_STATUS, JSON.stringify(npsSurveyStatusByAddress))

      return state.set('npsSurveyStatus', npsSurveyStatusByAddress[address])
    }
    case NPSSurveyActionTypes.SET_OPEN_NPS_SURVEY_DIALOG:
      return state.set('showNPSSurveyDialog', true)
    case NPSSurveyActionTypes.SET_CLOSE_NPS_SURVEY_DIALOG:
      return state.set('showNPSSurveyDialog', false)
    case NPSSurveyActionTypes.SET_NPS_SURVEY_STAGE:
      return state.set('npsSurveyStage', action.payload)
    default:
      return state
  }
}
