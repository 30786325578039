import {
  createStyles, makeStyles, Theme,
} from '@material-ui/core'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import React from 'react'

interface Props {
  children: string
  className?: string
  variant?: string
  decimalPlaces?: number
  colorBySign?: boolean
  side?: string
  separateThousands?: boolean
  showDollarSign?: boolean
  showPercentage?: boolean
  showNegativeSign?: boolean
  showPositiveSign?: boolean
  roundMode?: BigNumber.RoundingMode

  overrideColourBuy?: boolean
  overrideColourSell?: boolean
  overrideColourNegative?: boolean
  overrideColourPostive?: boolean
  overrideColourZero?: boolean
}
export type WithNumber = Props

function withNumber<T extends {}>(Component: React.ComponentType<T>): React.FC<Props & T> {
  const WrappedComponent = (props: Props) => {
    const {
      children, className,
      variant, // remove variant from componentProps
      decimalPlaces, colorBySign, side, separateThousands = true,
      showDollarSign, showPercentage,
      showNegativeSign, showPositiveSign, roundMode = BigNumber.ROUND_UP,
      overrideColourBuy,
      overrideColourSell,
      overrideColourNegative,
      overrideColourPostive,
      overrideColourZero,
      ...componentProps
    } = props
    const classes = useStyles()

    let value: BigNumber = new BigNumber(children)

    const fmt: any = {
      decimalSeparator: '.',
      prefix: '',
    }

    if (separateThousands) {
      fmt.groupSeparator = ','
      fmt.groupSize = 3
    }

    if (showNegativeSign && value.lt(0)) {
      fmt.prefix += '-'
    }

    if (showPositiveSign && value.gt(0)) {
      fmt.prefix += '+'
    }

    if (showDollarSign) {
      fmt.prefix += '$'
    }

    if (showPercentage) {
      fmt.suffix = '%'
    }

    const classNames = clsx(
      className,
      classes.root,
      {
        buy: !overrideColourBuy && side === 'buy',
        sell: !overrideColourSell && side === 'sell',
        positive: !overrideColourPostive && colorBySign && value.gt(0),
        negative: !overrideColourNegative && colorBySign && value.lt(0),
        zero: !overrideColourZero && colorBySign && value.isZero(),
      },
    )

    // make value absolute because we manually prefix the negative sign before the dollar sign
    value = value.abs()
    const formattedValue: string = decimalPlaces === undefined
      ? value.toFormat(roundMode, fmt)
      : value.toFormat(decimalPlaces, roundMode, fmt)

    const component: any = React.createElement(
      Component,
      {
        ...componentProps,
        side,
        className: classNames,
      } as Props & T,
      value.isNaN() ? `${children}` : formattedValue,
    )

    return component
  }

  WrappedComponent.displayName = `withNumber(${Component.displayName})`
  return WrappedComponent
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&.buy': {
      color: theme.palette.buyText,
    },
    '&.sell': {
      color: theme.palette.sellText,
    },
    '&.positive': {
      color: theme.palette.buyText,
    },
    '&.negative': {
      color: theme.palette.sellText,
    },
    '&.zero': {
      color: theme.palette.text.secondary,
    },
  },
}))

export default withNumber
