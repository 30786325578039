import { Action } from 'redux'
import { action } from 'typesafe-actions'

import { SagaToastProps } from 'js/utils/notifications'

import { ActionType, RemoveTxLoading, SetTxLoading, Toast, UpdateTxPhase } from './types'

export interface AddBackgroundLoadingAction extends Action<ActionType.ADD_BACKGROUND_LOADING> {
  name: string
  uuid: string
}
export function addBackgroundLoading(name: string, uuid: string) {
  return {
    type: ActionType.ADD_BACKGROUND_LOADING,
    name,
    uuid,
  }
}

export interface RemoveBackgroundLoadingAction extends Action<ActionType.REMOVE_BACKGROUND_LOADING> {
  uuid: string
}
export function removeBackgroundLoading(uuid: string) {
  return {
    type: ActionType.REMOVE_BACKGROUND_LOADING,
    uuid,
  }
}

export interface StartCountdownAction extends Action<ActionType.START_COUNTDOWN> {
  start: boolean
}
export function startCountdown(start: boolean) {
  return {
    type: ActionType.START_COUNTDOWN,
    start,
  }
}

export interface SetWsInitAction extends Action<ActionType.SET_WS_INIT> {
  channel: string
}

export function setWsInit(channel: string) {
  return {
    type: ActionType.SET_WS_INIT,
    channel,
  }
}

export interface ResetWsInitAction extends Action<ActionType.RESET_WS_INIT> {
}

export function resetWsInit() {
  return {
    type: ActionType.RESET_WS_INIT,
  }
}

export interface RemoveWsInitAction extends Action<ActionType.REMOVE_WS_INIT> {
  channel: string
}

export function removeWsInit(channel: string) {
  return {
    type: ActionType.REMOVE_WS_INIT,
    channel,
  }
}

export const updateTxPhase = (payload: UpdateTxPhase) => action(
  ActionType.UPDATE_TRANSACTION_PHASE, payload,
)

export const setTxLoading = (payload: SetTxLoading) => action(
  ActionType.SET_TRANSACTION_LOADING, payload,
)

export const removeTxLoading = (payload: RemoveTxLoading) => action(
  ActionType.REMOVE_TRANSACTION_LOADING, payload,
)

export const confirmBroadcastedTxs = () => action(
  ActionType.CONFIRM_BROADCASTED_TRANSACTION,
)

export const addToast = (payload: Toast) => action(
  ActionType.ADD_TOAST, payload
)

export const queueToast = (payload: SagaToastProps) => action(
  ActionType.QUEUE_TOAST, payload
)

export const onCloseToast = (payload: string) => action(
  ActionType.ON_CLOSE_TOAST, payload
)

export const dismissAllToasts = () => action(
  ActionType.DISMISS_ALL_TOASTS
)

export const dismissAllActiveToasts = () => action(
  ActionType.DISMISS_ALL_ACTIVE_TOASTS
)

export const updatePendingToActiveToast = (payload: string) => action(
  ActionType.UPDATE_PENDING_TO_ACTIVE_TOAST, payload
)
