import { ClickAwayListener, Theme, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { ReactComponent as ContainedInfoIcon } from 'assets/Info.svg'

import SvgIcon from '../SvgIcon'
import V2Tooltip, { V2TooltipProps } from '../V2Tooltip/V2Tooltip'

interface Props {
  iconClass?: string
  tooltipContent: React.ReactElement | string
  tooltipIcon?: SVGComponent
  popoverProps?: Omit<V2TooltipProps, 'open'> // omit open so that it does not have to be declared
  popoverOpen?: boolean
}

const V2IconTooltip: React.FC<Props> = (props: Props) => {
  const { iconClass, tooltipContent, tooltipIcon, popoverProps = {}, popoverOpen } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handleToggle = (e: any) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(e.currentTarget)
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <SvgIcon
          className={clsx(classes.inflationaryIcon, iconClass)}
          onMouseEnter={(e: any) => setAnchorEl(e.currentTarget)}
          onMouseLeave={() => setAnchorEl(null)}
          onTouchStart={handleToggle}
          component={tooltipIcon ?? ContainedInfoIcon}
        />
        <V2Tooltip {...popoverProps} open={popoverOpen ?? Boolean(anchorEl)} anchorEl={popoverProps?.anchorEl ?? anchorEl}>
          {tooltipContent}
        </V2Tooltip>
      </div>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  inflationaryIcon: {
    cursor: 'help',
    width: '1rem',
    maxHeight: '1rem',
    marginLeft: theme.spacing(0.5),
    '&:hover path': {
      stroke: theme.palette.text.demexSolidHover,
    },
  },
}))

export default V2IconTooltip
