import { Theme } from '@material-ui/core'

import { PickersOverrides } from './types'

const MuiPickersBasePicker: PickersOverrides = (theme: Theme) => ({
  pickerView: {
    borderRadius: '4px',
    margin: '0 24px',
    width: 'calc(100% - 64px)',
    justifyContent: 'start',
    minHeight: 'unset',
    overflow: 'hidden',
    [theme.breakpoints.only('xs')]: {
      margin: '0 16px',
      width: 'calc(100% - 34px)',
      minWidth: '224px',
    },
    '@media (max-width: 320px)': {
      margin: '0 12px',
      width: 'calc(100% - 24px)',
    },
  },
})

export default MuiPickersBasePicker
