import { Network } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved

export const DEBOUNCE_DURATION_SECONDS = 2

interface Urls {
  external: string
  processor: string
}

export const AlertsApis: Record<Network, Urls> = {
  [Network.DevNet]: {
    external: 'https://dev-notification-api.dem.exchange',
    processor: 'https://dev-notification-processor-api.dem.exchange',
  },
  [Network.MainNet]: {
    external: 'https://notification-api.dem.exchange',
    processor: 'https://notification-processor-api.dem.exchange',
  },
  [Network.TestNet]: {
    external: 'https://test-notification-api.dem.exchange',
    processor: 'https://test-notification-processor-api.dem.exchange',
  },
  [Network.LocalHost]: {
    external: 'http://localhost:9791',
    processor: 'http://localhost:9792',
  }
}











