import { CarbonWallet, OrderModule } from 'carbon-js-sdk'
import { ReactText } from 'react'
import { ToastContentProps, ToastOptions } from 'react-toastify'

import {
  ActionProps,
  BtnLinkProps,
  CustomMessage,
  DEFAULT_ERROR_NOTIFICATION_DURATION_SECONDS,
  DEFAULT_NOTIFICATION_DURATION_SECONDS,
  DEFAULT_SUCCESS_NOTIFICATION_DURATION_SECONDS,
  DynamicCustomMessageCancelReasonCode,
  FeatureType,
  NotificationType,
  OrderCancelCustomMessages,
  OrderCancelReasonCode,
} from 'js/constants/notification'

export interface NotificationProps extends ToastContentProps {
  type: NotificationType,
  title?: string,
  featureType?: FeatureType,
  message?: React.ReactNode,
  body?: React.ReactNode,
  action?: ActionProps | undefined
  link?: BtnLinkProps | undefined
  replaceGroupedToken?: {
    denom: string,
    symbol: string,
  }
  dismissToastId?: string
}

export interface GenericToastNotificationOptions {
  featureType?: FeatureType,
}

export interface OrderNotificationProps {
  scenario: OrderScenario,
  cancelReason?: number
  orders: Order[]
}

export interface Order {
  market: MarketDetails,
  side: OrderModule.OrderSide,
  tradeAction?: string,
  triggerDetails?: TriggerDetails,
  execution?: ExecutionStatus,
  tif?: OrderModule.TimeInForce,
}

export interface MarketDetails {
  name: string,
  quote: string,
  base: string,
  isFutures: boolean,
}
export interface TriggerDetails {
  type: OrderModule.TriggerType,
  price: string,
  side: OrderModule.OrderType,
}
export interface ExecutionStatus {
  filled: string,
  remaining?: string,
}

export type OrderScenario = FeatureType.ORDER_PLACED | FeatureType.ORDER_CANCELLED | FeatureType.ORDER_FILLED | FeatureType.ORDER_PARTIALLY_FILLED | FeatureType.ORDER_SYSTEM_CANCELLED

export interface CustomToastProps {
  options: NotificationProps,
  userConfig?: ToastOptions
}

export enum ToastType {
  DEFAULT = 'default',
  TRANSACTION = 'transaction',
  TRANSACTION_LINK = 'transaction_link',
  ORDER = 'ORDER'
}

export type SagaToastProps =
| { type: ToastType.DEFAULT, options: NotificationProps, userConfig?: ToastOptions }
| { type: ToastType.TRANSACTION, options: NotificationProps, userConfig?: ToastOptions }
| { type: ToastType.TRANSACTION_LINK, options: NotificationProps, txExplorerLink: string, userConfig?: ToastOptions }
| { type: ToastType.ORDER, options: OrderNotificationProps, userConfig?: ToastOptions };


export const getOrderNotificationType = (scenario: OrderScenario, cancelReason?: number) => {
  switch (scenario) {
    case FeatureType.ORDER_CANCELLED:
    case FeatureType.ORDER_SYSTEM_CANCELLED:
      return cancelReason === OrderCancelReasonCode.UserInitiated ? NotificationType.SUCCESS : NotificationType.ERROR
    case FeatureType.ORDER_FILLED:
    case FeatureType.ORDER_PLACED:
    case FeatureType.ORDER_PARTIALLY_FILLED:
      return NotificationType.SUCCESS
    default:
      return NotificationType.INFO
  }
}

export const getOrderCancelReason = (market: MarketDetails, side: OrderModule.OrderSide, tif?: OrderModule.TimeInForce, cancelReasonNumber?: number) => {
  if (!cancelReasonNumber && cancelReasonNumber !== 0) return undefined
  if (DynamicCustomMessageCancelReasonCode.includes(cancelReasonNumber)) return getOrderCancelDynamicReason(market, side, tif, cancelReasonNumber)
  for (const [reasonCode, customMessage] of Object.entries(OrderCancelCustomMessages)) {
    if (reasonCode === cancelReasonNumber.toString()) return customMessage
  }
  return undefined
}

const getOrderCancelDynamicReason = (market: MarketDetails, side: OrderModule.OrderSide, tif: OrderModule.TimeInForce | undefined, cancelReasonNumber: OrderCancelReasonCode): CustomMessage | undefined => {
  switch (cancelReasonNumber) {
    case OrderCancelReasonCode.InsufficientMargin: {
      const isFutureMarket = market.isFutures
      return {
        title: 'Order Rejected',
        message: `You have insufficient balance to place this order. ${isFutureMarket ? 'Please free up more margin.' : `Please deposit more ${side === 'buy' ? market.base : market.quote}`}`,
      }
    }
    case OrderCancelReasonCode.TIFExpired: {
      return tif === 'fok'
        ? { title: 'Order Rejected', message: 'Your fill-or-kill order could not be fully filled immediately.' }
        : { title: 'Order Cancelled', message: 'Your order could not be fully filled immediately and the remaining quantity was cancelled.' }
    }
  }
  return undefined
}


interface SagaToastHandler {
  (
    props: SagaToastProps
  ): ReactText
}

interface ToastHandler {
  (
    options: NotificationProps,
    userConfig?: ToastOptions,
  ): ReactText
}

interface OrderToastHandler {
  (
    options: OrderNotificationProps,
    userConfig?: ToastOptions,
  ): ReactText
}

interface TxLinkToastHandler {
  (
    options: NotificationProps,
    txExplorerLink: string,
    userConfig?: ToastOptions,
  ): ReactText
}

export const assignDismissToastHandler = (handler: ToastHandler) => {
  sendDismissToast = handler
}

let sendDismissToast: ToastHandler
export const dismissToast = (userConfig?: ToastOptions): ReactText => {
  return sendDismissToast({ type: NotificationType.INFO }, userConfig)
}

export const assignToastHandler = (handler: ToastHandler) => {
  sendToast = handler
}

export const assignSagaToastHandler = (handler: SagaToastHandler) => {
  sendSagaToast = handler
}


let sendSagaToast: SagaToastHandler
export let sendToast: ToastHandler
export const customToast: { [type in NotificationType]: (options: Omit<NotificationProps, 'type'>, userConfig?: ToastOptions) => ReactText } = {
  error: (
    options: Omit<NotificationProps, 'type'>,
    userConfig?: ToastOptions,
  ): ReactText => {
    return sendSagaToast?.({ type: ToastType.DEFAULT, options: { ...options, type: NotificationType.ERROR }, userConfig })
  },
  success: (
    options: Omit<NotificationProps, 'type'>,
    userConfig?: ToastOptions,
  ): ReactText => {
    return sendSagaToast?.({type: ToastType.DEFAULT, options: { ...options, type: NotificationType.SUCCESS }, userConfig})
  },
  info: (
    options: Omit<NotificationProps, 'type'>,
    userConfig?: ToastOptions,
  ): ReactText => {
    return sendSagaToast?.({type: ToastType.DEFAULT, options: { ...options, type: NotificationType.INFO }, userConfig})
  },
  warning: (
    options: Omit<NotificationProps, 'type'>,
    userConfig?: ToastOptions,
  ): ReactText => {
    return sendSagaToast?.({type: ToastType.DEFAULT, options: { ...options, type: NotificationType.WARNING }, userConfig})
  },
  loading: (
    options: Omit<NotificationProps, 'type'>,
    userConfig?: ToastOptions,
  ): ReactText => {
    return sendSagaToast?.({type: ToastType.DEFAULT, options: { ...options, type: NotificationType.LOADING }, userConfig})
  },
}


export const makeCustomToast = (defaultOptions: GenericToastNotificationOptions): {
  [type in NotificationType]: (options: Omit<NotificationProps, 'type'>, userConfig?: ToastOptions) => ReactText;
} => {
  return {
    error: (
      options: Omit<NotificationProps, 'type'>,
      userConfig?: ToastOptions,
    ): ReactText => {
      return sendSagaToast?.({type: ToastType.DEFAULT, options: { ...defaultOptions, ...options, type: NotificationType.ERROR }, userConfig})
    },
    success: (
      options: Omit<NotificationProps, 'type'>,
      userConfig?: ToastOptions,
    ): ReactText => {
      return sendSagaToast?.({type: ToastType.DEFAULT, options: { ...defaultOptions, ...options, type: NotificationType.SUCCESS }, userConfig})
    },
    info: (
      options: Omit<NotificationProps, 'type'>,
      userConfig?: ToastOptions,
    ): ReactText => {
      return sendSagaToast?.({type: ToastType.DEFAULT, options: { ...defaultOptions, ...options, type: NotificationType.INFO }, userConfig})
    },
    warning: (
      options: Omit<NotificationProps, 'type'>,
      userConfig?: ToastOptions,
    ): ReactText => {
      return sendSagaToast?.({type: ToastType.DEFAULT, options: { ...defaultOptions, ...options, type: NotificationType.WARNING }, userConfig})
    },
    loading: (
      options: Omit<NotificationProps, 'type'>,
      userConfig?: ToastOptions,
    ): ReactText => {
      return sendSagaToast?.({type: ToastType.DEFAULT, options: { ...defaultOptions, ...options, type: NotificationType.LOADING }, userConfig})
    },
  }
}

export const assignTxToastHandler = (handler: ToastHandler) => {
  sendTxToast = handler
}

export let sendTxToast: ToastHandler
export const customTxToast = (
  options: NotificationProps,
  userConfig?: ToastOptions,
): ReactText => {
  return sendSagaToast?.({type: ToastType.TRANSACTION, options, userConfig})
}


export const assignOrderToastHandler = (handler: OrderToastHandler) => {
  sendOrderToast = handler
}

export let sendOrderToast: OrderToastHandler
export const customOrderToast = (
  options: OrderNotificationProps,
  userConfig?: ToastOptions,
): ReactText => {
  return sendSagaToast?.({type: ToastType.ORDER, options, userConfig})
}


export const assignTxLinkToastHandler = (handler: TxLinkToastHandler) => {
  sendTxLinkToast = handler
}

export let sendTxLinkToast: TxLinkToastHandler
export const customToastTxLink = (
  options: NotificationProps,
  txExplorerLink: string,
  userConfig?: ToastOptions,
): ReactText => {
  return sendSagaToast?.({ type: ToastType.TRANSACTION_LINK, options, txExplorerLink, userConfig })
}

export const readUserNotification = (userNotif: UserNotification, wallet?: CarbonWallet) => {
  if (!wallet) return
  const readNotifs = JSON.parse(window.localStorage.getItem('readNotifications') ?? '{}')
  if (!readNotifs[wallet.bech32Address]) {
    readNotifs[wallet.bech32Address] = [userNotif.id]
  } else if (!readNotifs[wallet.bech32Address].includes(userNotif.id)) {
    readNotifs[wallet.bech32Address].push(userNotif.id)
  }
  localStorage.setItem('readNotifications', JSON.stringify(readNotifs))
}

export const newNotifications = (userNotifs: UserNotification[], wallet?: CarbonWallet): UserNotification[] => {
  if (!wallet?.bech32Address) return []
  const readNotifs = JSON.parse(window.localStorage.getItem('readNotifications') ?? '{}')
  const userReadNotifs = readNotifs[wallet.bech32Address] ?? [] as string[]
  return userNotifs.filter((notif) => {
    return !userReadNotifs.some((readNotifId: string) => {
      return notif.id === readNotifId
    })
  })
}

export interface UserNotification {
  id: string
  title: string
  message: string
  target: string[]
}

export const getNotificationConfig = (userConfig: ToastOptions | undefined = {}, notificationType: NotificationType): ToastOptions => {
  let toastDuration: number
  switch (notificationType) {
    case NotificationType.ERROR:
      toastDuration = DEFAULT_ERROR_NOTIFICATION_DURATION_SECONDS
      break
    case NotificationType.SUCCESS:
      toastDuration = DEFAULT_SUCCESS_NOTIFICATION_DURATION_SECONDS
      break
    default:
      toastDuration = DEFAULT_NOTIFICATION_DURATION_SECONDS
      break
  }

  return {
    className: `toast-${notificationType ?? 'info'}`,
    autoClose: toastDuration * 1000,
    ...userConfig,
  }
}

export const getTxNotificationConfig = (userConfig: ToastOptions | undefined = {}, notificationType: NotificationType): ToastOptions => {
  return notificationType === NotificationType.LOADING
    ? {
      className: `toast-${notificationType ?? 'info'}`,
      autoClose: false,
      progress: 1,
      ...userConfig,
    } : getNotificationConfig(userConfig, notificationType)
}

