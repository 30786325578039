import { Grid, makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { StyleUtils, useCommonStyles } from "js/utils"

const LiquidationDetailsFallback: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  return (
    <div className={clsx(commonClasses.justifyContentCenter, classes.root)}>
      <Grid className={classes.grid} container direction="row">
        <Grid item xs={12}>
          <div className={classes.containerBox}>
            <LoadingSkeleton width={180} paddingBottom={48} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.base,
    minHeight: 'calc(100vh - 48px - 28px)',
    width: '100%',
    padding: '2rem',
    '@media (min-width: 2560px)': {
      width: '1800px',
      margin: '0 auto',
    },
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      minHeight: 'unset',
      flex: 1,
    },
  },
  grid: {
    maxWidth: '100%',
    margin: theme.spacing(2, 'auto'),
    columnGap: '4px',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down('md')]: {
      gap: '8px',
      columnGap: '0px',
      margin: theme.spacing(0, 'auto'),
    },
  },
  containerBox: {
    ...StyleUtils.scrollBar(theme),
    backgroundColor: theme.palette.background.primary,
    borderRadius: 4,
    padding: theme.spacing(3, 4),
    overflow: 'overlay',
    margin: theme.spacing(1, 0),
    minHeight: '600px',
    '@supports not (overflow:overlay)': {
      overflow: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2.5),
    },
  }
}))

export default LiquidationDetailsFallback