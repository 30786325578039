import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { Store, applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import { Saga } from 'redux-saga'

import localStorageMiddleware, { loadLocalStorageState } from './middlewares/localStorage'
import sagaMiddleware from './middlewares/sagas'
import sessionStorageMiddleware, { loadSessionStorageState } from './middlewares/sessionStorage'
import { ActionType } from './modules/loadingTask/types'
import rootReducer, { RootState } from './modules/rootReducer'

(window as any).setReduxLog = (enabled: boolean) => {
  if (enabled) {
    localStorage.setItem('reduxLog', 'enable')
  } else {
    localStorage.removeItem('reduxLog')
  }
}

const tStart = new Date().getTime();
(window as any).elapsedTime = (from = tStart) => {
  return new Date().getTime() - from
}

const configureStore = (): Store<RootState> => {
  const middlewares = [
    sessionStorageMiddleware,
    localStorageMiddleware,
    routerMiddleware(history),
    sagaMiddleware,
  ]

  if (localStorage.getItem('reduxLog') === 'enable') {
    const ignoredActions = [
      ActionType.ADD_BACKGROUND_LOADING,
      ActionType.REMOVE_BACKGROUND_LOADING,
    ]

    middlewares.push(createLogger({
      predicate: (state, action) => !ignoredActions.includes(action.type),
    }))
  }

  const preloadedState: RootState = loadSessionStorageState(
    loadLocalStorageState(),
  )

  const store = createStore(
    rootReducer(history), // Root reducer with router state
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  )

  return store
}

let isSagaStarted = false
export const startRootSaga = (rootSaga: Saga) => {
  if (!isSagaStarted) {
    sagaMiddleware.run(rootSaga)
    isSagaStarted = true
  }
}
export const history = createBrowserHistory()
export const store = configureStore()
