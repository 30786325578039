import { ButtonProps, createStyles, makeStyles, TableContainer } from '@material-ui/core'
import clsx from 'clsx'
import React, { Fragment, useMemo } from 'react'
import {
  Cell,
  Column,
  HeaderGroup, Row,
  useAsyncDebounce,
  useGlobalFilter, useSortBy,
  useTable,
} from 'react-table'

import Button from 'js/components/Common/Button'
import EmptyFiller from 'js/components/Common/EmptyFiller'
import IconButton from 'js/components/Common/IconButton'
import LoadingIcon from 'js/components/Common/LoadingIcon'
import RenderGuard from 'js/components/Common/RenderGuard'
import SvgIcon from 'js/components/Common/SvgIcon'
import Tooltip from 'js/components/Common/Tooltip'
import { switcheo } from 'js/theme/ThemeV2/palettes/colors'
import { StyleUtils, useCommonStyles } from 'js/utils/styles'

import { ReactComponent as ArrowDown } from 'assets/ArrowDown.svg'
import { ReactComponent as ArrowUp } from 'assets/ArrowUp.svg'
import { ReactComponent as CaretLeft } from 'assets/CaretLeftPlain.svg'
import { ReactComponent as CaretRight } from 'assets/CaretRightPlain.svg'
import { ReactComponent as Search } from 'assets/Search.svg'

interface TableProps<T extends object = object> {
  actionsDivClass?: string
  className?: string
  rowActionButtonClass?: string
  columns: Table.ExtendedColumn<T>[]
  data: Iterable<T> | Array<T>
  rowActions?: ReadonlyArray<RowAction>
  hasSearch?: boolean
  emptyRowsHelperText?: any
  sortByIDs?: Array<{ id: string, desc: boolean }>
  tableClass?: string
  nextPage?: () => void
  previousPage?: () => void
  canNextPage?: boolean
  canPreviousPage?: boolean
  filterComponent?: React.ReactNode
  loading?: boolean
  paginationClass?: string,
  pageBtnClass?: string,
  onClickRow?: (value: any) => void
  showHeader?: boolean
  classes?: TableClasses,
}

interface TableClasses {
  tableContainer?: string
  tableHeader?: string
}

export interface RowAction extends ButtonProps {
  label: any
  shouldHide?: (rowObject: any) => boolean
  handler: (rowObject: any) => void
}

interface ExtendedHeader<D extends object = object> extends HeaderGroup<D> {
  getSortByToggleProps: any
  canSort: boolean
  isSorted: boolean
  isSortedDesc: boolean
}

function Table<T extends object = object>({
  columns, data, rowActions, hasSearch = false,
  emptyRowsHelperText = 'No data found',
  className, sortByIDs, filterComponent, loading = false,
  rowActionButtonClass, tableClass, actionsDivClass,
  previousPage, nextPage, canNextPage, canPreviousPage,
  paginationClass, pageBtnClass, onClickRow, showHeader = true,
  classes: customClasses = {},
}: TableProps<T>) {
  let tableOptions = {
    columns,
    data,
  } as any

  if (sortByIDs) {
    tableOptions = {
      ...tableOptions,
      initialState: {
        sortBy: sortByIDs,
      },
    }
  }
  const {
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    tableOptions,
    useGlobalFilter,
    useSortBy,
  ) as any

  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const getTooltipContent = useMemo(
    () => (columnID: string): any => {
      const col = columns.find((c) => {
        if (typeof c.accessor === 'function') {
          return c.Header === columnID
        }
        return c.accessor === columnID
      })
      return col?.headerTooltipContent ?? null
    },
    [columns],
  )

  const getCellTooltip = useMemo(
    () => (cell: Cell): any => {
      const col = columns.find((c) => {
        if (typeof c.accessor === 'function') {
          return c.Header === cell.column.id
        }
        return c.accessor === cell.column.id
      })
      if ((col as any)?.cellTooltip) {
        return (col as any)?.cellTooltip(cell)
      }
      return null
    },
    [columns],
  )

  const handleClickRow = (value: any) => {
    if (typeof onClickRow === 'function') {
      onClickRow(value)
    }
  }

  return (
    <div className={clsx(classes.container, className)}>
      {hasSearch && (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={(state as any).globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      {filterComponent && (filterComponent)}
      <TableContainer className={customClasses.tableContainer}>
        <table role="table" className={clsx(classes.table, tableClass)}>
          {showHeader && (
            <thead>
              {
                headerGroups.map((headerGroup: any) => {
                  const { headers, getHeaderGroupProps } = headerGroup
                  const { key, role } = getHeaderGroupProps()
                  return (
                    <tr key={key} role={role}>
                      {
                        headers.map((header: ExtendedHeader<any>) => {
                          const { canSort, isSorted, isSortedDesc } = header
                          const {
                            colSpan, key: headerKey, onClick, role: headerRole,
                          } = header.getHeaderProps(header.getSortByToggleProps()) as any
                          const tooltipContent = getTooltipContent(header.id)
                          const hasTooltip: boolean = !!tooltipContent
                          return (
                            <th
                              colSpan={colSpan}
                              key={headerKey}
                              role={headerRole}
                              onClick={onClick}
                            >
                              <div className={clsx(classes.tableHeaderContainer, { canSort }, customClasses.tableHeader)}>
                                <div className={clsx(classes.tableHeader, { hasTooltip })}>
                                  {
                                    hasTooltip
                                      ? (
                                        <Tooltip tooltipContent={tooltipContent} direction="top">
                                          {
                                            header.render('Header')
                                          }
                                        </Tooltip>
                                      )
                                      : header.render('Header')
                                  }
                                </div>
                                <SortDirectionIcon
                                  isSorted={isSorted}
                                  canSort={canSort}
                                  isSortedDesc={isSortedDesc}
                                />
                              </div>
                            </th>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
            </thead>
          )}
          <tbody>
            <RenderGuard renderIf={!loading && rows.length === 0}>
              <tr>
                <td colSpan={columns.length}>
                  <EmptyFiller
                    className={classes.filler}
                    helperText={emptyRowsHelperText}
                  />
                </td>
              </tr>
            </RenderGuard>

            <RenderGuard renderIf={!loading && rows.length > 0}>
              {
                rows.map(
                  (row: Row, i: number) => {
                    prepareRow(row)
                    const { cells, original } = row
                    const { key, role } = row.getRowProps()
                    return (
                      <tr key={key} role={role} onClick={() => handleClickRow(row.original)}>
                        {
                          cells.map((cell: Cell) => {
                            const { getCellProps, render, column } = cell
                            const { key: cellKey, role: cellRole } = getCellProps()
                            const cellTooltipContent = getCellTooltip(cell)
                            const cellHasTooltip: boolean = !!cellTooltipContent
                            return (
                              <td key={cellKey} role={cellRole}>
                                {
                                  cellHasTooltip
                                    ? (
                                      <Tooltip
                                        childClass={classes.childClass}
                                        tooltipContent={cellTooltipContent}
                                        direction="left"
                                      >
                                        {
                                          column.id === 'tableActions'
                                            ? (
                                              <RowActions
                                                actionsDivClass={actionsDivClass}
                                                rowData={original}
                                                rowActions={rowActions}
                                                className={rowActionButtonClass}
                                              />
                                            )
                                            : render('Cell')
                                        }
                                      </Tooltip>
                                    ) : (
                                      <Fragment>
                                        {
                                          column.id === 'tableActions'
                                            ? (
                                              <RowActions
                                                actionsDivClass={actionsDivClass}
                                                rowData={original}
                                                rowActions={rowActions}
                                                className={rowActionButtonClass}
                                              />
                                            )
                                            : render('Cell')
                                        }
                                      </Fragment>
                                    )
                                }
                              </td>
                            )
                          })
                        }
                      </tr>
                    )
                  },
                )
              }
            </RenderGuard>
            <RenderGuard renderIf={loading}>
              <tr>
                <td colSpan={columns.length}>
                  <div className={clsx(classes.loadingDiv, commonClasses.alignItemsCenter, commonClasses.justifyContentCenter)}
                  >
                    <LoadingIcon size="2.5rem" />
                  </div>
                </td>
              </tr>
            </RenderGuard>
          </tbody>
        </table>
      </TableContainer>
      {(!!previousPage || !!nextPage) && (
        <div className={clsx(classes.pagination, paginationClass, commonClasses.justifyContentEnd)}>
          {!!previousPage && (
            <IconButton className={clsx(classes.roundBtn, pageBtnClass)} onClick={previousPage} disabled={!canPreviousPage}>
              <SvgIcon className={classes.arrowSvg} component={CaretLeft} />
            </IconButton>
          )}

          <div className={classes.marginRight4} />

          {!!nextPage && (
            <IconButton className={clsx(classes.roundBtn, pageBtnClass)} onClick={nextPage} disabled={!canNextPage}>
              <SvgIcon className={classes.arrowSvg} component={CaretRight} />
            </IconButton>
          )}
        </div>
      )}
    </div>
  )
}

interface GlobalFilterProps {
  preGlobalFilteredRows: any[]
  globalFilter: any
  setGlobalFilter: any
}

const GlobalFilter = (props: GlobalFilterProps) => {
  const { globalFilter, setGlobalFilter } = props
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((v) => {
    setGlobalFilter(v || undefined)
  }, 200)
  const classes = useStyles()

  return (
    <div className={classes.filterContainer}>
      <div className={classes.searchIconContainer}>
        <SvgIcon className={classes.searchIcon} component={Search} />
      </div>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder="Search"
        className={classes.filterInput}
      />
    </div>
  )
}

interface SortDirectionIconProps {
  canSort: boolean
  isSorted: boolean
  isSortedDesc: boolean
}

const SortDirectionIcon = (props: SortDirectionIconProps) => {
  const classes = useStyles()
  const { canSort, isSorted, isSortedDesc } = props
  if (!canSort) {
    return null
  }

  let SortIcon
  if (isSorted) {
    SortIcon = isSortedDesc ? ArrowDown : ArrowUp
  }

  return (
    <div>
      {SortIcon && (
        <SvgIcon
          className={classes.sortIcon}
          component={SortIcon}
        />
      )}
    </div>
  )
}

interface RowActionsProps {
  actionsDivClass?: string
  rowData: any
  rowActions?: ReadonlyArray<RowAction>
  className?: string
}

const RowActions = ({
  actionsDivClass, rowData, rowActions, className,
}: RowActionsProps) => {
  const classes = useStyles()
  if (!rowActions) return null
  return (
    <div className={clsx(classes.rowActionsContainer, actionsDivClass)}>
      {
        rowActions.map((rowAction: RowAction) => {
          const { label, handler, shouldHide, ...rest } = rowAction
          if (shouldHide?.(rowData)) {
            return null
          }
          return (
            <Button
              {...rest}
              useOriginalContained              
              className={clsx(className, classes.rowActionButton)}
              onClick={() => handler(rowData)}
              key={`rowActionButton-${label}`}
            >
              {label}
            </Button>
          )
        })
      }
    </div>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  arrowSvg: {
    width: '0.6rem',
    maxHeight: '0.6rem',
  },
  childClass: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  container: {
    borderRadius: '2px',
    border: StyleUtils.borderStyle(theme.palette.divider),
  },
  loadingDiv: {
    padding: theme.spacing(12, 6),
  },
  pagination: {
    borderTop: StyleUtils.borderStyle(theme.palette.divider),
    padding: theme.spacing(4, 8),
  },
  table: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
    width: '100%',
    borderCollapse: 'collapse',
    '& > tbody > tr:not(:last-child)': {
      borderBottom: StyleUtils.borderStyle(theme.palette.divider),
    },
    '& td, & th': {
      padding: '1rem 1.1rem',
      textAlign: 'right',
      '&:first-child': {
        textAlign: 'left',
        paddingLeft: '2rem',
      },
      '&:last-child': {
        paddingRight: '2rem',
        whiteSpace: 'pre',
      },
    },
    '& th': {
      borderBottom: StyleUtils.borderStyle(theme.palette.divider),
      padding: '0.7rem 1.1rem',
    },
  },
  filterContainer: {
    display: 'flex',
    width: '100%',
    borderBottom: StyleUtils.borderStyle(theme.palette.divider),
  },
  filterInput: {
    fontFamily: 'Roboto, sans-serif',
    flexGrow: 1,
    border: 0,
    padding: '1rem',
    background: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    '&:focus': {
      outline: 0,
    },
    '&::placeholder': {
      color: theme.palette.text.hint,
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 'normal',
    },
  },
  roundBtn: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
    width: '1.5rem',
    height: '1.5rem',
    '& path': {
      fill: theme.palette.secondary.main,
    },
    '&.Mui-disabled': {
      border: `1px solid ${switcheo.mono[400]}`,
      '& path': {
        fill: switcheo.mono[500],
      },
    },
  },
  searchIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    paddingLeft: '2rem',
    paddingRight: '0',
  },
  searchIcon: {
    height: '0.875rem',
    width: '0.875rem',
  },
  tableHeaderContainer: {
    ...theme.typography.body3,
    color: theme.palette.text.hint,
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '&.canSort': {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
  },
  tableHeader: {
    '&.hasTooltip': {
      borderBottom: '1px dotted',
    },
  },
  sortIcon: {
    marginLeft: '0.3rem',
    fontSize: '0.5625rem',
    '& path': {
      fill: theme.palette.text.primary,
    },
  },
  rowActionsContainer: {
    '& > :first-child': {
      marginLeft: '0',
    },
    '& > :last-child': {
      marginRight: '0',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      '& > button': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  rowActionButton: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    marginTop: '0.1rem',
    marginBottom: '0.1rem',
  },
  filler: {
    height: '11rem',
  },
  marginRight4: {
    marginRight: theme.spacing(4)
  },
}))

namespace Table {
  export type ExtendedColumn<D extends object = object> = Column<D> & {
    disableSort?: boolean
    disableSortBy?: boolean
    headerTooltipContent?: string
    filter?: string
    accessor?: 'tableActions' | Column<D>['accessor']
  }
}

export default Table
