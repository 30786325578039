import { makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import { formStyles } from "js/components/Borrow/styles"
import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"

const CommonFormFallback: React.FC = () => {
  const classes = formStyles()
  const styles = useStyles()
  return (
    <div className={classes.page}>
      <div className={classes.background}>
        <div className={classes.form}>
          <div className={clsx(classes.formBox, styles.formBox)}>
            <LoadingSkeleton width={180} paddingBottom={48} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton />
          </div>
        </div>
      </div>
    </div>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  formBox: {
    marginTop: '96px',
    height: '500px',
  },
}))

export default CommonFormFallback