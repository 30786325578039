import { Network } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved

interface TelegramBotData {
  botId: string
  link: string
}
export interface TelegramAuthResponse {
  data?: TelegramAuthData
}

export interface TelegramAuthData {
  authDate: number // unix seconds
  first_name: string
  hash: string
  id: number
  last_name?: string
  photo_url: string
  username: string
}

export const TelegramBotIds: Record<Network, TelegramBotData> = {
  [Network.MainNet]: { botId: '7239496995', link: 'https://t.me/demex_alerts_bot' },
  [Network.TestNet]: { botId: '6631216940', link: 'https://t.me/demex_alerts_testnet_bot' },
  [Network.DevNet]: { botId: '7209937341', link: 'https://t.me/demex_alerts_devnet_2_bot' },
  [Network.LocalHost]: { botId: '7066411367', link: 'https://t.me/demex_alerts_localhost_bot' },
}

export const startBot = (network: Network, token: string) => {
  const link = `${TelegramBotIds[network].link}?start=${token}`
  window.open(link, '_blank')
}


