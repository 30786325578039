import { Grid, makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { LiteContainer } from "js/components/Exchange/Lite/Container"
import { useCommonStyles } from "js/utils"

const SwapFallback = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  return (
    <SwapFallback.Container>
      <div className={classes.gridBox}>
        <Grid container className={classes.gridRoot} spacing={2}>
          <Grid item xs={12} className={classes.gridItem}>
            <div className={clsx(commonClasses.flexRow, commonClasses.justifyContentCenter, classes.root)}>
              <div className={clsx(commonClasses.flexColumn, classes.formBox)}>
                <LoadingSkeleton paddingBottom={48} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </SwapFallback.Container>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  gridBox: {
    alignSelf: 'center',
  },
  gridRoot: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    justifyContent: 'center',
  },
  gridItem: {
    [theme.breakpoints.only('md')]: {
      '&:first-child': {
        padding: theme.spacing(0, 1, 0, 2),
      },
      '&:last-child': {
        padding: theme.spacing(0, 2, 0, 1),
      },
    },
  },
  root: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.base,
    width: '40%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  },
  formBox: {
    width: '100%',
    backgroundColor: theme.palette.background.primary,
    padding: theme.spacing(3, 4, 4),
    borderRadius: '4px',
    height: '480px',
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(3, 3, 4),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(3),
    },
  },
}))

SwapFallback.Container = LiteContainer

export default SwapFallback
