import { useSelector } from 'react-redux'

import { RootState } from 'js/state/modules/rootReducer'

/**
 * Helper that wraps `useSelect` with custom `RootState`
 * to make use of implicit type declarations
 * 
 * @see useSelector 
 */
const useSelect = <R, T = RootState>(selector: (state: T) => R, equalityFn?: (left: R, right: R) => boolean) => useSelector(selector, equalityFn)
export default useSelect
