import BigNumber from 'bignumber.js'
import { TypeUtils } from 'carbon-js-sdk'
import { PriceSet as PricingPriceSet } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/pricing/pricing'
import { Dayjs } from 'dayjs'
import { RecordOf } from 'immutable'

import { BN_ZERO } from 'js/utils/number'
import { TokenPrice } from 'types/price'

export interface PricingProps {
  readonly prices: TypeUtils.SimpleMap<TokenPrice>
  readonly pricesSetMap: TypeUtils.SimpleMap<PriceSet>
  readonly pricingParams: PricingParams
}

export type PricingState = RecordOf<PricingProps>

export const TokenPricesActionTypes = {
  UPDATE_TOKEN_PRICES: '@marketStats/UPDATE_TOKEN_PRICES',
  SET_PRICE_SET_MAP: '@exchange/SET_PRICE_SET_MAP',
  RELOAD_PRICE_SET_MAP: '@exchange/RELOAD_PRICE_SET_MAP',
  SET_PRICING_PARAMS: '@exchange/SET_PRICING_PARAMS',
}

export interface PriceSet extends PricingPriceSet {
  indexUpdatedAtDayjs: Dayjs
  lastFundingAtDayjs: Dayjs
  isStaleIndexPrice: boolean
}

export interface PricingParams {
  backfillTimeIntervalInSeconds: BigNumber
  impactBand: BigNumber
  smoothenBand: BigNumber
  staleIndexAllowance: BigNumber
}

export const basePricingParams: PricingParams = {
  backfillTimeIntervalInSeconds: BN_ZERO,
  impactBand: BN_ZERO,
  smoothenBand: BN_ZERO,
  staleIndexAllowance: BN_ZERO,
}
