import { useCallback, useState } from 'react'

export interface DelayedAction {
  (): Promise<void> | void
}
export type DelayedActionReturnTypes = [
  (action: DelayedAction, delayMs: number) => void,
  () => void,
]

const useDelayedAction = (): DelayedActionReturnTypes => {
  const [timeoutId, setTimeoutId] = useState<number | null>(null)

  const doDelayedAction = useCallback((action: DelayedAction, delayMs: number) => {
    if (timeoutId) clearTimeout(timeoutId)

    const id = setTimeout(action, delayMs)

    setTimeoutId(id as unknown as number)
  }, [timeoutId, setTimeoutId])

  const clearDelayedAction = useCallback(() => {
    if (timeoutId) clearTimeout(timeoutId)
  }, [timeoutId])

  return [doDelayedAction, clearDelayedAction]
}

export default useDelayedAction
