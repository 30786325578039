import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { WebSocketActionTypes, ExternalWsState, ExternalWsStateProps } from './types'

export const DefaultInitialState:
Record.Factory<ExternalWsStateProps> = Record<ExternalWsStateProps>({
    wsUrl: null,
  })

const defaultInitialState: ExternalWsState = new DefaultInitialState()

export const externalWsReducer = (
  state: ExternalWsState = defaultInitialState,
  action: AnyAction,
): ExternalWsState => {
  switch (action.type) {
    case WebSocketActionTypes.WS_URL_CHANGE: {
      return state.set('wsUrl', action.payload)
    }
    default:
      return state
  }
}
