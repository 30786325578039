import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { CrossSellingStage } from 'js/constants/crossSelling'

import { CrossSellingActionTypes, CrossSellingState, CrossSellingStateProps, CrossSellingStatus, SetCrossSellingSourceTokenPayload, SetCrossSellingStagePayload, SetEnableCrossSellingPayload } from './types'

const defaultCrossSellingStatus: CrossSellingStatus = {
  enableCrossSelling: false,
  crossSellingStage: CrossSellingStage.Inactive,
  crossSellingSourceToken: null
}

export const DefaultInitialState:
  Record.Factory<CrossSellingStateProps> = Record<CrossSellingStateProps>({
    crossSellingStatus: defaultCrossSellingStatus,
    showCrossSellingWidget: false,
    showCrossSellingDialog: false,
    showTradeHelpWizard: false,
    tradeHelpWizardStage: 1,
    highlightFocusState: {enabled: false}
  })

const defaultInitialState: CrossSellingState = new DefaultInitialState()

export const crossSellingReducer = (
  state: CrossSellingState = defaultInitialState,
  action: AnyAction,
): CrossSellingState => {
  switch (action.type) {
    case CrossSellingActionTypes.SET_CROSS_SELLING_STATUS: {
      const address = action.payload
      
      if (!address) return state.set('crossSellingStatus', defaultCrossSellingStatus)

      const crossSellingStatusByAddress = JSON.parse(localStorage.getItem(CrossSellingActionTypes.SET_CROSS_SELLING_STATUS) ?? '{}')

      crossSellingStatusByAddress[address] ??= defaultCrossSellingStatus

      localStorage.setItem(CrossSellingActionTypes.SET_CROSS_SELLING_STATUS, JSON.stringify(crossSellingStatusByAddress))
      
      if (crossSellingStatusByAddress[address]["enableCrossSelling"]) {
        return state.set('showCrossSellingWidget', true).set('crossSellingStatus', crossSellingStatusByAddress[address])
      }
      return state.set('crossSellingStatus', crossSellingStatusByAddress[address])
    }
    case CrossSellingActionTypes.SET_ENABLE_CROSS_SELLING: {
      const { address, enableCrossSelling }: SetEnableCrossSellingPayload = action.payload
      if (!address) return state

      const crossSellingStatusByAddress = JSON.parse(localStorage.getItem(CrossSellingActionTypes.SET_CROSS_SELLING_STATUS) ?? '{}')

      const updatedStatus = {...state.crossSellingStatus, enableCrossSelling: enableCrossSelling}
      crossSellingStatusByAddress[address] = updatedStatus

      localStorage.setItem(CrossSellingActionTypes.SET_CROSS_SELLING_STATUS, JSON.stringify(crossSellingStatusByAddress))

      return state.set('crossSellingStatus', updatedStatus)
    }
    case CrossSellingActionTypes.SET_SHOW_CROSS_SELLING_WIDGET: {
      return state.set('showCrossSellingWidget', true)
        .set('showCrossSellingDialog', false)
        .set('showTradeHelpWizard', false)
        .set('highlightFocusState', {enabled: false})
    }
    case CrossSellingActionTypes.SET_HIDE_CROSS_SELLING_WIDGET: {
      return state.set('showCrossSellingWidget', false)
    }
    case CrossSellingActionTypes.SET_CROSS_SELLING_STAGE: {
      const { address, crossSellingStage }: SetCrossSellingStagePayload = action.payload
      if (!address) return state

      const crossSellingStatusByAddress = JSON.parse(localStorage.getItem(CrossSellingActionTypes.SET_CROSS_SELLING_STATUS) ?? '{}')

      const updatedStatus = {...state.crossSellingStatus, crossSellingStage: crossSellingStage}
      crossSellingStatusByAddress[address] = updatedStatus

      localStorage.setItem(CrossSellingActionTypes.SET_CROSS_SELLING_STATUS, JSON.stringify(crossSellingStatusByAddress))
      return state.set('crossSellingStatus', updatedStatus)
        .set('showCrossSellingDialog', true)
        .set('showCrossSellingWidget', false)
        .set('showTradeHelpWizard', false)
    }
    case CrossSellingActionTypes.SET_CROSS_SELLING_SOURCE_TOKEN: {
      const { address, crossSellingSourceToken }: SetCrossSellingSourceTokenPayload = action.payload
      if (!address) return state

      const crossSellingStatusByAddress = JSON.parse(localStorage.getItem(CrossSellingActionTypes.SET_CROSS_SELLING_STATUS) ?? '{}')

      const updatedStatus = {...state.crossSellingStatus, crossSellingSourceToken: crossSellingSourceToken}
      crossSellingStatusByAddress[address] = updatedStatus

      localStorage.setItem(CrossSellingActionTypes.SET_CROSS_SELLING_STATUS, JSON.stringify(crossSellingStatusByAddress))
      return state.set('crossSellingStatus', updatedStatus)
    }
    case CrossSellingActionTypes.SET_SHOW_CROSS_SELLING_DIALOG: {
      return state.set('showCrossSellingDialog', true)
        .set('showCrossSellingWidget', false)
        .set('showTradeHelpWizard', false)
    }
    case CrossSellingActionTypes.SET_HIDE_CROSS_SELLING_DIALOG: {
      return state.set('showCrossSellingDialog', false)
    }
    case CrossSellingActionTypes.SET_SHOW_TRADE_HELP_WIZARD: {
      return state.set('showTradeHelpWizard', true)
        .set('showCrossSellingDialog', false)
        .set('showCrossSellingWidget', false)
    }
    case CrossSellingActionTypes.SET_TRADE_HELP_WIZARD_STAGE: {
      return state.set('tradeHelpWizardStage', action.payload)
    }
    case CrossSellingActionTypes.SET_HIGHLIGHT_FOCUS: {
      return state.set('highlightFocusState', action.payload)
    }
    default:
      return state
  }
}
