import { Dialog, DialogActions, DialogContent, DialogProps, IconButton, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import Button from 'js/components/Common/Button'
import { StyleUtils } from 'js/utils/styles'

import { ReactComponent as Close } from 'assets/Close.svg'

interface DialogClasses {
  title?: string
  actions?: string
  paper?: string
  content?: string
  button?: string
  closeButton?: string
  titleContainer?: string
}

export type CloseDialogReason = 'backdropClick' | 'escapeKeyDown'

interface Props extends DialogProps {
  onSubmit?: () => void
  headerTitle?: string | React.ReactNode
  submitText?: string  | React.ReactNode
  cancelText?: string
  customClasses?: DialogClasses
  onCancel?: () => void
  onClose?: ((event?: {}, reason?: CloseDialogReason) => void)
  withCloseButton?: boolean
  stickyCloseButton?: boolean
  loading?: boolean
  cancelLoading?: boolean
  customBtn?: React.ReactNode
  noButton?: boolean
  submitDisabled?: boolean
  cancelDisabled?: boolean
  learnMoreUrl?: string
  noCancelButton?: boolean
  noSubmitButton?: boolean
  pushButtonToRight?: boolean
  buttonSize?: 'small' | 'regular' | 'large' | 'xLarge' | 'xSmall'
  zIndex?: number,
  isCustomHeader?: boolean
}

const StandardDialog: React.FC<Props> = (props: Props) => {
  const {
    children, customClasses = {}, onClose, withCloseButton = true, stickyCloseButton = true, headerTitle, submitDisabled, cancelDisabled = false,
    submitText, cancelText, onSubmit, onCancel, loading = false, cancelLoading = false, customBtn, noButton, learnMoreUrl, noCancelButton,
    buttonSize, noSubmitButton, isCustomHeader = false, pushButtonToRight = false, zIndex = 1300, disableEnforceFocus = false, ...rest
  } = props
  const classes = useStyles()

  return (
    <Dialog
      classes={{ paper: clsx(classes.dialogPaper, customClasses.paper ?? '') }}
      {...rest}
      style={{ zIndex: zIndex }}
      onClose={(evt, reason) => onClose && onClose(evt, reason)}
      disableEnforceFocus={disableEnforceFocus}
    >
      {onClose && withCloseButton && stickyCloseButton && (
        <IconButton className={clsx(classes.stickyCloseIconButton, customClasses.closeButton)} onClick={onClose}>
          <Close className={classes.closeIcon} />
        </IconButton>
      )}
      {isCustomHeader && (
        headerTitle
      )}
      <DialogContent className={clsx(classes.dialogContent, customClasses.content)}>
        {!isCustomHeader && (
          <div className={clsx(classes.dialogTitleContainer, (onClose && withCloseButton && !stickyCloseButton) && classes.flexTitleContainer, customClasses.titleContainer)}>
            {typeof headerTitle !== 'string' ? headerTitle : (
              <div className={clsx(classes.dialogTitle, customClasses.title)}>
                {headerTitle}
              </div>
            )}
            {onClose && withCloseButton && !stickyCloseButton && (
              <IconButton className={clsx(classes.closeIconButton, customClasses.closeButton)} onClick={onClose}>
                <Close className={classes.closeIcon} />
              </IconButton>
            )}
          </div>
        )}
        {children}
      </DialogContent>
      <DialogActions className={clsx(classes.dialogActions, { noClose: ((!onClose && !onCancel) || noCancelButton) }, customClasses.actions ?? '')}>
        {!noButton && (
          typeof customBtn !== 'undefined' ? customBtn : (
            <React.Fragment>
              {(onClose || onCancel) && !noCancelButton && (
                <Button
                  className={clsx(classes.button, classes.cancel, customClasses.button)}
                  onClick={onCancel ?? onClose}
                  baseButtonVariant="text"
                  loading={cancelLoading}
                  disabled={cancelDisabled}
                  btnsize={buttonSize}
                >
                  {cancelText ?? 'Cancel'}
                </Button>
              )}
              {
                learnMoreUrl && (
                  <Button
                    className={classes.learn}
                    baseButtonVariant="outlined"
                    btnsize={buttonSize}
                    href={learnMoreUrl}
                    target="_blank"
                  >
                    <Typography>
                      Learn More
                    </Typography>
                  </Button>
                )
              }
              {!noSubmitButton && (
                <Button
                  className={clsx(classes.button, customClasses.button, { pushButtonToRight })}
                  onClick={onSubmit}
                  loading={loading}
                  btnsize={buttonSize}
                  disabled={submitDisabled}
                >
                  {submitText ?? 'Confirm'}
                </Button>
              )}
            </React.Fragment>
          )
        )}
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: '4px',
    color: '#fff',
    padding: theme.spacing(1.5, 2.5),
    '&.pushButtonToRight': {
      marginLeft: 'auto'
    }
  },
  cancel: {
    color: theme.palette.text.primary,
    background: 'transparent',
    border: `1px solid ${theme.palette.action.disabledBackground}`,
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  learn: {
    background: `linear-gradient(to right, ${theme.palette.background.secondary}, ${theme.palette.background.secondary}), ${StyleUtils.buttonGradient(theme)}`,
    backgroundClip: 'padding-box, border-box',
    backgroundOrigin: 'padding-box, border-box',
  },
  stickyCloseIconButton: {
    zIndex: 1,
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    '@media (max-width: 400px)': {
      right: theme.spacing(0.75),
    },
    '&:hover': {
      '& path': {
        fill: theme.palette?.type === 'light' ? theme.palette.text.demexSolidHover : theme.palette.text.animated,
        ...StyleUtils.hoverAnimationDuration(),
      },
    },
  },
  closeIconButton: {
    padding: 0,
    height: '24px',
    marginRight: '-4px',
    '&:hover': {
      '& path': {
        fill: theme.palette?.type === 'light' ? theme.palette.text.demexSolidHover : theme.palette.text.animated,
        ...StyleUtils.hoverAnimationDuration(),
      },
    },
  },
  closeIcon: {
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },
  dialogActions: {
    justifyContent: 'space-between',
    '&.noClose': {
      justifyContent: 'flex-end',
    },
    padding: theme.spacing(1, 3, 3),
    '@media (max-width: 400px)': {
      padding: theme.spacing(2.5, 2.5, 3),
    },
  },
  dialogContent: {
    ...theme.typography.body3,
    ...StyleUtils.scrollBar(theme),
    padding: theme.spacing(0, 3.5),
    '@media (max-width: 400px)': {
      padding: theme.spacing(0, 2.5),
    },
  },
  dialogTitleContainer: {
    paddingTop: theme.spacing(3),
  },
  flexTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogTitle: {
    ...theme.typography.h4,
    marginBottom: theme.spacing(2.5),
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h6,
    },
  },
  dialogPaper: {
    width: '100%',
    backgroundColor: theme.palette.background.primary,
    backdropFilter: 'blur(3px)',
    '@media (max-width: 400px)': {
      margin: theme.spacing(2),
    },
  },
}))


export default StandardDialog
