import { RecordOf } from 'immutable'

import { CrossSellingStage, TradeHelpWizardStage } from 'js/constants/crossSelling'

export interface CrossSellingStateProps {
  readonly crossSellingStatus: CrossSellingStatus,
  readonly showCrossSellingWidget: boolean,
  readonly showCrossSellingDialog: boolean,
  readonly showTradeHelpWizard: boolean,
  readonly tradeHelpWizardStage: TradeHelpWizardStage
  readonly highlightFocusState: HighlightFocusState
}

export type CrossSellingState = RecordOf<CrossSellingStateProps>

export const CrossSellingActionTypes = {
  SET_CROSS_SELLING_STATUS: '@crossSelling/SET_CROSS_SELLING_STATUS',
  SET_ENABLE_CROSS_SELLING: '@crossSelling/SET_ENABLE_CROSS_SELLING',
  SET_SHOW_CROSS_SELLING_WIDGET: '@crossSelling/SET_SHOW_CROSS_SELLING_WIDGET',
  SET_HIDE_CROSS_SELLING_WIDGET: '@crossSelling/SET_HIDE_CROSS_SELLING_WIDGET',
  SET_CROSS_SELLING_STAGE: '@crossSelling/SET_CROSS_SELLING_STAGE',
  SET_CROSS_SELLING_SOURCE_TOKEN: '@crossSelling/SET_CROSS_SELLING_SOURCE_TOKEN',
  SET_SHOW_CROSS_SELLING_DIALOG: '@crossSelling/SET_SHOW_CROSS_SELLING_DIALOG',
  SET_HIDE_CROSS_SELLING_DIALOG: '@crossSelling/SET_HIDE_CROSS_SELLING_DIALOG',
  SET_SHOW_TRADE_HELP_WIZARD: '@crossSelling/SET_SHOW_TRADE_HELP_WIZARD',
  SET_TRADE_HELP_WIZARD_STAGE: '@crossSelling/SET_TRADE_HELP_WIZARD_STAGE',
  SET_HIGHLIGHT_FOCUS: '@crossSelling/SET_HIGHLIGHT_FOCUS',
}

export interface CrossSellingStatus {
  enableCrossSelling: boolean,
  crossSellingStage: CrossSellingStage,
  crossSellingSourceToken: string | null,
}

export interface SetEnableCrossSellingPayload {
  address: string | undefined,
  enableCrossSelling: boolean
}

export interface SetCrossSellingStagePayload {
  address: string | undefined,
  crossSellingStage: CrossSellingStage
}

export interface SetCrossSellingSourceTokenPayload {
  address: string | undefined,
  crossSellingSourceToken: string | null
}
export interface HighlightFocusState {
  enabled: boolean,
  key?: string
}