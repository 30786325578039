import { makeStyles, Typography } from '@material-ui/core'
import { TypographyProps } from '@material-ui/core/Typography' // eslint-disable-line import/no-unresolved
import clsx from 'clsx'
import React from 'react'

interface Props extends TypographyProps {
  divClass?: string
  empty?: boolean
  withBox?: boolean
  component?: string
}

const GradientText: React.FC<Props> = (props: Props) => {
  const { divClass, className, children, empty, withBox = true, component, ...rest } = props

  const classes = useStyles(props)

  const content = !empty ? children : (<span>&nbsp;</span>)

  return withBox ? (
    <div className={divClass}>
      <Typography
        {...rest}
        className={clsx(classes.root, className)}
      >
        {content}
      </Typography>
    </div>
  ) : (
    <Typography
      component={component ?? 'p'}
      className={clsx(classes.root, className)}
      {...rest}
    >
      {content}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body2,
    background: theme.palette.text.demexSolid,
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
  },
}))

export default GradientText
