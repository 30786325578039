// Help to prevent crash app when using google translate
export const overrideNodeMethods = () => {
    if (typeof Node === 'function' && Node.prototype) {
      const originalRemoveChild = Node.prototype.removeChild
      Node.prototype.removeChild = function<T extends Node>(child: T): T {
        if (child.parentNode !== this) {
          return child
        }
        return originalRemoveChild.apply(this, [child]) as T
      }
    
      const originalInsertBefore = Node.prototype.insertBefore
      Node.prototype.insertBefore = function<T extends Node>(newNode: T, referenceNode: Node | null): T {
        if (referenceNode && referenceNode.parentNode !== this) {
          return newNode
        }
        return originalInsertBefore.apply(this, [newNode, referenceNode]) as T
      }
    }
  }