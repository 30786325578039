import { Button, createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import ProfileAvatar from 'js/components/Common/ProfileAvatar'
import TypographyLabel from 'js/components/Common/TypographyLabel'
import Paths from 'js/constants/paths'
import { EvtCategory, useEventTrackers } from 'js/hooks'
import { getProfile, getShowCarbonAddress } from 'js/state/modules/account/selectors'
import { setWalletToggle } from 'js/state/modules/app/actions'
import { getSDKWallet } from 'js/state/modules/app/selectors'
import { copyTextToClipboard, useCommonStyles } from 'js/utils'
import { truncateStr } from 'js/utils/strings'

import { ReactComponent as CopyOutlinedIcon } from 'assets/CopyOutlined.svg'
import { ReactComponent as PlugIcon } from 'assets/Plug.svg'



interface Props {
  className?: string
}

const SidebarHeader: React.FunctionComponent<Props> = (props: Props) => {
  const { className } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dispatch = useDispatch()
  const eventTrackers = useEventTrackers()
  const history = useHistory()
  const profile = useSelector(getProfile)
  const showCarbonAddress = useSelector(getShowCarbonAddress)

  const wallet = useSelector(getSDKWallet)

  const onConnect = () => {
    dispatch(setWalletToggle(true))
    eventTrackers.sendEvent(EvtCategory.Wallet, 'wallet_connect_tap')
  }

  const onCopyAddress = () => {
    if (!wallet?.bech32Address || !wallet?.evmHexAddress) return
    copyTextToClipboard(showCarbonAddress ? wallet.bech32Address : wallet.evmHexAddress)
  }

  return (
    <div className={clsx(classes.root, className)}>
      {wallet ? (
        <div className={classes.addressContainer}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              history.push(Paths.Account.Profile)
            }}
            className={classes.addressBox}
          >
            <ProfileAvatar diameter={24} />
            <div className={clsx(commonClasses.flexColumn, commonClasses.justifyContentCenter)}>
              {profile?.username && (
                <TypographyLabel className={classes.username}>
                  {profile?.username}
                </TypographyLabel>
              )}
              <TypographyLabel className={classes.addressText}>
                {truncateStr(showCarbonAddress ? wallet.bech32Address : wallet.evmHexAddress, 7, 5, '...')}
              </TypographyLabel>
            </div>
          </div>
          <Button className={classes.copyBox} onClick={onCopyAddress}>
            <CopyOutlinedIcon className={classes.copyIcon} />
          </Button>
        </div>
      ) : (
        <div className={classes.addressContainer} onClick={onConnect}>
          <div className={classes.addressBox}>
            <div className={classes.waveBox}>
              <span className={classes.wave}>👋</span>
            </div>
            <div className={clsx(commonClasses.flexColumn, commonClasses.justifyContentCenter)}>
              <TypographyLabel className={classes.username}>
                Welcome to Demex
              </TypographyLabel>
              <TypographyLabel className={classes.connectText}>
                Connect Wallet
              </TypographyLabel>
            </div>
          </div>
          <Button className={clsx(classes.copyBox, 'connectButton')}>
            <PlugIcon className={classes.plugIcon} />
          </Button>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  username: {
    ...theme.typography.body4,
    flexBasis: 0,
    display: 'inline-block',
    wordBreak: 'break-word',
    color: theme.palette.text.secondary,
  },
  addressContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover $wave': {
      animationName: '$wave-animation',
      animationDuration: '2.5s',
      animationIterationCount: 'infinite',
      transformOrigin: '70% 70%',
    },
  },
  addressText: {
    zIndex: 1,
    ...theme.typography.body3,
    background: 'unset',
    backgroundClip: 'unset',
    WebkitTextFillColor: 'unset',
    WebkitBackgroundClip: 'unset',
    color: theme.palette.text.primary,
  },
  addressBox: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'flex-start',
    gap: theme.spacing(0, 1),
    height: '3rem',
    width: '100%',
    backgroundColor: theme.palette.background.tertiary,
    padding: theme.spacing(0.8, 1),
    borderRadius: '4px 0px 0px 4px',
    lineHeight: '0',
    '&:hover $addressText': {
      background: theme.palette.text.demexSolidHover,
      backgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      WebkitBackgroundClip: 'text',
      textDecoration: 'underline',
    },
  },
  connectText: {
    ...theme.typography.h6,
    background: theme.palette.text.demexSolid,
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
  },
  copyBox: {
    display: 'flex',
    minWidth: '1rem',
    height: '3rem',
    backgroundColor: theme.palette.background.divider,
    padding: theme.spacing(0.8, 1.2),
    borderRadius: '0px 4px 4px 0px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  copyIcon: {
    '& path': {
      stroke: theme.palette.text.secondary,
    },
  },
  plugIcon: {
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },
  wave: {
    display: 'inline-block',
    fontSize: '20px',
    lineHeight: 1,
  },
  waveBox: {
    display: 'flex',
    height: '28px',
    width: '28px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@keyframes wave-animation': {
    '0%': {
      transform: 'rotate(0.0deg)',
    },
    '10%': {
      transform: 'rotate(14.0deg)',
    },
    /* The following five values can be played with to make the waving more or less extreme */
    '20%': {
      transform: 'rotate(-8.0deg)',
    },
    '30%': {
      transform: 'rotate(14.0deg)',
    },
    '40%': {
      transform: 'rotate(-4.0deg)',
    },
    '50%': {
      transform: 'rotate(10.0deg)',
    },
    '60%': {
      transform: 'rotate(0.0deg)',
    },
    /* Reset for the last half to pause */
    '100%': {
      transform: 'rotate( 0.0deg)',
    },
  },
}))

export default SidebarHeader
