import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

interface Props {
  children: React.ReactNode;
  className?: any;
  toolbarClass?: string
}

const Toolbar: React.FC<Props> = (props: Props) => {
  const { children, className, toolbarClass } = props
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)}>
      <div className={clsx(classes.toolbar, toolbarClass)}>
        {children}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body2,
    position: 'static',
    transform: 'translateZ(0)', // Make sure we can see the elevation.
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
    flexShrink: 0,
    padding: theme.spacing(0, 2),
  },
  toolbar: {
    minHeight: theme.spacing(6),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
}))

export default Toolbar
