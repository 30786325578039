import { defaultPreferredLeverage } from 'js/constants/markets'
import { isFutures } from 'js/models/Market'

import { RootState } from '../rootReducer'

export function getLeverages(state: RootState): any {
  return state.leverages.leverages
}

export function getCurrentMarketLeverage(marketId?: string): (state: RootState) => number {
  return (state: RootState) => {
    const market = marketId ?? state.exchange.marketName
    const { leverages } = state.leverages
    const currentMarketLeverage = Number(leverages[market])
    if (currentMarketLeverage) {
      return currentMarketLeverage
    }
    return 1
  }
}

export function getIsSettingLeverages(state: RootState): boolean {
  return state.leverages.isSettingLeverages
}

// Pending leverage is the leverage that the next order will be executed at,
// if user has not traded on the current market before
export function getPendingLeverage(state: RootState): number {
  return state.leverages.pendingLeverage
}

export function getEffectiveLeverage(marketId?: string): (state: RootState) => number {
  return (state: RootState) => {
    const market = marketId ?? state.exchange.marketName
    const preferredLeverage = state.leverages.preferredLeverages[market]
    const marketType = state.exchange.market?.marketType
    const hasTradedInCurrentMarket = state.history.orderHistory.some(order => order.market_id === market)

    // use preferred leverage if it exists
    if (preferredLeverage) {
      return Number(preferredLeverage)
    }

    // if user has not traded in current future market before, use default leverage
    if (isFutures(marketType) && !hasTradedInCurrentMarket) {
      return Number(defaultPreferredLeverage) // Assuming defaultPreferredLeverage is defined elsewhere
    }
    return getCurrentMarketLeverage(market)(state)
  }
}

export function getPreferredLeverage(marketId?: string): (state: RootState) => string | undefined {
  return (state: RootState) => {
    const market = marketId ?? state.exchange.marketName
    const preferredLeverage = state.leverages.preferredLeverages[market]
    return preferredLeverage
  }
}

export function getShowLeveragesModal(state: RootState): boolean {
  return state.leverages.showLeveragesModal
}
