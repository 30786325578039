import { RecordOf } from 'immutable'

import { Inbox } from 'js/utils/alerts'

export type InboxWsEvent = {
  type: 'status'
  status: boolean
} | {
  type: 'message'
  result: InboxWsResult
}
export interface InboxWsSubscribeRace {
  socketDisconnected: boolean
  networkChanged: boolean
}

export interface InboxWsResult {
  type: 'full_state' | 'update'
  data: Inbox[]
}


export interface InboxWsStateProps {
  readonly isConnected: boolean
}

export interface InboxSubscriptionParams {
  address: string
}

export type InboxWsState = RecordOf<InboxWsStateProps>

export const InboxWebSocketActionTypes = {
  STATUS_CHANGE: '@inboxWebSocket/STATUS_CHANGE',
}

