import { Divider, FormControlLabel, SvgIcon, Theme, makeStyles } from '@material-ui/core'
import { CarbonSDK } from 'carbon-js-sdk'
import clsx from 'clsx'
import React, { Fragment, Suspense, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TypographyLabel } from 'js/components/Common'
import TokenSymbol from 'js/components/Common/TokenSymbol'
import V2Switch from 'js/components/Common/V2Switch'
import Accordion from 'js/components/Exchange/Common/Accordion'
import CommonTooltip from 'js/components/Exchange/Common/Tooltip'
import { FeeDropDownItem, FeeDropdownKeys, feeDropdownItems } from 'js/constants/fees'
import { FeatureType } from 'js/constants/notification'
import { useAdjustedBalance, useIndivReservedFees } from 'js/hooks'
import { setisSWTHDefaultWithdrawal } from 'js/state/modules/account/actions'
import { getEnableSwthFee, getIsSWTHDefaultWithdrawal, getReservedTokensPreference } from 'js/state/modules/account/selectors'
import { setCurrentFeeSettingsDropdown } from 'js/state/modules/app/actions'
import { getCarbonSDK, getCurrentFeeSettingsDropdown } from 'js/state/modules/app/selectors'
import { getAdjustedBalances } from 'js/state/modules/walletBalance/selectors'
import { useCommonStyles } from 'js/utils'
import { makeCustomToast } from 'js/utils/notifications'
import { BN_ZERO } from 'js/utils/number'

import { ReactComponent as AlertIcon } from 'assets/AlertIcon.svg'
import { ReactComponent as CautionIcon } from 'assets/Caution.svg'

import ReserveSettings from './ReserveSettings'
import SelectPriorityFeeTokens from './SelectPriorityFeeTokens'

const MiddleFeeBox = React.lazy(() => import('./MiddleFeeBox'))

interface Props {
  feeDropdownOpen?: boolean
  feeDropdownPaperRef?: React.RefObject<HTMLDivElement>
}

const FeeSettingsDetail: React.FC<Props> = (props: Props) => {
  const { feeDropdownOpen, feeDropdownPaperRef } = props
  const swthBalance = useAdjustedBalance('swth', 'available')
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dispatch = useDispatch()
  const customToast = makeCustomToast({ featureType: FeatureType.WALLET })
  const indivReservedFees = useIndivReservedFees()

  const { firstPriority: firstPriorityToken = { denom: '', symbol: '' } } = useSelector(getReservedTokensPreference) ?? {}
  const adjustedBalances = useSelector(getAdjustedBalances)
  const isSWTHDefaultWithdrawal = useSelector(getIsSWTHDefaultWithdrawal)
  const sdk: CarbonSDK = useSelector(getCarbonSDK) as CarbonSDK
  const indivEnable = useSelector(getEnableSwthFee) ?? false
  const currentFeeSettingsDropdown = useSelector(getCurrentFeeSettingsDropdown)

  const adjBalance = adjustedBalances?.[firstPriorityToken.denom]
  const balance = (adjBalance?.available ?? BN_ZERO).plus(adjBalance?.feeReserved ?? BN_ZERO)

  const isPreferredFeeOpen = currentFeeSettingsDropdown?.key === FeeDropdownKeys.preferredToken
  const isReserveFeeOpen = currentFeeSettingsDropdown?.key === FeeDropdownKeys.reserveToken
  const isSwthWithdrawFeeOpen = currentFeeSettingsDropdown?.key === FeeDropdownKeys.useSwthForFees
  const isBuySwthForFeeOpen = currentFeeSettingsDropdown?.key === FeeDropdownKeys.buySwthForFees

  const handleSwitchSwthWithdrawal = () => {
    dispatch(setisSWTHDefaultWithdrawal(!isSWTHDefaultWithdrawal, sdk?.wallet?.bech32Address))
    customToast.success({ title: `${!isSWTHDefaultWithdrawal ? 'Enable' : 'Disable'} Success`, message: `Pay withdrawal fee in SWTH is ${!isSWTHDefaultWithdrawal ? 'enabled' : 'disabled'}.` })
  }

  const { tooltipTitleWithdrawal, withdrawalIcon } = useMemo(() => {
    if (swthBalance?.eq(0)) {
      return {
        tooltipTitleWithdrawal: 'You’ve run out of SWTH to pay Withdrawal Fees. Buy more SWTH below or deposit from other wallets.',
        withdrawalIcon: (
          <SvgIcon
            className={clsx(classes.icon, classes.cautionIcon)}
            component={CautionIcon}
          />
        ),
      }
    }
    return {}

  }, [swthBalance, classes.cautionIcon, classes.icon])

  const withdrawFeeTitle: React.ReactElement =
    <div className={classes.dropdownTitle}>
      Use SWTH for Withdrawal Fees
      {withdrawalIcon && tooltipTitleWithdrawal && (
        <CommonTooltip isTextTooltip={false} title={tooltipTitleWithdrawal}>
          <div className={classes.reservedFees}>
            {withdrawalIcon}
          </div>
        </CommonTooltip>
      )}
    </div>

  const { toolTipTitle, icon } = useMemo(() => {
    if (!indivEnable) {
      return {
        toolTipTitle: 'We recommend enabling Reserved Fees to avoid running out of Network Fees for transactions. Enable Reserved Fees at the settings icon on the right.',
        icon: (
          <SvgIcon
            className={clsx(classes.icon, classes.cautionIcon)}
            component={CautionIcon}
          />
        ),
      }
    }
    if (indivEnable && balance && balance.isZero()) {
      return {
        toolTipTitle: 'Deposit more tokens to use as Network Fees.',
        icon: (
          <SvgIcon
            className={clsx(classes.icon, classes.alertIcon)}
            component={AlertIcon}
          />
        ),
      }
    }
    if (indivEnable && balance && balance.lt(indivReservedFees?.reserve)) {
      return {
        toolTipTitle: 'You are almost out of your current fee token to pay Network Fees. Buy more tokens below or deposit from another wallet.',
        icon: (
          <SvgIcon
            className={clsx(classes.icon, classes.cautionIcon)}
            component={CautionIcon}
          />
        ),
      }
    }
    return {}
  }, [indivEnable, balance, indivReservedFees, classes.icon, classes.cautionIcon, classes.alertIcon])

  const handleDropdownOpen = useCallback((feeDropdownItem: FeeDropDownItem) => {
    dispatch(setCurrentFeeSettingsDropdown(feeDropdownItem))
  }, [dispatch])

  return (
    <div>
      <Accordion
        accordionClassName={classes.accordionRoot}
        summaryClassName={classes.accordionSummary}
        expandIconClassName={classes.accordionExpandIcon}
        detailsClassName={classes.accordionDetails}
        onChange={() => handleDropdownOpen(feeDropdownItems[0])}
        expanded={isPreferredFeeOpen}
        withEndIcon
        summaryChildren={feeDropdownItems[0].label}
        detailsChildren={
          <SelectPriorityFeeTokens />
        }
      />

      <Divider className={classes.divider} />

      <Accordion
        accordionClassName={classes.accordionRoot}
        summaryClassName={classes.accordionSummary}
        expandIconClassName={classes.accordionExpandIcon}
        detailsClassName={classes.accordionDetails}
        onChange={() => handleDropdownOpen(feeDropdownItems[1])}
        expanded={isReserveFeeOpen}
        withEndIcon
        summaryChildren={
          <div className={classes.tagBox}>
            {toolTipTitle != null ? (
              <div className={classes.dropdownTitle}>
                Reserve Tokens for Fees
                <CommonTooltip isTextTooltip={false} title={toolTipTitle}>
                  <div className={classes.reservedFees}>
                    {icon}
                  </div>
                </CommonTooltip>
              </div>
            ) : (
              <div className={classes.dropdownTitle}>
                Reserve Tokens for Fees
              </div>
            )}
            <span className={clsx(classes.statusTag, { active: indivEnable })}>{indivEnable ? 'Enabled' : 'Disabled'}</span>
          </div>
        }
        detailsChildren={
          <div className={clsx(classes.dropdownSubtitle, classes.dropdownSubtitleRow)}>
            <ReserveSettings asset={firstPriorityToken.denom}/>
          </div>
        }
      />

      <Divider className={classes.divider} />

      <Accordion
        accordionClassName={classes.accordionRoot}
        summaryClassName={classes.accordionSummary}
        expandIconClassName={classes.accordionExpandIcon}
        detailsClassName={classes.accordionDetails}
        onChange={() => handleDropdownOpen(feeDropdownItems[2])}
        expanded={isSwthWithdrawFeeOpen}
        withEndIcon
        summaryChildren={
          <div className={classes.tagBox}>
            {withdrawFeeTitle}
            <span className={clsx(classes.statusTag, { active: isSWTHDefaultWithdrawal })}>{isSWTHDefaultWithdrawal ? 'Enabled' : 'Disabled'}</span>
          </div>
        }
        detailsChildren={
          <Fragment>
            <TypographyLabel className={classes.dropdownContent}>
              Withdraw an exact amount of tokens by paying withdrawal fees in SWTH instead of in the token being withdrawn.<br /><br />
            </TypographyLabel>
            <div className={clsx(classes.dropdownSubtitleRow, commonClasses.justifyContentSpaceBetween, commonClasses.alignItemsCenter)}>
              <TypographyLabel className={classes.dropdownSubtitle}>
                Pay withdrawal fee in SWTH
              </TypographyLabel>
              <FormControlLabel
                classes={{
                  root: classes.switch,
                }}
                control={(
                  <V2Switch
                    className={classes.switch}
                    checked={isSWTHDefaultWithdrawal}
                    onChange={handleSwitchSwthWithdrawal}
                  />
                )}
                label=''
              />
            </div>
          </Fragment>
        }
      />

      <Divider className={classes.divider} />

      <Accordion
        accordionClassName={classes.accordionRoot}
        summaryClassName={classes.accordionSummary}
        expandIconClassName={classes.accordionExpandIcon}
        detailsClassName={classes.accordionDetails}
        onChange={() => handleDropdownOpen(feeDropdownItems[3])}
        expanded={isBuySwthForFeeOpen}
        withEndIcon
        summaryChildren={
          <Fragment>
            Buy&nbsp;<TokenSymbol denom={firstPriorityToken.denom} />&nbsp;for Fees
          </Fragment>
        }
        detailsChildren={
          <Suspense fallback={null}>
            <MiddleFeeBox feeDropdownOpen={feeDropdownOpen} feeDropdownPaperRef={feeDropdownPaperRef} />
          </Suspense>
        }
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: '2px',
      marginLeft: 0,
    },
  },
  cautionIcon: {
    '& path': {
      fill: 'url(#accentWarning)',
    },
  },
  alertIcon: {
    '& path': {
      stroke: 'url(#accentError)',
      fill: 'none',
    },
  },
  reservedFees: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  dropdownSubtitle: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    lineHeight: '1rem',
    fontWeight: 700,
    '&.value': {
      color: theme.palette.text.primary,
      lineHeight: '0.875rem',
    },
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body4,
    },
  },
  dropdownSubtitleRow: {
    paddingBottom: theme.spacing(0.5),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  dropdownTitle: {
    ...theme.typography.body3,
    display: 'flex',
    fontWeight: 700,
    alignItems: 'center',
    '&.error': {
      color: theme.palette.error.main,
    },
    '&.warning': {
      color: theme.palette.warning.main,
    },
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body3,
      fontWeight: 700,
    },
  },
  withdrawal: {
    padding: '5px',
  },
  tooltip: {
    borderBottom: '1px dashed',
  },
  tagBox: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5)
  },
  statusTag: {
    ...theme.typography.body5,
    backgroundColor: theme.palette.background.tertiary,
    lineHeight: '11px',
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    fontWeight: 700,
    '&.active': {
      color: theme.palette.success.light,
    }
  },
  accordionRoot: {
    padding: 0,
    background: 'none',
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  accordionSummary: {
    ...theme.typography.body3,
    fontWeight: 700,
    maxHeight: '24px !important',
    minHeight: '24px !important',
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  accordionExpandIcon: {
    height: '24px',
    width: '24px',
    marginRight: '-4px',
  },
  accordionDetails: {
    display: 'block',
  },
  dropdownContent: {
    color: theme.palette.text.secondary,
  },
}))

export default FeeSettingsDetail
