import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { IconButton, SvgIcon } from 'js/components/Common'
import { useSelect } from 'js/hooks'
import { getTotalNewCount } from 'js/state/modules/alerts/selectors'
import { StyleUtils } from 'js/utils'

import { ReactComponent as NotificationsOff } from 'assets/NotificationsOff.svg'

interface Props {
  outlined?: boolean
}
const NotificationBell: React.FC<Props> = (props) => {
  const count = useSelect(getTotalNewCount())
  const { outlined = false } = props
  const classes = useStyles()
  return (
    <IconButton icontype='stroke' className={classes.iconButton}>
      {count > 0 && (
        <div className={classes.indicator}>
          {count > 99 ? '99+' : count}
        </div>
      )}
      <SvgIcon
        className={clsx(classes.icon, { outlined })}
        component={NotificationsOff}
      />
    </IconButton>
  )
}

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    margin: theme.spacing(0, 0.75),
    padding: theme.spacing(0.25, 0),
    '& path': {
      stroke: theme.palette.text.secondary,
    },
    '&.outlined': {
      '& path': {
        stroke: theme.palette.type === 'dark' ? theme.palette.text.animated : 'url(#demexLinearGradient)',
      },
    },
  },
  iconButton: {
    display: 'flex',
    '&:hover.stroke svg path': {
      stroke: 'transparent',
    },
    '&:hover.stroke svg path:nth-child(1)': {
      stroke: theme.palette.type === 'dark' ? theme.palette.text.animated : 'url(#demexLinearGradient)',
    },
  },
  indicator: {
    ...theme.typography.body4,
    background: StyleUtils.buttonGradient(theme),
    color: '#FFFFFF',
    position: 'absolute',
    height: '14px',
    top: 0,
    left: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 4px 2px',
    borderRadius: 4,
  },
}))

export default NotificationBell
