import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { LlamaWidgetStateProps, LlamaWidgetState, LlamaWidgetActionTypes } from './types'

export const DefaultInitialState: Record.Factory<LlamaWidgetStateProps> = Record<LlamaWidgetStateProps>({
  tweets: [],
  news: [],
  filteredTweets: [],
  filteredNews: [],
  latestContent: undefined,
})

const defaultInitialState: LlamaWidgetState = new DefaultInitialState()

export const llamaWidgetReducer = (
  state: LlamaWidgetState = defaultInitialState,
  action: AnyAction,
): LlamaWidgetState => {
  switch (action.type) {
    case LlamaWidgetActionTypes.SET_TWEETS: {
      return state.set('tweets', action.payload)
    }
    case LlamaWidgetActionTypes.SET_NEWS: {
      return state.set('news', action.payload)
    }
    case LlamaWidgetActionTypes.SET_FILTERED_TWEETS: {
      return state.set('filteredTweets', action.payload)
    }
    case LlamaWidgetActionTypes.SET_FILTERED_NEWS: {
      return state.set('filteredNews', action.payload)
    }
    case LlamaWidgetActionTypes.SET_LATEST_CONTENT: {
      return state.set('latestContent', action.payload)
    }
    default: {
      return state
    }
  }
}
