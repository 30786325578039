import { Button, Paper, Typography, createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { StyleUtils, copyTextToClipboard, useCommonStyles } from 'js/utils'

import { ReactComponent as CopyOutlinedIcon } from 'assets/CopyOutlined.svg'

interface CustomClasses {
  paper?: string
  box?: string
}

interface Props {
  error: string
  customClasses?: CustomClasses
}

const onCopyText = (error: string) => {
  copyTextToClipboard(error, 'Raw Error Copied', 'Error copying raw error. Please do it manually.')
}
const RawError: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { error, customClasses = {} } = props
  return (
    <Paper
      onClick={() => onCopyText(error)}
      className={clsx(classes.rawError, customClasses.paper)}
      elevation={0}
    >
      <div className={clsx(customClasses.box, commonClasses.alignItemsCenter)}>
        <Typography>
          {error}
        </Typography>
        <Button className={classes.copyBox}>
          <CopyOutlinedIcon />
        </Button>
      </div>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  rawError: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.background.tertiary,
    maxHeight: '3.5rem',
    display: 'inline-block',
    width: '100%',
    '& p': {
      ...theme.typography.body4,
      fontSize: '9px',
      color: theme.palette.text.secondary,
      lineHeight: '0.75rem',
      flexGrow: 1,
      ...StyleUtils.lineClamp(4),
    },
  },
  copyBox: {
    display: 'flex',
    padding: theme.spacing(0, 0.25, 0, 0.25),
    minWidth: '2rem',
    '&:hover': {
      background: 'none',
    },
    '& svg': {
      '& path': {
        stroke: theme.palette.text.secondary,
      },
    },
  },
}))

export default RawError
