import { createStyles, makeStyles, Paper, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSidebar from "js/components/Common/Sidebar/LoadingSidebar"
import { useCommonStyles } from "js/utils"

import PromotionSwitch from "./PromotionSwitch"

const PromotionFallback: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  return (
    <div className={clsx(commonClasses.flexRow, classes.container)}>
      <LoadingSidebar className={classes.drawer} />
      <Paper className={classes.content}>
        <PromotionSwitch />
      </Paper>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    width: '100%',
    flex: 1,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    }
  },
  drawer: {
    maxWidth: '17rem',
  },
  content: {
    flexGrow: 1,
    borderRadius: 0,
    boxShadow: 'none',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.base,
  },
}))

export default PromotionFallback
