import { makeStyles, Theme } from "@material-ui/core"

import { StyleUtils } from "js/utils"

export const menuListStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 1, 1.5),
    outline: 'none',
  },
  icon: {
    fill: 'none',
    marginRight: theme.spacing(0.75),
    maxWidth: '1.125rem',
    maxHeight: '1.125rem',
    width: '100%',
    '&.endIcon': {
      marginRight: 0,
    },
    '&.endIcon.active path': {
      fill: theme.palette.text.primary
    },
    '&.large': {
      maxWidth: '1.5rem',
      maxHeight: '1.5rem',
      marginRight: theme.spacing(1.25),
    },
    '&.innerIcon': {
      marginRight: theme.spacing(1.5),
      maxWidth: theme.spacing(3),
      maxHeight: theme.spacing(3),
    }
  },
  iconFill: {
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },
  iconStroke: {
    '& path': {
      stroke: theme.palette.text.secondary,
    },
  },
  label: {
    ...theme.typography.body3,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    padding: theme.spacing(1.5),
    '&$active': {
      color: theme.palette.text.primary,
    },
    '& $iconFill': {
      '& path': {
        fill: theme.palette.text.secondary,
        stroke: 'unset',
      },
    },
    '& $iconStroke': {
      '& path': {
        stroke: theme.palette.text.secondary,
        fill: 'unset',
      },
    },
    '&.small': {
      padding: theme.spacing(1.125, 1),
      [theme.breakpoints.only('xs')]: {
        minHeight: '2.5rem',
        padding: theme.spacing(0.75, 0.75),
      },
      '&$active': {
        fontWeight: 700,
      },
    },
    '&.large': {
      ...theme.typography.body2,
      minHeight: '3rem',
      padding: theme.spacing(0, 1),
      '&$active': {
        fontWeight: 700,
      },
    },
    '&:hover $wipe': {
      backgroundColor: theme.palette.type === 'dark' ? '#303236' : '#F7F6F5', // Custom colours for the hover state
    },
  },
  active: {
    '& $iconFill': {
      '& path': {
        fill: theme.palette.text.primary,
        stroke: 'unset',
      },
    },
    '& $iconStroke': {
      '& path': {
        stroke: theme.palette.text.primary,
        fill: 'unset',
      },
    },
  },
  notificationIndicator: {
    ...theme.typography.body4,
    background: StyleUtils.buttonGradient(theme),
    color: theme.palette.common.white,
    maxWidth: theme.spacing(2),
    position: 'absolute',
    height: theme.spacing(1.75),
    top: theme.spacing(1),
    left: theme.spacing(2.5),
    padding: theme.spacing(0, 0.5, 0.25),
    borderRadius: 4,
  },
  wipe: {
    WebkitMaskImage: 'linear-gradient(to right, transparent 47.5%, #FFFFFF 52.5%)',
    WebkitMaskSize: '210% 100%',
    WebkitMaskPosition: 'left',
    backgroundColor: theme.palette.background.secondary,
  },
  '@keyframes wipeLeft': {
    '0%': {
      WebkitMaskPosition: 'left',
    },
    '100%': {
      WebkitMaskPosition: 'right',
    },
  },

  '@keyframes cursorLeft': {
    '0%': {
      right: '-10%',
      width: theme.spacing(0.25),
    },
    '100%': {
      right: '100%',
      width: 0,
    },
  },
  divider: {
    margin: theme.spacing(0, 1),
  },
  menuItemBox: {
    columnGap: theme.spacing(1),
  },
  innerList: {
    ...theme.typography.body3,
    paddingLeft: theme.spacing(1)
  },
  scrollDown: {
    maxHeight: '100vh',
    transition: 'all 0.25s ease-in',
    overflow: 'hidden',
  },
  scrollUp: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'all 0.25s ease-out'
  },
  endIconInnerList: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
  },
  // MultiMenuListItems styles
  multiMenuLabel: {
    padding: theme.spacing(1, 1.5),
  },
  checkboxBox: {
    pointerEvents: 'none'
  },
  checkboxItem: {
    padding: 0,
    marginRight: '0.5rem',
    '&:hover, &.Mui-Checked:hover': {
      backgroundColor: `${theme.palette.action.hover} !important`,
    },
  },
  checkboxLbl: {
    ...theme.typography.body4,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  },
  selectedLabel: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 0, 0.5, 1)
  },
}))