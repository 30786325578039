import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { WelcomeOfferStateProps, WelcomeOfferState, WelcomeOfferActionTypes } from './types'

import { AccountActionTypes } from '../account/types'

export const MAX_USERS = 10

export const DefaultInitialState: Record.Factory<WelcomeOfferStateProps> = Record<WelcomeOfferStateProps>({
  numUserLeft: MAX_USERS,
  openAddTwitterDialog: false,
  openSignTransactionDialog: false,
  openDepositDialog: false,
  openWrongUserDialog: false,
  showStepOneCompletedBanner: false,
  showCompleteDialog: false,
  openUserCapReachedDialog: false,
  stepBtnDisabled: true,
})

const defaultInitialState: WelcomeOfferState = new DefaultInitialState()

export const welcomeOfferReducer = (
  state: WelcomeOfferState = defaultInitialState,
  action: AnyAction,
): WelcomeOfferState => {
  switch (action.type) {
    case AccountActionTypes.LOGOUT: {
      return new DefaultInitialState().set('numUserLeft', state.numUserLeft)
    }
    case WelcomeOfferActionTypes.SET_NUM_USER_LEFT: {
      return state.set('numUserLeft', action.payload)
    }
    case WelcomeOfferActionTypes.SET_OPEN_ADD_TWITTER_DIALOG: {
      return state.set('openAddTwitterDialog', action.payload)
    }
    case WelcomeOfferActionTypes.SET_OPEN_SIGN_TRANSACTION_DIALOG: {
      return state.set('openSignTransactionDialog', action.payload)
    }
    case WelcomeOfferActionTypes.SET_OPEN_DEPOSIT_DIALOG: {
      return state.set('openDepositDialog', action.payload)
    }
    case WelcomeOfferActionTypes.SET_OPEN_WRONG_USER_DIALOG: {
      return state.set('openWrongUserDialog', action.payload)
    }
    case WelcomeOfferActionTypes.SET_SHOW_STEP_ONE_COMPLETED_BANNER: {
      return state.set('showStepOneCompletedBanner', action.payload)
    }
    case WelcomeOfferActionTypes.SET_SHOW_COMPLETE_DIALOG: {
      return state.set('showCompleteDialog', action.payload)
    }
    case WelcomeOfferActionTypes.SET_STEP_BTN_DISABLED: {
      return state.set('stepBtnDisabled', action.payload)
    }
    case WelcomeOfferActionTypes.SET_OPEN_USER_CAP_REACHED_DIALOG: {
      return state.set('openUserCapReachedDialog', action.payload)
    }

    default: {
      return state
    }
  }
}
