import { FilledTextFieldProps, Theme, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import TextField from 'js/components/Common/TextField'

import { ReactComponent as CloseIcon } from 'assets/Cross.svg'
import { ReactComponent as SearchIcon } from 'assets/Search.svg'

import IconButton from '../IconButton'
import SvgIcon from '../SvgIcon'

interface InputClasses {
  input?: string
  labelClass?: string
  field?: string
  icon?: string
  crossBtn?: string
  dark?: boolean
  inputRoot?: string
  root?: string
  placeholder?: string
}

interface Props extends Omit<FilledTextFieldProps, 'variant' | 'onChange'> {
  input: string
  setInput?: (value: string) => void
  classes?: InputClasses
  disableCross?: boolean
}

const SearchInput: React.FC<Props> = (props: Props) => {
  const { classes: customClasses = {}, input, setInput, placeholder = 'Search', disableCross, className, ...rest } = props
  const classes = useStyles()

  const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setInput?.(ev.target.value)
  }

  const clearInput = () => {
    setInput?.('')
  }

  return (
    <TextField
      inputClasses={{
        ...customClasses,
        label: customClasses.labelClass,
        outlined: {
          root: customClasses.inputRoot,
          input: customClasses.input
        },
      }}
      prefix={<SvgIcon className={clsx(classes.searchIcon, customClasses.icon)} component={SearchIcon} />}
      suffix={(!disableCross && input) && (
        <IconButton className={clsx(classes.crossBtn, customClasses.crossBtn)} color="secondary" onClick={clearInput}>
          <CloseIcon />
        </IconButton>
      )}
      onChange={handleInputChange}
      variant="filled"
      value={input}
      placeholder={placeholder}
      {...rest}
      className={clsx(customClasses.root, className)}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  crossBtn: {
    marginRight: theme.spacing(1.25),
    padding: theme.spacing(0.5),
    '& svg': {
      '& path': {
        fill: theme.palette.text.secondary,
      },
      maxHeight: '0.5em',
      width: '0.5em',
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  inputField: {
    backgroundColor: 'transparent',
    border: 'none',
    marginLeft: theme.spacing(1),
    outline: 'none',
    padding: theme.spacing(1.5, 1.5, 1.5, 0),
    width: '100%',
  },
  light: {
    '&::placeholder': {
      color: theme.palette.text.tertiary,
    },
  },
  dark: {
    color: theme.palette.text.secondary,
    '&::placeholder': {
      color: theme.palette.text.tertiary,
    },
  },
  searchIcon: {
    minWidth: '1.25em',
    '& use': {
      fill: theme.palette.text.secondary,
    },
  },
  searchInput: {
    background: theme.palette.background.tertiary,
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '0.25em',
    display: 'flex',
    '&.focus': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}))

export default SearchInput
