import { Theme, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

import Paths from 'js/constants/paths'

interface Props {
  path?: string
  onClick?: () => void
  children: React.ReactNode
  classListItem?: string
  iconType: 'fill' | 'stroke'
  showActive?: boolean
  activePaths?: string[]
  disabledClick?: boolean
}

const ListItem: React.FC<Props> = (props: Props) => {
  const { path = '', onClick: onClickCallback, children, classListItem, iconType, showActive = true, activePaths, disabledClick = false } = props
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const theme = useTheme()
  const hideSidebar = useMediaQuery(theme.breakpoints.down('md'))
  const pathname = location.pathname
  const isHighlighted = useMemo(() => {
    if (activePaths) return activePaths.find((path) => path === pathname)
    return path === pathname || (path === Paths.Pools.List && (pathname === Paths.Pools.PerpList || pathname === Paths.Pools.SpotList))
  }, [activePaths, pathname, path])

  const onClick = () => {
    if (typeof onClickCallback === 'function') {
      onClickCallback()
    } else {
      history.push(path)
    }
  }

  return (
    <div
      role="presentation"
      className={clsx(
        classListItem,
        classes.root,
        {
          isHighlighted: path !== '' && isHighlighted && showActive,
          fill: iconType === 'fill',
          stroke: iconType === 'stroke',
          hoverEnabled: !disabledClick,
          hideSidebar
        },
      )}
      onKeyPress={() => null} // hack lint: jsx-a11y/click-events-have-key-events
      onClick={!disabledClick ? onClick : undefined}
    >
      {hideSidebar ? (
        <div className={classes.label}>
          {children}
          <div className={clsx(classes.indicator, { isHighlighted })} />
        </div>
      ) : children}
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => createStyles({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    boxSizing: 'border-box',
    textAlign: 'left',
    borderRadius: '4px',
    padding: theme.spacing(1.75),
    margin: theme.spacing(0, 2),
    whiteSpace: 'nowrap',
    '&.hideSidebar': {
      padding: 0,
      margin: 0,
      flex: 0,
    },
    '&.stroke': {
      '& path': {
        stroke: theme.palette.text.secondary,
        fill: 'unset',
      },
    },
    '&.fill': {
      '& path': {
        fill: theme.palette.text.secondary,
        stroke: 'unset',
      },
    },
    '&.isHighlighted': {
      color: theme.palette.text.primary,
      '& > div': {
        fontWeight: 700,
      },
      '&.stroke': {
        '& path': {
          stroke: theme.palette.text.primary,
          strokeWidth: 1,
          fill: 'unset',
        },
      },
      '&.fill': {
        '& path': {
          fill: theme.palette.text.primary,
          stroke: 'unset',
        },
      },
    },
    '&.hoverEnabled': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        [theme.breakpoints.down('md')]: {
          backgroundColor: 'transparent',
        },
      },
    }
  },
  indicator: {
    background: 'transparent',
    position: 'absolute',
    width: '20px',
    height: 3,
    bottom: 0,
    left: 'calc(50% - 10px)',
    '&.isHighlighted': {
      background: theme.palette.text.demexSolid,
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.75, 0),
    position: 'relative',
  },
}))

export default ListItem
