import { Popover, PopoverOrigin, PopoverProps, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { StyleUtils } from 'js/utils/styles'

export interface V2TooltipProps extends PopoverProps {
  popoverClass?: string
  pointerEvents?: boolean
}

const V2Tooltip: React.FC<V2TooltipProps> = (props: V2TooltipProps) => {
  const { anchorOrigin, children, transformOrigin, popoverClass, PaperProps = {}, pointerEvents = false, ...popoverProps } = props
  const classes = useStyles()

  const defaultAnchorOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center',
  }
  const defaultTransformOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  }

  return (
    <Popover
      {...popoverProps}
      style={!pointerEvents ? {
        pointerEvents: 'none',
      } : {}}
      anchorOrigin={anchorOrigin ?? defaultAnchorOrigin}
      transformOrigin={transformOrigin ?? defaultTransformOrigin}
      disableRestoreFocus
      PaperProps={{
        ...PaperProps,
        className: clsx(classes.popoverPaper, PaperProps.className),
      }}
    >
      <div className={clsx(classes.popover, popoverClass)}>
        {children}
      </div>
    </Popover>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  popoverPaper: {
    ...theme.typography.body4,
    lineHeight: '13px',
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.primary,
    borderRadius: '2px',
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: StyleUtils.dropShadow(theme),
    padding: theme.spacing(1, 1.5),
    maxWidth: '12.5rem',
  },
}))

export default V2Tooltip
