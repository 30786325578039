import { CircularProgress, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

interface Props extends React.HTMLAttributes<HTMLDivElement> {

}
const PageLoadFallback: React.FC<Props> = (props: Props) => {
  const { children, className, ...rest } = props
  const classes = useStyles()
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <CircularProgress />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
}))


export default PageLoadFallback
