import {
  Radio as MuiRadio, RadioProps, SvgIcon, makeStyles, createStyles, Theme,
} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { ReactComponent as RadioDefaultIcon } from 'assets/RadioDefault.svg'
import { ReactComponent as RadioEnabledIcon } from 'assets/RadioEnabled.svg'

interface Props extends RadioProps {

}

// Material ui's Radio but default to our own Radio enabled icon
const Radio: React.FC<Props> = (props: Props) => {
  const { checkedIcon, className } = props
  const classes = useStyles()
  const checkedRadioIcon = (
    <SvgIcon
      component={RadioEnabledIcon}
      viewBox="0 0 24 24"
    />
  )
  const defaultRadioIcon = (
    <SvgIcon
      className={classes.defaultRadioIcon}
      component={RadioDefaultIcon}
      viewBox="0 0 24 24"
    />
  )

  return (
    <MuiRadio
      {...props}
      className={clsx(classes.root, className)}
      checkedIcon={checkedIcon || checkedRadioIcon}
      icon={defaultRadioIcon}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    />
  )
}
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginRight: theme.spacing(0),
    '&:hover, &.Mui-Checked:hover': {
      backgroundColor: `${theme.palette.action.hover} !important`,
    },
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.background.secondary,
  },
  defaultRadioIcon: {
    '& path': {
      fill: `${theme.palette.background.secondary} !important`,
    },
  },
}))

export default Radio
