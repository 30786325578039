import { Collapse, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import RenderIfVisible from 'react-render-if-visible'

import LoadingIcon from 'js/components/Common/LoadingIcon'
import RenderGuard from 'js/components/Common/RenderGuard'
import { useGetRefHeightWidth } from 'js/hooks/useGetRefHeightWidth'
import { StyleUtils, useCommonStyles } from 'js/utils/styles'

import Tooltip from '../../Exchange/Common/Tooltip'
import EmptyRecordsCTARow from '../EmptyRecordsCtaRow'

export interface BasicCell {
  key: string
  colSpan?: number
  item: React.ReactNode | string
  tooltip?: string
  hidden?: boolean
}

export interface EmptyCellTooltip {
  key: string
  item: React.ReactNode
  isStart?: boolean
}

export interface BasicRow {
  key: string
  cells: BasicCell[]
  emptyCellTooltipStart?: EmptyCellTooltip
  emptyCellTooltipEnd?: EmptyCellTooltip
  className?: string
  value: any
  transitionIn?: boolean // needs tableContainer to be transitionable to work
}

export interface BasicTableContainer {
  headers: BasicCell[]
  rows: BasicRow[]
  tableClass?: string
  containerClass?: string
  headerRowClass?: string
  bodyRowClass?: string
  onClickRow?: (value: any) => void
  hoverRow?: boolean
  loading?: boolean
  emptyRowsHelperText?: string | React.ReactNode
  emptyCellClass?: string
  cta?: boolean
  fillerClass?: string
  headerEmptyCellClass?: string
  emptyRecordsClass?: string
  paperStyle?: React.CSSProperties
  paperClass?: string
  hasBottomLine?: boolean
  estimatedRowHeight?: number
  initialRowsToRender?: number
  secondaryText?: boolean
  disableLoadingIcon?: boolean
  isStickyHeader?: boolean
  customEmptyCondition?: boolean
  transitionable?: boolean
}

interface Props {
  tableContainer: BasicTableContainer
  tableRef?: React.RefObject<HTMLDivElement>
}

const BasicTable = (props: Props, ref: any) => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { tableContainer} = props
  const { hasBottomLine = true } = tableContainer

  const [renderedRows, setRenderedRows] = useState<React.ReactElement[]>([])
  const [loadingRenderedRows, setLoadingRenderedRows] = useState<boolean>(true)
  // using a set to keep track of rows that have transitioned in so that we don't transition again if we display the same row again
  const transitionRowKeysRef = useRef<Set<string>>(new Set())
  const startEmptyCellRef = useRef(null)
  const endEmptyCellRef = useRef(null)
  const { width: startEmptyCellWidth } = useGetRefHeightWidth(startEmptyCellRef)
  const { width: endEmptyCellWidth } = useGetRefHeightWidth(endEmptyCellRef)

  // setting loaded rendered rows here instead of rendering it directly as it may cause a lag in rendering the table
  useEffect(() => {
    const renderedRows = tableContainer.rows.map((row: BasicRow, index: number) => {      
      const startEmptyCell = (
        <TableCell ref={startEmptyCellRef} className={clsx(classes.emptyCell, tableContainer.emptyCellClass)}>
          {row.emptyCellTooltipStart && row.emptyCellTooltipStart?.item}
        </TableCell>
      )
      const endEmptyCell = (
        <TableCell ref={endEmptyCellRef} className={clsx(classes.emptyCell, tableContainer.emptyCellClass)}>
          {row.emptyCellTooltipEnd && row.emptyCellTooltipEnd?.item}
        </TableCell>
      )
      const isTransitional = row.transitionIn && tableContainer.transitionable
      const tableRowContent = (isTransitional?: boolean) => {
        return (
          <TableRow 
            className={clsx(classes.tableRow, row.className, { canHover: typeof tableContainer.onClickRow === 'function', last: index === tableContainer.rows.length - 1 && !hasBottomLine })} 
            onClick={() => handleClickRow(row.value)}>
            {startEmptyCell}
            {
              row.cells.filter((cell: BasicCell) => !cell.hidden).map((cell: BasicCell) => (
                <TableCell 
                  key={cell.key} 
                  className={clsx(isTransitional && classes.innerTransitionTableCell, tableContainer.bodyRowClass)} 
                  colSpan={cell.colSpan || 1}>
                  {cell.item}
                </TableCell>
              ))
            }
            {endEmptyCell}
          </TableRow>
        )
      }

      if (isTransitional && !transitionRowKeysRef.current.has(row.key)) {
        // collapse initially needs to be false to be closed, we then set it to true after a timeout to transition in the row
        setTimeout(() => {
          transitionRowKeysRef.current.add(row.key)
        }, 50)
      }
      
      const totalColSpan = row.cells.reduce((acc, cell) => acc + (cell.colSpan || 1), 0) + 2
      // Based off https://mui.com/material-ui/react-table/#collapsible-table
      const transitionTableRow = (
        <TableRow>
          <TableCell className={classes.outerTransitionTableCell} colSpan={totalColSpan}>
            <Collapse 
              timeout={500} 
              classes={{
                wrapperInner: clsx(classes.collapseWrapperInner, tableContainer.containerClass)
              }} 
              in={transitionRowKeysRef.current.has(row.key)}>
                {/* NOTE: Needs tableLayout:'fixed', otherwise table cells widths will not match */}
                <Table className={clsx(classes.fixedTableLayout, tableContainer.tableClass)}>
                  <TableBody>
                    {tableRowContent(true)}
                  </TableBody>
                </Table>
            </Collapse>
            {transitionRowKeysRef.current.has(row.key) && <Divider style={{marginLeft: startEmptyCellWidth, marginRight: endEmptyCellWidth}}/>}
          </TableCell>
        </TableRow>
      )

      return (<RenderIfVisible key={row.key} initialVisible={index < (tableContainer.initialRowsToRender || 30)} defaultHeight={tableContainer.estimatedRowHeight || 100} visibleOffset={5000} rootElement="tbody" placeholderElement="tr" stayRendered>
        { isTransitional ? transitionTableRow : tableRowContent() }
      </RenderIfVisible>
    )})
    setRenderedRows(renderedRows)
    setLoadingRenderedRows(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])


  const handleClickRow = (value: any) => {
    if (typeof tableContainer.onClickRow === 'function') {
      tableContainer.onClickRow(value)
    }
  }

  const tableLoading = tableContainer.loading || loadingRenderedRows
  const emptyText = tableContainer.emptyRowsHelperText ?? ''
  const hasRows = tableContainer.rows.length > 0

  return (
    <Paper className={clsx(classes.root, tableContainer.paperClass, { stickyHeader: tableContainer.isStickyHeader })} elevation={0} style={tableContainer.paperStyle}>
      <TableContainer ref={ref} className={clsx(classes.tableContainer, tableContainer.containerClass, { stickyHeader: tableContainer.isStickyHeader })}>
        <Table className={clsx(tableContainer.transitionable && classes.fixedTableLayout, tableContainer.tableClass)}>
          <TableHead className={clsx(tableContainer.isStickyHeader && classes.stickyHeaderTable)}>
            <TableRow>
              <TableCell className={clsx(classes.emptyCell, tableContainer.emptyCellClass, tableContainer.headerEmptyCellClass)} />
              {tableContainer.headers && tableContainer.headers.filter((header: BasicCell) => !header.hidden).map((header: BasicCell) => {
                const headerKey = header.key
                return header.tooltip && typeof header.item === 'string' ? (
                  <React.Fragment key={headerKey}>
                    <TableCell className={tableContainer.headerRowClass}>
                      <Tooltip title={header.tooltip}>
                        <span className={classes.tooltip}>{header.item}</span>
                      </Tooltip>
                    </TableCell>
                  </React.Fragment>
                ) : (
                  <TableCell key={headerKey} className={tableContainer.headerRowClass}>
                    {header.item}
                  </TableCell>
                )
              }
              )}
              <TableCell className={clsx(classes.emptyCell, tableContainer.emptyCellClass, tableContainer.headerEmptyCellClass)} />
            </TableRow>
          </TableHead>
          {
            tableLoading && !tableContainer.disableLoadingIcon ? (
              <TableBody>
                <TableRow>
                  <TableCell className="empty" colSpan={tableContainer.headers.length + 2}>
                    <div className={clsx(classes.loadingDiv, commonClasses.alignItemsCenter, commonClasses.justifyContentCenter)}>
                      <LoadingIcon size="2.5rem" />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <Fragment>
                <RenderGuard renderIf={!!emptyText && !hasRows}>
                  <TableBody>
                    <EmptyRecordsCTARow
                      className={clsx(classes.emptyRecordsCell, tableContainer.emptyRecordsClass, 'empty')}
                      fillerClass={tableContainer.fillerClass}
                      colSpan={tableContainer.headers.length + 2 + 5}
                      secondaryTextTemp={tableContainer.secondaryText}
                      message={emptyText}
                      cta={tableContainer.cta}
                      customEmptyCondition={tableContainer.customEmptyCondition}
                    />
                  </TableBody>
                </RenderGuard>
                <RenderGuard renderIf={!tableLoading && tableContainer.rows.length > 0}>
                  {renderedRows}
                </RenderGuard>
              </Fragment>
            )
          }
        </Table>
      </TableContainer>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
    '&.stickyHeader': {
      height: '100%',
    }
  },
  collapseWrapperInner: {
    maxWidth: 'unset'
  },
  outerTransitionTableCell: {
    padding: '0 !important',
    border: 0,
  },
  innerTransitionTableCell: {
    borderBottom: '0 !important',
  },
  fixedTableLayout: {
    tableLayout: 'fixed',
  },
  tableRow: {
    '&.canHover': {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.action.hover,
      },
    },
    '& > td': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&.empty': {
        borderBottom: 'none',
      },
      '&:last-child': {
        paddingLeft: theme.spacing(4),
      },
    },
    '&.last': {
      '& > td': {
        borderBottom: '1px solid transparent !important',
      },
    },
  },
  emptyCell: {
    border: 'none !important',
    minWidth: theme.spacing(4),
    padding: '0 !important',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing(1.5),
      width: theme.spacing(1.5),
    },
  },
  emptyRecordsCell: {
    paddingTop: '20px !important',
  },
  loadingDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(14),
    marginTop: theme.spacing(8),
  },
  tableContainer: {
    ...StyleUtils.scrollBar(theme),
    '&.stickyHeader': {
      height: '100%',
    }
  },
  stickyHeaderTable: {
    top: 0,
    position: 'sticky',
    backgroundColor: theme.palette.background.primary,
    zIndex: 1,
  }
}))
export default React.forwardRef(BasicTable)
