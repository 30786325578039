

import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'

import LoadingSkeleton from 'js/components/Common/LoadingSkeleton/LoadingSkeleton'

const TransfersFallback: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <div className={classes.box}>
      <div className={classes.container}>
        <LoadingSkeleton width={isMobile ? undefined : 320} paddingBottom={48} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.background.base,
    padding: theme.spacing(4),
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(4, 4, 8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0, 7),
    },
  },
  container: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.primary,
    padding: theme.spacing(4),
    height: '800px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      height: '600px',
    }
  },
}))

export default TransfersFallback
