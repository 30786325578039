import BigNumber from 'bignumber.js'
import { CarbonSDK, TypeUtils, WSModels } from 'carbon-js-sdk'
import { EModeCategory } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/e_mode_category'
import { Network, uscUsdValue } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import dayjs from 'dayjs'

import { nitronBannerWhitelist, usdGroupedToken } from 'js/constants/assets'
import { Pool } from 'js/models/Pool'
import { WalletBalance } from 'js/state/modules/walletBalance/types'
import { CDPAccountDebt, CDPAccountView, CDPAsset, Collateral, DebtableAsset, DebtType, LendingAssetRowData, LVLStats, RewardType } from 'types/cdp'
import { TokenPrice } from 'types/price'

import { chainLabel } from './externalTransfer'
import { sortNumbers, sortStrings } from './misc'
import { BN_ONE, BN_ZERO, parseNumber } from './number'
import { getCibtDenom, getTokenName, getUnderlyingDenom } from './strings'
import { SortDirection, SortProps } from './table'
import { SimpleMap } from './types'

export interface AccountBorrowedAsset {
  asset: CDPAsset
  debt: CDPAccountDebt
}

export interface UnlockRatios {
  loanToValue: BigNumber
  liquidationThreshold: BigNumber
}


// Helper functions
function getOutstandingRewardsUpToLastUpdatedTime(rewardPershare: BigNumber, userShare: BigNumber, rewardDebtAmt: BigNumber) {
  return rewardPershare.times(userShare).minus(rewardDebtAmt)
}

function getOutstandingRewardsFromLastUpdatedTimeToFuture(rewardLastUpdated: dayjs.Dayjs, future: dayjs.Dayjs, userShare: BigNumber, totalShares: BigNumber, rewardAmtPerSecond: BigNumber) {
  const userShareProp = totalShares.isZero() ? BN_ZERO : userShare.div(totalShares)
  const diffSeconds = future.diff(rewardLastUpdated, 'seconds')
  return userShareProp.times(diffSeconds).times(rewardAmtPerSecond)
}

/**
 * Calculates outstanding rewards (in raw format) that user stands to gain from reward scheme (if scheme is active)
 * @param scheme scheme data
 * @param userShare user tokens (use cdp token for lend schemes, debt for borrow schemes and minted tokens for mint schemes)
 * @param totalShares total supply of token (use total cdp token for lend schemes, total debt for borrow schemes and total minted tokens for mint schemes)
 * @param rewardDebtAmt amount user has claimed before
 * @param sdk SDK instance
 * @param endLimitDate end date for the period in question
 * @returns number of tokens as outstanding rewards in BigNumber format
 */
function getActiveOutstandingRewards(scheme: WSModels.RewardScheme, userShare: BigNumber, totalShares: BigNumber, rewardDebtAmt: BigNumber, sdk: CarbonSDK, endLimitDate: dayjs.Dayjs) {
  const rewardPerShare = parseNumber(scheme.reward_per_share, BN_ZERO)!
  const outstandingRewardsUpToLastUpdatedTime = getOutstandingRewardsUpToLastUpdatedTime(rewardPerShare, userShare, rewardDebtAmt)

  const schemeEndTime = dayjs.utc(scheme.end_time)
  const rewardLastUpdated = dayjs.utc(scheme.reward_per_share_last_updated_at)
  const rewardAmtPerSecond = parseNumber(scheme.reward_amount_per_second, BN_ZERO)!
  const endLimitTime = endLimitDate.isAfter(schemeEndTime) || endLimitDate.isSame(schemeEndTime) ? schemeEndTime : endLimitDate
  const outstandingRewardsFromLastUpdatedTimeToNow = getOutstandingRewardsFromLastUpdatedTimeToFuture(rewardLastUpdated, endLimitTime, userShare, totalShares, rewardAmtPerSecond)
  const reward = outstandingRewardsUpToLastUpdatedTime.plus(outstandingRewardsFromLastUpdatedTimeToNow)
  return reward ?? BN_ZERO
}

/**
 * Calculates outstanding rewards (in raw format) that user stands to gain from reward scheme (if scheme has expired)
 * @param scheme scheme data
 * @param userShare user tokens (use cdp token for lend schemes, debt for borrow schemes and minted tokens for mint schemes)
 * @param totalShares total supply of token (use total cdp token for lend schemes, total debt for borrow schemes and total minted tokens for mint schemes)
 * @param rewardDebtAmt amount user has claimed before
 * @param sdk SDK instance
 * @returns number of tokens as outstanding rewards in BigNumber format
 */
function getPastOutstandingRewards(scheme: WSModels.RewardScheme, userShare: BigNumber, totalShares: BigNumber, rewardDebtAmt: BigNumber, sdk: CarbonSDK) {
  const rewardPerShare = parseNumber(scheme.reward_per_share, BN_ZERO)!
  const rewardLastUpdated = dayjs.utc(scheme.reward_per_share_last_updated_at)
  const endTime = dayjs.utc(scheme.end_time)
  if (rewardLastUpdated.isSame(endTime, 'seconds') || rewardLastUpdated.isAfter(endTime, 'seconds')) {
    const outstandingRewards = getOutstandingRewardsUpToLastUpdatedTime(rewardPerShare, userShare, rewardDebtAmt)
    return outstandingRewards ?? BN_ZERO
  }
  return getActiveOutstandingRewards(scheme, userShare, totalShares, rewardDebtAmt, sdk, endTime)
}

/**
 * Calculates outstanding rewards that user stands to gain from reward scheme
 * @param scheme scheme data
 * @param userShare user tokens (use cdp token for lend schemes, debt for borrow schemes and minted tokens for mint schemes)
 * @param totalShares total supply of token (use total cdp token for lend schemes, total debt for borrow schemes and total minted tokens for mint schemes)
 * @param rewardDebtAmt amount user has claimed before
 * @param sdk SDK instance
 * @param nowTime current timestamp
 * @returns number of tokens as outstanding rewards in BigNumber format
 */
export function calculateTokenRewards(scheme: WSModels.RewardScheme, userShare: BigNumber, totalShares: BigNumber, rewardDebtAmt: BigNumber, sdk: CarbonSDK, nowTime: dayjs.Dayjs): BigNumber {
  const isActive = dayjs(scheme.start_time).isBefore(nowTime) && dayjs(scheme.end_time).isAfter(nowTime)
  let outstandingRewardsTokens: BigNumber = BN_ZERO
  if (isActive) {
    outstandingRewardsTokens = getActiveOutstandingRewards(scheme, userShare, totalShares, rewardDebtAmt, sdk, nowTime)
  } else {
    outstandingRewardsTokens = getPastOutstandingRewards(scheme, userShare, totalShares, rewardDebtAmt, sdk)
  }
  return BigNumber.max(outstandingRewardsTokens, BN_ZERO) // avoid negative numbers
}

/**
 * Retrieve token price from pricing ws data
 * @param prices prices map (map by denom)
 * @param denom denom whose token price you want to retrieve
 * @param sdk CarbonSDK instance
 * @returns token price in BigNumber format
 */
export function getTokenUSDPrice(prices: TypeUtils.SimpleMap<TokenPrice>, denom: string, sdk: CarbonSDK | undefined) {
  const twapPrice = prices[denom]?.twap
  let price: BigNumber = twapPrice
  if (!price) {
    price = sdk?.token.getUSDValue(denom) ?? BN_ZERO
  }
  // If all else fails and usc usd value is 0, default to usd value of $1
  if (sdk?.token.isNativeStablecoin(denom) && price.isZero()) {
    price = uscUsdValue
  }
  return price
}

/**
 * Compute liquidation bonus for CDP collateral position.
 * @param liquidationPrice average price of collateral to be liquidated at
 * @param collateralAmountLiquidator collateral amount to be liquidated by liquidator in raw string
 * @param discount liquidation discount (in percent)
 * @param collateralDp collateral decimals
 * @returns bonus value in USD (in BigNumber format)
 */
export function getBonusAmount(liquidationPrice: string, collateralAmountLiquidator: string, discount: string, collateralDp: number): BigNumber {
  const liquidationPriceBN = bnOrZero(liquidationPrice).shiftedBy(-18)
  const bonusTokenAmount = bnOrZero(collateralAmountLiquidator).times(bnOrZero(discount).shiftedBy(-18))
  const bonusBN = bonusTokenAmount.times(liquidationPriceBN).shiftedBy(-collateralDp)
  return bonusBN
}


export function calculateBFromA(decimalsA: number, decimalsB: number, priceA: BigNumber, priceB: BigNumber, amountA: BigNumber, isRoundUp: boolean = false) {
  const diff = decimalsB - decimalsA
  const amountB = priceA.times(amountA).shiftedBy(diff).dividedBy(priceB)
  return amountB.dp(0, isRoundUp ? BigNumber.ROUND_CEIL : BigNumber.ROUND_FLOOR)
}

/**
 * Convert cdp token amount => underlying amt
 * @param cdpAmount amount of cdp token
 * @param asset cdp asset info
 * @param totalSupplyMap total supply of tokens (from sdk.query.bank.TotalSupply query)
 * @param cdpModuleBalanceMap token balances in cdp module balance
 * @returns corresponding underlying token amount (BigNumber)
 */
export function getCdpUnderlyingAmount(cdpAmount: BigNumber, asset: CDPAsset, totalSupplyMap: SimpleMap<BigNumber>, cdpModuleBalanceMap: SimpleMap<BigNumber>): BigNumber {
  const totalSupplyBN = totalSupplyMap[asset?.assetInfo?.cibtDenom ?? ''] ?? BN_ZERO
  const cdpModuleBalance = cdpModuleBalanceMap[asset?.assetInfo?.denom ?? ''] ?? BN_ZERO
  const cdpRatio = asset.getCdpRatio(totalSupplyBN, cdpModuleBalance)
  // if cdpRatio is finite && non-zero, amount / cdpRatio. Else, return amount
  return cdpRatio.isFinite() && !cdpRatio.isZero() ? cdpAmount.div(cdpRatio) : cdpAmount
}

export function getCdpTokenUsdAmount(amount: BigNumber, tokenPrices: TypeUtils.SimpleMap<TokenPrice>, totalSupplyMap: SimpleMap<BigNumber>, cdpModuleBalanceMap: SimpleMap<BigNumber>, sdk: CarbonSDK | undefined, asset?: CDPAsset): BigNumber {
  if (!sdk || !asset || !asset.assetInfo?.cibtDenom || !CarbonSDK.TokenClient.isCdpToken(asset.assetInfo?.cibtDenom ?? '')) {
    return BN_ZERO
  }
  const underlyingDenom = getUnderlyingDenom(asset.assetInfo.cibtDenom, sdk?.token) ?? '-'
  const decimals = sdk.token.getDecimals(underlyingDenom) ?? 0
  const totalSupplyBN = totalSupplyMap[asset.assetInfo.cibtDenom] ?? BN_ZERO
  const cdpModuleBalance = cdpModuleBalanceMap[underlyingDenom] ?? BN_ZERO
  const cdpRatio = asset.getCdpRatio(totalSupplyBN, cdpModuleBalance)
  // if cdpRatio is finite && non-zero, amount / cdpRatio. Else, return amount
  const underlyingAmount = cdpRatio.isFinite() && !cdpRatio.isZero() ? amount.div(cdpRatio) : amount
  const usdValue = getTokenUSDPrice(tokenPrices, underlyingDenom, sdk)
  return underlyingAmount.times(usdValue).shiftedBy(-decimals)
}

export function getUnderlyingTokenUsdAmount(amount: BigNumber, tokenPrices: TypeUtils.SimpleMap<TokenPrice>, sdk: CarbonSDK | undefined, asset: CDPAsset): BigNumber {
  if (!sdk || !asset) {
    return BN_ZERO
  }
  const usdValue = getTokenUSDPrice(tokenPrices, asset.denom, sdk)
  return amount.times(usdValue)
}

export function getRewardSchemeKey(denom: string, type: RewardType, sdk: CarbonSDK | undefined): string {
  if (!sdk) return ''
  let tokenDenom = denom
  if (CarbonSDK.TokenClient.isCdpToken(denom)) {
    tokenDenom = getUnderlyingDenom(denom, sdk?.token) ?? ''
  }
  return `${tokenDenom}:${type}`
}

export function getOverallRewardApr(rewardsMap: TypeUtils.SimpleMap<BigNumber>, totalSharesUsd: BigNumber, sdk: CarbonSDK | undefined): BigNumber {
  // Return one share if there are no shares on the market - so that we at least get a number
  const totalSharesAdjusted = totalSharesUsd.isZero() ? BN_ONE : totalSharesUsd
  const totalRewardUsd: BigNumber = Object.keys(rewardsMap).reduce((prev: BigNumber, rewardKey: string) => {
    const decimals = sdk?.token.getDecimals(rewardKey) ?? 0
    const rewardUsd = (rewardsMap[rewardKey] ?? BN_ZERO).shiftedBy(-decimals)
    return prev.plus(rewardUsd)
  }, BN_ZERO)
  return totalRewardUsd.div(totalSharesAdjusted)
}

export function getTotalSharesUsd(debt: AccountBorrowedAsset | DebtableAsset, tokenUsdValue: BigNumber, sdk: CarbonSDK | undefined): BigNumber {
  const debtInfoA = debt.asset.getDebtInfo()
  const totalSharesA = bnOrZero(debtInfoA?.totalPrincipal)
  const tokenDecimalsA = sdk?.token.getDecimals(debt.asset.denom) ?? 0
  return totalSharesA.times(tokenUsdValue).shiftedBy(-tokenDecimalsA)
}

/**
 * Function to checks assetCap against cdp total supply.
 * Takes minimum of the 2 (ensures that limit is capped by the total supply (in case, assetCap is 0)
 * @param assetCap asset borrow cap (as indicated in cdp assets)
 * @param totalSupplied asset total supply
 * @param isBorrow is borrow scheme
 * @returns actual cap (in BigNumber)
 */
export function getActualCap(assetCap: BigNumber, totalSupplied: BigNumber, isBorrow: boolean) {
  if (!isBorrow) return assetCap
  if (assetCap.eq(1)) return BN_ZERO
  if (assetCap.eq(0)) return totalSupplied
  return BigNumber.min(totalSupplied, assetCap)
}


const collateralWithdrawBuffer = 0.999

export function getMaxCollateralForUnlock(
  accountView: CDPAccountView | undefined,
  sdk: CarbonSDK | undefined,
  asset: CDPAsset,
  unlockRatios: UnlockRatios
) {
  if (!asset || !accountView || !sdk) return BN_ZERO
  const assetInfo = asset.assetInfo
  const denom = asset.denom
  const tokenDp = sdk?.token?.getDecimals(denom) ?? 0
  const assetCollateral = accountView.collaterals[denom]
  if (!assetInfo || !assetCollateral) return BN_ZERO
  let unlockRatio = unlockRatios.loanToValue
  let maxRemovable = accountView.maxBorrowableValue.minus(accountView.debtTotalValue)

  /**
   * If network is localnet or devnet,
   * allow tester to withdraw all collateral up to HF 1 instead of usual 1.75
   * to allow for testing of liquidation on Nitron
   */
  if ([Network.DevNet, Network.LocalHost].includes(sdk.network)) {
    maxRemovable = accountView.liquidationThreshold.minus(accountView.debtTotalValue)
    unlockRatio = unlockRatios.liquidationThreshold
  }

  const unlockableUsd = maxRemovable.multipliedBy(new BigNumber(10000)).div(unlockRatio)
  const tokenTwap = assetCollateral.price
  if (tokenTwap.isZero()) {
    return BN_ZERO
  }

  const tokenAmt = unlockableUsd.div(tokenTwap).shiftedBy(tokenDp).dp(0, BigNumber.ROUND_FLOOR)
  const lockedAmount = bnOrZero(assetCollateral.eqUnderlyingAmount)
  const maxCollateralForUnlock = BigNumber.min(lockedAmount, tokenAmt)
  if (maxCollateralForUnlock.isEqualTo(lockedAmount)) return BigNumber.max(maxCollateralForUnlock, BN_ZERO)
  return BigNumber.max(maxCollateralForUnlock.times(collateralWithdrawBuffer), BN_ZERO)
}


export function getNitronAssetName(
  denom: string = '',
  pools: SimpleMap<Pool>,
  sdk: CarbonSDK | undefined,
  network?: CarbonSDK.Network,
  symbolOverride?: TypeUtils.SimpleMap<string>,
) {
  if (!sdk?.token || !network) {
    return denom
  }
  let pool = pools[denom]
  const isCdpToken = CarbonSDK.TokenClient.isCdpToken(denom)
  if (isCdpToken) {
    const underlying = getUnderlyingDenom(denom, sdk?.token) ?? ''
    pool = pools[underlying.toLowerCase()]
  }
  if (pool) {
    const tokenA = sdk.token.getTokenName(pool.denomA, symbolOverride)
    const tokenB = sdk.token.getTokenName(pool.denomB, symbolOverride)
    return isCdpToken ? `cib${tokenA}-${tokenB}` : `${tokenA}-${tokenB}`
  }
  return sdk.token.getTokenName(denom, symbolOverride)
}

export function isNitronLpToken(
  denom: string = '',
  pools: SimpleMap<Pool>,
  sdk: CarbonSDK | undefined,
): boolean {
  if (!sdk?.cdp) {
    return false
  }
  let pool = pools[denom]
  const isCdpToken = CarbonSDK.TokenClient.isCdpToken(denom)
  if (isCdpToken) {
    const underlying = getUnderlyingDenom(denom, sdk?.token) ?? ''
    pool = pools[underlying.toLowerCase()]
  }
  return Boolean(pool)
}

export const getCdpAlias = (denom: string, cdpAssetAliasMap: SimpleMap<string>): string | undefined => {
  const cdpAlias = Object.entries(cdpAssetAliasMap).find(([_, value]: [string, string]) => value === denom)
  return cdpAlias?.[0]
}

export function sortBorrowableAssets(sortedAssets: DebtableAsset[], search: string, pools: SimpleMap<Pool>, sdk: CarbonSDK | undefined, net: Network, category?: EModeCategory): DebtableAsset[] {
  const emodeDenoms = category?.denoms ?? []
  sortedAssets.sort((assetA: DebtableAsset, assetB: DebtableAsset) => {
    const denomA = assetA.asset.denom
    const denomB = assetB.asset.denom
    const lowerSearchTerm = search.toLowerCase()

    if (!lowerSearchTerm && emodeDenoms.length) {
      const isValidA = emodeDenoms.includes(denomA)
      const isValidB = emodeDenoms.includes(denomB)

      return +(isValidB) - +(isValidA)
    }
    return compareCDPAssetSearch(denomA, denomB, lowerSearchTerm, pools, sdk, net)
  })
  return sortedAssets
}

export function compareCDPAssetSearch(denomA: string, denomB: string, search: string, pools: SimpleMap<Pool>, sdk: CarbonSDK | undefined, net: Network): number {
  if (search.length > 0) {
    const lowerAssetADisplayName = getNitronAssetName(denomA, pools, sdk, net).toLowerCase()
    const lowerAssetBDisplayName = getNitronAssetName(denomB, pools, sdk, net).toLowerCase()
    const hasSearchTermA = lowerAssetADisplayName.includes(search)
    const hasSearchTermB = lowerAssetBDisplayName.includes(search)
    return +(hasSearchTermB) - +(hasSearchTermA)
  }
  return 0
}

const isMultiChainDenom = (denom: string | undefined) => {
  if (!denom) return false
  return [usdGroupedToken, 'swth'].includes(denom)
}

export function renameChain(
  asset: CDPAsset,
  tokenClient?: CarbonSDK.TokenClient,
) {
  const chainName = tokenClient?.getBlockchainV2(asset.denom) ?? ''
  if (chainName && chainName === 'Native') {
    return isMultiChainDenom(asset.denom) ? 'Multi-Chain' : chainLabel(chainName)
  }
  return chainName
}


// EMODE
export enum EModeAction {
  'Enable',
  'Disable',
  'Switch',
}

export const getInvalidDebts = ({
  validDenoms,
  debts,
}: {
  validDenoms: string[],
  debts: AccountBorrowedAsset[]
}) => (debts.filter(({ debt }) => (!validDenoms.includes(debt.denom))))


export const getProjectedLTVStats = ({
  emodeCategory,
  sdk,
  cdpAssets,
  totalSupplyMap,
  lentBalances,
  collaterals,
  accountView,
  prices,
  options,
}: {
  emodeCategory?: EModeCategory,
  sdk?: CarbonSDK,
  cdpAssets: TypeUtils.SimpleMap<CDPAsset>,
  accountView: CDPAccountView,
  totalSupplyMap: TypeUtils.SimpleMap<BigNumber>,
  lentBalances: TypeUtils.SimpleMap<BigNumber>,
  collaterals: Collateral[],
  prices: TypeUtils.SimpleMap<TokenPrice>,
  options?: {
    useNet?: boolean
  },
}): LVLStats => {
  const stats: LVLStats = {
    maxLtv: BN_ZERO,
    liquidationThreshold: BN_ZERO,
    maxBorrowableValue: BN_ZERO,
    collateralTotalValue: BN_ZERO,
  }

  if (!sdk) return stats

  const hasCollaterals = !!collaterals.length && bnOrZero(accountView?.collateralTotalValue).gte(0)
  for (const [denom, cdpAsset] of Object.entries(cdpAssets)) {
    const cibtDenom = getCibtDenom(denom)
    const debtInfo = cdpAsset.getDebtInfo()

    const tokenDecimals = sdk?.token.getDecimals(denom) ?? 0
    const totalCdpSupply = totalSupplyMap[cibtDenom] ?? BN_ZERO

    let cdpRatio = BN_ONE
    if (debtInfo) {
      cdpRatio = cdpAsset.getCdpRatio(totalCdpSupply, lentBalances[denom] ?? BN_ZERO)
    }

    // find price for denom
    let price: BigNumber = bnOrZero(prices[denom]?.twap)
    if (!price) {
      price = sdk?.token.getUSDValue(denom) ?? BN_ZERO

      // If all else fails and usc usd value is 0, default to usd value of $1
      if (sdk?.token.isNativeStablecoin(denom) && price.isZero()) {
        price = uscUsdValue
      }
    }

    const collateral = collaterals.find((collateral) => collateral.denom === denom)

    if (!collateral) continue
    const isEModeDenom = !emodeCategory ? false : emodeCategory.denoms.includes(denom)
    const cdpAmount = bnOrZero(collateral.collateral_amount)
    const eqUnderlyingAmount = cdpAmount.dividedToIntegerBy(cdpRatio)

    const assetCollateralValue = eqUnderlyingAmount.shiftedBy(-tokenDecimals).times(price)
    stats.collateralTotalValue = stats.collateralTotalValue.plus(assetCollateralValue)

    const ltv = (emodeCategory && isEModeDenom) ? emodeCategory.loanToValue : cdpAsset.assetInfo?.loanToValue
    const borrowableValue = assetCollateralValue.times(bnOrZero(ltv).shiftedBy(-4))
    stats.maxBorrowableValue = stats.maxBorrowableValue.plus(borrowableValue)
    // check for liquidation threshold
    if (cdpAmount.gt(0)) {
      if (!cdpAsset.assetInfo?.liquidationThreshold) {
        console.warn('cdp liquidation threshold not found for', denom)
      } else {
        const liqThreshold = (emodeCategory && isEModeDenom) ? emodeCategory.liquidationThreshold : cdpAsset.assetInfo.liquidationThreshold
        const assetLiquidationThreshold = assetCollateralValue.times(bnOrZero(liqThreshold).shiftedBy(-4))
        stats.liquidationThreshold = stats.liquidationThreshold.plus(assetLiquidationThreshold)
      }
    }
  }

  const maxLtv = bnOrZero(stats.maxBorrowableValue.div(stats.collateralTotalValue))
  stats.maxLtv = (maxLtv.isNaN() || !maxLtv.isFinite()) ? new BigNumber(0.65) : maxLtv

  // for net projections, independent of collaterals
  const isNet: boolean = options?.useNet ?? !hasCollaterals
  if (isNet && emodeCategory) {
    stats.maxLtv = bnOrZero(emodeCategory.loanToValue).shiftedBy(-4)
  }

  return stats
}
/**
  * closeFactor determines the proportion of collateral value that can be liquidated once HF < 1
  * closeFactor scales linearly from 0.1 at liquidation threshold to 1 at critical borrowed value
  * closeFactor is 0 for any HF > 1, capped at 1 for any debtTotalValue > criticalBorrowValue
  *
  * CBV = LiqThresh + (CollatVal - LiqThresh) * completeLiqThresh
  * Close Factor:
  *  -- 0 if HF > 1, else:
  *    -- 1 if debtTotalValue < smallLiquidationSize
  *    -- ((DTV - LT) / (CBV - LT))  * (1 - minCloseFactor) + minCloseFactor if debtTotalValue < CBV else 1
  */
export const calculateCloseFactor = (
  debtTotalValue: BigNumber,
  collateralTotalValue: BigNumber,
  liquidationThresholdValue: BigNumber,
  completeLiquidationThreshold: BigNumber,
  minimumCloseFactor: BigNumber,
  smallLiquidationSize: BigNumber
) => {
  const healthFactor = liquidationThresholdValue.div(debtTotalValue)

  const criticalBorrowValue = liquidationThresholdValue.plus((collateralTotalValue.minus(liquidationThresholdValue)).times(completeLiquidationThreshold))
  let closeFactor = BN_ZERO

  if (healthFactor.lte(1)) {
    if (debtTotalValue.lt(smallLiquidationSize)) return BN_ONE
    const gradient = BN_ONE.minus(minimumCloseFactor).div(criticalBorrowValue.minus(liquidationThresholdValue))
    closeFactor = (debtTotalValue.minus(liquidationThresholdValue)).times(gradient).plus(minimumCloseFactor)
    if (closeFactor.gt(1)) return BN_ONE
  }
  return closeFactor
}

export const getSortNewAssets = (newlyAddedTokens: Set<string>, assetADenom: string, assetBDenom: string) => {
  const aIsNewlyAdded = newlyAddedTokens.has(assetADenom)
  const bIsNewlyAdded = newlyAddedTokens.has(assetBDenom)
  if (aIsNewlyAdded && !bIsNewlyAdded) return -1
  if (bIsNewlyAdded && !aIsNewlyAdded) return 1
  return 0
}

export const getSortedPriority = (addRewardsUsd: SimpleMap<SimpleMap<BigNumber>>, sdk: CarbonSDK | undefined, asset: CDPAsset | DebtableAsset) => {
  let isAble
  let hasRewards
  let hasAirdrop

  if (asset instanceof CDPAsset) {
    isAble = asset.assetInfo?.supplyCap !== '1'
    const schemeKey = getRewardSchemeKey(asset.denom, RewardType.Lend, sdk)
    hasRewards = !!addRewardsUsd[schemeKey]
    hasAirdrop = nitronBannerWhitelist[asset.denom]
  } else {
    isAble = asset.isBorrowableOrMintable
    const schemeKey = getRewardSchemeKey(asset.asset.denom, asset.isBorrow ? RewardType.Borrow : RewardType.Mint, sdk)
    hasRewards = !!addRewardsUsd[schemeKey]
    hasAirdrop = nitronBannerWhitelist[asset.asset.denom]
  }

  if (hasRewards) return 1
  if (hasAirdrop) return 2
  if (isAble) return 3
  return 4
}

export const sortLendByAvailableUsd = (assetsData: CDPAsset[], search: string, pools: SimpleMap<Pool>, sdk: CarbonSDK | undefined, net: CarbonSDK.Network, direction: SortDirection, balances: TypeUtils.SimpleMap<WalletBalance>, cdpTokenPrices: TypeUtils.SimpleMap<TokenPrice>) => {
  return assetsData.sort((assetA: CDPAsset, assetB: CDPAsset) => {
    // if user inputs search term, bump all search items to the top first
    const compareSearch = compareCDPAssetSearch(assetA.denom, assetB.denom, search.toLowerCase(), pools, sdk, net)
    if (compareSearch !== 0) return compareSearch

    const dpAssetA = sdk?.token.getDecimals(assetA.denom) ?? 0
    const availBalanceA = balances[assetA.denom]?.available ?? BN_ZERO
    const availBalanceUsdA = availBalanceA.times(getTokenUSDPrice(cdpTokenPrices, assetA.denom, sdk)).shiftedBy(-dpAssetA)

    const dpAssetB = sdk?.token.getDecimals(assetB.denom) ?? 0
    const availBalanceB = balances[assetB.denom]?.available ?? BN_ZERO
    const availBalanceUsdB = availBalanceB.times(getTokenUSDPrice(cdpTokenPrices, assetB.denom, sdk)).shiftedBy(-dpAssetB)
    return sortNumbers(direction, availBalanceUsdA, availBalanceUsdB)
  })
}

export const defaultSortLendByGlobalUsd = (assetsData: CDPAsset[], search: string, pools: SimpleMap<Pool>, sdk: CarbonSDK | undefined, net: CarbonSDK.Network, sort: SortProps, lendableNewlyAddedDenoms: Set<string>, cdpTotalSupplyMap: TypeUtils.SimpleMap<BigNumber>, cdpTokenPrices: TypeUtils.SimpleMap<TokenPrice>, cdpModuleBalances: TypeUtils.SimpleMap<BigNumber>, addRewardsUsd: SimpleMap<SimpleMap<BigNumber>>) => {
  return assetsData.sort((assetA: CDPAsset, assetB: CDPAsset) => {
    // if user inputs search term, bump all search items to the top first
    const compareSearch = compareCDPAssetSearch(assetA.denom, assetB.denom, search.toLowerCase(), pools, sdk, net)
    if (compareSearch !== 0) return compareSearch

    // default only, before user click any sort header
    if (!sort.prop) {
      // if there is newly added token, bump all new tokens to the top
      const result = getSortNewAssets(lendableNewlyAddedDenoms, assetA.cibtDenom, assetB.cibtDenom)
      if (result === 1 || result === -1) return result

      const priorityA = getSortedPriority(addRewardsUsd, sdk, assetA)
      const priorityB = getSortedPriority(addRewardsUsd, sdk, assetB)
      if (priorityA !== priorityB) return priorityA - priorityB
    }

    const cdpTotalSupplyA = cdpTotalSupplyMap[assetA.cibtDenom] ?? BN_ZERO
    const totalSupplyUsdA = getCdpTokenUsdAmount(cdpTotalSupplyA, cdpTokenPrices, cdpTotalSupplyMap, cdpModuleBalances, sdk, assetA)

    const cdpTotalSupplyB = cdpTotalSupplyMap[assetB.cibtDenom] ?? BN_ZERO
    const totalSupplyUsdB = getCdpTokenUsdAmount(cdpTotalSupplyB, cdpTokenPrices, cdpTotalSupplyMap, cdpModuleBalances, sdk, assetB)
    return sortNumbers(sort.direction, totalSupplyUsdA, totalSupplyUsdB)
  })
}

export const sortLendByApr = (assetsData: CDPAsset[], search: string, pools: SimpleMap<Pool>, sdk: CarbonSDK | undefined, net: CarbonSDK.Network, direction: SortDirection) => {
  return assetsData.sort((assetA: CDPAsset, assetB: CDPAsset) => {
    // if user inputs search term, bump all search items to the top first
    const compareSearch = compareCDPAssetSearch(assetA.denom, assetB.denom, search.toLowerCase(), pools, sdk, net)
    if (compareSearch !== 0) return compareSearch

    const lendAprA = assetA.getLendAPY()
    const lendAprB = assetB.getLendAPY()
    return sortNumbers(direction, lendAprA, lendAprB)
  })
}

export const sortLendByAsset = (assetsData: CDPAsset[], search: string, pools: SimpleMap<Pool>, sdk: CarbonSDK | undefined, net: CarbonSDK.Network, direction: SortDirection) => {
  return assetsData.sort((rowA: CDPAsset, rowB: CDPAsset) => {
    // if user inputs search term, bump all search items to the top first
    const compareSearch = compareCDPAssetSearch(rowA.denom, rowB.denom, search.toLowerCase(), pools, sdk, net)
    if (compareSearch !== 0) return compareSearch

    const assetA = getNitronAssetName(rowA.denom, pools, sdk, net).toLowerCase() || ''
    const assetB = getNitronAssetName(rowB.denom, pools, sdk, net).toLowerCase() || ''
    return sortStrings(direction, assetA, assetB)
  })
}

export const sortLendingByAsset = (assetsData: LendingAssetRowData[], direction: SortDirection, sdk: CarbonSDK | undefined, net: CarbonSDK.Network) => {
  return assetsData.sort((rowA: LendingAssetRowData, rowB: LendingAssetRowData) => {
    const assetA = getTokenName(rowA.asset.denom, sdk?.token, net).toLowerCase() || ''
    const assetB = getTokenName(rowB.asset.denom, sdk?.token, net).toLowerCase() || ''
    return sortStrings(direction, assetA, assetB)
  })
}

export const sortLendingByAmount = (assetsData: LendingAssetRowData[], direction: SortDirection, cdpTokenPrices: TypeUtils.SimpleMap<TokenPrice>, cdpTotalSupplyMap: TypeUtils.SimpleMap<BigNumber>, cdpModuleBalances: TypeUtils.SimpleMap<BigNumber>, sdk: CarbonSDK | undefined) => {
  return assetsData.sort((rowA: LendingAssetRowData, rowB: LendingAssetRowData) => {
    const totalLentA = rowA.availBalance.plus(rowA.collateralBalance)
    const totalLentUsdA = getCdpTokenUsdAmount(totalLentA, cdpTokenPrices, cdpTotalSupplyMap, cdpModuleBalances, sdk, rowA.asset)

    const totalLentB = rowB.availBalance.plus(rowB.collateralBalance)
    const totalLentUsdB = getCdpTokenUsdAmount(totalLentB, cdpTokenPrices, cdpTotalSupplyMap, cdpModuleBalances, sdk, rowB.asset)
    return sortNumbers(direction, totalLentUsdA, totalLentUsdB)
  })
}

export const sortLendingByApr = (assetsData: LendingAssetRowData[], direction: SortDirection) => {
  return assetsData.sort((rowA: LendingAssetRowData, rowB: LendingAssetRowData) => {
    const lendAprA = rowA.asset.getLendAPY()
    const lendAprB = rowB.asset.getLendAPY()
    return sortNumbers(direction, lendAprA, lendAprB)
  })
}

export const sortLendingByCollateral = (assetsData: LendingAssetRowData[], direction: SortDirection, sdk: CarbonSDK | undefined) => {
  return assetsData.sort((rowA: LendingAssetRowData, rowB: LendingAssetRowData) => {
    const tokenDpA = sdk?.token.getDecimals(rowA.asset.denom) ?? 0
    const totalCollateralA = rowA.collateralBalance.shiftedBy(-tokenDpA)
    const tokenDpB = sdk?.token.getDecimals(rowB.asset.denom) ?? 0
    const totalCollateralB = rowB.collateralBalance.shiftedBy(-tokenDpB)
    return sortNumbers(direction, totalCollateralA, totalCollateralB)
  })
}

export const defaultSortBorrowByAvailableValue = (assetsData: DebtableAsset[], sort: SortProps, sdk: CarbonSDK | undefined, net: CarbonSDK.Network, borrowableNewlyAddedDenoms: Set<string>, addRewardsUsd: SimpleMap<SimpleMap<BigNumber>>, cdpModuleBalances: TypeUtils.SimpleMap<BigNumber>, cdpTokenPrices: TypeUtils.SimpleMap<TokenPrice>, totalSupply: SimpleMap<BigNumber>) => {
  return assetsData.sort((assetA: DebtableAsset, assetB: DebtableAsset) => {
    if (!sort.prop) {
      // if there is newly added token, bump all new tokens to the top
      const result = getSortNewAssets(borrowableNewlyAddedDenoms, assetA.asset.cibtDenom, assetB.asset.cibtDenom)
      if (result === 1 || result === -1) return result

      const priorityA = getSortedPriority(addRewardsUsd, sdk, assetA)
      const priorityB = getSortedPriority(addRewardsUsd, sdk, assetB)
      if (priorityA !== priorityB) return priorityA - priorityB
    }

    const cdpAssetA = assetA.asset
    const cdpAssetB = assetB.asset

    const cdpModuleBalanceA = cdpModuleBalances[cdpAssetA.denom] ?? BN_ZERO
    const cdpModuleBalanceB = cdpModuleBalances[cdpAssetB.denom] ?? BN_ZERO
    const cdpBorrowCapA = cdpAssetA.getBorrowCap()
    const availableBeforeCapA = cdpBorrowCapA.minus(bnOrZero(cdpAssetA.getDebtInfo()?.totalPrincipal))
    let availBalanceA = BigNumber.min(cdpModuleBalanceA, availableBeforeCapA)
    const cdpBorrowCapB = cdpAssetB.getBorrowCap()
    const availableBeforeCapB = cdpBorrowCapB.minus(bnOrZero(cdpAssetB.getDebtInfo()?.totalPrincipal))
    let availBalanceB = BigNumber.min(cdpModuleBalanceB, availableBeforeCapB)

    if (cdpAssetA.isStablecoin && !assetA.isBorrow) {
      const debtInfoA = cdpAssetA.getMintInfo()
      const totalPrincipalA = bnOrZero(debtInfoA?.totalPrincipal)
      const mintCapA = cdpAssetA.getMintCap()
      const totalMintedA = net === CarbonSDK.Network.MainNet ? totalSupply[cdpAssetA.denom ?? ''] : totalPrincipalA
      availBalanceA = bnOrZero(mintCapA).minus(totalMintedA)
    }

    if (cdpAssetB.isStablecoin && !assetB.isBorrow) {
      const debtInfoB = cdpAssetB.getMintInfo()
      const totalPrincipalB = bnOrZero(debtInfoB?.totalPrincipal)
      const mintCapB = cdpAssetB.getMintCap()
      const totalMintedB = net === CarbonSDK.Network.MainNet ? totalSupply[cdpAssetB.denom ?? ''] : totalPrincipalB
      availBalanceB = bnOrZero(mintCapB).minus(totalMintedB)
    }

    const tokenUsdValueA = getTokenUSDPrice(cdpTokenPrices, assetA.asset.denom, sdk)
    const tokenDpA = sdk?.token.getDecimals(assetA.asset.denom) ?? 0
    const availBalanceValueA = availBalanceA.times(tokenUsdValueA).shiftedBy(-tokenDpA)

    const tokenUsdValueB = getTokenUSDPrice(cdpTokenPrices, assetB.asset.denom, sdk)
    const tokenDpB = sdk?.token.getDecimals(assetB.asset.denom) ?? 0
    const availBalanceValueB = availBalanceB.times(tokenUsdValueB).shiftedBy(-tokenDpB)
    return sortNumbers(sort.direction, availBalanceValueA, availBalanceValueB)
  })
}

export const sortBorrowByInterest = (assetsData: DebtableAsset[], direction: SortDirection, sdk: CarbonSDK | undefined, addRewardsUsd: SimpleMap<SimpleMap<BigNumber>>, cdpTokenPrices: TypeUtils.SimpleMap<TokenPrice>) => {
  return assetsData.sort((assetA: DebtableAsset, assetB: DebtableAsset) => {
    const interestA = assetA.isBorrow ? assetA.asset.getBorrowAPY() : assetA.asset.getMintAPY()
    const interestB = assetB.isBorrow ? assetB.asset.getBorrowAPY() : assetB.asset.getMintAPY()

    // for additional rewards
    const rewardTypeA = assetA.isBorrow ? RewardType.Borrow : RewardType.Mint
    const tokenUsdValueA = getTokenUSDPrice(cdpTokenPrices, assetA.asset.denom, sdk)
    const totalSharesUsdA = getTotalSharesUsd(assetA, tokenUsdValueA, sdk)
    const schemeKeyA = getRewardSchemeKey(assetA.asset.denom, rewardTypeA, sdk)
    const rewardsMapA = addRewardsUsd[schemeKeyA] ?? {}
    const rewardsAprA = getOverallRewardApr(rewardsMapA, totalSharesUsdA, sdk)

    const rewardTypeB = assetB.isBorrow ? RewardType.Borrow : RewardType.Mint
    const tokenUsdValueB = getTokenUSDPrice(cdpTokenPrices, assetB.asset.denom, sdk)
    const totalSharesUsdB = getTotalSharesUsd(assetB, tokenUsdValueB, sdk)
    const schemeKeyB = getRewardSchemeKey(assetB.asset.denom, rewardTypeB, sdk)
    const rewardsMapB = addRewardsUsd[schemeKeyB] ?? {}
    const rewardsAprB = getOverallRewardApr(rewardsMapB, totalSharesUsdB, sdk)

    const aprA = BigNumber.max(interestA, rewardsAprA)
    const aprB = BigNumber.max(interestB, rewardsAprB)
    return sortNumbers(direction, aprA, aprB)
  })
}

export const sortBorrowByAsset = (assetsData: DebtableAsset[], direction: SortDirection, pools: SimpleMap<Pool>, sdk: CarbonSDK | undefined, net: CarbonSDK.Network) => {
  return assetsData.sort((rowA: DebtableAsset, rowB: DebtableAsset) => {
    const assetA = getNitronAssetName(rowA.asset.denom, pools, sdk, net).toLowerCase() || ''
    const assetB = getNitronAssetName(rowB.asset.denom, pools, sdk, net).toLowerCase() || ''
    return sortStrings(direction, assetA, assetB)
  })
}

export const sortBorrowedByAsset = (debtsData: AccountBorrowedAsset[], direction: SortDirection, pools: SimpleMap<Pool>, sdk: CarbonSDK | undefined, net: CarbonSDK.Network) => {
  return debtsData.sort((rowA: AccountBorrowedAsset, rowB: AccountBorrowedAsset) => {
    const assetA = getNitronAssetName(rowA.asset.denom, pools, sdk, net).toLowerCase() || ''
    const assetB = getNitronAssetName(rowB.asset.denom, pools, sdk, net).toLowerCase() || ''
    return sortStrings(direction, assetA, assetB)
  })
}

export const sortBorrowedByInterest = (debtsData: AccountBorrowedAsset[], direction: SortDirection, sdk: CarbonSDK | undefined, addRewardsUsd: SimpleMap<SimpleMap<BigNumber>>, cdpTokenPrices: TypeUtils.SimpleMap<TokenPrice>) => {
  return debtsData.sort((debtA: AccountBorrowedAsset, debtB: AccountBorrowedAsset) => {
    const interestA = debtA.debt.type === DebtType.Borrow ? debtA.asset.getBorrowAPY() : debtA.asset.getMintAPY()
    const interestB = debtB.debt.type === DebtType.Borrow ? debtB.asset.getBorrowAPY() : debtB.asset.getMintAPY()

    // for additional rewards
    const rewardTypeA = debtA.debt.type === DebtType.Borrow ? RewardType.Borrow : RewardType.Mint
    const tokenUsdValueA = getTokenUSDPrice(cdpTokenPrices, debtA.asset.denom, sdk)
    const totalSharesUsdA = getTotalSharesUsd(debtA, tokenUsdValueA, sdk)
    const schemeKeyA = getRewardSchemeKey(debtA.asset.denom, rewardTypeA, sdk)
    const rewardsMapA = addRewardsUsd[schemeKeyA] ?? {}
    const rewardsAprA = getOverallRewardApr(rewardsMapA, totalSharesUsdA, sdk)

    const rewardTypeB = debtB.debt.type === DebtType.Borrow ? RewardType.Borrow : RewardType.Mint
    const tokenUsdValueB = getTokenUSDPrice(cdpTokenPrices, debtB.asset.denom, sdk)
    const totalSharesUsdB = getTotalSharesUsd(debtB, tokenUsdValueB, sdk)
    const schemeKeyB = getRewardSchemeKey(debtB.asset.denom, rewardTypeB, sdk)
    const rewardsMapB = addRewardsUsd[schemeKeyB] ?? {}
    const rewardsAprB = getOverallRewardApr(rewardsMapB, totalSharesUsdB, sdk)

    const aprA = BigNumber.max(interestA, rewardsAprA)
    const aprB = BigNumber.max(interestB, rewardsAprB)
    return sortNumbers(direction, aprA, aprB)
  })
}

export const sortBorrowedByDebt = (debtsData: AccountBorrowedAsset[], direction: SortDirection, sdk: CarbonSDK | undefined) => {
  return debtsData.sort((assetA: AccountBorrowedAsset, assetB: AccountBorrowedAsset) => {
    const debtA = assetA.debt.getCurrentTotalDebt(assetA.asset?.calculateCIM(0, assetA.debt.type))
    const debtB = assetB.debt.getCurrentTotalDebt(assetB.asset?.calculateCIM(0, assetB.debt.type))

    const decimalsA = sdk?.token.getDecimals(assetA.asset?.denom ?? '') ?? 0
    const decimalsB = sdk?.token.getDecimals(assetB.asset?.denom ?? '') ?? 0
    const debtAmountA = debtA.shiftedBy(-decimalsA).times(assetA.debt?.price ?? BN_ZERO)
    const debtAmountB = debtB.shiftedBy(-decimalsB).times(assetB.debt?.price ?? BN_ZERO)
    return sortNumbers(direction, debtAmountA, debtAmountB)
  })
}
