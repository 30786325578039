import BigNumber from 'bignumber.js'
import { WSModels } from 'carbon-js-sdk'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getMarket } from 'js/state/modules/exchange/selectors'
import { getRecentTrades } from 'js/state/modules/recentTrades/selectors'

const parseRecentTrades = (baseDp: number, quoteDp: number, recentTrades: WSModels.RecentTrade[]) => {
  if (!recentTrades || recentTrades.length <= 0) {
    return []
  }

  return recentTrades.map((trade: WSModels.RecentTrade) => {
    const priceBN = new BigNumber(trade.price).shiftedBy(baseDp - quoteDp)
    const quantityBN = new BigNumber(trade.quantity).shiftedBy(-baseDp)
    return {
      ...trade,
      price: priceBN.toString(10),
      quantity: quantityBN.toString(10),
    }
  })
}

const useAdjustedRecentTradesByMarket = () => {
  const market = useSelector(getMarket)
  const recentTrades = useSelector(getRecentTrades)

  const adjustedRecentTrades = useMemo(() => {
    const baseDp = market?.basePrecision.toNumber() ?? 0
    const quoteDp = market?.quotePrecision.toNumber() ?? 0

    const adjustedRecentTrades = parseRecentTrades(baseDp, quoteDp, recentTrades)
    return adjustedRecentTrades
  }, [market, recentTrades])

  return adjustedRecentTrades
}

export default useAdjustedRecentTradesByMarket
