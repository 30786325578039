import BigNumber from 'bignumber.js'
import { CarbonSDK, TypeUtils } from 'carbon-js-sdk'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'

import { TxError } from 'js/constants/error'
import { WalletBalance } from 'js/state/modules/walletBalance/types'
import { CDPAccountDebt, CDPAccountView, CDPAsset, DebtType, RewardType } from 'types/cdp'
import { TokenPrice } from 'types/price'

import { getTokenUSDPrice, UnlockRatios } from './cdp'
import { sortNumbers, sortStrings } from './misc'
import { BN_ONE, BN_ZERO } from './number'
import { getCdpAccountDebtsKey, getCibtDenom } from './strings'
import { SortDirection } from './table'
import { SimpleMap } from './types'
import { ErrorMap } from './validation'

export const cdpErrorMsgs: ErrorMap = {
  general: 'This transaction has failed. Please try again.',
  disabled: 'This action for this asset for the time-being is disabled. Please try again later.',
  disabledForRepay: 'This asset is disabled for this action at the time. Please select an alternate asset with sufficient balance to complete the action.',
  lowBalance: 'This action cannot be done as your balance is insufficient. Please top up your balance.',
  lowCollateral: 'This action cannot be done as your collateral is insufficient. Please top up your collateral.',
  invalidInput: 'Please input a valid amount.',
  inputGtLimitSpecific: 'Your entered amount exceeds the txType limit. Please enter an amount within the valid range.',
  inputGtMax: 'The amount you have input is greater than the maximum amount. Please input a valid amount.',
  inputLtMin: 'The amount you have input is lesser than the minimum amount. Please input a valid amount. ',
  dataUnavailable: 'Transaction failed as the data for the asset cannot be derived at the time. Please try again later.',
  healthFactorGtOne: 'You cannot liquidate this position as the health factor is >1 and considered healthy.',
  healthFactorLtOne: 'Please lock in more collateral or lend more assets or repay your loans to improve your health factor',
  liquidationNoDebt: 'You cannot liquidate this position as no debt is available to repay for this user.',
  notWithinRange: 'The amount you have input is not within the acceptable range. Please input a valid amount.',
}

export const txErrorMap: SimpleMap<string> = {
  1: cdpErrorMsgs.dataUnavailable,
  4: cdpErrorMsgs.inputGtMax,
  5: cdpErrorMsgs.inputLtMin,
  7: cdpErrorMsgs.invalidInput,
  8: cdpErrorMsgs.invalidInput,
  9: cdpErrorMsgs.invalidInput,
  10: cdpErrorMsgs.disabled,
  30: cdpErrorMsgs.healthFactorLtOne,
  31: cdpErrorMsgs.healthFactorGtOne,
  32: cdpErrorMsgs.liquidationNoDebt,
  40: cdpErrorMsgs.dataUnavailable,
  50: cdpErrorMsgs.dataUnavailable,
  52: cdpErrorMsgs.disabled,
  53: cdpErrorMsgs.disabledForRepay,
  54: cdpErrorMsgs.disabled,
  55: cdpErrorMsgs.dataUnavailable,
  60: cdpErrorMsgs.dataUnavailable,
  71: cdpErrorMsgs.lowCollateral,
  81: cdpErrorMsgs.dataUnavailable,
  94: cdpErrorMsgs.dataUnavailable,
}

const barWidth: number = 296
const gradient = [
  {
    percentage: 0,
    rgb: [233, 72, 70],
  },
  {
    percentage: 20,
    rgb: [255, 83, 8],
  },
  {
    percentage: 50,
    rgb: [255, 168, 1],
  },
  {
    percentage: 80,
    rgb: [1, 169, 102],
  },
  {
    percentage: 100,
    rgb: [1, 176, 145],
  },
]

export const maxResults: number = 20

export type APRObj = TypeUtils.SimpleMap<BigNumber> // each reward token with their respective USD values
export type RewardsUsdObj = TypeUtils.SimpleMap<APRObj>

export interface CoinApr {
  denom: string
  apr: BigNumber
}

interface Assets {
  denom: string
  cdpAlias: string | undefined
  symbol: string // getTokenName(denom)
  name: string // getNitronAssetName(denom)
  schemeKey: string
  tokenDp: number
  isNitronLPToken: boolean
  rewardsApr: BigNumber
}

export interface LendableAsset extends Assets {
  globalLent: BigNumber
  globalLentUsd: BigNumber
  walletBalance: BigNumber
  walletBalanceUsd: BigNumber
  lentInProtocol: BigNumber
  cap: BigNumber
  supplyCap: BigNumber
  apr: BigNumber
}

export interface LendingAsset extends Assets {
  availableBalance: BigNumber
  availableUsd: BigNumber
  collateralBalance: BigNumber
  collateralUsd: BigNumber
  totalShares: BigNumber
  totalSharesUsd: BigNumber
  totalLent: BigNumber
  totalLentUsd: BigNumber
  apr: BigNumber
  isCollateralizable: boolean
}

export interface BorrowedAsset extends Assets { // for both borrowed and minted assets
  isBorrow: boolean
  debt?: CDPAccountDebt
  totalShares: BigNumber
  totalSharesUsd: BigNumber
  debtAmount: BigNumber
  debtAmountUsd: BigNumber
  interest: BigNumber
}


function pickHex(color1: number[], color2: number[], weight: number) {
  const p = weight
  const w = p * 2 - 1
  const w1 = (w / 1 + 1) / 2
  const w2 = 1 - w1
  const rgb = [Math.round(color1[0] * w1 + color2[0] * w2), Math.round(color1[1] * w1 + color2[1] * w2), Math.round(color1[2] * w1 + color2[2] * w2)]
  return rgb
}

export function getHealthFactorColor(value?: BigNumber): string {
  if (value === undefined) return ('rgba(255, 255 255)')
  if (value.lte(0)) return 'rgba(255,0,0)' // TODO: update bad health factor color

  const colorRange: number[] = []
  let currentVal = 0
  if (!value.isNaN()) {
    currentVal = !value.isFinite() || value.gte(10) ? 10 : value.toNumber()
  }
  const currentValuePercentage = (currentVal / 11) * 100

  gradient.forEach((g, i) => {
    if (currentValuePercentage <= g.percentage && currentValuePercentage > 0) {
      colorRange.push(i - 1)
      colorRange.push(i)
    } else if (currentValuePercentage <= g.percentage) {
      colorRange.push(i)
      colorRange.push(i)
    }
  })

  const firstColor = gradient[colorRange[0]].rgb
  const secondColor = gradient[colorRange[1]].rgb

  const firstColorX = barWidth * (gradient[colorRange[0]].percentage / 100)
  const secondColorX = barWidth * (gradient[colorRange[1]].percentage / 100) - firstColorX
  const sliderX = barWidth * (currentValuePercentage / 100) - firstColorX
  const ratio = sliderX / secondColorX

  const res = pickHex(secondColor, firstColor, ratio)
  return `rgba(${res.toString()})`
}

export function getNetworkFeeBN(
  feeDenom: string,
  sdk: CarbonSDK | undefined,
  actionType: string,
): BigNumber {
  const feeDp = sdk?.token?.getDecimals(feeDenom) ?? 0
  return (sdk?.gasFee?.getFee(actionType, feeDenom) ?? BN_ZERO).shiftedBy(-feeDp)
}

export function calculateHealthFactor(
  assets: TypeUtils.SimpleMap<CDPAsset>,
  accountView: CDPAccountView,
  collateralValueToAdd: TypeUtils.SimpleMap<BigNumber>,
  debtValueToAdd: TypeUtils.SimpleMap<BigNumber>,
  unlockRatios: UnlockRatios
): BigNumber {
  let liquidationThreshold = accountView.liquidationThreshold
  let debtTotalValue = accountView.debtTotalValue

  for (const [denom] of Object.entries(assets)) {
    if (debtValueToAdd[denom]) {
      debtTotalValue = debtTotalValue.plus(debtValueToAdd[denom])
    }

    if (collateralValueToAdd[denom]) {
      const liqThresholdFactor = bnOrZero(unlockRatios?.liquidationThreshold).shiftedBy(-4)
      const assetLiquidationThreshold = collateralValueToAdd[denom].times(liqThresholdFactor)
      liquidationThreshold = liquidationThreshold.plus(assetLiquidationThreshold)
    }
  }
  const newDebtTotalValue = BigNumber.max(debtTotalValue, BN_ZERO)
  if (newDebtTotalValue.isZero()) return BigNumber(Infinity)
  const newLiquidationThreshold = BigNumber.max(liquidationThreshold, BN_ZERO)
  return newLiquidationThreshold.div(newDebtTotalValue)
}

export function calculateHealthFactorFromInputs(
  inputUsdValue: BigNumber,
  debtTotalValue: BigNumber,
  asset: CDPAsset,
  accountView?: CDPAccountView,
): BigNumber {
  const liqThreshold = (accountView?.liquidationThreshold ?? BN_ZERO)
  const liqThresholdFactor = bnOrZero(asset.assetInfo?.liquidationThreshold).shiftedBy(-4)
  const assetLiquidationThreshold = inputUsdValue.times(liqThresholdFactor)
  const newLiquidationThreshold = liqThreshold.plus(assetLiquidationThreshold)
  return debtTotalValue.isZero() ? BN_ZERO : newLiquidationThreshold.div(debtTotalValue)
}

export const formatHealthFactor = (healthFactor?: BigNumber): string => {
  if (healthFactor === undefined || healthFactor?.isNaN()) {
    return '—'
  }
  if (healthFactor.lte(10)) {
    return healthFactor.toFormat(2)
  }
  // for health factor > 10
  return '> 10'
}

export function calculateProjectedInterest(asset: CDPAsset, debt: CDPAccountDebt, decimals: number, debtToAdd?: BigNumber) {
  const cim = asset?.calculateCIM()
  const addedDebt = debtToAdd ?? BN_ZERO
  const totalDebt = addedDebt?.plus(debt.getCurrentTotalDebt(cim))
  const apy = debt.type === DebtType.Borrow ? asset?.getBorrowAPY() : asset.getMintAPY()
  const projectedInterestValue = totalDebt.times(apy).shiftedBy(-decimals).times(debt.price)
  return projectedInterestValue
}

export function calculateNetApr(
  accountView: CDPAccountView | undefined,
  cdpAssets: TypeUtils.SimpleMap<CDPAsset>,
  selectedDenom: string,
  collateralAmountToAdd: BigNumber,
  debtAmountToAdd: BigNumber,
  sdk: CarbonSDK | undefined,
  tokenPrices?: TypeUtils.SimpleMap<TokenPrice>,
): BigNumber {
  if (!accountView) return BN_ZERO
  const totalAccCollateralsValue = accountView?.collateralTotalValue ?? BN_ZERO
  const totalAccSuppliedValue = accountView?.supplyTotalValue ?? BN_ZERO
  let totalAccLentValue = totalAccCollateralsValue.plus(totalAccSuppliedValue)
  let totalLentAprValue = BN_ZERO
  let totalDebtInterestValue = BN_ZERO

  for (const [denom, asset] of Object.entries(cdpAssets)) {
    const collateral = accountView?.collaterals[denom]
    const supply = accountView?.supplies[denom]
    const borrowDebt = accountView?.debts[getCdpAccountDebtsKey(denom, DebtType.Borrow)]
    const mintDebt = accountView?.debts[getCdpAccountDebtsKey(denom, DebtType.Mint)]

    const decimals = sdk?.token.getDecimals(denom) ?? 0

    const collateralBalance = collateral?.eqUnderlyingAmount ?? BN_ZERO
    const supplyBalance = supply?.eqUnderlyingAmount ?? BN_ZERO
    const price = supply?.price ?? collateral?.price ?? (tokenPrices && tokenPrices[denom]?.twap) ?? BN_ZERO
    let totalAssetLentAmount = collateralBalance.plus(supplyBalance)

    if (totalAssetLentAmount.gt(0) || selectedDenom === denom) {
      const lendApr = asset.getLendAPY()
      if (selectedDenom === denom) {
        totalAssetLentAmount = totalAssetLentAmount.plus(collateralAmountToAdd)
        totalAccLentValue = totalAccLentValue.plus(collateralAmountToAdd.shiftedBy(-decimals).times(price))
      }
      const projectedAprValue = totalAssetLentAmount.times(lendApr).shiftedBy(-decimals).times(price)
      totalLentAprValue = totalLentAprValue.plus(projectedAprValue)
    }

    if (totalAccLentValue.isZero()) return BN_ZERO

    if (borrowDebt) {
      const addedDebt = selectedDenom === denom ? debtAmountToAdd : BN_ZERO
      const projectedInterest = calculateProjectedInterest(asset, borrowDebt, decimals, addedDebt)
      totalDebtInterestValue = totalDebtInterestValue.plus(projectedInterest)
    } else if (mintDebt) {
      const addedDebt = selectedDenom === denom ? debtAmountToAdd : BN_ZERO
      const projectedInterest = calculateProjectedInterest(asset, mintDebt, decimals, addedDebt)
      totalDebtInterestValue = totalDebtInterestValue.plus(projectedInterest)
    }
  }
  return (totalLentAprValue.minus(totalDebtInterestValue)).dividedBy(totalAccLentValue)
}

export interface NetAddRewardsParams {
  accountView: CDPAccountView | undefined,
  addRewardsUsd: RewardsUsdObj,
  cdpAssets: TypeUtils.SimpleMap<CDPAsset>,
  totalSupplyMap: TypeUtils.SimpleMap<BigNumber>,
  moduleBalancesMap: TypeUtils.SimpleMap<BigNumber>,
  tokenPrices: TypeUtils.SimpleMap<TokenPrice>,
  balances: TypeUtils.SimpleMap<WalletBalance>,
  sdk: CarbonSDK | undefined,
}

function calculateRewards(
  supplyToAdd: BigNumber,
  collateralToAdd: BigNumber,
  debtToAdd: BigNumber,
  totalLendUsd: BigNumber,
  selectedDenom: string,
  rewardsParams: NetAddRewardsParams,
): TypeUtils.SimpleMap<BigNumber> {
  const { accountView, addRewardsUsd, cdpAssets, totalSupplyMap, balances, sdk } = rewardsParams
  const rewardsAprMap: TypeUtils.SimpleMap<BigNumber> = {}

  for (const [denom, asset] of Object.entries(cdpAssets)) {
    const cibtDenom = asset.cibtDenom
    const collateral = accountView?.collaterals[denom]
    const borrowDebt = accountView?.debts[getCdpAccountDebtsKey(denom, DebtType.Borrow)]
    const mintDebt = accountView?.debts[getCdpAccountDebtsKey(denom, DebtType.Mint)]

    if (collateral || (selectedDenom === denom && (supplyToAdd.gt(0) || collateralToAdd.gt(0)))) {
      const schemeKey = `${denom}:${RewardType.Lend}`
      const rewardsMap = addRewardsUsd[schemeKey] ?? {}
      const collateralSupply = totalSupplyMap[cibtDenom] ?? BN_ZERO
      const totalCollateral = collateralSupply.plus(supplyToAdd)

      let unlockedCollateral = bnOrZero(balances[cibtDenom]?.available ?? '0')
      let collateralCdpAmount = collateral?.cdpAmount ?? BN_ZERO
      if (denom === selectedDenom) {
        unlockedCollateral = unlockedCollateral.plus(supplyToAdd).minus(collateralToAdd)
        collateralCdpAmount = collateralCdpAmount.plus(collateralToAdd)
      }
      const proportion = (collateralCdpAmount.plus(unlockedCollateral)).div(totalCollateral)

      Object.keys(rewardsMap).forEach((rewardDenom: string) => {
        const usd = rewardsMap[rewardDenom] ?? BN_ZERO
        const rewardDecimals = sdk?.token.getDecimals(rewardDenom) ?? 0
        const userRewardsUsd = (usd.times(proportion)).shiftedBy(-rewardDecimals)
        if (usd.gt(0)) {
          const currApr = bnOrZero(rewardsAprMap[rewardDenom])
          rewardsAprMap[rewardDenom] = currApr.plus(userRewardsUsd.dividedBy(totalLendUsd))
        }
      })
    }

    [borrowDebt, mintDebt].forEach((debt) => {
      if (!debt) {
        return
      }

      const isBorrowDebt = debt.type === DebtType.Borrow
      const debtInfo = isBorrowDebt ? asset?.getDebtInfo() : asset?.getMintInfo()
      const schemeKey = `${denom}:${isBorrowDebt ? DebtType.Borrow : DebtType.Mint}`
      const rewardsMap = addRewardsUsd[schemeKey] ?? {}
      const totalBorrow = bnOrZero(debtInfo?.totalPrincipal)
      const debtBalance = debt.principal ?? BN_ZERO
      const toAdd = denom === selectedDenom ? debtToAdd : BN_ZERO
      const proportion = totalBorrow.isZero() ? BN_ZERO : (debtBalance.plus(debtToAdd)).div(totalBorrow.plus(toAdd))

      Object.keys(rewardsMap).forEach((rewardDenom: string) => {
        const usd = rewardsMap[rewardDenom] ?? BN_ZERO
        const rewardDecimals = sdk?.token.getDecimals(rewardDenom) ?? 0
        const userRewardsUsd = usd.times(proportion).shiftedBy(-rewardDecimals)
        if (usd.gt(0)) {
          const currApr = bnOrZero(rewardsAprMap[rewardDenom])
          rewardsAprMap[rewardDenom] = currApr.plus(userRewardsUsd.dividedBy(totalLendUsd))
        }
      })
    })
  }

  return rewardsAprMap
}

export function getNetAdditionalRewardsMap(
  rewardsParams: NetAddRewardsParams,
  denom?: string,
  collateralValue?: BigNumber,
  supplyValue?: BigNumber,
  debtValue?: BigNumber,
): TypeUtils.SimpleMap<BigNumber> {
  const { accountView, cdpAssets, totalSupplyMap, moduleBalancesMap, tokenPrices, sdk } = rewardsParams
  const selectedDenom = denom ?? ''

  const cdpModuleBalance = moduleBalancesMap[selectedDenom] ?? BN_ZERO
  const cibtDenom = getCibtDenom(selectedDenom)
  const totalSupply = totalSupplyMap[cibtDenom] ?? BN_ZERO
  const asset = cdpAssets[selectedDenom]
  const cdpRatio = asset?.getCdpRatio(totalSupply, cdpModuleBalance) ?? BN_ONE

  const collateralBN = (collateralValue ?? BN_ZERO).times(cdpRatio)
  const supplyBN = (supplyValue ?? BN_ZERO).times(cdpRatio)
  const debtBN = debtValue ?? BN_ZERO
  const tokenUsd = getTokenUSDPrice(tokenPrices, selectedDenom, sdk)
  const tokenDp = sdk?.token.getDecimals(selectedDenom) ?? 0

  const totalCollateralsUsd = accountView?.collateralTotalValue ?? BN_ZERO
  const totalSuppliedUsd = accountView?.supplyTotalValue ?? BN_ZERO
  const addedCollateralUsd = collateralBN.times(tokenUsd).shiftedBy(-tokenDp)
  const addedSupplyUsd = (supplyBN.minus(collateralBN)).times(tokenUsd).shiftedBy(-tokenDp)
  const totalLendUsd = totalCollateralsUsd.plus(totalSuppliedUsd).plus(addedCollateralUsd).plus(addedSupplyUsd)

  const rewardsAprMap = calculateRewards(supplyBN, collateralBN, debtBN, totalLendUsd, selectedDenom, rewardsParams)
  return rewardsAprMap
}

export const getPositiveBnOrZeroLabel = (value: BigNumber) => {
  if (value.isNegative()) {
    return '0.00'
  }
  return value.toFormat(2)
}

export const getFormattedRewardsApr = (apr: BigNumber) => {
  if (apr.isZero() || !apr.isFinite()) { // if apr is infinite/NaN or zero
    return '0.00'
  }
  if (apr.lt(0.0001)) {
    return '<0.01'
  }
  return apr.shiftedBy(2).toFormat(2)
}

export const getErrorMsg = (error: TxError) => {
  const errorResponse = error.response
  if (errorResponse) {
    const errorCode = error.response.code
    if (errorCode === 5 && error.message.includes('insufficient funds')) {
      return cdpErrorMsgs.lowBalance
    }
    if (errorCode === 4 && error.message.includes('limit')) {
      if (error.message.includes('minted')) {
        return cdpErrorMsgs.inputGtLimitSpecific.replace('txType', 'minting')
      }
      if (error.message.includes('supply cap')) {
        return cdpErrorMsgs.inputGtLimitSpecific.replace('txType', 'supply cap')
      }
      if (error.message.includes('borrow')) {
        return cdpErrorMsgs.inputGtLimitSpecific.replace('txType', 'borrow')
      }
    }
    if (!isNaN(errorCode) && txErrorMap[errorCode] !== undefined) {
      return txErrorMap[errorCode]
    }
    return cdpErrorMsgs.general
  }
  return error.message
}

export const sortByAsset = (debtsData: BorrowedAsset[], direction: SortDirection) => {
  return debtsData.sort((rowA: BorrowedAsset, rowB: BorrowedAsset) => {
    const assetA = rowA.name.toLowerCase() || ''
    const assetB = rowB.name.toLowerCase() || ''
    return sortStrings(direction, assetA, assetB)
  })
}

export const sortByInterest = (debtsData: BorrowedAsset[], direction: SortDirection) => {
  return debtsData.sort((debtA: BorrowedAsset, debtB: BorrowedAsset) => {
    const interestA = debtA.interest
    const interestB = debtB.interest
    const rewardsAprA = debtA.rewardsApr
    const rewardsAprB = debtB.rewardsApr

    const aprA = BigNumber.max(interestA, rewardsAprA)
    const aprB = BigNumber.max(interestB, rewardsAprB)
    return sortNumbers(direction, aprA, aprB)
  })
}

export const sortByDebt = (debtsData: BorrowedAsset[], direction: SortDirection) => {
  return debtsData.sort((assetA: BorrowedAsset, assetB: BorrowedAsset) => {
    const debtAmountA = assetA.debtAmountUsd
    const debtAmountB = assetB.debtAmountUsd
    return sortNumbers(direction, debtAmountA, debtAmountB)
  })
}
