import { CarbonSDK } from 'carbon-js-sdk'
import { Set } from 'immutable'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { FavoriteType, liquidityThreshold, lowLiquidity } from 'js/constants/markets'
import { isExpired, isFutures, isPerpetualMarket, isSpot, isValidMarket } from 'js/models/Market'
import { getNet } from 'js/state/modules/app/selectors'
import { getBooksObj, getMarketsLiquidityMap } from 'js/state/modules/exchange/selectors'
import { ExchangeOrderBook, Market, MarketTypeTab } from 'js/state/modules/exchange/types'
import { RootState } from 'js/state/modules/rootReducer'
import { chainLabel } from 'js/utils/externalTransfer'
import { getLiquidityWithinThreshold } from 'js/utils/markets'
import { isMultiChainDenom } from 'js/utils/misc'
import { BN_ZERO } from 'js/utils/number'
import { fuzzySearch, getMarketDisplayName, matchStart } from 'js/utils/strings'


/**
 * Checks if market name matches search term using fuzzy search
 * @param marketName market display name (e.g. SWTH / ETH, BTC-PERP etc.)
 * @param searchTerm search term
 * @returns boolean representing whether or not market name matches search term
 */
function marketNameMatchSearch(marketName: string, searchTerm: string) {
  if (searchTerm.length > 0) {
    return fuzzySearch(searchTerm, marketName)
  }
  return true
}

function marketChainMatchSearch(marketChain: string, searchTerm: string) {
  if (searchTerm.length > 0) {
    return matchStart(marketChain, searchTerm)
  }
  return true
}

type HasTab = Partial<{
  [key in MarketTypeTab]: boolean
}>

const useFilterMarkets = (
  markets: Market[],
  searchTerm: string,
  marketType: string,
  favourites: Set<string>,
): {
  markets: Market[],
  hasTab: HasTab
} => {
  const booksObj = useSelector(getBooksObj)
  const demexConfig = useSelector((store: RootState) => store.app.demexConfig)
  const sdk = useSelector((store: RootState) => store.app.carbonSDK)
  const showLowLiquidity = useSelector((store: RootState) => store.exchange.showLowLiquidity)
  const marketStats = useSelector((store: RootState) => store.marketStats.marketStats)
  const liquidityObj = useSelector(getMarketsLiquidityMap)
  const net = useSelector(getNet)

  const memo = useMemo(() => {
    const hasTab: HasTab = {
      futures: false,
      spot: false,
      perpetual: false,
      favourites: true,
      all: true,
    }

    // filter based on show low liquidity
    const newMarkets = markets.filter((market) => {
      if (!isValidMarket(market, demexConfig.blacklistedMarkets)) {
        return false
      }

      const isNew = market.isNew
      const marketName = market.id
      const orderBook: ExchangeOrderBook | undefined = booksObj[marketName]
      const liquidityUSD = getLiquidityWithinThreshold(orderBook, sdk, market, marketStats[marketName], liquidityThreshold)

      return showLowLiquidity || isNew || liquidityUSD.gte(lowLiquidity)
    })


    const m = {
      favMarkets: newMarkets.filter((market) => favourites.contains(market.id)),
      spotMarkets: [] as Market[],
      futuresMarkets: [] as Market[],
      perpetualMarkets: [] as Market[],
      sortedPerpetualMarketsFinal: [] as Market[],
      liquidityObj,
      hasTab,
    }

    const renameChain = (market: Market, isQuote: boolean, tokenClient?: CarbonSDK.TokenClient) => {
      const denomInQn = isQuote ? market.quote : market.base
      const marketChain = tokenClient?.getBlockchainV2(denomInQn)
      if (marketChain === 'Native') {
        return isMultiChainDenom(denomInQn) ? 'Multi-Chain' : chainLabel(marketChain)
      } else if (marketChain === 'Binance Smart Chain') {
        return chainLabel(marketChain, true)
      }
      return marketChain
    }

    // filtering markets based on their name and chain name with reverse name searching supported
    newMarkets.forEach((market: Market) => {
      const marketId = market?.id
      const marketOverride = marketId ? demexConfig?.marketsOverride?.[marketId] : undefined
      const marketName = getMarketDisplayName(market, sdk!, net, false, marketOverride) // marketName
      const parts = marketName.split(' / ')
      const reverseMarketname = parts.reverse().join(' / ')
      const isMatch = marketNameMatchSearch(marketName, searchTerm) || marketNameMatchSearch(reverseMarketname, searchTerm)

      const marketChainA = renameChain(market, true, sdk?.token)
      const marketChainB = renameChain(market, false, sdk?.token)
      const marketChain = `${marketChainA} / ${marketChainB}` // chainName
      const reverseChain = `${marketChainB} / ${marketChainA}`
      const marketLiquidity = liquidityObj[market.id]
      const liquidityUsd = marketLiquidity ? marketLiquidity.bidsUsd.plus(marketLiquidity.asksUsd) : BN_ZERO
      const hasLiquidity = liquidityUsd.gt(BN_ZERO)
      const isChainMatch = marketChainMatchSearch(marketChain, searchTerm) || marketChainMatchSearch(reverseChain, searchTerm)
      if (isSpot(market.marketType)) {
        if (!hasTab.spot && hasLiquidity) hasTab.spot = true
        if (isMatch || isChainMatch) { m.spotMarkets.push(market) }
      } else if (isFutures(market.marketType) && !isPerpetualMarket(market) && !isExpired(market)) {
        if (!hasTab.futures && hasLiquidity) hasTab.futures = true
        if (isMatch) m.futuresMarkets.push(market)
      } else if (isPerpetualMarket(market)) {
        if (!hasTab.perpetual && hasLiquidity) hasTab.perpetual = true
        if (isMatch) m.perpetualMarkets.push(market)
      }
    })

    return m
  }, [demexConfig.blacklistedMarkets, demexConfig?.marketsOverride, booksObj, markets, sdk, marketStats, showLowLiquidity, favourites, net, searchTerm, liquidityObj])

  return {
    hasTab: memo.hasTab,
    markets: ((marketType: string) => {
      switch (marketType) {
        case FavoriteType:
          return memo.favMarkets
        case 'spot':
          return memo.spotMarkets
        case 'futures':
          return memo.futuresMarkets
        case 'perpetual':
          return memo.perpetualMarkets
        default:
          return memo.perpetualMarkets.concat(memo.futuresMarkets).concat(memo.spotMarkets)
      }
    })(marketType),
  }
}

export default useFilterMarkets
