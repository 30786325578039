import React from "react"
import { useLocation } from "react-router"

import Paths from "js/constants/paths"

import ManageStakeFallback from "./ManageStakeFallback"
import StakeFAQFallback from "./StakeFAQFallback"
import StakeFallbackMain from "./StakeFallbackMain"

import CommonFormFallback from "../CommonFormFallback"


const StakeSwitch = () => {
  const location = useLocation()

  if (location.pathname.includes('stake/unstake')
    || location.pathname.includes('stake/transfer')
    || location.pathname.includes('stake/add')
  ) return <CommonFormFallback />

  switch (location.pathname) {
    case Paths.Stake.List: {
      return <StakeFallbackMain />
    }
    case Paths.Stake.Manage: {
      return <ManageStakeFallback />
    }
    case Paths.Stake.FAQ: {
      return <StakeFAQFallback />
    }
    default: {
      return <StakeFallbackMain />
    }
  }
}

export default StakeSwitch