import { Theme } from '@material-ui/core'

import { FlavorableOverrides } from './types'

const MuiSvgIcon: FlavorableOverrides = (theme: Theme) => ({
  root: {
    width: 'unset',
    height: 'unset',
    fill: 'none',
    '&.sq1em': {
      height: '1em',
      width: '1em',
    }
  },
})

export default MuiSvgIcon
