import { action } from 'typesafe-actions'

import { RefereeDetails, RefLink, TradeListEntry } from 'js/constants/referrals'

import { ReferralActionTypes } from './types'

export const resetReferrals = () => action(ReferralActionTypes.RESET_REFERRALS)

export const setAmbassador = (isAmbassador: boolean) => action(
  ReferralActionTypes.SET_AMBASSADOR, isAmbassador,
)
export const setUrlReferraId = (urlReferralId: string) => action(
  ReferralActionTypes.SET_URL_REFERRAL_ID, urlReferralId,
)
export const setReferralIdAddr = (referralAddr: string) => action(
  ReferralActionTypes.SET_REFERRAL_ID_ADDR, referralAddr,
)
export const setReferraIdComm = (referralIdComm: string) => action(
  ReferralActionTypes.SET_REFERRAL_ID_COMM, referralIdComm,
)
export const setReferraIdKb = (referralIdKb: string) => action(
  ReferralActionTypes.SET_REFERRAL_ID_KB, referralIdKb,
)
export const setRefIdRefereeDetails = (refIdReferees: RefereeDetails[]) => action(
  ReferralActionTypes.SET_REF_ID_REFEREE_DETAILS, refIdReferees,
)
export const setOpenRefPopUp = (open: boolean) => action(
  ReferralActionTypes.SET_OPEN_REF_POPUP, open,
)
export const setShowRefBanner = (showBanner: boolean) => action(
  ReferralActionTypes.SET_SHOW_REF_BANNER, showBanner,
)
export const setReferrerAddress = (referrerAddr: string) => action(
  ReferralActionTypes.SET_REFERRER_ADDR, referrerAddr,
)
export const setReferrerComm = (referrerComm: number) => action(
  ReferralActionTypes.SET_REFERRER_COMM, referrerComm,
)
export const setReferrerKb = (referrerKb: number) => action(
  ReferralActionTypes.SET_REFERRER_KB, referrerKb,
)
export const setReferrerUsername = (referrerUsername: string) => action(
  ReferralActionTypes.SET_REFERRER_USERNAME, referrerUsername,
)
export const setRefereeCount = (refereeCount: string) => action(
  ReferralActionTypes.SET_REFEREE_COUNT, refereeCount,
)
export const setRefereeDetails = (refereeDetails: RefereeDetails[]) => action(
  ReferralActionTypes.SET_REFEREE_DETAILS, refereeDetails,
)
export const setRefLinks = (refLinks: RefLink[]) => action(
  ReferralActionTypes.SET_REF_LINKS, refLinks,
)
export const setAmtEarned = (amtEarned: string) => action(
  ReferralActionTypes.SET_AMT_EARNED, amtEarned,
)
export const setTopEarned = (topEarnedAmt: string) => action(
  ReferralActionTypes.SET_TOP_EARNED, topEarnedAmt,
)
export const setTopEarnedAddr = (topEarnedAddr: string) => action(
  ReferralActionTypes.SET_TOP_EARNED_ADDR, topEarnedAddr,
)
export const setExistingReferrer = (referrerExist: boolean) => action(
  ReferralActionTypes.SET_EXISTING_REFERRER, referrerExist,
)
export const setTradeList = (tradeList: TradeListEntry[]) => action(
  ReferralActionTypes.SET_TRADE_LIST, tradeList,
)
export const queryRefLinks = () => action(
  ReferralActionTypes.QUERY_REF_LINKS,
)
export const queryForReferrer = () => action(
  ReferralActionTypes.QUERY_FOR_REFERRER,
)
export const queryForReferees = () => action(
  ReferralActionTypes.QUERY_FOR_REFEREES,
)
export const queryForRefIdRefereeDetails = (refId: string) => action(
  ReferralActionTypes.QUERY_FOR_REF_ID_REFEREE_DETAILS, refId,
)
export const queryForRefereeDetails = () => action(
  ReferralActionTypes.QUERY_FOR_REFEREE_DETAILS,
)
export const queryForTradeList = () => action(
  ReferralActionTypes.QUERY_FOR_TRADE_LIST,
)
export const setShowCommissionOnly = (checked: boolean) => action(
  ReferralActionTypes.SET_SHOW_COMMISSION_ONLY,
  checked,
)
export const setShowKickbackOnly = (checked: boolean) => action(
  ReferralActionTypes.SET_SHOW_KICKBACK_ONLY,
  checked,
)
