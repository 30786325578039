import { WSModels } from 'carbon-js-sdk'
import { Record } from 'immutable'
import Long from 'long'
import { AnyAction } from 'redux'

import { Pool } from 'js/models/Pool'
import { SimpleMap } from 'js/utils'
import { getLastSelected, SelectedMode, setLastSelected } from 'js/utils/lastToggle'
import { BN_ZERO } from 'js/utils/number'

import { PoolsActionTypes, PoolsState, PoolsStateProps, PoolStakeInfoMap, TxFilter, WSCommitEvtMap, WSCommitmentEvent } from './types'

const showUnlinkedPoolsBoolean = getLastSelected(SelectedMode.SHOW_UNLINKED_POOLS)
const showZeroRewardsBoolean = getLastSelected(SelectedMode.SHOW_ZERO_APR)
const showSmallPoolsBoolean = getLastSelected(SelectedMode.SHOW_SMALL_POOLS)

export const DefaultInitialState:
  Record.Factory<PoolsStateProps> = Record<PoolsStateProps>({
    pools: {},
    poolRoutes: undefined,
    poolStakedInfoMap: {},
    poolsWeeklyRewards: BN_ZERO,
    poolsWeeklyRewardsRealInflation: BN_ZERO,
    poolsUnclaimedRewards: {},
    poolsUnclaimedUsd: {},
    poolsTransactions: [],
    poolVolumes: {},
    txFilter: TxFilter.All,
    poolCommitmentCurve: {
      maxCommitmentDuration: Long.ZERO,
      maxRewardMultiplier: 0,
    },
    poolRewardCurve: {
      startTime: new Date('1970-01-01T00:00:00Z'),
      initialReward: 0,
      interval: Long.ZERO,
      numberOfReductions: 0,
      reduction: 0,
      finalReward: 0,
      reductionsMade: 0,
    },
    distributionParams: undefined,
    distributedRewards: {},
    showZeroRewards: showZeroRewardsBoolean,
    showSmallPools: showSmallPoolsBoolean,
    showUnlinkedPools: showUnlinkedPoolsBoolean,
    totalCommitObj: {},
    poolsRewardCoins: [],
    totalPoolRewardsUsd: BN_ZERO,
  })

const defaultInitialState: PoolsState = new DefaultInitialState()

export const poolsReducer = (
  state: PoolsState = defaultInitialState,
  action: AnyAction,
): PoolsState => {
  switch (action.type) {
    case PoolsActionTypes.SET_POOL_ROUTES: {
      return state.set('poolRoutes', action.payload)
    }
    case PoolsActionTypes.SET_POOLS: {
      return state.set('pools', action.payload)
    }
    case PoolsActionTypes.SYNC_POOLS: {
      const newPools = syncPools(action.payload, state.pools)
      return state.set('pools', newPools)
    }
    case PoolsActionTypes.SET_UNCLAIMED_REWARDS: {
      return state.set('poolsUnclaimedRewards', action.payload)
    }
    case PoolsActionTypes.SET_UNCLAIMED_USD_MAP: {
      return state.set('poolsUnclaimedUsd', action.payload)
    }
    case PoolsActionTypes.SET_WEEKLY_POOLS_REWARDS_REAL_INFLATION: {
      return state.set('poolsWeeklyRewardsRealInflation', action.payload)
    }
    case PoolsActionTypes.QUERY_POOLS_TRANSACTIONS: {
      return state.set('txFilter', action.payload)
    }
    case PoolsActionTypes.SET_POOLS_TRANSACTIONS: {
      return state.set('poolsTransactions', action.payload)
    }
    case PoolsActionTypes.SET_POOL_VOLUMES: {
      return state.set('poolVolumes', action.payload)
    }
    case PoolsActionTypes.SET_COMMITMENT_CURVE: {
      return state.set('poolCommitmentCurve', action.payload)
    }
    case PoolsActionTypes.SET_REWARD_CURVE: {
      return state.set('poolRewardCurve', action.payload)
    }
    case PoolsActionTypes.SET_DISTRIBUTED_REWARDS: {
      return state.set('distributedRewards', action.payload)
    }
    case PoolsActionTypes.ADD_STAKE_INFO: {
      return state.set('poolStakedInfoMap', action.payload)
    }
    case PoolsActionTypes.SYNC_STAKE_INFO: {
      const incomingState = syncStakeInfo(action.payload, state.poolStakedInfoMap)
      return state.set('poolStakedInfoMap', incomingState)
    }
    case PoolsActionTypes.SET_SHOW_ZERO_REWARDS: {
      const checked = action.payload
      setLastSelected(SelectedMode.SHOW_ZERO_APR, checked)
      return state.set('showZeroRewards', checked)
    }
    case PoolsActionTypes.SET_SHOW_SMALL_POOLS: {
      const checked = action.payload
      setLastSelected(SelectedMode.SHOW_SMALL_POOLS, checked)
      return state.set('showSmallPools', checked)
    }
    case PoolsActionTypes.SET_SHOW_UNLINKED_POOLS: {
      const checked = action.payload
      setLastSelected(SelectedMode.SHOW_UNLINKED_POOLS, checked)
      return state.set('showUnlinkedPools', checked)
    }
    case PoolsActionTypes.SET_TOTAL_COMMIT_OBJ: {
      return state.set('totalCommitObj', action.payload)
    }
    case PoolsActionTypes.SET_POOLS_REWARD_COINS: {
      return state.set('poolsRewardCoins', action.payload)
    }
    case PoolsActionTypes.SET_TOTAL_POOL_REWARDS_USD: {
      return state.set('totalPoolRewardsUsd', action.payload)
    }
    case PoolsActionTypes.SET_DISTRIBUTION_PARAMS: {
      return state.set('distributionParams', action.payload)
    }
    default:
      return state
  }
}

function syncPools(incomingPools: Pool[], state: SimpleMap<Pool>): SimpleMap<Pool> {
  const newState: SimpleMap<Pool> = { ...state }
  incomingPools.forEach((pool: Pool) => {
    newState[pool.denom] = pool
  })
  return newState
}

function syncStakeInfo(incomingCommit: WSCommitEvtMap, state: PoolStakeInfoMap): PoolStakeInfoMap {
  const newState: PoolStakeInfoMap = { ...state }
  Object.values(incomingCommit).forEach((commit: WSCommitmentEvent) => {
    if (commit.Type === 'delete') {
      delete newState[commit.denom]
    } else {
      const { amount, boost_factor, commitment_power, denom, end_time, start_time, is_locked, duration } = commit
      const newCommit: WSModels.Commitment = {
        amount, boost_factor, commitment_power, denom, end_time, start_time, is_locked, duration,
      }
      newState[denom] = newCommit
    }
  })
  return newState
}
