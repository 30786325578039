import { makeStyles, Theme } from '@material-ui/core'
import { CarbonSDK, Insights } from 'carbon-js-sdk'
import { CarbonEvmChainIDs, MANTLE_MAINNET } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved
import { parseChainId } from 'carbon-js-sdk/lib/util/ethermint'
import clsx from 'clsx'
import React, { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSwitchChain } from 'wagmi'

import { SvgIcon, TypographyLabel } from 'js/components/Common'
import AlertBanner from 'js/components/Common/AlertBanner'
import LearnMoreLink from 'js/components/Common/LearnMoreLink'
import MenuListItems, { MenuItem } from 'js/components/Common/MenuListItems'
import TextButton from 'js/components/Common/TextButton'
import Accordion from 'js/components/Exchange/Common/Accordion'
import RawError from 'js/components/Toast/RawError'
import { StaticLinks } from 'js/constants/externalLinks'
import { EvtCategory, useEventTrackers } from 'js/hooks'
import { isWalletConnected } from 'js/state/modules/account/selectors'
import { setFormNode, setNet, showNodeInfoForm } from 'js/state/modules/app/actions'
import { getAutoSelectNode, getNet, getSDKWallet } from 'js/state/modules/app/selectors'
import { ConnectionAlert } from 'js/state/modules/app/types'
import { capitalize, useCommonStyles } from 'js/utils'
import { IS_ENV_MASTER } from 'js/utils/environment'
import { customToast } from 'js/utils/notifications'

import { ReactComponent as AddIcon } from 'assets/AddGradient.svg'
import { ReactComponent as Caret } from 'assets/CaretUp.svg'
import { ReactComponent as CarbonLogo } from 'assets/chains/CarbonLogo.svg'
import { ReactComponent as MantleLogo } from 'assets/chains/MantleLogo.svg'

import NodeTable from './NodeTable'

interface Props {
  handleSelectNode: (node: any) => void
  recommendedNode: Insights.NodeItem
  selectingNode: any
  connectionAlert?: ConnectionAlert
  setFirstLoad: React.Dispatch<React.SetStateAction<boolean>>
  handleDropdownClose: () => void
  setChainId: (chainId: number) => void
}

const NodeTablePage: React.FC<Props> = (props: Props) => {
  const { handleSelectNode, recommendedNode, selectingNode, setFirstLoad, connectionAlert, handleDropdownClose, setChainId } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dispatch = useDispatch()
  const autoSelectNode = useSelector(getAutoSelectNode)
  const isLoggedIn = useSelector(isWalletConnected)
  const sdkWallet = useSelector(getSDKWallet)

  const eventTrackers = useEventTrackers()
  const network = useSelector(getNet)

  const { switchChain } = useSwitchChain({
    mutation: {
      onError: (error) => {
        customToast.error({ title: 'Error switching chain', message: error.message })
      }
    }
  })

  const expandRef = useRef<HTMLDivElement | null>(null)
  const [expanded, setExpanded] = useState<boolean>(false)

  const handleAddCustomNode = () => {
    dispatch(showNodeInfoForm())
    dispatch(setFormNode(undefined))
  }

  const onSelectNetwork = (newNetwork: CarbonSDK.Network) => {
    const targetChainId =  Number(parseChainId(CarbonEvmChainIDs[newNetwork]))
    if (newNetwork !== network) {
      dispatch(setNet(newNetwork))
      setExpanded(false)
      eventTrackers.sendEvent(EvtCategory.Navigation, 'menu_network', { network: newNetwork })
      if (autoSelectNode) {
        setFirstLoad(true)
      }
      setChainId(targetChainId)
      handleDropdownClose()
    } else {
      switchChain({ chainId: Number(parseChainId(CarbonEvmChainIDs[newNetwork])) }, {
        onSuccess: () => {
          setChainId(targetChainId)
        }
      })
      handleDropdownClose()
    }
  }

  const menuItems = useMemo(() => {
    let initNetworks: CarbonSDK.Network[] = [CarbonSDK.Network.MainNet, CarbonSDK.Network.TestNet]

    if (!IS_ENV_MASTER) {
      initNetworks = initNetworks.concat([CarbonSDK.Network.DevNet, CarbonSDK.Network.LocalHost])
    }

    return initNetworks.map((option: CarbonSDK.Network, idx: number) => {
      const handleChange = (event: React.ChangeEvent<{}>) => {
        if (expandRef.current?.contains(event.target as HTMLElement)) {
          setExpanded(!expanded)
        }
      }

      return (
        <Accordion
          key={`network-${option}-${idx}`}
          expanded={network === option ? expanded : false}
          onChange={(e) => handleChange(e)}
          accordionClassName={clsx(classes.accordionClassName, { isExpanded: network === option && expanded })}
          detailsClassName={classes.accordionDetailsClassName}
          summaryChildren={
            <React.Fragment>
              <div
                className={clsx(classes.networkName, commonClasses.alignItemsCenter, classes.gap1)}
                onClick={() => onSelectNetwork(option)}
              >
                <SvgIcon component={CarbonLogo} className={classes.svgLogo} />
                {option === CarbonSDK.Network.MainNet ? 'Carbon' : `Carbon ${capitalize(option)}`}
              </div>
              {network === option && (
                <div ref={expandRef} className={classes.caretWrapper} >
                  <SvgIcon component={Caret} className={clsx(classes.svgIcon, { isExpanded: expanded })} />
                </div>
              )}
            </React.Fragment>
          }
          detailsChildren={(
            <div className={classes.tableContainer}>
              <NodeTable handleSelectNode={handleSelectNode} recommendedNode={recommendedNode} selectingNode={selectingNode} />
              <div className={classes.dropdownFooter}>
                <TextButton
                  startIcon={AddIcon}
                  label="Add Custom Node"
                  onClick={handleAddCustomNode}
                  boxClass={classes.addButton}
                  labelClass={classes.addButtonLable}
                />
                <LearnMoreLink
                  label="Learn more"
                  className={classes.link}
                  href={StaticLinks.DemexDocs.Community.Nodes}
                  svgClass={classes.learnMoreIcon}
                />
              </div>
            </div>
          )}
          withEndIcon={false}
        />
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, expanded, recommendedNode, selectingNode])

  const handleSwitchChain = (chainId: number, chainName: string) => {
    if (isLoggedIn) {
      switchChain({ chainId }, {
        onSuccess: () => {
          setChainId(chainId)
          handleDropdownClose()
        }
      })
    } else {
      setChainId(chainId)
      handleDropdownClose()
    }
  }

  const chainItems: MenuItem[] = [
    {
      active: Boolean(sdkWallet?.isRainbowKit),
      label: (
        <div className={clsx(commonClasses.alignItemsCenter, classes.gap1)}>
          <SvgIcon component={MantleLogo} className={classes.svgLogo} />
          Mantle
        </div>
      ),
      key: MANTLE_MAINNET.chainId,
      className: classes.menuItemLable,
      onClick: () => handleSwitchChain(parseInt(MANTLE_MAINNET.chainId, 16), 'Mantle'),
    },
  ]

  return (
    <React.Fragment>
      {connectionAlert && connectionAlert?.alertTitle && (
        <AlertBanner
          variant={connectionAlert.alertVariant}
          title={connectionAlert.alertTitle as string}
          message={(
            <React.Fragment>
              <TypographyLabel color="textPrimary" className={clsx(classes.subtitle, { 'withAlertText': !!connectionAlert.alertText })}>{connectionAlert.alertSubtitle}</TypographyLabel>
              <TypographyLabel color="textSecondary" className={clsx(classes.text, { withAlertErrMesg: !!connectionAlert.errMessage })}>{connectionAlert.alertText}</TypographyLabel>
              {connectionAlert.errMessage && (
                <RawError error={connectionAlert.errMessage as string} />
              )}
            </React.Fragment>
          )}
          allowCollapse={false}
        />
      )}
      <div className={classes.networkMenu}>
        {menuItems}
        <MenuListItems
          items={chainItems}
          size="regular"
          menuListClasses={{
            root: classes.menuListRoot,
          }}
        />
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    ...theme.typography.body4,
    lineHeight: '13px',
    '&.withAlertText': {
      marginBottom: theme.spacing(0.5),
    }
  },
  text: {
    ...theme.typography.body4,
    lineHeight: '13px',
    '& ul': {
      margin: 0,
      paddingLeft: '1em',
    },
    '&.withAlertErrMesg': {
      marginBottom: theme.spacing(0.5),
    },
    '& div': {
      ...theme.typography.body4,
    }
  },
  networkMenu: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(0.5, 0),
    borderRadius: '0.25rem',
  },
  tableContainer: {
    padding: 0,
    width: '100%',
  },
  dropdownHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(0.75),
      paddingTop: theme.spacing(0.75),
      borderTop: `1px solid ${theme.palette.divider}`,
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
    },
  },
  switch: {
    justifyContent: 'flex-end',
    marginLeft: 0,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  switchLabel: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.5),
    whiteSpace: 'nowrap',
  },
  addButton: {
    padding: theme.spacing(1.5, 0),
    whiteSpace: 'nowrap',
    '& svg': {
      maxWidth: '0.875rem',
      height: '0.875rem',
      '&.start': {
        marginRight: theme.spacing(1.25),
      },
    },
  },
  addButtonLable: {
    ...theme.typography.body4,
  },
  dropdownFooter: {
    ...theme.typography.body4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  link: {
    '& p': {
      ...theme.typography.body4,
      fontWeight: 700,
    },
  },
  learnMoreIcon: {
    height: '8px',
    width: '8px',
    paddingTop: '1px',
    [theme.breakpoints.down('xs')]: {
      width: '11px',
    },
  },
  gradientText: {
    ...theme.typography.body4,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  accordionClassName: {
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? '#303236' : '#F7F6F5', // Custom colours for the hover state
    },
    '&:before': {
      backgroundColor: theme.palette.background.divider,
    },
    '&.isExpanded': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    }
  },
  accordionDetailsClassName: {
    padding: 0,
  },
  networkName: {
    ...theme.typography.body3,
    width: '100%',
    cursor: 'pointer',
    height: '2.5rem',
  },
  caretWrapper: {
    width: '2rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  svgIcon: {
    height: '0.75rem',
    width: '0.75rem',
    rotate: '180deg',
    '& path': {
      stroke: theme.palette.text.secondary,
    },
    '&.isExpanded': {
      '& path': {
        stroke: theme.palette.text.demexSolid,
      },
      rotate: '0deg',
    },
    transition: '0.4s',
  },
  svgLogo: {
    height: '1rem',
    width: '1rem',
  },
  menuListRoot: {
    padding: 0,
  },
  menuItemLable: {
    padding: theme.spacing(1.375, 2, 1.5),
    borderTop: `1px solid ${theme.palette.divider}`,
    borderRadius: 0,
  },
  wipe: {
    WebkitMaskImage: 'linear-gradient(to right, transparent 47.5%, #FFFFFF 52.5%)',
    WebkitMaskSize: '210% 100%',
    WebkitMaskPosition: 'left',
    backgroundColor: theme.palette.background.secondary,
  },
  gap1: {
    gap: theme.spacing(1),
  }
}))

export default React.memo(NodeTablePage)
