import Paths from 'js/constants/paths'

export const generateAccountPaths = (type: 'Deposit' | 'Withdraw') => [
  Paths.Account[type],
  `${Paths.Account[type]}/:denom`,
  `${Paths.Account[type]}/ibc/:hash`,
  `${Paths.Account[type]}/cibt/:denom`,
  `${Paths.Account[type]}/cibt/ibc/:hash`,
  `${Paths.Account[type]}/cibt/clpt/:hash`,
  `${Paths.Account[type]}/cibt/cgt/:id`,
  `${Paths.Account[type]}/cgt/:id`,
  `${Paths.Account[type]}/clpt/:id`,
  `${Paths.Account[type]}/cplt/:id`,
  `${Paths.Account[type]}/brdg/:brdgId`,
]

export const DepositWithdrawPaths = [
  ...generateAccountPaths('Deposit'),
  ...generateAccountPaths('Withdraw'),
]