import {
  IconButtonProps,
  makeStyles,
  IconButton as MuiIconButton,
  Theme,
  withStyles,
} from '@material-ui/core'
import capitalize from '@material-ui/core/utils/capitalize'
import clsx from 'clsx'
import React from 'react'

import { StyleUtils } from 'js/utils'

import colors, { makeButtonColors } from './colors'

interface CustomProps {
  icontype?: 'fill' | 'stroke'
}

export type NewButtonProps = IconButtonProps & CustomProps

const IconButton: React.FC<NewButtonProps> = React.forwardRef((props: NewButtonProps, ref) => {
  const {
    className,
    color = 'default',
    icontype = 'fill',
  } = props
  const classes = useStyles()

  const classNames = clsx(
    {
      [classes[`color${capitalize(color)}`]]: color !== 'default',
    },
    className, classes.root, icontype,
  )

  return React.createElement(NoPaddingIconButton, {
    ...props,
    className: classNames,
    color: colors.includes(color) ? undefined : color,
    ref,
  })
})

const NoPaddingIconButton = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
}))(MuiIconButton)

const useStyles = makeStyles((theme: Theme) => {
  const buttonColors = makeButtonColors(theme)
  const colorClasses = colors.reduce((styles: {}, color: string) => ({
    ...styles,
    [`color${capitalize(color)}`]: buttonColors(color),
  }), {})
  return {
    ...colorClasses,
    root: {
      ...StyleUtils.hoverAnimationDuration(),
      '&:hover': {
        '&.fill svg path': {
          fill: theme.palette.text.animated,
        },
        '&.stroke svg path': {
          stroke: theme.palette.text.demexSolidHover,
        },
      },
    },
  }
})

export default IconButton
