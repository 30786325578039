import { StatsigClient } from "@statsig/js-client"
import { TypeUtils } from "carbon-js-sdk"
import { action } from "typesafe-actions"

import { StatsigActionTypes, StatsigExperiment } from "./types"


export const setStatsigClient = (client: StatsigClient) => action(
  StatsigActionTypes.SET_STATSIG_CLIENT,
  client
)

export const setStatsigExperiments = (experiments: TypeUtils.SimpleMap<StatsigExperiment> | undefined) => action(
  StatsigActionTypes.SET_STATSIG_EXPERIMENTS,
  experiments
)