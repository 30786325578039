import { Chain, getDefaultConfig } from '@rainbow-me/rainbowkit'
import * as WagmiChains from '@wagmi/core/chains'
import { CarbonEvmNativeCurrency } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved
import { HttpTransport } from 'viem'
import { http } from 'wagmi'
import {
  arbitrum,
  base,
  bsc,
  evmos,
  mainnet,
  mantle,
  okc,
  optimism,
  polygon
} from 'wagmi/chains'

import { CARBON_EVM_DEVNET, CARBON_EVM_LOCALHOST, CARBON_EVM_MAINNET, CARBON_EVM_TESTNET } from 'js/constants/chainConfig'
import { StaticLinks } from 'js/constants/externalLinks'

import { Wallets } from './wallets'

import { SimpleMap } from '../types'

const blockExplorersUrl = StaticLinks.EvmScan
const mainnetRPCUrls = { http: CARBON_EVM_MAINNET.rpcUrls }
const testnetRpcUrls = { http: CARBON_EVM_TESTNET.rpcUrls }
const devnetRpcUrls = { http: CARBON_EVM_DEVNET.rpcUrls }
const localHostRpcUrls = { http: CARBON_EVM_LOCALHOST.rpcUrls }

export const carbonEVMChain: Chain = {
  id: CARBON_EVM_MAINNET.chainId,
  name: CARBON_EVM_MAINNET.chainName,
  nativeCurrency: CarbonEvmNativeCurrency,
  rpcUrls: {
    public: mainnetRPCUrls,
    default: mainnetRPCUrls,
  },
  blockExplorers: {
    default: { name: 'Mainnet EVM', url: blockExplorersUrl },
  },
}

export const carbonEVMChainTestNet: Chain = {
  id: CARBON_EVM_TESTNET.chainId,
  name: CARBON_EVM_TESTNET.chainName,
  nativeCurrency: CarbonEvmNativeCurrency,
  rpcUrls: {
    public: testnetRpcUrls,
    default: testnetRpcUrls,
  },
  blockExplorers: {
    default: { name: 'Testnet EVM', url: blockExplorersUrl },
  },
  testnet: true
}

export const carbonEVMChainDevNet: Chain = {
  id: CARBON_EVM_DEVNET.chainId,
  name: CARBON_EVM_DEVNET.chainName,
  nativeCurrency: CarbonEvmNativeCurrency,
  rpcUrls: {
    public: devnetRpcUrls,
    default: devnetRpcUrls,
  },
  blockExplorers: {
    default: { name: 'Devnet EVM', url: blockExplorersUrl },
  },
}

export const carbonEVMChainLocalhost: Chain = {
  id: CARBON_EVM_LOCALHOST.chainId,
  name: CARBON_EVM_LOCALHOST.chainName,
  nativeCurrency: CarbonEvmNativeCurrency,
  rpcUrls: {
    public: localHostRpcUrls,
    default: localHostRpcUrls,
  },
  blockExplorers: {
    default: { name: 'Localhost EVM', url: blockExplorersUrl },
  },
}

const carbonEvmChains = [carbonEVMChain, carbonEVMChainTestNet, carbonEVMChainDevNet, carbonEVMChainLocalhost]

const supportedCrossChains: readonly Chain[] = [
  ...carbonEvmChains,
  mainnet,
  polygon,
  arbitrum,
  bsc,
  evmos,
  okc,
  mantle,
  optimism,
  base,
]

const walletConnectionChains = [...Object.values(WagmiChains), ...carbonEvmChains]
export const wagmiConfig = getDefaultConfig({
  appName: 'app.dem.exchange',
  projectId: 'c743c2a52bb5e1fd97863489e0b86dc0',
  wallets: Wallets,
  chains: walletConnectionChains as any,
  transports: walletConnectionChains.reduce((acc, { id }) => { acc[id] = http(); return acc }, {} as SimpleMap<HttpTransport>),
  ssr: true,
  multiInjectedProviderDiscovery: true,
})

export const mapChainNames: Record<string, string> = {
  'Arbitrum One': 'Arbitrum',
  'BNB Smart Chain': 'Binance Smart Chain'
}

export const supportedCrosschainsNames = supportedCrossChains.map(c => mapChainNames[c.name] ?? c.name)
