import { Divider, makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import { CarbonSDK } from 'carbon-js-sdk'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import GridLayout, { Layout } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import { useDispatch, useSelector } from 'react-redux'

import { Checkbox, FormControlBox, SvgIcon, TypographyLabel } from 'js/components/Common'
import AssetIcon from 'js/components/Common/AssetIcon'
import ChainTag from 'js/components/Common/ChainTag'
import LearnMoreLink from 'js/components/Common/LearnMoreLink'
import TokenSymbol from 'js/components/Common/TokenSymbol'
import { StaticLinks } from 'js/constants/externalLinks'
import { EvtCategory, useEventTrackers } from 'js/hooks'
import { setTokenFeePreference } from 'js/state/modules/account/actions'
import { getTokenFeePrefs } from 'js/state/modules/account/selectors'
import { getCarbonSDK, getFeeToggle, getMinGasPricesMap, getTokens } from 'js/state/modules/app/selectors'
import { getAdjustedBalances } from 'js/state/modules/walletBalance/selectors'
import { ChartLayoutType, StyleUtils, useCommonStyles } from 'js/utils'
import { BN_ZERO } from 'js/utils/number'

import { ReactComponent as Drag } from 'assets/Drag.svg'

interface Props {
}

const SelectPriorityFeeTokens: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dispatch = useDispatch()
  const eventTrackers = useEventTrackers()
  const adjustedBalances = useSelector(getAdjustedBalances)
  const isWidthXxs = useMediaQuery('@media (max-width: 440px)')
  const feeToggle = useSelector(getFeeToggle)

  const tokens = useSelector(getTokens)
  const tokenFeePref = useSelector(getTokenFeePrefs)
  // TODO: Refactor storing entire grid object inside local storage, should store (denom: priority)[] instead, in general we should make persisted data as lean as possible to avoid compatibility issues between versions
  const [layoutState, setLayoutState] = useState<ChartLayoutType[]>(tokenFeePref?.layout ?? [])
  const [selectedTokensState, setSelectedTokensState] = useState<string[]>(tokenFeePref?.selectedTokens ?? [])
  const [unselectedTokensState, setUnselectedTokensState] = useState<string[]>(tokenFeePref?.unselectedTokens ?? [])
  const [oldLayout, setOldLayout] = useState<ChartLayoutType[]>([])
  const [oldSelectedTokensState, setOldSelectedTokensState] = useState<string[]>([])
  const sdk: CarbonSDK = useSelector(getCarbonSDK) as CarbonSDK
  const minGasPricesMap = useSelector(getMinGasPricesMap)
  const minGasDenoms = Object.keys(minGasPricesMap)
  const address = sdk?.wallet?.bech32Address

  // Load latest token fee pref from store into local state
  useEffect(() => {
    if (tokenFeePref) {
      setLayoutState(tokenFeePref.layout)
      setSelectedTokensState(tokenFeePref.selectedTokens)
      setUnselectedTokensState(tokenFeePref.unselectedTokens)
    }
  }, [tokenFeePref, feeToggle])


  const tokenOpts = React.useMemo(() => {
    return tokens.filter((token: Token) => (
      minGasDenoms.includes(token.denom)
    )).sort((a: Token, b: Token) => {
      // sort according to y
      const aOrder = layoutState.find((l: Layout) => l.i === a.denom)?.y ?? 0
      const bOrder = layoutState.find((l: Layout) => l.i === b.denom)?.y ?? 0
      const aIsSelected = selectedTokensState.includes(a.denom)
      const bIsSelected = selectedTokensState.includes(b.denom)
      if (aIsSelected && !bIsSelected) return -1
      if (!aIsSelected && bIsSelected) return 1
      if (!aOrder) return 1
      if (!bOrder) return -1
      return aOrder - bOrder
    }).map((token: Token) => {
      const balance = adjustedBalances[token.denom]
      const availableBeforeFees = (balance?.available ?? BN_ZERO).plus(balance?.feeReserved ?? BN_ZERO)
      return { ...token, balance: availableBeforeFees }
    })
  }, [tokens, adjustedBalances, layoutState, minGasDenoms, selectedTokensState])

  const handleChange = (denom: string) => {
    if (selectedTokensState.includes(denom)) {
      setSelectedTokensState(selectedTokensState.filter((t: string) => t !== denom))
      setUnselectedTokensState([...unselectedTokensState, denom])
    } else {
      setSelectedTokensState([...selectedTokensState, denom])
      setUnselectedTokensState(unselectedTokensState.filter((t: string) => t !== denom))
    }
  }

  const handleChangeAll = () => {
    if (selectedTokensState.length === minGasDenoms.length) {
      setSelectedTokensState(['swth'])
      const filtered = minGasDenoms.filter((denom) => denom !== 'swth')
      setUnselectedTokensState([...filtered])
    } else {
      setSelectedTokensState(minGasDenoms)
      setUnselectedTokensState([])
    }
  }

  const handleSubmit = () => {
    if ((JSON.stringify(layoutState) !== JSON.stringify(oldLayout)) || (JSON.stringify(selectedTokensState) !== JSON.stringify(oldSelectedTokensState))) {
      const sortedGridLayout = layoutState?.sort((a: Layout, b: Layout) => a.y - b.y) ?? []
      dispatch(setTokenFeePreference({ layout: sortedGridLayout, selectedTokens: selectedTokensState, unselectedTokens: unselectedTokensState }, address))
      const top5tokens = sortedGridLayout.slice(0, 5).map(layout => layout.i).join(' ')
      eventTrackers.sendEvent(EvtCategory.Trade, 'fee_edit_priority', { top5: top5tokens })
      setOldLayout(layoutState)
      setOldSelectedTokensState(selectedTokensState)
    }
  }

  const returnSortedLayout = (layout: Layout[]) => {
    const layoutSorted = layout.sort((a: Layout, b: Layout) => {
      const selectedA = selectedTokensState.includes(a.i)
      const selectedB = selectedTokensState.includes(b.i)
      if (!selectedA && !selectedB) {
        return a.y - b.y
      }
      if (!selectedA) return 1
      if (!selectedB) return -1
      return a.y - b.y
    })
    layoutSorted.forEach((_: Layout, index: number) => {
      layoutSorted[index].y = index
    })
    return layoutSorted
  }

  const handleDrop = (layout: Layout[]) => {
    const layoutSorted = returnSortedLayout(layout)
    setLayoutState(layoutSorted)
  }

  useEffect(() => {
    handleSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTokensState, layoutState])

  return (
    <React.Fragment>
      <TypographyLabel className={classes.subtitle}>
        Select your preferred tokens to use for network fees.
      </TypographyLabel>
      <TypographyLabel className={classes.subtitle}>
        Arrange the tokens you have chosen in the order of priority for Demex to use in paying network fees. Tokens with higher priority will be used first until their balance is insufficient, after which lower priority tokens will be utilized.
        <LearnMoreLink
          inlineBlock
          labelClass={classes.link}
          svgClass={classes.linkIcon}
          atTooltipBottom
          href={StaticLinks.DemexDocs.FlexibleNetworkFees}
        />
      </TypographyLabel>
      <div className={classes.list}>
        <div key="all" className={classes.selectBoxContainer}>
          <FormControlBox
            boxClass={classes.selectBox}
            control={(
              <Checkbox checked={selectedTokensState.length === minGasDenoms.length} onClick={handleChangeAll} size="small" name="all" />
            )}
            label="All Tokens"
            formControlClasses={{
              root: classes.checkboxRoot,
              label: classes.checkboxLbl,
            }}
          />
        </div>
        <Divider className={classes.divider} />
        <GridLayout
          className={classes.layout}
          draggableHandle=".draggable"
          layout={layoutState as Layout[]}
          cols={1}
          rowHeight={isWidthXxs ? 32 : 28}
          isResizable={false}
          compactType="vertical"
          useCSSTransforms={false}
          onDragStop={(layout: Layout[]) => {
            handleDrop(layout)
          }}
        >
          {tokenOpts.map((token) => (
            <div className={classes.tokenRow} key={token.denom}>
              <FormControlBox
                disabled={token.denom === 'swth'}
                boxClass={classes.selectBox}
                control={(
                  <Checkbox checked={selectedTokensState.includes(token.denom)} size="small" className={classes.checkbox} onChange={() => handleChange(token.denom)} />
                )}
                label={(
                  <div className={clsx(selectedTokensState.includes(token.denom) ? classes.activeToken : classes.inactiveToken, commonClasses.alignItemsCenter)}>
                    {isWidthXxs && (
                      <AssetIcon className={classes.tokenSymbol} denom={token.denom} />
                    )}
                    <div className={classes.activeTokenBox}>
                      {!isWidthXxs && (
                        <AssetIcon className={classes.tokenSymbol} denom={token.denom} />
                      )}
                      <div className={commonClasses.alignItemsCenter}>
                        <TokenSymbol denom={token.denom} groupedIndicatorTokenClass={classes.groupedTokenIndicator} />
                        <ChainTag className={classes.chain} denom={token.denom} />
                      </div>
                      {isWidthXxs && (
                        <TypographyLabel className={classes.balanceText}>
                          Balance: {token.balance.decimalPlaces(5).toFormat()}
                        </TypographyLabel>
                      )}
                    </div>
                    {!isWidthXxs && (
                      <TypographyLabel className={classes.balanceText}>
                        Balance: {token.balance.decimalPlaces(5).toFormat()}
                      </TypographyLabel>
                    )}
                  </div>
                )}
                formControlClasses={{
                  root: classes.checkboxRoot,
                  label: clsx(classes.checkboxLbl, classes.checkboxTokenLbl),
                }}
              />
              <SvgIcon className={clsx(classes.draggableIcon, 'draggable')} component={Drag} />
            </div>
          ))}
        </GridLayout>
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    padding: theme.spacing(2.5, 4, 4),
    '@media (max-width: 320px)': {
      padding: theme.spacing(2.5, 2.5, 4),
    },
  },
  activeTokenBox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.25),
    '@media (max-width: 440px)': {
      display: 'block',
    },
  },
  checkboxLbl: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    '@media (max-width: 440px)': {
      ...theme.typography.body4,
    },
  },
  checkboxRoot: {
    marginLeft: theme.spacing(-1.5),
    '& > span:first-child': {
      padding: theme.spacing(0.25),
      margin: theme.spacing(0, 0.5, 0, 0.25),
    },
    '& svg': {
      fontSize: '1.375rem',
    },
  },
  checkboxTokenLbl: {
    color: theme.palette.text.primary,
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
  subtitle: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1.5),
  },
  title: {
    marginBottom: theme.spacing(1.75),
    width: '100%',
    paddingRight: 4,
  },
  selectBoxContainer: {
    padding: theme.spacing(0.5, 0.625)
  },
  selectBox: {
    width: '100%',
  },
  paper: {
    '@media (max-width: 440px)': {
      margin: theme.spacing(1.5),
    },
  },
  divider: {
    margin: theme.spacing(0.25, 0, 0),
  },
  tokenSymbol: {
    height: '1.75em',
    width: '1.75em',
    paddingTop: 0,
    marginLeft: 0,
    '@media (max-width: 440px)': {
      marginRight: 6,
    },
  },
  checkbox: {
    padding: '8px',
    '&.Mui-disabled': {
      opacity: 0.6,
    },
  },
  listSecondaryAction: {
    right: 0,
  },
  draggableIcon: {
    position: 'relative',
    right: 0,
    marginLeft: theme.spacing(2),
    '& path': {
      fill: theme.palette.text.secondary,
    },
    '&:hover': {
      cursor: 'grab',
      '& path': {
        fill: theme.palette.text.demexSolidHover,
        stroke: theme.palette.text.demexSolidHover,
      },
    },
    '&:active': {
      cursor: 'grabbing',
      '& path': {
        fill: theme.palette.text.demexSolid,
        stroke: 'url(#demexsolid)',
      },
    },
  },
  balanceText: {
    color: theme.palette.text.secondary,
    '@media (max-width: 440px)': {
      ...theme.typography.body4,
      marginTop: 2,
    },
  },
  layout: {
    ...StyleUtils.scrollBar(theme),
    width: '100%',
    borderRadius: '4px',
    maxHeight: '160px',
    overflow: 'auto',
    '& .react-grid-item': {
      left: '0 !important',
    },
    '& .react-grid-item.react-grid-placeholder': {
      background: theme.palette.background.primary,
    },
    '& .react-grid-item.react-draggable-dragging': {
      border: `1px solid ${theme.palette.divider}`,
      background: theme.palette.action.active,
      borderRadius: '4px',
      boxShadow: StyleUtils.dropShadow(theme),
    },
  },
  activeToken: {
    color: theme.palette.text.primary,
  },
  inactiveToken: {
    color: theme.palette.text.secondary,
  },
  tokenRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100% !important',
    padding: '4px 0px 4px 5px',
    '@media (max-width: 440px)': {
      padding: '0px 0px 0px 5px',
    },
  },
  chain: {
    margin: '0 4px',
  },
  groupedTokenIndicator: {
    fontSize: 'inherit !important',
  },
  link: {
    ...theme.typography.body3,
  },
  linkIcon: {
    width: '10px',
    height: '10px',
  }
}))

export default SelectPriorityFeeTokens
