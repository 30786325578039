import {  makeStyles, Theme, Typography } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import { useHistory } from "react-router"

import { FEES_REBATE_PERCENTAGE } from "js/constants/competition"
import Paths from "js/constants/paths"

import { ReactComponent as CarbonCreditsBannerIcon } from 'assets/CarbonCreditsBannerIcon.svg'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  rootClass?: string
}

const CarbonCreditsBanner: React.FC<Props> = (props: Props) => {
  const { rootClass } = props
  const history = useHistory()
  const classes = useStyles()

  const handleRedirect = () => {
    history.push(Paths.Rewards.CarbonCreditsTradingLeague)
  }

  return (
    <div className={clsx(classes.bannerWrapper, rootClass)} onClick={handleRedirect}>
      <div className={classes.bannerContainer}>
        <div className={classes.bannerInnerBG}>
          <CarbonCreditsBannerIcon className={classes.icon} />
          <Typography className={classes.bannerText}>Your Trading Fees have been effectively reduced by {FEES_REBATE_PERCENTAGE}% due to our ongoing Carbon Credits incentive program.</Typography>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  bannerWrapper: {
    width: '100%',
    padding: '1px',
    borderRadius: theme.spacing(1),
    background: 'linear-gradient(90deg, #63D0F3 0%, #946DEE 32.81%, #F5827E 68.23%, #FECE5D 100%)',
    cursor: 'pointer',
  },
  bannerContainer: {
    borderRadius: theme.spacing(1),
    background: theme.palette.background.primary,
  },
  bannerInnerBG: {
    background: 'linear-gradient(90deg, rgba(99, 208, 243, 0.12) 0%, rgba(148, 109, 238, 0.12) 32.81%, rgba(245, 130, 126, 0.12) 68.23%, rgba(254, 206, 93, 0.12) 100%)',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  bannerText: {
    ...theme.typography.body3,
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body4,
    },
  },
  icon: {
    minHeight: '1.5rem',
    minWidth: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
  }
}))

export default CarbonCreditsBanner