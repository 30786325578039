import { useRouteMatch } from 'react-router'

import Paths, { DEFAULT_DEPOSIT_LINK } from 'js/constants/paths'

export interface RouteMatches {
  // Trade
  isTrade: boolean
  isRecentTrades: boolean
  isLite: boolean
  // Swap
  isSwap: boolean
  // Markets
  isMarkets: boolean
  // Pools
  isPools: boolean
  // Stake
  isStake: boolean
  // Leaderboard

  isLeaderboard: boolean
  // Competition
  isCompetition: boolean
  // Borrow
  isBorrow: boolean
  // Liquidations
  isLiquidations: boolean
  // GLP Compounder
  isGLPWrapper: boolean
  // Strategies
  isStrategies: boolean
  // Promotion Hub
  isPromotionHub: boolean
  // Wallet
  isAccountMain: boolean
  isUserOverview: boolean
  isUserProfile: boolean
  isWalletBalance: boolean
  isCrosschainTransfer: boolean
  isOrderHistory: boolean
  isTradeHistory: boolean
  isFundingHistory: boolean
  isDeposit: boolean
  isWithdraw: boolean
  isReferrals: boolean
  isAddressManagement: boolean
  isInternalTransfer: boolean
  isCarbonEVMTransfer: boolean
  isCompetitionRegistration: boolean
  isMobilePromo: boolean
  isWelcomeOffer: boolean
  isDepositUSD: boolean
  isSubAccounts: boolean
  isDelegateAccounts: boolean
  isDemexPoints: boolean

  // Vaults
  isFluoDashboard: boolean
  isLaunchVaults: boolean
  isPerpPool: boolean
}

const useGetRouteMatch = (): RouteMatches => {
  // Recent Trades page
  const isRecentTrades = useRouteMatch(Paths.Trade.RecentTrades)

  // Trade page (i.e. Advanced)
  const isTrade = useRouteMatch(Paths.Trade.Main) && !isRecentTrades

  // Lite mode page (i.e. Basic)
  const isLite = useRouteMatch(Paths.Lite)

  // Swap page (i.e. Basic for spot)
  const isSwap = useRouteMatch(Paths.Swap.Home)

  // Markets page
  const isMarkets = useRouteMatch(Paths.Markets.List)

  // Pools page
  const isPerpPool = useRouteMatch(Paths.Pools.PerpList)
  const isPools = useRouteMatch(Paths.Pools.List) && !isPerpPool

  // Stake page
  const isStake = useRouteMatch(Paths.Stake.List)

  // Leaderboard page
  const isLeaderboard = useRouteMatch(Paths.LeaderBoard)

  // Competition Leaderboard page
  const isCompetition = useRouteMatch(Paths.Competition.Main)
  const isCompetitionRegistration = useRouteMatch(Paths.Competition.SignUp)
  const isMobilePromo = useRouteMatch(Paths.Rewards.MobilePromotion)

  // Welcome Offer page
  const isWelcomeOffer = useRouteMatch(Paths.WelcomeOffer)

  // Borrow page (i.e. Nitron)
  const isBorrow = useRouteMatch(Paths.Nitron.Main)

  // Liquidations page (i.e. Nitron Liquidations)
  const isLiquidations = useRouteMatch(Paths.Nitron.Liquidations)

  // GLP Compounder page
  const isGLPWrapper = useRouteMatch(Paths.Strategy.GLPWrapper)

  // Strategies page
  const isStrategies = useRouteMatch(Paths.Strategy.Main)

  // Vaults
  const isLaunchVaults = useRouteMatch(Paths.LaunchVaults.Main || Paths.LaunchVaults.Manage)
  // Promotion hub page
  const isPromotionHub = useRouteMatch(Paths.Rewards.Main)
  const isReferrals = useRouteMatch(Paths.Rewards.Referrals)

  // Wallet Page
  const isAccountMain = useRouteMatch(Paths.Account.Home)
  const isUserOverview = useRouteMatch(Paths.Account.Overview)
  const isUserProfile = useRouteMatch(Paths.Account.Profile)
  const isWalletBalance = useRouteMatch(Paths.Account.Balance)
  const isCrosschainTransfer = useRouteMatch(Paths.Account.Transfer.Crosschain.v2)
  const isOrderHistory = useRouteMatch(Paths.Account.Order.v2)
  const isTradeHistory = useRouteMatch(Paths.Account.Trade.v2)
  const isFundingHistory = useRouteMatch(Paths.Account.Funding)
  const isDeposit = useRouteMatch(Paths.Account.Deposit)
  const isWithdraw = useRouteMatch(Paths.Account.Withdraw)
  const isDepositUSD = useRouteMatch(DEFAULT_DEPOSIT_LINK)
  const isAddressManagement = useRouteMatch(Paths.Account.AddressManagement)
  const isInternalTransfer = useRouteMatch(Paths.Account.Transfer.Internal.v2)
  const isSubAccounts = useRouteMatch(Paths.Account.Subaccounts)
  const isCarbonEVMTransfer = useRouteMatch(Paths.Account.Transfer.CarbonEVM)
  const isDelegateAccounts = useRouteMatch(Paths.Account.Subaccounts)

  // Vaults page
  const isFluoDashboard = useRouteMatch(Paths.FluoDashboard.Main)

  // Demex Points page
  const isDemexPoints = useRouteMatch(Paths.Rewards.DemexPoints.Main)

  return {
    isTrade: Boolean(isTrade),
    isRecentTrades: Boolean(isRecentTrades),
    isLite: Boolean(isLite),
    isSwap: Boolean(isSwap),
    isMarkets: Boolean(isMarkets),
    isPools: Boolean(isPools),
    isPerpPool: Boolean(isPerpPool),
    isStake: Boolean(isStake),
    isFluoDashboard: Boolean(isFluoDashboard),
    isLeaderboard: Boolean(isLeaderboard),
    isCompetition: Boolean(isCompetition),
    isCompetitionRegistration: Boolean(isCompetitionRegistration),
    isMobilePromo: Boolean(isMobilePromo),
    isWelcomeOffer: Boolean(isWelcomeOffer),
    isBorrow: Boolean(isBorrow),
    isLiquidations: Boolean(isLiquidations),
    isGLPWrapper: Boolean(isGLPWrapper),
    isStrategies: Boolean(isStrategies),
    isLaunchVaults: Boolean(isLaunchVaults),
    isPromotionHub: Boolean(isPromotionHub),
    isAccountMain: Boolean(isAccountMain),
    isUserOverview: Boolean(isUserOverview),
    isUserProfile: Boolean(isUserProfile),
    isWalletBalance: Boolean(isWalletBalance && !isDepositUSD),
    isCrosschainTransfer: Boolean(isCrosschainTransfer),
    isInternalTransfer: Boolean(isInternalTransfer),
    isCarbonEVMTransfer: Boolean(isCarbonEVMTransfer),
    isOrderHistory: Boolean(isOrderHistory),
    isTradeHistory: Boolean(isTradeHistory),
    isFundingHistory: Boolean(isFundingHistory),
    isAddressManagement: Boolean(isAddressManagement),
    isDeposit: Boolean(isDeposit),
    isWithdraw: Boolean(isWithdraw),
    isReferrals: Boolean(isReferrals),
    isDepositUSD: Boolean(isDepositUSD),
    isSubAccounts: Boolean(isSubAccounts),
    isDelegateAccounts: Boolean(isDelegateAccounts),
    isDemexPoints: Boolean(isDemexPoints),
  }
}
export default useGetRouteMatch
