import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSidebar from "js/components/Common/Sidebar/LoadingSidebar"
import { useDimensions } from "js/hooks"
import { useCommonStyles } from "js/utils"

import StakeSwitch from "./StakeSwitch"

const StakeFallback: React.FC = () => {
  const classes = useStyles()
  const dimensions = useDimensions()
  const theme = useTheme()
  const commonClasses = useCommonStyles()
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className={clsx(commonClasses.flexRow, classes.container)} style={{ minHeight: isTablet ? dimensions.otherPagesHeight.mobile : dimensions.otherPagesHeight.desktop }}>
      <LoadingSidebar />
      <div className={classes.stakeMain}>
        <StakeSwitch />
      </div>
    </div>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  stakeMain: {
    backgroundColor: theme.palette.background.base,
    maxWidth: 'calc(100% - 15.5rem)',
    minHeight: 'calc(100vh - 48px - 28px)', // top bar and bottom bar
    overflowY: 'auto',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      minHeight: 'max-content',
    },
  },
}))

export default StakeFallback
