import { action } from 'typesafe-actions'

import { Inbox, Preference, TelegramDialog, TriggerVerification, UpdateEmailAction, UpdateTelegramAction, UpdateVerificationStatus, UserProfile, VerificationStatusResponse } from 'js/utils/alerts'

import { AlertsActionTypes, UpdateInboxStatusPayload } from './types'

export const resetAlertsData = () => action(
  AlertsActionTypes.RESET_ALERTS_DATA
)

export const updateUserPreferences = (update: Preference[]) => action(
  AlertsActionTypes.UPDATE_USER_PREFERENCES,
  update,
)

export const setUserProfile = (profile: UserProfile) => action(
  AlertsActionTypes.SET_USER_PROFILE,
  profile,
)

export const setUserPreferences = (preferences: Preference[] | null) => action(
  AlertsActionTypes.SET_USER_PREFERENCES,
  preferences,
)

export const setOptimsticUserPreferences = (preferences: Preference[] | null) => action(
  AlertsActionTypes.SET_OPTIMISTIC_USER_PREFERENCES,
  preferences,
)

export const toggleDisplayStatus = (section: string, show: boolean) => action(
  AlertsActionTypes.UPDATE_DISPLAY_STATUS,
  { section, show },
)

export const toggleTelegramDialog = (toggleTelegramDialog: TelegramDialog) => action(
  AlertsActionTypes.TOGGLE_TELEGRAM_DIALOG,
  toggleTelegramDialog,
)

export const showEmail = () => action(
  AlertsActionTypes.SHOW_EMAIL,
)

export const showTelegram = () => action(
  AlertsActionTypes.SHOW_TELEGRAM,
)

export const triggerVerification = (trigger: TriggerVerification) => action(
  AlertsActionTypes.TRIGGER_VERIFICATION,
  trigger,
)


export const updateEmail = (update: UpdateEmailAction) => action(
  AlertsActionTypes.UPDATE_EMAIL,
  update,
)

export const updateTelegram = (update: UpdateTelegramAction) => action(
  AlertsActionTypes.UPDATE_TELEGRAM,
  update,
)

export const setVerification = (response: VerificationStatusResponse | null) => action(
  AlertsActionTypes.SET_VERIFICATION,
  response,
)

export const updateVerificationStatus = (update: UpdateVerificationStatus) => action(
  AlertsActionTypes.UPDATE_VERIFICATION_STATUS,
  update,
)

export const updateInboxState = (updates: Inbox[]) => action(
  AlertsActionTypes.UPDATE_INBOX,
  updates,
)

export const updateOptimisticInbox = (updates: Inbox[]) => action(
  AlertsActionTypes.UPDATE_OPTIMISTIC_INBOX,
  updates,
)

export const resetInbox = () => action(
  AlertsActionTypes.RESET_INBOX,
)

export const resetOptimisticInbox = () => action(
  AlertsActionTypes.RESET_OPTIMISTIC_INBOX,
)

export const updateInboxStatus = (updates: UpdateInboxStatusPayload[]) => action(
  AlertsActionTypes.UPDATE_INBOX_STATUS,
  updates,
)

export const addPendingInboxUpdates = (updates: UpdateInboxStatusPayload[]) => action(
  AlertsActionTypes.ADD_PENDING_INBOX_STATUS_UPDATES,
  updates,
)

export const removePendingInboxUpdates = (uuids: string[]) => action(
  AlertsActionTypes.REMOVE_PENDING_INBOX_STATUS_UPDATES,
  uuids,
)

export const addPendingPreferencesUpdates = (updates: Preference[]) => action(
  AlertsActionTypes.ADD_PENDING_PREFERENCES_UPDATES,
  updates,
)

export const revertPreferencesOptimisticUpdates = (uuids: string[]) => action(
  AlertsActionTypes.REVERT_PREFERENCES_OPTIMISTIC_UPDATES, uuids,
)

export const revertInboxOptimisticUpdates = (uuids: string[]) => action(
  AlertsActionTypes.REVERT_INBOX_OPTIMISTIC_UPDATES, uuids
)

export const removePendingPreferencesUpdates = (uuids: string[]) => action(
  AlertsActionTypes.REMOVE_PENDING_PREFERENCES_UPDATES,
  uuids,
)

export const pollEmailVerified = (postAuth?: boolean) => action(AlertsActionTypes.POLL_EMAIL_VERIFIED, postAuth)

export const pollTelegramVerified = (postAuth?: boolean) => action(AlertsActionTypes.POLL_TELEGRAM_VERIFIED, postAuth)