import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { StyleUtils, useCommonStyles } from 'js/utils'

import { ReactComponent as CaretDown } from 'assets/CaretDown.svg'
import { ReactComponent as CaretUp } from 'assets/CaretUp.svg'

import SvgIcon from './SvgIcon'
import TextButton from './TextButton'

interface Props extends ButtonProps {
  open?: boolean
  buttonClass?: string
  containerClass?: string
  iconClass?: string
  dark?: boolean
  iconContainerClass?: string
  labelClass?: string
  textButton?: string
}

const DropdownButton: React.FC<Props> = (props: Props) => {
  const {
    className, children, open = false, onClick: onClickCallback, containerClass, iconClass, labelClass, buttonClass, dark = false, iconContainerClass, textButton, disableRipple, ...rest
  } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (typeof onClickCallback === 'function') {
      onClickCallback(event)
    }
  }

  const classNames = clsx(
    classes.root,
    {
      [classes.open]: open,
      [classes.close]: !open,
    },
    className,
  )

  return (
    <Button
      variant="outlined"
      fullWidth
      onClick={onClick}
      classes={{ label: clsx(classes.selectorLabel, labelClass) }}
      disableRipple
      {...rest}
      className={clsx(dark ? classes.darkBg : classes.lightBg, classNames, buttonClass)}
    >
      <div className={clsx(commonClasses.alignItemsCenter, commonClasses.fullWidth, commonClasses.justifyContentSpaceBetween, iconContainerClass)}>
        <div className={clsx(classes.selectorContainer, containerClass)}>
          {children}
        </div>
        {textButton && (
          <TextButton className={classes.gradientTxt} label={textButton}/>
        )}
        <SvgIcon className={clsx(iconClass, classes.selectedIcon, { selected: open })} component={open ? CaretUp : CaretDown} />
      </div>
    </Button>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: 'none',
    textTransform: 'none',
  },
  darkBg: {
    backgroundColor: '#ffffff17',
    borderColor: 'transparent',
    '&:hover': {
      borderColor: '#007AFF',
    },
  },
  selectorContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  selectorLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  open: {
    borderColor: `${theme.palette.primary.main} !important`,
    '& path': {
      stroke: StyleUtils.primaryLayerGradient,
    },
  },
  selectedIcon: {
    '&.selected path': {
      stroke: theme.palette.text.demexSolid,
    },
    cursor: 'pointer',
  },
  gradientTxt: {
    ...theme.typography.body3,
    fontWeight: 700,
    marginRight: theme.spacing(1.25),
  },
}))

export default DropdownButton
