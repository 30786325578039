import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getLoadingTasks } from 'js/state/modules/loadingTask/selectors'

const useTaskSubscriber = (...tasks: string[]) => {
  const loadingTasks = useSelector(getLoadingTasks)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    for (const key of tasks) {
      if (loadingTasks[key]) {
        return setLoading(true)
      }
    }
    return setLoading(false)
  }, [loadingTasks, tasks])


  return [loading]
}

export default useTaskSubscriber
