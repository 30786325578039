import { Theme, useMediaQuery } from "@material-ui/core"
import { useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { useDimensions } from "js/hooks"
import { useCommonStyles } from "js/utils"

const MarketsListFallback: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const theme = useTheme()
  const dimensions = useDimensions()
  const isWidthDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <div className={clsx(classes.root, commonClasses.flexColumn)}>
      <div className={clsx(commonClasses.flexRow, classes.topCardsContainer)}>
        <div className={clsx(commonClasses.alignItemsCenter, commonClasses.justifyContentSpaceBetween, classes.marketsCard)} />
        <div className={clsx(commonClasses.alignItemsCenter, commonClasses.justifyContentSpaceBetween, classes.marketsCard)} />
        <div className={clsx(commonClasses.alignItemsCenter, commonClasses.justifyContentSpaceBetween, classes.marketsCard)} />
        <div className={clsx(commonClasses.alignItemsCenter, commonClasses.justifyContentSpaceBetween, classes.marketsCard)} />
      </div>
      <div className={classes.marketsTableWrapper} style={{ minHeight: isWidthDownSm ? dimensions.otherPagesHeight.mobile : undefined }}>
        <LoadingSkeleton width={200} paddingBottom={48} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.base,
    flex: 1,
  },
  topCardsContainer: {
    width: '1200px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignSelf: 'center',
    paddingTop: '26px',
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  marketsCard: {
    cursor: 'pointer',
    backgroundColor: theme.palette.background.primary,
    borderRadius: '4px',
    width: '25%',
    height: '100px',
    marginRight: '16px',
    padding: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      width: '33%',
    },
  },
  marketsTableWrapper: {
    background: theme.palette.background.primary,
    color: theme.palette.text.primary,
    width: '1200px',
    minHeight: '1000px',
    marginBottom: '2rem',
    padding: theme.spacing(3, 4),
    borderRadius: '4px',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(1.25, 1.5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}))

export default MarketsListFallback