import { CarbonSDK, Insights, TypeUtils } from 'carbon-js-sdk'
import { NetworkConfig, NetworkConfigs } from 'carbon-js-sdk/lib/constant/network' // eslint-disable-line

async function getNodeLatency(rpcUrl: string): Promise<number> {
  const start = Date.now()
  try {
    await fetch(`${rpcUrl}/status`)
    const latency = Date.now() - start // in milisecond
    return latency
  } catch (err) {
    return 999
  }
}

export interface RatingLatency {
  rating: number
  latency: number
}

export interface NodeWithFallbackFlag extends Insights.NodeItem {
  isFallbackNode?: boolean
}

export type RatingLatencyObj = TypeUtils.SimpleMap<RatingLatency>

export async function getNodesRating(nodes: NodeWithFallbackFlag[]): Promise<RatingLatencyObj> {
  const ratingLatencyObj: RatingLatencyObj = {}

  for (const node of nodes) {
    // wsUptime, insightUpTime, rpcUpTime
    const wsWeightage = 3
    const insightWeightage = 3
    const rpcWeightage = 3
    // eslint-disable-next-line no-await-in-loop
    const latency = await getNodeLatency(node.rpcUrl)
    let latencyRating = 0.0
    switch (true) {
      case latency <= 100:
        latencyRating = 1
        break
      case latency <= 200:
        latencyRating = 0.8
        break
      case latency <= 400:
        latencyRating = 0.6
        break
      case latency <= 600:
        latencyRating = 0.4
        break
      case latency < 999:
        latencyRating = 0.2
        break
      default:
        latencyRating = 0.0
        break
    }
    const latencyWeightage = 1
    const rating = ((parseInt(node?.wsUptime, 10) * wsWeightage) + (parseInt(node?.insightUptime, 10) * insightWeightage) + (parseInt(node?.rpcUptime, 10) * rpcWeightage))
    const ratingPercent = rating * 0.01 // convert to rating of 0.0 to 1
    const latencyRatingPercent = latencyRating * latencyWeightage
    const finalRating = (ratingPercent + latencyRatingPercent) / (wsWeightage + insightWeightage + rpcWeightage + latencyWeightage)
    ratingLatencyObj[node.nodeId] = {
      rating: finalRating,
      latency,
    }
  }
  return ratingLatencyObj
}

export type NodeFormKey = keyof NodeFormState

export interface NodeFormState {
  moniker: string
  rpcUrl: string
  restUrl: string
  wsUrl: string
  faucetUrl: string
  insightsUrl: string
  tmWsUrl: string
}

export const baseNodeForm: NodeFormState = {
  moniker: '',
  rpcUrl: '',
  restUrl: '',
  wsUrl: '',
  faucetUrl: '',
  insightsUrl: '',
  tmWsUrl: '',
}

// sort nodes by node rating
export const sortByRatingLatencies = (ratingLatencies: RatingLatencyObj): (a: Insights.NodeItem, b: Insights.NodeItem) => number => {
  return (a: Insights.NodeItem, b: Insights.NodeItem) => {
    const ratingA = ratingLatencies[a.nodeId]?.rating ?? 0
    const ratingB = ratingLatencies[b.nodeId]?.rating ?? 0

    const diff = ratingB - ratingA
    if (diff === 0) {
      const latencyA = ratingLatencies[a.nodeId]?.latency ?? 0
      const latencyB = ratingLatencies[b.nodeId]?.latency ?? 0
      return latencyB - latencyA
    }

    return ratingB - ratingA
  }
}

export const getDefaultConfig = (net: CarbonSDK.Network = CarbonSDK.Network.MainNet): NetworkConfig => {
  return NetworkConfigs[net]
}
