import BigNumber from 'bignumber.js'

import { SimpleMap } from 'js/utils'
import { BN_ZERO } from 'js/utils/number'

import { EMPTY_PRICES, MarketPrices, ModifiedMarketStat } from './types'

import { RootState } from '../rootReducer'

export function getMarketStats(state: RootState): SimpleMap<ModifiedMarketStat> {
  return state.marketStats.marketStats
}

export function getCurrentMarketPrices(state: RootState): MarketPrices {
  return state.marketStats.marketPrices ?? EMPTY_PRICES
}

export function getCurrentMarketMarkPrice(state: RootState): BigNumber {
  return state.marketStats.marketPrices?.mark ?? BN_ZERO
}

export function getCurrentMarketIndexPrice(state: RootState): BigNumber {
  return state.marketStats.marketPrices?.index ?? BN_ZERO
}

export function getCurrentMarketLastPrice(state: RootState): BigNumber {
  return state.marketStats.marketPrices?.last ?? BN_ZERO
}
