import { IconButton, makeStyles } from '@material-ui/core'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Bounce, cssTransition, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { MAX_TOAST, MOBILE_MAX_TOAST } from 'js/constants/notification'
import { StyleUtils } from 'js/utils/styles'

import { ReactComponent as Close } from 'assets/Close.svg'

export interface Props {
}

const CloseButton = (props: any) => {
  const { type, closeToast, ...buttonProps } = props
  const handleClose = () => {
    if (closeToast) {
      closeToast()
    }
  }
  return (
    <IconButton {...buttonProps} onClick={handleClose}>
      <Close />
    </IconButton>
  )
}

const fadeInAndOut =  cssTransition({
  enter: 'fade-in',
  exit: 'fade-out',
  collapseDuration: 150,
  duration: 300
})

const Notifications: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props)

  return (
    <ToastContainer
      position={isMobileOnly ? 'top-right' : 'bottom-right'}
      limit={isMobileOnly ? MOBILE_MAX_TOAST : MAX_TOAST}
      autoClose={15000}
      newestOnTop
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      toastClassName={classes.toast}
      bodyClassName={classes.toastBody}
      pauseOnHover
      closeButton={<CloseButton className={classes.closeButton} />}
      style={{ bottom: '1.25rem' }}
      progressClassName={classes.progress}
      className={classes.toastContainer}
      transition={isMobileOnly ? fadeInAndOut : Bounce}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  toast: {
    padding: 0,
    borderRadius: 2,
    minHeight: theme.spacing(8),
  },
  toastContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '13.75rem',
    },
    width: '20.75rem',
    '& .Toastify__toast': {
      borderRadius: 4,
      background: theme.palette.background.secondary,
      '& $progress': {
        height: 2,
      },
      '&.toast-success $progress': {
        background: StyleUtils.accentSuccess,
      },
      '&.toast-warning $progress': {
        background: StyleUtils.accentWarning,
      },
      '&.toast-info $progress, &.toast-loading $progress': {
        background: theme.palette.text.demexSolid,
      },
      '&.toast-error $progress': {
        background: StyleUtils.accentError,
      },
      '@media only screen and (max-width: 480px)': {
        marginBottom: theme.spacing(1)
      },
    },
    '@media only screen and (max-width: 480px)': {
      left: 'unset',
      top: theme.spacing(6),
    },
    '@media (max-width: 342px)': {
      right: 0,
      marginRight: theme.spacing(0.75),
    },
    '& .dismissToastWrapper': {
      height: 'unset',
      minHeight: 'unset',
    },
    '& .dismissToastBody': {
      borderTop: 'none',
    },
  },
  toastBody: {
    backgroundColor: theme.palette.background.secondary,
    borderTop: `2px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1.5, 2.5, 2, 1.75),
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 1.75, 1.5, 1),
    },
  },
  closeButton: {
    padding: theme.spacing(1.75),
    position: 'absolute',
    top: 0,
    right: 0,
    '& svg': {
      width: '1rem',
      height: '1rem',
    },
  },
  progress: {
    bottom: 'unset',
    top: 0,
  },
}))

export default Notifications
