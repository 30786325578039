import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { useCommonStyles } from "js/utils"

const ManageStakeFallback: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const commonClasses = useCommonStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const gridSpacing = React.useMemo(() => {
    if (isMobile) return 0
    return 4
  }, [isMobile])

  return (
    <Grid container className={classes.bigGrid} spacing={gridSpacing} style={{ maxHeight: 'calc(100vh - 48px - 95px)' }}>
      <Grid item xs={12} md={4}>
        <div className={clsx(classes.gridItem, commonClasses.flexColumn)}>
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton />
        </div>
      </Grid>
      <Grid item xs={12} md={8}>
        <div className={clsx(classes.gridItem, commonClasses.flexColumn)}>
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton />
        </div>
      </Grid>
    </Grid>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  bigGrid: {
    margin: 0,
    backgroundColor: theme.palette.background.base,
    padding: theme.spacing(1.5),
    width: '100%',
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(7.5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.only('xs')]: {
      '& > div': {
        padding: `${theme.spacing(0.5, 0)} !important`,
      },
    },
  },
  gridItem: {
    height: '100%',
    minHeight: '400px',
    maxHeight: '100%',
    backgroundColor: theme.palette.background.primary,
    borderRadius: '4px',
    padding: theme.spacing(2.5, 4),
  },
}))

export default ManageStakeFallback