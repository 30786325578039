import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { useCommonStyles } from 'js/utils'
export interface LoadingIconProps {
  color?: 'inherit' | 'primary' | 'secondary',
  size?: string | number,
  customClass?: string,
  rootClass?: string
  style?: React.CSSProperties,
  thickness?: number,
}

const LoadingIcon: React.FC<LoadingIconProps> = (props: LoadingIconProps) => {
  const { color = 'primary', size = '1em', customClass, rootClass, style, thickness } = props
  const classes = useStyles(props)
  const commonClasses = useCommonStyles()
  const theme = useTheme()

  const getBorderColor = () => {
    if (color === 'inherit') return color
    return color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main
  }

  const { borderColor, parsedSize } = React.useMemo(() => {
    let parsedSize = ''
    if (typeof size === 'string') {
      parsedSize = size
    } else {
      parsedSize = `${size.toString()}px`
    }
    return {
      borderColor: `${getBorderColor()}`,
      parsedSize,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, size])

  return (
    <div style={{ width: parsedSize, height: parsedSize }} className={clsx(classes.root, rootClass, commonClasses.posRelative)}>
      <div
        className={clsx(classes.loading, customClass)}
        style={{
          borderColor,
          borderRightColor: `${theme.palette.background.tertiary}`,
          borderBottomColor: `${theme.palette.background.tertiary}`,
          border: `solid ${thickness}px`,
          width: parsedSize,
          height: parsedSize,
          ...style,
        }} />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    background: 'none',
  },
  '@keyframes rotate': {
    from: {
      transform: 'rotate(135deg)',
    },
    to: {
      transform: 'rotate(495deg)',
    },
  },
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: 'solid 2px',
    borderRadius: '50%',
    transition: 'all 0.5s ease-in',
    transformOrigin: 'center',
    animation: '$rotate 1s linear infinite',
    '-webkit-transition': 'all 0.5s ease-in',
    '-webkit-animation-name': '$rotate',
    '-webkit-animation-duration': '1.0s',
    '-webkit-animation-iteration-count': 'infinite',
    '-webkit-animation-timing-function': 'linear',
  },
  loadingText: {
    width: '100%',
    height: '100%',
    fontSize: 'calc(100% - 3px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default LoadingIcon
