import { makeStyles, Theme } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'

import { useCommonStyles } from 'js/utils'


export const LiteContainer = ({ children }: PropsWithChildren<{}>) => {
  const classes = containerStyles()
  const commonClasses = useCommonStyles()

  return (
    <div className={commonClasses.fullWidth}>
      <div className={classes.root}>
        {children}
      </div>
    </div>
  )
}
const containerStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: '3rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  loader: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },
}))
