import { Carbon, ExternalUtils, Models, TypeUtils } from 'carbon-js-sdk'
import { action } from 'typesafe-actions'

import { TokenFeeSettings } from 'js/constants/assets'
import { UserNotification } from 'js/utils/notifications'

import { AccountActionTypes, AccountProfile, ConnectionError, Contact, ContactData, GranteeDetails, LastTransaction, ReservedTokensPreference, SignlessDialogTrigger, SmartWalletAddressMap, SocialLoginInfo, TokenFeePreference, TxBeforeMergeEvm } from './types'


export const logout = () => action(AccountActionTypes.LOGOUT)

export const pollProfileUpdate = (expected: AccountProfile) => action(
  AccountActionTypes.POLL_PROFILE_UPDATE,
  expected,
)

export const setLoginRedirect = (path: string | null) => action(
  AccountActionTypes.SET_LOGIN_REDIRECT,
  path,
)

export const setNeoExternalBalances = (payload: ExternalUtils.TokensWithExternalBalance[]) => action(
  AccountActionTypes.SET_NEO_EXTERNAL_BALANCES,
  payload,
)

export const setEthExternalBalances = (payload: ExternalUtils.TokensWithExternalBalance[]) => action(
  AccountActionTypes.SET_ETH_EXTERNAL_BALANCES,
  payload,
)

export const setBscExternalBalances = (payload: ExternalUtils.TokensWithExternalBalance[]) => action(
  AccountActionTypes.SET_BSC_EXTERNAL_BALANCES,
  payload,
)

export const setSmartWalletAddress = (payload: SmartWalletAddressMap) => action(
  AccountActionTypes.SET_SMART_WALLET_ADDRESSES,
  payload,
)

export const setProfile = (payload: Carbon.Profile.Profile) => action(
  AccountActionTypes.SET_PROFILE,
  payload,
)

export const setSocialLoginInfo = (payload: SocialLoginInfo | undefined) => action(
  AccountActionTypes.SET_SOCIAL_LOGIN_INFO,
  payload,
)

export const setTokenFeeSettings = (settings: TokenFeeSettings, address?: string) => action(
  AccountActionTypes.SET_TOKEN_FEE_SETTINGS,
  {
    address,
    settings,
  },
)

export const loadIsSWTHDefaultWithdrawal = (address?: string) => action(
  AccountActionTypes.LOAD_DEFAULT_WITHDRAWAL_SWTH,
  {
    address,
  },
)

export const setEnableReservedFee = (enable: boolean, address?: string) => action(
  AccountActionTypes.ENABLE_RESERVED_FEE_FEATURE,
  {
    address,
    enable,
  },
)

export const toggleHideZeroBalances = (hide: boolean) => action(
  AccountActionTypes.SET_HIDE_ZERO,
  hide,
)

export const setTokenFeePreference = (tokenFeePref: TokenFeePreference, address?: string) => action(
  AccountActionTypes.SET_TOKEN_FEE_PREFERENCE,
  {
    tokenFeePref,
    address,
  },
)

export const fetchContactData = () => action(
  AccountActionTypes.FETCH_CONTACT_DATA,
)

export const setContactData = (contactData: ContactData) => action(
  AccountActionTypes.SET_CONTACT_DATA,
  contactData,
)

export const setRecentlyUsedContactData = (contacts: Contact[]) => action(
  AccountActionTypes.SET_RECENTLY_USED_CONTACT_DATA,
  contacts,
)

export const handleUpdateRecentlyUsedContactData = (contact: Contact) => action(
  AccountActionTypes.HANDLE_UPDATE_RECENTLY_USED_CONTACT_DATA,
  contact,
)

export const handleClearRecentlyUsedContactData = () => action(
  AccountActionTypes.CLEAR_RECENTLY_USED_CONTACT_DATA
)

export const setShowAddAddressDialog = (showAddAddressDialog: boolean) => action(
  AccountActionTypes.SET_SHOW_ADD_ADDRESS_DIALOG,
  showAddAddressDialog,
)

export const queryUserNotifications = () => action(
  AccountActionTypes.QUERY_USER_NOTIFICATIONS,
)

export const setUserNotifications = (userNotifications: UserNotification[]) => action(
  AccountActionTypes.SET_USER_NOTIFICATIONS,
  userNotifications,
)

export const resetUserNotifications = () => action(
  AccountActionTypes.RESET_USER_NOTIFICATIONS,
)

export const setConnectionError = (error: ConnectionError) => action(
  AccountActionTypes.SET_CONNECTION_ERROR,
  error,
)

export const setShowCarbonAddress = (boolean: boolean) => action(
  AccountActionTypes.SET_SHOW_CARBON_ADDRESS,
  boolean,
)

export const setShowCreateSubAccountDialog = (show: boolean) => action(
  AccountActionTypes.SET_SHOW_CREATE_SUBACCOUNT_DIALOG,
  show,
)

export const fetchPendingSubAccountCreations = () => action(
  AccountActionTypes.FETCH_PENDING_SUBACCOUNT_CREATIONS,
)

export const fetchPendingSubAccountActivations = () => action(
  AccountActionTypes.FETCH_PENDING_SUBACCOUNT_ACTIVATIONS,
)

export const setPendingSubAccountCreations = (subAccounts: Carbon.Subaccount.SubAccount[]) => action(
  AccountActionTypes.SET_PENDING_SUBACCOUNT_CREATIONS,
  subAccounts,
)

export const setPendingSubAccountActivations = (subAccounts: Carbon.Subaccount.SubAccount[]) => action(
  AccountActionTypes.SET_PENDING_SUBACCOUNT_ACTIVATIONS,
  subAccounts,
)

export const fetchSubAccounts = () => action(
  AccountActionTypes.FETCH_SUBACCOUNTS,
)

export const setSubAccounts = (subAccounts: Carbon.Subaccount.SubAccount[]) => action(
  AccountActionTypes.SET_SUBACCOUNTS,
  subAccounts,
)

export const resetSubAccountsData = () => action(
  AccountActionTypes.RESET_SUBACCOUNTS_DATA,
)

export const setSubAccountCooldown = (cooldown: Models.Duration) => action(
  AccountActionTypes.SET_SUBACCOUNT_COOLDOWN,
  cooldown,
)

export const fetchSubAccountCooldown = () => action(
  AccountActionTypes.FETCH_SUBACCOUNT_COOLDOWN,
)

export const setPendingNewSubaccountNotifications = (subAccounts: Carbon.Subaccount.SubAccount[]) => action(
  AccountActionTypes.SET_PENDING_SUBACCOUNT_NOTIFICATIONS,
  subAccounts,
)

export const setisSWTHDefaultWithdrawal = (boolean: boolean, address?: string) => action(
  AccountActionTypes.SET_DEFAULT_WITHDRAWAL_SWTH,
  {
    boolean,
    address,
  },
)

export const setIsSWTHWithdrawal = (boolean: boolean) => action(
  AccountActionTypes.SET_WITHDRAWAL_SWTH,
  boolean
)

export const setReservedTokensPreference = (reservedTokensPreferences: ReservedTokensPreference) => action(
  AccountActionTypes.SET_RESERVED_TOKENS_PREFERENCE,
  reservedTokensPreferences
)

export const setEnableSignlessTransactions = (enableSignless: boolean) => action(
  AccountActionTypes.SET_ENABLE_SIGNLESS_TRANSACTIONS,
  enableSignless
)

export const setGranteeDetailsMap = (detailsMap: TypeUtils.SimpleMap<GranteeDetails>) => action(
  AccountActionTypes.SET_GRANTEE_DETAILS,
  detailsMap,
)

export const setLastTransactionTimestamp = (lastTransactionMap: TypeUtils.SimpleMap<LastTransaction>) => action(
  AccountActionTypes.SET_LAST_TRANSACTION_TIMESTAMP,
  lastTransactionMap,
)

export const setSignlessDialogTrigger = (trigger: SignlessDialogTrigger) => action(
  AccountActionTypes.SET_SIGNLESS_DIALOG_TRIGGER,
  trigger,
)

export const setTxBeforeMergeEvm = (txBeforeMergeEvm: TxBeforeMergeEvm | null) => action(
  AccountActionTypes.SET_TX_BEFORE_MERGE_EVM,
  txBeforeMergeEvm
)

export const setIsNewUser = (isNewUser: boolean) => action(
  AccountActionTypes.SET_NEW_USER_STATUS,
  isNewUser
)

export const setIsCompletedAllStepOnboarding = () => action(
  AccountActionTypes.SET_IS_COMPLETED_ONBOARDING,
)
