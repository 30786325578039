import { useMemo } from 'react'
import { useLocation } from 'react-router'

import { IS_ENV_MASTER } from 'js/utils/environment'

const useDebugInfo = () => {
  const location = useLocation()

  const renderDebugInfo = useMemo(() => {
    const isDebugMode = location.search.replace('?', '').includes('debug=true')
    return !IS_ENV_MASTER || isDebugMode
  }, [location])

  return renderDebugInfo
}

export default useDebugInfo