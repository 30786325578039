import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { BUILD_TIMESTAMP } from 'js/utils/environment'

import TypographyLabel from '../TypographyLabel'

interface Props extends React.HTMLAttributes<HTMLDivElement> {

}


const BuildTimestamp: React.FC<Props> = (props: Props) => {
  const { children, className, ...rest } = props
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <TypographyLabel className={classes.label}>Build: {BUILD_TIMESTAMP}</TypographyLabel>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  label: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
  },
}))

export default BuildTimestamp
