import BigNumber from 'bignumber.js'
import { TypeUtils } from 'carbon-js-sdk'
import { PoolRoute } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/liquiditypool'
import { CommitmentCurve, RewardCurve } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/reward'

import { RewardCoin } from 'js/constants/liquidityPools'
import { Pool } from 'js/models/Pool'
import {
  DistributedRewardsMap,
  DistributionParams,
  PoolStakeInfoMap,
  PoolTransaction, TotalCommitObj,
  TxFilter,
  UnclaimedRewardsData,
  UnclaimedRewardsMap,
} from 'js/state/modules/liquidityPool/types'

import { RootState } from '../rootReducer'


export function getPools(state: RootState): TypeUtils.SimpleMap<Pool> {
  return state.pools.pools
}

export function getPoolRoutes(state: RootState): TypeUtils.SimpleMap<PoolRoute[]> | undefined {
  return state.pools.poolRoutes
}

export function getPoolsUnclaimedRewards(state: RootState): UnclaimedRewardsMap {
  return state.pools.poolsUnclaimedRewards
}

export function getUnclaimedUsdMap(state: RootState): UnclaimedRewardsData {
  return state.pools.poolsUnclaimedUsd
}

export function getPoolsWeeklyPoolsRewardsRealInflation(state: RootState): BigNumber {
  return state.pools.poolsWeeklyRewardsRealInflation
}

export function getPoolVolumes(state: RootState): TypeUtils.SimpleMap<BigNumber> {
  return state.pools.poolVolumes
}

export function getPoolsTransactions(state: RootState): PoolTransaction[] {
  return state.pools.poolsTransactions
}

export function getStakedTokensMap(state: RootState): PoolStakeInfoMap {
  return state.pools.poolStakedInfoMap
}

export function getCommitmentCurve(state: RootState): CommitmentCurve {
  return state.pools.poolCommitmentCurve
}

export function getPoolRewardCurve(state: RootState): RewardCurve {
  return state.pools.poolRewardCurve
}

export function getDistributionParams(state: RootState): DistributionParams | undefined {
  return state.pools.distributionParams
}

export function getDistributedRewards(state: RootState): DistributedRewardsMap {
  return state.pools.distributedRewards
}

export function getShowZeroRewards(state: RootState): boolean {
  return state.pools.showZeroRewards
}

export function getShowSmallPools(state: RootState): boolean {
  return state.pools.showSmallPools
}

export function getShowUnlinkedPools(state: RootState): boolean {
  return state.pools.showUnlinkedPools
}

export function getTxFilter(state: RootState): TxFilter {
  return state.pools.txFilter
}

export function getTotalCommitObj(state: RootState): TotalCommitObj {
  return state.pools.totalCommitObj
}

export function getPoolsRewardCoins(state: RootState): RewardCoin[] {
  return state.pools.poolsRewardCoins
}

export function getTotalPoolRewardsUsd(state: RootState): BigNumber {
  return state.pools.totalPoolRewardsUsd
}
