import { Theme } from '@material-ui/core'

import { StyleUtils } from 'js/utils'

import { PickersOverrides } from './types'

const MuiPickersDay: PickersOverrides = (theme: Theme) => ({
  daySelected: {
    background: StyleUtils.buttonGradient(theme),
    color: '#F5F5F7 !important',
    WebkitTextFillColor: 'unset',
    backgroundClip: 'unset',
    TextFillColor: 'unset',
    borderRadius: theme.shape.borderRadius,
    width: '24px',
    height: '24px',
    margin: '6px 10px',
    [theme.breakpoints.only('xs')]: {
      margin: '4px 6px',
    },
    '@media (max-width: 320px)': {
      margin: '0 4px',
    },
  },
  current: {
    color: theme.palette.text.primary,
  },
  dayDisabled: {
    color: theme.palette.text.disabled,
  },
  day: {
    borderRadius: theme.shape.borderRadius,
    transition: 'none',
    width: '40px',
    [theme.breakpoints.only('xs')]: {
      width: '32px',
      height: '32px',
    },
    '@media (max-width: 320px)': {
      width: '28px',
      height: '28px',
    },
    '& > span > p': {
      fontSize: '13px',
    },
  },
})

export default MuiPickersDay
