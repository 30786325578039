import {
  StyleRules, Theme, withStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import SvgIcon from 'js/components/Common/SvgIcon'

import { ReactComponent as Caret } from 'assets/CaretUp.svg'

interface Props {
  classes: StyleRules
  className?: string
  open: boolean
}

const DropDownIcon: React.FC<Props> = (props: Props) => {
  const { classes, className, open } = props

  const classNames = clsx(
    classes.root,
    {
      [classes.open]: open,
      [classes.close]: !open,
    },
    className,
  )

  return <SvgIcon className={classNames} component={Caret} />
}

const styles = ((theme: Theme) => ({
  root: {
    fontSize: '0.375rem',
    transition: 'transform .2s ease-in-out',
  },
  open: {
    '& path': {
      stroke: theme.palette.text.demexSolid,
    },
  },
  close: {
    transform: 'rotate(180deg)',
    '& path': {
      stroke: theme.palette.text.secondary,
    },
  },
}))

export default withStyles(styles, { name: 'MuiDropdown' })(DropDownIcon)
