import { Link, LinkProps, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { StyleUtils, useCommonStyles } from 'js/utils/styles'

import SvgIcon from './SvgIcon'

export interface TextButtonProps extends LinkProps {
  boxClass?: string
  labelClass?: string
  svgClass?: string
  endIcon?: string | SVGComponent
  label: string | ReactNode
  startIcon?: string | SVGComponent
  fillType?: 'stroke' | 'fill'
  disabled?: boolean
  isLink?: boolean
  svgWidth?: number
  svgHeight?: number
  isInline?: boolean
  to?: string
}

const TextButton: React.FC<TextButtonProps> = (props: TextButtonProps) => {
  const { boxClass, labelClass, svgClass, className, endIcon, label, startIcon, fillType = 'stroke', disabled, isLink = true, isInline = false, svgHeight, svgWidth, children, to: routerDestination, ...rest } = props
  const classes = useStyles(props)
  const commonClasses = useCommonStyles()
  if (disabled) return (
    <div className={clsx(boxClass, classes.disabledBox, classes.root, commonClasses.alignItemsCenter, { [classes.inline]: isInline })}>
      {startIcon && (
        <SvgIcon className={clsx(classes.disabledIcon, className, fillType, svgClass, 'start')} component={startIcon} />
      )}
      <div className={clsx(classes.disabledTxt, labelClass)}>{label}</div>
      {children}
      {endIcon && (
        <SvgIcon className={clsx(classes.disabledIcon, className, fillType, svgClass, 'end')} component={endIcon} />
      )}
    </div>
  )
  if (routerDestination) return (
    <div className={clsx(boxClass, classes.root, commonClasses.alignItemsCenter, { [classes.inline]: isInline })}>
      <RouterLink className={clsx(classes.link, className, { notLink: !isLink })} to={routerDestination} {...rest}>
        {startIcon && (
          <SvgIcon className={clsx(classes.svgIcon, fillType, svgClass, 'start')} component={startIcon} />
        )}
        <div className={clsx(classes.gradientTxt, labelClass)}>{label}</div>
        {children}
        {endIcon && (
          <SvgIcon className={clsx(classes.svgIcon, fillType, svgClass, 'end')} component={endIcon} />
        )}
      </RouterLink>
    </div>
  ) 
  return (
    <div className={clsx(boxClass, classes.root, commonClasses.alignItemsCenter, { [classes.inline]: isInline })}>
      <Link className={clsx(classes.link, className, { notLink: !isLink })} {...rest}>
        {startIcon && (
          <SvgIcon className={clsx(classes.svgIcon, fillType, svgClass, 'start')} component={startIcon} />
        )}
        <div className={clsx(classes.gradientTxt, labelClass)}>{label}</div>
        {children}
        {endIcon && (
          <SvgIcon className={clsx(classes.svgIcon, fillType, svgClass, 'end')} component={endIcon} />
        )}
      </Link>
    </div>
  )
}

const useStyles = makeStyles<Theme, TextButtonProps>((theme: Theme) => ({
  root: {
    userSelect: 'none',
    color: theme.palette.text.demexSolid,
  },
  inline: {
    display: 'inline-block',
  },
  disabledBox: {
    cursor: 'default !important',

  },
  disabledTxt: {
    ...theme.typography.body3,
    fontWeight: 700,
    color: theme.palette.text.disabled,
  },
  disabledIcon: {
    maxWidth: 12,
    width: '100%',
    height: 12,
    '&.start': {
      margin: theme.spacing(0, 0.5, 0, 0.75),
    },
    '&.end': {
      margin: theme.spacing(0, 0.5, 0, 0.75),
    },
    '&.fill': {
      '& path': {
        fill: `${theme.palette.text.disabled} !important`,
      },
    },
    '&.stroke': {
      '& path': {
        stroke: `${theme.palette.text.disabled} !important`,
      },
    },
  },
  gradientTxt: {
    ...theme.typography.body3,
    fontWeight: 700,
  },
  link: {
    ...StyleUtils.textButtonHoverAnimationDuration(),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '&.notLink': {
      textDecoration: 'none !important',
      flexGrow: 1,
      placeContent: 'flex-end',
    },
    fontWeight: 700,
    color: theme.palette.text.demexSolid,
    '&:hover': {
      color: theme.palette.text.demexSolidHover,
      textDecoration: 'none',
      '& svg': {
        '&.fill': {
          '& path': {
            fill: theme.palette.text.demexSolidHover,
          },
        },
        '&.stroke': {
          '& path': {
            stroke: theme.palette.text.demexSolidHover,
          },
        },
      }
    },
  },
  svgIcon: (props: TextButtonProps) => ({
    maxWidth: props.svgWidth || 12,
    width: '100%',
    height: props.svgHeight || 12,
    '&.start': {
      margin: theme.spacing(0, 0.5),
    },
    '&.end': {
      margin: theme.spacing(0, 0.5),
    },
    '&.fill': {
      '& path': {
        ...StyleUtils.textButtonHoverAnimationDuration(),
        fill: theme.palette.text.demexSolid,
      },
    },
    '&.stroke': {
      '& path': {
        ...StyleUtils.textButtonHoverAnimationDuration(),
        stroke: theme.palette.text.demexSolid,
      },
    },
  }),
}))

export default TextButton
