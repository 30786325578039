import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"
import React, { useRef } from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { useDimensions } from "js/hooks"
import { useCommonStyles } from "js/utils"

const StakeFallbackMain: React.FC = () => {
  const classes = useStyles()
  const dimensions = useDimensions()
  const theme = useTheme()
  const validatorPoolsRef = useRef<HTMLDivElement>(null)
  const commonClasses = useCommonStyles()
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))

  const gridSpacing = React.useMemo(() => {
    if (isTablet) return 0
    return 3
  }, [isTablet])

  if (isTablet) return (
    <React.Fragment >
      <div className={clsx(commonClasses.flexRow, commonClasses.alingItemsCenter, commonClasses.justifyContentSpaceBetween, classes.mobileAccordionDiv)}>
        <LoadingSkeleton width={200} height={16} />
      </div>
      <div className={clsx(commonClasses.flexRow, commonClasses.alingItemsCenter, commonClasses.justifyContentSpaceBetween, classes.mobileAccordionDiv)}>
        <LoadingSkeleton width={200} height={16} />
      </div>
      <div className={classes.sectionBody} style={{ minHeight: dimensions.otherPagesHeight.mobile }}>
        <LoadingSkeleton width={180} paddingBottom={48} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton />
      </div>
    </React.Fragment>
  )
  return (
    <Grid container className={classes.bigGrid} spacing={gridSpacing}>
      <Grid item xs={12}>
        <div className={clsx(classes.gridItem, classes.firstRow, commonClasses.alignItemsCenter, commonClasses.justifyContentSpaceBetween)}>
          <LoadingSkeleton />
        </div>
      </Grid>
      <Grid item xs={8}>
        <div className={clsx(classes.gridItem, classes.middleRow, commonClasses.fullWidth)}>
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={clsx(classes.gridItem, classes.middleRow)}>
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          className={classes.validatorPoolsSection}
          ref={validatorPoolsRef}
          style={{ minHeight: dimensions.otherPagesHeight.desktop - 240 }}
        >
          <LoadingSkeleton width={180} paddingBottom={48} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton />
        </div>
      </Grid>
    </Grid>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  bigGrid: {
    margin: 0,
    backgroundColor: theme.palette.background.base,
    padding: theme.spacing(1.5),
    width: '100%',
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(7.5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.only('xs')]: {
      '& > div': {
        padding: `${theme.spacing(0.5, 0)} !important`,
      },
    },
  },
  gridItem: {
    height: '100%',
    backgroundColor: theme.palette.background.primary,
    borderRadius: '4px',
  },
  firstRow: {
    padding: theme.spacing(2, 4),
    minHeight: '17px',
  },
  middleRow: {
    padding: theme.spacing(2.5, 4),
    minHeight: '88px',
  },
  validatorPoolsSection: {
    backgroundColor: theme.palette.background.primary,
    borderRadius: '4px',
    padding: theme.spacing(3, 4),
  },
  mobileAccordionDiv: {
    margin: theme.spacing(1, 0),
    backgroundColor: theme.palette.background.primary,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: '2.5rem',
    maxHeight: '2.5rem',
  },
  sectionBody: {
    padding: theme.spacing(1.25, 1.5),
    backgroundColor: theme.palette.background.primary,
  },
}))

export default StakeFallbackMain