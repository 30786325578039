import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { ConnectedRouter } from 'connected-react-router'
import React, { useEffect, useLayoutEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useDispatch } from 'react-redux'

import { history } from 'js/state'
import { setInternetConnected, setPwaInstallPrompt, setWindowActive } from 'js/state/modules/app/actions'
import { PWABeforeInstallPromptEvent } from 'js/state/modules/app/types'
import { darkTheme } from 'js/theme'
import { overrideNodeMethods } from 'js/utils/overrideNodeMethods'

import AppProvider from './AppProvider'
import Web3Provider from './Web3Provider'
import ScrollToTop from './scrollToTop'

import GoogleAnalytics from '../Common/GoogleAnalytics'

const App: React.FC = () => {
  const dispatch = useDispatch()
  useLayoutEffect(() => {
    overrideNodeMethods()
  }, [])

  useEffect(() => {
    function onlineHandler() {
      dispatch(setInternetConnected(true))
    }

    function offlineHandler() {
      dispatch(setInternetConnected(false))
    }

    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', offlineHandler)

    return () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    }
  }, [dispatch])

  useEffect(() => {
    const handleEnableWindowActive = () => {
      dispatch(setWindowActive(true))
    }

    const handleDisableWindowActive = () => {
      dispatch(setWindowActive(false))
    }

    window.addEventListener('blur', handleDisableWindowActive)
    window.addEventListener('focus', handleEnableWindowActive)
    return () => {
      window.removeEventListener('blur', handleDisableWindowActive)
      window.removeEventListener('focus', handleEnableWindowActive)
    }
  }, [dispatch])

  useEffect(() => {
    const handler = (event: Event) => {
      event.preventDefault()
      dispatch(setPwaInstallPrompt(event as PWABeforeInstallPromptEvent))
    }

    window.addEventListener("beforeinstallprompt", handler)

    return () => window.removeEventListener("beforeinstallprompt", handler)
  }, [dispatch])

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <HelmetProvider>
        <GoogleAnalytics />
          <ConnectedRouter history={history}>
            <ScrollToTop />
            <Web3Provider>
              <AppProvider />
            </Web3Provider>
          </ConnectedRouter>
      </HelmetProvider>
    </ThemeProvider>
  )
}

export default App
