import BigNumber from 'bignumber.js'
import { OrderModule } from 'carbon-js-sdk'
import { createSelector } from 'reselect'

import { isMarket } from 'js/models/Order'
import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { getMarket } from 'js/state/modules/exchange/selectors'
import { getCurrentMarketOpenPosition } from 'js/state/modules/history/selectors'
import { getEffectiveLeverage } from 'js/state/modules/leverages/selectors'
import { getCurrentMarketMarkPrice } from 'js/state/modules/marketStats/selectors'
import { getQuoteBalances } from 'js/state/modules/walletBalance/selectors'
import { BN_ZERO, parseNumber } from 'js/utils/number'
import { shiftByQuoteDp } from 'js/utils/order'

import { OrderFormState } from './types'

import { RootState } from '../rootReducer'

const getCostQuantity = createSelector(
  [getSide, getCurrentMarketOpenPosition, getQuantity],
  (side, openPosition, quantity) => {
    let costQuantity = new BigNumber(quantity || 0)
    if (side === 'sell') {
      costQuantity = costQuantity.negated()
    }

    let lots = parseNumber(openPosition?.lots, BN_ZERO)!

    // make short position positive
    if (lots.isNegative()) {
      lots = lots.negated()
      costQuantity = costQuantity.negated()
    }

    if (costQuantity.isPositive()) {
      return costQuantity
    }

    // if reduce and flip position
    const newLots = lots.plus(costQuantity)
    if (newLots.isNegative()) {
      return newLots.negated()
    }
    return new BigNumber(0)
  },
)

export const getOrderPrice = createSelector(
  [getOrderType, getCurrentMarketMarkPrice, getPrice],
  (orderType, markPrice, price) => (isMarket(orderType) ? markPrice : price),
)

export const getCost = createSelector(
  [getCostQuantity, getOrderPrice, getEffectiveLeverage(), getIsUsdToggled],
  (quantity, price, leverage, isUsdToggled) => {
    if (isUsdToggled) {
      return quantity
    }
    const cost = quantity.times(price).div(leverage)
    return cost.isFinite() ? cost : new BigNumber(0)
  },
)

export function getOrderForm(state: RootState): OrderFormState {
  return state.orderManager.orderForm
}

export function getPrice(state: RootState): BigNumber {
  return state.orderManager.orderForm.price
}

export function getQuantity(state: RootState): any {
  return state.orderManager.orderForm.quantity
}

export function getTotal(state: RootState): any {
  return state.orderManager.orderForm.total
}

export function getSlippageTolerance(state: RootState): any {
  return state.orderManager.orderForm.slippageTolerance
}

export const getMaxQuantityFromPosition = createSelector(
  [getCurrentMarketOpenPosition, getSide, getMarket, getCarbonSDK],
  (openPosition, side, market, sdk) => {
    const lots = sdk?.token.toHuman(market?.base ?? '', parseNumber(openPosition?.lots, BN_ZERO)!) ?? BN_ZERO

    // reducing position
    if ((lots.isPositive() && side === OrderModule.OrderSide.Sell)
      || (lots.isNegative() && side === OrderModule.OrderSide.Buy)) {
      return lots.abs()
    }
    return new BigNumber(0)
  },
)

export const getMaxQuantityFromAvailableBalance = createSelector(
  [getIsReduceOnly, getQuoteBalances, getOrderPrice, getEffectiveLeverage(), getMarket, getCarbonSDK],
  (isReduceOnly, quoteBalances, orderPrice, leverage, market, sdk) => {
    // if reduce only then available balance contributes nothing to the max quantity,
    // and the fees are taken from reducing the position instead.
    if (isReduceOnly) {
      return new BigNumber(0)
    }
    const availableBalance = shiftByQuoteDp(market, quoteBalances?.available)
    const maxQuantity = availableBalance.times(leverage).div(orderPrice || 1)

    return maxQuantity
  },
)

export function getMaxBaseQuantity(state: RootState): BigNumber {
  const maxQuantityFromPosition = getMaxQuantityFromPosition(state)
  const maxQuantityFromAvailableBalance = getMaxQuantityFromAvailableBalance(state)
  let maxQuantity = maxQuantityFromPosition.plus(maxQuantityFromAvailableBalance)
  if (maxQuantity.isNaN()) {
    maxQuantity = new BigNumber(0)
  }
  return maxQuantity
}

export function getSide(state: RootState): OrderModule.OrderSide {
  return state.orderManager.orderForm.side
}

export function getOrderType(state: RootState): OrderModule.OrderType {
  return state.orderManager.orderForm.orderType
}

export function getShowOrderDetails(state: RootState): any {
  return state.orderManager.showOrderDetails
}

export function getTriggerType(state: RootState): OrderModule.TriggerType {
  return state.orderManager.orderForm.triggerType
}

export function getIsUsdToggled(state: RootState): boolean {
  return state.orderManager.orderForm.isUsdToggled
}

export function getIsReduceOnly(state: RootState): boolean {
  return state.orderManager.orderForm.isReduceOnly
}

export function getFeeQuantity(state: RootState): string {
  return state.orderManager.feeQuantity
}

export function getFeeToken(state: RootState): string | null {
  return state.orderManager.feeToken
}

export function getAlertOpen(state: RootState): boolean {
  return state.orderManager.alertOpen
}

export function getReservedSettingsOpen(state: RootState): boolean {
  return state.orderManager.reservedSettingsOpen
}

export function getOpenTpSlDialog(state: RootState): boolean {
  return state.orderManager.openTpSlDialog
}

export function getTakeProfitTriggerErrMsg(state: RootState): string {
  return state.orderManager.takeProfitTriggerErrMsg
}

export function getTakeProfitPriceErrMsg(state: RootState): string {
  return state.orderManager.takeProfitPriceErrMsg
}

export function getStopLossTriggerErrMsg(state: RootState): string {
  return state.orderManager.stopLossTriggerErrMsg
}

export function getStopLossPriceErrMsg(state: RootState): string {
  return state.orderManager.stopLossPriceErrMsg
}

export function getTakeProfitRate(state: RootState): number {
  return state.orderManager.orderForm.takeProfitRate
}

export function getStopLossRate(state: RootState): number {
  return state.orderManager.orderForm.stopLossRate
}

export function getTakeProfitTriggerPrice(state: RootState): BigNumber | null {
  return state.orderManager.orderForm.takeProfitTriggerPrice
}

export function getStopLossTriggerPrice(state: RootState): BigNumber | null {
  return state.orderManager.orderForm.stopLossTriggerPrice
}

export function getTakeProfitOrderType(state: RootState): OrderModule.OrderType {
  return state.orderManager.orderForm.takeProfitOrderType
}

export function getStopLossOrderType(state: RootState): OrderModule.OrderType {
  return state.orderManager.orderForm.stopLossOrderType
}

export function getStopLossPrice(state: RootState): BigNumber | null {
  return state.orderManager.orderForm.stopLossPrice
}

export function getTakeProfitPrice(state: RootState): BigNumber | null {
  return state.orderManager.orderForm.takeProfitPrice
}
