export const SelectedMode = {
  HIDE_SMALL_AMOUNT_NITRON: '@toggle/HIDE_SMALL_AMOUNT_NITRON',
  HIDE_SMALL_DEBT_AMOUNT: '@toggle/HIDE_SMALL_DEBT_AMOUNT',
  SHOW_ONLY_LOW_HEALTH: '@toggle/SHOW_ONLY_LOW_HEALTH',
  SHOW_UNLINKED_POOLS: '@toggle/SHOW_UNLINKED_POOLS',
  SHOW_ZERO_APR: '@toggle/SHOW_ZERO_APR',
  SHOW_COMMISSION_ONLY: '@toggle/SHOW_COMMISSION_ONLY',
  SHOW_KICKBACK_ONLY: '@toggle/SHOW_KICKBACK_ONLY',
  SHOW_SMALL_POOLS: '@toggle/SHOW_SMALL_POOLS',
  HIDE_OTHER_MARKETS: '@toggle/HIDE_OTHER_MARKETS',
}

export const savedToggleDefaults = {
  [SelectedMode.HIDE_SMALL_AMOUNT_NITRON]: true,
  [SelectedMode.HIDE_SMALL_DEBT_AMOUNT]: true,
  [SelectedMode.SHOW_ONLY_LOW_HEALTH]: false,
  [SelectedMode.SHOW_UNLINKED_POOLS]: false,
  [SelectedMode.SHOW_ZERO_APR]: false,
  [SelectedMode.SHOW_COMMISSION_ONLY]: false,
  [SelectedMode.SHOW_KICKBACK_ONLY]: false,
  [SelectedMode.SHOW_SMALL_POOLS]: false,
}

export const getLastSelected = (mode: string): boolean => {
  const defaultValue = savedToggleDefaults[mode]

  try {
    const rawLocalStorage = localStorage.getItem(mode)
    return rawLocalStorage ? JSON.parse(rawLocalStorage) : defaultValue
  } catch (error) {
    return defaultValue
  }
}

export const setLastSelected = (mode: string, value: boolean) => {
  localStorage.setItem(mode, JSON.stringify(value))
}