export interface ApiResponse<T> {
  result: T
}

export interface ApiError {
  type: string
  message: string
  code: number
}

export interface ApiErrorResponse<T = ApiError> {
  error: T
}

export enum HttpMethod {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}