import { Collapse, IconButton, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import SvgIcon from 'js/components/Common/SvgIcon'
import { useCommonStyles } from 'js/utils'

import { ReactComponent as AddSolid } from 'assets/AddSolid.svg'
import { ReactComponent as CautionSolid } from 'assets/CautionSolid.svg'
import { ReactComponent as CheckCircle } from 'assets/CheckCircle.svg'
import { ReactComponent as ContainedInfo } from 'assets/ContainedInfo.svg'
import { ReactComponent as CloseIcon } from 'assets/Cross.svg'

interface Props {
  allowCollapse?: boolean
  className?: string
  content: string | React.ReactNode
  contentClass?: string
  status?: 'error' | 'warning' | 'info' | 'success'
  title?: string | undefined
  titleClass?: string
  customOpen?: boolean | null
  handleCustomOpen?: () => void
}

const NotificationIcons = {
  error: AddSolid,
  success: CheckCircle,
  warning: CautionSolid,
  info: ContainedInfo,
}

const StandardAlert: React.FC<Props> = (props: Props) => {
  const {
    allowCollapse = false, className, content, contentClass, customOpen = null,
    handleCustomOpen, status = 'info', title = undefined, titleClass,
  } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const [open, setOpen] = React.useState(true)

  const Icon = React.useMemo(() => NotificationIcons[status], [status])

  const typeClasses = {
    info: status === 'info',
    warning: status === 'warning',
    error: status === 'error',
    success: status === 'success',
  }

  const closeAlert = () => {
    setOpen(false)
  }

  return (
    <Collapse in={customOpen !== null ? customOpen : open}>
      <div className={clsx(classes.root, className, classes.padding0p75em)}>
        <div className={commonClasses.flexRow}>
          <div className={clsx(classes.width1p5rem, commonClasses.justifyContentCenter)}>
            <SvgIcon className={clsx(classes.alertSvg, typeClasses)} component={Icon} />
          </div>
          <div className={clsx(classes.textContent, contentClass, { title: !title }, classes.marginLeft0p75em)}>
            <div>
              {title && (
                <div className={classes.titleBox}>
                  <Typography variant="body1" className={clsx(classes.textColor, classes.title, titleClass)}>
                    {title}
                  </Typography>
                </div>
              )}
              {typeof content === 'string' ? (
                <div>
                  <Typography variant="body2" className={clsx(classes.textColor, classes.subtitle)}>
                    {content}
                  </Typography>
                </div>
              ) : content}
            </div>
          </div>
        </div>
        {
          allowCollapse && (
            <IconButton
              className={classes.iconBtn}
              onClick={customOpen !== null && handleCustomOpen ? handleCustomOpen : closeAlert}
            >
              <SvgIcon className={classes.exitIcon} component={CloseIcon} />
            </IconButton>
          )
        }
      </div>
    </Collapse>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#F5F7FA',
    borderRadius: '0.125em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  alertSvg: {
    minWidth: '1.35rem',
    height: '1.35rem',
    '&.info': {
      '& circle': {
        fill: '#4276E6',
      },
      '& path': {
        fill: '#FFF',
      },
    },
    '&.warning': {
      minWidth: '1.55rem',
      height: '1.55rem',
      '& path:first-child': {
        fill: '#FFBA24',
      },
      '& path:last-child': {
        fill: '#FFF',
      },
    },
    '&.error': {
      height: '1.75rem',
      minWidth: '1.75rem',
      transform: 'rotate(45deg)',
      '& path:first-child': {
        fill: '#EE4B4C',
      },
      '& path:last-child': {
        fill: '#FFF',
      },
    },
    '&.success': {
      minWidth: '1.325rem',
      height: '1.325rem',
      '& path:first-child': {
        fill: '#02A566',
      },
      '& path:last-child': {
        fill: '#FFF',
      },
    },
  },
  exitIcon: {
    height: '0.75rem',
    width: '0.75rem',
  },
  iconBtn: {
    padding: '0.25em',
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: '0.75rem',
    letterSpacing: '0.01667em',
    lineHeight: 1.267,
  },
  textColor: {
    color: '#363B46',
  },
  textContent: {
    ...theme.typography.body2,
    '&.title': {
      alignItems: 'center',
      display: 'flex',
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '0.825rem',
    letterSpacing: '0.01667em',
    lineHeight: 1.167,
  },
  titleBox: {
    marginBottom: '0.15em',
  },
  padding0p75em: {
    padding: '0.75em',
  },
  width1p5rem: {
    width: '1.5rem',
  },
  marginLeft0p75em: {
    marginLeft: '0.75em',
  },
}))

export default StandardAlert
