import React, { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  renderIf: boolean
}

const RenderGuard: React.FC<Props> = (props: Props) => {
  const { children, renderIf } = props

  if (!renderIf) return null

  return (<React.Fragment>{children}</React.Fragment>)
}

export default RenderGuard
