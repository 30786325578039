/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-rest-params */
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { getConnectedWalletAddress, getUserProfile } from 'js/state/modules/app/selectors'
import { getStatsigClient } from 'js/state/modules/statsig/selectors'
import { EventAction, EvtCategory, sendGaEvent } from 'js/utils/events'
import { SimpleMap } from 'js/utils/types'

export type { EventAction } from 'js/utils/events'
export { EvtCategory } from 'js/utils/events'

const useEventTrackers = () => {
  const connectedAddress = useSelector(getConnectedWalletAddress)
  const statsigClient = useSelector(getStatsigClient)
  const userProfile = useSelector(getUserProfile)
  const sendEvent = useCallback((eventCategory: EvtCategory, eventAction: EventAction, args?: SimpleMap<string>, eventLabel?: string, eventValue?: number) => {
    let isNewAddress: string | undefined = undefined
    if (connectedAddress && connectedAddress === userProfile?.address) {
      isNewAddress = (userProfile.new ?? false).toString()
    }

    // logging event in google analytics
    sendGaEvent(eventCategory, eventAction, eventLabel, eventValue, {
      ...connectedAddress && {
        address: connectedAddress,
      },
      ...isNewAddress !== undefined && {
        isNewAddress,
      },
      ...args,
    })

    // logging event in statsig if statsig initialized
    statsigClient?.logEvent(eventAction, eventValue, {
      category: eventCategory,
      label: eventLabel ?? '',
      ...args
    })
  }, [userProfile, statsigClient, connectedAddress])


  return {
    sendEvent,
  }
}
export default useEventTrackers
