import { RecordOf } from 'immutable'

import { SimpleMap } from 'js/utils'
import { Id, Inbox, Preference, Status, TelegramDialog, UserProfile, VerificationStatusResponse } from 'js/utils/alerts'


export interface AlertsStateProps {
  profile: UserProfile | null
  preferences: Preference[] | null
  optimisticPreferences: Preference[] | null
  verification: VerificationStatusResponse | null
  inbox: Map<Id, Inbox> | null
  optimisticInbox: Map<Id, Inbox> | null
  pendingInboxUpdates: SimpleMap<UpdateInboxStatusPayload[]> | null
  pendingPreferencesUpdates: SimpleMap<Preference[]> | null
  displayStatus: SimpleMap<boolean> | null
  telegramDialog: TelegramDialog | null
}

export interface UpdateInboxStatusPayload {
  ids: number[]
  status: Status
}

export type AlertsState = RecordOf<AlertsStateProps>

export const AlertsActionTypes = {
  SHOW_EMAIL: '@alerts/SHOW_EMAIL',
  SHOW_TELEGRAM: '@alerts/SHOW_TELEGRAM',
  SET_USER_PROFILE: '@alerts/SET_USER_PROFILE',
  SET_USER_PREFERENCES: '@alerts/SET_USER_PREFERENCES',
  SET_OPTIMISTIC_USER_PREFERENCES: '@alerts/SET_OPTIMISTIC_USER_PREFERENCES',
  UPDATE_USER_PREFERENCES: '@alerts/UPDATE_USER_PREFERENCES',
  TRIGGER_VERIFICATION: '@alerts/TRIGGER_VERIFICATION',
  UPDATE_EMAIL: '@alerts/UPDATE_EMAIL',
  UPDATE_TELEGRAM: '@alerts/UPDATE_TELEGRAM',
  SET_VERIFICATION: '@alerts/SET_VERIFICATION',
  UPDATE_VERIFICATION_STATUS: '@alerts/UPDATE_VERIFICATION_STATUS',
  PROCESSOR_JWTS: '@alerts/PROCESSOR_JWTS',
  UPDATE_INBOX: '@alerts/UPDATE_INBOX',
  UPDATE_OPTIMISTIC_INBOX: '@alerts/UPDATE_OPTIMISTIC_INBOX',
  RESET_INBOX: '@alerts/RESET_INBOX',
  RESET_OPTIMISTIC_INBOX: '@alerts/RESET_OPTIMISTIC_INBOX',
  UPDATE_INBOX_STATUS: '@alerts/UPDATE_INBOX_STATUS',
  REVERT_PREFERENCES_OPTIMISTIC_UPDATES: '@alerts/REVERT_PREFERENCES_OPTIMISTIC_UPDATES',
  REVERT_INBOX_OPTIMISTIC_UPDATES: '@alerts/REVERT_INBOX_OPTIMISTIC_UPDATES',
  ADD_PENDING_INBOX_STATUS_UPDATES: '@alert/ADD_PENDING_INBOX_STATUS_UPDATES',
  REMOVE_PENDING_INBOX_STATUS_UPDATES: '@alert/REMOVE_PENDING_INBOX_STATUS_UPDATES',
  ADD_PENDING_PREFERENCES_UPDATES: '@alert/ADD_PENDING_PREFERENCES_UPDATES',
  REMOVE_PENDING_PREFERENCES_UPDATES: '@alert/REMOVE_PENDING_PREFERENCES_UPDATES',
  UPDATE_DISPLAY_STATUS: '@alerts/UPDATE_DISPLAY_STATUS',
  TOGGLE_TELEGRAM_DIALOG: '@alerts/TOGGLE_TELEGRAM_DIALOG',
  RESET_ALERTS_DATA: '@alerts/RESET_ALERTS_DATA',
  POLL_EMAIL_VERIFIED: '@alerts/POLL_EMAIL_VERIFIED',
  POLL_TELEGRAM_VERIFIED: '@alerts/POLL_TELEGRAM_VERIFIED',
}
