import React from "react"
import { useLocation } from "react-router"

import useIsLiteMode from "js/hooks/useIsLiteMode"

import LiteModeFallback from "./LiteModeFallback"
import TradeMainFallback from "./TradeMainFallback"

import PageLoadFallback from "../../PageLoadFallback"

const HomeFallback: React.FC = () => {
  const location = useLocation()
  const { isLiteMode } = useIsLiteMode()

  // lite mode fallback
  if (location.pathname.includes('lite')) {
    return <LiteModeFallback />
  }
  if (location.pathname.includes('trade')) {
    if (location.pathname.includes('recent_trades')) {
      return <PageLoadFallback />
    }
    return <TradeMainFallback />
  }

  if (typeof isLiteMode === 'boolean') {
    return isLiteMode ? <LiteModeFallback /> : <TradeMainFallback />
  }
  
  return <PageLoadFallback />
}

export default HomeFallback