import BigNumber from 'bignumber.js'
import { OrderModule } from 'carbon-js-sdk'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import { List } from 'immutable'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAdjustedRecentTradesByMarket } from 'js/hooks'
import { getMarkPriceByTotal as getAverageExecutionPriceByTotal, getMarkPrice } from 'js/hooks/useMarkPrice'
import { isSpotMarket } from 'js/models/Market'
import { isBuy, isLimit, isMarket } from 'js/models/Order'
import { getIsSimpleMode, getMarket } from 'js/state/modules/exchange/selectors'
import { Market } from 'js/state/modules/exchange/types'
import { getEffectiveLeverage } from 'js/state/modules/leverages/selectors'
import { getCurrentMarketPrices } from 'js/state/modules/marketStats/selectors'
import { getOpenBuys, getOpenSells } from 'js/state/modules/orderBook/selectors'
import { OrderBookEntry } from 'js/state/modules/orderBook/types'
import { setOrderFormInputs } from 'js/state/modules/orderManager/actions'
import { getOrderForm } from 'js/state/modules/orderManager/selectors'
import { OrderFormState, OrderManagerActionTypes } from 'js/state/modules/orderManager/types'
import { getAdjustedBalances } from 'js/state/modules/walletBalance/selectors'
import { logger } from 'js/utils'
import { safeParseStoredValue } from 'js/utils/localstorage'
import { BN_ONE, BN_ZERO, parseNumber } from 'js/utils/number'
import { calculateSliderValue, getAdjustedTickLotSize, sortByPriceEntry } from 'js/utils/order'


export type OrderFormAmountInputKey = 'price' | 'quantity' | 'total' | 'stopPrice' | 'sliderValue' | 'takeProfitTriggerPrice' | 'takeProfitPrice' | 'stopLossTriggerPrice' | 'stopLossPrice' | 'takeProfitRate' | 'stopLossRate' | 'orderSide'
export type OrderFormBooleanKey = 'usdToggled' | 'reduceOnly' | 'postOnly' | 'disabled' | 'takeProfitStopLoss'

export const adjustToSize = (amount: BigNumber, lotSize: BigNumber) => {
  if (amount === BN_ZERO) return BN_ZERO
  const lotSizeRemainder = amount.modulo(lotSize)
  if (lotSizeRemainder.gt(0)) {
    return amount.minus(lotSizeRemainder)
  }
  return amount
}

const useOrderManager = () => {
  const orderForm = useSelector(getOrderForm)
  const market = useSelector(getMarket)
  const openBuys = useSelector(getOpenBuys)
  const openSells = useSelector(getOpenSells)
  const marketPrices = useSelector(getCurrentMarketPrices)
  const leverage = useSelector(getEffectiveLeverage())
  const isSimpleMode = useSelector(getIsSimpleMode)
  const adjustedBalances = useSelector(getAdjustedBalances)
  const dispatch = useDispatch()
  const recentTradesByMarket = useAdjustedRecentTradesByMarket()
  const baseBalance = adjustedBalances[market?.base ?? '']?.available ?? BN_ZERO
  const quoteBalance = adjustedBalances[market?.quote ?? '']?.available ?? BN_ZERO

  const calculateTotal = useCallback((form: OrderFormState, adjustedTickSize: BigNumber, orders: List<OrderBookEntry>) => {
    if (isLimit(form.orderType)) {
      form.total = adjustToSize(form.quantity.times(form.price), adjustedTickSize)
      form.inputTotal = form.total.toString(10)
    } else {
      const markPrice = getMarkPrice(orders, form.quantity, market)
      form.total = adjustToSize(form.quantity.times(markPrice), adjustedTickSize)
      form.inputTotal = form.total.toString(10)
    }
  }, [market])

  const getCurrentTriggerPrice = useCallback((triggerType: OrderModule.TriggerType) => {
    if (triggerType === OrderModule.TriggerType.LastPrice) {
      return marketPrices.last
    }
    if (triggerType === OrderModule.TriggerType.IndexPrice) {
      return marketPrices.index
    }
    return marketPrices.mark
  }, [marketPrices])

  const calculateTriggerPrice = useCallback((form: OrderFormState, tpSlSide: 'takeProfit' | 'stopLoss', adjustedTickSize: BigNumber) => {
    const rate = tpSlSide === 'takeProfit' ? form.takeProfitRate : form.stopLossRate
    const leverageFactor = leverage === 0 ? 1 : leverage // to prevent math error
    const factor = (rate / (100 * leverageFactor))
    const bigFactor = 1 + factor
    const smallFactor = 1 - factor
    let multiplier = isBuy(form.side) ? bigFactor : smallFactor
    if (tpSlSide === 'stopLoss') multiplier = isBuy(form.side) ? smallFactor : bigFactor
    const priceToUse = isLimit(form.orderType) ? form.price : getCurrentTriggerPrice(form.stopLossTriggerType)
    const newTriggerPrice = priceToUse.multipliedBy(multiplier)
    const adjustedNewTriggerPrice = adjustToSize(newTriggerPrice, adjustedTickSize)
    return adjustedNewTriggerPrice
  }, [leverage, getCurrentTriggerPrice])

  const updateSlider = useCallback((form: OrderFormState) => {
    if (isBuy(orderForm.side)) {
      if (isMarket(form.orderType)) {
        const {
          tickSize: adjustedTickSize,
        } = getAdjustedTickLotSize(market)
        if (!isSpotMarket(market) && isSimpleMode) {
          form.price = adjustToSize(getMarkPrice(openSells, form.quantity, market), adjustedTickSize)
        }
      }
      const newTotal = form.price.times(form.quantity)
      const newSliderValue = calculateSliderValue(newTotal, quoteBalance)
      form.sliderValue = newSliderValue.shiftedBy(-2)
    } else {
      const newQuantity = form.quantity
      const {
        lotSize: adjustedLotSize,
      } = getAdjustedTickLotSize(market)
      const newSliderValue = calculateSliderValue(newQuantity, adjustToSize(baseBalance, adjustedLotSize))
      form.sliderValue = newSliderValue.shiftedBy(-2)
    }
  }, [market, openSells, baseBalance, isSimpleMode, orderForm.side, quoteBalance])

  // private function to recalibrate form state
  // integrity. market lot size and min quantity
  // are not handled here. they should be checked
  // before submission of form.
  const computeForm = useCallback((form: OrderFormState, fieldEdited?: OrderFormAmountInputKey): OrderFormState => {
    const requiredPrecision = market?.basePrecision.toNumber() ?? 0
    const roundingMode = form.side === OrderModule.OrderSide.Buy ? BigNumber.ROUND_DOWN : BigNumber.ROUND_UP
    const {
      tickSize: adjustedTickSize,
      lotSize: adjustedLotSize,
    } = getAdjustedTickLotSize(market)
    const orders = isBuy(form.side) ? sortByPriceEntry(openSells, true) : sortByPriceEntry(openBuys, false)

    logger('useOrderManager.computeForm')

    if (isMarket(form.orderType)) {
      const spotMarkPrice = getMarkPrice(orders, BN_ONE, market)
      const spotPrice = bnOrZero(recentTradesByMarket?.[0]?.price, spotMarkPrice)
      form.price = isSpotMarket(market) ? spotPrice : marketPrices.mark
    }

    switch (fieldEdited) {
      case 'total': {
        let price = form.price

        if (!form.price.gt(0)) {
          if (!isMarket(form.orderType)) {
            updateSlider(form)
            return form
          }
        }
        if (isSpotMarket(market) && isMarket(form.orderType)) {
          price = getAverageExecutionPriceByTotal(orders, form.total, market)
        }

        const desiredQuantity = bnOrZero(form.total.div(price).decimalPlaces(requiredPrecision, roundingMode))
        form.unadjustedQuantity = desiredQuantity
        form.quantity = adjustToSize(desiredQuantity, adjustedLotSize)
        form.inputQuantity = form.quantity.toString(10)
        updateSlider(form)
        return form
      }
      case 'price': {
        form.price = adjustToSize(form.price, adjustedTickSize)
        updateSlider(form)
        calculateTotal(form, adjustedTickSize, orders)
        if (form.isTakeProfitStopLoss && form.takeProfitRate !== 0) {
          const adjustedNewTriggerPrice = calculateTriggerPrice(form, 'takeProfit', adjustedTickSize)
          form.takeProfitTriggerPrice = adjustedNewTriggerPrice
          form.inputTakeProfitTriggerPrice = adjustedNewTriggerPrice.toString(10)
        }
        if (form.isTakeProfitStopLoss && form.stopLossRate !== 0) {
          const adjustedNewTriggerPrice = calculateTriggerPrice(form, 'stopLoss', adjustedTickSize)
          form.stopLossTriggerPrice = adjustedNewTriggerPrice
          form.inputStopLossTriggerPrice = adjustedNewTriggerPrice.toString(10)
        }
        return form
      }
      case 'quantity': {
        if (form.quantity.toString(10).split('.')?.[1]?.length > requiredPrecision) {
          form.quantity = new BigNumber(form.quantity.decimalPlaces(requiredPrecision, roundingMode))
          updateSlider(form)
        }
        form.unadjustedQuantity = form.quantity
        form.quantity = adjustToSize(form.quantity, adjustedLotSize)
        calculateTotal(form, adjustedTickSize, orders)
        updateSlider(form)
        return form
      }
      case 'stopPrice': {
        form.stopPrice = adjustToSize(form.stopPrice, adjustedTickSize)
        updateSlider(form)
        return form
      }
      case 'takeProfitTriggerPrice': {
        form.takeProfitTriggerPrice = adjustToSize(form.takeProfitTriggerPrice ?? BN_ZERO, adjustedTickSize)
        return form
      }
      case 'takeProfitPrice': {
        form.takeProfitPrice = adjustToSize(form.takeProfitPrice ?? BN_ZERO, adjustedTickSize)
        return form
      }
      case 'stopLossTriggerPrice': {
        form.stopLossTriggerPrice = adjustToSize(form.stopLossTriggerPrice ?? BN_ZERO, adjustedTickSize)
        return form
      }
      case 'stopLossPrice': {
        form.stopLossPrice = adjustToSize(form.stopLossPrice ?? BN_ZERO, adjustedTickSize)
        return form
      }
      case 'sliderValue': {
        updateSlider(form)
        return form
      }
      case 'takeProfitRate': {
        const adjustedNewTriggerPrice = calculateTriggerPrice(form, 'takeProfit', adjustedTickSize)
        form.takeProfitTriggerPrice = adjustedNewTriggerPrice
        form.inputTakeProfitTriggerPrice = adjustedNewTriggerPrice.toString(10)
        return form
      }
      case 'stopLossRate': {
        const adjustedNewTriggerPrice = calculateTriggerPrice(form, 'stopLoss', adjustedTickSize)
        form.stopLossTriggerPrice = adjustedNewTriggerPrice
        form.inputStopLossTriggerPrice = adjustedNewTriggerPrice.toString(10)
        return form
      }
      case 'orderSide': {
        if (!form.isTakeProfitStopLoss) return form
        if (form.takeProfitRate !== 0) {
          const adjustedNewTpTriggerPrice = calculateTriggerPrice(form, 'takeProfit', adjustedTickSize)
          form.takeProfitTriggerPrice = adjustedNewTpTriggerPrice
          form.inputTakeProfitTriggerPrice = adjustedNewTpTriggerPrice.toString(10)
        }
        if (form.stopLossRate !== 0) {
          const adjustedNewSlTriggerPrice = calculateTriggerPrice(form, 'stopLoss', adjustedTickSize)
          form.stopLossTriggerPrice = adjustedNewSlTriggerPrice
          form.inputStopLossTriggerPrice = adjustedNewSlTriggerPrice.toString(10)
        }
        return form
      }
      default: {
        return form
      }
    }
  }, [calculateTotal, calculateTriggerPrice, marketPrices.mark, market, openBuys, openSells, recentTradesByMarket, updateSlider])

  // private function to dispatch form update
  const updateForm = useCallback((form: Partial<OrderFormState>) => {
    dispatch(setOrderFormInputs({
      ...orderForm,
      ...form,
    }))
  }, [dispatch, orderForm])

  /**
   * Generic updating of order form state
   *
   */
  const update = useCallback((form: Partial<OrderFormState>, editType?: OrderFormAmountInputKey) => {
    const newState = computeForm({
      ...orderForm,
      ...form,
    }, editType)
    updateForm(newState)
  }, [orderForm, updateForm, computeForm])

  /**
   * For toggling form state.
   */
  const toggle = useCallback((key: OrderFormBooleanKey, override?: boolean) => {
    updateForm({
      ...orderForm,
      ...key === 'disabled' && {
        isDisabled: override ?? !orderForm.isDisabled,
      },
      ...key === 'postOnly' && {
        isPostOnly: override ?? !orderForm.isPostOnly,
      },
      ...key === 'reduceOnly' && {
        isReduceOnly: override ?? !orderForm.isReduceOnly,
      },
      ...key === 'usdToggled' && {
        isUsdToggled: override ?? !orderForm.isUsdToggled,
      },
    })
  }, [orderForm, updateForm])

  /**
   * For receiving a user entered numeric amount
   * that cannot be trusted and should be parsed.
   *
   */
  const input = useCallback((key: OrderFormAmountInputKey, value: string) => {
    const newState = computeForm({
      ...orderForm,
      ...key === 'price' && {
        inputPrice: value,
        price: parseNumber(value, BN_ZERO)!,
      },
      ...key === 'quantity' && {
        inputQuantity: value,
        quantity: parseNumber(value, BN_ZERO)!,
      },
      ...key === 'total' && {
        inputTotal: value,
        total: parseNumber(value, BN_ZERO)!,
      },
      ...key === 'stopPrice' && {
        inputStopPrice: value,
        stopPrice: parseNumber(value, BN_ZERO)!,
      },
      ...key === 'takeProfitTriggerPrice' && {
        inputTakeProfitTriggerPrice: value,
        takeProfitTriggerPrice: parseNumber(value),
      },
      ...key === 'takeProfitPrice' && {
        inputTakeProfitPrice: value,
        takeProfitPrice: parseNumber(value),
      },
      ...key === 'stopLossTriggerPrice' && {
        inputStopLossTriggerPrice: value,
        stopLossTriggerPrice: parseNumber(value),
      },
      ...key === 'stopLossPrice' && {
        inputStopLossPrice: value,
        stopLossPrice: parseNumber(value),
      },
      ...key === 'sliderValue' && {
        sliderValue: parseNumber(value, BN_ZERO)!,
      },
      ...key === 'takeProfitRate' && {
        takeProfitRate: parseInt(value, 10) ?? 0,
      },
      ...key === 'stopLossRate' && {
        stopLossRate: parseInt(value, 10) ?? 0,
      },
    }, key)
    updateForm(newState)

    return newState
  }, [updateForm, orderForm, computeForm])

  /**
   * For updating input states back to
   * valid/accepted data representations.
   * Typically called by onEndEditing or onBlur
   */
  const sync = useCallback((form: OrderFormState = orderForm) => {
    const {
      tickSize,
    } = getAdjustedTickLotSize(market)
    updateForm({
      ...form,
      inputPrice: form.price.toString(10),
      inputQuantity: form.quantity.toString(10),
      inputTotal: adjustToSize(form.total, tickSize).toString(10),
      inputStopPrice: form.stopPrice.toString(10),
    })
  }, [updateForm, market, orderForm])

  const inputAndSync = useCallback((key: OrderFormAmountInputKey, value: string) => {
    const form = input(key, value)
    sync(form)
  }, [input, sync])

  const resetOrderForm = useCallback((market?: Market | null) => {
    updateForm({
      price: BN_ZERO,
      inputPrice: '',
      quantity: BN_ZERO,
      inputQuantity: '',
      total: BN_ZERO,
      inputTotal: '',
      stopPrice: BN_ZERO,
      inputStopPrice: '',
      editingTPSL: false,
      ...(market && isSpotMarket(market)) && ({
        triggerType: OrderModule.TriggerType.LastPrice,
      }),
    })
  }, [updateForm])

  const getLastOrderType = useCallback((isFuturesMarket: boolean) => {
    const savedOrderType = safeParseStoredValue(localStorage.getItem(OrderManagerActionTypes.SAVE_ORDER_TYPE), {})
    const lastOrderType = isFuturesMarket ? savedOrderType?.future : savedOrderType?.spot
    return lastOrderType as OrderModule.OrderType ?? OrderModule.OrderType.Limit
  }, [])

  return {
    orderForm,
    update,
    toggle,
    input,
    inputAndSync,
    sync,
    resetOrderForm,
    getLastOrderType,
  }
}

export default useOrderManager
