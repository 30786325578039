import { makeStyles } from "@material-ui/core"
import { CarbonSDK, CarbonTx } from "carbon-js-sdk"
import { ethers } from "ethers"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { useAdjustedBalance, useAsyncTask } from "js/hooks"
import useBroadcastTx from "js/hooks/useBroadcastTx"
import { setNet } from "js/state/modules/app/actions"
import { getCarbonSDK } from "js/state/modules/app/selectors"
import { getTokensFromFaucet, mintTokensFromCarbon } from "js/utils"
import { customToast } from "js/utils/notifications"

import { Button } from "../Common"


const TestEvmTransaction: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const sdk = useSelector(getCarbonSDK)
  const swthBalance = useAdjustedBalance('swth', 'available')
  const [mintTokenTx] = useBroadcastTx('mintToken', CarbonTx.Types.MsgMintToken)
  const [runMintToken, loadingMintToken] = useAsyncTask('runMintToken')
  const [runTestEvm, loadingRunTestEvm] = useAsyncTask('runTestEvm')

  const handleSetNet = (network: CarbonSDK.Network) => {
    dispatch(setNet(network))
  }

  const handleMintToken = () => {
    runMintToken(async () => {
      try {
        if (!sdk) throw new Error('sdk not initialized')

        if (!sdk.wallet) throw new Error('wallet not connected')

        // for users to test without balance
        if (sdk.network === CarbonSDK.Network.MainNet) throw new Error('please switch to testnet or devnet')
        // mint tokens for gas
        const gasResult = await getTokensFromFaucet(sdk)
        if (gasResult) {
          await mintTokensFromCarbon('swth', sdk, 'swth', mintTokenTx)
        }
      } catch (err) {
        const error = err as Error
        console.error(error.message)
        customToast.error({ title: 'Error', message: error.message })
      }
    })
  }

  const handleTestEvm = () => {
    runTestEvm(async () => {
      try {
        if (!sdk || !sdk.wallet) throw new Error('sdk not initialized')

        // for users to test without balance
        if (sdk.network === CarbonSDK.Network.MainNet) throw new Error('please switch to testnet or devnet')

        if (swthBalance.eq(0)) throw new Error(`Insufficient balance, mint some ${sdk.network} swth first`)

        const { evmHexAddress } = sdk.wallet
        const request: ethers.providers.TransactionRequest = {
          to: evmHexAddress,
          from: evmHexAddress,
          value: '0x100',
        }
        const result = await sdk.wallet.signer.sendEvmTransaction(
          sdk,
          request
        )
        if (result) {
          customToast.success({ title: 'Success', message: 'Evm transaction successful' })
        }
      } catch (err) {
        const error = err as Error
        console.error(error.message)
        customToast.error({ title: 'Error', message: error.message })
      }
    })
  }

  return (
    <div className={classes.root}>
      {sdk?.network === CarbonSDK.Network.MainNet ? (
        <div className={classes.wrapper}>
          <div>Please switch to Testnet or Devnet</div>
          <Button onClick={() => handleSetNet(CarbonSDK.Network.TestNet)}>
            Switch to Testnet
          </Button>
          <Button onClick={() => handleSetNet(CarbonSDK.Network.DevNet)}>
            Switch to Devnet
          </Button>
        </div>
      ) : (
        <div className={classes.wrapper}>
          Available SWTH Balance: {swthBalance.toFormat()}
          <Button
            onClick={handleMintToken}
            boxClass={classes.box}
            labelClass={classes.text}
            loading={loadingMintToken}
          >
            Mint {sdk?.network} SWTH
          </Button>
          <Button
            onClick={handleTestEvm}
            boxClass={classes.box}
            labelClass={classes.text}
            loading={loadingRunTestEvm}
            disabled={swthBalance.eq(0)}
          >
            Test EVM TX
          </Button>
        </div>
      )}

    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body4,
      fontWeight: 700,
    },
  },
}))

export default TestEvmTransaction