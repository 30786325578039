import BigNumber from 'bignumber.js'
import { CDPModule, Models, TypeUtils, WSModels } from 'carbon-js-sdk'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import dayjs from 'dayjs'

import { disableCap, noCap } from 'js/constants/cdp'
import { BN_INFINITY, BN_ONE, BN_ZERO } from 'js/utils/number'
import { getCibtDenom } from 'js/utils/strings'

export interface InitCDPStatePayload {
  address: string
  borrows: TypeUtils.SimpleMap<WSModels.Debt>
  mint?: WSModels.CDPAccountStablecoin
}

export interface CDPDebtInfo extends Omit<Models.Carbon.Cdp.DebtInfo, 'cumulativeInterestMultiplier' | 'utilizationRate'> {
  utilizationRate: BigNumber
  cumulativeInterestMultiplier: BigNumber
}

export interface CDPSupplyInfo extends Models.Carbon.Cdp.AssetParams {
  cibtDenom: string
}

export interface CDPRateStrategy extends Models.Carbon.Cdp.RateStrategyParams {
}

export interface CDPAssetArgs {
  isStablecoin: boolean,
  rateStrategy?: CDPRateStrategy,
  assetInfo?: CDPSupplyInfo,
  debtInfo?: CDPDebtInfo,
  mintInfo?: CDPDebtInfo,
}

export enum DebtType {
  Borrow = 'borrow',
  Mint = 'mint'
}
export class CDPAsset {
  isStablecoin: boolean

  rateStrategy?: CDPRateStrategy

  assetInfo?: CDPSupplyInfo

  readonly cibtDenom: string

  private debtInfo?: CDPDebtInfo

  private mintInfo?: CDPDebtInfo

  private borrowAPY: BigNumber = BN_ZERO

  private lendAPY: BigNumber = BN_ZERO

  private mintAPY: BigNumber = BN_ZERO

  constructor(
    readonly denom: string,
    readonly params: Params,
    readonly args: CDPAssetArgs,
  ) {
    this.cibtDenom = getCibtDenom(this.denom)
    this.isStablecoin = args.isStablecoin
    this.rateStrategy = args.rateStrategy
    this.assetInfo = args.assetInfo
    this.debtInfo = args.debtInfo
    this.mintInfo = args.mintInfo

    this.recalculateAPY()
  }

  public updateDebtInfo(debtInfo: CDPDebtInfo) {
    this.debtInfo = debtInfo
    this.recalculateAPY()
  }

  public updateMintInfo(mintInfo: CDPDebtInfo) {
    this.mintInfo = mintInfo
    this.recalculateAPY()
  }

  /**
   * Provides the CDP to underlying ratio which is used to obtain equivalent collateral tokens
   * when multiplied with underlying token amount.
   * Can be used to obtain equivalent underlying tokens by dividing collateral tokens amount
   * by this ratio.
   * @param cdpTotalSupply total supply of the collateral token
   * @param cdpModuleUnderlyingBalance underlying token balance in the CDP module address
   * @returns see description
   */
  public getCdpRatio(cdpTotalSupply: BigNumber, cdpModuleUnderlyingBalance: BigNumber) {
    const totalDebt = this.getTotalDebt()
    const totalUnderlying = BigNumber.sum(cdpModuleUnderlyingBalance, totalDebt)
    const cdpRatio = totalUnderlying.isZero() ? BN_ONE : bnOrZero(cdpTotalSupply).div(totalUnderlying)
    return cdpRatio
  }

  public getTotalDebt(offsetSeconds: number = 0) {
    if (!this.debtInfo) return BN_ZERO
    const totalUnderlyingPrincipal = bnOrZero(this.debtInfo.totalPrincipal)
    const interestRate = this.calculateInterestForTimePeriod(offsetSeconds)
    const accumInterest = bnOrZero(this.debtInfo.totalAccumulatedInterest)

    const newInterest = totalUnderlyingPrincipal.times(interestRate).plus(accumInterest.times(BN_ONE.plus(interestRate)))
    const interest = newInterest.times(BN_ONE.minus(this.params.interestFee)).dp(0)
    return totalUnderlyingPrincipal.plus(interest)
  }

  public getAvailableToLend(underlyingBalance: BigNumber): BigNumber {
    const supplyCap = bnOrZero(this.assetInfo?.supplyCap)
    const availableToLend = BigNumber.max(supplyCap.minus(underlyingBalance), BN_ZERO)
    return availableToLend
  }

  public calculateCIM(offsetSeconds: number = 0, debtType?: DebtType): BigNumber {
    const debtInfo = debtType === DebtType.Mint ? this.mintInfo : this.debtInfo

    if (!debtInfo) return BN_ONE
    const interest = this.calculateInterestForTimePeriod(offsetSeconds, debtType)
    const currentCIM = debtInfo.cumulativeInterestMultiplier.times(interest.plus(1))
    return currentCIM
  }

  public calculateInterestForTimePeriod(offsetSeconds: number = 0, debtType?: DebtType) {
    const debtInfo = debtType === DebtType.Mint ? this.mintInfo : this.debtInfo

    if (!debtInfo) return BN_ONE
    const interestAPY = debtType === DebtType.Mint ? this.mintAPY : this.borrowAPY

    const now = dayjs().add(offsetSeconds, 'seconds').toDate()
    const lastDate = debtInfo.lastUpdatedTime ?? now
    const interest = CDPModule.calculateInterestForTimePeriod(interestAPY, lastDate, now)
    return interest
  }

  public getDebtInfo() {
    return this.debtInfo
  }

  public getMintInfo() {
    return this.mintInfo
  }

  public getBorrowAPY() {
    return this.borrowAPY
  }

  public getLendAPY() {
    return this.lendAPY
  }

  public getMintAPY() {
    return this.mintAPY
  }

  private recalculateAPY() {
    this.borrowAPY = this.calculateBorrowAPY()
    this.lendAPY = this.calculateLendAPY()
    this.mintAPY = this.calculateMintAPY()
  }

  private calculateMintAPY() {
    if (this.isStablecoin && this.mintInfo) {
      return this.params.stablecoinInterestRate
    }
    return BN_ZERO
  }

  private calculateBorrowAPY() {
    if (!this.rateStrategy || !this.debtInfo) return BN_ZERO

    const interestAPY = CDPModule.calculateInterestAPY({
      ...this.debtInfo,
      utilizationRate: this.debtInfo.utilizationRate.shiftedBy(18).toString(10),
      cumulativeInterestMultiplier: this.debtInfo.cumulativeInterestMultiplier.toString(10),
    }, this.rateStrategy)

    return interestAPY
  }

  private calculateLendAPY(): BigNumber {
    if (!this.debtInfo) return BN_ZERO
    const utilizationRate = bnOrZero(this.debtInfo.utilizationRate)
    const interestFeeRate = bnOrZero(this.params.interestFee)

    const interestAPY = this.borrowAPY
    const lendAPY = interestAPY.times(utilizationRate).times(BN_ONE.minus(interestFeeRate))
    return lendAPY
  }

  public getMintCap(): BigNumber {
    const cap = bnOrZero(this.params.stablecoinMintCap)
    return CDPAsset.getCap(cap)
  }

  public getBorrowCap(): BigNumber {
    const cap = bnOrZero(this.assetInfo?.borrowCap)
    return CDPAsset.getCap(cap)
  }

  public getLendCap(): BigNumber {
    const cap = bnOrZero(this.assetInfo?.supplyCap)
    return CDPAsset.getCap(cap)
  }

  public isBorrowable(): boolean {
    const cap = bnOrZero(this.assetInfo?.borrowCap)
    return CDPAsset.isPermitted(cap)
  }

  public isLendable(): boolean {
    const cap = bnOrZero(this.assetInfo?.supplyCap)
    return CDPAsset.isPermitted(cap)
  }

  public isMintable(): boolean {
    const cap = bnOrZero(this.params.stablecoinMintCap)
    return CDPAsset.isPermitted(cap)
  }

  private static getCap(rawCap: BigNumber): BigNumber {
    return rawCap.eq(noCap) ? BN_INFINITY : rawCap
  }

  private static isPermitted(cap: BigNumber): boolean {
    return cap.gte(noCap) && !cap.eq(disableCap)
  }
}

export interface CDPAccountView {
  debts: TypeUtils.SimpleMap<CDPAccountDebt>
  supplies: TypeUtils.SimpleMap<CDPAccountSupply>
  collaterals: TypeUtils.SimpleMap<CDPAccountCollateral>

  debtTotalValue: BigNumber
  supplyTotalValue: BigNumber
  collateralTotalValue: BigNumber

  liquidationThreshold: BigNumber
  maxBorrowableValue: BigNumber

  healthFactor: BigNumber
  closeFactor: BigNumber

  isAccuratePrice: boolean
}

export enum CDPPriceType {
  twap = 'twap',
  external = 'external',
}

export interface ICDPAccountDebt {
  denom: string
  principal: BigNumber
  initialCIM: BigNumber
  price: BigNumber
  priceType: CDPPriceType
  type: DebtType
}

export class CDPAccountDebt implements ICDPAccountDebt {
  /** denom of underlying token */
  denom: string

  /** principal amount in raw form */
  principal: BigNumber

  /** account cumulative interest multiplier */
  initialCIM: BigNumber

  /** price in USD */
  price: BigNumber

  priceType: CDPPriceType

  type: DebtType

  constructor(args: ICDPAccountDebt) {
    this.denom = args.denom
    this.principal = args.principal
    this.initialCIM = args.initialCIM
    this.price = args.price
    this.priceType = args.priceType
    this.type = args.type
  }

  /**
   * @param currentCIM current asset cumulative interest multiplier
   * @returns current account total debt for this asset in raw form
   */
  public getCurrentTotalDebt(currentCIM: BigNumber = BN_ONE) {
    const totalDebt = currentCIM.times(this.principal).div(this.initialCIM).dp(0, BigNumber.ROUND_CEIL)
    return totalDebt
  }

  /**
   * @param currentCIM current asset cumulative interest multiplier
   * @returns current accumulated interest
   */
  public getInterest(currentCIM: BigNumber) {
    const totalDebt = this.getCurrentTotalDebt(currentCIM)
    return totalDebt.minus(this.principal)
  }
}
export interface CDPAccountSupply {
  /** denom of underlying token */
  denom: string

  /** amount of CDP tokens */
  cdpAmount: BigNumber

  /** equivalent underlying amount */
  eqUnderlyingAmount: BigNumber

  /** price in USD */
  price: BigNumber
  priceType: CDPPriceType
}

export interface CDPAccountCollateral {
  /** denom of underlying token */
  denom: string

  /** amount of CDP tokens */
  cdpAmount: BigNumber

  /** equivalent underlying amount */
  eqUnderlyingAmount: BigNumber

  /** price in USD */
  price: BigNumber
  priceType: CDPPriceType
}

export interface Asset extends WSModels.AssetParams {
  borrowInterest: BigNumber
  lendApr: BigNumber
  cdpToActualRatio: BigNumber
  cibtDenom: string
  borrowableActualTokenAmount: BigNumber
}

export interface Collateral extends WSModels.Collateral {
  adjustedAmount: BigNumber
}

export enum RewardType {
  Borrow = 'borrow',
  Mint = 'mint',
  Lend = 'lend',
}
export interface Debt {
  denom: string
  principalDebtAmount: BigNumber
  totalDebtAmount: BigNumber
  totalDebtAdjustedAmount: BigNumber
  initialCumulativeInterestMultiplier: BigNumber
  type: DebtType
}

export interface AccountData {
  totalCollateralsUsd: BigNumber
  availableBorrowsUsd: BigNumber
  currLiquidationThreshold: BigNumber
  totalDebtsUsd: BigNumber
  totalStablecoinDebtsUsd: BigNumber
  healthFactor: BigNumber
}

export interface Params {
  completeLiquidationThreshold: BigNumber
  interestFee: BigNumber
  liquidationFee: BigNumber
  minimumCloseFactor: BigNumber
  smallLiquidationSize: BigNumber
  stablecoinInterestRate: BigNumber
  stablecoinMintCap: BigNumber
  cdpPaused: boolean
}

export interface AccountDataResponse {
  TotalCollateralsUsd: BigNumber
  AvailableBorrowsUsd: BigNumber
  CurrLiquidationThreshold: BigNumber
  TotalDebtsUsd: BigNumber
  TotalStablecoinDebtsUsd: BigNumber
  HealthFactor: BigNumber
}

export interface RewardScheme extends WSModels.RewardScheme {
  apr: BigNumber
}

export interface Reward {
  denom: string
  accumulatedApr: BigNumber
}

export interface CdpError extends Error {
  response: ErrorResponse
}

export interface ErrorResponse {
  readonly height: number;
  readonly code: number;
  readonly transactionHash: string;
  readonly rawLog?: string;
  readonly gasUsed: number;
  readonly gasWanted: number;
}

export interface DebtableAsset {
  asset: CDPAsset
  debt?: CDPAccountDebt

  availableAmount: BigNumber
  isBorrow: boolean
  isMinted: boolean
  isBorrowableOrMintable?: boolean
  availableBorrow: BigNumber
  availableMint: BigNumber
}

export interface BorrowableAsset extends DebtableAsset {
  availableModuleBalance: BigNumber
}

export interface EditCollateralState {
  lendApr: BigNumber
  isAddCollateral: boolean
}

export interface LVLStats {
  maxLtv: BigNumber
  liquidationThreshold: BigNumber
  maxBorrowableValue: BigNumber
  collateralTotalValue: BigNumber
}

export interface LendingAssetRowData {
  asset: CDPAsset
  availBalance: BigNumber // cdp token balance
  collateralBalance: BigNumber // cdp token balance
}