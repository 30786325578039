import { makeStyles } from '@material-ui/core'
import { CarbonSDK } from 'carbon-js-sdk'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Dropdown, DropDownIcon } from 'js/components/Common'
import MenuListItems, { MenuItem } from 'js/components/Common/MenuListItems'
import { EvtCategory, useDropdownHandler, useEventTrackers } from 'js/hooks'
import { setNet } from 'js/state/modules/app/actions'
import { getAutoSelectNode, getNet } from 'js/state/modules/app/selectors'
import { capitalize } from 'js/utils'
import { IS_ENV_MASTER } from 'js/utils/environment'
import { StyleUtils } from 'js/utils/styles'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  setFirstLoad: React.Dispatch<React.SetStateAction<boolean>>
}

const NetworkToggle: React.FC<Props> = (props: Props) => {
  const { className, setFirstLoad } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const eventTrackers = useEventTrackers()
  const network = useSelector(getNet)
  const autoSelectNode = useSelector(getAutoSelectNode)
  const [isDropdownOpen, handleDropdownOpen, handleDropdownClose] = useDropdownHandler(false)
  let initNetworks: CarbonSDK.Network[] = [CarbonSDK.Network.MainNet, CarbonSDK.Network.TestNet]

  if (!IS_ENV_MASTER) {
    initNetworks = initNetworks.concat([CarbonSDK.Network.DevNet, CarbonSDK.Network.LocalHost])
  }


  const onSelectNetwork = (newNetwork: CarbonSDK.Network) => {
    if (newNetwork !== network) {
      dispatch(setNet(newNetwork))
      eventTrackers.sendEvent(EvtCategory.Navigation, 'menu_network', { network: newNetwork })
      if (autoSelectNode) {
        setFirstLoad(true)
      }
    }
  }

  const menuItems: MenuItem[] = initNetworks.map((option: CarbonSDK.Network) => ({
    active: network === option,
    label: `Carbon ${capitalize(option)}`,
    key: option,
    onClick: () => onSelectNetwork(option),
  }))

  return (
    <Dropdown
      label={(
        <div className={clsx(classes.dropdownSelect, { open: isDropdownOpen })}>
          Carbon {capitalize(network)}
          <DropDownIcon className={classes.dropdownIcon} open={isDropdownOpen} />
        </div>
      )}
      dropdownOpen={isDropdownOpen}
      onClick={isDropdownOpen ? handleDropdownClose : handleDropdownOpen}
      onMouseLeave={handleDropdownClose}
      className={clsx(classes.root, className)}
      hasDropdownIcon={false}
      labelClassName={classes.labelClassName}
    >
      <MenuListItems
        items={menuItems}
        size="small"
      />
    </Dropdown>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '9rem',
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    boxShadow: StyleUtils.dropShadow(theme),
  },
  menuList: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  dropdownIcon: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  dropdownSelect: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
    minHeight: 'auto',
    padding: theme.spacing(0.5, 1.5),
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    '&:hover': {
      backgroundColor: 'transparent',
      borderRadius: 4,
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.5, 0.5, 0.5, 1.5),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: theme.spacing(0.5, 1.5, 0.5, 0.25),
    },
  },
  labelClassName: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
}))

export default NetworkToggle
