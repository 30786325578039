import { Record } from 'immutable'
import { AnyAction } from 'redux'

import {
  LeaderboardActionTypes, LeaderboardData, LeaderBoardParams,
  LeaderMap, LeaderboardState, LeaderboardStateProps, LeaderBoardDuration,
} from './types'

export const defaultLeaderboard: LeaderboardData = {
  count: 0,
  data: null,
  limit: 0,
  market: 'None',
  offset: 0,
  sort: 'DESC',
  toTime: 0,
}

export const defaultRankList: LeaderMap = {
  last24Hours: {
    top: [],
    bottom: [],
  },
  last7Days: {
    top: [],
    bottom: [],
  },
  last30Days: {
    top: [],
    bottom: [],
  },
  allTime: {
    top: [],
    bottom: [],
  },
}

const defaultFilters: LeaderBoardParams = {
  market: '',
  duration: LeaderBoardDuration.D7,
}

export const DefaultInitialState:
  Record.Factory<LeaderboardStateProps> = Record<LeaderboardStateProps>({
    topPerformers: defaultLeaderboard,
    bottomPerformers: defaultLeaderboard,
    usernameMap: undefined,
    leaderboardRankMap: defaultRankList,
    filters: defaultFilters,
  })

export const defaultInitialState: LeaderboardState = new DefaultInitialState()

export const leaderboardReducer = (
  state: LeaderboardState = defaultInitialState,
  action: AnyAction,
): LeaderboardState => {
  switch (action.type) {
    case LeaderboardActionTypes.SET_TOP_LEADERBOARD: {
      return state.set('topPerformers', action.payload)
    }
    case LeaderboardActionTypes.SET_BOTTOM_LEADERBOARD: {
      return state.set('bottomPerformers', action.payload)
    }
    case LeaderboardActionTypes.SET_USERNAME_MAP: {
      return state.set('usernameMap', action.payload)
    }
    case LeaderboardActionTypes.SET_LEADERBOARD_MAP: {
      return state.set('leaderboardRankMap', action.payload)
    }
    case LeaderboardActionTypes.SET_LEADERBOARD_FILTERS: {
      return state.set('filters', action.payload)
    }
    default:
      return state
  }
}
