import { alpha, makeStyles, Theme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import React from 'react'

import { LoadingInformation } from 'js/components/Exchange/History/Loader'

interface Props extends LoadingInformation {
  ready?: boolean
  containerWidth?: number,
  paddingBottom?: number,
}

const LoadingSkeleton: React.FC<Props> = (props: Props) => {
  const {
    width, height, side, style, variant = "rect", rectWithCircle,
    circleSize, end, className, stacked, secondaryHeight,
    secondaryWidth, secondaryClass,
    ready = false, children, containerWidth, paddingBottom,
  } = props

  const classes = useStyles()

  if (ready) return (<React.Fragment>{children}</React.Fragment>)

  return (
    <div className={clsx(className, classes.container, { end }, { stacked })} style={{ width: containerWidth, paddingBottom }}>
      {rectWithCircle && (
        <Skeleton
          variant="circle"
          animation="wave"
          width={circleSize ?? 12}
          height={circleSize ?? 12}
          className={clsx(classes.root, 'pushRight')}
          style={style}
        />
      )}
      <div className={clsx(classes.secondaryBox, { end }, secondaryClass)}>
        <Skeleton
          variant={variant}
          animation="wave"
          width={width}
          height={height}
          className={clsx(classes.root, side, variant)}
          style={style}
        />
        {stacked && (
          <Skeleton
            variant="rect"
            animation="wave"
            width={secondaryWidth ?? 40}
            height={secondaryHeight ?? 12}
            className={clsx(classes.secondary, variant)}
            style={style}
          />
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.buy': {
      background: alpha(theme.palette.buyText, 0.16),
    },
    '&.rect': {
      borderRadius: '4px',
      overflow: 'hidden',
    },
    '&.sell': {
      backgroundColor: alpha(theme.palette.sellText, 0.16),
    },
    '&.pushRight': {
      marginRight: theme.spacing(1),
    },
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '&.end': {
      justifyContent: 'flex-end',
    },
  },
  secondaryBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&.end': {
      alignItems: 'flex-end',
    }
  },
  secondary: {
    marginTop: theme.spacing(0.25),
    '&.rect': {
      borderRadius: '4px',
      overflow: 'hidden',
    },
  },
}))

export default LoadingSkeleton
