import BigNumber from 'bignumber.js'
import dayjs, { Dayjs } from 'dayjs'

import { LeaderBoardDuration } from 'js/state/modules/leaderboard/types'

export const DAYS_IN_A_WEEK = 7
export const DAYS_IN_A_YEAR = 365

export const FIFTEENSECONDS = 15000
export const SIXTYSECONDS = 60000
export const FIVEMINUTES = 300000

export const SECONDS_PER_YEAR = 31536000
export const SECONDS_IN_A_DAY = 86400
export const SECONDS_IN_A_HOUR = 3600
export const TWO_WEEKS = 86400 * 14
export const WEEKS_IN_A_YEAR = 52

export const NEW_MARKET_DURATION = SECONDS_IN_A_DAY * 5 // 5 days

export const HIDDEN_BANNER_DURATION = SECONDS_IN_A_HOUR * 8 * 1000 // 8 hours in miliseconds

export const DEFAULT_BLOCK_TIME = new BigNumber(2.2)

export const DEFAULT_EASY_DATE_RANGE = DAYS_IN_A_WEEK

export const secondsInMinBN = new BigNumber(60)
export const secondsInHourBN = secondsInMinBN.multipliedBy(60)
export const secondsInDayBN = secondsInHourBN.multipliedBy(24)
export const secondsInYearBN = secondsInDayBN.multipliedBy(365)

export interface DurationDropdownItem {
  label: string
  value: LeaderBoardDuration
}

export type DateRange = [Dayjs, Dayjs]

export const durationSelect: DurationDropdownItem[] = [{
  label: 'Last 24 Hours',
  value: LeaderBoardDuration.H24,
}, {
  label: 'Last 7 Days',
  value: LeaderBoardDuration.D7,
}, {
  label: 'Last 30 Days',
  value: LeaderBoardDuration.D30,
}, {
  label: 'All Time',
  value: LeaderBoardDuration.ALL,
}]

export interface TimeLeftBreakdown {
  [index: string]: number
}

export const ibcTimeoutMin: number = 30

export const dropDownTimeoutDuration: number = 250
export const navbarDropdownTimeoutDuration: number = 100

export const utcOffset = dayjs().utcOffset()
