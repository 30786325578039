import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { InboxWsStateProps, InboxWsState, InboxWebSocketActionTypes } from './types'

export const DefaultInitialState:
  Record.Factory<InboxWsStateProps> = Record<InboxWsStateProps>({
    isConnected: false,
  })

const defaultInitialState: InboxWsState = new DefaultInitialState()

export const inboxWsReducer = (
  state: InboxWsState = defaultInitialState,
  action: AnyAction,
): InboxWsState => {
  switch (action.type) {
    case InboxWebSocketActionTypes.STATUS_CHANGE: {
      return state.set('isConnected', action.payload)
    }
    default:
      return state
  }
}
