import { WSModels } from 'carbon-js-sdk'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { SimpleMap } from 'js/utils'

import { MarketPrices, MarketStatsActionTypes, MarketStatsState, MarketStatsStateProps, ModifiedMarketStat } from './types'

export const DefaultInitialState:
  Record.Factory<MarketStatsStateProps> = Record<MarketStatsStateProps>({
    marketStats: {},
    marketPrices: null,
  })

const defaultInitialState: MarketStatsState = new DefaultInitialState()

export const marketStatsReducer = (
  state: MarketStatsState = defaultInitialState,
  action: AnyAction,
): MarketStatsState => {
  switch (action.type) {
    case MarketStatsActionTypes.SET_MARKET_STATS: {
      return state.set('marketStats', action.payload)
    }
    case MarketStatsActionTypes.UPDATE_MARKET_STATS: {
      const newStats = consolidateMarketStats(action.payload, state.marketStats)
      return state.set('marketStats', newStats)
    }
    case MarketStatsActionTypes.CLEAR_MARKET_STATS: {
      return new DefaultInitialState()
    }
    case MarketStatsActionTypes.UPDATE_MARKET_PRICES: {
      const marketPrices: MarketPrices = action.payload
      // optimisation to reduce state update every block
      // if prices did not change.
      if (
        state.marketPrices?.index.eq(marketPrices.index)
        && state.marketPrices?.mark.eq(marketPrices.mark)
        && state.marketPrices?.last.eq(marketPrices.last)
      ) {
        return state
      }

      return state.set('marketPrices', action.payload)
    }

    default:
      return state
  }
}

/**
 * Modifies raw market stats in place
 */
export function parseMarketStats(stats: SimpleMap<WSModels.MarketStat>): SimpleMap<ModifiedMarketStat> {
  for (const stat of Object.values(stats)) {
    const close = bnOrZero(stat.day_close)
    const open = bnOrZero(stat.day_open)
    const change = close.isZero() ? null : close.minus(open).div(open)
    const volume = bnOrZero(stat.day_quote_volume).times(2)

    const modifiedStat = stat as ModifiedMarketStat
    
    modifiedStat.lastPrice = close.isZero() ? null : stat.day_close
    modifiedStat.change = (!change || change.isNaN()) ? null : change.times(100).toString()
    modifiedStat.volume =  volume.toString()
  }

  return stats as SimpleMap<ModifiedMarketStat>
}

const isSameStat = (lhs: WSModels.MarketStat, rhs: WSModels.MarketStat) => {
  return lhs.day_high === rhs.day_high
    && lhs.day_low === rhs.day_low
    && lhs.day_open === rhs.day_open
    && lhs.day_close === rhs.day_close
    && lhs.day_volume === rhs.day_volume
    && lhs.day_quote_volume === rhs.day_quote_volume
    && lhs.index_price === rhs.index_price
    && lhs.mark_price === rhs.mark_price
    && lhs.last_price === rhs.last_price
    && lhs.market_id === rhs.market_id
    && lhs.market_type === rhs.market_type
    && lhs.premium_rate === rhs.premium_rate
    && lhs.last_funding_at === rhs.last_funding_at
    && lhs.open_interest === rhs.open_interest
}

export const consolidateMarketStats = (newStats: SimpleMap<ModifiedMarketStat>, prevStats: SimpleMap<ModifiedMarketStat>): SimpleMap<ModifiedMarketStat> => {
  const updates = Object.values(newStats).map((newStat: ModifiedMarketStat) => {
    const marketId = newStat.market_id
    const oldStat = prevStats[marketId]
    if (oldStat && isSameStat(newStat, oldStat)) return { [marketId]: oldStat }

    return { [marketId]: newStat }
  })

  return Object.assign({}, prevStats, ...updates)
}
