import { makeStyles, SvgIconProps } from '@material-ui/core'
import clsx from 'clsx'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { TOKEN_ICON_REPO_URL } from 'js/constants/paths'

import { ReactComponent as Placeholder } from 'assets/Placeholder.svg'

import SvgIcon from './SvgIcon'

const getTokenIconURL = (symbol: string, fileType: string) => {
  return TOKEN_ICON_REPO_URL.replace(':type', 'tokens').replace(':token_name', symbol).replace(':file_type', fileType)
}

const TOKEN_NAME_OVERRIDE: {
  [index: string]: string
} = {
  // swth: "SWTH",
  // usc: "USC",
  'LKT.BEP20': 'LKT',
  BTCB: 'BTC',
  USD: 'cUSD',
  SWTHB: 'SWTH',
  USTC: 'UST',
  CUSDC: 'USDC',
  CPLT: 'CLP',
  DATOM: 'dATOM',
}

export interface CoinIconProps extends SvgIconProps {
  denom?: string
  isMinted?: boolean
}
const IMAGE_EXTS = ['svg', 'png']
const CoinIcon: React.FunctionComponent<CoinIconProps> = (
  props: CoinIconProps,
) => {
  const { denom, className, isMinted, ...rest } = props
  const classes = useStyles()

  const [extIndex, setExtIndex] = useState<number>(0)

  useEffect(() => {
    setExtIndex(0)
  }, [setExtIndex, denom])

  const symbol = useMemo(() => {
    let symbol = TOKEN_NAME_OVERRIDE[denom ?? ''] ?? denom
    if (symbol && isMinted) symbol += '-Minted'
    return symbol
  }, [denom, isMinted])

  const tryNextExt = useCallback(() => {
    setExtIndex(extIndex => extIndex + 1)
  }, [setExtIndex])

  const imgSrc = useMemo(() => {
    if (!symbol) return null
    const ext = IMAGE_EXTS[extIndex]

    if (!ext) return null // error

    return getTokenIconURL(symbol, ext)
  }, [symbol, extIndex])

  return imgSrc ? (
    <img className={clsx(classes.svg, className)} src={imgSrc} onError={() => tryNextExt()} alt={symbol} />
  ) : (
    <SvgIcon
      component={Placeholder}
      {...rest}
      className={clsx(classes.svg, className, `symbol-${symbol}-${denom}`)}
    />
  )
}

const useStyles = makeStyles({
  svg: {
    fontSize: 'inherit',
    width: '2em',
    height: '2em',
  },
})

export default CoinIcon
