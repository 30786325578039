import { StatsigClient } from "@statsig/js-client"
import { TypeUtils } from "carbon-js-sdk"

import { StatsigExperiment } from "./types"

import { RootState } from "../rootReducer"

export function getStatsigClient(state: RootState): StatsigClient | undefined {
  return state.statsig.statsigClient
}

export function getStatsigExperiments(state: RootState): TypeUtils.SimpleMap<StatsigExperiment> | undefined {
  return state.statsig.statsigExperiments
}