import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { ExchangeMode } from 'js/constants/markets'
import Paths from 'js/constants/paths'
import { getNet } from 'js/state/modules/app/selectors'
import { setIsSimpleMode, setLastMarket, setMarketName, setModeDialogState } from 'js/state/modules/exchange/actions'
import { getIsSimpleMode, getMarket, getModeDialogState } from 'js/state/modules/exchange/selectors'
import { Market } from 'js/state/modules/exchange/types'
import { defaultTpSlFormState } from 'js/state/modules/orderManager/types'

import useMarketDisplayName from './useMarketDisplayName'
import useOrderManager from './useOrderManager'

export const useTradeMode = () => {
  const isSimpleMode = useSelector(getIsSimpleMode)
  const modeDialogState = useSelector(getModeDialogState)
  const dispatch = useDispatch()
  const history = useHistory()
  const market = useSelector(getMarket)
  const network = useSelector(getNet)
  const orderManager = useOrderManager()
  const getDisplayName = useMarketDisplayName()

  const alternateMode: ExchangeMode = isSimpleMode ? 'pro' : 'lite'
  const currentMode: ExchangeMode = isSimpleMode ? 'lite' : 'pro'


  const prompt = () => {
    // Dialog prompt
    dispatch(setModeDialogState({
      ...modeDialogState,
      isOpen: true,
      selected: alternateMode,
    }))
  }

  const toPro = (pushHistory: boolean = true, keepDialogOpen: boolean = false) => {
    if (!isSimpleMode) return
    const currentMarket: Market | undefined | null = market
    if (!currentMarket) return

    dispatch(setLastMarket(currentMarket.id, network))
    dispatch(setMarketName(currentMarket.id))

    if (pushHistory) {
      const displayName = getDisplayName(currentMarket, true)
      window.scrollTo(0, 0)
      history.push(`${Paths.Trade.Main}/${displayName}`)
    }

    // Directly switch to Advanced mode
    dispatch(setIsSimpleMode(false))
    dispatch(setModeDialogState({
      ...modeDialogState,
      isOpen: keepDialogOpen,
      selected: 'lite',
    }))

    orderManager.toggle('postOnly', false)
    orderManager.toggle('reduceOnly', false)
  }

  const toLite = (pushHistory: boolean = true, keepDialogOpen: boolean = false) => {
    if (isSimpleMode) return

    // Directly Switch to Lite Mode
    dispatch(setIsSimpleMode(true))
    dispatch(setModeDialogState({
      ...modeDialogState,
      isOpen: keepDialogOpen,
      selected: 'pro',
    }))

    orderManager.update({
      ...defaultTpSlFormState,
      isTakeProfitStopLoss: false,
    })

    if (pushHistory) {
      const displayName = getDisplayName(market, true)
      window.scrollTo(0, 0)
      history.push(`${Paths.Lite}/${displayName}`)
    }
  }

  return {
    prompt,
    toPro,
    toLite,
    isSimpleMode,
    currentMode,
    alternateMode,
  }
}
