import { Models } from 'carbon-js-sdk'
import { RecordOf } from 'immutable'

export interface Erc20StateProps {
  readonly tokenPairsMap: TokenPairsMap
}

export type Erc20State = RecordOf<Erc20StateProps>

export interface TokenPairsMap {
  [tokenPairDenom: string]: Models.Carbon.Erc20.TokenPair
}

export enum Erc20ActionTypes {
  QUERY_TOKEN_PAIRS = '@erc20/QUERY_TOKEN_PAIRS',
  SET_TOKEN_PAIRS_MAP = '@erc20/SET_TOKEN_PAIRS_MAP',
}
