import BigNumber from 'bignumber.js'
import { TypeUtils } from 'carbon-js-sdk'
import { action } from 'typesafe-actions'

import { ChartLayoutType, ChartLayoutsType } from 'js/utils/types'

import { BooksObj, ExchangeActionTypes, ExchangeOrderBook, HistoricalFundingRateGraphData, HistoricalFundingRates, Market, MarketCandlesticks, MarketLiquidity, MarketTypeTab, ModeDialogState, OrderBookUpdates, OrderDetailsDialogState } from './types'

export const addFavouriteMarket = (market: string) => action(
  ExchangeActionTypes.ADD_FAVOURITE_MARKET, market,
)

export const setFundingInterval = (fundingInterval: number | null) => action(
  ExchangeActionTypes.SET_FUNDING_INTERVAL, fundingInterval,
)

export const clearHideLayouts = () => action(
  ExchangeActionTypes.CLEAR_HIDE_LAYOUTS,
)

export const removeFavouriteMarket = (market: string) => action(
  ExchangeActionTypes.REMOVE_FAVOURITE_MARKET, market,
)

export const setHideLayouts = (layout: string, hide: boolean) => action(
  ExchangeActionTypes.SET_HIDE_LAYOUTS,
  {
    layout,
    hide,
  },
)

export const setSearchHistory = (history: TypeUtils.SimpleMap<string[]>) => action(
  ExchangeActionTypes.SET_SEARCH_HISTORY,
  history,
)

export const setMarketName = (marketName: string) => action(
  ExchangeActionTypes.SET_MARKET_NAME, marketName,
)
export const setMarkets = (markets: Market[]) => action(ExchangeActionTypes.SET_MARKETS, markets)


export const setMarketsAliases = (marketsAliases: TypeUtils.SimpleMap<string>) => action(ExchangeActionTypes.SET_MARKETS_ALIASES, marketsAliases)

export const setMarketDisplayNames = (marketDisplayNames: TypeUtils.SimpleMap<string>) => action(ExchangeActionTypes.SET_MARKET_DISPLAY_NAMES, marketDisplayNames)

export const setLayout = (layout: ChartLayoutType) => action(
  ExchangeActionTypes.SET_LAYOUT,
  layout,
)

export const setLayouts = (layouts: ChartLayoutsType) => action(
  ExchangeActionTypes.SET_LAYOUTS,
  layouts,
)

export const resetLayout = () => action(ExchangeActionTypes.RESET_LAYOUT)

export const setMarketSelectOpen = () => action(ExchangeActionTypes.SET_MARKET_SELECT_OPEN)

export const setMarketSelectClose = () => action(ExchangeActionTypes.SET_MARKET_SELECT_CLOSE)

export const setSymbolToggle = () => action(ExchangeActionTypes.SET_SYMBOL_TOGGLE)

export const setMarketTypeSelect = (marketType: MarketTypeTab) => action(ExchangeActionTypes.SET_MARKET_TYPE_SELECT, marketType)

export const setMarketSearch = (search: string) => action(ExchangeActionTypes.SET_MARKET_SEARCH, search)

export const setLastMarket = (lastMarket: string, network: string) => action(ExchangeActionTypes.SET_LAST_MARKET, {
  lastMarket,
  network,
})

export const setLastSwapMarket = (lastSwapMarket: string, network: string) => action(ExchangeActionTypes.SET_LAST_SWAP_MARKET, {
  lastSwapMarket,
  network,
})

export const setCandlesticks = (candlesticks: MarketCandlesticks[]) => action(
  ExchangeActionTypes.SET_MARKETS_CANDLESTICKS,
  candlesticks,
)

export const reloadMarkets = () => action(ExchangeActionTypes.RELOAD_MARKETS)

export const setShowLowLiquidity = (checked: boolean) => action(
  ExchangeActionTypes.SET_SHOW_LOW_LIQUIDITY,
  checked,
)

export const setBooksObj = (booksObj: BooksObj) => action(
  ExchangeActionTypes.SET_BOOKS_OBJ,
  booksObj,
)

export const setMarketOrderBook = (orderBook: ExchangeOrderBook) => action(
  ExchangeActionTypes.SET_MARKET_ORDER_BOOK,
  orderBook,
)

export const syncMarketOrderBook = (orderBookUpdates: OrderBookUpdates) => action(
  ExchangeActionTypes.SYNC_MARKET_ORDER_BOOK,
  orderBookUpdates,
)

export const setIsSimpleMode = (isSimpleMode: boolean) => action(
  ExchangeActionTypes.SET_IS_SIMPLE_MODE,
  isSimpleMode,
)

export const setModeDialogState = (state: ModeDialogState) => action(
  ExchangeActionTypes.SET_MODE_DIALOG_STATE,
  state,
)

export const setShowModeDialog = (show: boolean) => action(
  ExchangeActionTypes.SET_SHOW_MODE_DIALOG, show,
)

export const setTempOrderDetailsDialogState = (tempOrderDetailsDialogState: OrderDetailsDialogState) => action(
  ExchangeActionTypes.SET_TEMP_ORDER_DETAILS_DIALOG_STATE,
  tempOrderDetailsDialogState,
)

export const calculateAdjustedMaxFuturesQuantity = () => action(
  ExchangeActionTypes.CALCULATE_ADJUSTED_MAX_FUTURES_QUANTITY,
)

export const setAdjustedMaxFuturesQuantity = (adjustedMaxFuturesQuantity: BigNumber) => action(
  ExchangeActionTypes.SET_ADJUSTED_MAX_FUTURES_QUANTITY,
  adjustedMaxFuturesQuantity,
)

export const setMarketsLiquidityMap = (marketsLiquidityMap: TypeUtils.SimpleMap<MarketLiquidity>) => action(
  ExchangeActionTypes.SET_MARKETS_LIQUIDITY_MAP,
  marketsLiquidityMap,
)

export const setMarketHistoricalFundingRates = (fundingRates: HistoricalFundingRates | null) => action(
  ExchangeActionTypes.SET_MARKET_HISTORICAL_FUNDING_RATES,
  fundingRates,
)

export const setHistoricalFundingRateGraphData = (fundingRateGraphData: HistoricalFundingRateGraphData[]) => action(
  ExchangeActionTypes.SET_HISTORICAL_FUNDING_RATE_GRAPH_DATA,
  fundingRateGraphData,
)

export const setExchangeSectionCollapseStatus = (section: string, status: boolean) => action(
  ExchangeActionTypes.SET_SECTION_COLLAPSE_STATUS,
  {
    section,
    status
  },
)

export const fetchHistorialFundingGraphData = (market?: string) => action(
  ExchangeActionTypes.FETCH_HISTORICAL_FUNDING_GRAPH_DATA,
  market
)
