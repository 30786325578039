import { Divider, makeStyles } from '@material-ui/core'
import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion' // eslint-disable-line import/no-unresolved
import MuiAccordionDetails from '@material-ui/core/AccordionDetails' // eslint-disable-line import/no-unresolved
import MuiAccordionSummary from '@material-ui/core/AccordionSummary' // eslint-disable-line import/no-unresolved
import clsx from 'clsx'
import React from 'react'

import { SvgIcon } from 'js/components/Common'

import { ReactComponent as Caret } from 'assets/CaretUp.svg'


interface Props extends Omit<AccordionProps, 'children'> {
  summaryChildren: any,
  detailsChildren: any,
  accordionClassName?: string,
  summaryClassName?: string,
  detailsClassName?: string,
  summaryContentClassName?: string,
  withEndIcon: boolean,
  expandIconClassName?: string,
  withDivider?: boolean
}

const Accordion: React.FC<Props> = (props: Props) => {
  const { expanded, onChange, summaryChildren, detailsChildren, accordionClassName, summaryClassName, detailsClassName, summaryContentClassName, withEndIcon, expandIconClassName, withDivider, disabled } = props
  const classes = useStyles()

  return (
    <MuiAccordion disabled={disabled} square classes={{ root: clsx(classes.accordion, accordionClassName), expanded: classes.expanded }} expanded={expanded} onChange={onChange}>
      <MuiAccordionSummary
        classes={{ root: clsx(classes.accordionSummary, summaryClassName), expanded: classes.expanded, expandIcon: classes.expandIcon, content: clsx(classes.content, summaryContentClassName) }}
        expandIcon={withEndIcon && <SvgIcon className={clsx(classes.svgIcon, { isExpanded: expanded }, expandIconClassName)} component={Caret} />}
      >
        {summaryChildren}
      </MuiAccordionSummary>
      {expanded && withDivider && (
        <Divider />
      )}
      <MuiAccordionDetails classes={{ root: clsx(classes.accordionDetails, detailsClassName) }}>
        {detailsChildren}
      </MuiAccordionDetails>
    </MuiAccordion>
  )
}

const useStyles = makeStyles((theme) => ({
  accordion: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 0,
    backgroundColor: theme.palette.background.primary,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: theme.palette.background.primary,
    },

    '&.Mui-disabled': {
      backgroundColor: 'unset'
    }
  },
  accordionSummary: {
    ...theme.typography.body3,
    minHeight: '2.5rem',
    maxHeight: '2.5rem',
    height: '100%',
    padding: 0,
    '&.Mui-focused': {
      backgroundColor: 'transparent',
    },
    '&.Mui-expanded': {
      minHeight: '2.5rem',
      maxHeight: '2.5rem',
      height: '100%',
      margin: 0,
    },
    '&.Mui-disabled': {
      opacity: 1
    },
  },
  content: {
    margin: 0,
  },
  accordionDetails: {
    padding: theme.spacing(0, 0, 2),
  },
  expandIcon: {
    padding: theme.spacing(1, 1.5),
    '&$expanded': {
      transform: 'none !important',
      paddingRight: 0,
    },
  },
  expanded: {
    margin: '0 !important',
    minHeight: 'unset',
  },
  svgIcon: {
    height: '0.75rem',
    width: '0.75rem',
    rotate: '180deg',
    '& path': {
      stroke: theme.palette.text.secondary,
    },
    '&.isExpanded': {
      '& path': {
        stroke: theme.palette.text.demexSolid,
      },
      rotate: '0deg',
    },
    transition: '0.4s',
  },
}))


export default React.memo(Accordion)
