import BigNumber from 'bignumber.js'
import { AddressUtils, BlockchainUtils, CarbonSDK, Hydrogen, TypeUtils } from 'carbon-js-sdk'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import { isDesktop } from 'react-device-detect'

import { TransferMethod, TransferType, chainLabelMapping } from 'js/constants/TransferOptions'
import { carbonStablecoinDenom, tokenAddrRegex, usdGroupedToken } from 'js/constants/assets'
import { BridgeTransferMethods } from 'js/state/modules/walletBalance/types'

export const CarbonEVM = 'Carbon EVM'

export const Carbon = 'Carbon'

export function chainLabel(chain: BlockchainUtils.BlockchainV2, shortVersion: boolean = false) {
  if (chain === 'Binance Smart Chain' && shortVersion) {
    return 'BSC'
  }
  return chainLabelMapping[chain] || chain
}

export const availableNets: CarbonSDK.Network[] = [CarbonSDK.Network.MainNet, CarbonSDK.Network.TestNet, CarbonSDK.Network.DevNet]

export const estimatedBlocksConfig: TypeUtils.SimpleMap<BlockchainInfo> = {
  Ethereum: {
    maxEstimatedBlocks: new BigNumber(96),
    blockTime: new BigNumber(0.2), // 12 seconds
  },
  Arbitrum: {
    maxEstimatedBlocks: new BigNumber(1500),
    blockTime: new BigNumber(0.00583), // 0.35 seconds
  },
  Polygon: {
    maxEstimatedBlocks: new BigNumber(500),
    blockTime: new BigNumber(0.0383), // 2.3 seconds
  },
  Base: {
    maxEstimatedBlocks: new BigNumber(1500),
    blockTime: new BigNumber(0.03), // 2 seconds
  },
  Mantle: {
    maxEstimatedBlocks: new BigNumber(1500),
    blockTime: new BigNumber(0.03), // 2 seconds
  },
}

export const estimatedTimeGap: TypeUtils.SimpleMap<number> = {
  default: 2,
  Base: 15,
  Mantle: 15,
  Arbitrum: 21,
}

export interface BlockchainInfo {
  maxEstimatedBlocks: BigNumber
  blockTime: BigNumber
}


export function getIbcTransferDenom(externalTransfer: Hydrogen.CrossChainTransfer, sdk: CarbonSDK | undefined) {
  if (!sdk?.token) return ''
  const fromReg = externalTransfer.from_asset.match(tokenAddrRegex)
  let asset = ''
  if (fromReg) {
    asset = `ibc/${(fromReg?.[1] ?? '').toUpperCase()}`
  } else {
    const toReg = externalTransfer.to_asset.match(tokenAddrRegex)
    if (toReg) {
      asset = `ibc/${(toReg?.[1] ?? '').toUpperCase()}`
    } else {
      asset = externalTransfer.to_asset
    }
  }
  return asset
}

export function getTransferDenom(externalTransfer: Hydrogen.CrossChainTransfer, sdk: CarbonSDK | undefined, isIBC: boolean = false) {
  if (!sdk?.token) return ''
  if (externalTransfer.carbon_token_id && externalTransfer.carbon_token_id !== '') {
    return sdk.token.tokenForId(externalTransfer.carbon_token_id)?.denom ?? sdk.token.tokenForDenom(externalTransfer.carbon_token_id)?.denom ?? ''
  }
  if (isIBC) {
    return getIbcTransferDenom(externalTransfer, sdk)
  }

  try {
    // Check if this is a SWTH address (either from_address or to_address is the swth address. then get the token denom for swth side token)
    const fromAddressBytes = AddressUtils.SWTHAddress.getAddressBytes(externalTransfer.from_address, sdk.network)
    if (fromAddressBytes && fromAddressBytes.length > 1) {
      const asset = externalTransfer.from_asset
      const tokenForId = sdk.token.tokenForId(asset)
      return tokenForId?.denom ?? ''
    }
  } catch (err) {
    const asset = externalTransfer.to_asset
    const tokenForId = sdk.token.tokenForId(asset)
    return tokenForId?.denom ?? ''
  }
  return ''
}

export function getTransferStatusLabel(status: Hydrogen.CrossChainFlowStatus) {
  switch (status) {
    case Hydrogen.CrossChainFlowStatus.Completed:
      return 'Completed'
    case Hydrogen.CrossChainFlowStatus.Refunded:
      return 'Refunded'
    case Hydrogen.CrossChainFlowStatus.FailedAndNotRecoverable:
      return 'Failed'
    default:
      return 'In Transit'
  }
}

export function getTransferType(externalTransfer: Hydrogen.CrossChainTransfer, walletAddress: string) {
  if (externalTransfer.to_address === walletAddress) {
    return TransferType.Deposit
  }
  return TransferType.Withdraw
}

export function getTransferLabelText(status: Hydrogen.CrossChainFlowStatus) {
  switch (status) {
    case Hydrogen.CrossChainFlowStatus.Completed:
      return 'Success'
    case Hydrogen.CrossChainFlowStatus.Refunded:
      return 'Refunded'
    case Hydrogen.CrossChainFlowStatus.FailedAndNotRecoverable:
      return 'Failed'
    default:
      return 'In Transit'
  }
}

export type NeoBlockchain = 'Neo' | 'Neo3'

export const isNeoBlockchain = (chain: BlockchainUtils.BlockchainV2 | undefined) => {
  if (!chain) return false
  return ['neo', 'neo3'].includes(chain.toLowerCase())
}

export const isZilBlockchain = (chain: BlockchainUtils.BlockchainV2 | undefined) => {
  if (!chain) return false
  return ['zil', 'zilliqa'].includes(chain.toLowerCase())
}
/**
* Calculates the estimated time for a deposit to be processed based on the selected blockchain.
* @param {string | undefined} blockchain - The name of the blockchain to estimate the deposit time for. Accepted values are 'Ethereum', 'Arbitrum', 'Polygon', 'Base', and 'Mantle'.
* @returns {{ estimatedTime: BigNumber }} An object containing the estimated time in seconds (in BigNumber format) for a deposit to be processed.
*/
export function getDepositEstimatedTime(blockchain: string | undefined) {
  const MAX_ESTIMATED_BLOCKS = bnOrZero(estimatedBlocksConfig[blockchain ?? '']?.maxEstimatedBlocks)
  const BLOCK_TIME = bnOrZero(estimatedBlocksConfig[blockchain ?? '']?.blockTime)

  return {
    estimatedTime: MAX_ESTIMATED_BLOCKS.multipliedBy(BLOCK_TIME),
  }
}

/**
 * Get a string indicating the estimated wait time for external transfer to be successful
 * @param blockchain blockchain for estimation
 * @returns string stating estimated duration for tx confirmation + receipt of funds
 */
export function getEstWaitTime(blockchain: BlockchainUtils.BlockchainV2 | undefined): string {
  // Give a conservative estimation of how long the tx will take
  if (!estimatedBlocksConfig[blockchain ?? ''] || !blockchain) return '~ 1 - 3 minutes'

  const { estimatedTime } = getDepositEstimatedTime(blockchain ?? '')
  const estimatedGap = estimatedTimeGap[blockchain] ?? estimatedTimeGap.default
  const shorterWaitTime = BigNumber.max(estimatedTime, 1)
  const longerWaitTime = shorterWaitTime.decimalPlaces(0, BigNumber.ROUND_CEIL).plus(estimatedGap)
  return `~ ${shorterWaitTime.toFormat(0, BigNumber.ROUND_CEIL)} - ${longerWaitTime.toString(10)} minutes`
}

// Flag for disabling polynetwork deposits and withdraws
export const DISABLE_POLYNETWORK_DEPOSIT = false
export const DISABLE_POLYNETWORK_WITHDRAW = false

// disable notional value for usc/usdg
export const disableTransferUsdValue = (tokenDenom: string) => {
  return tokenDenom === carbonStablecoinDenom || tokenDenom === usdGroupedToken || tokenDenom?.toLowerCase() === 'usd'
}

export const unsupportedExternalWalletConnection = (blockchain?: string) => !isDesktop && (isNeoBlockchain(blockchain) || isZilBlockchain(blockchain))


export const isExternalTransferMethod = (transferMethod: TransferMethod) => [TransferMethod.EVMBridge, TransferMethod.Internal].includes(transferMethod)


export const updateBlockchainTransferMethod = (bridgeTransferMethods: BridgeTransferMethods, blockchain: BlockchainUtils.BlockchainV2, transferMethod: TransferMethod) => {
  if (bridgeTransferMethods[blockchain]) bridgeTransferMethods[blockchain] = [...bridgeTransferMethods[blockchain], transferMethod]
  else bridgeTransferMethods[blockchain] = [transferMethod]
}

// SplitConnectionId splits the connection id into bridgeId, chainName and gatewayAddress
export const splitConnectionId = (connectionId: string = '') => {
  const parts = connectionId.split('/')

  if (parts.length !== 3) return {}

  const bridgeId = parseInt(parts[0], 10)

  const chainName = parts[1]
  const gatewayAddress = parts[2]

  return { bridgeId, chainName, gatewayAddress }
}

export const parseChainName = (name: string = '') => {
  // replace - with space for e.g bsc-testnet => bsc testnet
  return name.replace(/-/g, ' ')
}