import { Theme } from '@material-ui/core'

import { FlavorableOverrides } from './types'

const MuiLink: FlavorableOverrides = (theme: Theme) => ({
  root: {
    cursor: 'pointer',
  },
})

export default MuiLink
