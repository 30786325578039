import React from "react"
import { useLocation } from "react-router"

import Paths from "js/constants/paths"

import ManagePoolsFallback from "./ManagePoolsFallback"
import PoolsFallbackMain from "./PoolsFallbackMain"

import CommonFormFallback from "../CommonFormFallback"

const PoolsSwitch = () => {

  const location = useLocation()

  if (location.pathname.includes('pools/add')
    || location.pathname.includes('pools/remove')
    || location.pathname.includes('pools/commit')
    || location.pathname.includes('pools/extend')
    || location.pathname.includes('pools/unlock')
  ) {
    return <CommonFormFallback />
  }
  switch (location.pathname) {
    case (Paths.Pools.List || Paths.Pools.PerpList || Paths.Pools.SpotList): {
      return <PoolsFallbackMain />
    }
    case Paths.Pools.Manage: {
      return <ManagePoolsFallback />
    }
    case (Paths.Pools.Create): {
      return <CommonFormFallback />
    }
    default: {
      return <PoolsFallbackMain />
    }
  }
}

export default PoolsSwitch