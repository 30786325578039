import BigNumber from 'bignumber.js'
import { BlockchainUtils, CarbonSDK, OrderModule } from 'carbon-js-sdk'

import { MarketType } from 'js/models/Market'
import { OrderFormState, defaultTpSlFormState } from 'js/state/modules/orderManager/types'
import { BN_ZERO } from 'js/utils/number'

export const lowLiquidity: BigNumber = new BigNumber(2500)

export const liquidityThreshold: number = 0.05

export const BlockchainOpts: BlockchainUtils.BlockchainV2[] = [
  'Ethereum',
  'Neo',
  'Neo3',
  'Binance Smart Chain',
  'Zilliqa',
  'Native',
  'Tradehub',
  'Osmosis',
  'Terra Classic',
  'Cosmos Hub',
  'Juno',
  'Evmos',
  'Axelar',
  'Stride',
  'Kujira',
  'Terra',
  'Comdex',
  'Stafihub',
  'Persistence Core',
  'Stargaze',
  'Arbitrum',
  'Polygon',
  'OKC',
  'Canto',
  'Sommelier',
  'Decentr',
  'IRIS Hub',
  'Noble',
  'DYDX',
  'Archway',
  'Mantle',
  'OP',
  'Base',
]

const defaultMarketsBlacklist: string[] = [
  'swth_btc2',
]

export const fallbackMarketsBlacklist: { [key in CarbonSDK.Network]: string[] } = {
  [CarbonSDK.Network.MainNet]: ['gm1_busd1', 'SWTH_UST', 'LUNA_SWTH'],
  [CarbonSDK.Network.TestNet]: defaultMarketsBlacklist.concat(['cmkt/11']),
  [CarbonSDK.Network.DevNet]: defaultMarketsBlacklist,
  [CarbonSDK.Network.LocalHost]: defaultMarketsBlacklist,
}

export type ExchangeMode = 'lite' | 'pro'

export type DefaultMarkets = Record<CarbonSDK.Network, string>

export const fallbackDefaultPerpMarkets: DefaultMarkets = {
  [CarbonSDK.Network.MainNet]: 'cmkt/117',
  [CarbonSDK.Network.TestNet]: 'BTC_PERP.USDC',
  [CarbonSDK.Network.DevNet]: 'BTC_PERP.USDC',
  [CarbonSDK.Network.LocalHost]: 'BTC_PERP.USDC',
}

export const PERP_MARKET_PATH_REGEX = /\/trade\/[A-Z]+-PERP/

export const fallbackDefaultSpotMarkets: DefaultMarkets = {
  [CarbonSDK.Network.MainNet]: 'cmkt/109',
  [CarbonSDK.Network.TestNet]: 'swth_eth',
  [CarbonSDK.Network.DevNet]: 'swth_eth',
  [CarbonSDK.Network.LocalHost]: 'swth_eth',
}

const InitOrderParams: Partial<OrderFormState> = {
  price: BN_ZERO,
  inputPrice: '',
  quantity: BN_ZERO,
  inputQuantity: '',
  total: BN_ZERO,
  inputTotal: '',
}

export const DefaultLiteOrderParams = {
  [MarketType.Perpetual]: {
    ...InitOrderParams,
    orderType: OrderModule.OrderType.Limit,
    side: OrderModule.OrderSide.Buy,
  },
  [MarketType.Spot]: {
    ...InitOrderParams,
    ...defaultTpSlFormState,
    orderType: OrderModule.OrderType.Market,
    isReduceOnly: false,
    isPostOnly: false,
  },
}

export const makerFeeTooltip = 'Maker fees are fees charged when you add liquidity to the order book by placing a limit order (i.e. order does not get filled immediately).'

export const takerFeeTooltip = 'Taker fees are fees incurred when you remove liquidity from the order book by executing a market order (i.e. order is filled immediately).'

export const defaultPreferredLeverage = 3

export const orderTriggerTypeLabels = {
  [OrderModule.TriggerType.LastPrice]: 'Last Price',
  [OrderModule.TriggerType.MarkPrice]: 'Mark Price',
  [OrderModule.TriggerType.IndexPrice]: 'Index Price',
}

export const timeInForceLabels = {
  [OrderModule.TimeInForce.Gtc]: 'Good Till Canceled (GTC)',
  [OrderModule.TimeInForce.Ioc]: 'Immediate Or Cancel (IOC)',
  [OrderModule.TimeInForce.Fok]: 'Fill Or Kill (FOK)',
}

export const FavoriteType = 'favourites'
