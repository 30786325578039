import { Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EvtCategory, useEventTrackers } from 'js/hooks'
import { isWalletConnected } from 'js/state/modules/account/selectors'
import { setWalletToggle } from 'js/state/modules/app/actions'

import Button from './Button'
import EmptyFiller, { EmptyFillerProps } from './EmptyFiller'

interface Props extends EmptyFillerProps {
  cta?: boolean
  buttonLabel?: string
  onClick?: () => void
  customEmptyCondition?: boolean
}

const EmptyCTA: React.FC<Props> = (props: Props) => {
  const {
    helperText, helperClass, cta, className, buttonLabel, onClick, customEmptyCondition, ...rest
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const eventTrackers = useEventTrackers()
  const isLoggedIn = useSelector(isWalletConnected)

  const connectWallet = () => {
    dispatch(setWalletToggle(true))
    eventTrackers.sendEvent(EvtCategory.Wallet, 'wallet_connect_tap')
  }

  const emptyText = helperText ?? ''
  const ctaMessage = cta ? emptyText : 'Connect your preferred wallet to view this section'
  const message = isLoggedIn ? emptyText : ctaMessage

  return (
    <React.Fragment>
      {isLoggedIn || customEmptyCondition ? (
        <div className={clsx(classes.loggedInFillerRoot, className)}>
          <EmptyFiller {...rest} helperText={message} helperClass={helperClass} />
          {buttonLabel && onClick && (
            <Button
              className={classes.loggedInFillerButton}
              baseButtonVariant="contained"
              color="secondary"
              onClick={onClick}
            >
              {buttonLabel}
            </Button>
          )}
        </div>
      ) : (
        <div className={clsx(classes.container, helperClass)}>
          <Typography className={classes.ctaText}>
            {message}
          </Typography>
          <Button
            className={classes.connectBtn}
            onClick={connectWallet}
            baseButtonVariant="contained"
            btnsize="regular"
          >
            <Typography className={classes.buttonText}>
              Connect Wallet
            </Typography>
          </Button>
        </div>
      )}
    </React.Fragment >
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
  },
  ctaText: {
    ...theme.typography.body3,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  buttonText: {
    ...theme.typography.body3,
    fontWeight: 700,
  },
  container: {
    padding: theme.spacing(6, 2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loggedInFillerRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gridGap: theme.spacing(1),
  },
  loggedInFillerButton: {
    '&.large': {
      height: '40px',
      ...theme.typography.body3,
      fontWeight: 700,
    }
  },
}))

export default EmptyCTA
