import BigNumber from 'bignumber.js'
import { BN_ONE, bnOrZero } from 'carbon-js-sdk/lib/util/number'
import React, { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { DefaultLiteOrderParams, DefaultMarkets, fallbackDefaultPerpMarkets, fallbackDefaultSpotMarkets } from 'js/constants/markets'
import Paths from 'js/constants/paths'
import { useMarketDisplayName } from 'js/hooks'
import useOrderManager from 'js/hooks/useOrderManager'
import { useTradeMode } from 'js/hooks/useTradeMode'
import { MarketType, isSpotMarket } from 'js/models/Market'
import { getNet } from 'js/state/modules/app/selectors'
import { setLastMarket, setMarketName, setMarketTypeSelect } from 'js/state/modules/exchange/actions'
import { getLastMarket, getMarket, getMarketsAsMap } from 'js/state/modules/exchange/selectors'
import { ExchangeState, Market } from 'js/state/modules/exchange/types'
import { getEffectiveLeverage } from 'js/state/modules/leverages/selectors'
import { getSlippageTolerance } from 'js/state/modules/orderManager/selectors'

import useGetCurrentMarket from '../helpers/useGetCurrentMarket'


const LiteSwapMarketTypes = [MarketType.Perpetual, MarketType.Spot] as const
export type LiteSwapMarketType = typeof LiteSwapMarketTypes[number]

const DefaultCurrentMarkets: Record<LiteSwapMarketType, string> = {
  [MarketType.Perpetual]: '',
  [MarketType.Spot]: '',
}

const FallbackMarkets: Record<LiteSwapMarketType, DefaultMarkets> = {
  [MarketType.Perpetual]: fallbackDefaultPerpMarkets,
  [MarketType.Spot]: fallbackDefaultSpotMarkets,
}

export type LiteModeContextType = {
  market: ExchangeState['market']
  markets: Market[]

  showChart: boolean
  toggleChart: (visibility?: boolean) => void
  marketTabs: LiteSwapMarketType[]
  marketTab: LiteSwapMarketType
  setMarketTab: Dispatch<SetStateAction<LiteSwapMarketType>>
  currentMarkets: typeof DefaultCurrentMarkets
  setCurrentMarkets: Dispatch<SetStateAction<typeof DefaultCurrentMarkets>>
  handleChangeTab: (tab: LiteSwapMarketType) => void
  leverage: BigNumber,
  slippageTolerance: number,
  editLeverage: boolean,
  setEditLeverage: Dispatch<SetStateAction<boolean>>,
  showSlippageModal: boolean,
  setSlippageTolerance: Dispatch<SetStateAction<number>>,
  setShowSlippageModal: Dispatch<SetStateAction<boolean>>

}
export const LiteModeContext = createContext<LiteModeContextType | undefined>(undefined)
export const useLiteModeContext = () => {
  const ctxValue = useContext(LiteModeContext)
  if (ctxValue === undefined) {
    throw new Error(
      'Expected an Context Provider somewhere in the react tree to set context value',
    )
  }
  return ctxValue
}

export const LiteProvider = ({ children }: PropsWithChildren<{}>) => {
  const market = useSelector(getMarket)
  const { activeMarket, validMarkets } = useGetCurrentMarket('lite')
  const tradeMode = useTradeMode()
  const markets = Object.values(validMarkets) ?? []
  const marketType: LiteSwapMarketType = (isSpotMarket(activeMarket)) ? MarketType.Spot : MarketType.Perpetual

  // MARKETS
  const history = useHistory()
  const dispatch = useDispatch()

  const net = useSelector(getNet)
  const getDisplayName = useMarketDisplayName()
  const { update } = useOrderManager()

  const marketsMap = useSelector(getMarketsAsMap)
  const goToMarket = (marketId: string) => {
    const marketToGo = marketsMap.get(marketId)
    if (!marketToGo) return
    dispatch(setLastMarket(marketToGo.id, net))
    dispatch(setMarketName(marketToGo.id))
    const displayName = getDisplayName(marketToGo, true)
    history.push(`${Paths.Lite}/${displayName}`)
  }



  // TABS
  const lastMarketName = useSelector(getLastMarket)[net]
  const marketTabs = LiteSwapMarketTypes as unknown as LiteSwapMarketType[]
  const [marketTab, setMarketTab] = useState<LiteSwapMarketType>(marketType)
  const [currentMarkets, setCurrentMarkets] = useState<typeof DefaultCurrentMarkets>({
    ...DefaultCurrentMarkets,
    [marketType]: activeMarket?.id,
  })

  const handleChangeTab = (tab: LiteSwapMarketType) => {
    setMarketTab(tab)
    dispatch(setMarketTypeSelect(tab))

    let tabMarketId = currentMarkets[tab]

    // Snapshot of current
    const currentTab = marketType
    const currentMarketId = market?.id ?? FallbackMarkets[marketType][net]
    if (currentTab !== tab) {
      tabMarketId = currentMarkets[tab] || FallbackMarkets[tab][net]
    } else {
      tabMarketId = tabMarketId || (currentMarketId || FallbackMarkets[tab][net])
    }
    setCurrentMarkets({
      ...currentMarkets,
      [currentTab]: currentMarketId,
      [tab]: tabMarketId,
    })

    // Update order params
    update(DefaultLiteOrderParams[tab])

    // Push route
    const marketToGo = marketsMap.get(tabMarketId) ?? marketsMap.get(lastMarketName)
    goToMarket(marketToGo?.id ?? FallbackMarkets[marketType][net])
  }

  useEffect(() => {
    tradeMode.toLite(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleChangeTab(marketType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketType])

  useEffect(() => {
    setCurrentMarkets({
      ...DefaultCurrentMarkets,
    })
  }, [net])

  // CHART
  const [showChart, setShowChart] = useState<boolean>(true)
  const toggleChart = (visibility: boolean = showChart) => setShowChart(!visibility)

   // LEVERAGE
   const [editLeverage, setEditLeverage] = useState<boolean>(false)
   const effectiveLeverage = useSelector(getEffectiveLeverage())
   const leverage = BigNumber.max(bnOrZero(effectiveLeverage, BN_ONE), BN_ONE)
 
   // SLIPPAGE
   const initialSlippage = useSelector(getSlippageTolerance)
   const [showSlippageModal, setShowSlippageModal] = useState<boolean>(false)
   const [slippageTolerance, setSlippageTolerance] = useState<number>(initialSlippage)

  return (
    <LiteModeContext.Provider value={{
      market,
      markets,
      showChart,
      toggleChart,
      marketTabs,
      marketTab,
      setMarketTab,
      handleChangeTab,
      currentMarkets,
      setCurrentMarkets,
      leverage,
      slippageTolerance,
      editLeverage,
      setEditLeverage,
      showSlippageModal,
      setSlippageTolerance,
      setShowSlippageModal
    }}>
      {children}
    </LiteModeContext.Provider>
  )
}
