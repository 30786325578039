import { Container, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { useDimensions } from "js/hooks"
import { useCommonStyles } from "js/utils"

const WalletBalanceFallback: React.FC = () => {
  const dimensions = useDimensions()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  return (
    <div className={classes.box}>
      <Container maxWidth={false} className={classes.containerDiv}>
        <div className={clsx(commonClasses.flexRow, classes.summaryRow)}>
          <div className={clsx(classes.summarySection, commonClasses.flexRow, commonClasses.alignItemsStart, commonClasses.justifyContentSpaceBetween)}>
            <div className={classes.skeletonWrapper}>
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton />
            </div>
          </div>
          <div className={clsx(commonClasses.alignItemsStart, commonClasses.justifyContentSpaceAround, classes.rightSummarySection)}>
            <div className={classes.skeletonWrapperRight}>
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton />
            </div>
          </div>
        </div>
        <div
          className={classes.tableContainer}
          style={{ minHeight: isMobile ? dimensions.otherPagesHeight.mobile - (3 * dimensions.gridPadding) - 72 : dimensions.otherPagesHeight.desktop - 64 }}
        >
          <div className={classes.skeletonWrapperTable}>
            <LoadingSkeleton width={isMobile ? undefined : 300} paddingBottom={48} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton />
          </div>
        </div>
      </Container>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    backgroundColor: theme.palette.background.base,
    padding: theme.spacing(3),
    [theme.breakpoints.between('sm', 'md')]: {
      padding: theme.spacing(3, 3, 8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0, 7),
    },
  },
  summaryRow: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  summarySection: {
    flex: 3,
    padding: '1.25rem 1.5rem 1.5rem 1.75rem',
    borderRadius: '4px',
    backgroundColor: theme.palette.background.primary,
    marginBottom: '2rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: { // added this for mobile version
      marginRight: 0,
      width: '100%',
      borderRadius: 0,
      marginBottom: '0.5rem',
      padding: '0.625rem 0.75rem',
    },
    [theme.breakpoints.up('md')]: { // updated this for desktop version
      '&:first-child': {
        marginRight: '2rem',
        width: '60%',
      },
      '&:last-child': {
        width: '40%',
      },
    },
  },
  skeletonWrapper: {
    height: '83px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '68px',
    },
  },
  rightSummarySection: {
    flex: 5,
    padding: theme.spacing(2.5, 3, 3, 3.5),
    borderRadius: '4px',
    marginBottom: '2rem',
    backgroundColor: theme.palette.background.primary,
    width: '100%',
    [theme.breakpoints.down('sm')]: { // added this for mobile version
      marginRight: 0,
      width: '100%',
      borderRadius: 0,
      marginBottom: '0.5rem',
      padding: '0.625rem 0.75rem',
    },
    [theme.breakpoints.up('md')]: { // updated this for desktop version
      '&:first-child': {
        marginRight: '2rem',
        width: '60%',
      },
      '&:last-child': {
        width: '40%',
      },
    },
  },
  skeletonWrapperRight: {
    height: '79px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    },
  },
  tableContainer: {
    borderRadius: '4px',
    backgroundColor: theme.palette.background.primary,
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1.5),
    },
  },
  skeletonWrapperTable: {
    width: '100%',
    height: '1200px',
    [theme.breakpoints.down('sm')]: {
      height: '800px',
    },
  },
}))

export default WalletBalanceFallback