import BigNumber from 'bignumber.js'
import { OrderModule } from 'carbon-js-sdk'
import { RecordOf } from 'immutable'

import { StorageKey } from 'js/constants/storage'
import { safeParseStoredValue } from 'js/utils/localstorage'
import { BN_ZERO } from 'js/utils/number'

export interface OrderManagerStateProps {
  readonly showOrderDetails: boolean
  readonly orderForm: OrderFormState
  openTpSlDialog: boolean

  takeProfitTriggerErrMsg: string
  takeProfitPriceErrMsg: string
  stopLossTriggerErrMsg: string
  stopLossPriceErrMsg: string

  readonly feeToken: string | null
  readonly feeQuantity: string
  readonly alertOpen: boolean
  readonly reservedSettingsOpen: boolean
}

export type OrderManagerState = RecordOf<OrderManagerStateProps>

export interface TPSLFormState {
  inputTakeProfitTriggerPrice: string
  inputTakeProfitPrice: string
  inputStopLossTriggerPrice: string
  inputStopLossPrice: string

  takeProfitTriggerPrice: BigNumber | null
  takeProfitPrice: BigNumber | null
  stopLossTriggerPrice: BigNumber | null
  stopLossPrice: BigNumber | null
  takeProfitRate: number
  stopLossRate: number

  takeProfitTriggerType: OrderModule.TriggerType
  stopLossTriggerType: OrderModule.TriggerType

  takeProfitOrderType: OrderModule.OrderType
  stopLossOrderType: OrderModule.OrderType

  isTakeProfitStopLoss: boolean
  editingTPSL: boolean
}

export interface OrderFormState extends TPSLFormState {
  inputPrice: string
  inputQuantity: string
  inputTotal: string
  inputStopPrice: string

  priceRelevantMarket?: string

  price: BigNumber
  quantity: BigNumber
  total: BigNumber
  stopPrice: BigNumber
  sliderValue: BigNumber
  unadjustedQuantity: BigNumber

  slippageTolerance: number

  triggerType: OrderModule.TriggerType

  side: OrderModule.OrderSide

  orderType: OrderModule.OrderType

  timeInForce: OrderModule.TimeInForce

  isPostOnly: boolean
  isReduceOnly: boolean
  isDisabled: boolean
  isUsdToggled: boolean
}

export interface SavedOrderType {
  isFuturesMarket: boolean
  type: string
}

export const OrderManagerActionTypes = {
  CLEAR_ERROR_MESSAGE: '@orderManager/CLEAR_ERROR_MESSAGE',
  CLEAR_NEW_ORDER: '@orderManager/CLEAR_NEW_ORDER',
  CLEAR_ORDER_FORM: '@orderManager/CLEAR_ORDER_FORM',

  SET_ORDER_FORM_INPUTS: '@orderManager/SET_ORDER_FORM_INPUTS',
  SET_NEW_ORDER: '@orderManager/SET_NEW_ORDER',
  SET_CACHED_ORDER_PRICES: '@orderManager/SET_CACHED_ORDER_PRICES',

  SET_SLIDER_VALUE: '@orderManager/SET_SLIDER_VALUE',
  SET_SHOW_ORDER_DETAILS: '@orderManager/SET_SHOW_ORDER_DETAILS',

  DISABLE_SUBMIT: '@orderManager/DISABLE_SUBMIT',
  ENABLE_SUBMIT: '@orderManager/ENABLE_SUBMIT',
  SET_ALERT_DIALOG_CLOSE: '@orderManager/SET_ALERT_DIALOG_CLOSE',
  SET_ALERT_DIALOG_OPEN: '@orderManager/SET_ALERT_DIALOG_OPEN',
  SELECT_FEE_TOKEN: '@orderManager/SELECT_FEE_TOKEN',
  SET_FEE_QUANTITY: '@orderManager/SET_FEE_QUANTITY',
  CLEAR_SWTH_FEE_FORM: '@orderManager/CLEAR_SWTH_FEE_FORM',
  SET_RESERVED_SETTINGS_CLOSE: '@orderManager/SET_RESERVED_SETTINGS_CLOSE',
  SET_RESERVED_SETTINGS_OPEN: '@orderManager/SET_RESERVED_SETTINGS_OPEN',

  SET_OPEN_TP_SL_DIALOG: '@orderManager/SET_OPEN_TP_SL_DIALOG',
  SET_TAKE_PROFIT_TRIGGER_ERR_MSG: '@orderManager/SET_TAKE_PROFIT_TRIGGER_ERR_MSG',
  SET_TAKE_PROFIT_PRICE_ERR_MSG: '@orderManager/SET_TAKE_PROFIT_PRICE_ERR_MSG',
  SET_STOP_LOSS_TRIGGER_ERR_MSG: '@orderManager/SET_STOP_LOSS_TRIGGER_ERR_MSG',
  SET_STOP_LOSS_PRICE_ERR_MSG: '@orderManager/SET_STOP_LOSS_PRICE_ERR_MSG',
  CALCULATE_TP_SL_TRIGGER_PRICES: '@orderManager/CALCULATE_TP_SL_TRIGGER_PRICES',
  SAVE_ORDER_TYPE: '@orderManager/SAVE_ORDER_TYPE',
}

export const defaultTpSlFormState: TPSLFormState = {
  inputTakeProfitTriggerPrice: '',
  inputTakeProfitPrice: '',
  inputStopLossTriggerPrice: '',
  inputStopLossPrice: '',

  takeProfitTriggerPrice: null,
  takeProfitPrice: null,
  stopLossTriggerPrice: null,
  stopLossPrice: null,
  takeProfitRate: 0,
  stopLossRate: 0,

  takeProfitTriggerType: OrderModule.TriggerType.IndexPrice,
  stopLossTriggerType: OrderModule.TriggerType.IndexPrice,

  takeProfitOrderType: OrderModule.OrderType.TakeProfitMarket,
  stopLossOrderType: OrderModule.OrderType.StopMarket,

  isTakeProfitStopLoss: false,
  editingTPSL: false,
}

export const defaultOrderFormState: OrderFormState = {
  ...defaultTpSlFormState,

  inputPrice: '',
  inputQuantity: '',
  inputTotal: '',
  inputStopPrice: '',

  priceRelevantMarket: undefined,

  price: BN_ZERO,
  quantity: BN_ZERO,
  total: BN_ZERO,
  stopPrice: BN_ZERO,
  sliderValue: BN_ZERO,
  unadjustedQuantity: BN_ZERO,

  slippageTolerance: 1,

  isPostOnly: false,
  isReduceOnly: false,
  isDisabled: false,
  isUsdToggled: true,
  side: OrderModule.OrderSide.Buy,
  orderType: OrderModule.OrderType.Limit,
  triggerType: OrderModule.TriggerType.IndexPrice,
  timeInForce: safeParseStoredValue(localStorage.getItem(StorageKey.OrderDefaultTimeInForce), {})[OrderModule.OrderType.Limit] ?? OrderModule.TimeInForce.Gtc,
}
