import { FormControl, makeStyles, Typography } from '@material-ui/core'
import { Insights } from 'carbon-js-sdk'
import clsx from 'clsx'
import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TextField } from 'js/components/Common'
import StandardDialog from 'js/components/Common/StandardDialog'
import Tooltip from 'js/components/Exchange/Common/Tooltip'
import { useAsyncTask } from 'js/hooks'
import { hideNodeInfoForm, setCustomNodes, setFormNode } from 'js/state/modules/app/actions'
import { getCustomNodes, getFormNode, getNet, getNodes, getSelectedNodes, getShowNodeInfoForm } from 'js/state/modules/app/selectors'
import { CustomNodeItem } from 'js/state/modules/app/types'
import { uuidv4 } from 'js/utils'
import { baseNodeForm, NodeFormKey, NodeFormState } from 'js/utils/nodes'
import { useCommonStyles } from 'js/utils/styles'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  formState: NodeFormState
  handleSelectNode: (node: any) => void
  setFormState: React.Dispatch<NodeFormState>
  formErrors: string[]
  setFormErrors: React.Dispatch<string[]>
}

const NodeInfoForm: React.FC<Props> = (props: Props) => {
  const { handleSelectNode, formErrors, formState, setFormErrors, setFormState } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dispatch = useDispatch()
  const nodes = useSelector(getNodes)
  const network = useSelector(getNet)
  const customNodes = useSelector(getCustomNodes)
  const selectedNodes = useSelector(getSelectedNodes)
  const formNode = useSelector(getFormNode)
  const isShowNodeInfoForm = useSelector(getShowNodeInfoForm)
  const filteredCustomNodes = customNodes.filter((node: CustomNodeItem) => node.appBuild === network)
  const customIndex = customNodes.findIndex((node: CustomNodeItem) => node.moniker === formNode?.moniker)
  const isCustomNode = !formNode || customIndex > -1
  const [runSaveNode, loading, error] = useAsyncTask('runSaveNode')

  useEffect(() => {
    if (formNode) {
      setFormState(formNode)
    }
    setFormErrors([])
  }, [formNode]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setFormState(baseNodeForm)
    dispatch(hideNodeInfoForm())
    dispatch(setFormNode(undefined))
    setFormErrors([])
  }

  const validateForm = (formState: NodeFormState) => {
    setFormErrors([])
    const errors = []
    const moniker = formState.moniker as string // formState don't have nodeId, so check against moniker
    const nodeMatch = nodes.find((node: Insights.NodeItem) => node.moniker === moniker)
    const filteredNodeMatch = filteredCustomNodes.find((node: CustomNodeItem) => node.moniker === moniker)
    if (nodeMatch || (filteredNodeMatch && filteredNodeMatch.moniker !== formNode?.moniker)) {
      errors.push('moniker')
    }
    const rpcUrl = formState.rpcUrl as string
    if (!rpcUrl.startsWith('http://') && !rpcUrl.startsWith('https://')) {
      errors.push('rpcUrl')
    }
    const restUrl = formState.restUrl as string
    if (!restUrl.startsWith('http://') && !restUrl.startsWith('https://')) {
      errors.push('restUrl')
    }
    const wsUrl = formState.wsUrl as string
    if (!wsUrl.startsWith('ws://') && !wsUrl.startsWith('wss://')) {
      errors.push('wsUrl')
    }
    const faucetUrl = formState.faucetUrl as string // optional field
    if (faucetUrl !== '' && !faucetUrl.startsWith('http://') && !faucetUrl.startsWith('https://')) {
      errors.push('faucetUrl')
    }
    const insightsUrl = formState.insightsUrl as string // optional field
    if (insightsUrl !== '' && !insightsUrl.startsWith('http://') && !insightsUrl.startsWith('https://')) {
      errors.push('insightsUrl')
    }
    const tmWsUrl = formState.tmWsUrl as string
    if (!tmWsUrl.startsWith('wss://') && !tmWsUrl.startsWith('ws://')) {
      errors.push('tmWsUrl')
    }
    setFormErrors(errors)
    if (errors.length > 0) {
      throw new Error()
    }
  }

  const handleSave = () => {
    runSaveNode(async () => {
      validateForm(formState)

      const newNode: CustomNodeItem = {
        ...formNode,
        appBuild: network,
        nodeId: uuidv4(),
        ...formState,
      }
      const newNodes: CustomNodeItem[] = customNodes
      if (formNode) {
        newNodes[customIndex] = newNode
      } else {
        newNodes.push(newNode)
      }
      dispatch(setCustomNodes(newNodes))
      if (selectedNodes?.[network]?.moniker === formNode?.moniker) {
        handleSelectNode(formState)
      }
      handleClose()
    })
  }

  const handleFormChange = (key: NodeFormKey) => (event: ChangeEvent<{ value: unknown }>) => {
    const newFormState: NodeFormState = {
      ...formState,
      [key]: event.target.value as string,
    }
    setFormState(newFormState)
  }

  interface NodeFormField {
    key: NodeFormKey
    label: string
    tooltip?: string
  }

  const inputFields: NodeFormField[] = [
    {
      key: 'moniker',
      label: 'Node Name',
    },
    {
      key: 'rpcUrl',
      label: 'RPC URL',
    },
    {
      key: 'restUrl',
      label: 'Rest URL',
    },
    {
      key: 'wsUrl',
      label: 'Websocket URL',
    },
    {
      key: 'faucetUrl',
      label: 'Faucet URL (optional)',
    },
    {
      key: 'insightsUrl',
      label: 'Insights URL (optional)',
    },
    {
      key: 'tmWsUrl',
      label: 'tmWebSocket URL',
      tooltip: 'Tendermint Websocket endpoint (i.e. wss://<rpcUrl domain>/)',
    },
  ]

  return (
    <StandardDialog
      open={isShowNodeInfoForm}
      headerTitle={formNode ? 'Edit Node' : 'Add Custom Node'}
      maxWidth="xs"
      onSubmit={handleSave}
      onClose={handleClose}
      loading={loading}
      submitDisabled={loading || !isCustomNode}
    >
      <div>
        {inputFields.map((field: NodeFormField) => (
          <FormControl fullWidth key={field.key} className={classes.formControl}>
            <div className={commonClasses.alignItemsCenter}>
              {field.tooltip ? (
                <Tooltip isTextTooltip placement="top" arrow={false} title={field.tooltip}>
                  <span className={classes.inputLabel}>
                    {field.label}
                  </span>
                </Tooltip>
              ) : (
                <span className={classes.inputLabel}>
                  {field.label}
                </span>
              )}
            </div>
            <TextField
              fullWidth
              variant="standard"
              size="small"
              color="secondary"
              value={formState[field.key] ?? ''}
              onChange={handleFormChange(field.key)}
              error={!!formErrors.find((error) => error === field.key)}
              inputClasses={{
                outlined: {
                  root: clsx(classes.textField, { readOnly: !isCustomNode }),
                },
              }}
              inputProps={{ readOnly: !isCustomNode }}
            />
          </FormControl>
        ))}
        {error && formErrors[0] === 'moniker' && (
          <Typography className={classes.error}>
            Another node with the same name already exists
          </Typography>
        )}
        {error && (formErrors.includes('faucetUrl') || formErrors.includes('insightsUrl')) && (
          <Typography className={classes.error}>
            URL inputs for optional fields must be valid HTTP/WS URLs or empty
          </Typography>
        )}
        {error && (formErrors.includes('rpcUrl') || formErrors.includes('wsUrl') || formErrors.includes('restUrl') || formErrors.includes('tmWsUrl')) && (
          <Typography className={classes.error}>
            URL inputs must be valid HTTP/WS URLs
          </Typography>
        )}
      </div>
    </StandardDialog>
  )
}

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: 0,
    paddingTop: theme.spacing(0.5),
  },
  button: {
    ...theme.typography.body2,
    fontWeight: 'bold',
    padding: theme.spacing(1.25, 2.5),
    '&:last-child': {
      marginLeft: theme.spacing(2),
    },
    background: 'linear-gradient(270deg, #482BFF 0%, #007AFF 100%)',
    color: '#ffffff',
    borderRadius: 4,
  },
  buttonOutline: {
    ...theme.typography.body2,
    fontWeight: 'bold',
    padding: theme.spacing(1.25, 2.5),
    '&:last-child': {
      marginLeft: theme.spacing(2),
    },
    color: theme.palette.text.primary,
    borderRadius: 4,
  },
  containerClass: {
    maxWidth: '12rem',
    padding: theme.spacing(1, 1.25),
    zIndex: 20000,
  },
  smallButton: {
    '&:last-child': {
      marginLeft: theme.spacing(2),
    },
  },
  backArrow: {
    height: '1rem',
    width: '1rem',
    marginRight: theme.spacing(1),
  },
  formControl: {
    // padding: theme.spacing(4, 0, 3),
    display: 'block',
    paddingBottom: theme.spacing(1.5),
  },
  iconClass: {
    marginBottom: theme.spacing(-0.25),
    marginLeft: theme.spacing(0.25),
  },
  inputLabel: {
    ...theme.typography.body4,
    color: theme.palette.text.hint,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 0, 2),
  },
  dialogTitle: {
    '& h2': {
      ...theme.typography.h5,
    },
    padding: theme.spacing(5, 6, 0),
  },
  dialogContent: {
    padding: theme.spacing(2, 6, 6),
  },
  para: {
    ...theme.typography.body3,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(8),
  },
  error: {
    ...theme.typography.body3,
    color: theme.palette.error.main,
  },
  textField: {
    marginTop: theme.spacing(0.5),
    height: '2rem',
    '& input': {
      ...theme.typography.body4,
    },
  },
  textLabel: {
    '& input': {
      ...theme.typography.body3,
      padding: 0,
    },
  },
}))

export default NodeInfoForm
