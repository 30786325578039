import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { getLastSelected, SelectedMode, setLastSelected } from 'js/utils/lastToggle'

import { CdpLiquidationsActionTypes, CDPLiquidationsState, CDPLiquidationsStateProps, ModuleTotal } from './types'

const initTotal: ModuleTotal = {
  collateralsUsd: null,
  debtsUsd: null,
}

const hideSmallDebtBoolean = getLastSelected(SelectedMode.HIDE_SMALL_DEBT_AMOUNT)
const showOnlyLowHealthBoolean = getLastSelected(SelectedMode.SHOW_ONLY_LOW_HEALTH)

const DefaultInitialState:
  Record.Factory<CDPLiquidationsStateProps> = Record<CDPLiquidationsStateProps>({
    moduleTotal: initTotal,
    liquidations: [],
    positions: [],
    hideSmallDebt: hideSmallDebtBoolean,
    showOnlyLowHealth: showOnlyLowHealthBoolean,
  })

const defaultInitialState: CDPLiquidationsState = new DefaultInitialState()

export const CDPLiquidationReducer = (
  state: CDPLiquidationsState = defaultInitialState,
  action: AnyAction,
): CDPLiquidationsState => {
  switch (action.type) {
    case CdpLiquidationsActionTypes.SET_LIQUIDATIONS: {
      return state.set('liquidations', action.payload)
    }
    case CdpLiquidationsActionTypes.SET_POSITIONS: {
      return state.set('positions', action.payload)
    }
    case CdpLiquidationsActionTypes.SET_MODULE_TOTAL: {
      return state.set('moduleTotal', action.payload)
    }
    case CdpLiquidationsActionTypes.SET_SHOW_ONLY_LOW_HEALTH: {
      const checked = action.payload
      setLastSelected(SelectedMode.SHOW_ONLY_LOW_HEALTH, checked)
      return state.set('showOnlyLowHealth', checked)
    }
    case CdpLiquidationsActionTypes.SET_HIDE_SMALL_DEBT: {
      const checked = action.payload
      setLastSelected(SelectedMode.HIDE_SMALL_DEBT_AMOUNT, checked)
      return state.set('hideSmallDebt', checked)
    }
    default:
      return state
  }
}
