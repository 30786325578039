import { useEffect, useState } from 'react'

import { SimpleMap } from 'js/utils'
import { getContentfulEnvironment } from 'js/utils/environment'

interface CallArgs {
  space?: string
  contentType: string
  query?: SimpleMap<string | number>
}

interface CallResult {
  data: any
}

const useContentful = (args: CallArgs): CallResult => {
  const [data, setData] = useState<any | undefined>()
  const spaceId = args.space ?? 'demex'
  const orderQuery = args.query?.order
  const startTimeStampQuery = args.query?.startTimeStamp
  const endTimeStampQuery = args.query?.endTimeStamp
  const contentType = args.contentType

  useEffect(() => {
    setData(undefined);

    (async () => {
      const urlQuery = []
      const env = getContentfulEnvironment()
      if (orderQuery) urlQuery.push(`order=${orderQuery}`)
      if (contentType === 'demexAppBanner') urlQuery.push(`fields.environment=${env}`)
      if (startTimeStampQuery) urlQuery.push(`fields.startTimestamp[lte]=${startTimeStampQuery}`)
      if (endTimeStampQuery) urlQuery.push(`fields.endTimestamp[gt]=${endTimeStampQuery}`)
      const urlQueryString = `?${urlQuery.join('&')}`

      const url = `https://content.carbon.network/contentful/${spaceId}/${contentType}${urlQueryString}`

      const response = await fetch(url)
      const result = await response.json()

      setData(result)
    })().catch(console.error)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType, orderQuery, spaceId])

  return { data }
}

export default useContentful
