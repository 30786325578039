import { CarbonSDK, TypeUtils } from 'carbon-js-sdk'
import { RecordOf } from 'immutable'

export interface LeveragesStateProps {
  readonly leverages: {
    [market: string]: string
  }
  readonly preferredLeverages:{ // to be retrieved from localStorage
    [market: string]: string
  }
  readonly isSettingLeverages: boolean
  readonly pendingLeverage: number
  readonly showLeveragesModal: boolean
}

export interface SetPreferredLeveragePayload {
  network: CarbonSDK.Network
  address: string | undefined
}

export interface AddPreferredLeveragePayload {
  network: CarbonSDK.Network
  address: string | undefined
  preferredLeverages: PreferredLeverages
}

export interface PreferredLeverageByAddress {
  [address: string]: PreferredLeverages
}

export interface PreferredLeverageByNetwork {
  [network: string]: PreferredLeverageByAddress
}

export type PreferredLeverages = TypeUtils.SimpleMap<string>

export type LeveragesState = RecordOf<LeveragesStateProps>

export const LeveragesActionTypes = {
  SET_PENDING_LEVERAGE: '@leverages/SET_PENDING_LEVERAGE',
  SET_PREFERRED_LEVERAGES: '@leverages/SET_PREFERRED_LEVERAGES',
  ADD_PREFERRED_LEVERAGE: '@leverages/ADD_PREFERRED_LEVERAGE',
  CLEAR_PENDING_LEVERAGE: '@leverages/CLEAR_PENDING_LEVERAGE',
  SET_LEVERAGES: '@leverages/SET_LEVERAGES',
  BEGIN_SET_LEVERAGES: '@leverages/BEGIN_SET_LEVERAGES',
  END_SET_LEVERAGES: '@leverages/END_SET_LEVERAGES',
  SYNC_LEVERAGES: '@leverages/SYNC_LEVERAGES',
  CLEAR_LEVERAGES: '@leverages/CLEAR_LEVERAGES',
  SHOW_LEVERAGE_MODAL: '@leverages/SHOW_LEVERAGE_MODAL',
  HIDE_LEVERAGE_MODAL: '@leverages/HIDE_LEVERAGE_MODAL',
}
