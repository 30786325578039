import { makeStyles, Typography, Card } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

interface TopCardLabelClasses {
  item: string
}

interface Props {
  title?: string | React.ReactNode
  content?: string | React.ReactNode
  info?: string | React.ReactNode
  children?: string | React.ReactNode
  customClasses?: TopCardLabelClasses
}
const TopCardLabel: React.FC<Props> = (props: Props) => {
  const { title, content, children, customClasses = {} as TopCardLabelClasses } = props
  const classes = useStyles()
  return (
    <Card className={clsx(classes.item, customClasses.item)} elevation={0}>
      {title && <div className={classes.title}>{title}</div>}
      {typeof content === 'string' ? (
        <Typography className={classes.content}>{content}</Typography>
      ) : (
        <div className={classes.content}>
          {content}
        </div>
      )}
      {children}
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  item: {
    height: '100%',
    padding: theme.spacing(2.5, 4, 3),
    background: theme.palette.background.primary,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  title: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body4,
      marginBottom: theme.spacing(0.75),
    },
  },
  content: {
    ...theme.typography.h3,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h6,
    },
  },
}))

export default TopCardLabel
