import { useState } from 'react'

const useFormState = <T>(initialState: T) => {
  const [formState, setFormState] = useState<T>(initialState)

  const stateUpdater = (fieldKey: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormState({
        ...formState,
        [fieldKey]: event.target.value,
      })
    }
  }

  return [formState, setFormState, stateUpdater] as const
}

export default useFormState
