import { RefObject, useMemo, useSyncExternalStore } from 'react'

import { calculateElementHeight, calculateElementWidth } from 'js/utils'

function subscribe(callback: (e: Event) => void) {
  window.addEventListener("resize", callback)
  return () => {
    window.removeEventListener("resize", callback)
  }
}

// based off https://stackoverflow.com/a/75101934/18753727
export const useGetRefHeightWidth = (ref: RefObject<HTMLElement | null | undefined>) => {
  const heightWidth = useSyncExternalStore(
    subscribe,
    () => JSON.stringify({
      height: calculateElementHeight(ref.current),
      width: calculateElementWidth(ref.current)
    })
  )
  return useMemo(() => JSON.parse(heightWidth), [heightWidth])
}