import { DeliverTxResponse } from '@cosmjs/cosmwasm-stargate'
import { BroadcastTxSyncResponse } from '@cosmjs/tendermint-rpc'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { TypeUtils } from 'carbon-js-sdk'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FeatureType } from 'js/constants/notification'
import { BroadcastTxParams, WaitForBlockConfirmationCallback } from 'js/constants/tx'
import { TxPhase } from 'js/models'
import { setLastTransactionTimestamp, setTxBeforeMergeEvm } from 'js/state/modules/account/actions'
import { getReservedTokensPreference } from 'js/state/modules/account/selectors'
import { AccountActionTypes, LastTransaction } from 'js/state/modules/account/types'
import { setFeeToggleOpen, setMobileMenuOpen } from 'js/state/modules/app/actions'
import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { updateTxPhase } from 'js/state/modules/loadingTask/actions'
import { UpdateTxPhase } from 'js/state/modules/loadingTask/types'
import { getAdjustedBalances } from 'js/state/modules/walletBalance/selectors'
import { getErrorMessage, uuidv4 } from 'js/utils'
import { safeParseStoredValue } from 'js/utils/localstorage'
import { getAvailTokenDenom, getTotalFee } from 'js/utils/networkFee'
import { customToast } from 'js/utils/notifications'
import { BN_ZERO } from 'js/utils/number'
import { sentryCaptureException } from 'js/utils/sentry'

import { ReactComponent as FeeSettings } from 'assets/FeeSettings.svg'

const useBroadcastTx = (taskName: string, ...txTypes: string[]) => {
  const dispatch = useDispatch()
  const sdk = useSelector(getCarbonSDK)
  const walletAddress = sdk?.wallet?.bech32Address ?? ''
  const adjustedBalances = useSelector(getAdjustedBalances)
  const { tokenPrefDenoms = [] } = useSelector(getReservedTokensPreference) ?? {}
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const broadcastTxRunner = useCallback(async (params: BroadcastTxParams): Promise<BroadcastTxSyncResponse | WaitForBlockConfirmationCallback | null> => {
    const taskUuid = uuidv4()
    const { txTask, opts } = params
    const {
      onBlockCallback,
      waitForBlock = false,
      featureType,
      finalHandler,
      extras,
      onLowFeeCallback,
    } = opts ?? {}
    let txHash: string = ''
    let broadCastResponse: BroadcastTxSyncResponse

    const feeDenom = getAvailTokenDenom(adjustedBalances, tokenPrefDenoms, sdk, txTypes)
    const adjustedFee = getTotalFee(sdk, txTypes, feeDenom)
    const balance = adjustedBalances[feeDenom]
    const feeDenomBalance = (balance?.available ?? BN_ZERO).plus(balance?.feeReserved ?? 0)
    if (feeDenomBalance.lt(adjustedFee)) {
      customToast.info({
        featureType: FeatureType.WALLET,
        title: 'Low Fee Token Balance', message: 'Deposit more tokens or change your fee settings to ensure successful transactions.',
        action: {
          label: 'Change Fee Settings',
          endIcon: FeeSettings,
          cta: () => {
            if (isMobile) {
              dispatch(setMobileMenuOpen())
            }
            dispatch(setFeeToggleOpen())
          }
        }
      })
      if (onLowFeeCallback) onLowFeeCallback()
      return null
    }
    try {
      dispatch(setTxBeforeMergeEvm({ broadcastTxParams: params, taskName, txTypes }))
      broadCastResponse = await txTask() as BroadcastTxSyncResponse

      txHash = Buffer.from(broadCastResponse.hash).toString('hex')

      const newTxPhase: UpdateTxPhase = {
        name: taskName,
        uuid: taskUuid,
        phase: TxPhase.BROADCAST,
        txHash,
        featureType,
        onBlockConfirmation: {
          callback: onBlockCallback,
        },
        walletAddress,
        extras,
      }
      if (walletAddress) {
        const usersLastTransactionStr = localStorage.getItem(AccountActionTypes.SET_LAST_TRANSACTION_TIMESTAMP)
        const usersLastTransactionMap: TypeUtils.SimpleMap<LastTransaction> = safeParseStoredValue(usersLastTransactionStr, {})
        const currentTimestamp = dayjs.utc().unix()
        const userLastTransaction = usersLastTransactionMap?.[walletAddress]
        const previousTransactionTimestamp = userLastTransaction?.lastTransactionTimestamp ?? currentTimestamp
        const newState = {
          ...usersLastTransactionMap,
          [walletAddress]: {
            ...userLastTransaction,
            lastTransactionTimestamp: currentTimestamp,
            secondsFromPreviousTransaction: currentTimestamp - previousTransactionTimestamp,
          },
        }
        dispatch(setLastTransactionTimestamp(newState))
      }

      if (!waitForBlock) {
        dispatch(updateTxPhase(newTxPhase))
        return broadCastResponse
      }

      const blockConfirmationPromise = new Promise<DeliverTxResponse>((resolve, reject) => {
        dispatch(updateTxPhase({
          ...newTxPhase,
          onBlockConfirmation: {
            ...newTxPhase.onBlockConfirmation,
            handler: { resolve, reject },
          },
          extras,
        }))
      })

      return { wait: () => blockConfirmationPromise }
    } catch (err) {
      const errMsg = getErrorMessage(err)
      if (![
        // use reject signing request
        'Request rejected',
        'Transaction declined',
        'User rejected the transaction',
        'Request Signature: User denied request signature.',

        // ledger not open/screensaver mode
        'Ledger Native Error: InvalidStateError: The device must be opened first.',
        'Ledger Native Error: LockedDeviceError: Ledger device: Locked device (0x5515)',
      ].includes(errMsg ?? '')) {
        sentryCaptureException(err, {
          extra: {
            walletType: sdk?.wallet?.providerAgent,
            network: sdk?.network,
            carbonCoreAddress: sdk?.wallet?.bech32Address,
            evmBech32Address: sdk?.wallet?.evmBech32Address,
            txTypes,
            sendOpts: opts,
          },
        })
      }

      const error = err as Error
      dispatch(updateTxPhase({
        name: taskName,
        uuid: taskUuid,
        phase: TxPhase.BROADCAST,
        featureType,
        txHash,
        error,
        extras,
      }))
      throw err
    } finally {
      finalHandler?.()
    }
  }, [taskName, dispatch, walletAddress, adjustedBalances, tokenPrefDenoms, sdk, txTypes, isMobile])

  return [broadcastTxRunner]
}
export default useBroadcastTx