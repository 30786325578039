import { CarbonSDK } from 'carbon-js-sdk'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { TokenFeeSettings } from 'js/constants/assets'
import { getEnableSwthFee, getReservedTokensPreference, getTokenFeeSettings } from 'js/state/modules/account/selectors'
import { getCarbonSDK, getSDKWallet } from 'js/state/modules/app/selectors'
import { BN_ZERO } from 'js/utils/number'

const useIndivReservedFees = () => {
  const tokenFeeSettings: TokenFeeSettings | null = useSelector(getTokenFeeSettings)
  const wallet = useSelector(getSDKWallet)
  const sdk: CarbonSDK = useSelector(getCarbonSDK) as CarbonSDK
  const { firstPriority = { denom: '', symbol: '' } } = useSelector(getReservedTokensPreference) ?? {}
  const tokenUsdValue = sdk?.token.getUSDValue(firstPriority?.denom) ?? BN_ZERO
  const indivEnable = useSelector(getEnableSwthFee) ?? false

  const swthUsdValue = sdk?.token.getUSDValue('swth') ?? BN_ZERO

  const { reserveFeeUsd } = useMemo(() => {
    if (!wallet || !tokenFeeSettings) {
      return {
        reserveFeeUsd: BN_ZERO,
      }
    }

    return {
      reserveFeeUsd: bnOrZero(tokenFeeSettings?.reserve),
    }
  }, [tokenFeeSettings, wallet, swthUsdValue]) // eslint-disable-line

  const reserveFeeTokens = reserveFeeUsd.dividedBy(tokenUsdValue).dp(8) ?? BN_ZERO

  return {
    reserve: reserveFeeTokens,
    reserveUsd: reserveFeeUsd,
    indivEnable,
  }
}

export default useIndivReservedFees
