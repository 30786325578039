import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { useSelector } from 'react-redux'

import SvgIcon from 'js/components/Common/SvgIcon'
import { getThemeType } from 'js/state/modules/app/selectors'

import { ReactComponent as EmptyStateDark } from 'assets/EmptyState_Dark.svg'
import { ReactComponent as EmptyStateLight } from 'assets/EmptyState_Light.svg'


export interface EmptyFillerProps {
  helperText: string | React.ReactNode
  emptyClass?: string
  className?: string
  forceLightTheme?: boolean
  helperClass?: string
  componentImg?: React.ReactNode
  secondaryText?: boolean
}

const EmptyFiller: React.FC<EmptyFillerProps> = (props: EmptyFillerProps) => {
  const classes = useStyles()
  const {
    helperText, emptyClass, className, forceLightTheme = false,
    componentImg, helperClass, secondaryText = false,
  } = props

  const themeType = useSelector(getThemeType)
  const svg = themeType === 'light' || forceLightTheme ? EmptyStateLight : EmptyStateDark

  return (
    <div className={clsx(classes.container, className)}>
      {componentImg ?? (
        <SvgIcon
          className={clsx(classes.emptyIcon, emptyClass)}
          component={svg}
        />
      )}
      {/* TODO: Remove once V2 Theme is fully implemented */}
      <div>
        <div className={clsx(classes.helperText, helperClass)}>
          {helperText}
        </div>
        <div className={clsx(classes.outerBox)}>
          {secondaryText && (
            <div className={classes.suggestions}>
              <h3 className={classes.heading}>Suggestions</h3>
              <ul className={classes.list}>
                <li className={classes.listItem}>Make sure that all words are spelled correctly</li>
                <li className={classes.listItem}>Type in the token symbol</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  helperText: {
    ...theme.typography.body4,
    marginTop: '16px',
    marginBottom: '16px',
    textAlign: 'center',
    color: theme.palette.text.disabled,
  },
  emptyIcon: {
    fontSize: '3rem',
    maxHeight: '50px',
    '& rect': {
      fill: theme.palette.background.tertiary,
    },
  },
  suggestions: {
    color: theme.palette.text.disabled,
    fontFamily: 'Play',
  },
  heading: {
    ...theme.typography.body4,
    textAlign: 'left',
    marginBottom: '0.5rem',
  },
  list: {
    listStylePosition: 'outside',
    textAlign: 'left',
    display: 'block',
    paddingInlineStart: '15px',
    marginBottom: '1rem',
  },
  listItem: {
    ...theme.typography.body4,
    textAlign: 'left',
  },
  outerBox: {
    marginLeft: '1rem',
  },
}))

export default EmptyFiller
