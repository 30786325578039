import { BN_HUNDRED } from 'js/utils/number'
import { SimpleMap } from 'js/utils/types'

export interface AirdropLootBox {
  iconSrc: string
  revealSrc: string
  label: string
  minPoints: number
  maxPoints: number
}

export enum LootBoxType {
  Retro = '0',
  Common = '1',
  Uncommon = '2',
  Rare = '3',
  Epic = '4',
  Legendary = '5'
}

export const displayLootBoxTypes: LootBoxType[] = [
  LootBoxType.Common,
  LootBoxType.Uncommon,
  LootBoxType.Rare,
  LootBoxType.Epic,
  LootBoxType.Legendary
]

export interface WheelItem {
  id: string
  type: string
  imgSrc: string
  label: string
  imgAlt?: string
}

export interface LootBoxCount {
  type: LootBoxType
  count: number
  totalPoints: number
}

export interface SquadTier {
  tierNum: TierType,
  label: string
  pointsReq: number
  invitesReq: number,
}

export enum TierType {
  Bronze = 1,
  Silver = 2,
  Gold = 3,
  Diamond = 4
}

export const Tiers: SimpleMap<SquadTier> = {
  [TierType.Bronze]: {tierNum: 1, label: 'bronze', pointsReq: 0, invitesReq: 0},
  [TierType.Silver]: {tierNum: 2, label: 'silver', pointsReq: 1000000, invitesReq: 4},
  [TierType.Gold]: {tierNum: 3, label: 'gold', pointsReq: 15000000, invitesReq: 8},
  [TierType.Diamond]: {tierNum: 4, label: 'diamond', pointsReq: 75000000, invitesReq: 12},
}

export const MAX_TIER = TierType.Diamond

export const DEFAULT_PAGE_NUMBER = 1
export const RESULTS_PER_PAGE = 5
export const REQUIRED_TRADING_VOLUME = BN_HUNDRED
